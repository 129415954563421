export const kpiList = [
  {
    vehicle_id: true,
    reg_plate: true,
    steer_tire_size: true,
    drive_tire_size: true,
    tank_capacity: true,
    track_status: true,
    config_status: true,
    internal_grouping: true,
    make: true,
    axle_type: true,
    inspection_date: true,
    odometer_reading: true,
    mounted_tires: true,
    type: true,
    name: true,
    vin_chassis: true,
    safety_check_date: true,
    tire_size: true,
    lift_axle: true,
  },
  {
    isRetread: true,
    last_event_date: true,
    last_event_type: true,
    last_km_driven: true,
    last_pressure: true,
    last_tread_depth: true,
    mileage: true,
    mount_date: true,
    // product_line: true,
    size: true,
    status: true,
    tire_id: true,
    vehicle_id: true,
    vehicle_name: true,
    vehicle_type: true,
  },
  {
    tire_changes: true,
    tank_capacity: true,
    tire_recommendation: true,
  },
  {
    tire_changes: true,
    tank_capacity: true,
    tire_recommendation: true,
  },
  {
    total_share_motorway: true,
    total_share_regional: true,
    total_share_urban: true,
    '3PMS_needed': true,
    average_fuel_consumption: true,
    total_fuel_burnt: true,
    total_number_of_trips: true,
    total_distance_driven: true,
    fuel_level_drop: true,
    mission_profile: true,
    average_speed: true,
    idling_locations: false,
    minimum_change_in_elevation: false,
    maximum_change_in_elevation: false,
    average_change_in_elevation: false,
  },
];

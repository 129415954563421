import {
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
  Backdrop,
  CircularProgress,
  Autocomplete,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  createTirePrices,
  deleteTirePrices,
  getFilterTirePrices,
  getTirePriceFilter,
  getTirePrices,
  perPageTirePrices,
  updateTirePrices,
} from '../../../services/fleet_service';
import CustomTable from '../../../components/custom/CustomTableTemp';
import { setAccessTokenSelector } from '../../../redux/users/ducks';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { BiPencil, BiTrash } from 'react-icons/bi';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomTextField from '../../../components/custom/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import { UnfoldMore } from '@mui/icons-material';
import SearchBar from 'components/custom/Search';
import { useDispatch } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import { tiresDbSelector } from 'redux/tireInventory/ducks';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      minWidth: 500,
      maxWidth: 600,
      padding: '0rem 1rem',
    },
    inviteBtn: {
      textTransform: 'unset',
      fontSize: 18,
    },
    filterMenu: {
      width: '100%',
      height: '50%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    table: {
      marginTop: 20,
    },
    modalHead: {
      opacity: 0.5,
      marginBottom: 20,
    },
    submit: {
      outline: 'none',
      border: 'none',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      maxHeight: 45,
      padding: 10,
      borderRadius: 5,
      fontSize: 18,
      cursor: 'pointer',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&:disabled': { backgroundColor: 'grey' },
      '&:hover': { backgroundColor: '#49741c' },
    },
    closeBtn: {
      cursor: 'pointer',
    },
    assign: {
      textTransform: 'Capitalize',
    },
    confirmBtn: {
      margin: '28px 0px',
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
      maxHeight: '50px',
      '& h6': {
        marginBottom: '0px !important',
      },
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

export const TirePriceTable = () => {
  const { t } = useTranslation();
  const access_token = useSelector(setAccessTokenSelector);
  const [tireDetails, setTireDetails] = useState([]);
  const [totalTires, setTotalTires] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const { classes } = useStyles();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isSubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterBrands, setFIlterBrands] = useState([]);
  const [filterProductLines, setFIlteredProductLines] = useState([]);
  const [filteredTireSizes, setFilteredTireSizes] = useState([]);
  const [openFilterMenu, setOpenFilterMenu] = useState(false);
  const tiresResource = useSelector(tiresDbSelector);
  const [brands, setBrands] = useState([]);
  const [lines, setLines] = useState([]);
  const tireSizes = tiresResource ? tiresResource?.sizes : [];
  const [filterValues, setFilterValues] = useState({});
  const initialTireValues = {
    size: '',
    product_line: '',
    brand: '',
    price: 0,
    price_id: '',
  };
  const [tireValues, setTireValues] = useState(initialTireValues);
  const [isEditTirePrice, setIsEditTirePrice] = useState(false);
  const [test, setTest] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getPrices() {
      try {
        setIsLoading(true);
        const response = await getTirePrices(access_token, 0, rowsPerPage);
        if (response.data.status_code === 200) {
          const {
            data: {
              resp: { count, tire_price },
            },
          } = response;
          setTireDetails(tire_price);
          setTotalTires(count);
        }
      } catch (error) {
        console.error('could not fetch tire details');
      } finally {
        setIsLoading(false);
      }
    }
    if (access_token) {
      getPrices();
    }
  }, [test, access_token, rowsPerPage]);

  useEffect(() => {
    getBrands();
    // eslint-disable-next-line
  }, [tireValues.size]);

  useEffect(() => {
    if (access_token) {
      (async () => {
        const result = await getTirePriceFilter(access_token);
        setFilterValues(result.data.resp);
      })();
    }
  }, [access_token]);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setTireValues({
      size: row.size,
      product_line: row.product_line,
      brand: row.brand,
      price: row.price,
      price_id: row.price_id,
    });
    getProductLines(row.brand, row.size);
  };

  const deleteTirePrice = async priceId => {
    try {
      const res = await deleteTirePrices(access_token, priceId);
      if (res.data.status_code === 200) {
        dispatch(setNotification(res.data.return_message, 'success', true));
        setAnchorEl(null);
        setTest(prev => !prev);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
    }
  };

  const getBrands = () => {
    if (tiresResource) {
      Object.entries(tiresResource.sorted).forEach(resource => {
        if (resource[1][tireValues.size]) {
          const result = Object.keys(resource[1][tireValues.size]).map(
            brand => {
              return brand;
            }
          );
          setBrands(result);
        }
      });
    }
  };

  const columns = [
    {
      key: 'brand',
      title: t('settingsPage.tireSettings.brand'),
      canSort: false,
      dataHook: 'brand',
    },
    {
      key: 'size',
      title: t('settingsPage.tireSettings.tireSize'),
      canSort: false,
      dataHook: 'tire-size',
    },
    {
      key: 'product_line',
      title: t('settingsPage.tireSettings.productLine'),
      canSort: false,
      dataHook: 'product-line',
    },
    {
      key: 'price',
      title: t('settingsPage.tireSettings.priceId') + ' (€)',
      canSort: false,
      dataHook: 'price-id',
    },
    {
      key: 'actions',
      title: t('tireInventoryItems.titles.actions'),
      canSort: false,
      dataHook: 'actions',
      render: (row, i) => (
        <>
          <Box
            sx={{ cursor: 'pointer' }}
            data-hook={`actions_menu-${row.vehicle_id}`}
            onClick={e => handleClick(e, row)}
          >
            <MoreVertIcon color='primary' />
          </Box>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            className={classes.menu}
            onClose={() => {
              setTireValues(initialTireValues);
              setAnchorEl(null);
            }}
          >
            <MenuItem
              className={classes.item}
              onClick={() => {
                setIsEditTirePrice(true);
                setOpenCreateModal(true);
              }}
              data-hook='edit-icon'
            >
              <BiPencil size={20} style={{ opacity: 0.5 }} />
              <span>{t('fleetsSection.edit')}</span>
            </MenuItem>
            <MenuItem
              onClick={() => deleteTirePrice(row.price_id)}
              data-hook='delete-icon'
            >
              <BiTrash size={20} style={{ opacity: 0.5 }} />
              <span>{t('fleetsSection.delete')}</span>
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
  const getFiltersTires = async (searchString = '') => {
    let payload = {};
    if (filterBrands.length && searchString) {
      payload = {
        filterValues: {
          brand: filterBrands,
          size: filteredTireSizes,
          product_line: filterProductLines,
        },
        search_string: searchString,
      };
    } else if (filterBrands.length) {
      payload = {
        filter_values: {
          brand: filterBrands,
          size: filteredTireSizes,
          product_line: filterProductLines,
        },
      };
    } else if (searchString) {
      payload = {
        search_string: searchString,
      };
    }
    try {
      setIsLoading(true);
      const res = await getFilterTirePrices(access_token, payload);
      setTireDetails(res.data.resp.tire_price);
      setTotalTires(res.data.resp.count);
      if (res.data.status_code === 200) {
        setOpenFilterMenu(false);
      }
    } catch (error) {
      console.error('Could not fetch Tires');
    } finally {
      setIsLoading(false);
    }
  };
  // eslint-disable-next-line
  const handleSearch = useCallback(
    debounce(async query => {
      getFiltersTires(query);
    }, 1000)
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await perPageTirePrices(0, rowsPerPage);
        if (res.data.status_code === 200) {
          setTireDetails(res.data.resp.tire_price);
          setTotalTires(res.data.resp.count);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [rowsPerPage]);

  const renderFilterMenu = () => {
    return (
      <Menu
        id='simple-menu'
        keepMounted
        open={openFilterMenu}
        className={classes.filterMenu}
        onClose={() => setOpenFilterMenu(prev => !prev)}
        slot={Backdrop}
        sx={{
          '& .MuiPaper-root.MuiMenu-paper': {
            left: '0px !important',
            right: '0px !important',
            margin: '0 auto',
            top: '140px !important',
            maxWidth: '600px',
            padding: '30px 30px 0px 30px',
            boxSizing: 'border-box',
          },
          '& .MuiTypography-root': {
            marginBottom: '10px',
          },
        }}
      >
        <Typography>Select Filters</Typography>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              id='tags-outlined'
              data-hook='select-users-dropdown'
              options={filterValues.brand || []}
              getOptionLabel={option => option}
              filterSelectedOptions
              onChange={(e, value) => {
                setFIlterBrands(value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  style={{ backgroundColor: '#fff' }}
                  variant='outlined'
                  label='Select Brands'
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              id='tags-outlined'
              data-hook='select-users-dropdown'
              options={filterValues.product_line || []}
              getOptionLabel={option => option}
              filterSelectedOptions
              onChange={(e, value) => {
                setFIlteredProductLines(value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  style={{ backgroundColor: '#fff' }}
                  variant='outlined'
                  label='Select Product Lines'
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              id='tags-outlined'
              data-hook='select-users-dropdown'
              options={filterValues.size || []}
              getOptionLabel={option => option}
              filterSelectedOptions
              onChange={(e, value) => {
                setFilteredTireSizes(value);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  style={{ backgroundColor: '#fff' }}
                  variant='outlined'
                  label='Select Tire Size'
                />
              )}
            />
          </Grid>
        </Grid>

        <Box>
          <Button
            color='primary'
            type='submit'
            variant='contained'
            className={classes.confirmBtn}
            fullWidth
            onClick={() => getFiltersTires()}
          >
            {renderSubmitButtonLabel()}
          </Button>
        </Box>
      </Menu>
    );
  };

  const renderFilterSection = () => {
    return (
      <>
        <SearchBar
          dataHook='tire-price-search-bar'
          handleSearch={e => {
            handleSearch(e.target.value);
          }}
          filterCounter={
            filterBrands.length +
            filterProductLines.length +
            filteredTireSizes.length
          }
          setOpen={setOpenFilterMenu}
        />
        {renderFilterMenu()}
      </>
    );
  };

  const paginationHandler = async page => {
    try {
      const res = await perPageTirePrices(page, rowsPerPage);
      if (res.data.status_code === 200) {
        setTireDetails(res.data.resp.tire_price);
        setTotalTires(res.data.resp.count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const valueChangeHandler = ({ target: { name, value } }) => {
    setTireValues(prev => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const renderSubmitButtonLabel = () => {
    if (isSubmitting) {
      return <CircularProgress size={30} color='inherit' />;
    }
    return (
      <Typography variant='h6' align='center'>
        {t('userManagement.confirm')}
      </Typography>
    );
  };

  const getProductLines = (brand, size = null) => {
    let prodLines = [];
    let tireSize = size ? size : tireValues.size;

    if (tiresResource) {
      Object.entries(tiresResource.sorted).forEach(resource => {
        if (resource[1][tireSize][brand]) {
          prodLines = [
            ...prodLines,
            ...Object.keys(resource[1][tireSize][brand]),
          ];

          setLines(prodLines);
        }
      });
    }
  };

  const renderAddPriceModal = () => {
    const addTirePrice = async () => {
      const payload = {
        brand: tireValues.brand,
        product_line: tireValues.product_line,
        price: tireValues.price,
        size: tireValues.size,
      };

      try {
        const res = await createTirePrices(payload);
        if (res.data.status_code === 200) {
          setTest(prev => !prev);
          dispatch(
            setNotification(t('vehiclePage.updateSuccess'), 'success', true)
          );
          setTireValues(initialTireValues);
        } else if (res.data.status_code === 400) {
          setNotification(t(res.return_message), 'error', true);
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setOpenCreateModal(false);
        setAnchorEl(null);
      }
    };

    const editTirePrice = async () => {
      const payload = {
        brand: tireValues.brand,
        product_line: tireValues.product_line,
        price: tireValues.price,
        size: tireValues.size,
      };
      try {
        const res = await updateTirePrices(
          access_token,
          tireValues.price_id,
          payload
        );
        setTest(prev => !prev);
        if (res.status === 200) {
          dispatch(setNotification(res.data.return_message, 'success', true));
          setTireValues({
            brand: '',
            price: 0,
            product_line: '',
            size: '',
          });
        }
      } catch (error) {
        dispatch(setNotification(error.message), 'error', true);
      } finally {
        setIsEditTirePrice(false);
        setOpenCreateModal(false);
        setAnchorEl(null);
      }
    };

    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
          setTireValues(initialTireValues);
          setIsEditTirePrice(false);
        }}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
        data-hook='create-tire-price-modal'
      >
        <Fade in={openCreateModal}>
          <Card className={classes.root}>
            <CardContent>
              <IconButton
                style={{ float: 'right' }}
                onClick={() => {
                  setOpenCreateModal(false);
                  setIsEditTirePrice(false);
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant='h5'
                align='center'
                data-hook='create-tire-price-heading'
                style={{ margin: 10 }}
              >
                {isEditTirePrice
                  ? t('settingsPage.tireSettings.updateTirePrice')
                  : t('settingsPage.tireSettings.createTirePrice')}
              </Typography>
              <form>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <CustomTextField
                      variant='outlined'
                      select
                      label={t('settingsPage.tireSettings.tireSize')}
                      id='size'
                      name='size'
                      data-hook='tire-size-input'
                      value={tireValues.size}
                      onChange={valueChangeHandler}
                      SelectProps={{ IconComponent: () => null }}
                      InputProps={{ endAdornment: <UnfoldMore /> }}
                    >
                      {tireSizes &&
                        tireSizes.map((role, i) => (
                          <MenuItem
                            key={role}
                            value={role}
                            data-hook={`tire-size-${i}`}
                          >
                            {role}
                          </MenuItem>
                        ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      variant='outlined'
                      select
                      label={t('settingsPage.tireSettings.brand')}
                      id='brand'
                      name='brand'
                      data-hook='brand-input'
                      value={tireValues.brand}
                      onChange={e => {
                        valueChangeHandler(e);
                        getProductLines(e.target.value);
                      }}
                      SelectProps={{ IconComponent: () => null }}
                      InputProps={{ endAdornment: <UnfoldMore /> }}
                    >
                      {brands &&
                        brands.map((role, i) => (
                          <MenuItem
                            key={role}
                            value={role}
                            data-hook={`tire-size-${i}`}
                          >
                            {role}
                          </MenuItem>
                        ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      variant='outlined'
                      select
                      label={t('settingsPage.tireSettings.productLine')}
                      id='product_line'
                      name='product_line'
                      data-hook='product-line-input'
                      disabled={!tireValues.size && !tireValues.brand}
                      value={tireValues.product_line}
                      onChange={valueChangeHandler}
                      SelectProps={{ IconComponent: () => null }}
                      InputProps={{ endAdornment: <UnfoldMore /> }}
                    >
                      {lines.length ? (
                        lines.map((role, i) => (
                          <MenuItem
                            key={role}
                            value={role}
                            data-hook={`tire-size-${i}`}
                          >
                            {role}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem key={'no-options'} value={'no-options'}>
                          No Product Lines Available
                        </MenuItem>
                      )}
                    </CustomTextField>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      label={t('settingsPage.tireSettings.tirePrice') + ' (€)'}
                      name={'price'}
                      id={'tire_price'}
                      value={tireValues.price}
                      onChange={valueChangeHandler}
                      variant={'outlined'}
                      data-hook='tire-price-input'
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <Box>
                  <Button
                    color='primary'
                    variant='contained'
                    data-hook='create-user-submit-button'
                    className={classes.confirmBtn}
                    disabled={isSubmitting}
                    fullWidth
                    onClick={() =>
                      isEditTirePrice ? editTirePrice() : addTirePrice()
                    }
                  >
                    {renderSubmitButtonLabel()}
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    );
  };

  return (
    <>
      <Box position={'relative'} zIndex={10}>
        <Box className={classes.container}>
          <Box>{renderFilterSection()}</Box>
          <Button
            style={{
              textTransform: 'unset',
              fontSize: 18,
            }}
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            onClick={() => setOpenCreateModal(true)}
            data-hook='add-tire-price-button'
          >
            {t('settingsPage.tireSettings.addTireLabel')}
          </Button>
        </Box>
        <CustomTable
          data={tireDetails}
          emptyMessage={t('common.noTiresPresent')}
          paginationHandler={paginationHandler}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          total={totalTires}
          freq={totalTires}
          loading={isLoading}
          columns={columns}
          data-hook='tire-price-table'
        />
        {renderAddPriceModal()}
      </Box>
    </>
  );
};

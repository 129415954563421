import React, { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {
  deleteWorkforceQuery,
  fetchWorkforceQuery,
  setAccessTokenSelector,
  setUserSelector,
  workforceSelector,
} from 'redux/users/ducks';
import { useTranslation } from 'react-i18next';
import AssetsWhitelisting from './AssetsWhitelisting';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styles from './UserManagement.module.css';
import { useEffect } from 'react';
import { USER_LS, USER_ROLES } from 'utils/string_utils';
import LoadAnimation from 'components/LoadAnimation';
import { editWorkForceQuery } from 'redux/users/ducks';
import { styled } from '@mui/system';
import { access_type_map } from 'utils/helper';
import { AssignResourcesModal } from './AssetsWhitelisting/BulkUploadModal';
import CustomTextField from '../../../components/custom/CustomTextField';
import { UnfoldMore, Visibility, VisibilityOff } from '@mui/icons-material';
import { inviteUser } from '../../../services/auth_service';
import { setNotification } from '../../../redux/notifications/ducks';

const deleteGeneralTextFont = {
  fontSize: '1.25rem',
  lineHeight: '1.714rem',
};
const useStyles = makeStyles()(theme => {
  return {
    root: {
      minWidth: 500,
      maxWidth: 600,
      padding: '0rem 1rem',
    },
    inviteBtn: {
      textTransform: 'unset',
      fontSize: 18,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    table: {
      marginTop: 20,
    },
    modalHead: {
      opacity: 0.5,
      marginBottom: 20,
    },
    submit: {
      outline: 'none',
      border: 'none',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      maxHeight: 45,
      padding: 10,
      borderRadius: 5,
      fontSize: 18,
      cursor: 'pointer',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&:disabled': { backgroundColor: 'grey' },
      '&:hover': { backgroundColor: '#49741c' },
    },
    closeBtn: {
      cursor: 'pointer',
    },
    assign: {
      textTransform: 'Capitalize',
    },
    deleteGeneralText: {
      fontWeight: 400,
      ...deleteGeneralTextFont,
    },
    username: {
      fontWeight: 900,
      ...deleteGeneralTextFont,
    },
    confirmBtn: {
      margin: '28px 0px',
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: '#f7f9f9',
    color: theme.palette.common.black,
    fontSize: 18,
    fontWeight: 700,
  },
  body: {
    fontSize: 14,
  },
}));

function formatDate(date) {
  return moment(date * 1000).format('dddd Do MMM, YYYY');
}

const SUPER_ADMIN = USER_ROLES.superAdmin;
const ADMIN = USER_ROLES.admin;
const UserManagement = () => {
  const userLs = JSON.parse(USER_LS);
  const { classes } = useStyles();
  const workforce = useSelector(workforceSelector);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [operation, setOperation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const access_token = useSelector(setAccessTokenSelector);
  const [email, setEmail] = useState(null);
  const [isVerified, setIsVerified] = useState(null);
  // const [allStaff, setAllStaff] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const userDetails = useSelector(setUserSelector) || userLs;
  const [isLoading, setIsLoading] = useState(false);
  const [accessType, setAccessType] = useState(null);
  const [editModal, setEditModal] = useState(false);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const isAdmin =
    userDetails?.iam_role === ADMIN || userDetails?.iam_role === SUPER_ADMIN;
  const accessTypes = ['WEB_AND_MOBILE', 'WEB_ONLY', 'MOBILE_ONLY'];
  const roles = [USER_ROLES.service, USER_ROLES.reporter, USER_ROLES.admin];
  const [assign, setAssign] = useState(true);
  const [openUnassign, setOpenUnassign] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formVal, setFormVal] = useState({
    first_name: '',
    last_name: '',
    email: '',
    access_type: '',
    iam_role: '',
    depot: '',
    password: '',
    locale: '',
  });
  const [showWarning, setShowWarning] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    if (openModal) setOpenModal(false);
    if (editModal) setEditModal(false);
    // eslint-disable-next-line
  }, [workforce]);

  useEffect(() => {
    if (access_token && !workforce) {
      dispatch(fetchWorkforceQuery());
    }
    // eslint-disable-next-line
  }, [access_token]);
  useEffect(() => {
    !editModal && setShowWarning(false);
  }, [editModal]);

  const valueChangeHandler = e => {
    setFormVal({
      ...formVal,
      [e.target.name]: e.target.value,
    });
  };

  const accessHandler = (queryMethod, user_id, uName, isAssign) => {
    setOperation(queryMethod);
    setUserId(user_id);
    setUsername(uName);
    setAssign(isAssign);
    if (!isAssign) {
      setOpenUnassign(true);
    } else {
      setOpen(true);
    }
  };

  const sortArray = data => {
    const first = SUPER_ADMIN;
    return data?.sort((x, y) => {
      return x.iam_role === first ? -1 : y.iam_role === first ? 1 : 0;
    });
  };

  const deleteUserHandler = async () => {
    setIsSubmitting(true);
    const payload = { email: email, verified: isVerified };
    try {
      dispatch(deleteWorkforceQuery(payload));
    } catch (error) {
      console.error('E20043', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const editUserHandler = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const payload = { email: email, iam_role: role, access_type: accessType };

    try {
      dispatch(editWorkForceQuery(payload));
    } catch (error) {
      console.error('E20043', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderInviteButton = () => {
    if (isAdmin) {
      return (
        <>
          <Button
            style={{
              minWidth: 160,
              textTransform: 'unset',
              border: `1px solid ${theme.palette.primary.main}`,
              marginRight: 10,
            }}
            color='primary'
            data-hook='bulk-upload-button'
            onClick={() => setOpenBulkUpload(true)}
          >
            {t('vehiclePage.bulkUpload')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            startIcon={<AddIcon />}
            className={classes.inviteBtn}
            onClick={() => setCreateUserModal(true)}
            data-hook='create-user-button'
            id='create-user-button'
          >
            {t('userManagement.inviteBtn')}
          </Button>
        </>
      );
    }
  };

  const renderStyledTableCell = () => {
    if (
      userDetails?.iam_role === ADMIN ||
      userDetails?.iam_role === SUPER_ADMIN
    ) {
      return (
        <>
          <StyledTableCell>{t('userManagement.accessType')}</StyledTableCell>
          <StyledTableCell>{t('userManagement.lastLogin')}</StyledTableCell>
          <StyledTableCell>{t('userManagement.actions')}</StyledTableCell>
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell />
        </>
      );
    }
  };

  const renderLocales = () => {
    const locales = ['en', 'de', 'pl'];
    return locales.map((local, i) => {
      return (
        <MenuItem key={local} value={local} data-hook={`locale-${i}`}>
          {local}
        </MenuItem>
      );
    });
  };

  const showPasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const inviteUserHandler = async e => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await inviteUser(formVal, access_token);
      workforce?.workforceData.filter(x => x.email === formVal.email);
      if (res.status === 200) {
        dispatch(fetchWorkforceQuery());
        setUserId(res?.data?.resp?.user_id);
        setUsername(res?.data?.message?.split(' ')[0]);
        dispatch(
          setNotification('Invitation sent successfully!', 'success', true)
        );
        setCreateUserModal(false);
        if (formVal.iam_role !== USER_ROLES.admin) {
          setOpen(true);
        }
        setFormVal({
          first_name: '',
          last_name: '',
          email: '',
          access_type: '',
          iam_role: '',
          depot: '',
          password: '',
          locale: '',
        });
      } else {
        dispatch(setNotification(res?.message, 'error', true));
      }
    } catch (error) {
      console.error('E20035', error);
      dispatch(
        setNotification('An error occurred. Please try again', 'error', true)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const renderCreateUserModal = () => {
    return (
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={createUserModal}
        onClose={() => setCreateUserModal(false)}
        data-hook='create-user-modal'
        closeAfterTransition
        // slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={createUserModal}>
          <Card className={classes.root}>
            <CardContent>
              <IconButton
                style={{ float: 'right' }}
                onClick={() => setCreateUserModal(false)}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant='h5'
                align='center'
                data-hook='create-user-modal-heading'
              >
                {t('userManagement.inviteBtn')}
              </Typography>
              <form onSubmit={inviteUserHandler}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <CustomTextField
                      label={t('userManagement.firstName')}
                      name={'first_name'}
                      id={'first_name'}
                      value={formVal['first_name']}
                      onChange={valueChangeHandler}
                      required
                      variant={'outlined'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      label={t('userManagement.lastName')}
                      name={'last_name'}
                      id={'last_name'}
                      value={formVal['last_name']}
                      onChange={valueChangeHandler}
                      required
                      variant={'outlined'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      label={t('userManagement.email')}
                      name={'email'}
                      id={'email'}
                      value={formVal['email']}
                      onChange={valueChangeHandler}
                      required
                      type={'email'}
                      variant={'outlined'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} data-hook='iam_role'>
                    <CustomTextField
                      variant='outlined'
                      select
                      label={t('userManagement.role')}
                      id='iam_role'
                      name='iam_role'
                      required
                      value={formVal['iam_role']}
                      onChange={valueChangeHandler}
                      SelectProps={{ IconComponent: () => null }}
                      InputProps={{ endAdornment: <UnfoldMore /> }}
                    >
                      {roles.map((role, i) => (
                        <MenuItem
                          key={role}
                          value={role}
                          data-hook={`role-${i}`}
                        >
                          {role[0].toUpperCase() + role.slice(1)}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item xs={6} data-hook='access-type-select'>
                    <CustomTextField
                      variant='outlined'
                      select
                      label={t('userManagement.accessType')}
                      id='access_type'
                      name='access_type'
                      required
                      value={formVal['access_type']}
                      onChange={valueChangeHandler}
                      SelectProps={{ IconComponent: () => null }}
                      InputProps={{ endAdornment: <UnfoldMore /> }}
                    >
                      {accessTypes.map((item, i) => (
                        <MenuItem
                          key={item}
                          value={item}
                          data-hook={`access-type-${i}`}
                        >
                          {access_type_map[item]}
                        </MenuItem>
                      ))}
                    </CustomTextField>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      label={t('userManagement.depot')}
                      name={'depot'}
                      id={'depot'}
                      value={formVal['depot']}
                      onChange={valueChangeHandler}
                      required
                      variant={'outlined'}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel htmlFor='outlined-adornment-password'>
                        {t('userSettingsPage.password')}
                      </InputLabel>
                      <OutlinedInput
                        id='outlined-adornment-password'
                        margin={'normal'}
                        label={t('userSettingsPage.password')}
                        name={'password'}
                        required
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={showPasswordHandler}
                              edge='end'
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        value={formVal['password']}
                        onChange={valueChangeHandler}
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} data-hook='select-locale'>
                    <CustomTextField
                      label={t('userManagement.locale')}
                      name={'locale'}
                      id={'locale'}
                      select
                      value={formVal['locale']}
                      onChange={valueChangeHandler}
                      required
                      variant={'outlined'}
                      SelectProps={{ IconComponent: () => null }}
                      InputProps={{ endAdornment: <UnfoldMore /> }}
                      fullWidth
                    >
                      {renderLocales()}
                    </CustomTextField>
                  </Grid>
                </Grid>

                <Box>
                  <Button
                    color='primary'
                    type='submit'
                    variant='contained'
                    data-hook='create-user-submit-button'
                    className={classes.confirmBtn}
                    disabled={isSubmitting}
                    fullWidth
                  >
                    {renderSubmitButtonLabel()}
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    );
  };

  const renderAdminTableCells = (user, i = undefined) => {
    if (userDetails?.iam_role === ADMIN && user.iam_role !== SUPER_ADMIN) {
      return (
        <>
          <TableCell>
            {user.login_history?.['time_stamp']?.length
              ? formatDate(
                  user.login_history['time_stamp'][
                    user.login_history['time_stamp']?.length - 1
                  ]
                )
              : 'N/A'}
          </TableCell>
          <TableCell style={{ width: 130 }}>
            <Button
              size='small'
              color='primary'
              variant='outlined'
              style={{
                display:
                  userDetails.first_name + ' ' + userDetails.last_name ===
                  user.name
                    ? 'none'
                    : 'block',
              }}
              className={classes.assign}
              onClick={() =>
                accessHandler('PUT', user?.user_id, user?.name, true)
              }
            >
              {t('userManagement.assignResources')}
            </Button>
          </TableCell>
          <TableCell style={{ width: 130 }}>
            <Button
              size='small'
              variant='outlined'
              style={{
                display:
                  userDetails.first_name + ' ' + userDetails.last_name ===
                  user.name
                    ? 'none'
                    : 'block',
              }}
              className={classes.assign}
              onClick={() =>
                accessHandler('DELETE', user?.user_id, user?.name, false)
              }
            >
              {t('userManagement.deleteResources')}
            </Button>
          </TableCell>
          <TableCell>
            <Tooltip title={t('userManagement.editTooltip')}>
              <Box
                style={{
                  display:
                    userDetails.first_name + ' ' + userDetails.last_name ===
                    user.name
                      ? 'none'
                      : 'block',
                }}
              >
                <IconButton
                  aria-label='delete'
                  color='primary'
                  data-hook={'edit-icon' + i}
                  onClick={() => {
                    setUsername(user?.name);
                    setEmail(user?.email);
                    setIsVerified(user?.verified);
                    setRole(user?.iam_role);
                    setAccessType(user?.access_type);
                    setEditModal(true);
                  }}
                >
                  <EditIcon fontSize='medium' />
                </IconButton>
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title={t('userManagement.deleteTooltip')}>
              <Box
                style={{
                  display:
                    userDetails.first_name + ' ' + userDetails.last_name ===
                    user.name
                      ? 'none'
                      : 'block',
                }}
              >
                <IconButton
                  aria-label='delete'
                  color='secondary'
                  data-hook={'delete-user' + i}
                  onClick={() => {
                    setUsername(user?.name);
                    setEmail(user?.email);
                    setIsVerified(user?.verified);
                    setOpenModal(true);
                  }}
                >
                  <DeleteIcon fontSize='medium' />
                </IconButton>
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    }
  };
  const renderSuperAdminTableCells = (user, i = undefined) => {
    if (userDetails?.iam_role === SUPER_ADMIN) {
      return (
        <>
          <TableCell>
            {user.login_history?.['time_stamp']?.length
              ? formatDate(
                  user.login_history['time_stamp'][
                    user.login_history['time_stamp']?.length - 1
                  ]
                )
              : 'N/A'}
          </TableCell>
          <TableCell style={{ width: 130 }}>
            <Button
              size='small'
              color='primary'
              variant='outlined'
              style={{
                display:
                  userDetails.first_name + ' ' + userDetails.last_name ===
                  user.name
                    ? 'none'
                    : 'block',
              }}
              className={classes.assign}
              onClick={() =>
                accessHandler('PUT', user?.user_id, user?.name, true)
              }
            >
              {t('userManagement.assignResources')}
            </Button>
          </TableCell>
          <TableCell style={{ width: 130 }}>
            <Button
              size='small'
              variant='outlined'
              style={{
                display:
                  userDetails.first_name + ' ' + userDetails.last_name ===
                  user.name
                    ? 'none'
                    : 'block',
              }}
              className={classes.assign}
              onClick={() =>
                accessHandler('DELETE', user?.user_id, user?.name, false)
              }
            >
              {t('userManagement.deleteResources')}
            </Button>
          </TableCell>
          <TableCell>
            <Tooltip title={t('userManagement.editTooltip')}>
              <Box
                style={{
                  display:
                    userDetails.first_name + ' ' + userDetails.last_name ===
                    user.name
                      ? 'none'
                      : 'block',
                }}
              >
                <IconButton
                  aria-label='delete'
                  color='primary'
                  data-hook={'edit-icon' + i}
                  onClick={() => {
                    setUsername(user?.name);
                    setEmail(user?.email);
                    setIsVerified(user?.verified);
                    setRole(user?.iam_role);
                    setAccessType(user?.access_type);
                    setEditModal(true);
                  }}
                >
                  <EditIcon fontSize='medium' />
                </IconButton>
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Tooltip title={t('userManagement.deleteTooltip')}>
              <Box
                style={{
                  display:
                    userDetails.first_name + ' ' + userDetails.last_name ===
                    user.name
                      ? 'none'
                      : 'block',
                }}
              >
                <IconButton
                  aria-label='delete'
                  color='secondary'
                  data-hook={'delete-user' + i}
                  onClick={() => {
                    setUsername(user?.name);
                    setEmail(user?.email);
                    setIsVerified(user?.verified);
                    setOpenModal(true);
                  }}
                >
                  <DeleteIcon fontSize='medium' />
                </IconButton>
              </Box>
            </Tooltip>
          </TableCell>
        </>
      );
    }
  };

  const renderTableCells = () => {
    return sortArray(
      workforce?.workforceData?.filter(x => x.name !== 'CO2OPT BOT')
    )?.map((user, i) => (
      <>
        <TableRow key={i} className={styles.tableRow}>
          <TableCell>{user?.name}</TableCell>
          {(userDetails?.iam_role === ADMIN ||
            userDetails?.iam_role === SUPER_ADMIN) && (
            <TableCell>{user?.email}</TableCell>
          )}
          <TableCell>
            {user?.iam_role?.charAt(0).toUpperCase() + user?.iam_role?.slice(1)}
          </TableCell>
          <TableCell>
            {user.verified
              ? t('userManagement.verified')
              : t('userManagement.pending')}
          </TableCell>
          <TableCell>{access_type_map[user.access_type]}</TableCell>
          {renderAdminTableCells(user, i)}
          {renderSuperAdminTableCells(user, i)}
        </TableRow>
      </>
    ));
  };

  const renderRolesList = () => {
    return roles.map(role => (
      <option key={role} value={role}>
        {role}
      </option>
    ));
  };
  const renderAccessList = () => {
    return accessTypes.map(type => (
      <option key={type} value={type}>
        {access_type_map[type]}
      </option>
    ));
  };

  const renderSubmitButtonLabel = () => {
    if (isSubmitting) {
      return <CircularProgress size={30} color='inherit' />;
    }
    return (
      <Typography variant='h6' align='center'>
        {t('userManagement.confirm')}
      </Typography>
    );
  };

  return (
    <Box className={classes.main}>
      <LoadAnimation loading={isLoading} />
      <Box className={classes.container}>
        {workforce !== null && (
          <Typography>{`${
            workforce?.workforceData.filter(x => x.name !== 'CO2OPT BOT')
              ?.length
          } ${
            workforce?.workforceData.length > 1
              ? t('userManagement.users')
              : t('userManagement.user')
          }`}</Typography>
        )}
        <Box>{renderInviteButton()}</Box>
      </Box>
      <TableContainer className={classes.table} component={Paper}>
        <Table
          aria-label='simple table'
          stickyHeader
          style={{ height: 'max-content' }}
        >
          <TableHead data-hook='user-management-table'>
            <TableRow>
              <StyledTableCell>Name</StyledTableCell>
              {(userDetails?.iam_role === ADMIN ||
                userDetails?.iam_role === SUPER_ADMIN) && (
                <StyledTableCell>E-mail</StyledTableCell>
              )}
              <StyledTableCell>{t('userManagement.role')}</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>

              {renderStyledTableCell()}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableCells()}</TableBody>
        </Table>
      </TableContainer>
      <AssetsWhitelisting
        userId={userId}
        username={username}
        open={openUnassign}
        setOpen={setOpenUnassign}
        method={operation}
        setLoading={setIsLoading}
      />
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={editModal}
        onClose={() => setEditModal(false)}
        closeAfterTransition
        data-hook='edit-user-modal'
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={editModal}>
          <Card className={classes.root}>
            <CardContent>
              <IconButton
                style={{ float: 'right' }}
                onClick={() => setEditModal(false)}
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant='h5'
                align='center'
                data-hook='edit-modal-title'
              >
                {t('userManagement.editRole')}
              </Typography>
              <form onSubmit={editUserHandler}>
                <FormControl
                  fullWidth
                  variant='outlined'
                  className={classes.formControl}
                  style={{ margin: '0.5rem 0rem' }}
                  data-hook='select-role'
                >
                  <InputLabel htmlFor='outlined-age-native-simple'>
                    {t('userManagement.role')}
                  </InputLabel>
                  <Select
                    native
                    value={role}
                    onChange={e => {
                      setRole(e.target.value);
                      if (e.target.value === 'admin') {
                        setShowWarning(true);
                      } else setShowWarning(false);
                    }}
                    label={t('userManagement.role')}
                    inputProps={{
                      name: 'age',
                      id: 'outlined-age-native-simple',
                    }}
                  >
                    {renderRolesList()}
                  </Select>
                </FormControl>
                <Box data-hook='select-access-type'>
                  <FormControl
                    fullWidth
                    variant='outlined'
                    className={classes.formControl}
                    style={{ margin: '0.5rem 0rem' }}
                  >
                    <InputLabel htmlFor='outlined-age-native-simple'>
                      {t('userManagement.accessType')}
                    </InputLabel>

                    <Select
                      native
                      value={accessType}
                      onChange={e => setAccessType(e.target.value)}
                      label={t('userManagement.accessType')}
                      inputProps={{
                        name: t('userManagement.accessType'),
                        id: 'outlined-age-native-simple',
                      }}
                    >
                      {renderAccessList()}
                    </Select>
                  </FormControl>
                </Box>
                {showWarning && (
                  <Box color={'#FF9800'}>
                    all the vehicles and fleets will be whitelisted for the user
                  </Box>
                )}
                <Box>
                  <Button
                    color='primary'
                    type='submit'
                    variant='contained'
                    className={classes.confirmBtn}
                    onClick={editUserHandler}
                    disabled={isSubmitting}
                    fullWidth
                  >
                    {isSubmitting ? (
                      <CircularProgress size={30} color='inherit' />
                    ) : (
                      renderSubmitButtonLabel()
                    )}
                  </Button>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Card className={classes.root}>
            <CardContent>
              <IconButton
                style={{ float: 'right' }}
                onClick={() => setOpenModal(false)}
              >
                <CloseIcon />
              </IconButton>
              <Box>
                <Typography
                  className={classes.deleteGeneralText}
                  gutterBottom
                  align='center'
                  data-hook='delete-modal-content'
                >
                  {t('userManagement.deletePrompt1')}{' '}
                  <span className={classes.username}>{username}</span>
                  {t('userManagement.deletePrompt2')}
                </Typography>
              </Box>
              <Box>
                <Button
                  color='secondary'
                  type='submit'
                  variant='contained'
                  data-hook='delete-user-button'
                  className={classes.confirmBtn}
                  onClick={deleteUserHandler}
                  disabled={isSubmitting}
                  fullWidth
                >
                  {renderSubmitButtonLabel()}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
      {renderCreateUserModal()}
      <AssignResourcesModal
        open={open}
        setOpen={setOpen}
        bulkUpload={false}
        userId={userId}
        userName={username}
        isAssign={assign}
      />
      <AssignResourcesModal
        open={openBulkUpload}
        setOpen={setOpenBulkUpload}
        users={workforce?.workforceData?.filter(
          user => user.name !== 'CO2OPT BOT' && user.iam_role !== 'admin'
        )}
        bulkUpload={true}
        isAssign={assign}
      />
      <LoadAnimation loading={isLoading} setLoading={setIsLoading} />
    </Box>
  );
};

export default UserManagement;

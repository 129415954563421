import React, { useRef } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& .MuiInputLabel-root': {
        position: 'absolute',
        top: 0,
        left: 10,
        transform: 'translate(0, -50%)',
        pointerEvents: 'none',
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontSize: '12px !important',
      },
      '& .MuiInput-root': {
        marginTop: '1.5rem',
      },
      fontSize: '8px',
    },
  };
});

export default function CustomTextField(props) {
  const { classes } = useStyles();
  const myRef = useRef();

  const handleWheel = event => {
    event.preventDefault();
  };
  React.useEffect(() => {
    if (props.type === 'number') {
      const element = myRef.current;
      element.addEventListener('wheel', handleWheel, { passive: false });

      return () => {
        element.removeEventListener('wheel', handleWheel);
      };
    }
  }, [props.type]);

  return (
    <div ref={myRef}>
      <TextField
        className={props.location && classes.root}
        type={props.type}
        variant={props.variant || 'outlined'}
        margin={props.margin || ''}
        required={props.required}
        fullWidth={true || props.fullWidth}
        multiline={props.multiline || false}
        rows={props.rows}
        placeholder={props.placeholder}
        label={props.label}
        name={props.name}
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.default ? null : props.value}
        defaultValue={props.value ? null : props.default}
        disabled={props.disabled}
        error={props.error}
        helperText={props.helperText}
        InputProps={{ style: { fontSize: props.fontSize || 16 } }}
        InputLabelProps={{ style: { fontSize: props.fontSize || 16 } }}
        {...props}
      />
    </div>
  );
}

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getManagedTelematicsVehicles,
  getTelematicsData,
} from 'services/util_services';
import { TelematicsProviderActionTypes } from './ducks';
import { FleetActionTypes } from '../fleets/ducks';

export function* telematicsSaga() {
  yield takeLatest(
    TelematicsProviderActionTypes.FETCH_TELEMATICS_DATA,
    getTelematics
  );
}

function* getTelematics() {
  try {
    const res = yield call(getTelematicsData);
    if (res?.status === 200) {
      yield put({
        type: TelematicsProviderActionTypes.SET_TELEMATICS_DATA,
        payload: {
          telematicsData: res?.data?.resource,
        },
      });
      localStorage.setItem(
        'telematics_info',
        JSON.stringify(res?.data?.resource)
      );
      let telematicsData = res?.data?.resource;
      const temp2 = [];
      let temp3 = [];
      if (telematicsData) {
        if (telematicsData?.length > 0) {
          for (const entry of telematicsData) {
            const t = entry.credentials_token;
            try {
              const response = yield call(getManagedTelematicsVehicles, t);
              if (response?.status === 200) {
                const vehiclesFromTelematics = response?.data?.resource;
                temp2.push({
                  ...vehiclesFromTelematics,
                  protocol: entry?.protocol,
                });
                temp3 = temp3.concat(
                  vehiclesFromTelematics?.registered_and_tracked
                );
              }
            } catch (error) {
              console.error(error);
            }
          }
          yield put({
            type: FleetActionTypes.SET_TRACKED_VEHICLES_IDS,
            payload: temp3,
          });
          yield put({
            type: FleetActionTypes.SET_TRACKED_VEHICLES_INFO,
            payload: temp2,
          });
          localStorage.setItem('tracked', JSON.stringify(temp3));
          localStorage.setItem('trackedVehiclesManager', JSON.stringify(temp2));
        }
      }
    }
  } catch (error) {
    console.error('E20039', error);
  }
}

import React from 'react';
import { Backdrop, Modal } from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import styles from './LoadAnimation.module.css';

const useStyles = makeStyles()(theme => {
  return {
    message: {
      color: theme.palette.primary.alt,
    },
    waveContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dot: {
      background: theme.palette.primary.main,
    },
  };
});

const messages = [
  'This will take a minute, please wait...',
  'Sit back as we process your request',
  'Just a few more moments...',
  `Hold on tight, we're almost there!`,
  `You're almost there!`,
  'This is taking a while, how about you get some coffee?',
  'Almost done...',
];
const LoadAnimation = ({ loading, setLoading, message, useMultiple }) => {
  const { classes } = useStyles();

  const [message_, setMessage] = React.useState(0);

  React.useEffect(() => {
    const handleNextItem = () => {
      setMessage(prevIndex => (prevIndex + 1) % messages.length);
    };

    let interval = useMultiple && setInterval(handleNextItem, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [useMultiple]);

  return (
    <Modal
      className={classes.modal}
      open={loading}
      onClose={() => setLoading(false)}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        height='100%'
        gap={'10px'}
      >
        <Box className={classes.waveContainer}>
          <div
            className={`${styles.circle} ${styles.waveDelay1} ${classes.dot}`}
          />
          <div
            className={`${styles.circle} ${styles.waveDelay2} ${classes.dot}`}
          />
          <div
            className={`${styles.circle} ${styles.waveDelay3} ${classes.dot}`}
          />
          <div
            className={`${styles.circle} ${styles.waveDelay4} ${classes.dot}`}
          />
          <div className={styles.circle} />
        </Box>
        <div className={classes.message}>
          {useMultiple ? messages[message_] : message ?? 'Please Wait...'}
        </div>
      </Box>
    </Modal>
  );
};

export default LoadAnimation;

import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
  Box,
  Typography,
  Button,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
  Grid,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Visibility from '@mui/icons-material/Visibility';

import { useFormik } from 'formik';

import { appStatusSelector, setUserSelector } from 'redux/users/ducks';
import { setToken, setUser } from 'redux/users/ducks';
import { setNotification } from 'redux/notifications/ducks';
import { checkUserExist, Login } from 'services/auth_service';
import { encrypter } from 'utils/Encrypter';
import {
  formValidations,
  loginvalidationSchema,
  passwordComplexityConfig,
} from 'utils/string_utils';

import LanguageSwitcher from 'components/LanguageSwitcher';
import hostedInGermany from 'assets/images/login1.png';
import gdprCompliant from 'assets/images/gdpr.png';
import sslSecured from 'assets/images/ssl.jpg';
import letsEncrypt from 'assets/images/lets-encrypt.png';

import CopyRights from 'components/CopyRights/CopyRights';
import DividerWithText from 'components/Divider/Divider';
import { useTheme } from '@mui/material/styles';

import logo from 'assets/images/app_logo.svg';

import CustomTextField from 'components/custom/CustomTextField';
const trustBadges = [
  { src: sslSecured, alt: 'SSL Secured' },
  { src: gdprCompliant, alt: 'GDPR Complian' },
  { src: hostedInGermany, alt: 'tHosted in Germany' },
  { src: letsEncrypt, alt: "Let's Encrypt Secured" },
];

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& .MuiInputLabel-root': {
        position: 'absolute',
        top: 18,
        transform: 'translate(10px, -50%)',
        pointerEvents: 'none',
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontSize: '12px !important',
      },
      '& .MuiOutlinedInput-root': {
        marginTop: '1.1rem',
      },
    },
    title: {
      fontSize: 14,
    },
    links: {
      cursor: 'pointer',
      fontSize: '0.875rem',
      textDecoration: 'none',
      color: '#3371F2',
      fontWeight: 400,
    },
    primaryLinks: {
      cursor: 'pointer',
      fontSize: '0.875rem',
      textDecoration: 'none',
      color: theme.palette.primary.main,
      fontWeight: 400,
    },

    learnMore: {
      height: '48px',
      width: '140px',
      textTransform: 'unset',
      borderRadius: '8px',
      fontSize: '14px',
      fontWeight: '700',
    },
    testAcctButton: {
      border: '2px solid #000',
      borderRadius: '8px',
      boxShadow: 'none',
      textTransform: 'unset',
      color: '#000',
      fontWeight: 600,
      height: '40px',
      fontSize: '1rem',
    },
    trustBadge: {
      width: '100%',
    },
    loginButton: {
      height: '40px',
      boxShadow: 'none',
      textTransform: 'unset',
      fontSize: '1rem',
      fontWeight: 700,
      borderRadius: '8px',
    },
    loginPrimaryPoster: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      maxHeight: '500px',
      maxWidth: '500px',
    },
    tAndC: { overflow: 'hidden', textOverflow: 'ellipsis' },
    firstGridItem: { marginTop: theme.spacing(-3) },
    container: {
      width: '100vw',
      height: '100%',
      minHeight: '100vh',
      backgroundColor: '#E5E5E5',
      padding: '50px',
      display: 'flex',
    },
    languageSwitcher: {
      position: 'absolute',
      top: 5,
      right: 20,
    },
    content: { display: 'flex', justifyContent: 'center', width: '100%' },
    infoContent: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: theme.spacing(5),
      minHeight: '100%',
      position: 'relative',
      width: '100%',
    },
    formContainer: {
      maxWidth: '600px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      marginTop: '20px',
    },
    formContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      padding: '2rem 2rem',
      borderRadius: 8,
    },
    forgotPasswordContainer: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
    },
    startSavingAction: { width: '120px', marginTop: theme.spacing(2.5) },
    loginMessage: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: theme.spacing(1),
      gridGap: 2,
    },
    buttonAction: {
      width: '100%',
      marginInline: 'auto',
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    copyrights: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(1.5),
    },
    tnc: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      color: 'primary',
      flexWrap: 'wrap',
      columnGap: 5,
    },
    tncContainer: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: '10',
      fontSize: '0.625rem',
      marginTop: theme.spacing(2),
      width: '100%',
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    registerButton: {
      width: '100%',
      marginInline: 'auto',
      marginTop: theme.spacing(1.5),
    },
    poweredBy: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '0.5rem',
      marginTop: '0.5rem',
    },
    testAcctButtonContainer: {
      width: '100%',
      marginLine: 'auto',
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: 'none',
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalCard: {
      maxWidth: 400,
      width: '100%',
    },
    formSpacing: {
      margin: '0.5rem 0rem',
    },
  };
});

const LoginPage = () => {
  const theme = useTheme();
  const { classes } = useStyles();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector(appStatusSelector);
  const user = useSelector(setUserSelector);
  const [showPassword, setShow] = useState(false);
  const [errorMessageKeyword, setErrorMessageKeyword] = useState({
    email: null,
  });
  const [passwordComplexityState, setPasswordComplexityState] = useState(null);

  useEffect(() => {
    if (status) {
      window.location.reload();
    }
  }, [status]);

  const handleBlur = e => {
    if (e.target.name === 'email') {
      const email = formik.values.email;
      if (formValidations.email.test(email)) {
        checkEmailTaken(email);
      } else {
        setErrorMessageKeyword({
          ...errorMessageKeyword,
          email: 'invalid',
        });
      }
    }
  };

  // handleSilentLogin function Deleted

  const checkEmailTaken = async email => {
    const res = await checkUserExist(email);
    if (res?.status === 400) {
      setErrorMessageKeyword({
        ...errorMessageKeyword,
        email: res?.data?.message.includes('registration')
          ? t('loginPage.emailNotRegistered')
          : res?.data?.message,
      });
    } else if (res?.status === 200 && res?.data?.resp === undefined) {
      setErrorMessageKeyword({
        ...errorMessageKeyword,
        email: t('loginPage.emailNotRegistered'),
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginvalidationSchema,
    onSubmit: async values => {
      const res = await Login(values);
      if (res !== undefined) {
        if (res?.status === 200) {
          if (
            res?.data?.user.access_type === 'WEB_AND_MOBILE' ||
            res?.data?.user.access_type === 'WEB_ONLY'
          ) {
            await localStorage.setItem(
              'refresh_token',
              encrypter(res.data.refresh_token)
            );
            await localStorage.setItem(
              'access_token',
              encrypter(res.data.access_token)
            );

            dispatch(setUser(res.data.user, 'login'));
            dispatch(setToken(res.data.access_token));

            localStorage.setItem('user', JSON.stringify(res?.data?.user));

            dispatch(
              setNotification('Successfully Logged in', 'success', true)
            );
            i18next.changeLanguage(res.data?.user?.locale);

            navigate('/home');
          } else {
            dispatch(
              setNotification('Access denied for desktop', 'error', true)
            );
          }
        } else if (res?.status === 500) {
          dispatch(
            setNotification(
              'An error has occurred. Please try again or notify co2opt admin.',
              'error',
              true
            )
          );
        } else {
          dispatch(setNotification(res?.message, 'error', true));
        }
      } else {
        dispatch(
          setNotification(
            'An error has occurred. Please try again.',
            'error',
            true
          )
        );
      }
    },
  });

  useEffect(() => {
    if (user !== null) {
      navigate('/home');
    }
    // eslint-disable-next-line
  }, [user]);

  // handleStorageChange Function Deleted

  const handleClickShowPassword = () => {
    setShow(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    const password = formik.values.password;
    const passwordComplexityConfigKeys = Object.keys(passwordComplexityConfig);
    let temp = {};
    if (password) {
      passwordComplexityConfigKeys.forEach(key => {
        if (key === 'length') {
          password.length >= passwordComplexityConfig[key]['condition']
            ? (temp[key] = true)
            : (temp[key] = false);
        } else {
          passwordComplexityConfig[key]['condition'].test(password)
            ? (temp[key] = true)
            : (temp[key] = false);
        }
      });

      setPasswordComplexityState({ ...temp });
    } else if (!password && passwordComplexityState) {
      passwordComplexityConfigKeys.forEach(key => {
        temp[key] = false;
      });
      setPasswordComplexityState({ ...temp });
    }
    /*eslint-disable-next-line*/
  }, [formik.values.password]);

  useEffect(() => {
    setErrorMessageKeyword({ ...errorMessageKeyword, email: null });
    /*eslint-disable-next-line*/
  }, [formik.values.email]);

  return (
    <Box className={classes.container}>
      <Box className={classes.languageSwitcher}>
        <LanguageSwitcher withoutIcon={true} />
      </Box>

      <Grid container spacing={2} alignItems='center'>
        <Grid item lg={12} md={12} xs={12}>
          <Box
            display='flex'
            width='100%'
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Box className={classes.formContainer}>
              <Box className={classes.formContent}>
                <img src={theme.logo} width={200} alt='logo' />

                <form onSubmit={formik.handleSubmit}>
                  <CustomTextField
                    margin={'normal'}
                    label={'Email'}
                    name={'email'}
                    id={'email'}
                    required
                    type={'email'}
                    variant={'outlined'}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={
                      (formik.touched.email && Boolean(formik.errors.email)) ||
                      errorMessageKeyword.email
                    }
                    helperText={
                      errorMessageKeyword.email
                        ? errorMessageKeyword.email
                        : formik.touched.email && formik.errors.email
                    }
                    fullWidth
                    onBlur={handleBlur}
                    location='login'
                  />
                  <FormControl
                    fullWidth
                    variant='outlined'
                    className={classes.root}
                  >
                    <InputLabel htmlFor='outlined-adornment-password'>
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id='outlined-adornment-password'
                      margin={'normal'}
                      label={'Password'}
                      name={'password'}
                      required
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      fullWidth
                    />
                  </FormControl>

                  <Box className={classes.forgotPasswordContainer}>
                    <Typography variant='subtitle2'>
                      <Link
                        to='/user/forgot-password'
                        className={classes.links}
                      >
                        <span>{t('loginPage.forgotPassword')}</span>
                      </Link>
                    </Typography>
                  </Box>
                  <Box className={classes.loginMessage}>
                    <span>
                      <Typography variant='h6' component='span'>
                        {t('loginPage.loginInAgreeMessageInitial')} <TnCLink />{' '}
                        {t('common.and')} <PrivacyPolicyLink />
                      </Typography>
                    </span>
                  </Box>
                  <Box className={classes.buttonAction}>
                    <Button
                      color={'primary'}
                      type={'submit'}
                      variant={'contained'}
                      className={classes.loginButton}
                      fullWidth
                    >
                      {t('loginPage.login')}
                    </Button>
                  </Box>
                  <Box className={classes.divider}>
                    <DividerWithText label={t('loginPage.or')} />
                  </Box>
                  <Box className={classes.registerButton}>
                    <a
                      href={process.env.REACT_APP_ONBOARDING_URL}
                      target='_blank'
                      className={classes.link}
                      rel='noreferrer'
                    >
                      <Button
                        color={'primary'}
                        variant={'contained'}
                        className={classes.loginButton}
                        fullWidth
                      >
                        {t('loginPage.register')}
                      </Button>
                    </a>
                  </Box>
                  <Box className={classes.divider}>
                    <DividerWithText label={t('loginPage.or')} />
                  </Box>
                  <Box className={classes.testAcctButtonContainer}>
                    <a
                      href={process.env.REACT_APP_REDIRECT_SCHEDULE_MEETING}
                      target='_blank'
                      className={classes.link}
                      rel='noreferrer'
                    >
                      <Button
                        color={'primary'}
                        className={classes.testAcctButton}
                        fullWidth
                      >
                        {t('loginPage.demo')}
                      </Button>
                    </a>
                  </Box>
                  <Grid
                    container
                    spacing={1}
                    alignItems={'center'}
                    justifyContent='center'
                  >
                    {trustBadges.map(({ src, alt }, i) => (
                      <Grid item sm={3}>
                        <img
                          src={src}
                          className={classes.trustBadge}
                          alt={alt}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </form>

                {process.env.REACT_APP_THEME !== undefined ? (
                  process.env.REACT_APP_THEME !== 'default' ? (
                    <Box className={classes.poweredBy}>
                      <Typography variant='h6'>Powered by &nbsp;</Typography>

                      <img src={logo} style={{ width: 150 }} alt='logo' />
                    </Box>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )}
                <Box className={classes.tncContainer}>
                  <Box className={classes.tnc}>
                    <ImprintLink />
                    <TnCLink />

                    <PrivacyPolicyLink />
                  </Box>

                  <Box className={classes.copyrights}>
                    <CopyRights fontSize={'0.875rem'} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export const PrivacyPolicyLink = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <a
      href={
        i18next.language.includes('de')
          ? process.env.REACT_APP_PRIVACY_POLICY_DE_URL
          : process.env.REACT_APP_PRIVACY_POLICY_EN_URL
      }
      target='_blank'
      className={classes.primaryLinks}
      rel='noreferrer'
    >
      {t('common.privacyPolicy')}
    </a>
  );
};

export const TnCLink = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <a
      href={
        i18next.language.includes('de')
          ? process.env.REACT_APP_TNC_DE_URL
          : process.env.REACT_APP_TNC_EN_URL
      }
      target='_blank'
      className={classes.primaryLinks}
      rel='noreferrer'
    >
      {t('common.tnc')}
    </a>
  );
};

export const ImprintLink = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <a
      href={
        i18next.language.includes('de')
          ? process.env.REACT_APP_IMPRINT_DE_URL
          : process.env.REACT_APP_IMPRINT_EN_URL
      }
      target='_blank'
      className={classes.primaryLinks}
      rel='noreferrer'
    >
      {t('common.imprint')}
    </a>
  );
};

export default LoginPage;

import { dropzoneApi } from '../../../axios.config';

export const UploadFile = (files, customer, token) => {
  return dropzoneApi
    .post(``, files, { params: { vehicle_id: customer.vehicle.id } })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
      if (err.response) {
        return { status: err.response.status };
      }
      return err.response;
    });
};

export const FetchFile = async customer => {
  return await dropzoneApi
    .get(``, { params: { vehicle_id: customer.vehicle.id } })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
    });
};

export const deleteFile = item => {
  return dropzoneApi
    .delete('', {
      data: item,
    })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
    });
};

import React from 'react';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { fleetOverviewSelector } from 'redux/fleets/ducks';
import { VEHICLE } from 'utils/string_utils';

const useStyles = makeStyles()(theme => {
  return {
    btn: { textTransform: 'capitalize' },
  };
});

export function SelectAllBtn(props) {
  const {
    setTruck,
    setTrailer,
    setBus,
    selectedTrucksStatic,
    selectedTrailersStatic,
    selectedBusesStatic,
    fleet_id,
    resource,
    userHasVehicles,
    method,
    vehicles,
    setVehicles,
    selectedVehiclesStatic,
  } = props;
  const { classes } = useStyles();
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const [isSelectMode, setIsSelectMode] = React.useState(true);

  const resourceMap = {
    [VEHICLE.vehicle_type.TRUCKS]: selectedTrucksStatic,
    [VEHICLE.vehicle_type.TRAILERS]: selectedTrailersStatic,
    [VEHICLE.vehicle_type.BUSES]: selectedBusesStatic,
  };
  const getFleetName = fleetId => {
    const found = fleets.find(fleet => fleet.fleet_id === fleetId);

    if (found) {
      return found.fleet_name;
    }
  };

  const addSelectedVehicles = (resource, fleetId) => {
    switch (resource) {
      case VEHICLE.vehicle_type.TRUCKS:
        setTruck(latest => [...latest, ...selectedTrucksStatic[fleetId]]);
        break;
      case VEHICLE.vehicle_type.BUSES:
        setBus(latest => [...latest, ...selectedBusesStatic[fleetId]]);
        break;
      case 'all':
        setVehicles(latest => [
          ...latest,
          ...selectedVehiclesStatic[getFleetName(fleetId)],
        ]);
        break;
      default:
        setTrailer(latest => [...latest, ...selectedTrailersStatic[fleetId]]);
        break;
    }
  };

  const removeSelectedVehicles = (resource, fleetId) => {
    switch (resource) {
      case VEHICLE.vehicle_type.TRUCKS:
        setTruck(latest => latest.filter(truck => truck.fleet_id !== fleetId));
        break;
      case VEHICLE.vehicle_type.BUSES:
        setBus(latest => latest.filter(bus => bus.fleet_id !== fleetId));
        break;
      case 'all':
        setVehicles(latest =>
          latest.filter(vehicle => vehicle.fleet_id !== fleetId)
        );
        break;
      default:
        setTrailer(latest =>
          latest.filter(trailer => trailer.fleet_id !== fleetId)
        );
        break;
    }
  };

  const selectAllHandler = fleet_id => {
    if (isSelectMode) {
      addSelectedVehicles(resource, fleet_id);
    } else {
      removeSelectedVehicles(resource, fleet_id);
    }
    setIsSelectMode(!isSelectMode);
  };

  const renderSelectAllButton = () => {
    if (method === 'PUT' && vehicles?.length > 0) {
      return (
        <Button
          className={classes.btn}
          onClick={() => selectAllHandler(fleet_id)}
          data-hook='selectAll'
        >
          {`${isSelectMode ? 'Select' : 'Deselect'} All`}
        </Button>
      );
    } else if (
      method !== 'PUT' &&
      !userHasVehicles(resourceMap[resource], fleet_id)
    ) {
      return (
        <Button
          className={classes.btn}
          onClick={() => selectAllHandler(fleet_id)}
          data-hook='selectAll'
        >
          {`${isSelectMode ? 'Select' : 'Deselect'} All`}
        </Button>
      );
    }
  };

  return <>{renderSelectAllButton()}</>;
}

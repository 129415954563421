import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useParams } from 'react-router-dom';

import {
  Tooltip,
  Button,
  useTheme,
  Chip,
  Breadcrumbs,
  Typography,
  Menu,
  MenuItem,
  Checkbox,
  IconButton,
  Modal,
  FormControl,
  InputLabel,
  Select,
  Fade,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';
import Box from '@mui/system/Box';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';
import {
  setTour,
  setTourSelector,
  setUserSelector,
  tableauDataSelector,
  updateGuideSelection,
} from 'redux/users/ducks';
import { setAccessTokenSelector } from 'redux/users/ducks';
import {
  allTrackedVehiclesSelector,
  setTrackedVehiclesIdsSelector,
  vehicleUpdatedSelector,
} from 'redux/fleets/ducks';

import {
  getFilteredFleetVehicles,
  getVehiclesForFleet,
  moveVehicles,
} from 'services/fleet_service';
import Loading from 'components/Loading';
import { fleetOverviewSelector } from 'redux/fleets/ducks';
import { getFleetChangelogData } from 'services/util_services';
import { currentDate, renderStatus } from 'utils/helper';
import Downloads from 'components/Downloads';

import {
  TRACKED_LS,
  TRAILER_HEADERS,
  TRUCK_AND_BUSES_HEADERS,
  USER_LS,
  USER_ROLES,
  VEHICLE,
} from 'utils/string_utils';
import { parse } from 'json2csv';

import { boolean } from 'yup';
import DeleteModal from './DeleteModal';
import TourContent from 'components/Tour/Content';
import Tour from 'reactour';
import { FaFileExport } from 'react-icons/fa';
import { BsDownload } from 'react-icons/bs';
import { Add, Close } from '@mui/icons-material';
import { ReactComponent as Truck } from 'assets/svg/common/truckIcon.svg';
import { ReactComponent as Trailer } from 'assets/svg/common/trailerIcon.svg';
import { ReactComponent as Bus } from 'assets/svg/common/busLogo.svg';
import { ReactComponent as Delete } from 'assets/svg/common/deleteIcon.svg';
import VehicleUploads from './VehicleUploads';
import { BiPencil, BiTrash } from 'react-icons/bi';
import VehicleEditModal from '../VehiclePage/VehicleEditModal';
import VehicleDeleteModal from '../VehiclePage/VehicleDeleteModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FIlterDrawer from 'components/FIlterDrawer';
import SearchBar from 'components/custom/Search';
import { processData } from '../MultiLayeredReport/AllReports';
import { setNotification } from 'redux/notifications/ducks';
import ColumnsEditDrawer from 'components/ColumnsEditDrawer';
import CustomTable from 'components/custom/CustomTableTemp';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    fullWidth: { width: '100%' },
    card: {
      height: '100%',
      width: '100%',
      boxShadow: theme.palette.boxShadow,
    },
    download: {
      textTransform: 'unset',
      color: theme.palette.primary.main,
      fontSize: '1rem',
      fontWeight: 500,
    },
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 10,
    },
    flexBox: { display: 'flex' },
    deleteIcon: { display: 'flex', alignItems: 'center', marginTop: -45 },
    contentItems: { width: '100%', marginBottom: '10px' },
    formContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '15px',
      width: '100%',
      position: 'relative',
    },

    formContent: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'wrap',
    },
    fab: {
      height: '40px',
      width: '40px',
      backgroundColor: theme.palette.primary.main,
      textTransform: 'unset',
      margin: '1rem 0.25rem',
      padding: '1rem 0.5rem',
      position: 'absolute',
      right: 'calc(50% - 20px)',
      borderRadius: '50%',
      bottom: '-105px',
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
    noVehicles: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    untracked: { backgroundColor: '#6c5b7b', color: '#fff' },
    tracked: { backgroundColor: theme.palette.primary.main, color: '#fff' },
    loader: {
      width: '70vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    link: {
      textDecoration: 'none',
      marginLeft: 10,
    },
    btn: {
      textTransform: 'unset',
      // minWidth: 160,
      fontSize: 10,
    },
    nav: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    btns: {
      borderRadius: 24,
      textTransform: 'unset',
      minWidth: 146,
      maxWidth: 200,
      height: 40,
      fontSize: '16px',
      lineHeight: '16px',
    },
    icons: {
      cursor: 'pointer',
    },
    xlsx: {
      height: 30,
      width: 30,
    },
    paper: {
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 10,
      padding: 20,
    },
    deletePaper: {
      width: 650,
      padding: '64px 98px 64px 98px',
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    title: {
      fontWeight: 900,
    },
    excelBtn: {
      alignSelf: 'flex-start',
      height: 56,
    },
    cancel: {
      borderRadius: 24,
      textTransform: 'unset',
      width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      width: 355,
      border: '1px solid #D1D5DB',
      borderRadius: 8,
    },
    iconStyles: {
      height: 40,
      width: 40,
    },
    item: {
      display: 'flex',
      gap: 15,
    },
    columnBox: {
      background: 'grey',
      borderRadius: '8px 8px 32px 8px',
      padding: '5px',
      color: 'white',
      fontSize: 12,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

const StyledTableCell = withStyles(TableCell, (theme, props) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 18,
  },
  body: {
    fontSize: 22,
  },
}));

const operate_map = {
  truck: VEHICLE.vehicle_type.TRUCKS,
  bus: VEHICLE.vehicle_type.BUSES,
  trailer: VEHICLE.vehicle_type.TRAILERS,
};
const init = [
  {
    key: 'name',
  },
  {
    key: 'reg_plate',
  },
  {
    key: 'vehicle_type',
  },
  {
    key: 'telematicsProvider',
  },
  {
    key: 'axle_type',
  },
  {
    key: 'internal_grouping',
  },
  {
    key: 'configStatus',
  },
  {
    key: 'trackStatus',
  },
];

const FleetsSection = () => {
  const selectedFiltersLS = localStorage.getItem('selectedFilters');
  const filterParametersLS = localStorage.getItem('filterParameters');
  const { classes } = useStyles();

  const userLs = JSON.parse(USER_LS);
  const [loading, setLoading] = useState(true);
  const isTour = useSelector(setTourSelector);
  const theme = useTheme();
  const availableTableauData = useSelector(tableauDataSelector);
  const user = useSelector(setUserSelector);
  const access_token = useSelector(setAccessTokenSelector);
  // eslint-disable-next-line
  const [fleet, setFleet] = useState({});
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const { t } = useTranslation();

  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const params = useParams();
  const { fleet_id } = params;
  const dispatch = useDispatch();
  const trackedVehicles = useSelector(setTrackedVehiclesIdsSelector);
  // eslint-disable-next-line
  const [tracked, setTracked] = useState([]);
  const overviewData = useSelector(fleetOverviewSelector);
  const [fleetsOperated, setFleetsOperated] = useState([]);
  // eslint-disable-next-line
  const [isVehicleUplaod, setIsVehicleUpload] = useState(false);

  const trackedLs = TRACKED_LS;
  const [filterOptions, setFilterOptions] = useState(init);
  // eslint-disable-next-line
  const [filterOptionsStatic, setFilterOptionsStatic] = useState(init);
  const [selectedFilters, setSelectedFilters] = useState(
    JSON.parse(selectedFiltersLS) || []
  );
  const [filterParameters, setFilterParameters] = useState(
    JSON.parse(filterParametersLS) || {}
  );
  const [allVehicles, setAllVehicles] = useState(null);
  const [isFetchingAllVehicles, setIsFetchingAllVehicles] = useState(false);
  const [flag, setFlag] = useState(false);
  const guideSelectionSelector = useSelector(
    state => state.users.guideSelection
  );
  const [showTour, setShowTour] = useState(
    guideSelectionSelector === 'upload_vehicles' ? true : false
  );
  const styling = {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  };
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);

  useEffect(() => {
    const timeout = setTimeout(() => setFlag(true), 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(updateGuideSelection(null));
    };
  }, [dispatch]);

  useEffect(() => {
    setTracked(trackedVehicles || trackedLs);
    // eslint-disable-next-line
  }, [trackedVehicles]);
  useEffect(
    function callAllVehicles() {
      const fetchAllVehicles = async () => {
        setIsFetchingAllVehicles(true);
        try {
          const response = await getVehiclesForFleet(
            { fleet_id },
            access_token,
            true,
            0,
            10
          );
          if (response.status === 200) {
            const vehicles = response.data.resp.vehicles;
            vehicles && setAllVehicles(vehicles);
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsFetchingAllVehicles(false);
        }
      };
      if (open && !allVehicles) {
        fetchAllVehicles();
      }
    },
    // eslint-disable-next-line
    [open]
  );

  // eslint-disable-next-line
  const [choice, setChoice] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const userDetails = useSelector(setUserSelector) || userLs;

  let managedVehicles = useSelector(allTrackedVehiclesSelector);

  const userIsReporter = userDetails?.iam_role === USER_ROLES.reporter;

  const [showBtn, setShowBtn] = useState({ found: false, data: null });
  const NA = t('common.na');
  const [currentPage, setCurrentPage] = useState(0);
  const NAComponent = ({ val, type }) => {
    if (type === boolean) return <span>{!!val}</span>;
    return <span>{val || NA}</span>;
  };
  useEffect(() => {
    const foundFleet = fleets?.find(fleet => fleet.fleet_id === fleet_id);

    if (foundFleet) {
      setFleet(foundFleet);
    }
  }, [fleet_id, fleets]);

  const [tableData, setTableData] = useState([]);
  const [tableDataStatic, setTableDataStatic] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [vehicleType, setVehicleType] = useState('');
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);

  useEffect(() => {
    if (overviewData !== null) {
      let temp = overviewData.general_configs;
      let operate_arr = [];
      for (let i = 0; i < Object.values(temp).length; i++) {
        if (Object.values(temp)[i] !== null) {
          operate_arr.push(operate_map[Object.keys(temp)[i]]);
        }
      }
      setFleetsOperated(operate_arr);
    }
  }, [overviewData]);

  useEffect(() => {
    const check = availableTableauData?.filter(
      item => item.fleet_id === fleet_id
    );

    if (check?.length) {
      setShowBtn({ found: true, data: check });
    }
  }, [fleet_id, availableTableauData]);

  const fetchFilteredVehicles = async payload => {
    try {
      const res = await getVehiclesForFleet(
        {
          ...payload,
          fleet_id: params.fleet_id,
        },
        access_token,
        false,
        currentPage,
        rowsPerPage
      );
      if (res.data.resp) {
        // setTableData(res.data.resp);
        const foundVehicles = res.data.resp.vehicles;
        const mapped = foundVehicles.map(vehicle => {
          vehicle.mounted_tires = vehicle.mounted_tires.length;
          return vehicle;
        });
        setTableData({ vehicles: mapped, count: res.data.resp.count });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchVehiclesFromFilters = async () => {
    const payload = {};

    if (Object.keys(filterParameters).length > 0) {
      payload.filter_values = filterParameters;
    }

    if (searchQuery.length > 0) {
      payload.search_string = searchQuery;
    }

    if (Object.keys(payload).length > 0) {
      fetchFilteredVehicles(payload);
    } else {
      fetchFleetVehicle(true);
    }
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchVehiclesFromFilters();
    }, 500);

    return () => clearTimeout(getData);
    /*eslint-disable-next-line*/
  }, [searchQuery]);

  const vehicleTypeImageMapping = {
    [VEHICLE.vehicle_type.BUS1]: <Bus stroke={theme.palette.primary.main} />,
    [VEHICLE.vehicle_type.TRAILER1]: (
      <Trailer
        className={classes.iconStyles}
        fill={theme.palette.primary.main}
      />
    ),
    [VEHICLE.vehicle_type.TRUCK1]: (
      <Truck className={classes.iconStyles} fill={theme.palette.primary.main} />
    ),
  };

  useEffect(() => {
    if (fleet_id !== undefined && Object.keys(fleet).length && access_token) {
      fetchFleetVehicle(true);
    }
    /*eslint-disable-next-line*/
  }, [
    fleet_id,
    // fleetTracker,
    // fleet,
    // vehilcesForFleetData?.[fleet_id],
    // tracked,
    managedVehicles,
    vehicleDataHasChanged,
  ]);

  async function fetchFleetVehicle(isUpdate, page) {
    try {
      // let res = {};
      // if (
      //   vehilcesForFleetData?.[fleet_id] &&
      //   vehilcesForFleetData?.[fleet_id]?.status === 200 &&
      //   !isVehicleUplaod &&
      //   isUpdate
      // ) {
      //   res = JSON.parse(JSON.stringify(vehilcesForFleetData[fleet_id]));
      // } else {
      //   res = await getVehiclesForFleet(
      //     {
      //       fleet_id: fleet_id,
      //       ...(searchQuery.length > 0 && { search_string: searchQuery }),
      //     },
      //     access_token,
      //     false,
      //     currentPage,
      //     rowsPerPage
      //   );
      //   dispatch(setVehiclesForFleet(fleet_id, res));
      // }
      const res = await getVehiclesForFleet(
        {
          fleet_id: fleet_id,
          ...(searchQuery.length > 0 && { search_string: searchQuery }),
          ...(Object.keys(filterParameters).length > 0 && {
            filter_values: filterParameters,
          }),
        },
        access_token,
        false,
        page ?? currentPage,
        rowsPerPage
      );
      const data = res?.data?.resp;

      if (data) {
        const foundVehicles = data.vehicles;
        const mapped = foundVehicles.map(vehicle => {
          vehicle.mounted_tires =
            typeof vehicle.mounted_tires === 'number'
              ? vehicle.mounted_tires
              : vehicle.mounted_tires.length;
          return vehicle;
        });
        setTableData({ vehicles: mapped, count: data.count });
        setTableDataStatic({ vehicles: mapped, count: data.count });
      }
      return res.status;
    } catch (err) {
      console.error('E20010', err);
    } finally {
      setLoading(false);
      setLoadingData(false);
    }
  }

  const processTireSize = size => {
    if (size.drive_tire_size || size.steer_tire_size) {
      return size.steer_tire_size + ' ' + (size.drive_tire_size || '');
    }
    return size.tire_size ?? 'N/A';
  };

  const fetchFilterOptions = async () => {
    try {
      const res = await getFilteredFleetVehicles(fleet_id);
      let filter_options = res?.data?.resp;

      setFilterOptions(filter_options);

      // setSelectedFilters([]);
      // setFilterParameters({});
      // setFilterCounter(0);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (access_token && Object.keys(tableDataStatic).length) {
      fetchFilterOptions();
    }
    // eslint-disable-next-line
  }, [t, tableDataStatic]);

  const CSVToJSON = (data, delimiter = ',') => {
    const titles = data.slice(0, data.indexOf('\n')).split(delimiter);
    return data
      .slice(data.indexOf('\n') + 1)
      .split('\n')
      .map(v => {
        const values = v.split(delimiter);
        return titles.reduce(
          // eslint-disable-next-line
          (obj, title, index) => ((obj[title] = values[index]), obj),
          {}
        );
      });
  };

  const downloadChangelogHandler = async fileType => {
    try {
      let res = await getFleetChangelogData(access_token, fleet_id);
      const results = CSVToJSON(res?.data);
      if (
        Object.entries(results[0])[0][0] !== Object.entries(results[0])[0][1]
      ) {
        let keys = Object.keys(results[0]);
        const empty_cols = [];
        for (let i = 0; i < keys.length; i++) {
          let flag = 0;
          for (let j = 0; j < results.length; j++) {
            if (results[j][keys[i]] !== '') {
              flag = 1;
              break;
            }
          }
          if (flag) {
            continue;
          } else {
            empty_cols.push(keys[i]);
          }
        }
        if (empty_cols.length > 0) {
          for (let i = 0; i < results.length; i++) {
            for (let j = 0; j < empty_cols.length; j++) {
              delete results[i][empty_cols[j]];
            }
          }
        }

        const csv = parse(results, Object.keys(results[results.length - 1]));
        if (res.status === 200) {
          if (fileType === 'csv') {
            const blob = new Blob([csv], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fleet.fleet_name}_changelog.csv`;
            a.click();
          } else {
            const thisData = processData(csv);
            const ws = XLSX.utils.json_to_sheet(thisData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
            XLSX.writeFile(workbook, `Fleet_${fleet_id}_changelog.xlsx`);
          }
        }
      } else {
        dispatch(
          setNotification(
            'No changes have been made on this fleet',
            'error',
            true
          )
        );
      }
    } catch (error) {
      console.error('E20033', error);
    }
    setOpenDialog(false);
  };

  const fleets_page = 'fleets';

  const steps = [
    {
      selector: '#fleets-page-table',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.fleet.table')}
          finalAction='Finish'
          page={fleets_page}
          goTo={() => {
            dispatch(setTour(false));
          }}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
  ];

  const handleDataFormatForExcel = data => {
    let rows = data.map(row => ({
      [t('fleetsSection.vehicleName')]: row.name || NA,
      [t('fleetsSection.regNumber')]: row.reg_plate || NA,
      [t('homePage.vehicleType')]: row.vehicleType || NA,
      [t('fleetsSection.telematics')]: row.telematicsProvider || NA,
      [t('common.axelTypes')]: row.axle_type || NA,
      [t('fleetsSection.internalGrouping')]: row.internal_grouping || NA,
      [t('common.tireInventory.size')]: row.tire_size || NA,
      [t('vehiclePage.tireConfig')]: row.configStatus || NA,
      [t('fleetsSection.tracked')]: row.trackStatus || NA,
      [t('fleetsSection.mountedTires')]: row.tires_mounted.length || 0,
      [t('common.mergedTrailer')]: row.linked_trailer || NA,
    }));

    if (!overviewData.general_configs?.truck) {
      rows = rows.map(obj => {
        delete obj[t('common.mergedTrailer')];
        return obj;
      });
    }

    return rows;
  };
  const today = currentDate();

  const downloadExcel = () => {
    let rows = tableData;
    if (handleDataFormatForExcel) {
      rows = handleDataFormatForExcel(tableData);
    }
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    XLSX.writeFile(workbook, `${fleet.fleet_name}_${today}_.xlsx`);
  };

  const [deleteList, setDeleteList] = useState({});
  const [selectedFleetsWithFleetId, setSelectedFleetsWithFleetId] = useState(
    {}
  );

  useEffect(() => {
    const newObject = fleets?.reduce((result, item) => {
      result[item.fleet_name] = fleet_id === item.fleet_id ? true : false;
      if (item.fleet_id === fleet_id) {
        const newObj = { [item.fleet_name]: fleet_id };
        setSelectedFleetsWithFleetId({
          ...selectedFleetsWithFleetId,
          ...newObj,
        });
      }
      return result;
    }, {});

    setDeleteList(newObject);

    // eslint-disable-next-line
  }, [fleets, fleet_id]);

  const handleCheckChange = event => {
    setDeleteList({ ...deleteList, [event.target.name]: event.target.checked });
    let temp = selectedFleetsWithFleetId;
    if (event.target.checked) {
      let fleet = fleets.filter(
        vehicle => vehicle.fleet_name === event.target.name
      )?.[0].fleet_id;
      if (selectedFleetsWithFleetId[event.target.name] === undefined) {
        temp[event.target.name] = fleet;
      }
    } else {
      delete temp[event.target.name];
    }
    setSelectedFleetsWithFleetId({ ...selectedFleetsWithFleetId, ...temp });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditSelected, setIsEditSelected] = useState(false);
  const [isDeleteSelected, setIsDeleteSelected] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [openDeleteFleetModal, setOpenDeleteFleetModal] = useState(false);
  const [filterCounter, setFilterCounter] = useState(
    selectedFilters?.length || 0
  );
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setVehicle(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteModalCloseHandler = () => {
    setSelectedFleetsWithFleetId({});
    const newObject = fleets.reduce((result, item) => {
      result[item.fleet_name] = false;
      return result;
    }, {});

    setDeleteList(newObject);
    setOpenDeleteFleetModal(false);
  };

  const itemClickHandler = index => {
    if (index === 1) {
      setIsEditSelected(true);
    } else {
      setIsDeleteSelected(true);
    }
    handleClose();
  };

  const itemsToShow = [
    'more',
    'name',
    'reg_plate',
    'vehicle_type',
    'telematicsProvider',
    'axle_type',
    'internal_grouping',
    'actions',
  ];

  const actions = {
    key: 'actions',
    title: t('tireInventoryItems.titles.actions'),
    canSort: false,
    dataHook: 'actions',
    render: (row, i) => (
      <>
        <Box
          sx={{ cursor: 'pointer' }}
          data-hook={`actions_menu-${row.vehicle_id}`}
          onClick={e => handleClick(e, row)}
        >
          <MoreVertIcon color='primary' />
        </Box>
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          className={classes.menu}
          onClose={handleClose}
        >
          <MenuItem
            className={classes.item}
            onClick={() => itemClickHandler(1)}
            data-hook='edit-icon'
          >
            <BiPencil size={20} style={{ opacity: 0.5 }} />
            <span>{t('fleetsSection.edit')}</span>
          </MenuItem>
          <MenuItem
            className={classes.item}
            onClick={() => itemClickHandler(2)}
            data-hook='delete-icon'
          >
            <BiTrash size={20} style={{ opacity: 0.5 }} />
            <span>{t('fleetsSection.delete')}</span>
          </MenuItem>
        </Menu>
      </>
    ),
  };
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedFleet, setSelectedFleet] = useState(null);
  const [rows, setRows] = useState(null);

  const columns = [
    {
      key: 'more',
      title: t('tireConfigPage.more'),
      canSort: false,
      dataHook: 'more',
    },
    {
      key: 'name',
      title: t('fleetsSection.vehicleName'),
      dataHook: 'vehicle-name',
      render: row => (
        <Link
          data-hook={`navigate_to_${row.vehicle_id}`}
          color='primary'
          to={`/fleets-data/${row.fleet_id}/${row.vehicle_type.toLowerCase()}${
            row.vehicle_type.toLowerCase() === 'bus' ? 'es' : 's'
          }/${row.vehicle_id}`}
          className={classes.nav}
        >
          {row.name} {renderStatus(row?.metadata?.interchange?.status)}
        </Link>
      ),
      canSort: true,
    },
    {
      key: 'reg_plate',
      title: t('fleetsSection.regNumber'),
      canSort: true,
      dataHook: 'reg-number',
    },
    {
      key: 'vehicle_type',
      title: t('homePage.vehicleType'),
      canSort: true,
      render: row => vehicleTypeImageMapping[row.vehicle_type],
      dataHook: 'vehicle-type',
    },
    {
      key: 'telematicsProvider',
      title: t('fleetsSection.telematics'),
      canSort: true,
      render: row => <NAComponent val={row.telematics_protocol} />,
      dataHook: 'telematics',
    },
    {
      key: 'axle_type',
      title: t('common.axelTypes'),
      canSort: true,
      render: row => (
        <NAComponent
          val={
            row.vehicle_type === 'trailer'
              ? t(`axles.${row.axle_type}`)
              : row.axle_type
          }
        />
      ),
      dataHook: 'axle-types',
    },
    {
      key: 'internal_grouping',
      title: t('fleetsSection.internalGrouping'),
      canSort: true,
      render: row => <NAComponent val={row.internal_grouping} />,
      dataHook: 'internal-grouping',
    },

    actions,
    {
      key: 'tire_size',
      title: t('common.tireInventory.size'),
      canSort: true,
      render: row => processTireSize(row.tire_size),
      dataHook: 'size',
    },
    {
      key: 'configuration',
      title: t('vehiclePage.tireConfig'),
      canSort: true,
      render: row => <NAComponent val={row.configuration} />,
      dataHook: 'tire-config',
    },
    {
      key: 'trackStatus',
      title: t('fleetsSection.tracked'),
      dataHook: 'tracked',
      canSort: true,
      render: row => (
        <Chip
          label={row.is_telematics_matched ? 'Tracked' : 'Untracked'}
          className={
            classes[row.is_telematics_matched ? 'tracked' : 'untracked']
          }
        />
      ),
    },
    {
      key: 'mounted_tires',
      title: t('fleetsSection.mountedTires'),
      canSort: true,
      render: row => row.mounted_tires,
      dataHook: 'mounted-tires',
    },
    // {
    //   key: 'linked_trailer',
    //   title: t('common.mergedTrailer'),
    //   canSort: true,
    //   render: row => <NAComponent val={row.linked_trailer} />,
    //   dataHook: 'merged-trailer',
    // },
  ];
  if (
    process.env.REACT_APP_ENV === 'production' &&
    user?.customer_id === 'I2NDQ3NDY'
  ) {
    columns.push({
      key: 'metadata',
      title: t('fleetsSection.customerName'),
      canSort: true,
      render: row =>
        row?.metadata?.interchange?.name
          ? row?.metadata?.interchange?.name
          : 'N/A',
      dataHook: 'mounted-tires',
    });
  }
  if (
    process.env.REACT_APP_ENV === 'stage' &&
    user?.customer_id === 'Y2NDQ3NDY'
  ) {
    columns.push({
      key: 'metadata',
      title: t('fleetsSection.customerName'),
      canSort: true,
      render: row =>
        row?.metadata?.interchange?.name
          ? row?.metadata?.interchange?.name
          : 'N/A',
      dataHook: 'mounted-tires',
    });
  }
  // const [columns, setColumns] = useState()
  const hiddenColumns = columns.filter(
    column => !itemsToShow.includes(column.key)
  );

  const shownColumns = columns.filter(column =>
    itemsToShow.includes(column.key)
  );

  const [items, setItems] = useState({
    list1: shownColumns,
    list2: hiddenColumns,
  });

  const [activeColumns, setActiveColumns] = useState(itemsToShow);

  const [editColumnsIsOpen, setEditColumnsIsOpen] = useState(false);
  const toggleDrawer = newOpen => () => {
    setEditColumnsIsOpen(newOpen);
  };
  const filterCheckChangeHandler = e => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    const key = e.target.name;

    setSelectedFilters(prevSelectedFilters => {
      if (isChecked) {
        return [...prevSelectedFilters, value];
      } else {
        return prevSelectedFilters.filter(item => item !== value);
      }
    });
    let obj = filterParameters;
    if (isChecked) {
      if (!!obj[key]) {
        obj[key] = [...obj[key], value];
      } else {
        obj[key] = [value];
      }
    } else {
      if (!!obj[key]) {
        if (obj[key]?.length > 1) {
          let temp = obj[key]?.filter(item => item !== value);
          obj[key] = temp;
        } else delete obj[key];
      }
    }
    setFilterParameters(obj);
  };

  const filterApplyHandler = () => {
    fetchVehiclesFromFilters();
    setFilterCounter(selectedFilters.length);
    setOpenFilterDrawer(false);
  };

  const closeFilterHandler = () => {
    setOpenFilterDrawer(false);
  };

  const deleteSelectedFilterHandler = item => {
    setSelectedFilters(prevSelectedFilters => {
      return prevSelectedFilters.filter(clicked => clicked !== item);
    });
    let key = '';
    let obj = filterParameters;
    Object.entries(filterParameters)?.forEach(value => {
      if (value[1]?.includes(item)) {
        key = value[0];
      }
    });
    if (!!obj[key]) {
      if (obj[key]?.length > 1) {
        let temp = obj[key]?.filter(item_ => item_ !== item);
        obj[key] = temp;
      } else delete obj[key];
    }
  };

  const filterSearchHandler = (event, filter) => {
    let val = event.target.value;
    let updated = JSON.parse(JSON.stringify(filterOptionsStatic));
    let updatedIndex = updated.findIndex(item => item.key === filter.key);
    let updatedArr = updated[updatedIndex].options;
    const filtered = updatedArr.filter(hit => {
      const stringWithoutSpaces = hit.replace(/\s+/g, '');
      return stringWithoutSpaces.toLowerCase().includes(val);
    });

    updated[updatedIndex].options = filtered;
    setFilterOptions(updated);
    if (val.length === 0) {
      setFilterOptions(filterOptionsStatic);
    }
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isInitial, setIsInitial] = useState(true);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    if (!isInitial) {
      fetchPaginatedData();
    }
    // eslint-disable-next-line
  }, [rowsPerPage, isInitial]);

  const fetchPaginatedData = async page => {
    setCurrentPage(page);
    setSelectedVehicles([]);
    setLoadingData(true);
    try {
      // dispatch(
      //   fetchVehiclesForFleets(
      //     access_token,
      //     fleet_id,
      //     page,
      //     rowsPerPage,
      //     setLoadingData,
      //     searchQuery
      //   )
      // );
      await fetchFleetVehicle(true, page);
    } catch (error) {
      console.error('E20045');
    }
  };

  async function moveVehiclesHandler() {
    setIsSubmittingRequest(true);
    const resource = {
      vehicleIds: selectedVehicles,
      fleet_id: selectedFleet,
    };
    try {
      const res = await moveVehicles(resource);
      if (res.status === 200) {
        setRows(res.data.resp);
        const res2 = await getVehiclesForFleet(
          { fleet_id: fleet_id },
          access_token,
          false,
          currentPage,
          rowsPerPage
        );
        const data = res2?.data?.resp;

        if (data) {
          const foundVehicles = data.vehicles;
          const mapped = foundVehicles.map(vehicle => {
            vehicle.mounted_tires =
              typeof vehicle.mounted_tires === 'number'
                ? vehicle.mounted_tires
                : vehicle.mounted_tires.length;
            return vehicle;
          });
          setTableData({ vehicles: mapped, count: data.count });
          setTableDataStatic({ vehicles: mapped, count: data.count });
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmittingRequest(false);
    }
  }

  if (loading)
    return (
      <div className={classes.loader}>
        <Loading />
      </div>
    );

  const renderDeleteFleetModal = () => {
    if (!userIsReporter) {
      return (
        <Tooltip title={t('fleetsSection.deleteFleet')}>
          <Box
            color={theme.palette.primary.main}
            className={classes.icons}
            data-hook='delete-button'
            onClick={() => setOpenDeleteFleetModal(true)}
          >
            <Delete size={32} fill={theme.palette.primary.main} />
          </Box>
        </Tooltip>
      );
    }
  };

  const renderFleetAddTruckBtn = () => {
    if (fleetsOperated.includes(VEHICLE.vehicle_type.TRUCKS)) {
      return (
        <Button
          className={classes.btns}
          startIcon={<Add />}
          variant='contained'
          color='primary'
          data-hook='add-truck'
          id='vehicle-upload-btn-trucks'
          onClick={() => {
            setVehicleType('truck');
            setOpenUploadModal(true);
          }}
        >
          {t('fleetsSection.addTruck')}
        </Button>
      );
    }
  };

  const renderFleetAddtrailerBtn = () => {
    if (fleetsOperated.includes(VEHICLE.vehicle_type.TRAILERS)) {
      return (
        <Button
          className={classes.btns}
          startIcon={<Add />}
          variant='contained'
          color='primary'
          data-hook='add-trailer'
          onClick={() => {
            setVehicleType('trailer');
            setOpenUploadModal(true);
          }}
        >
          {t('fleetsSection.addTrailer')}
        </Button>
      );
    }
  };

  const renderFleetAddBusBtn = () => {
    if (fleetsOperated.includes(VEHICLE.vehicle_type.BUSES)) {
      return (
        <Button
          className={classes.btns}
          startIcon={<Add />}
          variant='contained'
          color='primary'
          data-hook='add-bus'
          onClick={() => {
            setVehicleType('bus');
            setOpenUploadModal(true);
          }}
        >
          {t('fleetsSection.addBus')}
        </Button>
      );
    }
  };

  function renderMoveVehiclesBtn() {
    return (
      !!tableData?.vehicles?.length && (
        <Button
          className={classes.btns}
          variant='contained'
          size='small'
          sx={{ textTransform: 'unset' }}
          onClick={() => {
            setOpen(true);
          }}
          data-hook='move-vehicles-btn'
        >
          {t('fleetsSection.moveVehicles')}
        </Button>
      )
    );
  }

  const renderNonReporterUserDetails = () => {
    if (!userIsReporter) {
      return (
        <Box display='flex' alignItems='center' gap={'16px'}>
          {renderFleetAddTruckBtn()}
          {renderFleetAddtrailerBtn()}
          {renderFleetAddBusBtn()}
          {renderMoveVehiclesBtn()}
        </Box>
      );
    }
  };

  const renderShowBtn = () => {
    if (showBtn.found) {
      return (
        <Tooltip title={t('fleetsSection.tableauBtnInfo')}>
          <Link
            color='primary'
            to={`/fleets-data/${fleet.fleet_id}/tableau`}
            className={classes.nav}
          >
            <Button color='primary' className={classes.btn} variant='outlined'>
              {t('fleetsSection.tableauBtnName')}
            </Button>
          </Link>
        </Tooltip>
      );
    }
  };

  const renderUploadModal = () => {
    if (openUploadModal) {
      return (
        <VehicleUploads
          data-hook='vehicle-upload-modal'
          vehicleType={vehicleType}
          open={openUploadModal}
          setOpen={setOpenUploadModal}
          setIsVehicleUpload={setIsVehicleUpload}
          title={csvUploaderConfig[vehicleType]['title']}
          headers={csvUploaderConfig[vehicleType]['headers']}
          asset_type={csvUploaderConfig[vehicleType]['asset_type']}
          header1={csvUploaderConfig[vehicleType]['header1']}
          header2={csvUploaderConfig[vehicleType]['header2']}
          flex_direction='column'
          page={currentPage}
          rows={rowsPerPage}
        />
      );
    }
  };

  const renderVehicleEditModal = () => {
    if (isEditSelected) {
      return (
        <VehicleEditModal
          data-hook='vehicle-edit-modal'
          open={isEditSelected}
          vehicle={vehicle}
          setOpen={setIsEditSelected}
          caller='fleets_page'
          isMerged={vehicle?.isCoupled}
        />
      );
    }
  };

  const renderVehicleDeleteModal = () => {
    if (isDeleteSelected) {
      return (
        <VehicleDeleteModal
          data-hook='vehicle-delete-modal'
          open={isDeleteSelected}
          fetchFleetVehicle={fetchFleetVehicle}
          setOpen={setIsDeleteSelected}
          vehicle={vehicle}
        />
      );
    }
  };

  const renderTour = () => {
    if (isTour && steps.length) {
      return (
        <Tour
          startAt={0}
          steps={steps}
          isOpen={isTour}
          accentColor={theme.palette.primary.main}
          onRequestClose={() => dispatch(setTour(false))}
          showNavigation={false}
          showButtons={false}
        />
      );
    }
  };

  function renderFilterDrawer() {
    return (
      openFilterDrawer && (
        <FIlterDrawer
          data-hook='fliter-drawer-modal'
          open={openFilterDrawer}
          closeHandler={closeFilterHandler}
          filters={filterOptions}
          selectedFilters={selectedFilters}
          filterParameters={filterParameters}
          setSelectedFilters={setSelectedFilters}
          setFilterParameters={setFilterParameters}
          handleChange={filterCheckChangeHandler}
          apply={filterApplyHandler}
          deleteHandler={deleteSelectedFilterHandler}
          filterSearchHandler={filterSearchHandler}
        />
      )
    );
  }

  function getMessage(entry) {
    if (typeof entry === 'object') {
      return entry.return_message;
    }
    return entry;
  }
  function selectVehiclesView() {
    return !isFetchingAllVehicles ? (
      <>
        <Button
          variant='contained'
          size='small'
          sx={{ textTransform: 'unset' }}
          onClick={selectAllHandler}
          data-hook='select-all-btn'
        >
          {t('multiLayerReport.select')}
        </Button>
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          width={'100%'}
          maxHeight={300}
          overflow={'auto'}
        >
          {allVehicles?.map((vehicle, i) => {
            return (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    color='primary'
                    onChange={() => checkChangeHandler(vehicle)}
                    name={vehicle.vehicle_id}
                    checked={selectedVehicles.includes(vehicle.vehicle_id)}
                    data-hook={`kpi-select-${i}`}
                  />
                }
                label={vehicle.name}
              />
            );
          })}
        </Box>
        <FormControl sx={{ width: '100%' }}>
          <InputLabel id='demo-simple-select-helper-label'>
            Select New Fleet Location
          </InputLabel>
          <Select
            labelId='demo-simple-select-helper-label'
            id='demo-simple-select-helper'
            data-hook='select-new-location-dropdown'
            label='Select New Fleet Location'
            onChange={e => setSelectedFleet(e.target.value)}
          >
            <MenuItem value=''>
              <em>None</em>
            </MenuItem>
            {fleets
              ?.filter(fleet => fleet.fleet_id !== fleet_id)
              .map((fleet, i) => {
                return (
                  <MenuItem
                    key={i}
                    data-hook={`${fleet.fleet_name}`}
                    value={fleet.fleet_id}
                  >
                    {fleet.fleet_name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <Box display={'flex'} alignItems={'center'} gap={'20px'}>
          <Button
            variant='outlined'
            sx={{ textTransform: 'unset' }}
            onClick={() => setOpen(false)}
            data-hook='cancel-btn'
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant='contained'
            sx={{ textTransform: 'unset' }}
            onClick={moveVehiclesHandler}
            disabled={!selectedFleet || !selectedVehicles.length}
            data-hook='submit-btn'
          >
            {isSubmittingRequest ? (
              <CircularProgress size={30} color='inherit' />
            ) : (
              t('common.confirm')
            )}
          </Button>
        </Box>
      </>
    ) : (
      <Loading message={'Fetching Vehicles...'} />
    );
  }

  const closeModalHandler = () => {
    setRows(null);
    setOpen(false);
    setSelectedFleet(null);
    setSelectedVehicles([]);
  };

  const renderTableRows = () => {
    return rows.map(row => (
      <TableRow key={row.vehicle_name}>
        <StyledTableCell align='right'>{row.vehicle_name}</StyledTableCell>
        <StyledTableCell align='right'>
          {row.success === null ? (
            'Processing...'
          ) : (
            <Chip
              label={row.success ? 'Pass' : 'Fail'}
              color={row.success ? 'primary' : 'secondary'}
            />
          )}
        </StyledTableCell>
        <TableCell align='right'>
          {row.success === null ? 'Fetching...' : getMessage(row.message)}
        </TableCell>
      </TableRow>
    ));
  };

  const renderTable = () => {
    if (rows) {
      return (
        <>
          <TableContainer component={Paper} style={{ maxHeight: 500 }}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align='right'>
                    {t('uploadSummary.vehicleName')}
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    {'Status'}&nbsp;
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    {t('uploadSummary.result')}&nbsp;
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTableRows()}</TableBody>
            </Table>
          </TableContainer>
        </>
      );
    }
  };
  const checkChangeHandler = row => {
    if (!selectedVehicles.includes(row.vehicle_id)) {
      setSelectedVehicles(items => [...items, row.vehicle_id]);
    } else {
      setSelectedVehicles(items =>
        items.filter(item => item !== row.vehicle_id)
      );
    }
  };

  const selectAllHandler = () => {
    if (selectedVehicles.length === allVehicles.length) {
      setSelectedVehicles([]);
      return;
    }
    setSelectedVehicles(allVehicles.map(vehicle => vehicle.vehicle_id));
  };

  const uploadTuckStep = [
    {
      selector: '#vehicle-upload-btn-trucks',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t(`guidesPage.tour.addVehicle`)}
          finalAction='Done'
          goTo={() => setShowTour(false)}
          hideSkip={true}
        />
      ),
      style: styling,
    },
  ];

  const renderTourFromGuide = () => {
    if (flag) {
      return (
        <Tour
          startAt={0}
          steps={uploadTuckStep}
          isOpen={showTour}
          accentColor={theme.palette.primary.main}
          onRequestClose={() => setShowTour(false)}
          showNavigation={false}
          showButtons={false}
          showNNumber={false}
          badgeContent={() => ``}
        />
      );
    }
  };

  return (
    <Box position={'relative'}>
      <Box className={classes.container}>
        <Breadcrumbs separator='>' aria-label='breadcrumb'>
          <Typography className={classes.vehicleName} color='textPrimary'>
            {t('fleetsDataPage.fleet')}
          </Typography>
          <NavLink
            color='inherit'
            variant='text'
            data-hook='fleet-name'
            to={'/fleets-data/' + fleet?.fleet_id}
            className={classes.nav}
          >
            {fleet?.fleet_name}
          </NavLink>
        </Breadcrumbs>
      </Box>
      <Box display='flex' alignItems='center' marginBottom={'28px'}>
        <SearchBar
          dataHook='filter-search-bar'
          handleSearch={e => {
            setSearchQuery(e.target.value);
          }}
          setOpen={setOpenFilterDrawer}
          filterCounter={filterCounter}
        />
        <Box display='flex' alignItems='center' gap={'50px'} marginLeft={2}>
          {renderDeleteFleetModal()}
          <Tooltip title={t('fleetsSection.changelogDownload')}>
            <Box
              color={theme.palette.primary.main}
              className={classes.icons}
              onClick={() => setOpenDialog(true)}
            >
              <BsDownload data-hook='download-button' size={24} />
            </Box>
          </Tooltip>
          {tableData?.length > 0 && (
            <Tooltip title={t('fleetsSection.export')}>
              <Box
                data-hook='export-file'
                color={theme.palette.primary.main}
                className={classes.icons}
                onClick={() => downloadExcel()}
              >
                <FaFileExport size={24} />
              </Box>
            </Tooltip>
          )}
          {renderNonReporterUserDetails()}
          {renderShowBtn()}
        </Box>
        <ColumnsEditDrawer
          items={items}
          setItems={setItems}
          setActiveColumns={setActiveColumns}
          editColumnsIsOpen={editColumnsIsOpen}
          toggleDrawer={toggleDrawer}
          setToggleDrawer={setEditColumnsIsOpen}
        />
      </Box>
      <Box
        display='flex'
        position={'sticky'}
        bgcolor={'#F3F4F6'}
        top={60}
        zIndex={11}
        alignItems={'center'}
        gap={'20px'}
        height={60}
      >
        <Button
          sx={{ textTransform: 'unset' }}
          onClick={() => setEditColumnsIsOpen(true)}
        >
          {t('columnsEdit.editBtn')}
        </Button>
      </Box>

      <Box position={'relative'} zIndex={10}>
        <CustomTable
          data={tableData?.vehicles ? tableData.vehicles : []}
          emptyMessage={t('common.noVehiclesPresent')}
          paginationHandler={fetchPaginatedData}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          total={tableData?.count}
          freq={tableData?.count}
          loading={loadingData}
          isInitialRun={setIsInitial}
          columns={
            !userIsReporter ? columns : columns.filter(x => x.key !== 'actions')
          }
          revealedColumns={activeColumns}
          data-hook='fleets-table'
        />
      </Box>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={closeModalHandler}
        closeAfterTransition
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            {!rows ? (
              selectVehiclesView()
            ) : (
              <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                <IconButton
                  sx={{ alignSelf: 'flex-end' }}
                  onClick={closeModalHandler}
                  data-hook='close-btn'
                >
                  <Close />
                </IconButton>
                {renderTable()}
              </Box>
            )}
          </Paper>
        </Fade>
      </Modal>
      {renderUploadModal()}
      <Downloads
        data-hook='download-modal'
        t={t}
        dialogOpen={openDialog}
        setDialogOpen={setOpenDialog}
        handleDownload={downloadChangelogHandler}
        title={t('fleetsSection.changelogDownload')}
      />
      {renderFilterDrawer()}
      {renderVehicleEditModal()}
      {renderVehicleDeleteModal()}
      <DeleteModal
        data-hook='delete-vehicle-modal'
        open={openDeleteFleetModal}
        setOpen={setOpenDeleteFleetModal}
        handleChange={handleCheckChange}
        list={deleteList}
        selectedFleets={selectedFleetsWithFleetId}
        closeHandler={deleteModalCloseHandler}
        fleet={fleet}
        allFleets={fleets}
      />
      {renderTour()}
      {renderTourFromGuide()}
    </Box>
  );
};
export default FleetsSection;

const csvUploaderConfig = {
  trailer: {
    title: 'Trailer .csv Upload',
    headers: TRAILER_HEADERS,
    asset_type: VEHICLE.vehicle_type.TRAILERS,
    header1: 'fleet_name',
    header2: 'fleet_location',
  },
  truck: {
    title: 'Truck .csv Upload',
    headers: TRUCK_AND_BUSES_HEADERS,
    asset_type: VEHICLE.vehicle_type.TRUCKS,
    header1: 'fleet_name',
    header2: 'fleet_location',
  },
  bus: {
    title: 'Bus .csv Upload',
    headers: TRUCK_AND_BUSES_HEADERS,
    asset_type: VEHICLE.vehicle_type.BUSES,
    header1: 'fleet_name',
    header2: 'fleet_location',
  },
};

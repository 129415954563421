import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Drawer,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Close } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import LoadAnimation from '../LoadAnimation';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { parseISO } from 'date-fns';
import { Slider } from '@mui/material';
import { filterLabels } from '../../utils/string_utils';

const useStyles = makeStyles()(theme => {
  return {
    drawer: {
      height: '100vh',
      overflow: 'auto',
    },
    top: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '40px',
      paddingBottom: '40px',
    },
    clear: {
      textTransform: 'none',
      opacity: 0.6,
    },
    content: {
      maxHeight: 200,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      position: 'relative',
    },
    btn: {
      width: '100%',
      textTransform: 'unset',
      marginTop: 50,
      borderRadius: 24,
    },
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      width: 330,
      border: '1px solid #D1D5DB',
      borderRadius: 8,
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      background: 'white',
    },
  };
});

export const TireInventoryFIlterDrawer = ({
  open,
  closeHandler,
  filters,
  setFilterParameters,
  handleChange,
  selectedFilters,
  setSelectedFilters,
  apply,
  filterSearchHandler,
  checkedFilters,
  setCheckedFilters,
  dates,
  setDates,
  regroovedCheck,
  setRegroveCheck,
  treadFilterValues,
  setTreadFilterValues,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [mappedArr, setMappedArr] = useState([]);
  const [isApplying, setIsApplying] = useState(false);
  const [initialSliderValues, setInitialSliderValues] = useState({});

  const displayedFilters = useMemo(() => {
    return Object.entries(filters);
  }, [filters]);
  const [expanded, setExpanded] = useState(
    new Array(displayedFilters?.length).fill(false)
  );
  const theme = useTheme();

  const getOptions = (option, filter) => {
    if (filter === 'isRetread' || filter === 'isRegrooved') {
      if (!option) {
        return 'false';
      }
      return 'true';
    }
    return option;
  };

  // eslint-disable-next-line
  const checkItemsView = filter => {
    if (Array.isArray(filter[1])) {
      return filter[1].map((item, j) => {
        return (
          <FormControlLabel
            key={j}
            control={
              <Checkbox
                checked={
                  !checkRegroovedandRetread(filter[0])
                    ? selectedFilters.includes(item)
                    : regroovedCheck[filter[0]]
                }
                onChange={e => handleSelectFilters(e, filter[0])}
                value={item}
                name={filter[0]}
                color='primary'
                data-hook={`${filter[0]}_${j}_check`}
              />
            }
            style={{ width: '100%' }}
            label={getOptions(item, filter[0])}
          />
        );
      });
    } else if (checkDates(filter[0])) {
      return <>{filterDatePicker(filter[0])}</>;
    } else if (checkTreadDepths(filter[0])) {
      const key = filter[0].slice(0, -4);
      let maxInitialValue = displayedFilters.filter(
        i => i[0].slice(0, -4).includes(key) && /max/.test(i[0])
      );
      if (!initialSliderValues[key]) {
        setInitialSliderValues({
          ...initialSliderValues,
          [key]: [filter[1], maxInitialValue[0][1]],
        });
      }
      return (
        <Slider
          value={
            Object.keys(treadFilterValues).length
              ? treadFilterValues[key]
              : [filter[1], maxInitialValue[0][1]]
          }
          valueLabelDisplay='on'
          aria-labelledby='estimated_tread_deapth'
          onChange={(e, newValue) => handleRangeChange(e, newValue, key)}
          min={0}
          max={20}
          marks={[
            {
              value: 0,
              label: 'Min',
            },
            {
              value: 20,
              label: 'Max',
            },
          ]}
        />
      );
    }
    return (
      <FormControlLabel
        key={filter[0]}
        control={
          <Checkbox
            checked={selectedFilters.includes(filter[1])}
            onChange={handleChange}
            value={filter[1]}
            name={filter[0]}
            color='primary'
          />
        }
        label={getOptions(filter[1], filter[0])}
      />
    );
  };

  useEffect(() => {
    let display = displayedFilters.filter(
      item =>
        item[0] !== 'mount_date_min' &&
        item[0] !== 'estimated_wear_out_date_max' &&
        item[0] !== 'last_tread_depth_max' &&
        item[0] !== 'estimated_tread_depth_max'
    );
    // eslint-disable-next-line
    const displayFiltered = display.map((filter, i) => {
      if (filter[1]) {
        return (
          <Accordion
            key={filter[0]}
            id={filter[0]}
            square
            data-hook={`filter_by_${filter[0]}`}
          >
            <AccordionSummary
              expandIcon={
                <KeyboardArrowRightIcon
                  color={expanded[i] ? 'primary' : 'inherit'}
                />
              }
              aria-controls='panel1a-content'
              id='panel1a-header'
              style={{ borderBottom: '1px solid white' }}
            >
              <Typography
                className={classes.typography}
                style={{
                  color: expanded[i] ? theme.palette.primary.main : 'inherit',
                }}
              >
                {checkDates(filter[0]) || checkTreadDepths(filter[0])
                  ? filterLabels[filter[0].slice(0, -4)]
                  : filterLabels[filter[0]]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{checkItemsView(filter)}</AccordionDetails>
          </Accordion>
        );
      }
    });
    setMappedArr(displayFiltered);
    setIsLoading(false);
  }, [
    // eslint-disable-next-line no-use-before-define
    checkItemsView,
    classes.content,
    classes.searchBar,
    classes.typography,
    displayedFilters,
    expanded,
    filterSearchHandler,
    theme.palette.primary.main,
    selectedFilters,
    checkedFilters,
    regroovedCheck,
    treadFilterValues,
  ]);

  const dateChangeHandler = (date, key) => {
    if (date === null || date.toString() === 'Invalid Date') {
      setDates(prev => ({
        ...prev,
        [key]: null,
      }));
    } else {
      setDates(prev => ({
        ...prev,
        [key]: moment(date).format('YYYY-MM-DD'),
      }));
      if (!selectedFilters.includes(key)) {
        setSelectedFilters(prev => [...prev, key]);
      }
    }
  };

  const filterDatePicker = label => {
    const key = label.slice(0, -4);

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box style={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
          <Box>
            <label style={{ margin: '6px' }}>Min</label>
            <DatePicker
              label={`${key}_min`}
              value={dates[`${key}_min`] ? parseISO(dates[`${key}_min`]) : null}
              minDate={Date.now}
              onChange={date => {
                dateChangeHandler(date, `${key}_min`);
              }}
              name={`${key}_min`}
              format='yyyy.MM.dd'
            />
          </Box>
          <Box>
            <label style={{ margin: '6px' }}>Max</label>
            <DatePicker
              label={`${key}_max`}
              value={dates[`${key}_max`] ? parseISO(dates[`${key}_max`]) : null}
              minDate={Date.now}
              onChange={date => {
                dateChangeHandler(date, `${key}_max`);
              }}
              name={`${key}_max`}
              format='yyyy.MM.dd'
            />
          </Box>
        </Box>
      </LocalizationProvider>
    );
  };

  const checkDates = value => {
    const dates = [
      'estimated_wear_out_date_min',
      'estimated_wear_out_date_max',
      'mount_date_min',
      'mount_date_max',
    ];
    return dates.includes(value);
  };

  const checkTreadDepths = value => {
    const treadLabels = [
      'last_tread_depth_min',
      'last_tread_depth_max',
      'estimated_tread_depth_min',
      'estimated_tread_depth_max',
    ];
    return treadLabels.includes(value);
  };

  const checkRegroovedandRetread = value => {
    const regroovedAndRetread = ['isRetread', 'isRegrooved'];
    return regroovedAndRetread.includes(value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleSelectFilters = (e, key) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    const filters = { ...checkedFilters };
    if (checkRegroovedandRetread(key) && e.target.value === 'false') {
      setRegroveCheck(prev => ({
        ...prev,
        [key]: isChecked,
      }));
      if (isChecked) {
        setSelectedFilters(prev => [...prev, key]);
      } else {
        setSelectedFilters(prev => prev.filter(value => value !== key));
      }
    }

    if (filters[key]) {
      if (filters[key].includes(value)) {
        if (checkRegroovedandRetread(key)) {
          filters[key] = ['true'];
        } else {
          filters[key] = filters[key].filter(val => val !== value);
          setSelectedFilters(prev => prev.filter(filter => value !== filter));
        }
      } else {
        if (checkRegroovedandRetread(key)) {
          filters[key] = ['false'];
        } else {
          filters[key] = [...filters[key], value];
          setSelectedFilters(prev => [...prev, value]);
        }
      }
    } else {
      if (!checkRegroovedandRetread(key)) {
        setSelectedFilters(prev => [...prev, value]);
      }
      filters[key] = [value];
    }

    setCheckedFilters(filters);
  };

  const handleRangeChange = (event, newValue, key) => {
    let filters = { ...checkedFilters };
    if (newValue[1] === 0) {
      setSelectedFilters(prev => prev.filter(value => value !== key));
      return;
    }
    setTreadFilterValues(prev => ({ ...prev, [key]: newValue }));

    if (!selectedFilters.includes(key)) {
      setSelectedFilters([...selectedFilters, key]);
    }
    filters[key] = newValue;

    setCheckedFilters(filters);
  };

  // eslint-disable-next-line

  const resetAllFiltersHandler = item => {
    setCheckedFilters({});
    setSelectedFilters([]);
    setFilterParameters({});
    setRegroveCheck({
      isRegrooved: false,
      isRetread: false,
    });
    setDates({});
    setTreadFilterValues({});
    setExpanded(prev => prev.map(item => false));
  };

  const isDateSelected = () => {
    let datePayload = { ...checkedFilters };
    if (dates['estimated_wear_out_date_min'] && dates['mount_date_min']) {
      datePayload = {
        ...datePayload,
        estimated_wear_out_date: {
          min: dates?.estimated_wear_out_date_min,
          max: dates?.estimated_wear_out_date_max,
        },
        mount_date: {
          min: dates?.mount_date_min,
          max: dates?.mount_date_max,
        },
      };
    } else if (dates['estimated_wear_out_date_min']) {
      datePayload = {
        ...datePayload,
        estimated_wear_out_date: {
          min: dates?.estimated_wear_out_date_min,
          max: dates?.estimated_wear_out_date_max,
        },
      };
    } else if (dates['mount_date_max']) {
      datePayload = {
        ...datePayload,
        mount_date: {
          min: dates?.mount_date_min,
          max: dates?.mount_date_max,
        },
      };
    }

    return datePayload;
  };

  const treadSelectedValues = () => {
    let treadPayload = { ...isDateSelected() };
    if (
      treadFilterValues.estimated_tread_depth?.[1] &&
      treadFilterValues.last_tread_depth?.[1]
    ) {
      treadPayload = {
        ...treadPayload,
        estimated_tread_depth: {
          min: treadFilterValues.estimated_tread_depth[0],
          max: treadFilterValues.estimated_tread_depth[1],
        },
        last_tread_depth: {
          min: treadFilterValues.last_tread_depth[0],
          max: treadFilterValues.last_tread_depth[1],
        },
      };
    } else if (treadFilterValues.last_tread_depth?.[1]) {
      treadPayload = {
        ...treadPayload,
        last_tread_depth: {
          min: treadFilterValues.last_tread_depth[0],
          max: treadFilterValues.last_tread_depth[1],
        },
      };
    } else if (treadFilterValues?.estimated_tread_depth?.[1]) {
      treadPayload = {
        ...treadPayload,
        estimated_tread_depth: {
          min: treadFilterValues.estimated_tread_depth[0],
          max: treadFilterValues.estimated_tread_depth[1],
        },
      };
    }

    return treadPayload;
  };

  const applyAndCloseHandler = () => {
    const payload = { ...isDateSelected(), ...treadSelectedValues() };
    apply(payload);
    setExpanded(prev => prev.map(item => false));
    setIsApplying(true);
  };

  const renderFilterOptions = () => {
    if (!isLoading) {
      return (
        <>
          <Box>{mappedArr}</Box>
          <Button
            color='primary'
            variant='contained'
            className={classes.btn}
            data-hook='apply_filter_button'
            onClick={applyAndCloseHandler}
          >
            Apply
          </Button>
        </>
      );
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        className='testloader'
      >
        <CircularProgress size={50} value={75} width={100} height={100} />
        <Typography style={{ marginTop: '1rem' }} variant={'determinate'}>
          {t('common.wait')}
        </Typography>
      </Box>
    );
  };

  const removeKeys = keyToRemove => {
    let filters = { ...checkedFilters };
    delete filters[keyToRemove];
    setCheckedFilters(filters);
  };

  const getKey = (obj, target) => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key].includes(target)) {
          return key;
        }
      }
    }
    return null;
  };

  const handleSelectedFilters = filter => {
    setSelectedFilters(selectedFilters.filter(value => value !== filter));
    if (treadFilterValues[filter]) {
      setTreadFilterValues(prev => ({
        ...prev,
        [filter]: initialSliderValues[filter],
      }));
    }
    if (checkTreadDepths(filter)) {
      setTreadFilterValues(prev => ({
        ...prev,
        [filter]: '',
      }));
      removeKeys(filter.slice(0, -4));
      return;
    }
    if (checkRegroovedandRetread(filter)) {
      setRegroveCheck({
        ...regroovedCheck,
        [filter]: false,
      });
      removeKeys(filter);
      return;
    }

    if (checkDates(filter)) {
      setDates(prev => ({
        ...prev,
        [filter]: null,
      }));
      removeKeys(filter.slice(0, -4));
      return;
    }

    const key = getKey(checkedFilters, filter);
    if (checkedFilters[key]?.length === 1) {
      setCheckedFilters(
        prev => prev.length && prev.filter(value => value !== key)
      );
    } else {
      let checkedFiltersCopy = { ...checkedFilters };
      const filteredKey = checkedFilters[key]?.filter(
        value => value !== filter
      );
      checkedFiltersCopy[key] = filteredKey;
      setCheckedFilters(checkedFiltersCopy);
    }
  };

  return (
    <>
      <LoadAnimation loading={isApplying} setLoading={setIsApplying} />
      <Drawer
        data-hook='filter-drawerr'
        anchor='right'
        open={open}
        onClose={closeHandler}
        PaperProps={{
          style: {
            width: '477px',
            padding: '8px 40px 0px 40px',
          },
        }}
      >
        <div className={classes.drawer} style={{ overflowX: 'clip' }}>
          <Box className={classes.top}>
            <Typography variant='caption'>
              {t('filterComponent.filter')}
            </Typography>
            <Button
              variant='text'
              endIcon={<Close />}
              className={classes.clear}
              onClick={resetAllFiltersHandler}
              data-hook='filter_clear_button'
            >
              {t('filterComponent.clear')}
            </Button>
          </Box>
          {selectedFilters?.length > 0 && (
            <Box display='flex' alignItems='center' gap={'5px'} flexWrap='wrap'>
              {selectedFilters.map((filter, i) => (
                <Chip
                  key={i}
                  label={filter}
                  onDelete={() => handleSelectedFilters(filter)}
                />
              ))}
            </Box>
          )}
          {renderFilterOptions()}
        </div>
      </Drawer>
    </>
  );
};

TireInventoryFIlterDrawer.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  filters: PropTypes.array,
  selectedFilters: PropTypes.array,
  setSelectedFilters: PropTypes.func,
  setFilterParameters: PropTypes.func,
  handleChange: PropTypes.func,
  apply: PropTypes.func,
  deleteHandler: PropTypes.func,
  filterSearchHandler: PropTypes.func,
};

import React from 'react';
import { Button, Drawer, Divider, IconButton } from '@mui/material';
import Box from '@mui/system/Box';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    columnBox: {
      background: 'grey',
      borderRadius: '8px 8px 32px 8px',
      padding: '5px',
      color: 'white',
      fontSize: 12,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

const ColumnsEditDrawer = ({
  items,
  setItems,
  setActiveColumns,
  editColumnsIsOpen,
  toggleDrawer,
  setToggleDrawer,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  function sortByKey(arr) {
    return arr.sort((a, b) => {
      const order = { more: -1, actions: 1 }; // Assign priorities to keys
      return (order[a.key] || 0) - (order[b.key] || 0);
    });
  }

  const onDragEnd = result => {
    const { source, destination } = result;

    // Check if item is dropped within the same list
    if (!destination || source.droppableId === destination.droppableId) return;
    let items1 = JSON.parse(JSON.stringify(items.list1));
    let items2 = JSON.parse(JSON.stringify(items.list2));
    if (source.droppableId === 'list2') {
      const x = items2[source.index];
      items2.splice(source.index, 1);
      items1.push(x);
    }
    if (source.droppableId === 'list1') {
      const x = items1[source.index];
      items1.splice(source.index, 1);
      items2.push(x);
    }
    items1 = sortByKey(items1.slice());

    // Update the state with the modified lists
    setItems({ list1: items1, list2: items2 });
  };

  const addToShownColumnsHandler = index => {
    let items1 = JSON.parse(JSON.stringify(items.list1));
    let items2 = JSON.parse(JSON.stringify(items.list2));

    const x = items2[index];
    items2.splice(index, 1);
    items1.push(x);

    items1 = sortByKey(items1.slice());
    setItems({ list1: items1, list2: items2 });
  };

  const moveToHiddenColumnsHandler = index => {
    let items1 = JSON.parse(JSON.stringify(items.list1));
    let items2 = JSON.parse(JSON.stringify(items.list2));

    const temp = items1[index];
    items1.splice(index, 1);
    items2.push(temp);

    items1 = sortByKey(items1.slice());
    setItems({ list1: items1, list2: items2 });
  };
  return (
    <Drawer
      open={editColumnsIsOpen}
      onClose={toggleDrawer(false)}
      anchor='right'
    >
      <Box width={'400px'} padding={'20px'}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='list1'>
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                }}
              >
                {items.list1.map((column, index) => (
                  <Draggable
                    key={column.key}
                    draggableId={column.key}
                    index={index}
                    isDragDisabled={
                      column.key === 'actions' ||
                      column.key === 'more' ||
                      column.key === 'multi' ||
                      column.key === 'name'
                    }
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box
                          className={classes.columnBox}
                          style={{
                            display:
                              (column.key === 'multi' ||
                                column.key === 'actions' ||
                                column.key === 'more') &&
                              'none',
                          }}
                        >
                          {column.title}

                          <IconButton
                            sx={{
                              background: '#FFFFFF1A',
                              marginRight: '5px',
                              display: column.key === 'name' && 'none',
                            }}
                            onClick={() => moveToHiddenColumnsHandler(index)}
                          >
                            <FaMinus size={10} color='red' />
                          </IconButton>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {items.list1.length >= 11 && (
            <span style={{ color: 'red' }}>{t('columnsEdit.error')}</span>
          )}
          <Box margin={'20px'}>
            <Divider />
          </Box>

          <Droppable droppableId='list2'>
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                }}
              >
                {items.list2.map((column, index) => (
                  <Draggable
                    key={column.key}
                    draggableId={column.key}
                    index={index}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Box className={classes.columnBox}>
                          {column.title}
                          <IconButton
                            sx={{
                              background: '#FFFFFF1A',
                              marginRight: '5px',
                            }}
                            onClick={() => addToShownColumnsHandler(index)}
                          >
                            <FaPlus size={10} color='green' />
                          </IconButton>
                        </Box>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button
          variant='contained'
          color='primary'
          style={{
            textTransform: 'unset',
            marginTop: '20px',
            width: '100%',
          }}
          onClick={() => {
            setActiveColumns(items.list1.map(item => item.key));
            setToggleDrawer(false);
          }}
          disabled={items.list1.length >= 11 || items.list1.length <= 7}
        >
          {t('columnsEdit.update')}
        </Button>
      </Box>
    </Drawer>
  );
};

export default ColumnsEditDrawer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAccessTokenSelector,
  setModalState,
  setModalStatus,
  setUserSelector,
} from 'redux/users/ducks';
import { getVehiclesForFleet } from 'services/fleet_service';
import { setOnboardingSelector } from 'redux/onborading/ducks';
import {
  fleetOverviewSelector,
  setTrackedVehiclesIdsSelector,
  setVehiclesForFleet,
  vehiclesForFleetSelector,
} from 'redux/fleets/ducks';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import CustomTextField from '../custom/CustomTextField';

const useStyles = makeStyles()(theme => {
  return {
    fieldTitle: {
      textAlign: 'right',
      paddingTop: '0.5rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
  };
});

const UserAccount = ({ user }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const vehilcesForFleetData = useSelector(vehiclesForFleetSelector);
  const access_token = useSelector(setAccessTokenSelector);

  const userActive = useSelector(setUserSelector);
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const overvieData = useSelector(fleetOverviewSelector);
  const trackedVehicles = useSelector(setTrackedVehiclesIdsSelector);
  const org_profile = useSelector(setOnboardingSelector);
  const { t } = useTranslation();

  const handleExport = async () => {
    var zip = new JSZip();

    zip.folder('exported_data').file('user.txt', JSON.stringify(userActive));
    zip.folder('exported_data').file('fleets.txt', JSON.stringify(fleets));
    zip
      .folder('exported_data')
      .file('overview_data.txt', JSON.stringify(overvieData));
    zip
      .folder('exported_data')
      .file('tracked_vehciles.txt', JSON.stringify(trackedVehicles));
    zip
      .folder('exported_data')
      .file('org_profile.txt', JSON.stringify(org_profile));
    for (let i = 0; i < fleets.length; i++) {
      let res = {};
      if (vehilcesForFleetData?.[fleets[i]['fleet_id']]) {
        res = vehilcesForFleetData?.[fleets[i]['fleet_id']];
      } else {
        res = await getVehiclesForFleet(
          { fleet_id: fleets[i]['fleet_id'] },
          access_token
        );
        dispatch(setVehiclesForFleet(fleets[i]['fleet_id'], res));
      }
      if (res?.status === 200) {
        zip
          .folder('exported_data')
          .file(
            `${fleets[i]['fleet_name']}.txt`,
            JSON.stringify(res.data.resp)
          );
      }
    }

    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, 'exported_data.zip');
    });
  };

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item lg={4} md={4} xs={12}>
          <Typography
            variant='h6'
            gutterBottom
            className={classes.fieldTitle}
            data-hook='profile-first-name'
          >
            {t('userSettingsPage.fName')}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <CustomTextField
            id='first-name'
            label=''
            name=''
            value={user.first_name}
            variant='outlined'
            disabled
            fullWidth
            required
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Typography
            variant='h6'
            gutterBottom
            className={classes.fieldTitle}
            data-hook='profile-last-name'
          >
            {t('userSettingsPage.lName')}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <CustomTextField
            id='last-name'
            label=''
            name=''
            value={user.last_name}
            variant='outlined'
            disabled
            fullWidth
            required
          />
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Typography
            variant='h6'
            gutterBottom
            className={classes.fieldTitle}
            data-hook='profile-change-password-text'
          >
            {t('userSettingsPage.password')}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <Button
            variant='outlined'
            color='primary'
            onClick={() => {
              dispatch(setModalState(true));
              dispatch(setModalStatus('change-password'));
            }}
            data-hook='profile-change-password-button'
          >
            {t('userSettingsPage.changePassword')}
          </Button>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Typography
            variant='h6'
            gutterBottom
            className={classes.fieldTitle}
            data-hook='profile-export-data'
          >
            {t('userSettingsPage.exportData')}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <Button
            variant='contained'
            color='primary'
            data-hook='profile-export-data-button'
            onClick={() => {
              handleExport();
            }}
          >
            {t('userSettingsPage.export')}
          </Button>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Typography
            variant='h6'
            gutterBottom
            className={classes.fieldTitle}
            data-hook='profile-delete-data'
          >
            {t('userSettingsPage.deleteAcct')}
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} xs={12}>
          <Button
            variant='contained'
            color='secondary'
            data-hook='profile-delete-button'
            onClick={() => {
              dispatch(setModalState(true));
              dispatch(setModalStatus('delete-user'));
            }}
          >
            {t('common.actions.delete')}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserAccount;

UserAccount.propTypes = {
  user: PropTypes.any,
};

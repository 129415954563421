// import { ReactComponent as Co2Emission } from 'assets/svg/kpi/co2_emission.svg';
import { ReactComponent as AvgFuelConsumption } from 'assets/svg/kpi/avg_fuel_consumption_up.svg';
import { ReactComponent as AvgKmPerAsset } from 'assets/svg/kpi/route.svg';
import { ReactComponent as NoOfAsset } from 'assets/svg/kpi/no_of_assets.svg';
import { ReactComponent as AvgFuelCost } from 'assets/svg/kpi/assumed_fuel_cost_upgraded.svg';

// import fuel from 'assets/images/fuelCosts.png';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
// eslint-disable-next-line
import locales from 'numeral/locales';
import i18next from 'i18next';
import { ReactComponent as Co2 } from 'assets/svg/home/co2 1.svg';
import { ReactComponent as FuelImg } from 'assets/svg/home/fi-rs-gas-pump.svg';
import { withStyles } from 'tss-react/mui';
const useKpiList = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const iconConfig = {
    stroke: theme.palette.primary.main,
    fill: theme.palette.primary.main,
  };
  const recommAvailablity = item => {
    if (!Math.round(item)) {
      return true;
    } else {
      return false;
    }
  };
  const getKpiList = (data = {}) => {
    const { fleetRes, fleetOverviewRes } = data;
    numeral.locale('da-dk');

    const formatCondition = i18next.language === 'de' ? 'de-DE' : 'en-US';
    const fleets = fleetRes?.fleets;
    const savingPotentialValues =
      fleetOverviewRes?.costs?.tire_related_savings_potential;
    // if (fleetOverviewRes) {
    // let isTireRecommendationAvailable_ =
    //   fleetOverviewRes &&
    //   Object.values(
    //     fleetOverviewRes?.costs?.tire_related_savings_potential
    //   ).every(item => item.registered_vehicles === 0);
    // // }

    const fuelCosts =
      fleets?.length === 0
        ? savingPotentialValues?.fuel_costs_eur?.initially_accessed
        : recommAvailablity(
            savingPotentialValues?.fuel_costs_eur?.registered_vehicles
          )
        ? savingPotentialValues?.fuel_costs_eur?.initially_accessed
        : savingPotentialValues?.fuel_costs_eur?.registered_vehicles;
    const co2Emissions =
      fleets?.length === 0
        ? savingPotentialValues?.co2_emissions_tonnes?.initially_accessed
        : recommAvailablity(
            savingPotentialValues?.co2_emissions_tonnes?.registered_vehicles
          )
        ? savingPotentialValues?.co2_emissions_tonnes?.initially_accessed
        : savingPotentialValues?.co2_emissions_tonnes?.registered_vehicles;

    return [
      {
        key: 'co2-emissions',
        value: `${Number(Math.trunc(co2Emissions || 0)).toLocaleString(
          formatCondition
        )} ${t('kpiList.tonnes')}`,
        text: t('kpiList.emission'),
        icon: <Co2 stroke={theme.palette.primary.main} alt='co2 emissions' />,
      },
      {
        key: 'fuel-costs',
        value: fuelCosts || 0,
        isCurrency: true,
        isDecimalValue: true,
        text: t('kpiList.fuelCosts'),
        icon: (
          <FuelImg fill={theme.palette.primary.main} alt='fuel pump machine' />
        ),
      },
      {
        key: 'no-of-assets',
        value:
          (fleetRes?.general_configs?.truck?.no_of_units ?? 0) +
          (fleetRes?.general_configs?.trailer?.no_of_units ?? 0) +
          (fleetRes?.general_configs?.bus?.no_of_units ?? 0),
        text: t('kpiList.assets'),
        icon: (
          <IconBox>
            <NoOfAsset {...iconConfig} />
          </IconBox>
        ),
      },
      {
        key: 'avg-km-per-asset',
        value: Number(
          Math.trunc(
            fleetRes?.general_configs?.truck?.km_driven_per_year ||
              fleetRes?.general_configs?.trailer?.km_driven_per_year ||
              0
          )
        ).toLocaleString(formatCondition),
        text: t('kpiList.averageKm'),
        icon: (
          <IconBox>
            <AvgKmPerAsset {...iconConfig} />
          </IconBox>
        ),
      },
      {
        key: 'avg-fuel-consumption',
        value: `${
          fleetOverviewRes?.costs?.assumptions?.avg_fuel_consumption || 0
        } Liters`,
        text: t('kpiList.averageFuelConsumption'),
        icon: (
          <IconBox>
            <AvgFuelConsumption {...iconConfig} />
          </IconBox>
        ),
        secondaryValue: t('common.per100Km'),
      },
      {
        key: 'assumed-fuel-costs',
        value: `${fleetOverviewRes?.costs?.assumptions?.fuel_price || 0}`,
        isCurrency: true,
        isDecimalValue: true,
        text: t('kpiList.assumedFuelCosts'),
        icon: (
          <IconBox>
            <AvgFuelCost {...iconConfig} />
          </IconBox>
        ),
        secondaryValue: t('common.perLitre'),
      },
      {
        key: 'co2-achieved',

        value: `${
          Math.round(
            fleetOverviewRes?.costs?.tire_related_savings_potential
              ?.co2_achieved?.co2_total * 100
          ) / 100
        } ${t('kpiList.tonnes')}`,
        isCurrency: false,
        isDecimalValue: true,
        text: t('common.co2Emission'),
        icon: <Co2 stroke={theme.palette.primary.main} alt='co2 emissions' />,
      },
    ];
  };
  return {
    getKpiList,
    // isTireRecommendationAvailable,
  };
};

// const IconBox = withStyles({
//   root: {
//     height: 45,
//     width: 45,
//     display: 'flex',
//     alignItems: 'center',
//   },
// })(Box);

const IconBox = withStyles(Box, {
  root: {
    height: 45,
    width: 45,
    display: 'flex',
    alignItems: 'center',
  },
});

export default useKpiList;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Drawer,
  FormControlLabel,
  IconButton,
  InputBase,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Close, Search } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import LoadAnimation from '../LoadAnimation';

const useStyles = makeStyles()(theme => {
  return {
    drawer: {
      height: '100vh',
      overflow: 'auto',
    },
    top: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '40px',
      paddingBottom: '40px',
    },
    clear: {
      textTransform: 'none',
      opacity: 0.6,
    },
    content: {
      maxHeight: 200,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      position: 'relative',
    },
    btn: {
      width: '100%',
      textTransform: 'unset',
      marginTop: 50,
      borderRadius: 24,
    },
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      width: 330,
      border: '1px solid #D1D5DB',
      borderRadius: 8,
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      background: 'white',
    },
  };
});

const FIlterDrawer = ({
  open,
  closeHandler,
  filters,
  selectedFilters,
  setSelectedFilters,
  setFilterParameters,
  handleChange,
  apply,
  deleteHandler,
  filterSearchHandler,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [mappedArr, setMappedArr] = useState([]);
  const [isApplying, setIsApplying] = useState(false);

  const displayedFilters = useMemo(() => {
    const fleetFilters = Object.entries(filters);
    return fleetFilters.filter(item => {
      if ((item[1].length === 1 && item[1] === null) || item[1] === undefined) {
        return false;
      }
      return true;
    });
  }, [filters]);

  const [isMatched, setIsMatched] = useState({ true: false, false: false });

  const [expanded, setExpanded] = useState(
    new Array(displayedFilters?.length).fill(false)
  );
  const theme = useTheme();

  const getOptions = (option, filter) => {
    if (
      (filter[0] === 'isRetread' || filter[0] === 'isRegrooved',
      filter[0] === 'is_telematics_matched')
    ) {
      if (!option) {
        return 'false';
      }
      return 'true';
    }
    return option;
  };

  const handleTelematicsMatched = e => {
    setIsMatched(prev => {
      return {
        ...prev,
        [e.target.value]: e.target.checked,
      };
    });
    if (e.target.checked) {
      setSelectedFilters(prev => [...prev, 'Telematics Matched']);
      setFilterParameters(prev => {
        return { ...prev, is_telematics_matched: [e.target.value] };
      });
    } else {
      setSelectedFilters(prev =>
        prev.filter(value => value !== 'Telematics Matched')
      );
      setFilterParameters(prev => {
        const { is_telematics_matched, ...rest } = JSON.parse(
          JSON.stringify(prev)
        );
        return rest;
      });
    }
  };

  // eslint-disable-next-line
  const checkItemsView = filter => {
    if (filter[1].length) {
      const mappedArr = filter[1].map((option, j) => {
        return (
          <FormControlLabel
            key={j}
            control={
              <Checkbox
                checked={
                  filter[0] !== 'is_telematics_matched'
                    ? selectedFilters.includes(option)
                    : isMatched[option]
                }
                onChange={e => {
                  filter[0] !== 'is_telematics_matched'
                    ? handleChange(e)
                    : handleTelematicsMatched(e);
                }}
                value={option}
                name={filter[0]}
                color='primary'
                data-hook={`${filter[0]}_${j}_check`}
              />
            }
            label={getOptions(option, filter)}
          />
        );
      });
      return mappedArr;
    }
    return <span>{t('filterComponent.zeroMatches')}</span>;
  };

  useEffect(() => {
    const getLabels = {
      name: 'Name',
      reg_plate: 'Reg Plate',
      axle_type: 'Axle Type',
      vehicle_type: 'Vehicle Type',
      internal_grouping: 'Internal Grouping',
      is_telematics_matched: 'Is Telematics Matched',
      telematics_protocol: 'Telematics Protocol',
      configuration: 'Configuration',
    };

    const displayFiltered = displayedFilters.map((filter, i) => {
      if (filter[1].length) {
        return (
          <Accordion
            key={filter[0]}
            id={filter[0]}
            onChange={(e, expanded) => {
              setExpanded(prev => {
                prev[i] = expanded;
                return prev;
              });
            }}
            square
            data-hook={`filter_by_${filter[0]}`}
          >
            <AccordionSummary
              expandIcon={
                <KeyboardArrowRightIcon
                  color={expanded[i] ? 'primary' : 'inherit'}
                />
              }
              aria-controls='panel1a-content'
              id='panel1a-header'
              style={{ borderBottom: '1px solid white' }}
            >
              <Typography
                className={classes.typography}
                style={{
                  color: expanded[i] ? theme.palette.primary.main : 'inherit',
                }}
              >
                {getLabels[filter[0]]}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.content}>
              <div className={classes.searchBar}>
                <IconButton aria-label='search'>
                  <Search color='primary' />
                </IconButton>
                <InputBase
                  placeholder='Search...'
                  fullWidth
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={e => filterSearchHandler(e, filter)}
                />
              </div>
              {checkItemsView(filter)}
            </AccordionDetails>
          </Accordion>
        );
      }
      return [];
    });
    setMappedArr(displayFiltered);
    setIsLoading(false);
  }, [
    // eslint-disable-next-line no-use-before-define
    checkItemsView,
    classes.content,
    classes.searchBar,
    classes.typography,
    displayedFilters,
    expanded,
    filterSearchHandler,
    theme.palette.primary.main,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const resetAllFiltersHandler = item => {
    setSelectedFilters([]);
    setFilterParameters({});
    setExpanded(prev => prev.map(item => false));
  };

  const applyAndCloseHandler = () => {
    apply();
    setExpanded(prev => prev.map(item => false));
    setIsApplying(true);
  };

  const renderFilterOptions = () => {
    if (!isLoading) {
      return (
        <>
          <Box>{mappedArr}</Box>
          <Button
            color='primary'
            variant='contained'
            className={classes.btn}
            data-hook='apply_filter_button'
            onClick={applyAndCloseHandler}
          >
            Apply
          </Button>
        </>
      );
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        className='testloader'
      >
        <CircularProgress size={50} value={75} width={100} height={100} />
        <Typography style={{ marginTop: '1rem' }} variant={'determinate'}>
          {t('common.wait')}
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <LoadAnimation loading={isApplying} setLoading={setIsApplying} />
      <Drawer
        data-hook='filter-drawerr'
        anchor='right'
        open={open}
        onClose={closeHandler}
        PaperProps={{
          style: {
            width: '477px',
            padding: '8px 40px 0px 40px',
          },
        }}
      >
        <div className={classes.drawer}>
          <Box className={classes.top}>
            <Typography variant='caption'>
              {t('filterComponent.filter')}
            </Typography>
            <Button
              variant='text'
              endIcon={<Close />}
              className={classes.clear}
              onClick={resetAllFiltersHandler}
              data-hook='filter_clear_button'
            >
              {t('filterComponent.clear')}
            </Button>
          </Box>
          {selectedFilters?.length > 0 && (
            <Box display='flex' alignItems='center' gap={'5px'} flexWrap='wrap'>
              {selectedFilters.map((filter, i) => (
                <Chip
                  key={i}
                  label={filter}
                  onDelete={() => deleteHandler(filter)}
                />
              ))}
            </Box>
          )}
          {renderFilterOptions()}
        </div>
      </Drawer>
    </>
  );
};

FIlterDrawer.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
  filters: PropTypes.array,
  selectedFilters: PropTypes.array,
  setSelectedFilters: PropTypes.func,
  setFilterParameters: PropTypes.func,
  handleChange: PropTypes.func,
  apply: PropTypes.func,
  deleteHandler: PropTypes.func,
  filterSearchHandler: PropTypes.func,
};

export default FIlterDrawer;

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import CustomTabs from 'components/custom/Tabs/CustomTabs';
import OrganizationForm from 'container/settings/customerSettings/form/Organization';
import TireForm from 'container/settings/customerSettings/form/Tire';
import VehicleForm from 'container/settings/customerSettings/form/Vehicle';
import { setAccessTokenSelector } from 'redux/users/ducks';
import {
  OnboardingActionTypes,
  setOnboardingSelector,
} from 'redux/onborading/ducks';
import { fleetOverviewSelector } from 'redux/fleets/ducks';
import { FleetCheck } from 'container/settings/customerSettings/form/FleetCheck';

const FleetSettings = () => {
  const { t } = useTranslation();
  const access_token = useSelector(setAccessTokenSelector);
  const dispatch = useDispatch();
  const profile = useSelector(fleetOverviewSelector);
  const organizationProfile = useSelector(
    setOnboardingSelector
  )?.organizationProfile;
  const vehicleData = organizationProfile?.['general_configs'];
  function getOrganizationProfileData() {
    dispatch({
      type: OnboardingActionTypes.ORGANIZATION_PROFILE.GET,
      access_token: access_token,
    });
  }

  useEffect(() => {
    if (access_token) {
      if (!profile && !organizationProfile) {
        getOrganizationProfileData();
      } else if (profile && !organizationProfile) {
        dispatch({
          type: OnboardingActionTypes.ORGANIZATION_PROFILE.SET,
          payload: profile,
        });
      }
    }
    //eslint-disable-next-line
  }, [access_token]);

  let items = [
    {
      label: t('customerSettingsPage.tabs.organization'),
      component: <OrganizationForm />,
      dataHook: 'organization-tab',
    },
    {
      label: t('customerSettingsPage.tabs.tire'),
      component: <TireForm />,
      dataHook: 'tire-tab',
    },
    {
      label: t('customerSettingsPage.tabs.fleetCheck'),
      component: <FleetCheck />,
      dataHook: 'fleetcheck-tab',
    },
  ];

  const vehicleTypeMapping = {
    truck: 'common.truck',
    trailer: 'common.trailer',
    bus: 'common.bus',
  };

  Object.entries(vehicleTypeMapping).forEach(([type, labelKey]) => {
    if (vehicleData?.[type]) {
      items.push({
        label: t(labelKey),
        component: <VehicleForm vehicleType={type} />,
        dataHook: `${type}-tab`,
      });
    }
  });

  return (
    <Box width='100%'>
      <CustomTabs items={items} />
    </Box>
  );
};

export default FleetSettings;

import { Box, CardContent, Card, Typography, Button } from '@mui/material';
import maintence_logo from 'assets/images/maintenance.png';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    card: {
      maxWidth: 600,
      width: '100%',
      padding: '1rem 3rem',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

const Maintenance = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Box
      width='100vw'
      height='100%'
      minHeight='100vh'
      bgcolor='#E5E5E5'
      display='flex'
      justifyContent='center'
      alignItems='center'
      padding='50px'
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <img src={maintence_logo} alt='maintence_logo' />
          <Box my='1rem'>
            <Typography
              variant='h5'
              gutterBottom
              align='center'
              style={{
                color: 'red',
              }}
              fontWeight='bold'
            >
              {t('maintencePage.title')}
            </Typography>
            <Typography variant='h6' align='center' fontWeight='bold'>
              {t('maintencePage.description')}
            </Typography>
            <Typography
              variant='h6'
              gutterBottom
              align='center'
              fontWeight='bold'
            >
              {t('maintencePage.apology')}
            </Typography>
          </Box>
          <Button
            variant='contained'
            color='primary'
            onClick={() => window.location.reload()}
            style={{ textTransform: 'unset' }}
          >
            Try Again
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Maintenance;

var CryptoJS = require('crypto-js');

// Encrypter
export const encrypter = token => {
  var ciphertext = CryptoJS.AES.encrypt(
    token,
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return ciphertext;
};

// Decrypter
export const decrypter = ciphertext => {
  var bytes = CryptoJS.AES.decrypt(
    ciphertext,
    process.env.REACT_APP_SECRET_KEY
  );
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

import { combineReducers } from 'redux';
import { notificationsReducer } from './notifications/ducks';
import { usersReducer } from './users/ducks';
import { telematicsProviderReducer } from './telematicsProvider/ducks';
import { onboardingReducer } from './onborading/ducks';
import { fleetReducer } from './fleets/ducks';
import { inventoryReducer } from './tireInventory/ducks';
const rootReducer = combineReducers({
  onboarding: onboardingReducer,
  notifications: notificationsReducer,
  users: usersReducer,
  telematics: telematicsProviderReducer,
  fleet: fleetReducer,
  inventory: inventoryReducer,
});

export default rootReducer;

import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { fleetOverviewSelector } from 'redux/fleets/ducks';
import { useSelector } from 'react-redux';
import { EDIT, TIRES } from 'utils/string_utils';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { tiresDbSelector } from 'redux/tireInventory/ducks';
import CustomTextField from 'components/custom/CustomTextField';
import { Close } from '@mui/icons-material';
import {
  internalAxleName,
  stringLookup,
} from '../../pages/VehiclePage/TireConfig/const';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { isEqual } from 'lodash';

const useStyles = makeStyles()(theme => {
  return {
    others: {
      width: 300,
      // margin: '0.5rem 1rem',
    },
    moreBtn: {
      margin: '0.15rem 0.15rem',
      textTransform: 'unset',
    },
    linesGrid: {
      maxHeight: 200,
      overflowY: 'scroll',
    },
    objectFit: {
      objectFit: 'contain',
    },
    btn: {
      textTransform: 'unset',
      borderRadius: 24,
      cursor: 'pointer',
      // color: theme.palette.primary.main,
    },
    retread: {
      border: '1px solid grey',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.8),
      opacity: 0.8,
    },
  };
});

const TireForm = props => {
  const {
    productLine,
    tireBrand,
    setTireBrand,
    size,
    setSize,
    error,
    setError,
    handleClose,
    setProductLine,
    handleChange,
    initialId,
    submitFormHandler,
    payload,
    setPayload = null,
    staticPayload = {},
    axleName,
    eventDate = new Date(),
    setDate,
    caller,
    isGrooveChanged = null,
    // fieldIsTouched,
    setUpdatePressure = () => {},
    last_pressure,
    vehicleType,
    tire,
    axle,
    index,
    setUpdateRegroove = () => {},
    optPressure,
    vehicle = {},
    isRegroovedSelected,
    handleRegroove = () => {},
    grooves,
    setGrooves,
    checked = false,
    handleChangeSwitch,
    handleGrooveTD = null,
    handleIncrement = null,
    setGrooveChanged = null,
    handleDeleteGroove = null,
    setUpdateTread = () => {},
  } = props;

  const { classes } = useStyles();
  const { t } = useTranslation();
  const overviewData = useSelector(fleetOverviewSelector);
  const tiresResource = useSelector(tiresDbSelector);
  const sorted = tiresResource?.sorted?.[axleName]?.[size];
  const availableSizes = tiresResource?.sizes;
  const resource = sorted?.[tireBrand];
  const defaultTreadDepth = sorted?.[tireBrand]?.[productLine];
  const theme = useTheme();
  const [isDisabled, setIsDisabled] = useState(false);
  const optimumPressure = useSelector(fleetOverviewSelector).general_configs;
  const [typedYear, setTypedYear] = React.useState(eventDate.getFullYear());
  const position =
    stringLookup[internalAxleName?.[axle]]?.[tire?.type]?.[tire?.side]?.[index];
  const valuesAreEqual = isEqual(payload, staticPayload);
  const condition_edit =
    !!error[TIRES.TREAD_DEPTH] ||
    !!error[TIRES.TIRE_ID] ||
    !!error[TIRES.PRESSURE] ||
    eventDate.toString() === 'Invalid Date' ||
    typedYear < 1900 ||
    typedYear > 2099 ||
    valuesAreEqual;

  const condition_add =
    !productLine ||
    !tireBrand ||
    !size ||
    error[TIRES.TIRE_ID] !== null ||
    error[TIRES.TREAD_DEPTH] !== null ||
    !!error[TIRES.PRESSURE];
  useEffect(() => {
    const updatedPayload = {
      ...payload,
      pressure: last_pressure?.toString(),
    };
    setPayload(updatedPayload);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const updatePayloadFn = () => {
      const updatedPayload = {
        ...payload,
        last_tread_depth: defaultTreadDepth?.td ?? '',
      };
      setPayload && setPayload(updatedPayload);
    };

    caller !== EDIT && updatePayloadFn();
    // eslint-disable-next-line
  }, [defaultTreadDepth]);

  const [showAltBrandField, setShowAltBrandField] = React.useState(false);

  const renderOPtimumPressureLabel = () => {
    if (optPressure) {
      return optPressure;
    }
    let pressure;
    if (vehicle.optimum_air_pressure) {
      pressure = vehicle.optimum_air_pressure;
    } else {
      pressure =
        optimumPressure?.[vehicleType]?.['optimum_air_pressure']?.[axle];
    }
    if (pressure && position) {
      return pressure[position.slice(0, 2)];
    }
    return 'N/A';
  };

  const tiresToShow = entry => {
    const capitalizeBrand = brand =>
      brand === 'KUMHO' ? 'KumhoTire' : capitalize(brand);

    if (sorted) {
      const preferredBrands = [
        overviewData?.tire_management_data['preferred_tire_brand_1'],
        overviewData?.tire_management_data['preferred_tire_brand_2'],
      ].map(brand => capitalizeBrand(brand));

      return Object.keys(sorted).filter(value =>
        entry === 1
          ? preferredBrands.includes(capitalizeBrand(value))
          : !preferredBrands.includes(capitalizeBrand(value))
      );
    } else {
      return [];
    }
  };

  const revert = () => {
    setTireBrand(null);
    setProductLine(null);
  };

  const renderRegrooveField = () => {
    if (!isRegroovedSelected) {
      return (
        <Grid item xs={6}>
          <FormGroup className={classes.retread}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={payload.isRegrooved || ''}
                  name='isRegrooved'
                  color='primary'
                  onChange={e => {
                    handleChange(e);
                    setPayload({ ...payload, isRegrooved: e.target.checked });
                    setUpdateRegroove(true);
                  }}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={t('level1Modal.regrooved')}
            />
          </FormGroup>
        </Grid>
      );
    }
  };

  const handleSubmit = e => {
    setIsDisabled(true);
    if (isRegroovedSelected) {
      handleRegroove(e);
      return;
    }
    submitFormHandler(e);
  };

  const check_edit = caller === EDIT || caller === 'inventory';

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {!isRegroovedSelected && (
            <Grid item xs={caller === EDIT ? 6 : 12}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                width='100%'
                marginBottom={2}
                gap={'20px'}
              >
                <Box
                  flex={6}
                  display='flex'
                  flexDirection='column'
                  position='relative'
                >
                  <CustomTextField
                    required
                    name='tire_id'
                    id='tire_id'
                    label={t('common.tireInventory.tireId')}
                    data-hook='tire-id'
                    onChange={handleChange}
                    defaultValue={initialId}
                    variant='outlined'
                    error={error[TIRES.TIRE_ID] !== null}
                  />
                </Box>

                {caller === 'inventory' && (
                  <Autocomplete
                    id='tire-size-auto-complete'
                    options={availableSizes || []}
                    getOptionLabel={option => option}
                    style={{
                      flex: 6,
                    }}
                    value={size}
                    onChange={(e, value) => {
                      if (e.target.value !== undefined) {
                        if (size === value) {
                          setSize(null);
                        } else {
                          setSize(value);
                        }
                      } else {
                        setSize(null);
                      }
                      setProductLine(null);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={t('common.tireInventory.size')}
                        variant='outlined'
                      />
                    )}
                    data-hook='select-size'
                  />
                )}
              </Box>
            </Grid>
          )}
          {caller !== EDIT && (
            <>
              <Grid item xs={12}>
                <Box>
                  <Typography variant='h5' gutterBottom align='left'>
                    {t('common.tireInventory.tireBrand')} :
                  </Typography>
                  {size && (
                    <Box>
                      {tiresToShow(1)?.length > 0 ||
                      tiresToShow(2)?.length > 0 ? (
                        <Box
                          display='flex'
                          flexDirection='row'
                          flexWrap='wrap'
                          width='100%'
                          gap={'10px'}
                        >
                          {tiresToShow(1)?.map((item, i) => (
                            <Button
                              key={i}
                              variant='outlined'
                              color='primary'
                              data-hook={`option-${i}`}
                              disableElevation
                              style={{
                                // margin: '0.15rem 0.15rem',
                                textTransform: 'unset',
                                opacity:
                                  tireBrand !== item && tireBrand !== null
                                    ? 0.5
                                    : 1,
                                height: 55,
                              }}
                              onClick={() => {
                                if (tireBrand === item) {
                                  revert();
                                } else {
                                  setTireBrand(item);
                                }
                              }}
                              disabled={
                                tireBrand !== item && tireBrand !== null
                                  ? true
                                  : false
                              }
                            >
                              <Box className={classes.objectFit}>
                                <img
                                  src={require(`../../assets/images/brands/${
                                    item === 'KUMHO'
                                      ? 'kumhotire'
                                      : item.toLowerCase()
                                  }.png`)}
                                  style={{ width: 175 }}
                                  alt={item || 'brand'}
                                />
                              </Box>
                            </Button>
                          ))}
                          {tiresToShow(2)?.length > 0 && (
                            <Box>
                              <Autocomplete
                                id='combo-box-demo'
                                className={classes.others}
                                options={tiresToShow(2).sort()}
                                disabled={
                                  tireBrand !== null &&
                                  tiresToShow(1).includes(tireBrand)
                                }
                                getOptionLabel={option => option}
                                value={
                                  tiresToShow(2)?.findIndex(
                                    item => item === tireBrand
                                  ) !== -1
                                    ? tireBrand
                                    : ''
                                }
                                data-hook='is-retread'
                                onChange={(e, value) => {
                                  if (e.target.value !== undefined) {
                                    if (tireBrand === value) {
                                      revert();
                                    } else {
                                      setTireBrand(value);
                                      setProductLine(null);
                                    }
                                  } else {
                                    revert();
                                  }
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label={
                                      tiresToShow(1)?.length
                                        ? t('tireForm.others')
                                        : t('tireForm.brands')
                                    }
                                    data-hook='brands'
                                    variant='outlined'
                                  />
                                )}
                              />
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box color={'tomato'}>
                          No brands/product lines present for this tire size.
                          Please enter them manually below
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                {tireBrand !== null && (
                  <Box my='1rem' className={classes.linesGrid}>
                    <Typography variant='h5' gutterBottom align='left'>
                      {t('common.tireInventory.productLines')} :
                    </Typography>
                    <Box
                      display='flex'
                      flexDirection='row'
                      flexWrap='wrap'
                      alignItems='center'
                      data-hook='product-lines'
                    >
                      {resource &&
                        Object.keys(resource)?.map((item, i) => (
                          <Button
                            key={i}
                            variant='contained'
                            color='primary'
                            disableElevation
                            data-hook={`line-${i}`}
                            sx={{
                              margin: '0.15rem 0.15rem',

                              textTransform: 'unset',
                              opacity:
                                productLine !== item && productLine !== null
                                  ? 0.5
                                  : 1,
                              backgroundColor:
                                Object.keys(resource).length === 1 &&
                                productLine !== item &&
                                'rgba(0, 0, 0, 0.12)',
                            }}
                            onClick={() => {
                              if (productLine === item) {
                                setProductLine(null);
                              } else {
                                setProductLine(item);
                              }
                            }}
                            disabled={
                              productLine !== item && productLine !== null
                                ? true
                                : false
                            }
                          >
                            <Box className={classes.objectFit}>{item}</Box>
                          </Button>
                        ))}
                      <Box></Box>
                    </Box>
                  </Box>
                )}
              </Grid>
            </>
          )}

          {caller === EDIT && !isRegroovedSelected && (
            <Grid item xs={6}>
              <Box
                flex={6}
                display='flex'
                flexDirection='column'
                position='relative'
              >
                <CustomTextField
                  name='pressure'
                  id='last_pressure'
                  label={t('common.tireInventory.optimum_pressure')}
                  data-hook='pressure'
                  onChange={e => {
                    const value =
                      e.target.value.length > 0 ? e.target.value : null;

                    // handleChange(e);
                    setUpdatePressure(true);
                    if (
                      parseFloat(e.target.value) > 15 ||
                      parseFloat(e.target.value) < 5
                    ) {
                      setError({
                        ...error,
                        pressure: t('common.pressureValueError'),
                      });
                    } else {
                      setError({
                        ...error,
                        pressure: null,
                      });
                    }
                    setPayload({ ...payload, pressure: value });
                  }}
                  type={'number'}
                  InputProps={{
                    inputProps: {
                      min: 5,
                      max: 15,
                      step: 0.1,
                    },
                  }}
                  value={payload.pressure}
                  // value={Number(payload.pressure)}
                  variant='outlined'
                  error={error[TIRES.PRESSURE] !== null}
                  helperText={error[TIRES.PRESSURE]}
                />
              </Box>
              <Typography
                style={{ fontSize: 12, margin: 5 }}
                gutterBottom
                align='left'
              >
                {t('common.tireInventory.optPressure')} :
                {renderOPtimumPressureLabel()}
              </Typography>{' '}
            </Grid>
          )}
          <Grid item xs={6} position={'relative'}>
            <Box
              flex={6}
              display='flex'
              flexDirection='column'
              position='relative'
              marginBottom={1}
            >
              <CustomTextField
                name='last_tread_depth'
                required
                id='last_tread_depth'
                label={t('common.tireInventory.depth')}
                data-hook='last-tread-depth'
                data-cy='last_tread_depth'
                onChange={e => {
                  handleChange(e);
                  setUpdateTread(true);
                }}
                variant='outlined'
                value={payload.last_tread_depth}
                type='number'
                // InputProps={{
                //   inputProps: {
                //     min: 0,
                //     max: 20,
                //     step: 'any',
                //   },
                // }}
                fullWidth
                error={error[TIRES.TREAD_DEPTH] !== null}
                helperText={error[TIRES.TREAD_DEPTH]}
              />
            </Box>
            {defaultTreadDepth?.td && (
              <Tooltip
                title={t(`treadDepthSource.${defaultTreadDepth?.td_source}`)}
                placement='top'
                sx={{ cursor: 'pointer' }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#CCCCCC',
                    borderRadius: '50%',
                    width: '25px',
                    height: '25px',
                    fontSize: '12px',
                    color: '#000',
                    position: 'absolute',
                    right: 5,
                    top: '45%',
                  }}
                >
                  i
                </Box>
              </Tooltip>
            )}
          </Grid>
          {!isRegroovedSelected && (
            <Grid item xs={6}>
              <FormGroup className={classes.retread}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={payload.isRetread || ''}
                      name='isRetread'
                      color='primary'
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label={t('level1Modal.retreaded')}
                />
              </FormGroup>
            </Grid>
          )}

          {caller !== EDIT && (
            <Grid item xs={6}>
              <CustomTextField
                name='pressure'
                // required
                id='pressure'
                label={t('tireInventoryItems.titles.pressure')}
                onChange={handleChange}
                variant='outlined'
                value={payload?.pressure}
                type='number'
                InputProps={{
                  inputProps: {
                    min: 5,
                    max: 15,
                    step: 0.1,
                  },
                }}
                fullWidth
                error={error[TIRES.PRESSURE] !== null}
                helperText={error[TIRES.PRESSURE]}
              />
            </Grid>
          )}
          {renderRegrooveField()}
          {caller !== EDIT && (
            <Grid item xs={12}>
              <Typography style={{ fontSize: 20 }} gutterBottom align='left'>
                {t('common.tireInventory.optPressure')} :
                {renderOPtimumPressureLabel()}
              </Typography>
            </Grid>
          )}

          {caller === EDIT && (
            <Grid item xs={6}>
              <Box width='100%'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={t('tireOperationsModal.eventDate')}
                    value={eventDate}
                    labelText='Date of Swap'
                    onChange={date => {
                      const year = date.getFullYear().toString();
                      setTypedYear(parseInt(year));
                      setDate(date);
                    }}
                    maxDate={new Date()}
                    format='yyyy.MM.dd'
                    sx={{ width: '100%' }}
                    data-hook='event-date-picker'
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
          )}

          {check_edit && (
            <Grid item xs={6}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                width='100%'
                marginBottom={2}
                gap={'20px'}
              >
                <Box
                  flex={6}
                  display='flex'
                  flexDirection='column'
                  position='relative'
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Dot'
                      value={payload.dot ? new Date(payload.dot) : new Date()}
                      onChange={date => {
                        setPayload({ ...payload, dot: date });
                      }}
                      maxDate={new Date()}
                      format='yyyy.MM'
                      sx={{ width: '100%' }}
                      data-hook='dot-date-picker'
                      renderInput={params => (
                        <TextField
                          {...params}
                          onClick={params.inputProps.onClick}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
            </Grid>
          )}

          {caller !== EDIT && (
            <Box
              position={'relative'}
              width={'100%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              // height={'100%'}
              gap={'30px'}
              marginTop={'20px'}
            >
              <Grid item xs={12} width={'95%'}>
                {!showAltBrandField ? (
                  <Box
                    display='flex'
                    alignItems='center'
                    gap={'10px'}
                    marginLeft={'24px'}
                  >
                    <Typography align='left'>
                      {t('tiresUpload.brandMissing')}
                    </Typography>
                    <span
                      className={classes.btn}
                      style={{ color: theme.palette.primary.main }}
                      data-hook='manual-entry-btn'
                      onClick={() => setShowAltBrandField(true)}
                    >
                      {t('tiresUpload.enterBtn')}
                    </span>
                  </Box>
                ) : (
                  <Box marginLeft={'24px'} width={'100%'}>
                    <CustomTextField
                      name='tire_brand'
                      required
                      id='tire_brand'
                      label={t('tiresUpload.brand')}
                      onChange={e => {
                        setTireBrand(e.target.value.toUpperCase());
                        if (e.target.value === '') {
                          setTireBrand(null);
                        }
                      }}
                      variant='outlined'
                      type='text'
                      fullWidth
                      data-hook='tire_brand_alt'
                    />
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} width={'95%'} marginLeft={'24px'}>
                {showAltBrandField && (
                  <CustomTextField
                    name='product_line'
                    id='product_line'
                    label={t('tiresUpload.productLine')}
                    onChange={e => {
                      setProductLine(e.target.value.toUpperCase());
                      if (e.target.value === '') {
                        setProductLine(null);
                      }
                    }}
                    variant='outlined'
                    disabled={tireBrand === null}
                    type='text'
                    fullWidth
                    data-hook='product_line_alt'
                  />
                )}
              </Grid>
              <IconButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: '36%',
                  height: '50px',
                  width: '50px',
                  alignSelf: 'flex-end',
                  display: showAltBrandField ? 'block' : 'none',
                }}
                data-hook='hide-manual-entry-fields'
                onClick={() => {
                  setShowAltBrandField(false);
                  revert();
                }}
              >
                <Close />
              </IconButton>
            </Box>
          )}
          {caller !== EDIT && !isRegroovedSelected && (
            <Grid item xs={12}>
              <Box
                width='100%'
                display='flex'
                flexDirection={'row'}
                // justifyContent={'center'}
                alignItems={'center'}
              >
                <Typography variant='h6' align='left'>
                  {t('grooveTD.selectGroove')}
                </Typography>
                <Switch
                  checked={checked}
                  onChange={handleChangeSwitch}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                {checked && (
                  <Tooltip title='Add Groove'>
                    <IconButton
                      aria-label='delete'
                      disabled={Object.keys(grooves).length === 6}
                      size='large'
                      color='primary'
                      onClick={() =>
                        setGrooves({
                          ...grooves,
                          [Object.keys(grooves).length + 1]: null,
                        })
                      }
                    >
                      <AddCircleOutlineIcon fontSize='large' />
                    </IconButton>
                  </Tooltip>
                )}
                {checked && Object.keys(grooves).length === 6 && (
                  <Typography variant='body1' align='left' color='secondary'>
                    ( Max Groove Limit : 6 )
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
          {caller !== EDIT && checked && !isRegroovedSelected && (
            <Grid item xs={12}>
              <Box
                width='60%'
                display='flex'
                flexDirection={'row'}
                // justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid container spacing={2}>
                  {Object.keys(grooves).map((item, i) => (
                    <Grid item xs={4} key={i}>
                      <Box
                        display='flex'
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        <TextField
                          style={{ width: 150 }}
                          id='outlined-basic'
                          label={`G-${item}`}
                          inputProps={{
                            step: 0.1,
                            min: 0,
                            max: 20,
                          }}
                          value={grooves[item]}
                          onChange={e =>
                            setGrooves({
                              ...grooves,
                              [item]: parseFloat(e.target.value),
                            })
                          }
                          variant='outlined'
                          type='number'
                          size='small'
                        />
                        {i === Object.keys(grooves).length - 1 && i !== 0 && (
                          <Tooltip title='Remove Groove'>
                            <IconButton
                              aria-label='delete'
                              size='small'
                              color='secondary'
                              onClick={() => {
                                let temp = JSON.parse(JSON.stringify(grooves));
                                delete temp[Object.keys(grooves).length];
                                setGrooves(temp);
                              }}
                            >
                              <DeleteOutlineIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          )}
          {caller === EDIT && !isRegroovedSelected && (
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='h6'
                  data-hook='groove-tread-depth'
                  gutterBottom
                  align='left'
                >
                  Grooves Tread Depth
                </Typography>
                <Tooltip title='Add Groove'>
                  <IconButton
                    aria-label='delete'
                    disabled={
                      payload.grooves_tread_depth
                        ? Object.keys(payload.grooves_tread_depth).length === 6
                        : false
                    }
                    data-hook='add-grooves-button'
                    size='large'
                    color='primary'
                    onClick={() => {
                      setGrooveChanged(true);
                      handleIncrement();
                    }}
                  >
                    <AddCircleOutlineIcon fontSize='large' />
                  </IconButton>
                </Tooltip>
                {payload.grooves_tread_depth && (
                  <>
                    {Object.keys(payload.grooves_tread_depth).length === 6 && (
                      <Typography
                        variant='body1'
                        align='left'
                        color='secondary'
                      >
                        ( Max Groove Limit : 6 )
                      </Typography>
                    )}
                  </>
                )}
              </Box>
              {payload.grooves_tread_depth && (
                <Grid container spacing={3} sx={{ marginTop: '0.25rem' }}>
                  {Object.keys(payload.grooves_tread_depth).map((item, i) => (
                    <Grid item xs={4} key={i}>
                      <Box
                        display='flex'
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        <TextField
                          style={{ width: 150 }}
                          id='outlined-basic'
                          data-hook={`grooves-field-${i}`}
                          label={`G-${item}`}
                          inputProps={{
                            step: 0.1,
                            min: 0,
                            max: 20,
                          }}
                          value={payload.grooves_tread_depth[item]}
                          onChange={e => {
                            setGrooveChanged(true);
                            handleGrooveTD(item, e.target.value);
                          }}
                          variant='outlined'
                          type='number'
                          size='small'
                        />
                        {i ===
                          Object.keys(payload.grooves_tread_depth).length -
                            1 && (
                          <Tooltip title='Remove Groove'>
                            <IconButton
                              aria-label='delete'
                              size='small'
                              color='secondary'
                              onClick={() => {
                                setGrooveChanged(true);
                                handleDeleteGroove(item);
                              }}
                            >
                              <DeleteOutlineIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          )}
        </Grid>

        <Box display='flex' justifyContent='center' marginTop='2rem' gap='16px'>
          {caller !== 'inventory' && (
            <Button
              className={classes.btn}
              variant='outlined'
              color='primary'
              disableElevation
              onClick={() => handleClose()}
              data-hook='cancel-button'
            >
              {t('singleVehicleUpload.cancel')}
            </Button>
          )}
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.btn}
            data-hook='update-button'
            disabled={
              (caller === EDIT
                ? !isGrooveChanged && condition_edit
                : condition_add) || isDisabled
            }
            disableElevation
          >
            {caller === EDIT ? (
              isDisabled ? (
                <CircularProgress size={30} color='inherit' />
              ) : (
                t('common.tireInventory.update')
              )
            ) : isDisabled ? (
              <CircularProgress size={30} color='inherit' />
            ) : (
              t('singleVehicleUpload.add')
            )}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default TireForm;

const capitalize = value => {
  return value.charAt(0) + value.slice(1).toLowerCase();
};

import React, { useEffect, useState } from 'react';
import { splitAndCapitalize } from 'utils/string_utils';
import { NavLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { Breadcrumbs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    currentPath: {
      fontSize: '1.125rem',
      fontWeight: 700,
      color: '#343434',
    },
    links: { color: '#343434' },
  };
});

export default function Breadcrumb({ customLastPath }) {
  const { classes } = useStyles();
  const pathname = window.location.pathname;
  const [paths, setPaths] = useState([]);
  const [lastPath, setLastPath] = useState('');
  let customPathnName = customLastPath || lastPath;
  let { t } = useTranslation();

  useEffect(() => {
    if (pathname) {
      const pathname = window.location.pathname.split('/');
      let _lastPath = '';
      if (pathname) {
        _lastPath = splitAndCapitalize(pathname.pop());
        setLastPath(_lastPath);
      }
      pathname.shift();
      let _paths = [];
      let lastLink = '';
      pathname.forEach(path =>
        _paths.push({
          path: splitAndCapitalize(path),
          link: lastLink + '/' + path,
        })
      );
      setPaths([..._paths]);
    }
  }, [pathname]);

  return (
    <div className={classes.root}>
      <Breadcrumbs separator='›' aria-label='breadcrumb'>
        {paths.map((path, i) => (
          <NavLink className={classes.links} to={path.link}>
            {t(`common.inventory.${path.path.split(' ').join('')}`)}
          </NavLink>
        ))}
        <Typography className={classes.currentPath}>
          {customPathnName === 'undefined' ? '' : customPathnName}
        </Typography>
      </Breadcrumbs>
    </div>
  );
}

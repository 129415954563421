import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  setToken,
  setUser,
  setTestUser,
  setAppStatus,
} from 'redux/users/ducks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Logout = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setToken(null));
    dispatch(setUser(null));
    dispatch(setTestUser(false));
    localStorage.clear();
    sessionStorage.clear();
    dispatch(setAppStatus(true));
  }, [dispatch]);

  useEffect(() => {
    let timeout;
    if (!localStorage.getItem('refresh_token')) {
      timeout = setTimeout(async () => {
        navigate('/login');
      }, 1000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <>
      <Box
        width='100vw'
        height='100vh'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
      >
        <>
          <CircularProgress size={50} />
          <Typography style={{ marginTop: '1rem' }} variant={'body1'}>
            {t('common.wait')}
          </Typography>
        </>
      </Box>
    </>
  );
};

export default Logout;

import { useCallback, useState } from 'react';
// import * as CONST from 'pages/FleetOverview/const';
import useCharts from './useCharts';

const useFleetOverview = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { getPieCharts, getBarCharts } = useCharts();
  const [pieCharts, setPieCharts] = useState();
  const [barCharts, setBarCharts] = useState();
  const [response, setResponse] = useState({});
  const [orgName] = useState('');

  const getFleetOverview = useCallback(
    async (fleetOverviewRes, fleetRes) => {
      try {
        setResponse({ fleetRes, fleetOverviewRes });

        // const newPieChartData = CONST.getPieCharts({ fleetOverviewRes });
        const newPieChartData = getPieCharts({ fleetOverviewRes });
        setPieCharts(newPieChartData);
        // const newBarCharts = CONST.getBarCharts({ fleetOverviewRes });
        const newBarCharts = getBarCharts({ fleetOverviewRes });
        setBarCharts(newBarCharts);
        const fleets = fleetRes?.fleets;
        const savingPotentialValues =
          fleetOverviewRes?.costs?.tire_related_savings_potential;
        let isTireRecommendationAvailable =
          fleetOverviewRes &&
          Object.values(
            fleetOverviewRes?.costs?.tire_related_savings_potential
          ).every(item => item.registered_vehicles === 0);
        const tcoEur =
          fleets?.length === 0
            ? savingPotentialValues?.tco_eur?.initially_accessed
            : isTireRecommendationAvailable
            ? savingPotentialValues?.tco_eur?.initially_accessed
            : savingPotentialValues?.tco_eur?.registered_vehicles;
        const newDashboardData = {
          totalPotentialSavings: tcoEur || 0,
          tireServiceProvider:
            fleetRes?.tire_management_data?.preferred_service_provider ||
            'Regional services at Vergölst',
        };
        setDashboardData(newDashboardData);
        setIsLoading(false);
      } catch (error) {
        console.error('E00001', error);
      }
    },
    [getBarCharts, getPieCharts]
  );

  return {
    getFleetOverview,
    dashboardData,
    isLoading,
    pieCharts,
    barCharts,
    orgName,
    response,
  };
};

export default useFleetOverview;

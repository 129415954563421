import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFleets, fleetOverviewSelector } from 'redux/fleets/ducks';
import {
  assignAccessLevel,
  deleteAssignedResources,
  getVehiclesForFleet,
} from 'services/fleet_service';
import { useTranslation } from 'react-i18next';
import { setAccessTokenSelector } from 'redux/users/ducks';
import { setNotification } from 'redux/notifications/ducks';
import { useNavigate, useLocation } from 'react-router-dom';
import { SelectAllBtn } from './SelectAllBtn';
import { VEHICLE } from 'utils/string_utils';
import LoadAnimation from 'components/LoadAnimation';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    grid: {
      // width: '90%',
      // backgroundColor: 'white',
      // padding: 15,
      marginTop: 10,
    },
    input: {
      backgroundColor: '#fff',
    },
    accordion: {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      marginBottom: 10,
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      // height: '80vh',
    },
    accordionDetailsContainer: {
      maxHeight: 175,
      overflowY: 'auto',
    },
    closeBtn: {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer',
    },
    info: {
      fontWeight: 700,
      fontStyle: 'italic',
    },
    common: {
      overflowY: 'scroll',
    },
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: '1px solid #D1D5DB',
      borderRadius: 8,
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      background: 'white',
    },
    selectAndSearchDiv: {
      display: 'flex',
      justifyContent: 'center',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    paper: {
      width: '80%',
      // width: '100%',
      height: 'auto',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      padding: '2rem 0rem',
    },
  };
});

const PUT_METHOD = 'PUT';
const DELETE_METHOD = 'DELETE';

const AssetsWhitelisting = props => {
  const { open, setOpen, userId, method, username, setLoading } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const access_token = useSelector(setAccessTokenSelector);
  const overview = useSelector(fleetOverviewSelector);
  const fleets = overview?.fleets;
  const [trucks, setTrucks] = useState([]);
  const [trucksStatic, setTrucksStatic] = useState([]);
  const [trailersStatic, setTrailersStatic] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [buses, setBuses] = useState([]);
  const [busesStatic, setBusesStatic] = useState([]);
  const [selectedTrucks, setSelectedTrucks] = useState([]);
  const [selectedTrucksStatic, setSelectedTrucksStatic] = useState([]);
  const [selectedTrailers, setSelectedTrailers] = useState([]);
  const [selectedTrailersStatic, setSelectedTrailersStatic] = useState([]);
  const [selectedBuses, setSelectedBuses] = useState([]);
  const [selectedBusesStatic, setSelectedBusesStatic] = useState([]);
  const [trailerResource, setTrailerResource] = useState([]);
  const [truckResource, setTruckResource] = useState([]);
  const [busResource, setBusResource] = useState([]);
  const [allFleets, setAllFleets] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetchingVehicles, setIsFetchingVehicles] = useState(false);
  const loc = useLocation();
  const navigate = useNavigate();

  const filterSearchHandler = (
    e,
    index,
    setVehicle,
    vehicle_type,
    staticVehicles
  ) => {
    let updated = JSON.parse(JSON.stringify(staticVehicles));
    let updatedArr = updated[index][vehicle_type];
    const searchQuery = e.target.value.toLowerCase();

    const filtered = updatedArr.filter(vehicle => {
      const lowerCaseName = vehicle.name.toLowerCase();
      return lowerCaseName.includes(searchQuery);
    });

    updated[index][vehicle_type] = filtered;
    setVehicle(updated);
    if (searchQuery.length === 0) {
      setVehicle(staticVehicles);
    }
  };
  const [allVehicles, setAllVehicles] = useState([]);

  const handleChange = async (e, value) => {
    let trucks_temp = [];
    let trailers_temp = [];
    let buses_temp = [];
    const selected_trucks = new Map();
    const selected_trailers = new Map();
    const selected_buses = new Map();
    if (value[value.length - 1]?.fleet_id) {
      try {
        setIsFetchingVehicles(true);
        const res = await getVehiclesForFleet(
          { fleet_id: value[value.length - 1]?.fleet_id },
          access_token,
          true
        );
        let seen = new Set();
        let updatedVehicles = allVehicles.concat(res.data.resp.vehicles);
        let uniqueVehicles = updatedVehicles.filter(vehicle => {
          if (seen.has(vehicle.vehicle_id)) {
            return false;
          } else {
            seen.add(vehicle.vehicle_id);
            return true;
          }
        });
        setAllVehicles(updatedVehicles);
        for (const element of value) {
          const vehiclesInFleet = uniqueVehicles.filter(
            vehicle => vehicle.fleet_id === element.fleet_id
          );

          if (vehiclesInFleet.length > 0) {
            let trucks_arr = vehiclesInFleet.filter(truck => {
              return truck.vehicle_type === 'TRUCK';
            });

            let trailers_arr = vehiclesInFleet.filter(trailer => {
              return trailer.vehicle_type === 'TRAILER';
            });
            let buses_arr = vehiclesInFleet.filter(bus => {
              return bus.vehicle_type === VEHICLE.vehicle_type.BUS;
            });

            trailers_arr.forEach(item => {
              setAllFleets(curr => {
                const temp = [...curr, item];
                const final = [...new Set(temp)];
                return final;
              });
            });
            trucks_arr.forEach(item => {
              setAllFleets(curr => {
                const temp = [...curr, item];
                const final = [...new Set(temp)];
                return final;
              });
            });
            buses_arr.forEach(item => {
              setAllFleets(curr => {
                const temp = [...curr, item];
                const final = [...new Set(temp)];
                return final;
              });
            });

            trucks_temp.push({
              fleet: element,
              trucks: trucks_arr,
            });
            trailers_temp.push({
              fleet: element,
              trailers: trailers_arr,
            });
            buses_temp.push({
              fleet: element,
              buses: buses_arr,
            });

            selected_trucks.set(element.fleet_id, trucks_arr);
            selected_trailers.set(element.fleet_id, trailers_arr);
            selected_buses.set(element.fleet_id, buses_arr);
          } else {
            setAllFleets(value);
          }
        }

        setSelectedTrucks(Object.fromEntries(selected_trucks));
        setSelectedTrucksStatic(Object.fromEntries(selected_trucks));
        setSelectedTrailers(Object.fromEntries(selected_trailers));
        setSelectedTrailersStatic(Object.fromEntries(selected_trailers));
        setSelectedBuses(Object.fromEntries(selected_buses));
        setSelectedBusesStatic(Object.fromEntries(selected_buses));
        setTrucks(trucks_temp);
        setTrucksStatic(trucks_temp);
        setTrailers(trailers_temp);
        setTrailersStatic(trailers_temp);
        setBuses(buses_temp);
        setBusesStatic(buses_temp);
      } catch (err) {
        console.error('E2113', err);
      } finally {
        setIsFetchingVehicles(false);
      }
    }

    if (value.length === 0) {
      setTruckResource([]);
      setTrailerResource([]);
      setBusResource([]);
      setTrailers([]);
      setTrucks([]);
      setBuses([]);
    }
  };

  const handleToggleTrucks = (fleet, vehicle) => {
    setSelectedTrucks(prevSelectedTrucks => {
      const isVehicleSelected = prevSelectedTrucks[fleet]
        .map(x => x.vehicle_id)
        .includes(vehicle.vehicle_id);

      const updatedTrucks = isVehicleSelected
        ? prevSelectedTrucks[fleet].filter(
            x => x.vehicle_id !== vehicle.vehicle_id
          )
        : [...prevSelectedTrucks[fleet], vehicle];

      return {
        ...prevSelectedTrucks,
        [fleet]: updatedTrucks,
      };
    });
  };

  const handleToggleBuses = (fleet, vehicle) => {
    setSelectedBuses(prevSelectedBuses => {
      const isVehicleSelected = prevSelectedBuses[fleet]
        .map(x => x.vehicle_id)
        .includes(vehicle.vehicle_id);

      const updatedBuses = isVehicleSelected
        ? prevSelectedBuses[fleet]?.filter(
            x => x.vehicle_id !== vehicle.vehicle_id
          )
        : [...prevSelectedBuses[fleet], vehicle];

      return {
        ...prevSelectedBuses,
        [fleet]: updatedBuses,
      };
    });
  };

  const handleToggleTrailers = (fleet, vehicle) => {
    setSelectedTrailers(prevSelectedTrailers => {
      const isVehicleSelected = prevSelectedTrailers[fleet]
        .map(x => x.vehicle_id)
        .includes(vehicle.vehicle_id);

      const updatedTrailers = isVehicleSelected
        ? prevSelectedTrailers[fleet]?.filter(
            x => x.vehicle_id !== vehicle.vehicle_id
          )
        : [...prevSelectedTrailers[fleet], vehicle];

      return {
        ...prevSelectedTrailers,
        [fleet]: updatedTrailers,
      };
    });
  };

  const reset = () => {
    setOpen(false);
    setIsSubmitting(false);
    setTrailers([]);
    setTrailersStatic([]);
    setTrucks([]);
    setTrucksStatic([]);
    setTrailerResource([]);
    setTruckResource([]);
    setBusResource([]);
    setBuses([]);
    setBusesStatic([]);
    setAllFleets([]);
    setSelectedTrailers([]);
    setSelectedBuses([]);
    setSelectedTrucks([]);
    setSelectedTrailersStatic([]);
    setSelectedBusesStatic([]);
    setSelectedTrucksStatic([]);
    setAllVehicles([]);
  };

  const checkedItemChangeHandler = (e, resource, vehicle) => {
    if (resource === VEHICLE.vehicle_type.TRUCKS) {
      setTruckResource(curr => {
        if (curr.findIndex(item => item.name === vehicle.name) !== -1) {
          return curr.filter(item => item.name !== vehicle.name);
        } else {
          return [...curr, vehicle];
        }
      });
    } else if (resource === VEHICLE.vehicle_type.BUSES) {
      setBusResource(curr => {
        if (curr.findIndex(item => item.name === vehicle.name) !== -1) {
          return curr.filter(item => item.name !== vehicle.name);
        } else {
          return [...curr, vehicle];
        }
      });
    } else {
      setTrailerResource(curr => {
        if (curr.findIndex(item => item.name === vehicle.name) !== -1) {
          return curr.filter(item => item.name !== vehicle.name);
        } else {
          return [...curr, vehicle];
        }
      });
    }
  };

  const submitHandler = async () => {
    setIsSubmitting(true);
    let fleetsData = {};
    allFleets.forEach(fleet => {
      const trailers = trailerResource
        .filter(item => item.fleet_id === fleet.fleet_id)
        .map(obj => obj.vehicle_id);

      const trucks = truckResource
        .filter(item => item.fleet_id === fleet.fleet_id)
        .map(obj => obj.vehicle_id);

      const buses = busResource
        .filter(item => item.fleet_id === fleet.fleet_id)
        .map(obj => obj.vehicle_id);

      if (trailers.length || trucks.length || buses.length) {
        let vehicles_temp = trucks.concat(trailers, buses);

        fleetsData[fleet.fleet_id] = {
          vehicles: vehicles_temp,
        };
      } else {
        fleetsData[fleet.fleet_id] = { vehicles: [] };
      }
    });

    const payload = {
      user_id: userId,
      fleets: fleetsData,
    };

    try {
      let res;
      if (method === PUT_METHOD) {
        res = await assignAccessLevel(access_token, payload);
      } else {
        res = await deleteAssignedResources(access_token, payload);
      }

      if (res?.status === 200) {
        setLoading(true);
        dispatch(
          setNotification(t('assetsWhitelisting.success'), 'success', true)
        );
        setOpen(false);
        reset();
        dispatch(fetchFleets());

        setLoading(false);

        if (loc.pathname.includes('invite')) {
          navigate('/settings');
        }
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      dispatch(setNotification(t('common.error'), 'error', true));
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkAssignedStatus = (selectedVehicles, fleet_id) => {
    const assigned = selectedVehicles?.[fleet_id].filter(
      v => v.fleet_id === fleet_id && v?.['whitelisted_users']?.includes(userId)
    );
    if (method === PUT_METHOD) return false;

    return assigned?.length === 0;
  };

  const checkArrLength = (selectedVehicles, fleet_id) => {
    const assigned = selectedVehicles[fleet_id].filter(
      v => v.fleet_id === fleet_id && v?.['whitelisted_users']?.includes(userId)
    );

    return assigned.length;
  };

  const renderTrucks = () => {
    if (trucks.length > 0 && overview.general_configs['truck']) {
      return (
        <Grid item lg={3}>
          <Typography gutterBottom variant='h5'>
            {t('assetsWhitelisting.selectTruck')}
          </Typography>
          <Box maxHeight={500} className={classes.common}>
            {trucks.map((item, i) => (
              <Accordion
                key={i}
                className={classes.accordion}
                defaultExpanded={method === DELETE_METHOD}
              >
                <AccordionSummary
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography variant='h6'>{item.fleet.fleet_name}</Typography>
                </AccordionSummary>

                {selectedTrucks[item.fleet.fleet_id] !== undefined && (
                  <Box className={classes.accordionDetailsContainer}>
                    <AccordionDetails className={classes.accordionDetails}>
                      <>
                        <Typography variant='h6'>
                          {t('assetsWhitelisting.currentlyAssigned')}
                          {checkArrLength(
                            selectedTrucksStatic,
                            item.fleet.fleet_id
                          ) > 0 ? (
                            selectedTrucksStatic[item.fleet.fleet_id]
                              .filter(v => {
                                return (
                                  v.fleet_id === item.fleet.fleet_id &&
                                  v?.['whitelisted_users']?.includes(userId)
                                );
                              })
                              .map(assigned => (
                                <Chip
                                  key={assigned.vehicle_id}
                                  label={assigned.name}
                                  size='small'
                                />
                              ))
                          ) : (
                            <span className={classes.info}>
                              {t('assetsWhitelisting.noTrucksToDel')}
                            </span>
                          )}
                        </Typography>
                        <div className={classes.selectAndSearchDiv}>
                          <div className={classes.searchBar}>
                            <IconButton aria-label='search'>
                              <Search color='primary' />
                            </IconButton>
                            <InputBase
                              placeholder='Search...'
                              fullWidth
                              inputProps={{ 'aria-label': 'search' }}
                              onChange={e =>
                                filterSearchHandler(
                                  e,
                                  i,
                                  setTrucks,
                                  'trucks',
                                  trucksStatic
                                )
                              }
                            />
                          </div>

                          <SelectAllBtn
                            setTruck={setTruckResource}
                            setTrailer={setTrailerResource}
                            selectedTrucksStatic={selectedTrucksStatic}
                            selectedTrailersStatic={selectedTrailersStatic}
                            fleet_id={item?.fleet?.fleet_id}
                            resource={VEHICLE.vehicle_type.TRUCKS}
                            userHasVehicles={checkAssignedStatus}
                            method={method}
                            vehicles={item?.trucks}
                          />
                        </div>
                      </>
                      {renderAssignedTrucks(item)}
                    </AccordionDetails>
                  </Box>
                )}
              </Accordion>
            ))}
          </Box>
        </Grid>
      );
    }
  };

  const isButtonEnabled = () => {
    return (
      truckResource.length > 0 ||
      trailerResource.length > 0 ||
      busResource.length > 0
    );
  };

  const renderBuses = () => {
    if (
      buses?.length > 0 &&
      overview.general_configs[VEHICLE.vehicle_type.BUS]
    ) {
      return (
        <Grid item lg={3}>
          <Typography gutterBottom variant='h5'>
            {t('assetsWhitelisting.selectBus')}
          </Typography>
          <Box maxHeight={500} className={classes.common}>
            {buses?.map((item, i) => (
              <Accordion
                key={i}
                className={classes.accordion}
                defaultExpanded={method === DELETE_METHOD}
              >
                <AccordionSummary
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography variant='h6'>{item.fleet.fleet_name}</Typography>
                </AccordionSummary>

                {selectedBuses[item.fleet.fleet_id] !== undefined && (
                  <Box className={classes.accordionDetailsContainer}>
                    <AccordionDetails className={classes.accordionDetails}>
                      <>
                        <Typography variant='h6'>
                          {t('assetsWhitelisting.currentlyAssigned')}
                          {checkArrLength(
                            selectedBusesStatic,
                            item.fleet.fleet_id
                          ) > 0 ? (
                            selectedBusesStatic[item.fleet.fleet_id]
                              .filter(
                                v =>
                                  v.fleet_id === item.fleet.fleet_id &&
                                  v?.['whitelisted_users']?.includes(userId)
                              )
                              .map(assigned => (
                                <Chip
                                  key={assigned.vehicle_id}
                                  label={assigned.name}
                                  size='small'
                                />
                              ))
                          ) : (
                            <span className={classes.info}>
                              {t('assetsWhitelisting.noBusesToDel')}
                            </span>
                          )}
                        </Typography>
                        <div className={classes.selectAndSearchDiv}>
                          <div className={classes.searchBar}>
                            <IconButton aria-label='search'>
                              <Search color='primary' />
                            </IconButton>
                            <InputBase
                              placeholder='Search...'
                              fullWidth
                              inputProps={{ 'aria-label': 'search' }}
                              onChange={e =>
                                filterSearchHandler(
                                  e,
                                  i,
                                  setBuses,
                                  'buses',
                                  busesStatic
                                )
                              }
                            />
                          </div>
                          <SelectAllBtn
                            setTruck={setTruckResource}
                            setTrailer={setTrailerResource}
                            selectedTrucksStatic={selectedBusesStatic}
                            selectedTrailersStatic={selectedTrailersStatic}
                            setBus={setBusResource}
                            selectedBusesStatic={selectedBusesStatic}
                            fleet_id={item?.fleet?.fleet_id}
                            resource={VEHICLE.vehicle_type.BUSES}
                            userHasVehicles={checkAssignedStatus}
                            method={method}
                            vehicles={item?.buses}
                          />
                        </div>
                      </>
                      {renderAssignedBuses(item)}
                    </AccordionDetails>
                  </Box>
                )}
              </Accordion>
            ))}
          </Box>
        </Grid>
      );
    }
  };

  const renderAssignedVehicles = (item, selectedCategory) => {
    let assignedVehicles = [];
    assignedVehicles = selectedCategory[item.fleet.fleet_id].filter(
      v =>
        v.fleet_id === item.fleet.fleet_id &&
        v?.['whitelisted_users']?.includes(userId)
    );
    return assignedVehicles;
  };

  const renderAssignedBuses = item => {
    if (!checkAssignedStatus(selectedBusesStatic, item.fleet.fleet_id)) {
      const vehiclesList = renderAssignedVehicles(item, selectedBusesStatic);

      return (
        <List className={classes.input}>
          {vehiclesList.length
            ? vehiclesList?.map((vehicle, i) => (
                <ListItem
                  key={vehicle.name}
                  dense
                  button
                  onClick={() =>
                    handleToggleBuses(item.fleet.fleet_id, vehicle)
                  }
                >
                  <ListItemIcon>
                    {
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              edge='start'
                              checked={
                                busResource.findIndex(
                                  item => item.name === vehicle.name
                                ) !== -1
                              }
                              color='primary'
                              onChange={e =>
                                checkedItemChangeHandler(
                                  e,
                                  VEHICLE.vehicle_type.BUSES,
                                  vehicle
                                )
                              }
                              disableRipple
                            />
                          }
                          label={vehicle.name}
                        />
                      </FormGroup>
                    }
                  </ListItemIcon>
                </ListItem>
              ))
            : t('assetsWhitelisting.noBuses')}
        </List>
      );
    }
  };

  const renderAssignedTrucks = item => {
    if (!checkAssignedStatus(selectedTrucksStatic, item.fleet.fleet_id)) {
      const vehiclesList = renderAssignedVehicles(item, selectedTrucksStatic);

      return (
        <List className={classes.input}>
          {vehiclesList.length
            ? vehiclesList.map((vehicle, i) => (
                <ListItem
                  key={vehicle.name}
                  dense
                  button
                  onClick={() =>
                    handleToggleTrucks(item.fleet.fleet_id, vehicle)
                  }
                >
                  <ListItemIcon>
                    {
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              edge='start'
                              checked={
                                truckResource.findIndex(
                                  item => item.name === vehicle.name
                                ) !== -1
                              }
                              color='primary'
                              onChange={e =>
                                checkedItemChangeHandler(
                                  e,
                                  VEHICLE.vehicle_type.TRUCKS,
                                  vehicle
                                )
                              }
                              disableRipple
                            />
                          }
                          label={vehicle.name}
                        />
                      </FormGroup>
                    }
                  </ListItemIcon>
                </ListItem>
              ))
            : t('assetsWhitelisting.noTrucks')}
        </List>
      );
    }
  };

  const renderAssignedTrailers = item => {
    const vehiclesList = renderAssignedVehicles(item, selectedTrailersStatic);
    if (!checkAssignedStatus(selectedTrailersStatic, item.fleet.fleet_id)) {
      return (
        <List className={classes.input}>
          {vehiclesList.length
            ? vehiclesList.map((vehicle, i) => (
                <ListItem
                  key={vehicle.name}
                  dense
                  button
                  onClick={() =>
                    handleToggleTrailers(item.fleet.fleet_id, vehicle)
                  }
                >
                  <ListItemIcon>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            edge='start'
                            checked={
                              trailerResource.findIndex(
                                item => item.name === vehicle.name
                              ) !== -1
                            }
                            color='primary'
                            onChange={e =>
                              checkedItemChangeHandler(
                                e,
                                VEHICLE.vehicle_type.TRAILERS,
                                vehicle
                              )
                            }
                            disableRipple
                          />
                        }
                        label={vehicle.name}
                      />
                    </FormGroup>
                  </ListItemIcon>
                </ListItem>
              ))
            : t('assetsWhitelisting.noTrailers')}
        </List>
      );
    }
  };

  const renderTrailers = () => {
    if (
      trailers.length > 0 &&
      overview.general_configs[VEHICLE.vehicle_type.TRAILER]
    ) {
      return (
        <Grid item lg={3}>
          <Typography gutterBottom variant='h5'>
            {t('assetsWhitelisting.selectTrailer')}
          </Typography>
          <Box maxHeight={500} className={classes.common}>
            {trailers.map((item, i) => (
              <Accordion
                key={i}
                className={classes.accordion}
                defaultExpanded={method === DELETE_METHOD}
              >
                <AccordionSummary
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography variant='h6'>{item.fleet.fleet_name}</Typography>
                </AccordionSummary>

                {selectedTrailers[item.fleet.fleet_id] !== undefined && (
                  <Box className={classes.accordionDetailsContainer}>
                    <AccordionDetails className={classes.accordionDetails}>
                      <>
                        <Box maxHeight={'75px'} overflow={'auto'}>
                          <Typography variant='h6'>
                            {t('assetsWhitelisting.currentlyAssigned')}
                            {checkArrLength(
                              selectedTrailersStatic,
                              item.fleet.fleet_id
                            ) > 0 ? (
                              selectedTrailersStatic[item.fleet.fleet_id]
                                .filter(
                                  v =>
                                    v.fleet_id === item.fleet.fleet_id &&
                                    v?.['whitelisted_users']?.includes(userId)
                                )
                                .map(assigned => (
                                  <Chip
                                    key={assigned.vehicle_id}
                                    label={assigned.name}
                                    size='small'
                                  />
                                ))
                            ) : (
                              <span className={classes.info}>
                                {t('assetsWhitelisting.noTrailersToDel')}
                              </span>
                            )}
                          </Typography>
                        </Box>
                        <div className={classes.selectAndSearchDiv}>
                          <div className={classes.searchBar}>
                            <IconButton aria-label='search'>
                              <Search color='primary' />
                            </IconButton>
                            <InputBase
                              placeholder='Search...'
                              fullWidth
                              inputProps={{ 'aria-label': 'search' }}
                              onChange={e =>
                                filterSearchHandler(
                                  e,
                                  i,
                                  setTrailers,
                                  'trailers',
                                  trailersStatic
                                )
                              }
                            />
                          </div>
                          <SelectAllBtn
                            setTruck={setTruckResource}
                            setTrailer={setTrailerResource}
                            selectedTrucksStatic={selectedTrucksStatic}
                            selectedTrailersStatic={selectedTrailersStatic}
                            fleet_id={item.fleet.fleet_id}
                            resource={VEHICLE.vehicle_type.TRAILERS}
                            userHasVehicles={checkAssignedStatus}
                            method={method}
                            vehicles={item?.trailers}
                          />
                        </div>
                      </>
                      {renderAssignedTrailers(item)}
                    </AccordionDetails>
                  </Box>
                )}
              </Accordion>
            ))}
          </Box>
        </Grid>
      );
    }
  };

  return (
    <>
      <LoadAnimation loading={isFetchingVehicles} />
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={(e, reason) => {
          reason === 'escapeKeyDown' && reset();
        }}
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
        className={classes.modal}
      >
        <Paper
          sx={{
            maxHeight: '80%',
            overflow: 'auto',
            width: '90%',
            backgroundColor: 'white',
            padding: '2rem',
            position: 'relative',
          }}
        >
          <Grid className={classes.grid} container spacing={3} width='100%'>
            <Grid item xs={12}>
              <Typography variant='h5'>{`${
                method === PUT_METHOD
                  ? t('userManagement.assignResources')
                  : t('userManagement.deleteResources')
              } (${username})`}</Typography>
            </Grid>
            <IconButton className={classes.closeBtn} onClick={reset}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid className={classes.grid} container spacing={3} width='100%'>
            <Grid item lg={3}>
              <Typography gutterBottom variant='h5'>
                {t('multiLayerReport.selectFleet')}
              </Typography>
              <Autocomplete
                multiple
                id='tags-outlined'
                options={
                  method !== PUT_METHOD
                    ? fleets?.filter(item =>
                        item?.['whitelisted_users']?.includes(userId)
                      )
                    : fleets
                }
                getOptionLabel={option => option.fleet_name}
                filterSelectedOptions
                onChange={handleChange}
                noOptionsText={
                  !allFleets.length
                    ? t('assetsWhitelisting.noFleets')
                    : 'No options'
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    style={{ backgroundColor: '#fff' }}
                    variant='outlined'
                    label='Fleets'
                  />
                )}
              />
            </Grid>
            {renderTrucks()}
            {renderBuses()}
            {renderTrailers()}
            <Box
              width='100%'
              display='flex'
              flexDirection='column'
              px={2}
              py={2}
            >
              <Button
                variant='contained'
                color='primary'
                disabled={isSubmitting || !isButtonEnabled()}
                style={{
                  alignSelf: 'flex-end',
                  width: isSubmitting ? 105 : 'initial',
                }}
                onClick={submitHandler}
              >
                {isSubmitting ? (
                  <CircularProgress size={30} color='inherit' />
                ) : (
                  t('common.submitBtn')
                )}
              </Button>
            </Box>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

export default AssetsWhitelisting;

import React from 'react';

import {
  Badge,
  ClickAwayListener,
  IconButton,
  InputBase,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Search } from '@mui/icons-material';
import { ReactComponent as Filter } from 'assets/svg/common/filterIcon.svg';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      width: 355,
      border: '1px solid #D1D5DB',
      borderRadius: 8,
    },
  };
});

const SearchBar = ({
  setOpen,
  filterCounter,
  setSearchQuery,
  show,
  setShow,
  placeholder,
  height,
  width,
  hook,
  debouncedSearch = () => {},
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const handleSearch = event => {
    setSearchQuery(event.target.value);
    if (event.target.value.length > 0) {
      debouncedSearch(event);
      // !!setShow && setTimeout(() => setShow(true), 3000);
      setShow(true);
    } else {
      !!setShow && setShow(false);
      setSearchQuery(null);
    }
  };
  const handleOverviewSearch = event => {
    if (!!setShow) {
      event.target.value.length >= 1 && setShow(true);
    }
  };
  return (
    <div
      className={classes.searchBar}
      style={{
        width: width || 355,
        height: height || 'inherit',
        backgroundColor: '#fff',
      }}
    >
      <IconButton aria-label='search'>
        <Search color='primary' />
      </IconButton>
      <ClickAwayListener onClickAway={() => !!setShow && setShow(false)}>
        <InputBase
          placeholder={placeholder || `${t('common.search')}...`}
          fullWidth
          name='common-search'
          data-testid='search-bar'
          data-hook={`search-bar-${hook}`}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearch}
          onMouseDown={handleOverviewSearch}
        />
      </ClickAwayListener>
      {!!setOpen && (
        <IconButton aria-label='filter' onClick={() => setOpen(true)}>
          <Badge badgeContent={filterCounter} color='primary'>
            <Filter fill={theme.palette.primary.main} />
          </Badge>
        </IconButton>
      )}
    </div>
  );
};

export default SearchBar;

import React from 'react';
import { Button } from '@mui/material';
import Box from '@mui/system/Box';
import { VEHICLE } from 'utils/string_utils';
import { vehicle_resource } from '../const';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { fleetOverviewSelector } from 'redux/fleets/ducks';
const AxleConfig = ({ configData, axle, setAxle }) => {
  const overview = useSelector(fleetOverviewSelector);

  React.useEffect(() => {
    const axle1Percentage =
      overview.general_configs?.[configData.vehicle_type.toLowerCase()]
        ?.axle_1_perc;
    const axle2Percentage =
      overview.general_configs?.[configData.vehicle_type.toLowerCase()]
        ?.axle_2_perc;
    const axleType =
      overview.general_configs?.[configData.vehicle_type.toLowerCase()]?.[
        axle1Percentage > axle2Percentage ? 'axle_type_1' : 'axle_type_2'
      ];

    setAxle(axleType);
    // eslint-disable-next-line
  }, [configData]);

  return (
    <Box
      width='100%'
      display='flex'
      justifyContent='space-evenly'
      flexWrap='wrap'
      rowGap={'5px'}
      marginTop={'30px'}
      data-hook='axle-config'
    >
      {vehicle_resource[configData.vehicle_type.toLowerCase()]?.axle_types.map(
        (item, i) => (
          <Button
            key={i}
            variant={
              configData.vehicle_type === VEHICLE.vehicle_type.TRUCK1 ||
              configData.vehicle_type === VEHICLE.vehicle_type.BUS1
                ? 'outlined'
                : 'contained'
            }
            color='primary'
            disableElevation
            style={{
              textTransform: 'unset',
              opacity: axle !== item && axle !== null ? 0.5 : 1,
            }}
            onClick={() => {
              if (axle === item) {
                setAxle(null);
              } else {
                setAxle(item);
              }
            }}
            data-hook='set-axle'
            disabled={axle !== item && axle !== null ? true : false}
          >
            <Box>
              {configData.vehicle_type === VEHICLE.vehicle_type.TRUCK1 ||
              configData.vehicle_type === VEHICLE.vehicle_type.BUS1 ? (
                <img
                  src={require(`assets/images/${item}.png`)}
                  alt='vehicle type'
                />
              ) : (
                item
              )}
            </Box>
          </Button>
        )
      )}
    </Box>
  );
};

export default AxleConfig;

AxleConfig.propTypes = {
  configData: PropTypes.object,
  axle: PropTypes.any,
  setAxle: PropTypes.func,
};

import React, { memo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Chip,
  Paper,
  Typography,
  useTheme,
  // makeStyles,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as Truck } from 'assets/svg/common/truckIcon.svg';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { formattedValueWithAbs } from '../VehiclePage/TireRecommendations/CustomisableTireRecommendations';
import { useTranslation } from 'react-i18next';
import { VEHICLE } from 'utils/string_utils';
import { filteringRecommendationOptions } from '../VehiclePage/TireRecommendations/const';

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CustomTable from 'components/custom/CustomTable';

import { ReactComponent as Trailer } from 'assets/svg/common/trailerIcon.svg';
import { ReactComponent as Bus } from 'assets/svg/common/busLogo.svg';
import { fleetOverviewSelector, setRecDataSelector } from 'redux/fleets/ducks';
import { TireRecommendations } from 'components/VehicleOverview/TireRecommendations';
const useStyles = makeStyles()(theme => {
  return {
    boxstyle: {
      maxWidth: '150px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      textAlign: 'center',
    },
    metricBoxStyle: {
      maxWidth: '150px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',

      textAlign: 'center',
    },
    iconStyles: {
      height: 40,
      width: 40,
    },
    nav: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
  };
});

const TireRecomm = memo(({ tracked }) => {
  const overview = useSelector(fleetOverviewSelector);

  const [active, setActive] = useState(null);
  const rec = useSelector(setRecDataSelector);
  const [recommdationData, setRecommdationData] = useState(null);
  // eslint-disable-next-line
  const [fallBack, setFallback] = useState({
    status: false,
    message: null,
  });
  // eslint-disable-next-line
  const [activeColumns, setActiveColumns] = useState(itemsToShow);

  let tireRecommendationFilterNames = {};
  filteringRecommendationOptions.forEach(({ name }) => {
    tireRecommendationFilterNames[name] = false;
  });
  // eslint-disable-next-line

  useEffect(() => {
    if (active === null) {
      if (rec) {
        setActive(Object.keys(rec)[0]);
        setRecommdationData(rec[Object.keys(rec)[0]]);
      }
    }
    // eslint-disable-next-line
  }, [rec]);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const vehicleTypeImageMapping = {
    [VEHICLE.vehicle_type.BUS1]: <Bus />,
    [VEHICLE.vehicle_type.TRAILER1]: (
      <Trailer
        className={classes.iconStyles}
        fill={theme.palette.primary.main}
      />
    ),
    [VEHICLE.vehicle_type.TRUCK1]: (
      <Truck className={classes.iconStyles} fill={theme.palette.primary.main} />
    ),
  };

  const columns = [
    {
      key: 'name',
      title: t('fleetsSection.vehicleName'),
      dataHook: 'rec-vehicle-name',
      render: row => (
        <Link
          data-hook={`navigate_to_${row.vehicle_id}`}
          color='primary'
          to={`/fleets-data/${row.fleet_id}/${row.vehicle_type.toLowerCase()}${
            row.vehicle_type.toLowerCase() === 'bus' ? 'es' : 's'
          }/${row.vehicle_id}`}
          className={classes.nav}
        >
          {row.name}
        </Link>
      ),
      canSort: true,
    },

    {
      key: 'vehicle_id',
      title: 'Vehicle Id',
      canSort: true,
      render: row => row.vehicle_id,
      dataHook: 'rec-vehicle-type',
    },
    {
      key: 'vehicle_type',
      title: t('homePage.vehicleType'),
      canSort: true,
      render: row => vehicleTypeImageMapping[row.vehicle_type],
      dataHook: 'rec-vehicle-type',
    },
    {
      key: 'fleet_id',
      title: t('homePage.taskBoard.fleet'),
      canSort: true,
      render: row =>
        overview?.fleets?.filter(x => x.fleet_id === row.fleet_id)[0][
          'fleet_name'
        ],
      dataHook: 'rec-vehicle-type',
    },
  ];
  function isEmpty(val) {
    return val === undefined || val === null || val === 0 ? true : false;
  }

  const renderFallBackComponent = () => {
    if (fallBack?.status) {
      return (
        <Box
          my={2}
          style={{
            display: 'flex',
            width: '100%',
            padding: '12px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '12px',
            border: '1px solid var(--default-gray-200, #E5E7EB)',
            background: ' var(--Light-grey, #F6F9F7)',
          }}
        >
          <Typography
            variant='subtitle1'
            fontWeight='bold'
            gutterBottom
            align='center'
            style={{ color: 'black' }}
            data-hook={'fallback-message'}
          >
            {fallBack?.message}
          </Typography>
        </Box>
      );
    }
    return (
      <Box my={2}>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            padding: '12px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '12px',
          }}
          my='1rem'
        >
          <Box className={classes.boxstyle}>
            <Typography variant='h6' data-hook='amortization'>
              {t('tireRecommendation.header.amortization')}
            </Typography>
          </Box>
          <Box className={classes.boxstyle}>
            <Typography variant='h6' data-hook='savings'>
              {t('tireRecommendation.header.savingsInTco')}{' '}
            </Typography>
          </Box>
          <Box className={classes.boxstyle}>
            <Typography variant='h6' data-hook='estimated-km-price'>
              {t('tireRecommendation.header.estimatedKmPrice')}
            </Typography>
          </Box>
          <Box className={classes.boxstyle}>
            <Typography variant='h6' data-hook='theoretical-mileage'>
              {t('tireRecommendation.header.theoreticalMileage')}{' '}
            </Typography>
          </Box>
          <Box className={classes.boxstyle}>
            <Typography variant='h6' data-hook='monthly-distance'>
              {t('tireRecommendation.header.monthlyDistance')}{' '}
            </Typography>
          </Box>
          <Box className={classes.boxstyle}>
            <Typography variant='h6' data-hook='fuel-save'>
              {t('tireRecommendation.header.fuelSaveLperKM')}
            </Typography>
          </Box>

          <Box className={classes.boxstyle}>
            <Typography variant='h6' data-hook='estimated-price-all-tires'>
              {t('tireRecommendation.header.estimatedPriceAllTires')}
            </Typography>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            padding: '12px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '12px',
            border: '1px solid var(--default-gray-200, #E5E7EB)',
            background: ' var(--Light-grey, #F6F9F7)',
          }}
          my='1rem'
        >
          {!isEmpty(recommdationData?.roi_in_months) ? (
            <Box className={classes.metricBoxStyle}>
              <Typography
                variant='h6'
                align='center'
                color='secondary'
                data-hook='roi-in-months'
              >
                {formattedValueWithAbs(recommdationData?.roi_in_months)}
              </Typography>
            </Box>
          ) : (
            <Typography variant='h6' align='center' color='secondary'>
              {'N/A'}
            </Typography>
          )}
          {!isEmpty(
            recommdationData?.estimated_savings_potential_tco_lifetime
          ) ? (
            <Box className={classes.metricBoxStyle}>
              <Typography variant='h6' data-hook='tco-lifetime'>
                {formattedValueWithAbs(
                  recommdationData?.estimated_savings_potential_tco_lifetime
                )}
              </Typography>
            </Box>
          ) : (
            <Typography variant='h6' align='center' color='secondary'>
              {'N/A'}
            </Typography>
          )}
          {!isEmpty(recommdationData?.estimated_cost_100_km) ? (
            <Box className={classes.metricBoxStyle}>
              <Typography variant='h6' data-hook='estimated-km-price-tires'>
                {formattedValueWithAbs(recommdationData?.estimated_cost_100_km)}
              </Typography>
            </Box>
          ) : (
            <Typography variant='h6' align='center' color='secondary'>
              {'N/A'}
            </Typography>
          )}
          {!isEmpty(recommdationData?.theoretical_mileage) ? (
            <Box className={classes.metricBoxStyle}>
              <Typography variant='h6' data-hook='theoretical-mileage'>
                {formattedValueWithAbs(recommdationData?.theoretical_mileage)}
              </Typography>
            </Box>
          ) : (
            <Typography variant='h6' align='center' color='secondary'>
              {'N/A'}
            </Typography>
          )}
          {!isEmpty(recommdationData?.avg_monthly_distance) ? (
            <Box className={classes.metricBoxStyle}>
              <Typography variant='h6' data-hook='avg-monthly-distance'>
                {formattedValueWithAbs(recommdationData?.avg_monthly_distance)}
              </Typography>
            </Box>
          ) : (
            <Typography variant='h6' align='center' color='secondary'>
              {'N/A'}
            </Typography>
          )}
          {!isEmpty(recommdationData?.fuel_save_l_per_100km) ? (
            <Box className={classes.metricBoxStyle}>
              <Typography variant='h6' data-hook='fuel-save'>
                {formattedValueWithAbs(recommdationData?.fuel_save_l_per_100km)}
              </Typography>
            </Box>
          ) : (
            <Typography variant='h6' align='center' color='secondary'>
              {'N/A'}
            </Typography>
          )}
          {!isEmpty(recommdationData?.estimated_price_all_tires) ? (
            <Box className={classes.metricBoxStyle}>
              <Typography
                variant='h6'
                data-hook='estimated-price_all_tires-save'
              >
                {formattedValueWithAbs(
                  recommdationData?.estimated_price_all_tires
                )}
              </Typography>
            </Box>
          ) : (
            <Typography variant='h6' align='center' color='secondary'>
              {'N/A'}
            </Typography>
          )}
        </Box>

        {recommdationData?.vehicles ? (
          <Box>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1-content'
                id='panel1-header'
              >
                <Typography variant='subtitle1' align='center' gutterBottom>
                  {t('common.vehicles')}
                </Typography>

                <Chip
                  label={recommdationData.vehicles_count}
                  color='primary'
                  sx={{ marginLeft: 2 }}
                />
              </AccordionSummary>
              <AccordionDetails>
                <CustomTable
                  data={recommdationData?.vehicles}
                  columns={columns}
                  revealedColumns={activeColumns}
                  rowsPerPage={recommdationData?.vehicles.length}
                  showFooter={false}
                  isScroll={true}
                  data-hook='fleets-table'
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        ) : (
          <Box>
            <Typography variant='h6'>{t('common.no_vehicles')}</Typography>
          </Box>
        )}
      </Box>
    );
  };

  const renderRecommendations = () => {
    if (rec) {
      return (
        <>
          <Paper
            style={{
              width: '100%',
              padding: '16px 20px',
              boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
            }}
          >
            <Box style={{ marginBottom: '1rem' }}>
              <Typography
                variant='subtitle1'
                gutterBottom
                fontWeight={900}
                data-hook='tire-recommendation'
              >
                {t('vehiclePage.recommendation')}
              </Typography>
            </Box>
            <Box display='flex' flexDirection={'row'}>
              {Object.keys(rec)?.map((item, i) => (
                <Card
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '32px',
                    padding: ' 16px',
                    borderRadius: 16,
                    cursor: 'pointer',
                    border:
                      item === active
                        ? `1px solid var(--extended-lime-600, ${theme.palette.primary.main})`
                        : '1px solid var(--default-gray-200, #E5E7EB)',
                    background:
                      item === active ? 'var(--Light-grey, #F6F9F7)' : '#fff',
                    margin: '0rem 1rem',
                    boxShadow: 'none',
                  }}
                  data-hook={`vehicle-card-${i}`}
                  onClick={() => {
                    setActive(item);
                    setRecommdationData(rec[item]);
                  }}
                >
                  <Typography variant='h6' fontWeight='bold'>
                    {item.split('_').join(' ')}
                  </Typography>
                </Card>
              ))}
            </Box>
            <TireRecommendations active={active} tireNames={rec} />
            {renderFallBackComponent()}
          </Paper>
        </>
      );
    }
  };

  return <>{renderRecommendations()}</>;
});

export default TireRecomm;
const itemsToShow = [
  'name',
  'fleet_id',
  'vehicle_type',
  'telematicsProvider',
  'axle_type',
  'internal_grouping',
  'actions',
  'vehicle_id',
];

import React, { useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  Backdrop,
  Button,
  Chip,
  Fab,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
} from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import {
  ArrowDropDown,
  Edit,
  Eject,
  HomeOutlined,
  Publish,
  Close,
} from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { setNotification } from 'redux/notifications/ducks';
import { setAccessTokenSelector } from 'redux/users/ducks';
import {
  addTire,
  checkTire,
  fleetOverview,
  getVehiclesForFleet,
  unmountOptions,
  updateTire,
} from 'services/fleet_service';
import { useTranslation } from 'react-i18next';
import {
  event_types,
  tireOperationsOptions,
  TIRES,
  tireStatuses,
} from 'utils/string_utils';
import {
  debouncedCommonChangeHandler,
  debouncedTireIdChangeHandler,
} from 'services/util_services';
import {
  internalAxleName,
  positionNames,
  stringLookup,
} from 'pages/VehiclePage/TireConfig/const';
import {
  setChangeInVehicle,
  setFleetOverview,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  setVehiclesForFleet,
  unprocessedTireInventoryDataSelector,
  vehicleUpdatedSelector,
} from 'redux/fleets/ducks';
import { fetchTireInventoryDataAndProcess } from 'pages/TireInventory/TireInventory';
import CustomTextField from 'components/custom/CustomTextField';
import TireForm from 'components/TireForm';
import i18next from 'i18next';
import CustomDatePicker from 'components/custom/CustomDatePicker';
import LoadAnimation from 'components/LoadAnimation';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      height: 'auto',
      border: 'none',

      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 3rem',
      maxWidth: '1000px',
      position: 'relative',
    },
    back: {
      position: 'absolute',
      left: 5,
      top: '50%',
      transform: 'translate(0, -50%)',
    },
    applyBtn: {
      textTransform: 'unset',
      borderRadius: 24,
      flex: '10%',
    },
    uploadBox: {
      fontStyle: 'italic',
      textTransform: 'unset',
    },
    navBtns: {
      textTransform: 'unset',
    },
    link: {
      textTransform: 'uppercase',
      padding: 5,
      color: theme.palette.primary.main,
      fontSize: theme.typography.h6.fontSize,

      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    fullWidth: {
      width: '100%',
    },
  };
});

export function generateRandomCharacters() {
  let generatedOutput = '';
  let storedCharacters =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let totalCharacterSize = storedCharacters.length;
  for (let index = 0; index < 5; index++) {
    generatedOutput += storedCharacters.charAt(
      Math.floor(Math.random() * totalCharacterSize)
    );
  }
  return generatedOutput;
}

const TireOperationsModal = ({
  open,
  setOpen,
  tire,
  size,
  newConfig,
  index,
  axle,
  addOns,
  setAddOns,
  staticConfig,
  usedTires,
  setUsedTires,
  usageMileage,
  tireTreadDepth,
  mountedTires,
  initialOption,
  setThisTire,
  vehicleType,
  vehicle,
  tireType = '',
}) => {
  const { classes } = useStyles();
  const access_token = useSelector(setAccessTokenSelector);

  const tireInfo = newConfig?.[tire.type]?.[tire.side]?.[index];
  const params = useParams();
  const { fleet_id } = params;
  const [tiresDb, setTiresDb] = useState([]);
  const [availableTires, setAvailableTires] = useState([]);
  const [selectedTire, setSelectedTire] = useState('');
  const [presentTire, setPresentTire] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [option, setOption] = useState(initialOption ?? 0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tireBrand, setTireBrand] = useState(null);
  const [productLine, setProductLine] = useState(null);
  const [tireSize, setTireSize] = useState(vehicle.tire_size?.[tireType]);
  const [initialId, setInitialId] = useState('');
  const [errors, setErrors] = useState({
    [TIRES.TIRE_ID]: null,
    [TIRES.TREAD_DEPTH]: null,
    [TIRES.PRESSURE]: null,
  });
  const [isAutoGenerated, setIsAutoGenerated] = useState(true);
  const { t } = useTranslation();
  const [axleName, setAxleName] = useState(null);
  const [unmountReason, setUnmountReason] = useState(null);
  const [unmountOption, setUnmountOption] = useState(null);
  const [eventDate, setEventDate] = useState(new Date());
  const [newTireMountDate, setNewTireMountDate] = useState(new Date());
  const [currentStep, setCurrentStep] = useState(0);
  const [fieldIsTouched, setFieldIsTouched] = useState(false);
  const steps = [t('common.unmount'), t('common.mount')];
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);
  const [payload, setPayload] = useState({
    isRetread: false,
    [TIRES.TREAD_DEPTH]: '',
    tire_id: '',
    mileage: '',
    km: '',
    isRegrooved: false,
  });
  let disableCondition;
  if (currentStep === 0) {
    disableCondition = unmountOption === null || eventDate === null;
  } else {
    disableCondition =
      unmountOption === null ||
      selectedTire === null ||
      selectedTire === undefined ||
      selectedTire === '' ||
      JSON.stringify(finalDate) === 'null';
  }

  let dispatch = useDispatch();
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);
  const [initialPayload, setInitialPayload] = useState(null);
  const [changedFieldCount, setChangedFieldCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [selectedPositions, setSelectedPositions] = useState([]);
  const [showPositions, setShowPositions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const tireInventoryDetails = useSelector(state => state.fleet.overviewData);
  const emptyPositions = positionNames[axle].filter(item => {
    let y = mountedTires.map(tire => tire.position);
    const firstLetter = axleName?.charAt(0);
    return !y.includes(item) && item.includes(firstLetter);
  });
  const [checked, setChecked] = useState(false);
  const [isNewTire, setNewTire] = useState(false);
  const [lastTireId, setLastTireId] = useState(null);
  const handleChangeSwitch = event => {
    setChecked(event.target.checked);
  };
  const [grooves, setGrooves] = useState({
    1: null,
  });

  useEffect(() => {
    const getTires = async () => {
      setIsLoading(true);
      try {
        const data = await fetchTireInventoryDataAndProcess({
          fleet_id,
          inventoryData: fleetInventoryData,
          refetch: true,
          location: 'ALL',
          isPaginated: true,
          pageNo: 0,
          perpage: tireInventoryDetails.metrics[fleet_id].total_tires,
          isReports: true,
        });
        if (data?.originalData?.length > 0) {
          dispatch(setTireInventoryData(data, fleet_id));
        }
        setTiresDb(data?.originalData);
      } catch (error) {
        console.error('E20057', error);
      } finally {
        setIsLoading(false);
      }
    };

    const getInitialId = async () => {
      let isUsed = true;
      try {
        while (isUsed) {
          const newId = generateRandomCharacters();
          const res_ = await checkTire(newId);
          if (res_.status === 200) {
            setInitialId(newId);
            isUsed = false;
          } else if (res_.status === 404) {
            isUsed = false;
          }
        }
      } catch (error) {
        console.error('E20049', error);
      }
    };
    getTires();
    getInitialId();
    // eslint-disable-next-line
  }, []);

  const sortFunction = (a, b) => {
    return (
      moment(a.last_event_date).format('X') <
      moment(b.last_event_date).format('X')
    );
  };

  useEffect(() => {
    try {
      const size_ = size?.split(' ');
      const sizeToSearchWith = getSizeToSearchWith(size_);
      setTireSize(sizeToSearchWith);

      const availableTires = getAvailableTires(sizeToSearchWith);
      updateUsedTires(sizeToSearchWith);

      const sortedTires = getSortedTires(availableTires);
      if (isNewTire) {
        setSelectedTire(sortedTires.find(x => x.system_tire_id === lastTireId));
      }

      if (availableTires.length > 0) {
        setAvailableTires(sortedTires);
      }

      setAxleName(getAxleName(tire));
      updateTireInfo();

      setFinalDate(tireInfo?.mount_date);
    } catch (error) {
      console.error('E20058', error);
    }

    // eslint-disable-next-line
  }, [size, tire, tiresDb]);

  const getSizeToSearchWith = useCallback(
    size_ => {
      return tire?.type?.includes('drive') ? size_[3] : size_[0];
    },
    [tire?.type]
  );

  const getAvailableTires = sizeToSearchWith => {
    return tiresDb?.filter(
      tire =>
        tire.vehicle_id === undefined &&
        tire.size === sizeToSearchWith &&
        tire.tireStatus !== tireStatuses.RETREADING &&
        tire.tireStatus !== tireStatuses.REPAIR
    );
  };

  const updateUsedTires = sizeToSearchWith => {
    tiresDb?.forEach(tire => {
      if (tire.vehicle_id && tire.size === sizeToSearchWith) {
        if (!usedTires.includes(tire.system_tire_id)) {
          setUsedTires(prev => [...prev, tire.system_tire_id]);
        }
      }
    });
  };

  const getSortedTires = availableTires => {
    let newTires = [];
    for (let i = 0; i < availableTires.length; i++) {
      if (availableTires[i].last_event_type === 'CREATE') {
        newTires.push(availableTires[i]);
        availableTires[i] = null;
      }
    }
    return newTires
      .sort(sortFunction)
      .concat(availableTires.filter(x => x !== null));
  };

  const getAxleName = tire => {
    return tire.type?.includes('axle')
      ? 'TRAILER'
      : tire.type?.split('_')[0].toUpperCase();
  };

  const updateTireInfo = () => {
    if (tireInfo) {
      const tireId = tireInfo.tire_id;
      const presentTire = tiresDb.find(tire => tire.tire_id === tireId);
      setPresentTire(presentTire);

      const updatedPayload = {
        ...payload,
        tire_id: tireInfo?.tire_id,
        [TIRES.TREAD_DEPTH]: tireTreadDepth,
        mileage: usageMileage,
      };
      setPayload(updatedPayload);
      setInitialPayload(updatedPayload);
    }
  };

  useEffect(() => {
    if (!tireInfo) {
      setCurrentStep(1);
      setOption(tireOperationsOptions.CHANGE_TIRE);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fieldIsTouched) {
      const count = Object.keys(payload).reduce((total, key) => {
        if (
          (key === TIRES.TIRE_ID ||
            key === TIRES.MILEAGE ||
            key === TIRES.TREAD_DEPTH) &&
          payload[key] !== initialPayload?.[key]
        ) {
          return total + 1;
        }
        return total;
      }, 0);
      setChangedFieldCount(count);
    }
  }, [initialPayload, payload, fieldIsTouched]);
  const changeHandler = (e, value) => {
    setSelectedTire(value);
  };

  const handlePayloadChange = async e => {
    const tireId_ = tireInfo?.tire_id;
    if (e.target.name === TIRES.TIRE_ID) {
      debouncedTireIdChangeHandler(
        e,
        setErrors,
        errors,
        setPayload,
        payload,
        tireId_,
        t,
        access_token,
        setIsAutoGenerated
      );
    } else {
      debouncedCommonChangeHandler(
        e,
        setErrors,
        errors,
        setPayload,
        payload,
        tireId_,
        t,
        access_token,
        setIsAutoGenerated
      );
    }
    if (
      e.target.name === TIRES.TIRE_ID ||
      e.target.name === TIRES.MILEAGE ||
      e.target.name === TIRES.TREAD_DEPTH
    ) {
      setFieldIsTouched(true);
    }
  };

  const nextStepClickHandler = async e => {
    if (currentStep === 1) {
      const unmount = await moveToInventoryHandler(e, true);
      if (unmount.res === 200) {
        await applyHandler(e, unmount.axlId);
        return;
      }
    }
    setCurrentStep(prev => prev + 1);
  };
  const applyHandler = async (e, axlPositionId) => {
    e?.preventDefault();
    const axlType = tire.type;
    const side = tire.side;
    const position = stringLookup[internalAxleName[axle]][axlType][side][index];
    const positionId =
      mountedTires.filter(tire => tire.position === null)[0]
        ?.axle_position_id ?? axlPositionId;
    let obj = {
      position,
      axle_position_id: positionId,
      event_type: 'MOUNT',
      event_date: moment(newTireMountDate).format('YYYY-MM-DD'),
    };
    let response = null;
    try {
      const payload_ = {
        events: [{ ...obj }],
      };

      const res = await updateTire(selectedTire.system_tire_id, payload_);
      if (res.status === 200) {
        response = 200;
        if (!usedTires.includes(selectedTire.system_tire_id)) {
          setUsedTires(prev => [...prev, selectedTire.system_tire_id]);
        }
        setThisTire({
          ...res?.data?.resp,
          axle_position_id: positionId,
        });
        const res2 = await getVehiclesForFleet(
          { fleet_id: fleet_id },
          access_token
        );
        dispatch(setVehiclesForFleet(fleet_id, res2));
        await commonPostSuccessOperation(res);
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error(error);
    }
    return response;
  };

  const moveToInventoryHandler = async (e, skip) => {
    setIsSubmitting(true);
    const id = staticConfig[tire.type]?.[tire.side]?.[index]?.system_tire_id;
    const tire_id = staticConfig[tire.type]?.[tire.side]?.[index]?.tire_id;
    const axlId =
      staticConfig[tire.type]?.[tire.side]?.[index]?.axle_position_id;
    let response = { axlId };
    let events = {
      event_type: unmountReason,
      event_date: moment(eventDate).format('YYYY-MM-DD'),
    };
    if (payload.km?.length !== 0) {
      events = { ...events, km: payload.km };
    }
    if (
      payload[TIRES.TREAD_DEPTH]?.length !== 0 &&
      payload[TIRES.TREAD_DEPTH] !== null
    ) {
      events = { ...events, tread_depth: payload[TIRES.TREAD_DEPTH] };
    }
    const payload_ = {
      tire_id: tire_id,
      events: [events],
    };

    try {
      const res = await updateTire(id, payload_);
      if (res.status === 200) {
        dispatch(
          setNotification(
            t('common.tireInventory.movedToStorageSuccess'),
            'success',
            true
          )
        );
        const data = await fetchTireInventoryDataAndProcess(
          fleet_id,
          fleetInventoryData,
          true
        );
        dispatch(setTireInventoryData(data, fleet_id));
        dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
        const res2 = await getVehiclesForFleet(
          { fleet_id: fleet_id },
          access_token
        );
        dispatch(setVehiclesForFleet(fleet_id, res2));
        response['res'] = 200;
        if (usedTires.includes(presentTire.system_tire_id)) {
          const x = usedTires;
          const foundIndex = x.findIndex(
            tire => tire.system_tire_id === presentTire.system_tire_id
          );
          x.splice(foundIndex, 1);
          setUsedTires(x);
        }
        let curr = newConfig[tire.type]?.[tire.side];
        if (curr.length === 1) {
          newConfig[tire.type][tire.side] = null;
        } else if (curr.length > 1) {
          newConfig[tire.type][tire.side][index] = null;
        }

        if (skip === false || skip === undefined) {
          dispatch(setChangeInVehicle(!vehicleDataHasChanged));
          handleClose();
        }
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20059', error);
    } finally {
      setIsSubmitting(false);
    }
    return response;
  };

  const commonPostSuccessOperation = async (res, multiple) => {
    dispatch(
      setNotification(
        `${
          multiple ? 'Tires mounted successfully!' : res?.data?.return_message
        }`,
        'success',
        true
      )
    );
    dispatch(setChangeInVehicle(!vehicleDataHasChanged));
    const data = await fetchTireInventoryDataAndProcess({
      fleet_id,
      inventoryData: fleetInventoryData,
      refetch: true,
    });
    dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
    dispatch(setTireInventoryData(data, fleet_id));
    handleClose();
  };

  const editTireDetails = async payload_ => {
    const id = staticConfig[tire.type]?.[tire.side]?.[index]?.system_tire_id;

    try {
      const res = await updateTire(id, payload_);
      if (res.status === 200) {
        await commonPostSuccessOperation(res);
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20060', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateTireHandler = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    const tire_ = staticConfig[tire.type]?.[tire.side]?.[index];
    let obj = { event_date: moment(eventDate).format('YYYY-MM-DD') };
    let payload_ = {};

    const updateMileageEvent = {
      ...obj,
      event_type: event_types.UPDATE_MILEAGE,
      km: payload.mileage,
    };

    const updateDepthEvent = {
      ...obj,
      event_type: event_types.UPDATE_DEPTH,
      tread_depth: payload.last_tread_depth,
    };

    const shouldUpdateMileage =
      parseInt(tire_.last_km_driven) !== parseInt(payload.mileage);
    const shouldUpdateDepth =
      tire_.last_tread_depth !== payload.last_tread_depth;
    const shouldUpdateTireId = payload.tire_id !== tire_?.tire_id;

    if (changedFieldCount > 2) {
      await editTireDetails({
        tire_id: payload.tire_id,
        events: [updateMileageEvent, updateDepthEvent],
      });
    } else {
      if (shouldUpdateMileage) {
        await editTireDetails({ ...payload_, events: [updateMileageEvent] });
      }

      if (shouldUpdateDepth) {
        await editTireDetails({ ...payload_, events: [updateDepthEvent] });
      }

      if (shouldUpdateTireId) {
        await editTireDetails({ tire_id: payload.tire_id });
      }
    }
  };

  const submitFormHandler = async e => {
    e.preventDefault();
    const payload_ = {
      fleet_id,
      tires: [
        {
          tire_id: isAutoGenerated ? initialId : payload.tire_id,
          brand: tireBrand,
          product_line: productLine,
          size: tireSize,
          tread_depth: payload.last_tread_depth,
          isRetread: payload.isRetread,
          isRegrooved: payload.isRegrooved,
          pressure: payload?.tire_pressure,
          grooves_tread_depth: checked ? grooves : {},
        },
      ],
    };

    try {
      const res = await addTire(access_token, payload_);
      if (res.status === 200) {
        const summary_ = res?.data?.resp?.tire_summary;

        setLastTireId(summary_[0].system_tire_id);
        setNewTire(true);

        if (summary_.length && summary_[0].upload_status === 'fail') {
          dispatch(setNotification(summary_[0].upload_result, 'error', true));
        } else {
          dispatch(
            setNotification(
              t('common.tireInventory.tireUploadedMsg'),
              'success',
              true
            )
          );
          const fleetResponse = await fleetOverview(access_token);
          dispatch(setFleetOverview(fleetResponse.data.resp));

          const totalTires =
            fleetResponse?.data?.resp?.metrics[fleet_id].total_tires ||
            tireInventoryDetails.metrics[fleet_id].total_tires;
          const data = await fetchTireInventoryDataAndProcess({
            fleet_id,
            inventoryData: fleetInventoryData,
            refetch: true,
            location: 'ALL',
            isPaginated: true,
            pageNo: 0,
            perpage: totalTires + 1,
          });
          dispatch(setTireInventoryData(data, fleet_id));
          dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
          setTiresDb(data?.originalData);
          if (presentTire) {
            setOption(tireOperationsOptions.CHANGE_TIRE);
            setCurrentStep(1);
          } else {
            setOption(tireOperationsOptions.CHANGE_TIRE);
          }
        }
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20062', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setOption(0);
    setErrors({
      [TIRES.TIRE_ID]: null,
      [TIRES.TREAD_DEPTH]: null,
    });
    setUsedTires([]);
    setSelectedTire('');
  };

  const changeHandler2 = (e, value) => {
    setUnmountReason(unmountOptions(t).unmountOptionsMapped[value]);
    setUnmountOption(value);
  };

  const newTireMountDateChangeHandler = date => {
    let current = addOns[tire?.type][tire?.side];
    if (current && current.length) {
      current.splice(index, 1, {
        mount_date: moment(date).format('YYYY-MM-DD'),
      });
    } else {
      current = [];
      if (index !== 0) {
        current.push(null);
      }
      current.splice(index, 1, {
        mount_date: moment(date).format('YYYY-MM-DD'),
      });
    }
    setAddOns({
      ...addOns,
      [tire?.type]: {
        ...addOns[tire?.type],
        [tire?.side]: current,
      },
    });
    setFinalDate(date);
    setNewTireMountDate(date);
  };

  const handleChipClick = position => {
    setSelectedPositions(prevSelectedPositions => {
      if (prevSelectedPositions.includes(position)) {
        return prevSelectedPositions.filter(selected => selected !== position);
      } else {
        return [...prevSelectedPositions, position];
      }
    });
  };

  const allPositionsToggleHandler = () => {
    selectedPositions.length !== emptyPositions.length
      ? setSelectedPositions([...emptyPositions])
      : setSelectedPositions([]);
  };

  function multipleMountView() {
    return (
      showPositions && (
        <>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems='center'
            gap='10px'
            marginTop={'20px'}
            flexWrap={'wrap'}
          >
            {emptyPositions.map((position, i) => (
              <Chip
                key={i}
                label={position}
                onClick={() => handleChipClick(position)}
                color={
                  selectedPositions.includes(position) ? 'primary' : 'default'
                }
              />
            ))}
            <Button
              variant='text'
              color='info'
              sx={{ textTransform: 'lowercase' }}
              onClick={allPositionsToggleHandler}
            >
              {selectedPositions.length !== emptyPositions.length
                ? t('multiLayerReport.select')
                : t('multiLayerReport.deselect')}
            </Button>
            <IconButton onClick={revertShowPositions}>
              <Close />
            </IconButton>
          </Box>
          <Button
            className={classes.applyBtn}
            variant='contained'
            disableElevation
            sx={{ marginTop: '20px' }}
            onClick={multipleSelectionSubmitHandler}
            disabled={selectedPositions.length === 0}
          >
            {t('common.submitBtn')}
          </Button>
        </>
      )
    );
  }

  const revertShowPositions = () => {
    setShowPositions(false);
    setSelectedPositions([]);
  };

  const multipleSelectionSubmitHandler = async () => {
    setLoading(true);
    let initialPayload = { fleet_id };
    const tires = Array.from(
      { length: selectedPositions.length - 1 },
      (_, i) => ({
        tire_id: `${Date.now().toString(24)}${(i + 1).toString()}`,
        brand: selectedTire.brand,
        product_line: selectedTire.product_line,
        size: tireSize,
        tread_depth: selectedTire.last_tread_depth,
        isRetread: selectedTire.isRetread,
        pressure: selectedTire?.last_pressure,
        isRegrooved: selectedTire?.isRegrooved || false,
      })
    );

    const filteredTires = tires.map(obj => {
      const filteredObj = {};
      Object.keys(obj).forEach(key => {
        if (obj[key] !== null) {
          filteredObj[key] = obj[key];
        }
      });
      return filteredObj;
    });

    const updatedPayload = { ...initialPayload, tires: filteredTires };

    let vacantAxlePositionIds = mountedTires
      .map(tire => tire.position === null && tire.axle_position_id)
      .filter(id => id)
      .slice(0, selectedPositions.length);

    let transformedTires = vacantAxlePositionIds.map((item, i) => ({
      position: selectedPositions[i],
      axle_position_id: item,
    }));
    let arg;

    try {
      const res = await addTire(access_token, updatedPayload);
      if (res.status === 200) {
        let allCallsSuccessful = true;
        let length = transformedTires.length - 1;
        for (let i = 0; i < length; i++) {
          let obj = {
            position: transformedTires[i].position,
            axle_position_id: transformedTires[i].axle_position_id,
            event_type: 'MOUNT',
            event_date: moment(newTireMountDate).format('YYYY-MM-DD'),
          };

          const payload_ = {
            events: [{ ...obj }],
          };
          const summary = res.data.resp.tire_summary;

          try {
            const res2 = await updateTire(summary[i].system_tire_id, payload_);

            if (res2.status !== 200) {
              allCallsSuccessful = false;
            } else if (res2.status === 200 && i === length) {
              arg = res2;
            }
          } catch (error) {
            allCallsSuccessful = false;
          }
        }
        let referenceTirePayload = {
          events: [
            {
              position: transformedTires[length].position,
              axle_position_id: transformedTires[length].axle_position_id,
              event_type: 'MOUNT',
              event_date: moment(newTireMountDate).format('YYYY-MM-DD'),
            },
          ],
        };
        try {
          const response = await updateTire(
            selectedTire.system_tire_id,
            referenceTirePayload
          );
          if (response.status !== 200) {
            allCallsSuccessful = false;
          }
        } catch (error) {
          console.error('E20300', error);
        }

        if (allCallsSuccessful) {
          const res3 = await getVehiclesForFleet(
            { fleet_id: fleet_id },
            access_token
          );
          dispatch(setVehiclesForFleet(fleet_id, res3));
          await commonPostSuccessOperation(arg, true);
        }
      }
    } catch (error) {
      console.error('E20112', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadAnimation loading={loading} setLoading={setLoading} />
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper
            className={classes.paper}
            style={{
              width: option !== 3 ? '50%' : '70%',
            }}
          >
            <Box display='flex' flexDirection='column'>
              {option === 0 && (
                <Box
                  display='flex'
                  justifyContent='center'
                  flexDirection='column'
                  gap={'15px'}
                >
                  <Box
                    display='flex'
                    alignItems='center'
                    alignSelf='center'
                    gap={'10px'}
                  >
                    <Link
                      className={classes.link}
                      color='primary'
                      to={`/tire-inventory/${fleet_id}/${presentTire?.system_tire_id}`}
                    >
                      {presentTire?.tire_id}
                    </Link>
                  </Box>
                  <Box display='flex' justifyContent='center' gap={'15px'}>
                    {tireInfo && (
                      <Button
                        variant='contained'
                        color='primary'
                        endIcon={<Edit />}
                        onClick={() => setOption(tireOperationsOptions.UPDATE)}
                      >
                        {t('tireOperationsModal.updateBtn')}
                      </Button>
                    )}
                    <Button
                      endIcon={<Publish />}
                      variant='contained'
                      onClick={() =>
                        setOption(tireOperationsOptions.CHANGE_TIRE)
                      }
                    >
                      {t('tireOperationsModal.changeBtn')}
                    </Button>
                    {tireInfo && (
                      <Button
                        endIcon={<Eject />}
                        variant='contained'
                        color='secondary'
                        onClick={() =>
                          setOption(tireOperationsOptions.TO_STORAGE)
                        }
                      >
                        {t('tireOperationsModal.unmountBtn')}
                      </Button>
                    )}
                  </Box>
                </Box>
              )}

              {option === tireOperationsOptions.CHANGE_TIRE && (
                <>
                  {presentTire && (
                    <Stepper
                      activeStep={currentStep}
                      alternativeLabel
                      style={{ backgroundColor: 'transparent' }}
                    >
                      {steps.map(label => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  )}
                  {currentStep === 0 && presentTire && (
                    <>
                      <h4>{t('tireOperationsModal.unmountTireTitle')}</h4>
                      <Autocomplete
                        id='unmount-options'
                        options={unmountOptions(t).options}
                        getOptionLabel={option => option}
                        onChange={changeHandler2}
                        value={unmountOption}
                        className={classes.fullWidth}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label={t('tireOperationsModal.unmountReasonLabel')}
                            variant='outlined'
                          />
                        )}
                      />
                      <Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <CustomDatePicker
                            label={t('tireOperationsModal.unmountDateLabel')}
                            value={eventDate}
                            onChange={date => {
                              setEventDate(date);
                            }}
                            fullWidth={true}
                          />
                        </LocalizationProvider>
                      </Box>
                    </>
                  )}
                  {currentStep === 1 && (
                    <div>
                      <h4>{t('tireOperationsModal.mountTireTitle')}</h4>
                      <form onSubmit={e => e.preventDefault()}>
                        <Box display='flex' flexDirection='column' gap={'10px'}>
                          <Box display='flex' width='100%'>
                            <Autocomplete
                              id='combo-box-demo'
                              options={availableTires.filter(
                                item => !usedTires.includes(item.system_tire_id)
                              )}
                              getOptionLabel={option =>
                                `${option.brand ?? ''} ${
                                  option.product_line ?? ''
                                } ${
                                  option?.last_tread_depth
                                    ? `- TD(${option?.last_tread_depth})`
                                    : ''
                                } ${option.tire_id ? '-' : ''} ${
                                  option.tire_id ?? ''
                                }`
                              }
                              onChange={changeHandler}
                              noOptionsText={t(
                                'tireOperationsModal.noTiresMsg'
                              )}
                              value={selectedTire}
                              className={classes.fullWidth}
                              renderInput={params => (
                                <TextField
                                  {...params}
                                  label='Tires'
                                  variant='outlined'
                                />
                              )}
                            />
                            {isLoading && (
                              <Box mx='0.5rem'>
                                <CircularProgress />
                              </Box>
                            )}
                          </Box>
                          <Box width='100%'>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <CustomDatePicker
                                label={`${t(
                                  'tireOperationsModal.mountDateLabel'
                                )}
                    ${
                      i18next.language === 'de'
                        ? ' (JJJJ.MM.TT)'
                        : ' (YYYY.MM.DD)'
                    }`}
                                value={newTireMountDate}
                                onChange={newTireMountDateChangeHandler}
                                fullWidth={true}
                              />
                            </LocalizationProvider>
                          </Box>
                        </Box>
                        {!presentTire && (
                          <Box
                            display='flex'
                            flexDirection='column'
                            justifyContent={'center'}
                            marginTop='2rem'
                            gap={'30px'}
                            width='100%'
                          >
                            <Box
                              display='flex'
                              alignSelf='center'
                              gap={'10px'}
                              width='100%'
                            >
                              <Button
                                type='submit'
                                variant='outlined'
                                className={classes.applyBtn}
                                disableElevation
                                onClick={handleClose}
                              >
                                {t('common.cancel')}
                              </Button>
                              <Button
                                color='primary'
                                className={classes.applyBtn}
                                disableElevation
                                disabled={isSubmitting || !selectedTire}
                                onClick={event =>
                                  setAnchorEl(event.currentTarget)
                                }
                                endIcon={<ArrowDropDown />}
                                aria-controls={
                                  openMenu ? 'basic-menu' : undefined
                                }
                                aria-haspopup='true'
                                aria-expanded={openMenu ? 'true' : undefined}
                                data-hook='apply-btn'
                              >
                                {t('common.apply')}
                              </Button>
                              <Menu
                                id='basic-menu'
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                                }}
                              >
                                <MenuItem
                                  onClick={applyHandler}
                                  disabled={isSubmitting || !selectedTire}
                                  data-hook='mount-one'
                                >
                                  {t('tireOperationsModal.mountOne')}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setShowPositions(true);
                                    setAnchorEl(null);
                                  }}
                                >
                                  {t('tireOperationsModal.mountMany')}
                                </MenuItem>
                              </Menu>
                            </Box>
                          </Box>
                        )}
                      </form>
                      {multipleMountView()}
                      {selectedTire && (
                        <Typography variant='h6'>
                          {t('tireOperationsModal.mountWarningMsg')}
                        </Typography>
                      )}
                      <Box
                        display='flex'
                        alignItems='center'
                        marginTop={2}
                        className={classes.uploadBox}
                      >
                        <Typography variant='h6'>
                          {t('tireOperationsModal.uploadTireSuggestion')}
                        </Typography>
                        <Button
                          variant='text'
                          color='primary'
                          style={{ textTransform: 'inherit' }}
                          onClick={() =>
                            setOption(tireOperationsOptions.NEW_TIRE)
                          }
                          data-hook='add-new-tire-btn'
                        >
                          {t('tireOperationsModal.here')}
                        </Button>
                      </Box>
                    </div>
                  )}
                  {presentTire && (
                    <Box
                      width='100%'
                      display='flex'
                      justifyContent='space-between'
                      px={2}
                      marginTop={2}
                    >
                      <Button
                        className={classes.navBtns}
                        style={{
                          visibility: currentStep === 0 ? 'hidden' : 'visible',
                        }}
                        variant='contained'
                        color='primary'
                        onClick={() => setCurrentStep(prev => prev - 1)}
                      >
                        {t('common.back')}
                      </Button>
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.navBtns}
                        onClick={nextStepClickHandler}
                        disabled={disableCondition}
                      >
                        {currentStep === 0
                          ? t('common.next')
                          : t('common.confirm')}
                      </Button>
                    </Box>
                  )}
                </>
              )}
              {option === tireOperationsOptions.UPDATE && (
                <Box>
                  <h2>{t('common.tireInventory.tireUpdateHeading')}</h2>
                  <form onSubmit={updateTireHandler}>
                    <Box display='flex' flexDirection='column' gap={'10px'}>
                      <Box
                        position='relative'
                        marginBottom={errors[TIRES.TIRE_ID] !== null ? 3 : 0}
                      >
                        <CustomTextField
                          type='text'
                          label={t('common.tireInventory.tireId')}
                          name='tire_id'
                          required='true'
                          fontSize={16}
                          default={payload.tire_id}
                          onChange={handlePayloadChange}
                          error={errors[TIRES.TIRE_ID] !== null}
                        />
                        <Box position='absolute' bottom={-30} color='red'>
                          {errors[TIRES.TIRE_ID]}
                        </Box>
                      </Box>
                      <Box>
                        <CustomTextField
                          name='mileage'
                          required
                          id='mileage'
                          label={t('common.tireInventory.mileage')}
                          onChange={handlePayloadChange}
                          variant='outlined'
                          value={payload.mileage}
                          type='number'
                          InputProps={{
                            inputProps: {
                              min: 0,
                            },
                          }}
                          fullWidth
                        />
                      </Box>
                      <Box
                        position='relative'
                        marginBottom={
                          errors[TIRES.TREAD_DEPTH] !== null ? 3 : 0
                        }
                      >
                        <CustomTextField
                          name={TIRES.TREAD_DEPTH}
                          required
                          id={TIRES.TREAD_DEPTH}
                          label={t('common.tireInventory.depth')}
                          onChange={handlePayloadChange}
                          variant='outlined'
                          value={payload[TIRES.TREAD_DEPTH]}
                          type='number'
                          error={errors[TIRES.TREAD_DEPTH] !== null}
                          InputProps={{
                            inputProps: {
                              min: 0,
                              max: 20,
                              step: 'any',
                            },
                          }}
                          fullWidth
                        />
                        <Box position='absolute' bottom={-30} color='red'>
                          {errors[TIRES.TREAD_DEPTH]}
                        </Box>
                      </Box>
                    </Box>
                    <Box width='100%'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CustomDatePicker
                          label={t('tireOperationsModal.eventDate')}
                          value={eventDate}
                          onChange={date => {
                            setEventDate(date);
                          }}
                          fullWidth={true}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box
                      display='flex'
                      justifyContent={'center'}
                      marginTop='2rem'
                    >
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.applyBtn}
                        disableElevation
                        disabled={
                          isSubmitting ||
                          !!errors[TIRES.TREAD_DEPTH] ||
                          !!errors[TIRES.TIRE_ID] ||
                          !fieldIsTouched
                        }
                      >
                        {t('common.submitBtn')}
                      </Button>
                    </Box>
                  </form>
                </Box>
              )}
              {option === tireOperationsOptions.NEW_TIRE && (
                <>
                  <h2>{t('common.tireInventory.addTire')}</h2>
                  <TireForm
                    vehicleType={vehicleType}
                    productLine={productLine}
                    tireBrand={tireBrand}
                    tireType={tireType}
                    setTireBrand={setTireBrand}
                    size={vehicle.tire_size[tireType]}
                    tire={tire}
                    axle={axle}
                    index={index}
                    setSize={setTireSize}
                    error={errors}
                    handleClose={handleClose}
                    setProductLine={setProductLine}
                    handleChange={handlePayloadChange}
                    vehicle={vehicle}
                    initialId={initialId}
                    payload={payload}
                    setPayload={setPayload}
                    submitFormHandler={submitFormHandler}
                    axleName={axleName}
                    grooves={grooves}
                    checked={checked}
                    setGrooves={setGrooves}
                    handleChangeSwitch={handleChangeSwitch}
                    caller='tire_config'
                  />
                </>
              )}
              {option === tireOperationsOptions.TO_STORAGE && (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  flexDirection='column'
                  gap={'20px'}
                >
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    flexDirection='column'
                    gap={'20px'}
                  >
                    <Autocomplete
                      id='unmount-options'
                      options={unmountOptions(t).options}
                      getOptionLabel={option => option}
                      onChange={changeHandler2}
                      value={unmountOption}
                      style={{
                        width: '100%',
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={t('tireOperationsModal.unmountReasonLabel')}
                          variant='outlined'
                        />
                      )}
                    />
                    <Box width='100%'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <CustomDatePicker
                          label={t('tireOperationsModal.unmountDateLabel')}
                          value={eventDate}
                          onChange={date => {
                            setEventDate(date);
                          }}
                          fullWidth={true}
                        />
                      </LocalizationProvider>
                    </Box>
                    <Box width='100%'>
                      <CustomTextField
                        name={TIRES.TREAD_DEPTH}
                        id={TIRES.TREAD_DEPTH}
                        label={t('common.tireInventory.depth')}
                        onChange={handlePayloadChange}
                        variant='outlined'
                        value={payload[TIRES.TREAD_DEPTH]}
                        type='number'
                        placeholder='Optional'
                        InputProps={{
                          inputProps: {
                            min: 0,
                            max: 20,
                            step: 'any',
                          },
                        }}
                        fullWidth
                      />
                    </Box>
                    <Box width='100%'>
                      <CustomTextField
                        name='km'
                        id='km'
                        label={t('common.tireInventory.mileage')}
                        onChange={handlePayloadChange}
                        variant='outlined'
                        value={payload.km}
                        type='number'
                        placeholder='Optional'
                        InputProps={{
                          inputProps: {
                            min: 0,
                          },
                        }}
                        fullWidth
                      />
                    </Box>
                  </Box>
                  <Typography variant='h6'>
                    {t('common.tireInventory.unmountMsgWarning')}
                  </Typography>
                  <Button
                    color='secondary'
                    variant='contained'
                    disabled={isSubmitting || unmountOption === null}
                    onClick={moveToInventoryHandler}
                  >
                    {t('common.tireInventory.confirmBtn')}
                  </Button>
                </Box>
              )}
              {option !== 0 && tireInfo && (
                <Tooltip title={t('tireOperationsModal.backToHome')}>
                  <Box className={classes.back} onClick={() => setOption(0)}>
                    <Fab color='primary' aria-label='back' size='small'>
                      <HomeOutlined />
                    </Fab>
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

export default TireOperationsModal;

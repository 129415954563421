export const co2optBaseUrl = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_CO2OPT_BASE_URL
  : process.env.REACT_APP_CO2OPT_BASE_URL;

export const dropzoneUrl = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_DROPZONE_URL
  : process.env.REACT_APP_DROPZONE_URL;

export const tireServiceMapUrl = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_TIRE_SERVICE_MAP_URL
  : process.env.REACT_APP_TIRE_SERVICE_MAP_URL;

export const appTelemetryUrl = window.__RUNTIME_CONFIG__
  ? window.__RUNTIME_CONFIG__.REACT_APP_APP_TELEMETRY
  : process.env.REACT_APP_APP_TELEMETRY;

import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ReactComponent as Info } from 'assets/svg/info.svg';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      position: 'fixed',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      backgroundColor: '#525D73',
      height: theme.spacing(8),
      width: theme.spacing(8),
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 999,
      cursor: 'pointer',
      '&:hover': {
        width: '280px',
        height: '190px',
        borderRadius: '8px 8px 32px 8px',
        '& #actions': {
          display: 'block',
        },
      },
    },
    icon: {
      position: 'absolute',
      right: theme.spacing(2.5),
      bottom: theme.spacing(2.5),
    },
    logo: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
    },
    actions: {
      display: 'none',
      color: '#fff',
      width: '100%',
      alignItems: 'center',
      fontSize: '1.25em',
      flexDirection: 'column',
      paddingLeft: theme.spacing(2),
    },
    actionLabel: {
      display: 'flex',
      width: '100%',
      height: theme.spacing(4),
      gridGap: theme.spacing(2),
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    link: {
      textDecoration: 'none',
      color: '#fff',
    },
  };
});

const PlatformInfoButton = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const actions = [
    {
      label: t('platformInfoRoute.scheduleMeeting'),
      link: process.env.REACT_APP_REDIRECT_SCHEDULE_MEETING,
      icon: <EventAvailableIcon />,
    },
    {
      label: t('platformInfoRoute.writeMessage'),
      link: `mailto:${process.env.REACT_APP_INFO_EMAIL_ID}`,
      icon: <MailOutlineIcon />,
    },
    {
      label: '+49 (0) 40607799896',
      link: process.env.REACT_APP_REDIRECT_CONTACT_US,
      icon: <CallIcon />,
    },
    {
      label: t('platformInfoRoute.tutorials'),
      link: process.env.REACT_APP_TUTORIAL_PLAYLIST,
      icon: <HelpOutlineIcon />,
    },
  ];
  return (
    <div className={classes.root} id='seventh-step'>
      <Info className={classes.icon} />
      <Box className={classes.actions} id='actions'>
        {actions.map(action => (
          <a
            key={action.label}
            href={action.link}
            className={classes.link}
            target='_blank'
            rel='noreferrer'
          >
            <Box className={classes.actionLabel}>
              <span>{action.icon}</span>
              <span>{action.label}</span>
            </Box>
          </a>
        ))}
      </Box>
    </div>
  );
};

export default PlatformInfoButton;

import React, { useState, useEffect, useRef } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEmailSubscriptionDetails,
  notificationPreferencesSelector,
  setAccessTokenSelector,
  setUserSelector,
} from 'redux/users/ducks';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { IOSSwitch } from 'pages/VehiclePage/TireConfig/TireConfig';
import ActionButtons from 'container/settings/customerSettings/form/ActionButtons';
import { getWorkForceData } from '../../../services/auth_service';
import {
  getSubscribedUsers,
  sendEmailToSelectedUsers,
} from '../../../services/fleet_service';
import { setNotification } from '../../../redux/notifications/ducks';
import { emptyResponse, USER_ROLES } from '../../../utils/string_utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles()(theme => {
  return {
    dayButton: {
      textTransform: 'unset',
      alignSelf: 'flex-end',
    },
    select: {
      '& .MuiSelect-selectMenu': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: theme.spacing(1),
      },
    },
    infoBox: {
      padding: 10,
      boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
      borderRadius: 5,
      transition: 'boxShadow 0.3s ease-in-out',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      gap: 10,

      '&:hover': {
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.5)',
      },
    },
    textBody: {
      opacity: 0.6,
      fontSize: '12px',
    },
    textHeading: {
      fontSize: '16px',
    },
  };
});

const daysOfWeek = [
  { day: 'sunday' },
  { day: 'monday' },
  { day: 'tuesday' },
  { day: 'wednesday' },
  { day: 'thursday' },
  { day: 'friday' },
  { day: 'saturday' },
];

const Notifications = () => {
  const access_token = useSelector(setAccessTokenSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentNotificationPreferences = useSelector(
    notificationPreferencesSelector
  );
  const emailSUbscriptionDetails = useSelector(getEmailSubscriptionDetails);
  const [orgSummary, setOrgSummary] = useState({});
  const [fleetCheckSummary, setFleetCheckSummary] = useState({});
  const [lowTreadSummary, setLowTreadSummary] = useState({});
  const [users, setUsers] = useState([]);
  const { classes } = useStyles();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [lowTreadSelectedUsers, setLowTreadSelectedUsers] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [lowTreadUsers, setLowTreadUsers] = useState([]);
  const [fleetCheckSelectedUsers, setFleetCheckSelectedUsers] = useState([]);
  const [isWeeklyOrg, setIsWeeklyOrg] = useState(false);
  const [isWeeklyLowTread, setIsWeeklyLowTread] = useState(false);
  const [emailPreferences, serEmailPreferences] = useState(
    emailSUbscriptionDetails
  );
  const [fleetCheckChange, setFleetCheckChange] = useState(false);
  const [fleetCheckUsers, setFleetCheckUsers] = useState([]);

  const [isOrgUserChange, setIsUserChange] = useState(false);
  const [isLowTreadUserChange, setIsLowTreadUserChange] = useState(false);
  const [isUserEnabled, setIsUserEnabled] = useState(false);
  const [isFleetEnabled, setIsFleetEnabled] = useState(false);
  const [isExpanded, setIsExpanded] = useState({
    lowTread: false,
    orgSummary: false,
    fleetUser: false,
  });

  const userDetails = useSelector(setUserSelector);
  const isAdmin =
    userDetails?.iam_role === USER_ROLES.admin ||
    userDetails?.iam_role === USER_ROLES.superAdmin;

  const [userSummary, setUserSummary] = useState({});
  const orgRef = useRef(null);
  const lowTreadRef = useRef(null);
  const fleetCheckRef = useRef(null);

  let isOrgSummaryChange =
    emailPreferences && !isEqual(emailPreferences.ORG_SUMMARY, orgSummary);

  let isTreadSummaryChange =
    emailPreferences &&
    !isEqual(emailPreferences.LOW_TREAD_DEPTH, lowTreadSummary);

  let isUserSummaryChange =
    emailPreferences && !isEqual(emailPreferences.USER_SUMMARY, userSummary);
  const [isSubmitting, setIsSubmitting] = useState(false);

  let isFleetCheckChange =
    emailPreferences &&
    !isEqual(emailPreferences.FLEETCHECK_REMINDER, fleetCheckSummary);

  useEffect(() => {
    async function getData() {
      const response = await getWorkForceData();
      const { data } = await getSubscribedUsers();
      const resp =
        Object.keys(data.resp).length > 0 ? data.resp : emptyResponse;
      serEmailPreferences(resp);

      const users = response?.data?.resp?.workforceData || [];
      setUsers(
        users.filter(
          user =>
            user.name !== 'CO2OPT BOT' &&
            user.iam_role !== 'admin' &&
            user.iam_role !== 'superAdmin'
        )
      );
      if (isAdmin) {
        setUserIds(resp.ORG_SUMMARY.user_ids);
        setLowTreadUsers(resp.LOW_TREAD_DEPTH.user_ids);
        setOrgSummary(resp.ORG_SUMMARY);
        setIsWeeklyOrg(resp.ORG_SUMMARY?.is_enabled);
        setLowTreadSummary(resp.LOW_TREAD_DEPTH);
        setIsWeeklyLowTread(resp.LOW_TREAD_DEPTH.is_enabled);
      }
      setFleetCheckSummary(resp.FLEETCHECK_REMINDER);
      setUserSummary(resp.USER_SUMMARY);
      setFleetCheckUsers(resp.FLEETCHECK_REMINDER?.user_ids);
      setIsUserEnabled(resp.USER_SUMMARY?.is_enabled);
      setIsFleetEnabled(resp.FLEETCHECK_REMINDER?.is_enabled);
      setSelectedUsers(Array(users.length).fill(false));
      setFleetCheckSelectedUsers(Array(users.length).fill(false));
      setLowTreadSelectedUsers(Array(users.length).fill(false));
    }
    if (access_token) {
      getData();
    }
  }, [access_token, isAdmin]);

  useEffect(() => {
    function handleClickOutside(event) {
      const refs = [orgRef, lowTreadRef, fleetCheckRef];
      const isClickedOutside = refs.every(
        ref => ref.current && !ref.current.contains(event.target)
      );
      if (isClickedOutside) {
        setIsExpanded({
          orgSummary: false,
          lowTread: false,
        });
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [orgRef, lowTreadRef, fleetCheckRef]);

  useEffect(() => {
    getCheckedUsers();
    // eslint-disable-next-line
  }, [lowTreadUsers, userIds]);

  const getCheckedUsers = resp => {
    const selectedUsersCopy = JSON.parse(JSON.stringify(selectedUsers));
    const lowTreadSelectedUsersCopy = JSON.parse(
      JSON.stringify(lowTreadSelectedUsers)
    );
    const fleetUsersCopy = JSON.parse(JSON.stringify(fleetCheckSelectedUsers));
    userIds?.forEach(id => {
      const index = users.findIndex(user => user.user_id === id);
      selectedUsersCopy[index] = true;
    });

    lowTreadUsers.forEach(id => {
      const index = users.findIndex(user => user.user_id === id);
      lowTreadSelectedUsersCopy[index] = true;
    });

    fleetCheckUsers.forEach(id => {
      const index = users.findIndex(user => user.user_id === id);
      fleetUsersCopy[index] = true;
    });
    setFleetCheckSelectedUsers(fleetUsersCopy);
    setSelectedUsers(selectedUsersCopy);
    setLowTreadSelectedUsers(lowTreadSelectedUsersCopy);
  };

  useEffect(() => {
    if (!!currentNotificationPreferences) {
    }

    // eslint-disable-next-line
  }, [currentNotificationPreferences]);

  const handleSwitchChange = (event, summary, setSummary, setEnabled) => {
    let copySummary = JSON.parse(JSON.stringify(summary));
    let checked = { ...copySummary, is_enabled: event.target.checked };
    setSummary(checked);
    setEnabled(prev => !prev);
  };

  const handleSelectAllChange = (e, setSelected, setIds) => {
    setSelected(Array(users.length).fill(e.target.checked));
    if (e.target.checked) {
      let userId = [];
      users.forEach(user => {
        userId.push(user.user_id);
      });
      setIds(userId);
    } else {
      setIds([]);
    }
  };

  const handleSelectUser = (
    e,
    i,
    id,
    selectUsers,
    setSelectUsers,
    userIds,
    setIds,
    key
  ) => {
    const selectedUsersCopy = JSON.parse(JSON.stringify(selectUsers));
    selectedUsersCopy[i] = e.target.checked;
    setSelectUsers(selectedUsersCopy);
    let userId;
    if (userIds.some(user => user === id)) {
      userId = userIds.filter(ids => ids !== id);
    } else {
      userId = [...JSON.parse(JSON.stringify(userIds)), id];
    }
    if (key === 'low_td') {
      setIsLowTreadUserChange(true);
    } else if (key === 'fleet_check') {
      setFleetCheckChange(true);
    } else {
      setIsUserChange(true);
    }

    setIds(userId);
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    let orgSummaryPayload;
    let low_tread_depth_payload;
    let fleet_check_payload;
    if (isAdmin) {
      orgSummaryPayload = {
        email_type: 'ORG_SUMMARY',
        is_enabled: isWeeklyOrg,
        frequency: {
          type: orgSummary?.frequency?.type,
        },
        user_ids: userIds,
      };

      low_tread_depth_payload = {
        ...lowTreadSummary,
        is_enabled: isWeeklyLowTread,
        email_type: 'LOW_TREAD_DEPTH',
        frequency: {
          type: lowTreadSummary?.frequency?.type,
        },
        user_ids: lowTreadUsers,
      };

      if (orgSummary.frequency?.type === 'weekly') {
        orgSummaryPayload = {
          ...orgSummaryPayload,
          frequency: {
            ...orgSummaryPayload.frequency,
            days: orgSummary.frequency?.days,
          },
        };
      }

      if (lowTreadSummary.frequency?.type === 'weekly') {
        low_tread_depth_payload = {
          ...low_tread_depth_payload,
          frequency: {
            ...low_tread_depth_payload.frequency,
            days: lowTreadSummary.frequency?.days,
          },
        };
      }
    }

    fleet_check_payload = {
      ...fleetCheckSummary,
      is_enabled: isFleetEnabled,
      email_type: 'FLEETCHECK_REMINDER',
      frequency: {
        type: fleetCheckSummary.frequency?.type,
      },
      user_ids: fleetCheckUsers,
    };
    if (fleetCheckSummary.frequency?.type === 'weekly') {
      fleet_check_payload = {
        ...fleet_check_payload,
        frequency: {
          ...fleet_check_payload.frequency,
          days: fleetCheckSummary.frequency?.days,
        },
      };
    }

    let user_summary_payload = {
      ...userSummary,
      is_enabled: isUserEnabled,
      email_type: 'USER_SUMMARY',
      frequency: {
        type: userSummary.frequency?.type,
      },
      user_ids: [],
    };

    if (userSummary.frequency?.type === 'weekly') {
      user_summary_payload = {
        ...user_summary_payload,
        frequency: {
          ...user_summary_payload.frequency,
          days: userSummary.frequency?.days,
        },
      };
    }

    try {
      let lowTreadResponse = { status: 200 };
      let orgSummaryResponse = { status: 200 };
      let userSummaryResponse = { status: 200 };
      let fleetCheckResponse = { status: 200 };
      if (isAdmin) {
        if (
          (isOrgSummaryChange || isOrgUserChange) &&
          (isLowTreadUserChange || isTreadSummaryChange) &&
          isUserSummaryChange
        ) {
          lowTreadResponse = await sendEmailToSelectedUsers(
            low_tread_depth_payload
          );
          orgSummaryResponse = await sendEmailToSelectedUsers(
            orgSummaryPayload
          );
          userSummaryResponse = await sendEmailToSelectedUsers(
            user_summary_payload
          );
        } else if (
          (isOrgSummaryChange || isOrgUserChange) &&
          (isLowTreadUserChange || isTreadSummaryChange)
        ) {
          lowTreadResponse = await sendEmailToSelectedUsers(
            low_tread_depth_payload
          );
          orgSummaryResponse = await sendEmailToSelectedUsers(
            orgSummaryPayload
          );
        } else if (
          (isOrgSummaryChange || isOrgUserChange) &&
          isUserSummaryChange
        ) {
          orgSummaryResponse = await sendEmailToSelectedUsers(
            orgSummaryPayload
          );
          userSummaryResponse = await sendEmailToSelectedUsers(
            user_summary_payload
          );
        } else if (
          (isLowTreadUserChange || isTreadSummaryChange) &&
          isUserSummaryChange
        ) {
          lowTreadResponse = await sendEmailToSelectedUsers(
            low_tread_depth_payload
          );
          userSummaryResponse = await sendEmailToSelectedUsers(
            user_summary_payload
          );
        } else if (isUserSummaryChange) {
          userSummaryResponse = await sendEmailToSelectedUsers(
            user_summary_payload
          );
        } else if (isLowTreadUserChange || isTreadSummaryChange) {
          lowTreadResponse = await sendEmailToSelectedUsers(
            low_tread_depth_payload
          );
        } else if (isOrgSummaryChange || isOrgUserChange) {
          orgSummaryResponse = await sendEmailToSelectedUsers(
            orgSummaryPayload
          );
        }

        fleetCheckResponse = await sendEmailToSelectedUsers(
          fleet_check_payload
        );
      }

      if (
        lowTreadResponse.status === 200 &&
        userSummaryResponse.status === 200 &&
        orgSummaryResponse.status === 200 &&
        fleetCheckResponse.status === 200
      ) {
        dispatch(
          setNotification(
            'Email Subscription Updated Successfully',
            'success',
            true
          )
        );
        setIsExpanded({
          orgSummary: false,
          lowTread: false,
        });
        isOrgSummaryChange = false;
        isTreadSummaryChange = false;
        isUserSummaryChange = false;
        isFleetCheckChange = false;
        setIsUserChange(false);
        setIsLowTreadUserChange(false);
      } else {
        dispatch(setNotification('Something went wrong..', 'error', true));
      }
    } catch (err) {
      dispatch(setNotification('Something went wrong..', 'error', true));
    } finally {
      setIsSubmitting(false);
    }
  };
  const renderSelectDaysView = () => {
    if (isWeeklyOrg) {
      return (
        <>
          {SelectReportFrequency(
            orgSummary,
            setOrgSummary,
            'org-frequency-hook'
          )}

          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '30px',
              zIndex: 9,
              justifyContent: 'space-between',
            }}
          >
            {orgSummary.frequency.type === 'weekly' && (
              <SelectDaysView
                summary={orgSummary}
                hook='org-days'
                setSummary={setOrgSummary}
              />
            )}
            <Accordion
              style={{ height: 'max-content', width: '50%' }}
              expanded={isExpanded.orgSummary}
              ref={orgRef}
            >
              <AccordionSummary
                aria-controls='panel1a-content'
                id='panel1a-header'
                expandIcon={<ExpandMoreIcon />}
                onClick={() =>
                  setIsExpanded({
                    ...isExpanded,
                    orgSummary: !isExpanded.orgSummary,
                  })
                }
              >
                <Typography variant='h6' data-hook='select-org-user'>
                  {t('settingsPage.notifications.selectUser')}
                </Typography>
              </AccordionSummary>
              <Box>
                <AccordionDetails
                  style={{ maxHeight: '200px', overflow: 'auto' }}
                >
                  <FormControlLabel
                    label={t(`multiLayerReport.select`)}
                    control={
                      <Checkbox
                        checked={selectedUsers.every(user => user === true)}
                        indeterminate={
                          selectedUsers.every(user => user === false)
                            ? false
                            : selectedUsers.some(user => user === false)
                        }
                      />
                    }
                    onChange={e =>
                      handleSelectAllChange(e, setSelectedUsers, setUserIds)
                    }
                  />
                  <div>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        ml: 3,
                        gap: 1,
                        mt: 1,
                      }}
                    >
                      {users &&
                        users.map((user, i) => {
                          return (
                            <FormControlLabel
                              label={user.name}
                              control={
                                <Checkbox
                                  data-hook={`org-user-${i}`}
                                  onChange={e =>
                                    handleSelectUser(
                                      e,
                                      i,
                                      user.user_id,
                                      selectedUsers,
                                      setSelectedUsers,
                                      userIds,
                                      setUserIds,
                                      'org'
                                    )
                                  }
                                />
                              }
                              checked={selectedUsers[i]}
                            />
                          );
                        })}
                    </Box>
                  </div>
                </AccordionDetails>
              </Box>
            </Accordion>
          </Box>
        </>
      );
    }
  };

  const checkDisabled = () => {
    return (
      isTreadSummaryChange ||
      isOrgSummaryChange ||
      isOrgUserChange ||
      isLowTreadUserChange ||
      isUserSummaryChange ||
      isFleetCheckChange ||
      fleetCheckChange
    );
  };

  const renderUpdateNotificationButton = () => {
    return (
      <Box width='100%' display='flex' flexDirection='column' marginTop={1}>
        <ActionButtons
          datahook='update-pref'
          handleUpdate={() => handleUpdate()}
          handleClear={() => {
            setOrgSummary(emailPreferences.ORG_SUMMARY);
            setLowTreadSummary(emailPreferences.LOW_TREAD_DEPTH);
          }}
          disabled={!checkDisabled() || isSubmitting}
          isSubmitting={isSubmitting}
        />
      </Box>
    );
  };

  const handleSelectFrequencyChange = (e, summary, setSummary) => {
    const summaryCopy = JSON.parse(JSON.stringify(summary));
    summaryCopy['frequency']['type'] = e.target.value;
    setSummary(summaryCopy);
  };

  const SelectReportFrequency = (summary, setSummary, hook = '') => {
    const frequency = ['daily', 'weekly', 'monthly', 'biannually', 'quaterly'];
    return (
      <Select
        variant='standard'
        className={classes.select}
        value={summary && summary['frequency']['type']}
        onChange={e => handleSelectFrequencyChange(e, summary, setSummary)}
        multiple={false}
        data-hook={hook}
      >
        {frequency.map((type, i) => (
          <MenuItem key={type} value={type} data-hook={`frequency-${i}`}>
            {t(`common.frequency.${type}`)}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderAdminNotifications = () => {
    if (isAdmin) {
      return (
        <>
          <Grid item xs={6}>
            <Box className={classes.infoBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                gap={'30px'}
              >
                <Box>
                  <Typography
                    variant='subtitle1'
                    className={classes.textHeading}
                  >
                    {t('settingsPage.notifications.weeklyLabel')}
                  </Typography>
                  <Typography variant='subtitle' className={classes.textBody}>
                    {t('settingsPage.notifications.weeklyBody')}
                  </Typography>
                </Box>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name='weekly_summary'
                      checked={orgSummary.is_enabled}
                      onChange={e =>
                        handleSwitchChange(
                          e,
                          orgSummary,
                          setOrgSummary,
                          setIsWeeklyOrg
                        )
                      }
                      data-hook='switch-weekly'
                    />
                  }
                />
              </Box>
              {renderSelectDaysView()}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.infoBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                gap={'30px'}
              >
                <Box>
                  <Typography
                    variant='subtitle1'
                    className={classes.textHeading}
                  >
                    {t('settingsPage.notifications.tDepth')}
                  </Typography>
                  <Typography variant='subtitle' className={classes.textBody}>
                    {t('settingsPage.notifications.tDepthBody')}
                  </Typography>
                </Box>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      name='low_tread_depth'
                      checked={lowTreadSummary.is_enabled}
                      onChange={e =>
                        handleSwitchChange(
                          e,
                          lowTreadSummary,
                          setLowTreadSummary,
                          setIsWeeklyLowTread
                        )
                      }
                      data-hook='switch-low-TD'
                    />
                  }
                />
              </Box>
              {isWeeklyLowTread && (
                <>
                  {SelectReportFrequency(lowTreadSummary, setLowTreadSummary)}
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '30px',
                      zIndex: 9,
                      justifyContent: 'space-between',
                    }}
                  >
                    {lowTreadSummary.frequency.type === 'weekly' && (
                      <SelectDaysView
                        hook='select-low-TD'
                        summary={lowTreadSummary}
                        setSummary={setLowTreadSummary}
                      />
                    )}
                    <Accordion
                      style={{ height: 'max-content', width: '50%' }}
                      expanded={isExpanded.lowTread}
                      ref={lowTreadRef}
                    >
                      <AccordionSummary
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                        expandIcon={<ExpandMoreIcon />}
                        onClick={() =>
                          setIsExpanded({
                            ...isExpanded,
                            lowTread: !isExpanded.lowTread,
                          })
                        }
                      >
                        <Typography variant='h6'>
                          {t('settingsPage.notifications.selectUser')}
                        </Typography>
                      </AccordionSummary>
                      <Box>
                        <Box>
                          <AccordionDetails
                            style={{ maxHeight: '200px', overflow: 'auto' }}
                          >
                            <FormControlLabel
                              label={`Select All`}
                              control={
                                <Checkbox
                                  checked={lowTreadSelectedUsers.every(
                                    user => user === true
                                  )}
                                  indeterminate={
                                    lowTreadSelectedUsers.every(
                                      user => user === false
                                    )
                                      ? false
                                      : lowTreadSelectedUsers.some(
                                          user => user === false
                                        )
                                  }
                                />
                              }
                              onChange={e =>
                                handleSelectAllChange(
                                  e,
                                  setLowTreadSelectedUsers,
                                  setLowTreadUsers
                                )
                              }
                            />
                            <div>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  ml: 3,
                                  gap: 1,
                                  mt: 1,
                                }}
                              >
                                {users &&
                                  users.map((user, i) => {
                                    return (
                                      <FormControlLabel
                                        label={user.name}
                                        control={
                                          <Checkbox
                                            onChange={e =>
                                              handleSelectUser(
                                                e,
                                                i,
                                                user.user_id,
                                                lowTreadSelectedUsers,
                                                setLowTreadSelectedUsers,
                                                lowTreadUsers,
                                                setLowTreadUsers,
                                                'low_td'
                                              )
                                            }
                                          />
                                        }
                                        checked={lowTreadSelectedUsers[i]}
                                      />
                                    );
                                  })}
                              </Box>
                            </div>
                          </AccordionDetails>
                        </Box>
                      </Box>
                    </Accordion>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        </>
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6' data-hook='notification_summary'>
          {t('settingsPage.notifications.heading')}
        </Typography>
      </Grid>
      {renderAdminNotifications()}
      <Grid item xs={6}>
        <Box className={classes.infoBox}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            gap={'30px'}
          >
            <Box>
              <Typography variant='subtitle1' className={classes.textHeading}>
                {t('settingsPage.notifications.userReport')}
              </Typography>
              <Typography variant='subtitle' className={classes.textBody}>
                {t('settingsPage.notifications.userBodyDesc')}
              </Typography>
            </Box>
            <FormControlLabel
              control={
                <IOSSwitch
                  name='low_tread_depth'
                  checked={userSummary?.is_enabled}
                  onChange={e =>
                    handleSwitchChange(
                      e,
                      userSummary,
                      setUserSummary,
                      setIsUserEnabled
                    )
                  }
                  data-hook='switch-low-TD'
                />
              }
            />
          </Box>
          {isUserEnabled && (
            <>
              {SelectReportFrequency(
                userSummary,
                setUserSummary,
                'user-summary-freq-select'
              )}
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '30px',
                  zIndex: 9,
                  justifyContent: 'space-between',
                }}
              >
                {userSummary?.frequency?.type === 'weekly' && (
                  <SelectDaysView
                    hook='select-user-summary'
                    summary={userSummary}
                    setSummary={setUserSummary}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box className={classes.infoBox}>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            gap={'30px'}
          >
            <Box>
              <Typography variant='subtitle1' className={classes.textHeading}>
                {t('settingsPage.notifications.fleetCheckRemainder')}
              </Typography>
              <Typography
                variant='subtitle'
                data-hook='fleet-check-desc'
                className={classes.textBody}
              >
                {t('settingsPage.notifications.fleetCheckDesc')}
              </Typography>
            </Box>
            <FormControlLabel
              control={
                <IOSSwitch
                  name='weekly_summary'
                  checked={fleetCheckSummary?.is_enabled}
                  onChange={e =>
                    handleSwitchChange(
                      e,
                      fleetCheckSummary,
                      setFleetCheckSummary,
                      setIsFleetEnabled
                    )
                  }
                  data-hook='switch-fleet'
                />
              }
            />
          </Box>
          {isFleetEnabled && (
            <>
              {SelectReportFrequency(
                fleetCheckSummary,
                setFleetCheckSummary,
                'fleet_frequency'
              )}
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '30px',
                  zIndex: 9,
                  justifyContent: 'space-between',
                }}
              >
                {fleetCheckSummary.frequency.type === 'weekly' && (
                  <SelectDaysView
                    hook='select-fleet-days'
                    summary={fleetCheckSummary}
                    setSummary={setFleetCheckSummary}
                  />
                )}
                <Accordion
                  style={{ height: 'max-content', width: '50%' }}
                  expanded={isExpanded.fleetUser}
                  ref={fleetCheckRef}
                >
                  <AccordionSummary
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    expandIcon={<ExpandMoreIcon />}
                    onClick={() =>
                      setIsExpanded({
                        ...isExpanded,
                        fleetUser: !isExpanded.fleetUser,
                      })
                    }
                  >
                    <Typography variant='h6'>
                      {t('settingsPage.notifications.selectUser')}
                    </Typography>
                  </AccordionSummary>
                  <Box>
                    <Box>
                      <AccordionDetails
                        style={{ maxHeight: '200px', overflow: 'auto' }}
                      >
                        <FormControlLabel
                          label={`Select All`}
                          control={
                            <Checkbox
                              checked={fleetCheckSelectedUsers.every(
                                user => user === true
                              )}
                              indeterminate={
                                fleetCheckSelectedUsers.every(
                                  user => user === false
                                )
                                  ? false
                                  : fleetCheckSelectedUsers.some(
                                      user => user === false
                                    )
                              }
                            />
                          }
                          onChange={e =>
                            handleSelectAllChange(
                              e,
                              setFleetCheckSelectedUsers,
                              setFleetCheckUsers
                            )
                          }
                        />
                        <div>
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              ml: 3,
                              gap: 1,
                              mt: 1,
                            }}
                          >
                            {users &&
                              users.map((user, i) => {
                                return (
                                  <FormControlLabel
                                    label={user.name}
                                    control={
                                      <Checkbox
                                        onChange={e =>
                                          handleSelectUser(
                                            e,
                                            i,
                                            user.user_id,
                                            fleetCheckSelectedUsers,
                                            setFleetCheckSelectedUsers,
                                            fleetCheckUsers,
                                            setFleetCheckUsers,
                                            'fleet_check'
                                          )
                                        }
                                      />
                                    }
                                    checked={fleetCheckSelectedUsers[i]}
                                  />
                                );
                              })}
                          </Box>
                        </div>
                      </AccordionDetails>
                    </Box>
                  </Box>
                </Accordion>
              </Box>
            </>
          )}
        </Box>
      </Grid>

      {renderUpdateNotificationButton()}
    </Grid>
  );
};

export default Notifications;

function SelectDaysView({
  multiple = true,
  hook,
  summary = {},
  setSummary = () => {},
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const handleDaySelectChange = event => {
    let curr = JSON.parse(JSON.stringify(summary));
    curr['frequency']['days'] = event.target.value;
    setSummary(curr);
  };

  const handleDelete = day => {
    let curr = JSON.parse(JSON.stringify(summary));
    curr['frequency']['days'] = curr['frequency']['days'].filter(
      item => item !== day
    );
    setSummary(curr);
  };

  return (
    <Box>
      <Select
        variant='standard'
        className={classes.select}
        multiple={multiple}
        value={(summary['frequency'] && summary['frequency']['days']) || []}
        data-hook={hook}
        onChange={handleDaySelectChange}
        renderValue={selected => (
          <Typography className={classes.textBody}>
            {multiple
              ? t('settingsPage.notifications.select')
              : t('settingsPage.notifications.selectDay')}
          </Typography>
        )}
      >
        {daysOfWeek.map(({ day, icon }) => (
          <MenuItem
            style={{
              backgroundColor:
                summary['frequency']['days'] &&
                summary['frequency']['days'].includes(day) &&
                theme.palette.primary.main,
              color:
                summary['frequency']['days'] &&
                summary['frequency']['days'].includes(day) &&
                '#fff',
            }}
            data-hook={`day-${day}`}
            key={day}
            value={day}
          >
            {t(`settingsPage.notifications.days.${day}`)}
          </MenuItem>
        ))}
      </Select>
      <Box
        display='flex'
        alignItems='center'
        flexWrap='wrap'
        gap={'5px'}
        marginTop={2}
      >
        {summary['frequency']['days'] &&
          summary['frequency']['days'].map(day => (
            <Chip
              key={day}
              label={t(`settingsPage.notifications.days.${day}`)}
              data-hook={`chip-${hook}`}
              clickable
              onDelete={
                summary['frequency']['days'].length > 1
                  ? () => handleDelete(day)
                  : null
              }
            />
          ))}
      </Box>
    </Box>
  );
}

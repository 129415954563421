import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Typography, Box } from '@mui/material';
import { useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function ScrollableTabsButtonAuto({ items }) {
  const guideSelectionSelector = useSelector(
    state => state.users.guideSelection
  );
  const initialValue = guideSelectionSelector === 'fleet-settings' && 3;
  const [value, setValue] = React.useState(initialValue || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <AppBar position='static' color='default'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          {items.length &&
            items.map((item, i) => (
              <Tab
                label={<Typography variant='h6'>{item.label}</Typography>}
                {...a11yProps(i)}
                key={i}
                data-hook={item.dataHook}
              />
            ))}
        </Tabs>
      </AppBar>
      {items.length &&
        items.map((item, i) => (
          <TabPanel value={value} index={i}>
            {' '}
            {item.component}
          </TabPanel>
        ))}
    </div>
  );
}

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import KpiTile from 'components/custom/KpiTile';
import useFleetOverview from 'hooks/useFleetOverview';
import { currencyFormatEUR, currencyFormatNonEU } from 'utils/helper';
import { setUserSelector } from 'redux/users/ducks';
import useKpiList from 'hooks/useKpiList';
import Tile from 'components/Tile';
import i18next from 'i18next';
import {
  fleetOverviewSelector,
  savingEstimateSelector,
} from 'redux/fleets/ducks';
import { ReactComponent as Coins } from 'assets/svg/home/Ic-coins.svg';
import { FaArrowRight } from 'react-icons/fa';
import { ReactComponent as Co2 } from 'assets/svg/home/co2 1.svg';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      width: '550px',
      borderRadius: '8px',
      display: 'flex',
      flexWrap: 'wrap',
    },
    header: {
      height: '60px',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingInline: theme.spacing(2.5),
      alignItems: 'center',
    },
    heading: { fontSize: 'clamp(18px, 1.3vw, 3rem)', fontWeight: 600 },
    viewMoreLink: {
      fontSize: '0.75rem',
      fontWeight: 600,
      cursor: 'pointer',
      color: '#3577DC',
      gridGap: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      textTransform: 'capitalize',
    },
    tileContent: {
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.5),
    },
  };
});

const itemsToExclude = [
  'avg-km-per-asset',
  'avg-fuel-consumption',
  'no-of-assets',
];

const SavingPotential = ({ vehicles, fleets }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();

  const primaryKPI = [
    'co2-emissions',
    'fuel-costs',
    'tire-costs',
    'co2-achieved',
  ];

  const user = useSelector(setUserSelector);
  const fleetRes = useSelector(fleetOverviewSelector);
  const fleetOverviewRes = useSelector(savingEstimateSelector);
  const vechilesOverviewinfo = useSelector(
    state => state.fleet.vehicleOverview
  );
  const { getFleetOverview, dashboardData, isLoading, response } =
    useFleetOverview();
  const { getKpiList } = useKpiList();

  const list = getKpiList(response);
  const isTireRecommendationAvailable_ =
    fleetOverviewRes &&
    Object.values(
      fleetOverviewRes?.costs?.tire_related_savings_potential
    ).every(item => item.registered_vehicles === 0);

  // eslint-disable-next-line

  const hasTruck =
    vechilesOverviewinfo?.bus.count + vechilesOverviewinfo?.truck.count;

  useEffect(() => {
    if (user !== undefined && fleetOverviewRes && fleetRes) {
      getFleetOverview(fleetOverviewRes, fleetRes);
    }
  }, [getFleetOverview, user, fleetOverviewRes, fleetRes]);

  let x = list;
  if (fleets) {
    x = x.filter(item => !itemsToExclude.includes(item.key));
  }
  if (!hasTruck) {
    x = x.filter(item => !item.key.includes('fuel'));
  }

  let kpiList = fleets ? x : list;
  // eslint-disable-next-line
  const achievedSavings = {
    key: 'co2-achieved',
    value: `${
      fleetOverviewRes?.costs?.tire_related_savings_potential?.co2_achieved
        ?.co2_total || 0
    }`,
    isCurrency: false,
    isDecimalValue: true,
    text: 'CO2 Emissions Reduced',
    icon: <Co2 stroke={theme.palette.primary.main} alt='co2 emissions' />,
    secondaryValue: t('common.perLitre'),
  };

  const renderKpiList = () => {
    if (!kpiList) {
      return;
    }

    return kpiList.map(kpi => {
      if (primaryKPI.includes(kpi.key)) {
        return (
          <Grid item xs={12} md={4} lg={4} key={kpi.key}>
            <CustomKpiTile
              isLoading={isLoading}
              border={'1px solid var(--default-gray-200, #E5E7EB)'}
              icon={kpi.icon}
              primaryLabel={kpi.text}
              value={
                kpi.isCurrency
                  ? i18next.language === 'de'
                    ? currencyFormatEUR(kpi.value)
                    : currencyFormatNonEU(kpi.value)
                  : kpi.value
              }
            />
          </Grid>
        );
      }
      return <></>;
    });
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Tile>
            <Box className={classes.header}>
              <Typography variant='subtitle1'>
                {t('common.savingPotential') +
                  (!isTireRecommendationAvailable_
                    ? ''
                    : t('homePage.initialAssessment'))}
              </Typography>
              <Box>
                <Button
                  color='primary'
                  size='large'
                  className={classes.moreBtn}
                  endIcon={<FaArrowRight size={16} />}
                  data-hook='recent-activity-drawer-open'
                >
                  <Link
                    to='/home/saving-potentials'
                    className={classes.link}
                    data-hook='navigate_to_see_more'
                  >
                    {t('common.seeMore')}
                  </Link>
                </Button>
              </Box>
            </Box>
          </Tile>
        </Grid>
        <Grid item lg={12}>
          <Grid container spacing={2} className={classes.tileContent}>
            <Grid item xs={12} md={4} lg={4}>
              <CustomKpiTile
                isLoading={isLoading}
                border={'1px solid var(--default-gray-200, #E5E7EB)'}
                icon={<Coins fill={theme.palette.primary.main} alt='coins' />}
                primaryLabel={t('common.savingPotential')}
                value={
                  i18next.language === 'de'
                    ? currencyFormatEUR(dashboardData.totalPotentialSavings)
                    : currencyFormatNonEU(dashboardData.totalPotentialSavings)
                }
                timePeriod={t('common.perYear')}
              />
            </Grid>
            {renderKpiList()}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const CustomKpiTile = props => {
  return <KpiTile paddingX={1.5} paddingY={1.5} {...props} />;
};

export default SavingPotential;

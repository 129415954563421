import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import padlock from 'assets/images/padlock-icon.png';
import Tour from 'reactour';
import {
  Box,
  Button,
  Grid,
  Typography,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Chip,
  Link,
  useTheme,
  Skeleton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Add, CloseOutlined, DoneOutline } from '@mui/icons-material';

import {
  setUserSelector,
  setAccessTokenSelector,
  setPageLevelTourSelector,
  setTourSelector,
  setTour,
  workforceSelector,
} from 'redux/users/ducks';
import { setNotification } from 'redux/notifications/ducks';
import {
  setProvider,
  setProviderSelector,
  setTelematicsData,
  telematicsDataSelector,
} from 'redux/telematicsProvider/ducks';
import {
  telematicsProviderIntegration,
  getTelematicsFormData,
} from 'services/util_services';
import { useTranslation } from 'react-i18next';
import MotionWrap from 'wrapper/MotionWrap';
import TelematicsModal from './TelematicsModal/TelematicsModal';
import { providerDetails } from './const';
import Tile from '../Tile';
import TourContent from '../Tour/Content/TourContent';
import { TELEMATICS_INFO, USER_ROLES } from 'utils/string_utils';
import {
  allTrackedVehiclesSelector,
  fleetOverviewSelector,
} from 'redux/fleets/ducks';
import CustomTextField from '../custom/CustomTextField';
import i18next from 'i18next';
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/system';
import useFetchAllVehicles from 'hooks/useFetchAllVehicles';

const useStyles = makeStyles()(theme => {
  return {
    brand: {
      maxWidth: 395,
      height: 80,
      // margin: '0rem auto',
      border: '1px solid black',
      borderRadius: 16,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    bool: {
      width: '70%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    chip: {
      backgroundColor: 'white',
      position: 'relative',
      padding: 10,
      marginTop: 10,
      border: '1px solid grey',
      borderRadius: '5px',
    },
    box: {
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    box1: {
      backgroundColor: '#fff',
      padding: '1rem 1.5rem',
      boxShadow: theme.palette.boxShadow,
      overflow: 'auto',
      minHeight: '663px',
    },
    box2: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    box3: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      gap: 5,
    },
    commonTypography: {
      fontWeight: 700,
      fontSize: 'clamp(20px, 1.5vw, 3rem)',
      alignSelf: 'flex-start',
    },
    addVehiclesContainer: {
      height: 20,
      width: 50,
      position: 'absolute',
      right: 0,
      top: 10,
      cursor: 'pointer',
    },
    submitBtn: {
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
    },
    skeletonBox: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 20,
      marginTop: 20,
    },
    imgContainer: {
      width: '100%',
      height: 'inherit',
      display: 'block',
      overflow: 'hidden',

      '& img': {
        width: '100%',
        height: 'inherit',
        objectFit: 'contain',
      },
    },
    tileBox: {
      backgroundColor: '#fff',
      padding: '1rem 1.5rem',
      boxShadow: theme.palette.boxShadow,
      height: '100%',
    },
    commonFontSize: {
      fontSize: '1vw',
    },
    tableCell: {
      textTransform: 'uppercase',
      fontSize: '1vw',
    },
    tosText: {
      opacity: 0.5,
      textAlign: 'center',
    },
  };
});

const telematics = 'telematics';
const TELEMATICS_INFO_STR = 'telematics_info';
const telematicsInfo_LS = JSON.parse(TELEMATICS_INFO);
export const TelematicsDataIntegration = props => {
  const user = useSelector(setUserSelector);
  const access_token = useSelector(setAccessTokenSelector);
  const isTour = useSelector(setTourSelector);

  const isTourRequired = !useSelector(setPageLevelTourSelector)?.telematics;

  const provider = useSelector(setProviderSelector);
  // const vehiclesForFleet = useSelector(vehiclesForFleetSelector);
  const [showPassword, setShow] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [showApi, setShowApi] = useState(false);
  const [formData, setFormData] = useState(null);
  const [rows, setData] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const inputRef = useRef(null);
  const [resource, setResource] = useState(null);
  const telematicsData =
    useSelector(telematicsDataSelector) || telematicsInfo_LS;
  const allFleetsData = useSelector(fleetOverviewSelector)?.fleets;

  let managedVehicles = useSelector(allTrackedVehiclesSelector);
  const workforce = useSelector(workforceSelector);
  const userIsAdmin =
    user?.iam_role === USER_ROLES.admin ||
    user?.iam_role === USER_ROLES.superAdmin;
  const { isLoading, allVehicles, fetchFleets } = useFetchAllVehicles();
  useEffect(() => {
    fetchFleets(allFleetsData);

    // eslint-disable-next-line
  }, [allFleetsData, access_token]);

  //Fetching Form Data
  useEffect(() => {
    async function fetchFormData() {
      try {
        const res = await getTelematicsFormData(access_token, provider);
        if (res !== undefined) {
          if (res.status === 200) {
            setFormData(res?.data?.resource);
          } else {
            console.error('E10008', 'status not 200');
          }
        }
      } catch (error) {
        console.error('E10009', error);
      }
    }
    fetchFormData();
  }, [access_token, provider]);
  useEffect(() => {
    if (user !== null && user !== undefined && telematicsData) {
      setData(JSON.parse(JSON.stringify(telematicsData)));
    }
  }, [user, telematicsData]);
  const matchedVehicles = allVehicles
    ?.filter(vehicle =>
      resource?.registered_and_tracked.includes(vehicle.vehicle_id)
    )
    .map(vehicle => ({
      vehicle_id: vehicle.vehicle_id,
      name: vehicle.name,
    }));

  const isFalse = (
    <div className={classes.bool}>
      <CloseOutlined color='error' />
    </div>
  );
  const isTrue = (
    <div className={classes.bool}>
      <DoneOutline color='primary' />
    </div>
  );

  const [formVal, setFormVal] = useState({});

  const handleSetProvider = provider => {
    dispatch(setProvider(provider));
    setFormVal({});
    setVehicles([]);
  };

  const handleChangeFleet = e => {
    setFormVal({ ...formVal, [e.target.name]: e.target.value });
  };

  const addVehiclesHandler = () => {
    setVehicles(vehicle => {
      const x = [...vehicle, inputRef.current.value];
      const y = [...new Set(x)];
      return y;
    });
  };

  const removeVehiclesHandler = item => {
    const x = vehicles.filter(v => v !== item);
    setVehicles(x);
  };

  const handleShowFieldValue = entry => {
    entry === 'password' && setShow(!showPassword);
    entry.includes('api') && setShowApi(!showApi);
    entry.includes('secret') && setShowSecret(!showSecret);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleSubmitProvider = async e => {
    setIsSubmitting(true);
    e.preventDefault();
    let payload = formVal;
    if (provider === 'yellowfox') {
      if (vehicles.length > 0) {
        payload = { ...formVal, vehicles: vehicles };
      } else {
        payload = formVal;
      }
    }
    try {
      const res = await telematicsProviderIntegration(
        access_token,
        provider,
        payload
      );
      const firstDigit = res?.response?.status_code?.toString().substr(0, 1);

      if (res?.status === 200) {
        dispatch(
          setNotification('Credentials Submitted Successfully', 'success', true)
        );
        const resource = res?.data?.resource;
        const newTelematicsData = resource;
        if (telematicsData) {
          const telematicsData_ = JSON.parse(JSON.stringify(telematicsData));
          let currentTelematicsData = telematicsData_;
          if (telematicsData_?.length > 0) {
            currentTelematicsData?.push(newTelematicsData);
          } else {
            currentTelematicsData = [newTelematicsData];
          }
          dispatch(setTelematicsData(currentTelematicsData));
          localStorage.setItem(
            TELEMATICS_INFO_STR,
            JSON.stringify(currentTelematicsData)
          );
          setFormVal({});
        }
      } else if (firstDigit === '4') {
        dispatch(
          setNotification(res?.['response']?.['message'], 'error', true)
        );
      } else if (res?.response?.status_code === 500) {
        dispatch(
          setNotification(
            res?.['response']?.['message']?.['msg'].split(':')[1],
            'error',
            true
          )
        );
      } else {
        dispatch(setNotification(res?.status, 'error', true));
      }
    } catch (error) {
      console.error('E20005', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = [
    {
      selector: '#integrated-telematics-provider',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.telematics.integratedTelematicsProvider')}
          finalAction='Next'
          page={telematics}
          goTo={() => goTo(1)}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
    {
      selector: '#provider',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.telematics.provider')}
          finalAction='Next'
          page={telematics}
          goTo={() => goTo(2)}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
    {
      selector: '#authorization',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.telematics.authorization')}
          finalAction='Finish'
          page={telematics}
          goTo={() => {
            dispatch(setTour(false));
          }}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
  ];

  useEffect(() => {
    if (isTourRequired) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isTourRequired]);

  const renderLoader = () => {
    if (isLoading) {
      return (
        <Box className={classes.box}>
          <StyledSkeleton variant='rect' height='50px' width='100%' />
        </Box>
      );
    } else if (rows.length > 0) {
      return (
        <TableContainer component={Paper}>
          <Table
            aria-label='simple table'
            stickyHeader
            style={{ height: 'max-content' }}
          >
            <TableHead>
              <TableRow>
                <TableCell className={classes.commonFontSize}>
                  {' '}
                  {t('dataIntegrationPage.provider')}
                </TableCell>
                <TableCell className={classes.commonFontSize}>
                  {t('dataIntegrationPage.credentialsToken')}
                </TableCell>
                <TableCell className={classes.commonFontSize}>
                  {t('dataIntegrationPage.traceAvailable')}
                </TableCell>
                <TableCell className={classes.commonFontSize}>
                  {t('dataIntegrationPage.trackAvailable')}
                </TableCell>
                <TableCell className={classes.commonFontSize} />
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow key={i}>
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.tableCell}
                  >
                    {row?.protocol}
                  </TableCell>
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.tableCell}
                  >
                    {userIsAdmin ? row?.credentials_token : '********'}
                  </TableCell>
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.tableCell}
                  >
                    {row?.is_trace_available ? isTrue : isFalse}
                  </TableCell>
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.tableCell}
                  >
                    {row?.is_track_available ? isTrue : isFalse}
                  </TableCell>
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.tableCell}
                  >
                    <Button
                      variant='text'
                      color='primary'
                      onClick={() => {
                        fetchFleets(allFleetsData);
                        setResource(managedVehicles?.[i]);
                        setOpen(true);
                      }}
                      data-hook='details'
                    >
                      {t('dataIntegrationPage.detailsBtn')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
    } else {
      return (
        <Box margin='1rem 0px'>
          <Typography variant='h6' gutterBottom align='center'>
            {t('dataIntegrationPage.noTelematicsMessage')}
          </Typography>
        </Box>
      );
    }
  };
  const isTelematicsVisible = () => {
    let temp = workforce?.workforceData?.filter(
      x => x.name === `${user.first_name} ${user.last_name}`
    );
    //test
    if (temp?.length) {
      let logged_in_user = temp[0];

      if (logged_in_user.email.includes('@co2opt.com')) {
        return true;
      }
    }
    return false;
  };
  const renderTelematicsSection = () => {
    if (userIsAdmin) {
      return (
        <Box margin='1rem 0px'>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12} id='provider'>
              <Tile
                infoComponent={t('tileToolTip.telematics.integratedProvides')}
              >
                <Box className={classes.box1}>
                  <Typography
                    variant='h5'
                    gutterBottom
                    align='left'
                    className={classes.commonTypography}
                    data-hook='provider-typography'
                  >
                    {t('dataIntegrationPage.provider')}
                  </Typography>
                  <Box marginTop='1rem'>
                    <Grid container spacing={2}>
                      {isLoading
                        ? [...Array(8).keys()].map(item => (
                            <Grid key={item} item lg={6} md={6} xs={12}>
                              <StyledSkeleton
                                variant='rectangular'
                                height='80px'
                                width='100%'
                              />
                            </Grid>
                          ))
                        : providerDetails.map((item, index) => (
                            <Grid key={index} item lg={6} md={6} xs={12}>
                              <Box
                                bgcolor={
                                  item.name === provider
                                    ? 'rgba(144, 202 , 249 , 0.2)'
                                    : '#fff'
                                }
                                className={classes.brand}
                                onClick={() => handleSetProvider(item.name)}
                                data-hook={item.name}
                              >
                                <div className={classes.imgContainer}>
                                  <img src={item.imgSrc} alt={item.name} />
                                </div>
                              </Box>
                            </Grid>
                          ))}
                    </Grid>
                  </Box>
                </Box>
              </Tile>
            </Grid>
            <Grid item lg={6} md={6} xs={12} id='authorization'>
              <Tile infoComponent={t('tileToolTip.telematics.authorization')}>
                <Box position={'relative'}>
                  <Box className={classes.box1}>
                    <Typography
                      variant='h5'
                      className={classes.commonTypography}
                      gutterBottom
                      data-hook='authorization-typography'
                    >
                      {t('dataIntegrationPage.auth')}
                    </Typography>
                    <Box className={classes.box2} data-hook='container'>
                      <form onSubmit={handleSubmitProvider}>
                        {formData !== null ? (
                          <Box width='100%'>
                            {formData?.map((item, i) => (
                              <Fragment key={i}>
                                {item?.key.includes('password') ? (
                                  <Box>
                                    <FormControl fullWidth variant='outlined'>
                                      <InputLabel
                                        htmlFor='outlined-adornment-password'
                                        required
                                      >
                                        {item?.key}
                                      </InputLabel>
                                      <OutlinedInput
                                        id='outlined-adornment-password'
                                        label={item?.key}
                                        margin={'normal'}
                                        name={item?.key}
                                        type={
                                          showPassword ? 'text' : 'password'
                                        }
                                        endAdornment={
                                          <InputAdornment position='end'>
                                            <IconButton
                                              aria-label='toggle password visibility'
                                              onClick={() =>
                                                handleShowFieldValue(item.key)
                                              }
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge='end'
                                            >
                                              {showPassword ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        required
                                        variant={'outlined'}
                                        value={formVal[item.key] || ''}
                                        onChange={handleChangeFleet}
                                        fullWidth
                                      />
                                    </FormControl>
                                  </Box>
                                ) : item?.key.includes('api') ? (
                                  <Box margin='1rem 0px'>
                                    <FormControl fullWidth variant='outlined'>
                                      <InputLabel
                                        htmlFor='outlined-adornment-api'
                                        required
                                      >
                                        {item?.key}
                                      </InputLabel>
                                      <OutlinedInput
                                        id='outlined-adornment-api'
                                        label={item?.key}
                                        margin={'normal'}
                                        name={item?.key}
                                        type={showApi ? 'text' : 'password'}
                                        endAdornment={
                                          <InputAdornment position='end'>
                                            <IconButton
                                              aria-label='toggle password visibility'
                                              onClick={() =>
                                                handleShowFieldValue(item.key)
                                              }
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge='end'
                                            >
                                              {showApi ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        required
                                        variant={'outlined'}
                                        value={formVal[item.key] || ''}
                                        onChange={handleChangeFleet}
                                        fullWidth
                                      />
                                    </FormControl>
                                  </Box>
                                ) : item?.key.includes('secret') ? (
                                  <Box margin='1rem 0px'>
                                    <FormControl fullWidth variant='outlined'>
                                      <InputLabel
                                        htmlFor='outlined-adornment-secret'
                                        required
                                      >
                                        {item?.key}
                                      </InputLabel>
                                      <OutlinedInput
                                        id='outlined-adornment-secret'
                                        label={item?.key}
                                        margin={'normal'}
                                        name={item?.key}
                                        type={showSecret ? 'text' : 'password'}
                                        endAdornment={
                                          <InputAdornment position='end'>
                                            <IconButton
                                              aria-label='toggle password visibility'
                                              onClick={() =>
                                                handleShowFieldValue(item.key)
                                              }
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge='end'
                                            >
                                              {showSecret ? (
                                                <Visibility />
                                              ) : (
                                                <VisibilityOff />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        required
                                        variant={'outlined'}
                                        value={formVal[item.key] || ''}
                                        onChange={handleChangeFleet}
                                        fullWidth
                                      />
                                    </FormControl>
                                  </Box>
                                ) : item.key.includes('vehicles') ? (
                                  <Box margin='1rem 0px' position='relative'>
                                    <CustomTextField
                                      id='outlined-basic'
                                      label={item?.key}
                                      name={item?.key}
                                      value={formVal[item.key] || ''}
                                      onChange={handleChangeFleet}
                                      onKeyPress={e => {
                                        if (e.key === 'Enter') {
                                          addVehiclesHandler();
                                          e.preventDefault();
                                        }
                                      }}
                                      variant='outlined'
                                      placeholder={t(
                                        'dataIntegrationPage.yellowfoxVehiclePlaceholder'
                                      )}
                                      fullWidth
                                      required
                                      inputRef={inputRef}
                                    />
                                    <div
                                      className={classes.addVehiclesContainer}
                                      onClick={addVehiclesHandler}
                                    >
                                      <Add style={{ fontSize: 40 }} />
                                    </div>
                                    <Box className={classes.box3}>
                                      {vehicles.length > 0 &&
                                        vehicles.map((item, i) => (
                                          <Chip
                                            key={i}
                                            label={item}
                                            onDelete={e =>
                                              removeVehiclesHandler(item)
                                            }
                                            className={classes.chip}
                                          />
                                        ))}
                                    </Box>
                                  </Box>
                                ) : (
                                  <Box margin='1rem 0px'>
                                    <CustomTextField
                                      id={item.key}
                                      label={item?.key}
                                      name={item?.key}
                                      value={formVal[item.key] || ''}
                                      onChange={handleChangeFleet}
                                      variant='outlined'
                                      placeholder={`${t(
                                        'common.eg'
                                      )} abcdef12345`}
                                      fullWidth
                                      required
                                    />
                                  </Box>
                                )}
                              </Fragment>
                            ))}
                            <p className={classes.tosText} data-hook='tos'>
                              {t('dataIntegrationPage.TOS')}{' '}
                              <Link
                                href={
                                  i18next.language.includes('de')
                                    ? process.env.REACT_APP_TNC_DE_URL
                                    : process.env.REACT_APP_TNC_EN_URL
                                }
                                target='_blank'
                                rel='noreferrer'
                              >
                                {t('common.termsAndCondition')}
                              </Link>
                            </p>

                            <Box margin='20px 0px'>
                              <Button
                                color={'primary'}
                                type={'submit'}
                                variant={'contained'}
                                disabled={
                                  isSubmitting || !isTelematicsVisible()
                                }
                                className={classes.submitBtn}
                                fullWidth
                                data-hook='submit-btn'
                              >
                                {!isSubmitting ? (
                                  <Typography variant='h6' align='center'>
                                    {t('common.submitBtn')}
                                  </Typography>
                                ) : (
                                  <CircularProgress />
                                )}
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <Box className={classes.skeletonBox}>
                            <FormSkeleton heightFactor={1} />
                            <FormSkeleton heightFactor={1} />
                            <FormSkeleton heightFactor={1} />
                            <FormSkeleton heightFactor={0.8} />
                          </Box>
                        )}
                      </form>
                    </Box>
                  </Box>
                  {!isTelematicsVisible() && (
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',

                        zIndex: '1000',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',

                          alignItems: 'center',
                          width: '100%',
                          padding: '1rem 2rem',
                          backgroundColor: 'rgba(105, 166, 40, 0.4)',
                          border: `3px solid ${theme.palette.primary.main}`,

                          borderRadius: 16,
                        }}
                      >
                        <img
                          src={padlock}
                          style={{ width: '70px' }}
                          alt='maintence_logo'
                        />
                        <Typography
                          variant='h6'
                          gutterBottom
                          fontWeight='bold'
                          align='center'
                        >
                          {t('common.telemtics_support')}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Tile>
            </Grid>
          </Grid>
        </Box>
      );
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12} id='integrated-telematics-provider'>
          <Tile infoComponent={t('tileToolTip.telematics.connectedProviders')}>
            <Box className={classes.tileBox}>
              <Typography variant='h5' gutterBottom align='left'>
                {t('dataIntegrationPage.integratedProviders')}
              </Typography>

              {renderLoader()}
            </Box>
          </Tile>
        </Grid>
      </Grid>
      {renderTelematicsSection()}

      {open && (
        <TelematicsModal
          open={open}
          close={setOpen}
          resource={resource}
          vehicles={allVehicles}
          regTracked={matchedVehicles}
        />
      )}
      <Tour
        startAt={0}
        steps={steps}
        isOpen={isTour}
        accentColor={theme.palette.primary.main}
        onRequestClose={() => dispatch(setTour(false))}
        showNavigation={false}
        showButtons={false}
      />
    </div>
  );
};

export default MotionWrap(
  TelematicsDataIntegration,
  { opacity: 0 },
  { opacity: 1 },
  { opacity: 0 }
);

const FormSkeleton = ({ heightFactor }) => {
  return (
    <StyledSkeleton variant='rect' height={80 * heightFactor} width='100%' />
  );
};

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
  root: {
    borderRadius: '10px',
  },
}));

import * as yup from 'yup';

// const pincodeRegex = '/^[1-9]{1}[0-9]{2}s{0,1}[0-9]{3}$/';

export const passwordResetValidationSchema = yup.object({
  password: yup
    .string('Enter your password')
    .trim()
    .min(8, 'At least 8 Characters')
    .matches(
      /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
      'Must contain at least one lowercase, uppercase ,number & symbol each'
    )
    .required('Password is required'),
});
export const resetvalidationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
});
export const loginvalidationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Min 8 charaters needed')
    .required('Password is required'),
});
export const signupvalidationSchema = yup.object({
  firstName: yup
    .string('Enter your first')
    .min(3, 'Atleast 3 Characters')
    .required('Required'),
  lastName: yup
    .string('Enter your first')
    .min(3, 'Atleast 3 Characters')
    .required('Required'),

  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string('Enter your password')
    .min(8, 'Min 8 charaters needed')
    .required('Password is required'),
  pincode: yup
    .string('Enter your password')
    .min(5, 'Min 8 charaters needed')
    .required('Pincode is required'),
  organizationName: yup
    .string('Enter your Organization Name')
    .required('Required'),
  country: yup.string().required('Required'),
});
export const changePasswordValidationSchema = yup.object({
  old_password: yup
    .string('Enter your password')
    .required('Password is required'),
  new_password: yup.string().required('This field is required'),
  confirm_password: yup.string().when('new_password', {
    is: val => (val && val.length > 0 ? true : false),
    then: yup
      .string()
      .oneOf([yup.ref('new_password')], 'Both password need to be the same'),
  }),
});
export const supportValidationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required'),
  subject: yup.string().required('This field is required'),
  message: yup.string().required('This field is required'),
});
export const companyProfileValidationSchema = yup.object({
  companyName: yup
    .string('Enter your Company Name')

    .required('Required'),
  phoneNumber: yup.string('Enter Phone No').required('Required'),
  yards: yup.number('Enter Yards').min(1).max(255).required('Required'),
  streetAddress: yup
    .string()
    .min(5)
    .max(255)
    .required('This field is required'),
  houseNo: yup.string().min(1).max(4).required('This field is required'),
  zipCode: yup
    .number('Enter ZipCode')
    .min(1000)
    .max(999999)
    .required('Required'),
  city: yup.string().min(2).max(20).required('This field is required'),
});

export function splitAndCapitalize(word) {
  let splittedString = word.split('-');
  splittedString = splittedString.map(
    word => word[0]?.toUpperCase() + word?.slice(1)
  );
  return splittedString.join(' ');
}
export const passwordComplexityConfig = {
  number: { condition: /\d/ },
  specialCharacter: { condition: /\W/ },
  lowercase: { condition: /[a-z]/ },
  uppercase: { condition: /[A-Z]/ },
  length: { condition: 8 },
};

export const formValidations = {
  email: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
};

export const USER_ROLES = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  service: 'service',
  reporter: 'reporter',
};

export const emptyResponse = {
  LOW_TREAD_DEPTH: {
    is_enabled: false,
    frequency: {
      days: [],
      type: 'daily',
    },
    user_ids: [],
  },
  USER_SUMMARY: {
    is_enabled: false,
    frequency: {
      days: [],
      type: 'daily',
    },
  },
  ORG_SUMMARY: {
    is_enabled: false,
    frequency: {
      type: 'daily',
    },
    user_ids: [],
  },
};

export const TIRES = {
  tireStatus: {
    IN_USE: 'in_use',
    IN_STORAGE: 'in_storage',
  },
  TIRE_ID: 'tire_id',
  MILEAGE: 'mileage',
  TREAD_DEPTH: 'last_tread_depth',
  MAX_DEPTH: 20,
  EST_DEPTH: 'estimated_current_tread_depth',
  EST_WEAR_OUT: 'estimated_wear_out_date',
  RETREAD: 'isRetread',
  REGROOVE: 'isRegrooved',
  PRESSURE: 'pressure',
  TIRE_GROOVE_TD: 'groove-td',
};

export const VEHICLE = {
  vehicle_type: {
    TRAILER: 'trailer',
    TRAILER1: 'TRAILER',
    TRAILERS: 'trailers',
    TRUCK: 'TRUCK',
    TRUCK1: 'TRUCK',
    TRUCKS: 'trucks',
    BUS: 'BUS',
    BUS1: 'BUS',
    BUSES: 'buses',
  },
  VIN: 'vin',
  CHASSIS: 'chassis_number',
  TANK: 'tank_capacity',
  ID: 'vehicle_id',
  STEER: 'Steer',
  DRIVE: 'Drive',
  VIN_CHASSIS: 'vin_chassis',
  TIRE: {
    STATUS: {
      IN_USE: 'In use',
      IN_STORAGE: 'In storage',
    },
  },
};

export const ROUTES = {
  TELEMATICS_PAGE_ROUTE: '/telemetric-data/provider-integration',
  COMPLETE_SIGN_UP_BASE: '/complete-signup',
  TIRE_INVENTORY: '/tire-inventory',
  FLEET_PAGE: '/fleets-data',
};

export const NOTIFICATIONS = {
  success: 'success',
  error: 'error',
};

export const filterLabels = {
  isRegrooved: 'Regrooved',
  last_tread_depth: 'Last tread depth',
  size: 'Size',
  product_line: 'Product line',
  isRetread: 'Retreaded',
  brand: 'Brand',
  mount_date: 'Mount date',
  estimated_tread_depth: 'Estimated tread depth',
  mission_profile: 'Mission profile',
  estimated_wear_out_date: 'Estimated wear out date',
};

export const fleetKpis = {
  avgSpeed: 'average_speed',
  avgFuelConsumption: 'average_fuel_consumption',
  missionProfile: 'mission_profile',
  totalDistanceDriven: 'total_distance_driven',
  totalFuelBurnt: 'total_fuel_burnt',
  totalShareMotorway: 'total_share_motorway',
  totalShareRegional: 'total_share_regional',
  totalShareUrban: 'total_share_urban',
  timestamp: 'timestamp',
};

export const common = {
  ourRecommendation: 'ourRecommendation',
  customize: 'customize',
};

export const EDIT = 'edit_tire';
export const SERVICE_MAIL_ID = 'app-events@co2opt.com';

export const event_types = {
  UPDATE_MILEAGE: 'UPDATE_M',
  UPDATE_DEPTH: 'UPDATE_TD',
  UPDATE_PRESSURE: 'UPDATE_P',
  REGROOVE: 'REGROOVE',
  UNMOUNT: 'UNMOUNT_REPAIR',
};

export const TIRE_INVENTORY = {
  UPDATE: {
    UPDATE: 'UPDATE',
    ADD: 'ADD',
    DELETE: 'DELETE',
    MOVED_TO_STORAGE: 'MOVED_TO_STORAGE',
  },
};

export const NA = 'N/A';

export function toProperCase(str) {
  return str.toLowerCase().replace(/(?:^|\s)\w/g, function (match) {
    return match.toUpperCase();
  });
}

export const TELEMATICS_INFO = localStorage.getItem('telematics_info');
export const USER_LS = localStorage.getItem('user');
export const TRACKED_LS = localStorage.getItem('tracked');
export const TRAILER_HEADERS = [
  'name',
  'chassis_number',
  'fleet_name',
  'reg_plate',
  'fleet_location',
  'internal_grouping',
  // 'axle_type',
  // 'tire_size',
];
export const TRUCK_AND_BUSES_HEADERS = [
  'name',
  'vin',
  'fleet_name',
  'reg_plate',
  'tank_capacity',
  'fleet_location',
  'internal_grouping',
  // 'axle_type',
  // 'steer_tire_size',
  // 'drive_tire_size',
];

export const TIRE_STATUS = {
  UNMOUNT_REPAIR: 'Recycling bank',
  UNMOUNT_STORE: 'Recycling bank',
  UNMOUNT_WORN: 'Recycling bank',
  UNMOUNT_SMASH: 'Recycling bank',
  UNMOUNT_PUNCTURE: 'Recycling bank',
  UNMOUNT_WARRANTY: 'Recycling bank',
  MOUNT: 'In use',
  RETREAD: 'Retreading',
  REGROOVE: 'In storage',
};

export const tireColorCode = {
  IN_USE: 'unset',
  STORAGE: 'primary',
  RECYCLING_BANK: 'primary',
  RETREAD: 'common',
  REGROOVE: 'primary',
  GRAVEYARD: 'secondary',
};

export const tireStorageLocation = {
  IN_USE: 'IN_USE',
  STORAGE: 'STORAGE',
  RECYCLING_BANK: 'RECYCLING_BANK',
  RETREAD: 'RETREAD',
  REGROOVE: 'REGROOVE',
  GRAVEYARD: 'GRAVEYARD',
};

export const tireOperationsOptions = {
  UPDATE: 1,
  CHANGE_TIRE: 2,
  TO_STORAGE: 3,
  NEW_TIRE: 4,
};

export const storageStatus = {
  STORAGE: 'stored',
  REFURBISH: 'refurbished',
  RETREAD: 'retreaded',
  REGROOVE: 'regrooved',
};

export const tireStatuses = {
  STORAGE: 'New',
  REFURBISH: 'Refurbished',
  RETREAD: 'Retreaded',
  REGROOVE: 'Regrooved',
  REPAIR_BANK: 'Repair',
  WORN_BANK: 'Worn',
  SMASH_BANK: 'Smashed',
  PUNCTURE_BANK: 'Punctured',
  WARRANTY_BANK: 'Warranty',
  STORE_BANK: 'Store',
  STORE: 'Storage',
  RETREADING: 'Retreading',
  IN_USE: 'In use',
  REPAIR: 'Repairing',
};

export const inventoryTabs = {
  RETREAD: 'RETREAD',
  RECYCLING_BANK: 'RECYCLING_BANK',
  REPAIR: 'REPAIR',
};

export const TIRE_UPLOAD_HEADERS = [
  'tire_id (optional)',
  'size',
  'brand',
  'product_line',
  'tread_depth',
  'axle_position',
  'vehicle_name',
  'mount_date',
];

// STATE
import {
  co2OptBaseApi,
  dropzoneApi,
  tireServiceMapApi,
  appTelemetryApi,
} from '../../axios.config';
const INITIAL_STATE = {
  user: null,
  workforce: null,
  status: null,
  accessToken: null,
  profileStatus: null,
  modalState: false,
  modalStatus: null,
  isLoading: false,
  truckData: null,
  tireProviderData: null,
  databaseName: null,
  activeStep: 0,
  isTour: false,
  isTestUser: false,
  organizationProfile: null,
  assumedFuelCosts: null,
  average_fuel_consumption: null,
  isGlobalComponentTourComplete: false,
  retreadStrategy: null,
  pageLevelTour: {
    home: false,
    telematics: false,
    fleet: false,
    settings: false,
    savingPotentials: false,
    fleetsDataVehiclePage: false,
  },
  isInitial: false,
  isMaintenance: false,
  availableTasks: null,
  tableauData: null,
  locale: 'en',
  initialData: null,
  guideSelection: null,
};
// ACTIONS
export const UserActionTypes = {
  LOGIN_USER: 'LOGIN_USER',
  SIGNUP_USER: 'SIGNUP_USER',

  SIGN_OUT_USER: 'SIGN_OUT_USER',

  VERIFY_USER: 'VERIFY_USER',
  VERIFY_INVITED_USER: 'VERIFY_INVITED_USER',
  FORGOT_PASSWORD_USER: 'FORGOT_PASSWORD_USER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  COMPLETE_SIGNUP: 'COMPLETE_SIGNUP',

  SET_USER: 'SET_USER',
  SET_STATUS: 'SET_STATUS',
  SET_TOKEN: 'SET_TOKEN',
  SET_DATABASE: 'SET_DATABASE',
  SET_WORKFORCE: 'SET_WORKFORCE',

  MODAL_STATE: 'MODAL_STATE',
  MODAL_STATUS: 'MODAL_STATUS',

  SET_TRUCK_DATA: 'SET_TRUCK_DATA',
  SET_TIRE_PROVIDER_DATA: 'SET_TIRE_PROVIDER_DATA',

  SET_ACTIVE_STEP: 'SET_ACTIVE_STEP',

  SET_TOUR: 'SET_TOUR',
  SET_ORGANIZATION_DATA: 'SET_ORGANIZATION_DATA',
  SET_TEST_USER: 'SET_TEST_USER',
  SET_ASSUMED_FUEL_COSTS: 'SET_ASSUMED_FUEL_COSTS',
  SET_AVERAGE_FUEL_CONSUMPTION: 'SET_AVERAGE_FUEL_CONSUMPTION',
  SET_PAGE_LEVEL_TOUR: 'SET_PAGE_LEVEL_TOUR',
  SET_INITIAL_PAGE_LEVEL_TOUR: 'SET_INITIAL_PAGE_LEVEL_TOUR',
  SET_IS_INITIAL: 'SET_IS_INITIAL',
  SET_PROFILE_STATUS: 'SET_PROFILE_STATUS',
  FETCH_WORKFORCE_DATA: 'FETCH_WORKFORCE_DATA',
  SET_GLOBAL_LEVEL_TOUR: 'SET_GLOBAL_LEVEL_TOUR',
  SET_RETREAD_STRATEGY: 'SET_RETREAD_STRATEGY',
  DELETE_WORKFORCE_DATA: 'DELETE_WORKFORCE_DATA',
  EDIT_WORKFORCE_DATA_QUERY: 'EDIT_WORKFORCE_DATA_QUERY',
  DELETE_WORKFORCE_DATA_QUERY: 'DELETE_WORKFORCE_DATA_QUERY',
  SET_MAINTENANCE: 'SET_MAINTENANCE',

  SET_TASKS: 'SET_TASKS',
  SET_FLEETS_WITH_TABLEAU: 'SET_FLEETS_WITH_TABLEAU',

  SET_LOCALE: 'SET_LOCALE',
  UPDATE_TASKS: 'UPDATE_TASKS',
  FETCH_NOTIFICATION_PREFERENCES: 'FETCH_NOTIFICATION_PREFERENCES',
  SET_NOTIFICATION_PREFERENCES: 'SET_NOTIFICATION_PREFERENCES',
  UPDATE_NOTIFICATION_PREFERENCES: 'UPDATE_NOTIFICATION_PREFERENCES',
  SET_EMAIL_SUBSCRIPTION_DETAILS: 'SET_EMAIL_SUBSCRIPTION_DETAILS',
  SET_GUIDE_SELECTION: 'SET_GUIDE_SELECTION',
};
// ACTION CREATOR
export const loginUser = data => ({
  type: UserActionTypes.LOGIN_USER,
  payload: { data },
});
export const signUpUser = data => ({
  type: UserActionTypes.SIGNUP_USER,
  payload: { data },
});
export const signOutUser = () => ({ type: UserActionTypes.SIGN_OUT_USER });

export const forgotPasswordUser = email => ({
  type: UserActionTypes.FORGOT_PASSWORD_USER,
  payload: { email },
});
export const verifyUser = token => ({
  type: UserActionTypes.VERIFY_USER,
  payload: { token },
});
export const completeSignup = (password, locale, token) => ({
  type: UserActionTypes.COMPLETE_SIGNUP,
  payload: { password, locale, token },
});

export const workforceQuery = workforce => ({
  type: UserActionTypes.SET_WORKFORCE,
  payload: { workforce },
});

export const fetchWorkforceQuery = () => ({
  type: UserActionTypes.FETCH_WORKFORCE_DATA,
});

export const deleteWorkforceQuery = payload => ({
  type: UserActionTypes.DELETE_WORKFORCE_DATA_QUERY,
  payload,
});
export const editWorkForceQuery = payload => ({
  type: UserActionTypes.EDIT_WORKFORCE_DATA_QUERY,
  payload,
});
export const verifyInvitedUser = token => ({
  type: UserActionTypes.VERIFY_INVITED_USER,
  payload: { token },
});
export const resetPasswordUser = (userToken, newPassword) => ({
  type: UserActionTypes.RESET_PASSWORD,
  payload: { userToken, newPassword },
});
export const setUser = (user, login) => ({
  type: UserActionTypes.SET_USER,
  payload: { user, login },
});
export const setToken = token => ({
  type: UserActionTypes.SET_TOKEN,
  payload: { token },
});
export const setModalState = value => ({
  type: UserActionTypes.MODAL_STATE,
  payload: { value },
});
export const setModalStatus = value => ({
  type: UserActionTypes.MODAL_STATUS,
  payload: { value },
});

export const setTruckData = value => ({
  type: UserActionTypes.SET_TRUCK_DATA,
  payload: { value },
});

export const setTireProviderData = value => ({
  type: UserActionTypes.SET_TIRE_PROVIDER_DATA,
  payload: { value },
});
export const setDatabaseData = value => ({
  type: UserActionTypes.SET_DATABASE,
  payload: { value },
});
export const setActiveStep = value => ({
  type: UserActionTypes.SET_ACTIVE_STEP,
  payload: { value },
});
export const setTour = value => ({
  type: UserActionTypes.SET_TOUR,
  payload: { value },
});

export const setPageLevelTour = value => ({
  type: UserActionTypes.SET_PAGE_LEVEL_TOUR,
  payload: { value },
});
export const setInitialPageLevelTour = value => ({
  type: UserActionTypes.SET_INITIAL_PAGE_LEVEL_TOUR,
  payload: { value },
});

export const setOrganizationProfile = value => ({
  type: UserActionTypes.SET_ORGANIZATION_DATA,
  payload: value,
});

export const setTestUser = value => ({
  type: UserActionTypes.SET_TEST_USER,
  payload: value,
});

export const setFuelCosts = value => ({
  type: UserActionTypes.SET_ASSUMED_FUEL_COSTS,
  payload: { value },
});

export const setAvgConsumption = value => ({
  type: UserActionTypes.SET_AVERAGE_FUEL_CONSUMPTION,
  payload: { value },
});

export const setAppStatus = value => ({
  type: UserActionTypes.SET_IS_INITIAL,
  payload: { value },
});

export const setProfileStatusGlobally = data => ({
  type: UserActionTypes.SET_PROFILE_STATUS,
  payload: { data },
});
export const setGlobalLevelTour = value => ({
  type: UserActionTypes.SET_GLOBAL_LEVEL_TOUR,
  payload: { value },
});
export const setRetreadStrategy = value => ({
  type: UserActionTypes.SET_RETREAD_STRATEGY,
  payload: { value },
});

export const setMaintenance = value => ({
  type: UserActionTypes.SET_MAINTENANCE,
  payload: { value },
});

export const setAvailableTasks = value => ({
  type: UserActionTypes.SET_TASKS,
  payload: {
    value,
  },
});
export const setLocale = value => ({
  type: UserActionTypes.SET_LOCALE,
  payload: { value },
});
export const updateTasks = fleets => ({
  type: UserActionTypes.UPDATE_TASKS,
  payload: { fleets },
});
export const fetchUserNotificationPreferences = () => ({
  type: UserActionTypes.FETCH_NOTIFICATION_PREFERENCES,
});

export const setUserNotificationPreferences = data => ({
  type: UserActionTypes.SET_NOTIFICATION_PREFERENCES,
  payload: data,
});

export const updateNotificationPreferences = data => ({
  type: UserActionTypes.UPDATE_NOTIFICATION_PREFERENCES,
  payload: { data },
});

export const setEmailSubscriptions = data => ({
  type: UserActionTypes.SET_EMAIL_SUBSCRIPTION_DETAILS,
  payload: data,
});

export const updateGuideSelection = value => ({
  type: UserActionTypes.SET_GUIDE_SELECTION,
  payload: { value },
});

export const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_USER:
      return {
        ...state,
        user: action.payload.user,
        status: action.payload.status,
      };
    case UserActionTypes.SET_WORKFORCE:
      return {
        ...state,
        workforce: action.payload.workforce,
      };
    case UserActionTypes.SET_STATUS:
      return {
        ...state,
        status: action.payload.status,
      };
    case UserActionTypes.SET_TOKEN:
      const token = action.payload.token;
      co2OptBaseApi.interceptors.request.use(
        config => {
          if (token) config.headers['Authorization'] = `Bearer ${token}`;
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
      dropzoneApi.interceptors.request.use(
        config => {
          if (token) config.headers['Authorization'] = `Bearer ${token}`;
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );

      tireServiceMapApi.interceptors.request.use(
        config => {
          if (token) config.headers['Authorization'] = `Bearer ${token}`;
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );

      appTelemetryApi.interceptors.request.use(
        config => {
          if (token) config.headers['Authorization'] = `Bearer ${token}`;
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
      return {
        ...state,
        accessToken: token,
      };
    case UserActionTypes.MODAL_STATE:
      return {
        ...state,
        modalState: action.payload.value,
      };
    case UserActionTypes.MODAL_STATUS:
      return {
        ...state,
        modalStatus: action.payload.value,
      };
    case UserActionTypes.SET_TRUCK_DATA:
      return {
        ...state,
        truckData: action.payload.value,
      };
    case UserActionTypes.SET_TIRE_PROVIDER_DATA:
      return {
        ...state,
        tireProviderData: action.payload.value,
      };
    case UserActionTypes.SET_DATABASE:
      return {
        ...state,
        databaseName: action.payload.value,
      };
    case UserActionTypes.SET_ACTIVE_STEP:
      return {
        ...state,
        activeStep: action.payload.value,
      };
    case UserActionTypes.SET_TOUR:
      let _state = { ...state };
      if (!action.payload.value) _state.isGlobalComponentTourComplete = true;
      return {
        ..._state,
        isTour: action.payload.value,
      };
    case UserActionTypes.SET_ORGANIZATION_DATA:
      return {
        ...state,
        organizationProfile: action.payload,
      };
    case UserActionTypes.SET_TEST_USER:
      return {
        ...state,
        isTestUser: action.payload,
      };
    case UserActionTypes.SET_ASSUMED_FUEL_COSTS:
      return {
        ...state,
        fuelCosts: action.payload.value,
      };
    case UserActionTypes.SET_AVERAGE_FUEL_CONSUMPTION:
      return {
        ...state,
        consumption: action.payload.value,
      };

    case UserActionTypes.SET_PAGE_LEVEL_TOUR:
      return {
        ...state,
        pageLevelTour: {
          ...state.pageLevelTour,
          [action.payload.value]: true,
        },
      };
    case UserActionTypes.SET_INITIAL_PAGE_LEVEL_TOUR:
      return {
        ...state,
        pageLevelTour: {
          ...state.pageLevelTour,
          ...action.payload.value,
        },
      };
    case UserActionTypes.SET_IS_INITIAL:
      return {
        ...state,
        isInitial: action.payload.value,
      };

    case UserActionTypes.SET_PROFILE_STATUS:
      return {
        ...state,
        profileStatus: action.payload.data,
      };
    case UserActionTypes.SET_GLOBAL_LEVEL_TOUR:
      return {
        ...state,
        isGlobalComponentTourComplete: true,
      };
    case UserActionTypes.SET_RETREAD_STRATEGY:
      return {
        ...state,
        retreadStrategy: action.payload.value,
      };
    case UserActionTypes.DELETE_WORKFORCE_DATA:
      return {
        ...state,
        workforce: {
          ...state.workforce,
          workforceData: state.workforce.workforceData.filter(
            _data => _data.email !== action.payload.email
          ),
        },
      };

    case UserActionTypes.SET_MAINTENANCE:
      return {
        ...state,
        isMaintenance: action.payload.value,
      };
    case UserActionTypes.SET_TASKS:
      return {
        ...state,
        availableTasks: action.payload.value,
      };
    case UserActionTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.payload.value,
      };
    case UserActionTypes.SET_FLEETS_WITH_TABLEAU:
      return { ...state, tableauData: action.payload };
    case UserActionTypes.SET_NOTIFICATION_PREFERENCES:
      return { ...state, initialData: action.payload };
    case UserActionTypes.SET_EMAIL_SUBSCRIPTION_DETAILS:
      return { ...state, emailSubscriptionDetails: action.payload };
    case UserActionTypes.SET_GUIDE_SELECTION:
      return { ...state, guideSelection: action.payload.value };
    default:
      return state;
  }
};
// SELECTORS
export const setUserSelector = state => state.users.user;
export const workforceSelector = state => state.users.workforce;
export const setStatusSelector = state => state.users.status;
export const setAccessTokenSelector = state => state.users.accessToken;
export const setModalStateSelector = state => state.users.modalState;
export const setModalStatusSelector = state => state.users.modalStatus;
export const setTruckDataSelector = state => state.users.truckData;
export const setTireProviderSelector = state => state.users.tireProviderData;
export const setDatabaseDataSelector = state => state.users.databaseName;
export const setActiveStepSelector = state => state.users.activeStep;
export const setTourSelector = state => state.users.isTour;
export const setIsGlobalComponentTourSelector = state =>
  state.users.isGlobalComponentTourComplete;

export const setPageLevelTourSelector = state => state.users.pageLevelTour;
export const setTestUserSelector = state => state.users.isTestUser;
export const setOrganizationProfileSelector = state =>
  state.users.organizationProfile;
export const setFuelConsumptionSelector = state => state.users.consumption;
export const setFuelCostsSelector = state => state.users.fuelCosts;
export const appStatusSelector = state => state.users.isInitial;
export const profileStatusSelector = state => state.users.profileStatus;
export const retreadStrategySelector = state => state.users.retreadStrategy;
export const setMaintenanceSelector = state => state.users.isMaintenance;
export const setTaskSelector = state => state.users.availableTasks;
export const tableauDataSelector = state => state.users.tableauData;

export const setLocaleSelector = state => state.users.locale;
export const notificationPreferencesSelector = state => state.users.initialData;
export const getEmailSubscriptionDetails = state =>
  state.users.emailSubscriptionDetails;

import React from 'react';
import { makeStyles } from 'tss-react/mui';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import { AspectRatio } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { Box, Paper, Stack, styled } from '@mui/material';

const useStyles = makeStyles()(theme => {
  return {
    imageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      // justifyContent: 'space-bet',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: '#fff',
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    img: {
      width: '100%',
      minWidth: '360px',
      maxHeight: '200px',
      objectFit: 'contain',
    },
  };
});

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const itemData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));
export default function ImageViewer({
  data,
  onClick,
  srcKey,
  imgStyle,
  view = 'grid',
}) {
  const { classes } = useStyles();

  function gridView() {
    return (
      view === 'grid' && (
        <ImageList
          className={classes.imageList}
          cols={3}
          data-hook='image-list'
        >
          {data.map((item, i) => (
            <ImageListItem key={i} data-hook={`images-${i}`}>
              <img
                src={item[srcKey]}
                alt={item.caption}
                style={imgStyle}
                className={classes.img}
              />
              <ImageListItemBar
                title={item.caption}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
                actionIcon={
                  <IconButton
                    aria-label={`info about ${item.title}`}
                    className={classes.icon}
                  >
                    <AspectRatio onClick={() => onClick(i, item)} />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      )
    );
  }

  function listView() {
    return (
      view === 'list' && (
        <Stack
          spacing={1}
          sx={{ width: '100%', height: '500px', overflow: 'auto' }}
        >
          {data.map((item, i) => (
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Item>
                {item.caption}{' '}
                <IconButton>
                  <AspectRatio onClick={() => onClick(i, item)} />
                </IconButton>
              </Item>
            </Box>
          ))}
        </Stack>
      )
    );
  }

  return (
    <div className={classes.imageContainer}>
      {listView()}
      {gridView()}
    </div>
  );
}
ImageViewer.propTypes = {
  data: PropTypes.array,
  onClick: PropTypes.func,
  srcKey: PropTypes.any,
  imgStyle: PropTypes.any,
};

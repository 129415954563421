import React from 'react';
import { Box, Typography, CircularProgress, Paper, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { VEHICLE } from 'utils/string_utils';

import steer_logo from 'assets/svg/home/rec_steer.svg';
import trailer_logo from 'assets/svg/home/rec_trailer.svg';
import rec_plus from 'assets/svg/home/rec_plus.svg';

import { useTranslation } from 'react-i18next';
import { formattedValueWithAbs } from './CustomisableTireRecommendations';
import PropTypes from 'prop-types';

const useStyles = makeStyles()(theme => {
  return {
    container: {
      width: '500px',
      minHeight: '250px',
      borderRadius: theme.spacing(1),
      borderColor: theme.palette.primary.main,
      border: '2px solid #69A628',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    },
    buyButton: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: '-40px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  };
});

const truckTypes = [
  VEHICLE.vehicle_type.TRUCKS,
  VEHICLE.vehicle_type.TRUCK,
  VEHICLE.vehicle_type.BUSES,
  VEHICLE.vehicle_type.BUS,
];

const OurRecommendationCard = ({ data, vehicleType, isLoading, error }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <Paper
        style={{
          width: '100%',
          padding: '16px 20px',
          boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
        }}
      >
        <Box
          display={'flex'}
          justifyContent='center'
          alignItems={'center'}
          width='100%'
          height='100%'
        >
          <CircularProgress />
        </Box>
      </Paper>
    );
  }

  if (error?.isError && error?.errorMessage) {
    return (
      <Paper
        style={{
          width: '100%',
          padding: '16px 20px',
          boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
        }}
      >
        <Box
          display={'flex'}
          justifyContent='center'
          alignItems={'center'}
          width='100%'
          height='100%'
        >
          <Typography variant='h6' align='center' gutterBottom color='error'>
            {error.errorMessage.charAt(0) +
              error.errorMessage.slice(1).toLowerCase()}
          </Typography>
        </Box>
      </Paper>
    );
  }

  const renderFormatterdMatrixBox = formattedValue => {
    if (formattedValue) {
      return (
        <Box className={classes.metricBoxStyle}>
          <Typography variant='h6'>
            {formattedValueWithAbs(formattedValue)}
          </Typography>
        </Box>
      );
    }
  };

  const renderVehicleEstimates = (isPresent, value) => {
    if (isPresent) {
      return (
        <Box className={classes.boxstyle}>
          <Typography variant='h6'>{value}</Typography>
        </Box>
      );
    }
  };

  const renderVehicles = () => {
    if (truckTypes.includes(vehicleType)) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box
              style={{
                display: 'flex',
                width: '100%',
                padding: '12px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
                border: '1px solid var(--default-gray-200, #E5E7EB)',
                background: ' var(--Light-grey, #F6F9F7)',
              }}
            >
              <Box mx='0.5rem'>
                <img src={steer_logo} alt='steer' />
              </Box>
              <Typography variant='h6' fontWeight='bold' gutterBottom>
                <span style={{ color: '#6B7280' }}>
                  {t('tireRecommendation.header.steerBrand')}
                </span>
                {data?.steer_brand} {data?.steer_product}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              style={{
                display: 'flex',
                width: '100%',
                padding: '12px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={rec_plus} alt='plus' />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              style={{
                display: 'flex',
                width: '100%',
                padding: '12px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
                border: '1px solid var(--default-gray-200, #E5E7EB)',
                background: ' var(--Light-grey, #F6F9F7)',
              }}
            >
              <Box mx='0.5rem'>
                <img src={trailer_logo} alt='trailer' />
              </Box>
              <Typography variant='h6' fontWeight='bold' gutterBottom>
                <span style={{ color: '#6B7280' }}>
                  {t('tireRecommendation.header.driveBrand')}{' '}
                </span>
                {data?.drive_brand} {data?.drive_product}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box
            style={{
              display: 'flex',
              width: '100%',
              padding: '12px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '12px',
              border: '1px solid var(--default-gray-200, #E5E7EB)',
              background: ' var(--Light-grey, #F6F9F7)',
            }}
          >
            <Box mx='0.5rem'>
              <img src={trailer_logo} alt='trailer' />
            </Box>
            <Typography variant='h6' fontWeight='bold' gutterBottom>
              <span style={{ color: '#6B7280' }}>
                {t('tireRecommendation.header.trailerBrand')}{' '}
              </span>
              {data?.trailer_brand} {data?.trailer_product}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper
      style={{
        width: '100%',
        // height: '100%',
        padding: '16px 20px',
        boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
      }}
    >
      <>
        <Box>
          <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
            {t('common.ourRecommendation')}
          </Typography>
        </Box>
        {renderVehicles()}
        <Box
          style={{
            display: 'flex',
            width: '100%',
            padding: '12px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '12px',
          }}
          my='1rem'
        >
          {renderVehicleEstimates(
            data?.roi_in_months,
            t('tireRecommendation.header.amortization')
          )}
          {renderVehicleEstimates(
            data?.estimated_savings_potential_tco_lifetime,
            t('tireRecommendation.header.savingsInTco')
          )}
          {renderVehicleEstimates(
            data?.estimated_km_price_tires,
            t('tireRecommendation.header.estimatedKmPrice')
          )}
          {renderVehicleEstimates(
            data?.theoretical_mileage,
            t('tireRecommendation.header.theoreticalMileage')
          )}
          {renderVehicleEstimates(
            data?.avg_monthly_distance,
            t('tireRecommendation.header.monthlyDistance')
          )}
          {renderVehicleEstimates(
            data?.fuel_save_l_per_100km,
            t('tireRecommendation.header.fuelSaveLperKM')
          )}
          {renderVehicleEstimates(
            data?.estimated_price_all_tires,
            t('tireRecommendation.header.estimatedPriceAllTires')
          )}
        </Box>
        <Box
          style={{
            display: 'flex',
            width: '100%',
            padding: '12px',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: '12px',
            border: '1px solid var(--default-gray-200, #E5E7EB)',
            background: ' var(--Light-grey, #F6F9F7)',
          }}
          my='1rem'
        >
          {data?.roi_in_months && (
            <Box className={classes.metricBoxStyle}>
              <Typography variant='h6' align='center'>
                {formattedValueWithAbs(data?.roi_in_months)}
              </Typography>
            </Box>
          )}
          {renderFormatterdMatrixBox(
            data?.estimated_savings_potential_tco_lifetime
          )}
          {renderFormatterdMatrixBox(data?.estimated_km_price_tires)}
          {renderFormatterdMatrixBox(data?.theoretical_mileage)}
          {renderFormatterdMatrixBox(data?.avg_monthly_distance)}
          {renderFormatterdMatrixBox(data?.fuel_save_l_per_100km)}
          {renderFormatterdMatrixBox(data?.estimated_price_all_tires)}
        </Box>
      </>
    </Paper>
  );
};
// eslint-disable-next-line

export default OurRecommendationCard;
OurRecommendationCard.propTypes = {
  data: PropTypes.any,
  columns: PropTypes.any,
  vehicleType: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.object,
};

import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import { fleetOverviewSelector } from '../../../../redux/fleets/ducks';
import { useSelector } from 'react-redux';
import useSelectFleet from '../../../../hooks/useSelectFleet';
import {
  assignAccessLevel,
  getVehiclesForFleet,
} from '../../../../services/fleet_service';
import { setAccessTokenSelector } from '../../../../redux/users/ducks';
import { fetchFleets } from '../../../../redux/fleets/ducks';
import { setUser } from '@sentry/react';
import { useDispatch } from 'react-redux';
import { setNotification } from '../../../../redux/notifications/ducks';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles()(() => {
  return {
    closeBtn: {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer',
    },
    dropDown: {
      width: '100%',
      marginBottom: '25px',
    },
    dropdownLabel: {
      fontSize: '24px',
      marginTop: '25px',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    grid: {
      marginTop: 10,
    },
    input: {
      backgroundColor: '#fff',
    },
    accordion: {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      marginBottom: 10,
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      height: '80vh',
    },
    accordionDetailsContainer: {
      maxHeight: 175,
      overflowY: 'scroll',
    },
    info: {
      fontWeight: 700,
      fontStyle: 'italic',
    },
    common: {
      overflowY: 'scroll',
    },
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: '1px solid #D1D5DB',
      borderRadius: 8,
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      background: 'white',
    },
    selectAndSearchDiv: {
      display: 'flex',
      justifyContent: 'center',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      flexDirection: 'column',
      backgroundColor: 'white',
    },
    paper: {
      width: '80%',
      height: 'auto',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      padding: '2rem 0rem',
    },
  };
});

export const AssignResourcesModal = props => {
  const { classes } = useStyles();
  const {
    open,
    setOpen,
    users = [],
    bulkUpload,
    userName = '',
    userId = '',
  } = props;
  const [selectedUsers, setUserIds] = useState([]);
  const [selectedFleets, setFleetIds] = useState([]);
  const { t } = useTranslation();
  const fleets = useSelector(fleetOverviewSelector)?.fleets;

  const { fetchFleets: getFleets } = useSelectFleet();
  const access_token = useSelector(setAccessTokenSelector);
  const [fleetVehicles, setFleetVehicles] = useState({});
  const [selectedVehicles, setSelectedVehicles] = useState({});
  const [newPayload, setNewPayload] = useState({});
  const dispatch = useDispatch();
  const [staticSelectedVehicles, setStaticSelectedCehicles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isDisabled =
    isEqual(staticSelectedVehicles, selectedVehicles) || isSubmitting;

  useEffect(() => {
    async function getVehicles() {
      try {
        if (selectedFleets.length) {
          const response = await getVehiclesForFleet(
            {
              fleet_id: selectedFleets[selectedFleets.length - 1].fleet_id,
            },
            access_token
          );

          let vehiclesWithFleets = { ...fleetVehicles };
          let selectedFleetVehicles = { ...selectedVehicles };

          const fleetId = selectedFleets[selectedFleets.length - 1].fleet_id;

          if (!vehiclesWithFleets.hasOwnProperty(fleetId)) {
            response.data.resp.vehicles.forEach(vehicle => {
              if (!vehiclesWithFleets[fleetId]) {
                vehiclesWithFleets[fleetId] = [vehicle];
              } else {
                vehiclesWithFleets[fleetId] = [
                  ...vehiclesWithFleets[fleetId],
                  vehicle,
                ];
              }

              if (!selectedFleetVehicles[fleetId]) {
                selectedFleetVehicles[fleetId] = [false];
              } else {
                selectedFleetVehicles[fleetId] = [
                  ...selectedFleetVehicles[fleetId],
                  false,
                ];
              }
            });
          } else {
            selectedFleetVehicles = Object.keys(selectedFleetVehicles)
              .filter(objKey => objKey !== `${fleetId}`)
              .reduce((newObj, key) => {
                newObj[key] = selectedFleetVehicles[key];
                return newObj;
              }, {});

            vehiclesWithFleets = Object.keys(vehiclesWithFleets)
              .filter(objKey => objKey !== `${fleetId}`)
              .reduce((newObj, key) => {
                newObj[key] = vehiclesWithFleets[key];
                return newObj;
              }, {});
          }

          setSelectedVehicles(selectedFleetVehicles);
          setStaticSelectedCehicles(selectedFleetVehicles);
          setFleetVehicles(vehiclesWithFleets);
        }
      } catch (error) {
        console.error(error);
      }
    }
    // eslint-disable-next-line
    getVehicles();
    // eslint-disable-next-line
  }, [selectedFleets, access_token]);

  useEffect(() => {
    let obj = { ...newPayload };
    selectedFleets.forEach(({ fleet_id }) => {
      if (!obj.hasOwnProperty(`${fleet_id}`)) {
        obj = {
          ...obj,
          [fleet_id]: {
            assign_to_all: false,
            vehicles: {
              include: [],
              exclude: [],
            },
          },
        };
        setNewPayload(obj);
      }
    });
    // eslint-disable-next-line
  }, [selectedFleets]);

  const getSelectedVehicles = (fleet_id, check, partiallyCheck = false) => {
    if (selectedVehicles[fleet_id]) {
      if (partiallyCheck) {
        return selectedVehicles[fleet_id].includes(false);
      }
      return selectedVehicles[fleet_id].every(vehicle => vehicle === check);
    }
  };

  const handleChange = (event, fleet_id, type, index) => {
    let selectedVehiclesCopy = JSON.parse(JSON.stringify(selectedVehicles));
    selectedVehiclesCopy[fleet_id][index] = event.target.checked;
    let payload = JSON.parse(JSON.stringify(newPayload));
    let vType = 'vehicles';

    if (payload[fleet_id].assign_to_all) {
      if (!event.target.checked) {
        payload[fleet_id][vType]['exclude'] = [
          ...payload[fleet_id][vType]['exclude'],
          event.target.value,
        ];
      } else {
        payload[fleet_id][vType]['exclude'] = payload[fleet_id][vType][
          'exclude'
        ].filter(val => val !== event.target.value);
      }
    } else {
      if (event.target.checked) {
        payload[fleet_id][vType]['include'] = [
          ...payload[fleet_id][vType]['include'],
          event.target.value,
        ];
      } else {
        payload[fleet_id][vType]['include'] = payload[fleet_id][vType][
          'include'
        ].filter(val => val !== event.target.value);
      }
    }

    setNewPayload(payload);
    setSelectedVehicles(selectedVehiclesCopy);
  };

  const handleSelectAll = (event, fleet_id) => {
    let payload = JSON.parse(JSON.stringify(newPayload));
    payload[fleet_id]['assign_to_all'] = event.target.checked;
    let selectedVehiclesCopy = JSON.parse(JSON.stringify(selectedVehicles));
    selectedVehiclesCopy[fleet_id] = Array(
      selectedVehiclesCopy[fleet_id].length
    ).fill(event.target.checked);

    payload[fleet_id]['vehicles']['include'] = [];
    payload[fleet_id]['vehicles']['exclude'] = [];

    setSelectedVehicles(selectedVehiclesCopy);
    setNewPayload(payload);
  };

  const handleFleets = (e, value) => {
    setFleetIds(value);
  };

  const handleUsers = (e, value) => {
    setUserIds(value);
  };

  const renderList = fleet => {
    if (fleetVehicles[fleet.fleet_id]) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            ml: 3,
            gap: 1,
            mt: 1,
          }}
        >
          {fleetVehicles[fleet.fleet_id] && // eslint-disable-next-line
            fleetVehicles[fleet.fleet_id].map((vehicle, i) => {
              return (
                <FormControlLabel
                  key={i}
                  label={`${vehicle.name}`}
                  data-hook={`select_${i}_vehicle`}
                  control={
                    <Checkbox
                      checked={selectedVehicles[fleet.fleet_id][i]}
                      value={vehicle.vehicle_id}
                      onChange={e =>
                        handleChange(e, fleet.fleet_id, vehicle.type, i)
                      }
                    />
                  }
                />
              );
            })}
        </Box>
      );
    }
  };

  const renderSelectedFLeets = () => {
    return (
      // eslint-disable-next-line
      selectedFleets?.map((fleet, i) => {
        if (fleetVehicles[fleet.fleet_id]) {
          return (
            <Accordion key={i} className={classes.accordion}>
              <AccordionSummary
                aria-controls='panel1a-content'
                id='panel1a-header'
                data-hook='select-vehicles-accordion'
              >
                <Typography variant='h6'>{fleet.fleet_name}</Typography>
              </AccordionSummary>
              <Box className={classes.accordionDetailsContainer}>
                <AccordionDetails className={classes.accordionDetails}>
                  <>
                    <div className={classes.selectAndSearchDiv}>
                      <div className={classes.searchBar}>
                        <IconButton aria-label='search'>
                          <Search color='primary' />
                        </IconButton>
                        <InputBase
                          placeholder='Search...'
                          fullWidth
                          inputProps={{ 'aria-label': 'search' }}
                        />
                      </div>
                    </div>
                  </>
                  <div>
                    <FormControlLabel
                      label={`Select All`}
                      control={
                        <Checkbox
                          checked={getSelectedVehicles(fleet.fleet_id, true)}
                          indeterminate={
                            getSelectedVehicles(fleet.fleet_id, false)
                              ? false
                              : getSelectedVehicles(fleet.fleet_id, false, true)
                          }
                          onChange={e => handleSelectAll(e, fleet.fleet_id)}
                        />
                      }
                    />
                    {renderList(fleet, fleetVehicles[fleet.fleet_id])}
                  </div>
                </AccordionDetails>
              </Box>
            </Accordion>
          );
        }
      })
    );
  };

  const renderVehicles = () => {
    if (selectedFleets.length) {
      return (
        <Grid item xs={4}>
          <Typography
            className={classes.dropdownLabel}
            data-hook='select-vehicles-label'
          >
            Select Vehicles
          </Typography>
          {renderSelectedFLeets()}
        </Grid>
      );
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const payloadObj = {
      user_id: bulkUpload ? selectedUsers.map(user => user.user_id) : [userId],
      fleets: newPayload,
    };
    try {
      let response;
      response = await assignAccessLevel(access_token, payloadObj);
      if (response.status === 200) {
        dispatch(
          setNotification(t('vehiclePage.assignSuccessMsg'), 'success', true)
        );
        setOpen(false);
        dispatch(fetchFleets());
        await getFleets(fleets, true);
      } else {
        dispatch(
          setNotification(t('vehiclePage.assignFailureMsg'), 'error', true)
        );
      }
    } catch (err) {
      console.error('error occoured', err);
    } finally {
      setOpen(false);
      setFleetIds([]);
      setUser([]);
      setIsSubmitting(false);
    }
  };

  const renderUsers = () => {
    if (bulkUpload) {
      return (
        <Grid item xs={4}>
          <Typography
            className={classes.dropdownLabel}
            data-hook='bulk-upload-select-user-label'
          >
            Select User
          </Typography>
          <Autocomplete
            multiple
            id='tags-outlined'
            options={users?.filter(user => user.iam_role !== 'superAdmin')}
            data-hook='select-user-dropdown'
            getOptionLabel={option => option.name}
            filterSelectedOptions
            onChange={handleUsers}
            noOptionsText={
              !users?.length ? t('assetsWhitelisting.noFleets') : 'No options'
            }
            renderInput={params => (
              <TextField
                {...params}
                style={{ backgroundColor: '#fff' }}
                variant='outlined'
                label='Users'
              />
            )}
          />
        </Grid>
      );
    }
  };

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      data-hook='bulk-upload-modal'
    >
      <Paper
        sx={{
          maxHeight: '80%',
          overflow: 'auto',
          width: '90%',
          backgroundColor: 'white',
          padding: '2rem',
          position: 'relative',
        }}
      >
        <Grid className={classes.grid} container spacing={3} width='100%'>
          <Grid item xs={12}>
            <Typography variant='h5' data-hook='bulk-upload-header'>
              {bulkUpload
                ? t('vehiclePage.bulkUpload')
                : `Assign Resources (${userName})`}
            </Typography>
          </Grid>
          <IconButton
            className={classes.closeBtn}
            onClick={() => {
              setOpen(false);
              setFleetIds([]);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid container spacing={3}>
          {renderUsers()}
          <Grid item xs={4}>
            <Typography
              className={classes.dropdownLabel}
              data-hook='bulk-upload-select-fleet-label'
            >
              {t('multiLayerReport.selectFleet')}
            </Typography>
            <Autocomplete
              multiple
              id='tags-outlined'
              options={fleets}
              getOptionLabel={option => option.fleet_name}
              filterSelectedOptions
              onChange={handleFleets}
              data-hook='select-fleets-dropdown'
              noOptionsText={
                !fleets?.length
                  ? t('assetsWhitelisting.noFleets')
                  : 'No options'
              }
              renderInput={params => (
                <TextField
                  {...params}
                  style={{ backgroundColor: '#fff' }}
                  variant='outlined'
                  label={t('common.fleets')}
                />
              )}
            />{' '}
          </Grid>
          {renderVehicles()}
        </Grid>
        <Grid
          item
          xs={12}
          mt={5}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          <Button
            variant='contained'
            color='primary'
            style={{
              alignSelf: 'flex-end',
              width: '100px',
            }}
            data-hook='bulk-assign-submit-button'
            disabled={isDisabled}
            onClick={handleSubmit}
          >
            {isSubmitting ? (
              <CircularProgress size={30} color='inherit' />
            ) : (
              t('common.submitBtn')
            )}
          </Button>
        </Grid>
      </Paper>
    </Modal>
  );
};

AssignResourcesModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  users: PropTypes.array,
  userName: PropTypes.string,
  userId: PropTypes.string,
  bulkUpload: PropTypes.bool,
};

import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';

import UserAccount from 'components/UserAccount';
import Loading from 'components/Loading';

import { setUserSelector } from 'redux/users/ducks';

const ProfileSettings = () => {
  const user = useSelector(setUserSelector);
  return (
    <Box mx='0.5rem'>
      <Grid container spacing={2}>
        {user !== null ? <UserAccount user={user} /> : <Loading />}
      </Grid>
    </Box>
  );
};

export default ProfileSettings;

// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';

import { OnboardingActionTypes } from './ducks';

import {
  getOrganizationProfile,
  updateBusConfig,
  updateOrganizationData,
  updateTireConfig,
  updateTrailerConfig,
  updateTruckConfig,
} from 'services/organization_service';
import { NotificationsActionTypes } from '../notifications/ducks';

function* getOrganizationProfileSaga({ access_token }) {
  const resp = yield call(getOrganizationProfile, access_token);
  yield put({
    type: OnboardingActionTypes.ORGANIZATION_PROFILE.SET,
    payload: resp.data.resp,
  });
}

function* updateOrganizationDataSaga({ access_token, payload }) {
  try {
    const resp = yield call(updateOrganizationData, access_token, payload);
    if (resp.status === 200) {
      yield put({
        type: OnboardingActionTypes.ORGANIZATION_PROFILE.SET_ORGANIZATION_DATA,
        payload: resp.data.resp,
      });
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Organization data updated successfully.',
          type: 'success',
          show: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'Something went wrong.',
        type: 'error',
        show: true,
      },
    });
  }
}

function* updateTrailerConfigSaga({ access_token, payload }) {
  try {
    const resp = yield call(updateTrailerConfig, access_token, payload);

    if (resp.status === 200) {
      yield put({
        type: OnboardingActionTypes.ORGANIZATION_PROFILE.SET_TRAILER_CONFIG,
        payload: resp.data.resp,
      });
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Trailer data updated successfully.',
          type: 'success',
          show: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'Something went wrong.',
        type: 'error',
        show: true,
      },
    });
  }
}

function* updateTruckConfigSaga({ access_token, payload }) {
  try {
    const resp = yield call(updateTruckConfig, access_token, payload);
    if (resp.status === 200) {
      yield put({
        type: OnboardingActionTypes.ORGANIZATION_PROFILE.SET_TRUCK_CONFIG,
        payload: resp.data.resp,
      });
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Truck data updated successfully.',
          type: 'success',
          show: true,
        },
      });
    }
  } catch (err) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'Something went wrong.',
        type: 'error',
        show: true,
      },
    });
  }
}
function* updateBusConfigSaga({ access_token, payload }) {
  try {
    const resp = yield call(updateBusConfig, access_token, payload);
    if (resp.status === 200) {
      yield put({
        type: OnboardingActionTypes.ORGANIZATION_PROFILE.SET_BUS_CONFIG,
        payload: resp.data.resp,
      });
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Bus data updated successfully.',
          type: 'success',
          show: true,
        },
      });
    }
  } catch (err) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'Something went wrong.',
        type: 'error',
        show: true,
      },
    });
  }
}
function* updateTireConfigSaga({ access_token, payload }) {
  try {
    const resp = yield call(updateTireConfig, access_token, payload);
    if (resp.status === 200) {
      yield put({
        type: OnboardingActionTypes.ORGANIZATION_PROFILE.SET_TIRE_CONFIG,
        payload: resp.data.resp,
      });
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Tire data updated successfully.',
          type: 'success',
          show: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'Something went wrong.',
        type: 'error',
        show: true,
      },
    });
  }
}

export default function* onboardingSaga() {
  yield takeEvery(
    OnboardingActionTypes.ORGANIZATION_PROFILE.GET,
    getOrganizationProfileSaga
  );
  yield takeEvery(
    OnboardingActionTypes.ORGANIZATION_PROFILE.UPDATE_ORGANIZATION_DATA,
    updateOrganizationDataSaga
  );
  yield takeEvery(
    OnboardingActionTypes.ORGANIZATION_PROFILE.UPDATE_TIRE_CONFIG,
    updateTireConfigSaga
  );
  yield takeEvery(
    OnboardingActionTypes.ORGANIZATION_PROFILE.UPDATE_TRAILER_CONFIG,
    updateTrailerConfigSaga
  );
  yield takeEvery(
    OnboardingActionTypes.ORGANIZATION_PROFILE.UPDATE_TRUCK_CONFIG,
    updateTruckConfigSaga
  );
  yield takeEvery(
    OnboardingActionTypes.ORGANIZATION_PROFILE.UPDATE_BUS_CONFIG,
    updateBusConfigSaga
  );
}

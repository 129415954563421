import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSVReader } from 'react-papaparse';

import { Button, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import { setNotification } from 'redux/notifications/ducks';
import { setUserSelector, setAccessTokenSelector } from 'redux/users/ducks';
import { fleetCsvUpload } from 'services/fleet_service';
import { useTranslation } from 'react-i18next';

import UploadSummary from './UploadSummary';
import * as XLSX from 'xlsx';
import * as trucks from 'assets/data/truckSample.json';
import * as trucksGer from 'assets/data/truckSampleGer.json';
import * as trailers from 'assets/data/trailersSample.json';
import * as trailersGer from 'assets/data/trailersSampleGer.json';
import { VEHICLE } from 'utils/string_utils';
import { useParams } from 'react-router-dom';
import { fetchVehiclesForFleets } from 'redux/fleets/ducks';
import PropTypes from 'prop-types';
import { BsDownload, BsUpload } from 'react-icons/bs';

const useStyles = makeStyles()(theme => {
  return {
    main: {
      display: 'flex',
      gap: 5,
      width: '100%',
      height: '100%',
      padding: '0px 2rem',
      marginTop: 32,
    },
    box: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.light,
      border: `1px dashed ${theme.palette.primary.main}`,
      borderRadius: 4,
      padding: '2rem 8rem',
      margin: '1.5rem 0px',
    },
    box2: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      gap: 24,
    },
    uploadBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
    },
    btn: {
      textTransform: 'unset',
    },
    downloadBtns: {
      // flex: '30%',
      borderRadius: 24,
      textTransform: 'unset',
    },
    browseBtn: {
      color: theme.palette.primary.main,
    },
    uploadBtn: {
      borderRadius: 24,
      textTransform: 'unset',
      width: '100%',
    },
  };
});

function formatDate(inputDate) {
  const parts = inputDate.split('/');

  if (parts.length > 1) {
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

    return formattedDate;
  } else return inputDate;
}

// active-without-fleet-data@co2opt.com
const CsvUploader = ({
  title,
  headers,
  asset_type,
  header1,
  header2,
  flex_direction = 'column',
  closeAddPopover,
  page = 0,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const user = useSelector(setUserSelector);
  const [progress, setProgress] = useState(0);
  const [rows, setData] = useState(null);
  const [tableRows, setTableRows] = useState(null);
  const params = useParams();
  const { fleet_id } = params;
  const { t, i18n } = useTranslation();
  const access_token = useSelector(setAccessTokenSelector);
  // const [vehicleType, setType] = useState(null);
  // eslint-disable-next-line
  const [error, setError] = useState([]);
  const [openSummary, setOpenSummary] = useState(false);
  const [fileType, setFileType] = useState('');
  const [fleetNamesExcel, setFleetNamesExcel] = useState([]);
  // const [fleetLocExcel, setFleetLocExcel] = useState([]);
  const [successCount, setSuccessCount] = useState(0);

  const keyMapping = {
    Name: 'name',
    Fahrgestellnummer:
      asset_type === VEHICLE.vehicle_type.TRAILERS ? 'chassis_number' : 'vin',
    Flottenname: 'fleet_name',
    Kennzeichen: 'reg_plate',
    Flottenstandort: 'fleet_location',
    'Interne Gruppierung': 'internal_grouping',
    Tankkapazität: 'tank_capacity',
    'TankkapazitÃ¤t': 'tank_capacity',
    'Kilometerstand (optional)': 'odometer_reading (optional)',
    'Inspektion (optional)': 'inspection_date (optional)',
    'Sicherheitscheck (optional)': 'safety_check_date (optional)',
    'Achsart (optional)': 'axle_type (optional)',
    'Vorderreifengröße (optional)': 'steer_tire_size (optional)',
    'VorderreifengrÃ¶ÃŸe (optional)': 'steer_tire_size (optional)',
    'Hinterreifengröße (optional)': 'drive_tire_size (optional)',
    'HinterreifengrÃ¶ÃŸe (optional)': 'drive_tire_size (optional)',
    'Reifengröße (optional)': 'tire_size (optional)',
    'ReifengrÃ¶ÃŸe (optional)': 'tire_size (optional)',
  };

  const vehicleType = { trucks: 'TRUCK', trailers: 'TRAILER', buses: 'BUS' };
  const sampleTrucks = i18n.language === 'de' ? trucksGer : trucks;
  const sampleTrailers = i18n.language === 'de' ? trailersGer : trailers;
  const [finalData, setFinalData] = useState([]);

  const handleOnDropExcel = async f => {
    const reader = new FileReader();
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(f);

    reader.onload = async e => {
      const bufferArray = e.target.result;

      const wb = XLSX.read(bufferArray, { type: 'buffer', cellDates: true });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, {
        raw: false,
        dateNF: 'yyyy-mm-dd',
      });
      let newData = data;
      if (!!data[0]?.Flottenname) {
        newData = data.map(item => {
          const newItem = {};
          for (const oldKey in item) {
            if (keyMapping.hasOwnProperty(oldKey)) {
              const newKey = keyMapping[oldKey];
              newItem[newKey] = item[oldKey];
            }
          }
          return newItem;
        });
      }
      const fileHeaders = Object.keys(newData[0]);
      const x = Object.keys(newData[0]);
      const headers_ = JSON.parse(JSON.stringify(headers));

      optionalHeaders2.forEach(header => {
        if (fileHeaders.includes(header) && !headers_.includes(header)) {
          headers_.push(header);
        }
      });

      if (x.sort().toString() === headers_.sort().toString()) {
        const hasAllRequiredKeys = newData?.every(obj =>
          headers?.every(key => obj.hasOwnProperty(key))
        );
        if (hasAllRequiredKeys) {
          setData(newData);
          const _tableRows = [];
          for (let i = 0; i < newData.length; i++) {
            const obj = { ...newData[i] };
            for (let i = 0; i < headers.length; i++) {
              if (headers[i] !== 'name' && headers[i] !== 'fleet_name') {
                delete obj[headers[i]];
              }
            }
            obj.upload_status = null;
            obj.upload_result = null;
            _tableRows.push(obj);
          }
          setTableRows(_tableRows);

          const temp = [];
          const temp_loc = [];

          newData.forEach(item => {
            temp.push(item.fleet_name);
            temp_loc.push(item.fleet_location);
          });
          const groupedByFleetName = newData.reduce((result, item) => {
            if (!Array.isArray(result)) {
              result = [];
            }
            let group = result?.find(
              group => group.fleet_name === item.fleet_name
            );

            if (!group) {
              group = {
                fleet_name: item.fleet_name.toString(),
                location: item.fleet_location.toString(),
                assets: [],
              };
              result.push(group);
            }
            const itemProperties = {};
            for (const [key, value] of Object.entries(item)) {
              itemProperties[key] = value;
            }

            group.assets.push(removeOptional(itemProperties));

            group.assets = group.assets.map(asset => {
              const newAsset = { ...asset };

              delete newAsset.fleet_location;
              delete newAsset.fleet_name;

              if (newAsset.name) {
                newAsset.name = newAsset.name.toString();
              }

              if (asset.vin || asset.chassis_number) {
                newAsset.vin_chassis = asset.vin || asset.chassis_number;
              }

              newAsset.vehicle_type = vehicleType[asset_type];

              delete newAsset.vin;
              delete newAsset.chassis_number;

              return newAsset;
            });

            return result;
          }, []);
          setFinalData(groupedByFleetName);
          const fleetNames = [...new Set(temp)];
          setFleetNamesExcel(fleetNames);

          // const fleetLocations = [...new Set(temp_loc)];
          // setFleetLocExcel(fleetLocations);
        } else {
          dispatch(
            setNotification(
              t('csvUploaderPage.missingDataError'),
              'error',
              true
            )
          );
        }
      } else {
        dispatch(
          setNotification('Headers Of File Not Compatible', 'error', true)
        );
      }
    };
    reader.readAsArrayBuffer(f);
  };

  const handleOnDrop = (data, file) => {
    if (file.type === 'text/csv') {
      let temp = data[0].data.slice(0);
      setFileType('csv');
      const includesKey = Object.keys(keyMapping).some(key =>
        temp.includes(key)
      );

      let newData = JSON.parse(JSON.stringify(data));
      if (data[data.length - 1].data.length <= 1) {
        newData.pop();
      }
      let filteredArray = newData.filter(
        entry => !entry.data.every(item => item === '')
      );

      const firstEntryModified = filteredArray[0].data?.map(
        entry => keyMapping[entry]
      );
      if (includesKey) {
        filteredArray[0].data = firstEntryModified;
      }

      const transformed = includesKey
        ? temp.map(item => keyMapping[item])
        : temp;

      const headers_ = headers;
      optionalHeaders2.forEach(header => {
        if (transformed.includes(header) && !headers_.includes(header)) {
          headers_.push(header);
        }
      });

      const limit =
        asset_type === VEHICLE.vehicle_type.TRUCKS ||
        asset_type === VEHICLE.vehicle_type.BUSES
          ? 7
          : 6;
      const usableData = filteredArray.slice(1, -1);
      const hasEmptyString = usableData.some(obj => {
        return obj.data.slice(0, limit).some(str => str === '');
      });
      const headerSet = new Set(transformed);
      let nonDuplicateHeaders = [...headerSet];

      if (
        nonDuplicateHeaders.sort().toString() === headers_.sort().toString()
      ) {
        if (hasEmptyString) {
          dispatch(
            setNotification(
              t('csvUploaderPage.missingDataError'),
              'error',
              true
            )
          );
        } else {
          setData(filteredArray);
          let arr = [];
          let fleet_index = filteredArray[0].data.indexOf(header1);

          const vehicle_name = filteredArray[0].data.indexOf('name');
          filteredArray = filteredArray.slice(1);
          for (let i = 0; i < filteredArray.length; i++) {
            if (!arr.includes(filteredArray[i].data[fleet_index])) {
              arr.push(filteredArray[i].data[fleet_index]);
            }
          }
          let temp2 = [];
          filteredArray = filteredArray.slice(0, -1);
          for (let i = 0; i < filteredArray.length; i++) {
            temp2[i] = {
              fleet_name: filteredArray[i].data[fleet_index],
              vehicle_name: filteredArray[i].data[vehicle_name],
              upload_status: null,
              upload_result: null,
            };
          }
          setTableRows(temp2);
        }
      } else {
        dispatch(
          setNotification('Headers Of File Not Compatible', 'error', true)
        );
      }
    } else if (
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    ) {
      setFileType('xlsx');
      handleOnDropExcel(file);
    } else {
      handleOnRemoveFile();
      dispatch(setNotification('Invalid File Type', 'error', true));
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.error('E10002', err);
  };

  const handleOnRemoveFile = data => {
    setData(null);
    setError([]);
  };

  const handleSubmit = async () => {
    if (user !== null) {
      setOpenSummary(true);
      if (fileType === 'csv') {
        integrateCSVData(rows);
      } else {
        integrateExcelData();
      }
    }
  };

  const handleDownload = fileType => {
    if (fileType === 'xlsx') {
      const worksheet = XLSX.utils.json_to_sheet(
        [
          asset_type === 'trucks' || asset_type === 'buses'
            ? sampleTrucks
            : sampleTrailers,
        ][0].default
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        `${asset_map?.type[asset_type]}_sample`
      );
      XLSX.writeFile(workbook, `${asset_map?.type[asset_type]}_sample.xlsx`);
    } else {
      let data = [];
      if (
        asset_type === VEHICLE.vehicle_type.TRUCKS ||
        asset_type === VEHICLE.vehicle_type.BUSES
      ) {
        data = sampleTrucks.default;
      } else {
        data = sampleTrailers.default;
      }

      try {
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        XLSX.writeFile(workbook, `${asset_map?.type[asset_type]}_sample.csv`, {
          bookType: 'csv',
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const integrateCSVData = async data => {
    let arr = [];
    let loc_arr = [];
    let fleet_index = data[0].data.indexOf(header1);

    let loc_index = data[0].data.indexOf(header2);
    let headers = data[0].data;
    data = data.slice(1);

    for (let i = 0; i < data.length; i++) {
      if (!arr.includes(data[i].data[fleet_index])) {
        arr.push(data[i].data[fleet_index]);
        loc_arr.push(data[i].data[loc_index]);
      }
    }

    let count = 100 / arr.length;
    let error_arr = [];
    let fleet_temp = [];
    let summary = asset_map?.summary[asset_type];
    for (let i = 0; i < arr.length; i++) {
      let payload_arr = [];
      for (let j = 0; j < data.length; j++) {
        if (data[j].data[fleet_index] === arr[i]) {
          let obj = Object.create({});
          for (let k = 0; k < headers.length; k++) {
            const headersProper = headers[k].split(' ')[0];
            if (k !== fleet_index && k !== loc_index) {
              obj[headersProper] = data[j].data[k];
            }
          }
          const filteredObject = Object.fromEntries(
            Object.entries(obj).filter(([key, value]) => value !== '')
          );
          payload_arr.push(filteredObject);
        }
      }

      const reworkedPayloadArr = payload_arr.map(item => {
        if (item?.inspection_date) {
          item.inspection_date = formatDate(item.inspection_date);
        }
        if (item?.safety_check_date) {
          item.safety_check_date = formatDate(item.safety_check_date);
        }
        item.vin_chassis = item?.vin ? item.vin : item.chassis_number;
        item.vehicle_type = vehicleType[asset_type];

        delete item.vin;
        delete item.chassis_number;
        return item;
      });
      let payload = {
        fleet_name: arr[i],
        location: loc_arr[i],
        assets: reworkedPayloadArr,
      };

      try {
        fleet_temp = await postData(
          payload,
          i,
          count,
          fleet_temp,
          summary,
          error_arr
        );
        // closeAddPopover();

        fleet_temp && setTableRows(fleet_temp);
        setError(error_arr);
      } catch (error) {
        console.error('E20079', error);
      }
    }
  };

  const removeOptional = obj => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      acc[key.replace('(optional)', '').trim()] = value;
      return acc;
    }, {});
  };

  const integrateExcelData = async () => {
    let count = 100 / fleetNamesExcel.length;
    let error_arr = [];
    let fleet_temp = [];
    let summary = asset_map?.summary[asset_type];
    for (let i = 0; i < finalData.length; i++) {
      try {
        fleet_temp = await postData(
          finalData[i],
          i,
          count,
          fleet_temp,
          summary,
          error_arr
        );
        fleet_temp && setTableRows(fleet_temp);
        setError(error_arr);
      } catch (error) {
        console.error('E20080', error);
      }
    }
  };

  async function postData(p, i, count, fleet_temp, summary, error_arr) {
    try {
      const res = await fleetCsvUpload(p, access_token);
      let temp = (i + 1) * count;
      if (res.status === 200) {
        // const asset = asset_map?.type[asset_type];
        const summary_ = res?.data?.resp?.vehicle_summary;
        summary_.forEach(item => {
          if (!item['upload_result'].includes('Failed')) {
            setSuccessCount(successCount + 1);
          }
        });
        // let temp = (i + 1) * count;
        dispatch(
          fetchVehiclesForFleets(access_token, fleet_id, page, 10, () => {})
        );
        setProgress(temp);
        fleet_temp = fleet_temp.concat(res.data.resp.vehicle_summary);
      } else if (res.status === 400) {
        let x = res.data.return_message.toString();
        const y = x.split('d. ')[1];
        error_arr.push(y);
      } else if (res.status === 422) {
        let x = res.data.return_message?.toString();
        const y = x.split('d. ')[1];
        error_arr.push(y);
      } else if (res.status === 500) {
        dispatch(setNotification('An error has occurred', 'error', true));
        return;
      } else {
        dispatch(setNotification(res.data?.return_message, 'error', true));
      }
    } catch (err) {
      console.error('E20028', err);
      dispatch(setNotification('An error has occurred', 'error', true));
    }

    return fleet_temp;
  }

  const renderUploadSummary = () => {
    if (rows) {
      return (
        <UploadSummary
          open={openSummary}
          setOpen={setOpenSummary}
          rows={tableRows}
          progress={progress}
          handleOnRemoveFile={handleOnRemoveFile}
          successful={successCount}
          closeHandler={() => {
            setData(null);
            setError([]);
            setFinalData([]);
            setFleetNamesExcel([]);
          }}
        />
      );
    }
  };

  return (
    <Box flexDirection={flex_direction} className={classes.main}>
      <Box className={classes.box2}>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          height='166px'
          gap={'32px'}
          bgcolor='#F3F4F6'
          width='100%'
          padding={'24px 48px 24px 48px'}
        >
          <Typography variant='h6' data-hook='csv-uploader-title'>
            {t('csvUploaderPage.exampleFile')}
          </Typography>
          <Box display='flex' alignItems='center' gap={'16px'}>
            <Button
              variant='outlined'
              startIcon={<BsDownload />}
              color='primary'
              className={classes.downloadBtns}
              onClick={() => handleDownload('csv')}
              data-hook='download-csv-btn'
            >
              {t('csvUploaderPage.csv')}
            </Button>
            <Button
              variant='outlined'
              startIcon={<BsDownload />}
              color='primary'
              className={classes.downloadBtns}
              onClick={() => handleDownload('xlsx')}
              data-hook='download-xlxs-btn'
            >
              {t('csvUploaderPage.xlsx')}
            </Button>
          </Box>
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          height='100%'
          gap={'16px'}
          bgcolor='#F3F4F6'
          width='100%'
          padding={'30px 137px 30px 137px'}
        >
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'
            height={72}
            width={72}
            borderRadius={16}
            bgcolor='#FFF'
          >
            <BsUpload size={28} />
          </Box>
          <CSVReader
            onDrop={handleOnDrop}
            accept={{
              'text/csv': ['.csv'],
              'application/vnd.ms-excel': ['.xlx'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
            }}
            onError={handleOnError}
            addRemoveButton
            onRemoveFile={handleOnRemoveFile}
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              gap={'24px'}
            >
              <Box>
                {t('csvUploaderPage.drag')}
                <span className={classes.browseBtn}>
                  {t('csvUploaderPage.browse')}
                </span>
              </Box>
              <span>{t('csvUploaderPage.supportedFormats')}</span>
            </Box>
          </CSVReader>
          <Button
            variant='contained'
            color='primary'
            data-hook='csv-upload-btn'
            disableElevation
            disabled={rows === null ? true : false}
            className={classes.uploadBtn}
            onClick={handleSubmit}
          >
            {t('fleetsSection.upload')}
          </Button>
        </Box>
      </Box>
      {renderUploadSummary()}
    </Box>
  );
};

export default CsvUploader;

CsvUploader.propTypes = {
  title: PropTypes.string,
  headers: PropTypes.array,
  asset_type: PropTypes.string,
  header1: PropTypes.string,
  header2: PropTypes.string,
  flex_direction: PropTypes.string,
  closeAddPopover: PropTypes.func,
};

// const optionalHeaders = [
//   'odometer_reading',
//   'inspection_date',
//   'safety_check_date',
//   'axle_type',
//   'tire_size',
//   'steer_tire_size',
//   'drive_tire_size',
// ];
const optionalHeaders2 = [
  'odometer_reading (optional)',
  'inspection_date (optional)',
  'safety_check_date (optional)',
  'axle_type (optional)',
  'tire_size (optional)',
  'steer_tire_size (optional)',
  'drive_tire_size (optional)',
];

const asset_map = {
  title: {
    trucks: 'Truck/Van',
    trailers: 'Trailer',
    buses: 'Bus',
  },
  summary: {
    trucks: 'truck_summary',
    trailers: 'trailer_summary',
    buses: 'bus_summary',
  },
  type: {
    trucks: 'truck',
    trailers: 'trailer',
    buses: 'bus',
  },
};

import React from 'react';
import {
  Box,
  Button,
  Fab,
  Fade,
  IconButton,
  Modal,
  Paper,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import {
  inventoryChangeSelector,
  setChangeInInventory,
} from 'redux/tireInventory/ducks';
import { setAccessTokenSelector } from 'redux/users/ducks';
import { addTire, checkTire, fleetOverview } from 'services/fleet_service';
import { TIRES } from 'utils/string_utils';
import {
  setFleetOverview,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  unprocessedTireInventoryDataSelector,
} from 'redux/fleets/ducks';
import { fetchTireInventoryDataAndProcess } from 'pages/TireInventory/TireInventory';
import TireForm from 'components/TireForm';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      maxWidth: '1000px',
      width: 'auto',
      height: 'auto',
      border: 'none',

      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 3rem',
      position: 'relative',
    },
    next: {
      alignSelf: 'flex-end',
      textTransform: 'Capitalize',
      marginTop: 20,
    },
    backButton: {
      position: 'absolute',
      left: 5,
      top: '50%',
      transform: 'translate(0, -50%)',
    },
    closeIcon: {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer',
    },
  };
});

function generateRandomCharacters() {
  let generatedOutput = '';
  let storedCharacters =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let totalCharacterSize = storedCharacters.length;
  for (let index = 0; index < 5; index++) {
    generatedOutput += storedCharacters.charAt(
      Math.floor(Math.random() * totalCharacterSize)
    );
  }
  return generatedOutput;
}

const AddSingleTire = ({ open, setOpen, fleet, tireInventoryAction }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [tireBrand, setTireBrand] = useState(null);
  const [productLine, setProductLine] = useState(null);
  const currInventoryChangeValue = useSelector(inventoryChangeSelector);
  const [size, setSize] = useState(null);
  const [isAutoGenerated, setIsAutoGenerated] = useState(true);
  const [initialId, setInitialId] = useState('');
  const [checked, setChecked] = useState(false);

  const handleChangeSwitch = event => {
    setChecked(event.target.checked);
  };
  const [grooves, setGrooves] = useState({
    1: null,
  });
  const [error, setError] = useState({
    [TIRES.TIRE_ID]: null,
    [TIRES.TREAD_DEPTH]: null,
    [TIRES.PRESSURE]: null,
  });
  const [payload, setPayload] = useState({
    isRetread: false,
    last_tread_depth: '',
    tire_id: '',
    isRegrooved: false,
  });

  const [step, setStep] = useState(0);
  const [axleName, setAxleName] = useState(null);

  const access_token = useSelector(setAccessTokenSelector);
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);

  useEffect(() => {
    const getInitialId = async () => {
      let isUsed = true;
      try {
        while (isUsed) {
          const newId = generateRandomCharacters();
          const res_ = await checkTire(newId);
          if (res_.status === 200) {
            setInitialId(newId);
            isUsed = false;
          } else if (res_.status === 404) {
            isUsed = false;
          }
        }
      } catch (error) {
        console.error('E20049', error);
      }
    };
    if (!window.Cypress) {
      getInitialId();
    }
    // eslint-disable-next-line
  }, [access_token, currInventoryChangeValue]);

  const handleClose = () => {
    setOpen(false);
    setPayload({
      isRetread: false,
      last_tread_depth: '',
      tire_id: '',
      isRegrooved: false,
    });
    setSize(null);
    setProductLine(null);
    setTireBrand(null);
    setError({
      [TIRES.TIRE_ID]: null,
      [TIRES.TREAD_DEPTH]: null,
    });
    setIsAutoGenerated(true);
    generateRandomCharacters();
    setAxleName(null);
    setStep(0);
    setGrooves({
      1: null,
    });
    setChecked(false);
  };

  const handleChange = async e => {
    if (e.target.name === TIRES.TIRE_ID) {
      setTimeout(async () => {
        setIsAutoGenerated(false);
        if (e.target.value.length >= 5) {
          if (!window.Cypress) {
            try {
              const res = await checkTire(e.target.value);
              if (res.status === 400) {
                setError({
                  ...error,
                  [TIRES.TIRE_ID]: res?.data?.message,
                });
              } else if (e.target.value.indexOf(' ') !== -1) {
                setError({
                  ...error,
                  [TIRES.TIRE_ID]: t('common.tireInventory.noWhitespaceError'),
                });
              } else
                setError({
                  ...error,
                  [TIRES.TIRE_ID]: null,
                });
            } catch (error) {
              console.error('E20052', error);
            }
          } else {
            setError({
              ...error,
              [TIRES.TIRE_ID]: null,
            });
          }
        } else if (e.target.value.length < 5) {
          setError({
            ...error,
            [TIRES.TIRE_ID]: t('common.tireInventory.tooShortError'),
          });
        } else
          setError({
            ...error,
            [TIRES.TIRE_ID]: null,
          });
      }, 300);
    } else if (e.target.name === TIRES.TREAD_DEPTH) {
      if (parseFloat(e.target.value) > TIRES.MAX_DEPTH) {
        setError({
          ...error,
          [TIRES.TREAD_DEPTH]: t('common.tireInventory.maxDepthError'),
        });
      } else
        setError({
          ...error,
          [TIRES.TREAD_DEPTH]: null,
        });
    }

    if (e.target.name === TIRES.PRESSURE) {
      if (parseFloat(e.target.value) > 15 || parseFloat(e.target.value) < 5) {
        setError({
          ...error,
          [TIRES.PRESSURE]: t('common.pressureValueError'),
        });
      } else {
        setError({
          ...error,
          [TIRES.PRESSURE]: null,
        });
      }
    }

    if (e.target.name === TIRES.RETREAD || e.target.name === TIRES.REGROOVE) {
      setPayload({
        ...payload,
        [e.target.name]: e.target.checked,
      });
    } else {
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
      });
    }
  };

  const getWeekNumber = date => {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
    const startOfYear = new Date(tempDate.getFullYear(), 0, 1);
    return Math.ceil(((tempDate - startOfYear) / 86400000 + 1) / 7);
  };

  const dateConverter = date => {
    if (date) {
      const weekNumber = getWeekNumber(date);
      const year = date.getFullYear().toString().slice(-2);
      return `${weekNumber}/${year}`;
    }
  };

  const submitFormHandler = async e => {
    e.preventDefault();
    let payload_ = {
      fleet_id: fleet,
      tires: [
        {
          tire_id: isAutoGenerated ? initialId : payload.tire_id,
          brand: tireBrand,
          product_line: productLine,
          size: size,
          tread_depth: payload.last_tread_depth,
          isRetread: payload.isRetread,
          isRegrooved: payload.isRegrooved,
          ...(payload.tire_pressure !== '' && {
            pressure: payload.tire_pressure,
          }),
          grooves_tread_depth: checked ? grooves : {},
        },
      ],
    };

    if (payload.dot) {
      payload_ = {
        ...payload_,
        tires: [
          {
            ...payload_.tires[0],
            dot: dateConverter(payload.dot), // Add the dot key here
          },
        ],
      };
    }
    try {
      const res = await addTire(access_token, payload_);
      if (res.status === 200) {
        const summary_ = res?.data?.resp?.tire_summary;
        if (summary_.length && summary_[0].upload_status === 'fail') {
          dispatch(setNotification(summary_[0].upload_result, 'error', true));
        } else {
          dispatch(
            setNotification(
              t('common.tireInventory.tireUploadedMsg'),
              'success',
              true
            )
          );
          const data = await fetchTireInventoryDataAndProcess({
            fleet_id: payload_.fleet_id,
            inventoryData: fleetInventoryData,
            refetch: true,
          });
          dispatch(
            setUnprocessedTireInventoryData(data.res, payload_.fleet_id)
          );
          dispatch(setTireInventoryData(data, payload_.fleet_id));
          dispatch(setChangeInInventory(!currInventoryChangeValue));
          handleClose();
          const fleetResponse = await fleetOverview(access_token);
          dispatch(setFleetOverview(fleetResponse.data.resp));
        }
      } else {
        dispatch(
          setNotification(
            'An error has occurred. Please try again.',
            'error',
            true
          )
        );
      }
    } catch (error) {
      console.error('E20047', error);
    }
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          {step === 0 && (
            <Box display='flex' flexDirection='column'>
              <h2>{t('common.axleName')}</h2>
              <Box
                width='100%'
                display='flex'
                justifyContent='center'
                flexWrap='wrap'
                gap={'5px'}
                data-hook='axle-select-view'
              >
                {axle_Name.map((item, i) => (
                  <Button
                    key={i}
                    variant='contained'
                    color='primary'
                    disableElevation
                    style={{
                      textTransform: 'unset',
                      opacity: axleName !== item && axleName !== null ? 0.5 : 1,
                    }}
                    onClick={() => {
                      if (axleName === item) {
                        setAxleName(null);
                      } else {
                        setAxleName(item);
                      }
                    }}
                    disabled={
                      axleName !== item && axleName !== null ? true : false
                    }
                    data-hook={item}
                  >
                    {t(`common.axle_name.${item.toLowerCase()}`)}
                  </Button>
                ))}
              </Box>
              <Button
                className={classes.next}
                variant='outlined'
                color='primary'
                disabled={axleName === null}
                onClick={() => setStep(1)}
                data-hook='next-button'
              >
                {t('singleVehicleUpload.next')}
              </Button>
            </Box>
          )}
          {step === 1 && (
            <div data-hook='tire-form-modal'>
              <h2>{t('common.tireInventory.addTire')}</h2>
              <TireForm
                productLine={productLine}
                tireBrand={tireBrand}
                setTireBrand={setTireBrand}
                size={size}
                setSize={setSize}
                error={error}
                handleClose={handleClose}
                setProductLine={setProductLine}
                handleChange={handleChange}
                initialId={initialId}
                payload={payload}
                setPayload={setPayload}
                submitFormHandler={submitFormHandler}
                grooves={grooves}
                checked={checked}
                setGrooves={setGrooves}
                handleChangeSwitch={handleChangeSwitch}
                axleName={axleName}
                caller='inventory'
              />
              <Box
                className={classes.backButton}
                data-hook='back-btn'
                onClick={() => setStep(0)}
              >
                <Fab color='primary' aria-label='next' size='small'>
                  <ArrowBackIcon />
                </Fab>
              </Box>
            </div>
          )}
          <div
            className={classes.closeIcon}
            data-hook='close-modal-btn'
            onClick={handleClose}
          >
            <IconButton>
              <Close />
            </IconButton>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AddSingleTire;

const axle_Name = ['STEER', 'DRIVE', 'TRAILER', 'BUS'];

import { Box, Breadcrumbs, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { getTableauToken } from 'services/fleet_service';
import Loading from 'components/Loading';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fleetOverviewSelector } from 'redux/fleets/ducks';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { tableauDataSelector } from 'redux/users/ducks';
import NotFound from '../404/404';
import { useCallback } from 'react';

const useStyles = makeStyles()(theme => {
  return {
    nav: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
  };
});

const TableauPage = () => {
  const [token, setToken] = useState(null);
  const params = useParams();
  const { fleet_id, src } = params;
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const [fleet, setFleet] = useState();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const allTableauData = useSelector(tableauDataSelector);
  const [tableauSrc, setTableauSrc] = useState(null);
  const [endpointIsCalled, setEndpointIsCalled] = useState(false);

  const getToken = useCallback(async fleet_ => {
    try {
      const res = await getTableauToken();
      if (res.status === 200) {
        setToken(res.data.resp.token);
      }
    } catch (error) {
      console.error('E20109', error);
    } finally {
      setTimeout(() => {
        setEndpointIsCalled(true);
      }, 5000);
    }
  }, []);

  useEffect(() => {
    getToken();

    const interval = setInterval(() => {
      getToken();
    }, 9 * 60 * 1000);

    return () => clearInterval(interval);
  }, [getToken]);

  useEffect(() => {
    const foundFleet = fleets?.find(fleet => fleet.fleet_id === fleet_id);
    const tableauData = allTableauData?.filter(
      item => item.fleet_id === fleet_id
    );
    if (tableauData?.length) {
      setTableauSrc(tableauData[0].tableau_link);
    }
    if (foundFleet) {
      setFleet(foundFleet);
    }
  }, [fleet_id, fleets, allTableauData]);

  function tableauVisual() {
    return (
      <Box
        height={'90vh'}
        width={'75vw'}
        display='flex'
        justifyContent='center'
        alignItems='center'
        minWidth={'70vw'}
      >
        {tableauSrc && token ? (
          <tableau-viz
            id='tableauViz'
            src={tableauSrc}
            token={token}
            toolbar='bottom'
            width='100%'
            hide-tabs
          ></tableau-viz>
        ) : endpointIsCalled ? (
          <Box display='flex' alignItems={'center'}>
            <span>{t('tableauPage.errorMessage')}</span>
            <Button variant='text' onClick={() => getToken(fleet)}>
              {t('tableauPage.refresh')}
            </Button>
          </Box>
        ) : (
          <Loading />
        )}
      </Box>
    );
  }

  if (!src.includes('fleets-data') && !src.includes('tire-inventory')) {
    return <NotFound />;
  }

  if (!fleets) {
    return <Loading />;
  }
  const destination = src.includes('fleets-data')
    ? '/fleets-data/'
    : '/tire-inventory/';
  const firstBreadCrumb = src.includes('fleets-data')
    ? t('fleetsDataPage.fleet')
    : 'Tire Inventory';
  return (
    <>
      <Box className={classes.container}>
        <Breadcrumbs separator='>' aria-label='breadcrumb'>
          <Typography color='textPrimary' className={classes.vehicleName}>
            {firstBreadCrumb}
          </Typography>
          <NavLink
            color='inherit'
            variant='text'
            data-hook='fleet-name'
            to={destination + fleet_id}
            className={classes.nav}
          >
            {fleet?.fleet_name}
          </NavLink>
          <Typography color='textPrimary'>{'Tableau'}</Typography>
        </Breadcrumbs>
      </Box>
      {tableauVisual()}
    </>
  );
};

export default TableauPage;

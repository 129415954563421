import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsDownload, BsUpload } from 'react-icons/bs';
import { CSVReader } from 'react-papaparse';
import { parse } from 'json2csv';
import { TIRE_UPLOAD_HEADERS } from 'utils/string_utils';
import * as sample from 'assets/data/tireUploadSample.json';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import { addTire } from 'services/fleet_service';
import PropTypes from 'prop-types';
import { fleetOverviewSelector } from 'redux/fleets/ducks';
import { makeStyles, withStyles } from 'tss-react/mui';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';

const useStyles = makeStyles()(theme => {
  return {
    box2: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      gap: 24,
    },
    downloadBtns: {
      borderRadius: 24,
      textTransform: 'unset',
    },
    browseBtn: {
      color: theme.palette.primary.main,
    },
    uploadBtn: {
      borderRadius: 24,
      textTransform: 'unset',
      width: '100%',
    },
    heading: {
      marginBottom: '10px',
    },
  };
});

const StyledTableCell = withStyles(TableCell, (theme, props) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 18,
  },
  body: {
    fontSize: 22,
  },
}));

const CSV = 'csv';
const XLSX_ = 'xlsx';
const tire_id_key = 'tire_id (optional)';
const UploadTires = ({ fleetId }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const [uploadType, setUploadType] = useState('');
  const [data, setData] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [uploadSummary, setUploadSummary] = useState(null);
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const thisFleet = fleets.find(item => item.fleet_id === fleetId).fleet_name;

  const handleDownload = fileType => {
    if (fileType === 'xlsx') {
      const worksheet = XLSX.utils.json_to_sheet([sample][0].default);
      worksheet['!cols'] = new Array(7).fill({ wch: 16 });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, `Sheet`);
      XLSX.writeFile(workbook, `tire_upload_sample.xlsx`);
    } else {
      let fields = TIRE_UPLOAD_HEADERS;

      let data = sample.default;

      const opts = { fields };

      try {
        const csv = parse(data, opts);
        let blob = new Blob([csv], {
          type: 'text/csv',
        });
        let url = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.href = url;
        link.download = `tire_upload_sample.csv`;
        link.click();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const submitHandler = async () => {
    let payload = transformData(data);
    try {
      const res = await addTire('', payload);
      if (res.status === 200) {
        const uploadStatus = res?.data?.resp.tire_summary;
        setUploadSummary(uploadStatus);
        setIsFileUploaded(true);
      } else {
        dispatch(setNotification(res?.data?.value, 'error', true));
      }
    } catch (error) {
      console.error('E20110', error);
    }
  };

  const transformData = csvData => {
    let tireData = csvData;
    if (uploadType === CSV) {
      const columnTitles = csvData[0].data;
      tireData = csvData.slice(1).map(row => {
        const tireObj = {};
        row.data.forEach((value, index) => {
          tireObj[columnTitles[index].toLowerCase()] =
            columnTitles[index] === 'mount_date'
              ? moment(value).format('YYYY-MM-DD')
              : value;
        });
        tireObj.tire_id = tireObj[tire_id_key].toString();
        tireObj.vehicle_name = tireObj.vehicle_name.toString();
        delete tireObj[tire_id_key];
        return tireObj;
      });
    } else {
      tireData = csvData.map(entry => {
        entry.tire_id = entry[tire_id_key].toString();
        entry.mount_date = moment(entry.mount_date).format('YYYY-MM-DD');
        entry.vehicle_name = entry.vehicle_name.toString();
        delete entry[tire_id_key];

        return entry;
      });
    }

    const payload = { fleet_id: fleetId, tires: tireData };

    return payload;
  };

  const handleOnDropExcel = async f => {
    const reader = new FileReader();
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(f);

    reader.onload = async e => {
      const bufferArray = e.target.result;

      const wb = XLSX.read(bufferArray, { type: 'buffer' });

      const wsname = wb.SheetNames[0];

      const ws = wb.Sheets[wsname];

      const thisData = XLSX.utils.sheet_to_json(ws);
      let newData = thisData;

      newData = newData.map(entry => {
        if (!entry[tire_id_key]) {
          entry[tire_id_key] = generateRandomString(9);
        }
        return entry;
      });
      const fileHeaders = Object.keys(newData[0]);

      if (
        fileHeaders.sort().toString() === TIRE_UPLOAD_HEADERS.sort().toString()
      ) {
        const hasAllRequiredKeys = newData?.every(obj =>
          TIRE_UPLOAD_HEADERS.every(key => obj.hasOwnProperty(key))
        );
        if (hasAllRequiredKeys) {
          setData(newData);
        } else {
          dispatch(
            setNotification(
              t('csvUploaderPage.missingDataError'),
              'error',
              true
            )
          );
        }
      } else {
        dispatch(
          setNotification('Headers Of File Not Compatible', 'error', true)
        );
      }
    };
    reader.readAsArrayBuffer(f);
  };

  const generateRandomString = length => {
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleOnDrop = (data, file) => {
    if (file.type === 'text/csv') {
      let temp = data[0].data.slice(0);
      setUploadType(CSV);
      let newData = JSON.parse(JSON.stringify(data));
      if (data[data.length - 1].data.length <= 1) {
        newData.pop();
      }
      const tire_id_index = newData[0].data.indexOf(tire_id_key);

      for (const item of newData) {
        if (!item.data[tire_id_index]) {
          item.data[tire_id_index] = generateRandomString(9);
        }
      }

      let filteredArray = newData.filter(
        entry => !entry.data.every(item => item === '')
      );

      const hasEmptyString = filteredArray.some(obj => {
        return obj.data.some(str => str === '');
      });

      if (temp.sort().toString() === TIRE_UPLOAD_HEADERS.sort().toString()) {
        if (hasEmptyString) {
          dispatch(
            setNotification(
              t('csvUploaderPage.missingDataError'),
              'error',
              true
            )
          );
        } else {
          setData(filteredArray);
        }
      } else {
        dispatch(
          setNotification('Headers Of File Not Compatible', 'error', true)
        );
      }
    } else if (
      file.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      file.type === 'application/vnd.ms-excel'
    ) {
      setUploadType(XLSX_);
      handleOnDropExcel(file);
    } else {
      handleOnRemoveFile();
      dispatch(setNotification('Invalid File Type', 'error', true));
    }
  };

  const handleOnRemoveFile = data => {
    setData(null);
  };

  function uploadSummaryView() {
    return (
      isFileUploaded && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>Tire ID</StyledTableCell>
                <StyledTableCell>Upload Status</StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadSummary.map(row => (
                <TableRow key={row.tire_id}>
                  <StyledTableCell>{row.tire_id}</StyledTableCell>
                  <StyledTableCell>{row.upload_status}</StyledTableCell>
                  <StyledTableCell>{row.upload_result}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    );
  }

  function fileUploadView() {
    return (
      !isFileUploaded && (
        <Box className={classes.box2}>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            height='166px'
            gap={'32px'}
            bgcolor='#F3F4F6'
            width='100%'
            padding={'24px 48px 24px 48px'}
          >
            <Typography variant='h6'>
              {t('csvUploaderPage.exampleFile')}
            </Typography>
            <Box display='flex' alignItems='center' gap={'16px'}>
              <Button
                variant='outlined'
                startIcon={<BsDownload />}
                color='primary'
                className={classes.downloadBtns}
                onClick={() => handleDownload(CSV)}
                data-hook='download-csv-btn'
              >
                {t('csvUploaderPage.csv')}
              </Button>
              <Button
                variant='outlined'
                startIcon={<BsDownload />}
                color='primary'
                className={classes.downloadBtns}
                onClick={() => handleDownload(XLSX_)}
                data-hook='download-xlxs-btn'
              >
                {t('csvUploaderPage.xlsx')}
              </Button>
            </Box>
          </Box>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            height='100%'
            gap={'16px'}
            bgcolor='#F3F4F6'
            width='100%'
            padding={'30px 137px 30px 137px'}
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              height={72}
              width={72}
              borderRadius={16}
              bgcolor='#FFF'
            >
              <BsUpload size={28} />
            </Box>
            <CSVReader
              onDrop={handleOnDrop}
              accept={{
                'text/csv': ['.csv'],
                'application/vnd.ms-excel': ['.xlx'],
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  ['.xlsx'],
              }}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
            >
              <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                gap={'24px'}
                data-hook='upload-area'
              >
                <Box>
                  {t('csvUploaderPage.drag')}
                  <span className={classes.browseBtn}>
                    {t('csvUploaderPage.browse')}
                  </span>
                </Box>
                <span>{t('csvUploaderPage.supportedFormats')}</span>
              </Box>
            </CSVReader>
            <Button
              variant='contained'
              color='primary'
              data-hook='csv-upload-btn'
              disableElevation
              disabled={data === null ? true : false}
              className={classes.uploadBtn}
              onClick={submitHandler}
            >
              {t('fleetsSection.upload')}
            </Button>
          </Box>
        </Box>
      )
    );
  }

  return (
    <div style={{ overflow: 'auto' }}>
      <Typography variant='h5' className={classes.heading}>
        Fleet: {thisFleet}
      </Typography>
      {fileUploadView()}
      {uploadSummaryView()}
    </div>
  );
};

export default UploadTires;

UploadTires.propTypes = {
  fleetId: PropTypes.string,
};

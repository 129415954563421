import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import MotionWrap from 'wrapper/MotionWrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  fleetOverviewSelector,
  setFleetOverview,
  setOverviewDetails,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  unprocessedTireInventoryDataSelector,
} from 'redux/fleets/ducks';
import {
  fleetOverview,
  getAllFleetTireEventHistory,
  getTireInventoryData,
  getVehicleOverviewDetails,
} from 'services/fleet_service';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
import { ROUTES, USER_LS, USER_ROLES, tireStatuses } from 'utils/string_utils';
import { ReactComponent as Steering } from 'assets/svg/axles/steering2.svg';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useNavigate } from 'react-router-dom';
import UploadTiresModal from './UploadTiresModal';
import moment from 'moment';
import { IconBox } from 'hooks/useKpis';
import KpiTile from 'components/custom/KpiTile';
import {
  setAccessTokenSelector,
  setUserSelector,
  updateGuideSelection,
} from 'redux/users/ducks';
import { ReactComponent as Other } from 'assets/svg/axles/support.svg';

import i18next from 'i18next';
import { convertToEUR } from 'utils/helper';
import { makeStyles, withStyles } from 'tss-react/mui';
import Tour from 'reactour';
import TourContent from 'components/Tour/Content';

const useStyles = makeStyles()(theme => {
  return {
    cardContainer: {
      marginTop: theme.spacing(2),
      minHeight: 180,
      width: '100%',
      borderRadius: theme.spacing(2),
      // boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
      padding: theme.spacing(3),
      background: '#fff',
      position: 'relative',
      paddingBottom: theme.spacing(1),
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: '-40px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    fleetName: {
      position: 'absolute',
      top: '0px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      background: '#fff',
      // color: '#fff',
    },
    label: {
      color: '#6598e6',
    },
    value: { color: '#6c7178' },
    button: {
      borderRadius: theme.spacing(1),
    },
    container: {
      width: '30px',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 0,
    },
    spinner: {
      border: `4px solid #69A628`,
      borderTop: `4px solid rgba(255, 255, 255, 0.2)`,
      borderRadius: '50%',
      width: 'calc(100% - 8px)',
      height: 'calc(100% - 8px)',
      animation: '$spin 1s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
    kpiContainer: {
      display: 'flex',
      gridGap: 5,
    },
  };
});

export const fetchFleetTireEventHistory = async fleet_id => {
  const { data } = await getAllFleetTireEventHistory(fleet_id);
  let resp = data.resp;
  return resp;
};

export const getRemainingMonths = wearDate => {
  if (wearDate) {
    const estWearDate = wearDate.replace(/\./g, '-');
    const startDate = new Date();
    const [year, month, day] = estWearDate.split('-');
    const endDate = new Date(`${year}-${month}-${day}`);

    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();

    // this calculates the months remaining from present month
    const months = (endYear - startYear) * 12 + (endMonth - startMonth);

    const remainingMonths = months <= 0 ? 0 : months;

    return remainingMonths <= 24 ? remainingMonths : 24;
  }
};

export const fetchTireInventoryDataAndProcess = async ({
  fleet_id,
  inventoryData,
  refetch = false,
  location = 'ALL',
  isPaginated = false,
  pageNo = 0,
  perpage,
  isReports = false,
  filter_values,
  search_string,
}) => {
  let dataExists = true;
  let res = {};
  let per_page = perpage ? perpage : 10;
  if (inventoryData?.[fleet_id] && !refetch) {
    res = inventoryData[fleet_id];
  } else {
    dataExists = false;
    if (isReports === true) {
      res = await getTireInventoryData(fleet_id, location, pageNo, per_page);
    } else if (!isPaginated) {
      res = await getTireInventoryData(fleet_id, location);
    } else {
      res = await getTireInventoryData(fleet_id, location, pageNo, per_page, {
        filter_values,
        search_string,
      });
    }
  }
  if (res.status === 200) {
    let count = res?.data?.resp.count;
    let tires = res?.data?.resp.tires;
    if (count) {
      tires = JSON.parse(JSON.stringify(tires)).map(_item => {
        if (_item?.estimated_wear_out_date) {
          _item['remaining_months'] = getRemainingMonths(
            _item.estimated_wear_out_date
          );
        } else {
          _item['remaining_months'] = 'N/A';
        }
        if (_item?.estimated_tread_depth === null) {
          _item['estimated_tread_depth'] = 'N/A';
        }
        _item.tireStatus = tireStatuses[_item.status];
        return _item;
      });
      const avgValues = calcAvgValues(tires);
      return {
        originalData: tires,
        processedData: { avgValues },
        dataExists,
        res,
        count,
      };
    } else if (res?.data?.resp.length === 0) {
      return {
        originalData: [],
        processedData: {},
        dataExists,
        res,
        count: 0,
      };
    }
  } else {
    return {
      originalData: [],
      processedData: {},
      dataExists,
      res,
      count: 0,
    };
  }
};
export const calcAvgValues = data => {
  const avgInStorage = data?.filter(tire => !tire.vehicle_id);
  const avgInUse = data?.filter(tire => !!tire.vehicle_id);
  const averageValues = {
    treadDepths: {
      avgInStorage: calcAvgTreadDepth(avgInStorage),
      avgInUse: calcAvgTreadDepth(avgInUse),
    },
  };
  return averageValues;
};
export const calcAvgTreadDepth = data => {
  if (data?.length > 0) {
    let totalDepth = data.reduce((acc, obj) => {
      return acc + obj.last_tread_depth || 0;
    }, 0);

    const avg = (totalDepth / data.length).toFixed(2) + ' mm';
    return avg;
  }
  return 'N/A';
};

export function formatDate(date) {
  return date ? moment(date).format('YYYY.MM.DD') : '';
}
export function calculateDays(date) {
  return moment(Date.now()).diff(moment(date), 'days');
}
const TireInventory = () => {
  const userLs = JSON.parse(USER_LS);
  const { classes } = useStyles();
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const dispatch = useDispatch();
  const [openAddTireMOdal, setOpenAddTireModal] = useState(false);
  const [fleetId, setFleetId] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tireInventoryDetails = useSelector(state => state.fleet.overviewData);
  const userDetails = useSelector(setUserSelector) || userLs;
  const overviewDetails = useSelector(
    state => state.fleet.vehicleOverview?.tires
  );
  const userIsReporter = userDetails?.iam_role === USER_ROLES.reporter;
  const [tireDetails, setTireDetails] = useState(overviewDetails);
  const access_token = useSelector(setAccessTokenSelector);
  const rawTireInventoryData = useSelector(
    unprocessedTireInventoryDataSelector
  );

  const getDetails = useCallback(async () => {
    if (access_token) {
      try {
        const overviewDetails = await getVehicleOverviewDetails();
        dispatch(setOverviewDetails(overviewDetails));
        setTireDetails(overviewDetails.data.resources.vehicle_overview.tires);
      } catch (err) {
        throw new Error('error occurred');
      }
    }
  }, [access_token, dispatch, setTireDetails]);

  // useEffect(() => {
  //   if (fleets?.length) {
  //     getTires();
  //   }
  //   /*eslint-disable-next-line*/
  // }, [fleets]);
  // eslint-disable-next-line
  const getTires = async () => {
    try {
      await Promise.all(
        fleets.map(async ({ fleet_id }) => {
          const data = await fetchTireInventoryDataAndProcess(
            fleet_id,
            rawTireInventoryData
          );

          dispatch(setTireInventoryData(data, fleet_id));

          if (!data?.dataExists) {
            dispatch(setUnprocessedTireInventoryData(data?.res, fleet_id));
          }
        })
      );
    } catch (error) {
      console.error('E20045');
    }
  };
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setFlag(true), 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const updateTire = async () => {
      const fleetResponse = await fleetOverview(access_token);
      dispatch(setFleetOverview(fleetResponse.data.resp));
    };
    updateTire();
    if (!overviewDetails) {
      getDetails();
    }
  }, [tireDetails, access_token, overviewDetails, getDetails, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updateGuideSelection(null));
    };
  }, [dispatch]);
  const guideSelectionSelector = useSelector(
    state => state.users.guideSelection
  );
  const [showTour, setShowTour] = useState(
    guideSelectionSelector === 'addTires' ? true : false
  );
  const theme = useTheme();

  const styling = {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  };
  const steps = [
    {
      selector: '#add-tire',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t(`guidesPage.tour.addTire`)}
          finalAction='Done'
          goTo={() => setShowTour(false)}
          hideSkip={true}
        />
      ),
      style: styling,
    },
  ];

  const renderTourFromGuide = () => {
    if (flag) {
      return (
        <Tour
          startAt={0}
          steps={steps}
          isOpen={showTour}
          accentColor={theme.palette.primary.main}
          onRequestClose={() => setShowTour(false)}
          showNavigation={false}
          showButtons={false}
          showNNumber={false}
          badgeContent={() => ``}
        />
      );
    }
  };

  if (!fleets) {
    return <Loading />;
  }

  const renderAddTireModal = fleet => {
    if (!userIsReporter) {
      return (
        <Button
          color='primary'
          className={classes.button}
          size='small'
          startIcon={<PlaylistAddIcon />}
          onClick={() => {
            setFleetId(fleet.fleet_id);
            setOpenAddTireModal(true);
          }}
          data-hook='add-tire'
          id='add-tire'
        >
          {t('tireInventoryItems.addTires')}
        </Button>
      );
    }
  };

  const getTireMetrics = fleet_id => {
    const metrics = tireInventoryDetails.metrics;
    return metrics[fleet_id];
  };

  const getConvertedValue = fleet => {
    if (i18next.language === 'de') {
      return `${convertToEUR(
        parseFloat(getTireMetrics(fleet.fleet_id)?.avg_td_storage)
      )} mm`;
    }
    return getTireMetrics(fleet.fleet_id)?.avg_td_storage;
  };

  const renderFleetsList = () => {
    return fleets?.map((fleet, _index) => (
      <Grid item xs={12} sm={6} md={6} key={_index}>
        <Box className={classes.cardContainer} data-hook={`fleets-${_index}`}>
          <RowContent
            label={t('fleetsSection.fleet')}
            data-hook='fleet-name'
            value={fleet.fleet_name}
          />
          <RowContent
            label={t('tireInventoryItems.totalTires')}
            data-hook='total-tires'
            value={
              getTireMetrics(fleet.fleet_id)?.total_tires
                ? `${getTireMetrics(fleet.fleet_id)?.total_tires}`
                : '0'
            }
          />
          <RowContent
            label={t('tireInventoryItems.avgTreadDepthPerTireStore')}
            data-hook='avg-tread-depth'
            value={
              getTireMetrics(fleet.fleet_id)?.in_use
                ? getConvertedValue(fleet)
                : '0'
            }
          />
          <RowContent
            label={t('tireInventoryItems.avgTreadDepthPerTireInUse')}
            data-hook='avg-tread-depth-per-tire-in-use'
            value={
              getTireMetrics(fleet.fleet_id)?.avg_td_in_use
                ? `${getTireMetrics(fleet.fleet_id)?.avg_td_in_use} mm`
                : 'N/A'
            }
          />
          <Box display='flex' justifyContent={'space-between'} mt={2}>
            {renderAddTireModal(fleet)}
            <Button
              color='primary'
              size='small'
              className={classes.button}
              onClick={() =>
                navigate(`${ROUTES.TIRE_INVENTORY}/${fleet.fleet_id}`)
              }
              startIcon={<ViewListIcon />}
              data-hook={`view-tires-${_index}`}
            >
              {t('tireInventoryItems.viewAllTires')}
            </Button>
          </Box>
        </Box>
      </Grid>
    ));
  };

  const renderUploadTyreModal = () => {
    if (openAddTireMOdal) {
      return (
        <UploadTiresModal
          open={openAddTireMOdal}
          setOpen={setOpenAddTireModal}
          fleet={fleetId}
          tireInventoryAction={true}
          data-hook='upload-modal'
        />
      );
    }
  };

  const iconConfig = {
    opacity: 0.6,
  };

  const axleTypeTires = [
    {
      key: 'steer-tires',
      value: tireDetails?.steer_inUse ? tireDetails?.steer_inUse : '0',
      text: t('common.tireInventory.steerTires'),
      icon: (
        <IconBox>
          <Steering {...iconConfig} />
        </IconBox>
      ),
    },
    {
      key: 'drive-tires',
      value: tireDetails?.drive_inUse ? tireDetails?.drive_inUse : '0',
      text: t('common.tireInventory.driveTires'),
      icon: (
        <IconBox>
          <Other {...iconConfig} />
        </IconBox>
      ),
    },
    {
      key: 'trailer-tires',
      value: tireDetails?.trailer_inUse ? tireDetails?.trailer_inUse : '0',
      text: t('common.tireInventory.trailerTires'),
      icon: (
        <IconBox>
          <Other {...iconConfig} />
        </IconBox>
      ),
    },
  ];

  const tiresCount =
    tireDetails?.steer_inUse +
    tireDetails?.drive_inUse +
    tireDetails?.trailer_inUse;

  const renderTiresList = () => {
    if (tiresCount > 0) {
      return axleTypeTires.map((kpi, i) => (
        <Grid key={kpi.key} item xs={12} sm={4} md={4}>
          <CustomBox className={classes.kpiContainer}>
            <KpiTile
              data-hook={`kpi-tile-${i}`}
              icon={kpi.icon}
              primaryLabel={kpi.text}
              value={kpi.value}
              labelColor='#6598e6'
              showTooltip={false}
            />
          </CustomBox>
        </Grid>
      ));
    }
  };

  return (
    <Box width='100%'>
      <Grid container spacing={4} style={{ marginBottom: 15 }}>
        {renderTiresList()}
      </Grid>
      <Grid container spacing={4}>
        {renderFleetsList()}
      </Grid>
      {renderUploadTyreModal()}
      {renderTourFromGuide()}
    </Box>
  );
};

export const RowContent = ({ label, value }) => {
  const { classes } = useStyles();
  return (
    <Box display='flex' justifyContent={'space-between'}>
      <Typography variant='h6' className={classes.label}>
        {label}
      </Typography>
      <Box whiteSpace={'noWrap'}>
        {' '}
        <Typography variant='h6' className={classes.value}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default MotionWrap(
  TireInventory,
  { opacity: 0, scaleY: 0 },
  { opacity: 1, scaleY: 1 },
  { opacity: 0, scaleY: 0 }
);

const CustomBox = withStyles(Box, {
  root: {
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
});

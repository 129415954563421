// Rules

import { TIRES } from 'utils/string_utils';

// Always use [`] for wrapping string value
// Think of a common strings which can be reuse and put those inside common object
export const en = {
  common: {
    cautionMsg:
      'Caution : Adjusting tire pressures here impacts all vehicles based on their axle configurations.',
    updateCautionMsg:
      'Caution : all the mounted tires present in this vehicle will also be moved to a new fleet',
    updateOpt: 'Update Optimum Pressure',
    Unclassified: 'Unclassified',
    rows: 'Rows',
    assignResources: `Assign Resources`,
    optPressure: 'optimum pressure',
    noTiresAdded: 'You have not added any tires yet. Manage tires on ',
    bulkUpload: 'Bulk Assignment',
    assignSuccesMsg: 'Assign Resources Successfully',
    assignFailureMsg: 'Something Went Wrong',
    noVehiclesAdded:
      'You have not added any vehicles yet. Manage vehicles on  ',
    inventory: {
      TireInventory: 'Tire Inventory',
    },
    showing: 'showing',
    of: 'of',
    results: 'results',
    mounted: 'Mounted',
    Unmounted: 'Unmounted',
    on: 'on',
    notelematicsIntegrated:
      'You have not integrated telematics yet. Integrate telematics on',
    mergedTrucksTrailers: 'Merged Trucks and trailers',
    tireOverview: 'Tire overview',
    vehicleOverview: 'Vehicle Overview',
    inRange: 'in range',
    managedTires: 'Managed Tires',
    totalTiresInUse: `Tires in Use`,
    ourRecommendation: 'Our recommendation',
    customize: 'Customize',
    pleaseApplyMoreFilter: 'Please apply more filters!',
    and: 'and',
    imprint: 'Imprint',
    tnc: 'Terms&Conditions',
    here: 'here',
    continue: `Continue`,
    continueWithTestAccount: 'Click here to access test account',
    createAccount: `Create Account`,
    savingPotential: `Saving Potential`,
    noCsv: `No .csv file found`,
    uploadInstruction: `Drop .csv file here or click to upload`,
    uploadBtn: `Upload`,
    integrationInfo: `Telematics`,
    fleetSettings: 'Fleet Settings',
    personalSettings: 'Personal Settings',
    profileSettings: 'Profile Settings',
    notificationSettings: 'Notifications',
    userMgt: `User Management`,
    tireMgt: `Tire management`,
    truck: 'Truck',
    trailer: 'Trailer',
    bus: 'Bus',
    retread: 'Retread strategy',
    goPremium: `To use this feature, Go Premium`,
    premiumBtn: 'Buy premium',
    perYear: 'per Year',
    trucks: 'Trucks / Vans',
    per100Km: 'per 100 Km',
    perLitre: 'per Liter',
    seeMore: 'See More',
    co2Emission: 'CO2 Emissions Reduced',
    savingsAchieved: 'Savings Achieved',
    dataIntegration: 'Data Integration',
    noOfTyres: 'Number of Tires',
    noOfVehicles: 'Number of Vehicles',
    axelTypes: 'Axle Types',
    termsAndCondition: 'Terms&Conditions',
    privacyPolicy: 'Privacy policy',
    allRightsReserved: 'All Rights Reserved',
    configured: 'Configured',
    notConfigured: 'Not Configured',
    notConnected: 'Not Connected',
    partialConfig: 'Partially Configured',
    yes: `Yes`,
    no: `No`,
    submitBtn: `Submit`,
    noVehicleFound: 'No vehicle found',
    showMore: 'Show More',
    showLess: 'Show Less',
    sessionExpired: `Your session has expired. Please login again`,
    back: `Back`,
    error: `An error occurred. Please try again`,
    noDataFound: 'No Data Found',
    apply: `Apply`,
    confirm: `Confirm`,
    cancel: `Cancel`,
    next: `Next`,
    skip: `Skip`,
    configure: `Configure`,
    download: 'Download',
    mount: `Mount`,
    unmount: `Unmount`,
    approve: 'Approve',
    fleets: `Fleets`,
    wait: `Please wait . . .`,
    recentActivity: 'Recent Activities',
    tire: 'Tire',
    na: 'N/A',
    eg: 'Eg: ',
    axleName: 'Axle Name',
    vehicles: 'Vehicles',
    no_vehicles: 'No Vehicles Data',
    change_availablity: 'Change Availablity',
    change_availablity_success: 'Updated Successfully',
    change_availablity_failed: 'Updated Failed , Try again',
    telemtics_support:
      'Please contact your CO2OPT contact person to add telematics data',
    frequency: {
      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',
      biannually: 'Biannually',
      quaterly: 'Quarterly',
    },
    axle_name: {
      steer: 'Steer',
      drive: 'Drive',
      trailer: 'Trailer',
      bus: 'Bus',
    },
    rrc_reduction: 'RRC Reduction',
    savings_reduction: 'Savings Retread',
    saved_regrooved: 'Saved Regroove',
    changelog: 'Changelog',
    noVehiclesPresent: 'No Vehicles Present',
    noTiresPresent: 'No Tires Present',
    noVehicles:
      'No vehicles or trailers are present in the selected fleet please Add some to download the report.',
    tireInventory: {
      tooShortError: `Tire id must be 5 characters at least.`,
      maxDepthError: `Tread depth cannot exceed ${TIRES.MAX_DEPTH} mm`,
      alphanumericError: `Please enter only numbers`,
      noSpecialCharsMessage: `Enter numbers and letters only with no spaces`,
      tireUploadedMsg: `Tire successfully added`,
      addTire: `Add New Tire`,
      addTires: 'Add New Tires',
      uploading: 'Uploading Tires',
      movedToStorageSuccess: `Successfully Moved to Storage`,
      tireId: `Tire ID`,
      optimum_pressure: 'Pressure',
      mileage: `Current Mileage`,
      depth: `Tread Depth`,
      size: `Tire Size`,
      unmountMsgWarning: `This will unmount this tire and move it to storage.`,
      confirmBtn: `Confirm`,
      editTooltip: `Edit tire`,
      removeTooltip: `Dispose tire`,
      moveTooltip: `Unmount Tire`,
      viewEventHistory: `View Tire Event History`,
      inventoryEmpty: `No Tires In Inventory Yet`,
      singleUpload: `Add Single Tire`,
      multipleUpload: 'Add Multiple Tires',
      tireBrand: `Tire Brand`,
      optPressure: 'Optimum Pressure for this position is',
      showMore: `Show more`,
      showLess: `Show less`,
      productLines: `Product Lines`,
      update: `Update`,
      tireUpdateHeading: `Update Tire Information`,
      updateRegrooveHeading: `Regroove Tire`,
      uploadBtn: `Add Tire`,
      getMore: `Load more`,
      noBrands: `No Brands Available`,
      no_of_tires: 'No of Tires',
      steerTires: `Number of Steer Tires in Use`,
      driveTires: `Number of Drive Tires in Use`,
      trailerTires: `Number of Trailer Tires in Use`,
      manageTire: `Click to Manage Tire`,
      uploadFile: `Upload File`,
      disposeTires: `Dispose tires`,
    },
    actions: {
      cancel: 'Cancel',
      update: 'Update',
      delete: 'Delete',
      download: 'Download',
    },
    pageTour: 'Page Tour',
    mergedTrailer: 'Merged Trailer',
    example: 'Example',
    loadMessage: `Getting your things ready...`,
    deleteWarning: `By clicking delete , you agree to our T&C as per which your saved data will be completely erased and this action is permanent and cannot be reversed at any cost.`,
    search: `Search`,
    addFleet: `Add Fleet`,
    changeTire: `Change Tire`,
    unmountTire: `Unmount Tire`,
    success: `The operation was successful!`,
    vehicle: 'Vehicle',
    irregular_wear: 'Irregular Wear',
    pressureValueError: `Value should be between 5 and 15`,
  },
  verifyUser: {
    redirectToSignUpPage: 'You are being redirected to sign up page',
    ifNotRedirectedClick:
      'If you are not redirected automatically, then please click ',
  },
  fleetCheck: {
    fleetCheckUpdateMsg: 'Fleet Check Updated Successfully',
  },
  loginPage: {
    reduceTheCost: `Modernize your fleet management`,
    reduceTheCostMsg: `Reduce the total cost of ownership and carbon emissions through our optimised tire management plans`,
    signInToYourAccount: `Login to your account`,
    forgotPassword: `Forgot password ?`,
    or: `or`,
    loginInAgreeMessageInitial: 'By clicking on login, you accept our',

    register: 'Sign Up',
    login: 'Login',
    learnMore: 'Learn more',
    passwordComplexity: {
      number: 'At least one number',
      specialCharacter: 'At least one special character',
      lowercase: 'At least one lowercase letter',
      uppercase: 'At least one uppercase letter',
      length: 'At least 8 characters',
    },
    invalid: 'Invalid email format',
    emailNotRegistered: 'We have no account registered with this email',
    startSaving: 'Start saving',
    success: 'Successfully Logged in',
    leadWallHeading: 'Take just a minute to ...',
    leadWallButton: 'Continue to App',
    demo: `Request a demo`,
  },
  error: {
    406: 'Unverified account. Please check your mailbox (inclusive spam folder) for a user verification email from us.',
    407: 'User has been deactivated due to attempted malicious attack.',
    403: 'Wrong password. Max wrong password attempts are 5.',
    402: 'Too many unsuccessful password attempts. Your account has been flagged to protect your data. Request a new password using forgot password.',
    400: 'The account cannot be logged in with password as it was logged in with a social account already.',
    401: 'Wrong username',
    errorOccuredInformLogin:
      'An error has occurred. Please try again or notify co2opt admin.',
    errorLoadingData: 'Error Loading Data',
  },
  homePage: {
    on: 'on',
    overdue: 'Overdue',
    completeYourCompanyProfile: `Complete Your Company Profile`,
    completeYourCompanyProfileDesc: `Update your profile information and optimize your tire configuration`,
    configureYourFleet: `Configure Your Fleet`,
    savingPotential: `Saving potential`,
    status: {
      profile: `Profile status`,
      org: `Organization info`,
      tireMgmt: `Tire management info`,
      generalAsset: `General vehicles overview`,
      fleet: `Fleet data`,
      retreadStrategy: `Retread Strategy`,
    },
    fleetNotConfigured: `Fleet not Configured`,
    infoBoardHeading: `Meet us at the IAA Transportation fair in Hannover`,
    infoBoardSubtitle: `We are at Hall 13/C2020`,
    appointmentBtn: `Book an Appointment`,
    bookAnAppointment: `Free consultation`,
    configure: `Configure`,
    connectData: `Connect Data`,
    vehicleType: `Vehicle Type`,
    tireInfo: `Tire Info`,
    liveTracking: `Live Tracking`,
    nextActions: `Outstanding Tasks`,
    both: `Both`,
    unconfigured: `Unconfigured`,
    tracked: `Tracked`,
    untracked: `Untracked`,
    connected: `Connected`,
    infoNeeded: 'Infomation Needed',
    actions: 'Actions',
    retreadStrategy: 'Retread Strategy',
    confiqure: 'Configure',
    question: `Did you know ?`,
    operative_costs_eur: {
      text1: `Tires are operationally costing you `,
      text2: ` but are influencing your fuel bills worth `,
    },
    tire_related_savings_potential: {
      text1: `You can save at least `,
      text2: ` in total cost of ownership in a year through optimised tire management`,
    },
    taskBoard: {
      createdAt: `Created At`,
      description: `Description`,
      source: `Source`,
      fleet: `Fleet`,
      title: `Title`,
      taskTitleMany: ` Tires with Tread Depth < 3mm`,
      taskTitleSingle: ` Tire with Tread Depth < 3mm`,
      loading: `Loading tasks...`,
      actions: `Actions`,
      contactUs: `Contact CO2OPT Support`,
      changePayload: `Change payload`,
      configureTires: `Configure tires on the vehicle`,
      done: `Done`,
      after: `After Service`,
      before: `Before Service`,
    },
    moreBtn: `More Activities`,
    moreBtn2: `...See more`,
    lessBtn: `See less`,
    recentActivityHeading: `Recent Activity`,
    lessThan6months: `Expiring in less than 6 months`,
    dueIn6To12months: `Expiring in 6 - 12 months`,
    moreThan12months: `> 12 months`,
    lessThan3Months: '< 3 months',
    merged: `Merged`,
    atBottom: `You're all caught up`,
    initialAssessment: ` (Initial assessment)`,
    searchbar: {
      noResults: `No results found for your search term`,
      placeholder: `Search by vehicle name, registration number or tire ID`,
    },
    editHomeIcon: `Change what is visible`,
    dashboardEditTitle: `Select Sections to Display`,
    dashboardSections: {
      recent_activities: `Recent activities`,
      savings_potential: `Savings potential`,
      task_board: `Task board`,
      tire_overview: `Tire overview`,
      tire_recommendation: `Tire recommendation`,
      vehicle_overview: `Vehicle overview`,
    },
  },
  dataIntegrationPage: {
    provider: `Provider`,
    auth: `Authorization`,
    telematicsAltMessage: `Do not want to integrate your telematics provider yet?`,
    manualUploadBtn: `Upload telematics data as a CSV instead`,
    integratedProviders: `Integrated telematics providers`,
    noTelematicsMessage: `No telematics provider integrated!`,
    yellowfoxVehiclePlaceholder: `Enter one vehicle per time`,
    credentialsToken: `Credentials token`,
    traceAvailable: `Historical data`,
    trackAvailable: `Live tracking data`,
    infoLink: `Where do I find this?`,
    TOS: `By submitting the credentials, you declare that you are allowed to do so on behalf of your organization and that you accept to the terms of usage of the telematics data described in our`,
    detailsBtn: `See Details`,
  },
  csvReaderPage: {
    pageHeading: `Upload the telematics data as a .csv File`,
    providerNameQtn: `What is the name of the telematics provider from where you got the CSV data?`,
    uploadHistoryHeading: `CSV upload history`,
    uploadHistoryMessage: `Upload the telematics data as a CSV to get optimal tire configurations!`,
  },
  csvUploaderPage: {
    add: 'Add',
    addMultipleTrucks: 'Add multiple Trucks/Vans',
    addMultipleTrailers: 'Add multiple Trailers',
    addMultipleBuses: 'Add multiple Buses',
    exampleFile: 'Download Example File',
    supportedFormats: 'Supported formats: CSV, XLS, XLSX',
    sampleDownload: `Download`,
    individualUpload: 'Individual Vehicle Upload',
    bulkUpload: 'Bulk Upload',
    missingDataError: `Required column(s) are missing data. Please check file!`,
    drag: 'Drag File here or ',
    browse: 'Browse',
    csv: 'Download CSV',
    xlsx: 'Download XLSX',
  },
  supportPage: {
    address: `Address`,
    talk: `Personal Meeting`,
    genSupport: `General support`,
    formHeading: `Drop Us a Message`,
    send: `Send`,
    subject: `Subject`,
    message: `Message`,
  },
  fleetOverviewPage: {
    tireProvider: `Tire service provider`,
    deadline: `Application deadline`,
    telematics: `Telematics provider`,
    driverCard: `Driver card`,
    tank: `Fuel card`,
    overview: `Overview`,
  },
  kpiList: {
    emission: `CO2 Emissions`,
    fuelCosts: `Fuel Costs`,
    tireCosts: `Tire Costs`,
    tonnes: `Tonnes`,
    adminCosts: `Admin Tire Costs`,
    assets: `No. of Assets`,
    averageKm: `Avg. Km per Asset`,
    averageFuelConsumption: `Avg. Fuel consumption`,
    assumedFuelCosts: `Assumed Fuel Costs`,
    totalCostOfOwnership: 'Total cost of ownership',
    tireNumber: `Number of Tires`,
    brandsAndLines: `Tires - Brand and Product Lines`,
    tDepth: `Tires - Tread Depth`,
    size: `Tires - Size`,
    telematics: `Vehicle overview - Telematics`,
  },
  pieChart: {
    costsOfOwnershipTitle: `Total Cost of Ownership`,
    label1: `Other costs`,
    label2: `Diesel costs`,
    label3: `Tire costs`,

    assetsMakeTitle: `Vehicle Manufacturer`,
    tireTitle: `Tire Brands`,
  },
  barChart: {
    tireNumberTitle: `Number of Tires`,
    axleTitle: `Axle Types`,
    vehicleOverview: `Vehicle Overview`,
  },
  tabs: {
    home: `Home`,
    fleetsData: `Fleet`,
    support: `Support`,
    tireinventory: `Tire inventory`,
    reports: 'Reports',
    settings: `Settings`,
    logout: `Logout`,
    overview: `Overview`,
  },
  customerSettingsPage: {
    tabs: {
      organization: 'Organization',
      fleetCheck: 'Fleet Check',
      tire: 'Tire',
    },
    organization: {
      name: 'Name',
      noOfYards: 'Number of yards',
      zipcode: 'Zip code',
      city: 'City',
      country: 'Country',
    },
    tire: {
      isHotRetread: 'Hot retread',
      isColdRetread: 'Cold retread',
      isDeMinimis: 'De-minimis',
      preferredTireBrand1: 'Preferred tire brand 1',
      preferredTireBrand2: 'Preferred tire brand 2',
      preferredTire1Line: 'Preferred tire brand 1 line  ',
      preferredTire2Line: 'Preferred tire brand 2 line  ',
    },
    trailer: {
      noOfUnits: 'Number of units',
      totalDistanceTravelled: 'Total distance covered',
      selectAxelType: 'Select axle type',
      selectTireType: 'Select tire type',
    },
    truck: {
      noOfUnits: 'Number of units',
      totalDistanceTravelled: 'Total distance covered',
      selectAxelType: 'Select axle type',
      selectTireType: 'Select tire type',
    },
    bus: {
      noOfUnits: 'Number of units',
      totalDistanceTravelled: 'Total distance covered',
      selectAxelType: 'Select axle type',
      selectTireType: 'Select tire type',
    },
    actions: {
      cancel: 'Cancel',
      update: 'Update',
    },
    fleets: {
      fleetCheckFrequency: 'Fleet Check Reminder Frequency (max : 365 days)',
      selectDays: 'Select Days',
    },
  },
  userSettingsPage: {
    fName: `First Name`,
    lName: `Last Name`,
    orgName: `Organization Name`,
    email: `E-mail`,
    country: `Country`,
    deleteAcct: `Delete Account`,
    exportData: `Export Data`,
    notification: `Notifications`,
    integration: `Integrations`,
    dataExp: `Data Export`,
    export: `Export`,
    changePassword: `Change Password`,
    password: `Password`,
  },
  fleetsSection: {
    fleet: 'Fleet',
    bulkAssignment: 'Bulk Assignment',
    upload: 'Upload',
    trailer: 'Trailers',
    filter: 'Filter/Search',
    internalGrouping: 'Internal Grouping',
    options: 'Options',
    vehicleNumber: 'Vehicle Name',
    numberPlate: 'Number Plate',
    telematics: 'Telematics Connections',
    notAvailbale: 'N/A',

    configuration: 'Configuration',
    make: 'Make',
    vehicleType: 'Vehicle type',
    axleType: 'Axle type',
    currentTireConfiguration: 'Current tire configuration',
    configureTires: 'Configure tires',
    notConfigured: 'Not Configured',
    integrateTelematics: 'Integrate telematics',
    usageReports: 'Usage Reports',
    tireRecommendation: 'Tire Recommendation',
    uploadMore: `upload more vehicles`,
    navToPage: `Go to vehicle page`,
    expand: `Expand`,
    searchBy: `Search by`,
    regNumber: `Registration number`,
    changelogDownload: `Download recent changes in your vehicles`,
    vehicleNotAvailable: `Not Available`,
    missingTireRecommendationData:
      'Both tire configuration and usage reports are needed in order to obtain the tire recommendation. ',
    tireStatus: `Tire Status`,
    telematicsStatus: `Telematics Status`,
    vehicleName: `Vehicle Name`,
    tracked: `Tracked`,
    untracked: `Untracked`,
    partialConfig: 'Part. Configured',
    configured: 'Configured',
    mountedTires: 'Mounted tires',
    customerName: 'Customer Name',
    deleteModal: {
      fleetNamePrompt: `Enter the fleet name `,
      title: `Delete Fleet`,
      fieldLabel: `Fleet name`,
    },
    export: `Export to excel file`,
    tableauBtnInfo: `Access Interactive Insights`,
    tableHeaders: {
      name: `Vehicle Name`,
      reg_plate: `Registration number`,
      vehicle_type: `Vehicle Type`,
      telematicsProvider: `Telematics Connections`,
      axle_type: `Axle Types`,
      internal_grouping: `Internal Grouping`,
    },
    tableauBtnName: `Tire Analytics`,
    bulkUpload: `Bulk Upload`,
    addTruck: `Add Truck`,
    addTrailer: `Add Trailer`,
    addBus: `Add Bus`,
    edit: `Edit`,
    delete: `Delete`,
    deleteFleet: `Delete Fleet`,
    moveVehicles: `Move Vehicles`,
  },
  level1Modal: {
    vehicleName: 'Vehicle name',
    tiresizes: 'Tire sizes',
    axleTypes: 'Axle types',
    step1: 'Vehicle configuration',
    step2: 'Tire configuration',
    step3: 'Confirm & apply',
    infoBannerApplyToVehicles: 'Apply to one/many',
    vehiclesSelectionOption: 'Only this vehicle',
    show: `Show`,
    more: `more`,
    less: `less`,
    mountedDate: `Mounted Date`,
    retreaded: `Retread`,
    regrooved: 'Regroove',
    treadDepth: `Tread Depth`,
    applyToVehicle: `Apply to this `,
    selectOther: `Select other`,
    confirmApply: `Confirm & Apply`,
    next: `Next`,
    back: 'Back',
  },
  custom_dropzone: {
    mediaCenter: 'Media Center',
    vehicleFiles: 'Vehicle Files',
    infoText: 'Drag or drop some files here, or click to select files',
    error: `An error occurred. Please ensure the file size does not exceed 5mb and try again.`,
  },
  plansPage: {
    subAnnual: `Annually`,
    subMonthly: `Monthly`,
    perMonth: `Per month`,
    digitalTwin: `Digital twin of your fleet`,
    dataIntegration: `Telematics data integration`,
    savings: `Estimated savings`,
    documentFetch: `Tire Service document fetch`,
    yourPlan: `Your plan`,
    all: `All`,
    features: `Features`,
    tireRecommendations: `TCO Optimized tire Recommendations`,
    serviceCenter: `Nearest Tire Service Center`,
    dataExport: `BI Data Export`,
    select: `Select Plan`,
    tireBooking: `Tire Booking`,
    tailored: `Tailored for your company`,
    customizedFeatures: `Customized Features`,
    onRequest: `Price on Request:`,
    featureRequests: `Up to 3 Feature Requests`,
    customTelematics: `Custom Telematics`,
    installation: `Telematics Installation`,
    contact: `Contact Us`,
    monthBill: `Billed monthly`,
    annualBill: `Annual Plan`,
    specialOffer: `Special limited “InnoFleet” offer: All future features included for free until 31.12.2023`,
    deminimis: `Deminimis`,
  },
  subscriptionBtn: `Upgrade`,
  dataRoom: {
    welcome: `Welcome `,
    vehiclePhotos: `Photos`,
    docs: `Files`,
    noFiles: `No Files Uploaded Yet`,
    altUpload: `Simplify the workflow of maintaining your vehicle documents using one of the below options:`,
    whatsapp: `Whatsapp hotline to send the files:`,
    deleteConfirm: `Are you sure you want to delete the file(s)?`,
    clear: `Clear Selection`,
    uploadPhotosBtn: `Upload Photos`,
    uploadFilesBtn: `Upload Files`,
    addFilesBtn: `Add more Files`,
    addPhotosBtn: `Add more Photos`,
    uploadPhotoTitle: `Vehicle Photos`,
    uploadFilesTitle: `Vehicle Service Documents`,
  },
  nearestService: {
    heading: `Nearest Tire Service`,
    noTelematics: `Vehicle not tracked yet. To link your telematics provider, click HERE`,
    serviceUnavailable: `We were unable to get this vehicle's location. Please try again later or report this issue`,
  },
  releaseNotes: `Release Notes`,
  singleVehicleUpload: {
    add: 'Add',
    vehicle: 'Vehicle',
    truck: 'Trucks/Vans',
    trailer: 'Trailer',
    bus: 'Bus',
    next: 'Next',
    vehicleName: 'Vehicle Name',
    vin: 'Vin',
    chassisNumber: 'Chassis Number',
    fleetName: 'Fleet Name',
    selectFleet: 'Select Fleet',
    chooseFleet: 'Choose Fleet',
    addNewFleet: 'Add New Fleet',
    fleetLocation: 'Fleet Location',
    tankCapacity: 'Tank Capacity',
    regPlate: 'Registration Plate',
    internalGrouping: 'Internal Grouping ',
    cancel: 'Cancel',
    addVehicleButton: 'Add Vehicle',
    duplicateError: `One or more details entered is already in use by another vehicle. Please check and try again.`,
    success: `Vehicle added successfully`,
    safetyCheckLabel: `Safety Check Date`,
    inspectionDateLabel: `Next Inspection Date `,
    odometerLabel: `Current Odometer Reading`,
    vinCharsWarning: `should be 17 characters at least`,
    vinCharsWarning2: `should not exceed 20 characters`,
    regPlateCharsWarning: `Value should not exceed 12 characters`,
    single: `Single`,
    multiple: `Multiple Upload`,
    axleType: `Axle Type`,
    steer: `Steer tire size`,
    drive: `Drive tire size`,
    tireSize: `Tire Size`,
  },
  vehiclePage: {
    assignResources: 'AssignResources',
    resourceBtn: 'Assign Resource',
    bulkUpload: 'Bulk Assignment',
    assignSuccessMsg: 'Assign Resources Successfully',
    assignFailureMsg: 'Something Went Wrong',
    tireConfig: `Tire Configuration`,
    vehicleFile: `Vehicle File`,
    reports: `Usage Reports`,
    recommendation: `Tire Recommendations`,
    tireService: `Nearest Tire Service`,
    grouping: `Grouping`,
    regNumber: `Registration Number`,
    brand: `Vehicle Brand`,
    chassis: `Chassis Number`,
    type: `Type`,
    drawerBtn1: `Trucks/Vans`,
    retread: `Retread Strategy`,
    tank: `Tank Capacity`,
    noAccessResponse: `Integrate telematics for your vehicles to access this section`,
    updateSuccess: `Entries updated successfully`,
    tooltipUpdateIcon: `Update Vehicle Information`,
    tankCapacity: `Tank Capacity`,
    inspection: `Next Inspection`,
    safetyCheck: `Next Safety Check`,
    odometer: `Odometer Reading`,
    odometerLabel: `Odometer (Telematics)`,
    mergeStatus: `Merge Status`,
    pending: `Update missing info!`,
    dialogPromptFirst1: `You are attaching `,
    dialogPromptFirst2: `You are detaching `,
    dialogPromptSecond1: ` to this truck...`,
    dialogPromptSecond2: ` from this truck...`,
    dialogTitle: `Attach New Trailer`,
    dialogTitle2: `Detach Trailers`,
    selectLabel: `Merged Trailer `,
    attachSuccess: `Trailer Successfully Attached!`,
    steer: `Steer Tire Size`,
    drive: `Drive Tire Size`,
    tireSize: `Tire Size`,
    axle: `Axle`,
    deleteModal: {
      truckNamePrompt: `Enter truck name `,
      trailerNamePrompt: `Enter trailer name `,
      deleteTruck: `Delete truck`,
      deleteTrailer: `Delete trailer`,
    },
    updateVehicleHeading: `Update Vehicle Info`,
  },
  platformInfoRoute: {
    scheduleMeeting: 'Schedule a Meeting',
    writeMessage: 'Write us a Message',
    callUs: 'Call Us',
    tutorials: 'Tutorials',
  },
  tireConfigPage: {
    retreadConfigBtn: `Configure Retread Strategy`,
    brand: `Brand`,
    hotRetread: `Hot Retread`,
    coldRetread: `Cold Retread`,
    changelogDownload: `Download Tire History`,
    axlConfiguredSuccess: `Axle and Tire size set successfully`,
    axleSelect: `Select Axle Type`,
    tireSelect: `Select Tire Size`,
    swapFail: `Swap Failed. Tire Mismatch!`,
    more: `More`,
    confirm: `Confirm`,
    configureTirePrompt: `Please Configure Your Tires`,
    level2Modal: {
      noBrands: `No Brands Available For This Tire Size`,
      tireBrand: 'Tire Brand',
      others: 'Others',
      showMore: 'Show More',
      showLess: 'Show Less',
      productLines: 'Product Lines',
      choose: 'Choose Config Type For This',
      tireOnly: 'Tire Only',
      axleOnly: 'Axle Only',
      entireVehicle: 'Entire Vehicle',
      apply: 'Apply',
    },
    moreOptionsModal: {
      safetyCheckLabel: `Safety Check Date`,
      inspectionDateLabel: `Next Inspection Date`,
      odometerLabel: `Current Odometer Reading`,
    },
    liftAxlBtn: `Configure Lift Axles`,
    liftAxle: {
      successMsg: `Lift axles configured successfully!`,
      errorMsg: `All axles cannot be lifted!`,
      axle: `Axle`,
      lifted: `lifted`,
      notLifted: `lowered`,
    },
    axleLifted: `Axle is lifted`,
    axleLowered: `Axle is lowered`,
    noRetreadMsg: `Retread Not Configured`,
    tireRetreadInfo: `Tire is retreaded`,
    headingLeft: `Tire configuration details`,
    tableView: `Table view`,
    updateTireBtn: `Update Tire`,
    changeTireBtn: `Change Tire`,
    unmountTireBtn: `Unmount Tire`,
    uploadServiceReportBtn: `Upload Service Report`,
  },
  fleetsDataPage: {
    fleet: 'Fleet',
    trucks: 'Trucks/Vans',
    buses: 'Buses',
    upload: 'Upload',
    trailer: 'Trailers',
    noFleets: `There are No Fleets Available. Please contact the Admin.`,
  },
  header: {
    homeDashboard: 'Homepage',
    telematics: 'Telematics',
    fleetsSection: `Fleet`,
    changelog: `Release Notes`,
    reports: 'Reports',
    plans: ``,
    settings: `Settings`,
    fleet: 'Fleet',
    inventory: 'Inventory',
    activities: 'Activities',
  },
  tileToolTip: {
    fleet: {
      trucks: 'Work in progress',
      trailers: 'Work in progress',
      vehicleInfo: 'Work in progress',
    },
    telematics: {
      integratedProvides:
        'These are the different telematics connectors available for sharing your data. ',
      authorization:
        'The necessary credentials for authorization in API Gateway are required here.',
      connectedProviders: `These are the providers that are connected to your account`,
    },
    home: {
      actionsRequired:
        'This is the section where the open tasks are displayed. ',
    },
  },
  tour: {
    first:
      'Here, you see the consolidated information of your fleet in regards to actions required, asset management and savings estimates',
    second:
      'Here, you can allow seamless integration of your telematics provider with our app by just loading in the credentials with just two clicks',
    third:
      'Here, you can configure your fleet by adding vehicles and updating their configurations either individually or using bulk upload',
    fourth: 'Here, you can get add, update or manage tires in your inventory.',
    fifth: 'Here, you can check reports.',
    sixth:
      'Here, you can use different settings. Like- Profile, Fleet and User Management.',
    seventh:
      'Here you can reach out to us for help and the necessary resources to understand the scope of the product',
    eighth: 'Here you can upgrade your plan from trial to a premium one',
    page: {
      home: {
        actionsRequired:
          'Here, you can see different actions required for your vehicles.',
        savingPotential: 'Here, you can see metrices of potential savings.',
        homeBasicInfos:
          'Here, you can see the overall status of your organization profile.',
      },
      telematics: {
        integratedTelematicsProvider:
          'Here, you can see the telematics providers information.',
        provider: 'Here, you can choose the supported telematics providers.',
        authorization:
          "Here, you can authorize yourself on your organization's behalf to perform organization level tasks.",
      },
      fleet: {
        recentChanges:
          'Here, you can check the recent changes done to your fleets.',
        trucks:
          'Here, you can add trucks or update their configurations either individually or using bulk upload',
        trailers:
          'Here, you can add trailers or update their configurations either individually or using bulk upload',
        table:
          "This table shows details related to all vehicles of this fleet. You can add single or multiple trucks and trailers, delete fleet, download recent changes and also navigate to go the respective vehicle's page to configure it.",
      },
      vehiclePage: {
        tireConfig: 'Here, you can view and configure tires.',
        vehicleFile:
          'Here, you can view and upload the photos, videos and files of your vehicles.',
        reports: 'Here, you can see the usage reports of your vehicle.',
        recommendations:
          'Here, you can get the recommendation for suitable tires for your vehicles based on analysis.',
        tireService:
          'Here, you can check the nearest tire service for your vehicles.',
        basicVehicleInfo: 'Here, you can see the basic info of your vehicles.',
      },
      savingPotentials: {
        metricTile:
          'Here, you can see the different metrices for potential savings in an interactive format.',
      },
      settings: {
        profileSettings: 'Here, you can update your profile.',
        fleetSettings:
          'Here, you can update settings retated to your organization, and the common brand of tire, trucks, trailers or retreated strategy used by your organization.',
        userMgt: `Here, you can invite new users, delete existing users, as well as manage resources assigned to them`,
      },
    },
  },
  retreadModal: {
    coldRetread: `Do You Prefer Cold Retread?`,
    hotRetread: `Do You Prefer Hot Retread?`,
    casing: `What is Your Preferred Retread Casing?`,
    retreadPreference: `Is Retread Preferred?`,
    apply: `Apply To This  `,
    others: `Include Other `,
    otherTrucks: `Include Other Trucks`,
    otherTrailers: `Include Other Trailers`,
    otherBuses: `Include Other Buses`,
    select: `Select`,
  },
  recommendationPage: {
    noTires: `Tires for this vehicle are yet to be configured`,
    noTiresMessage: `Configure the tires and check back after a day to get the tire recommendation`,
    noTelematicsMessage: `Click HERE to connect the telematics to get tire recommendation in a data driven way`,
    noRecommendationMessage: `Check back after a day to see the tire recommendation`,
    tireMgt: `Tire Management Overview`,
    update: `Update Tires`,
    book: `Book`,
    exclusionPlaceholder: `Exclude Brands Here...`,
    inclusionPlaceholder: `Include Brands Here...`,
    showMore: `Show more`,
    collapse: `Collapse`,
    empty: `No results match your filter`,
    customizeRecommendations: 'Customize Recommendations',
    runningReading: 'Mileage',
    retreadable: 'Retreadable',
    rollingResistance: 'Rolling resistance',
    traction: 'Traction',
    fuelConsumption: 'fuel consumption',
    carcassValue: 'Casing value',
    price: 'Price',
    applyFilter: 'Apply Filter',
    partialConfiguredMessage: `Incomplete Tire Set. Please check that all tires are mounted.`,
    captions: {
      runningReading:
        'mileages would be a determining factor in the recommendations (higher lifetime mileage is better)',
      retreadable: 'Tick this if you want your tire to be retreadable',
      rollingResistance:
        'Tick this if you want RRC to be a part of calculations (lower RRC is better)',
      traction:
        'Tick this if you want tires with better traction (this imposes a lower limit on the tires in terms of traction)',
      price:
        'price would be a determining factor in the recommendations (cheaper is better)',
      fuelConsumption:
        'Tick this if you want the fuel consumption estimates for the tires to play a part in the calculations (less fuel is better)',
      sameBrand:
        'For trucks and buses, this will ensure that the brand on steer and drive axles is the same',
    },
    sameBrand: 'Same brand',
  },
  retreadStrategy: {
    casting_type:
      'What is the type of casing you would like to use for the retread tires for your fleet?',
    mount_type: 'Where would you like the retread tires to be mounted?',
    delivery_schedule:
      'What kind of delivery schedule do you desire with the casings of your retreads?',
  },
  usageReportsPage: {
    nextDay: `Next Day`,
    nextWeek: `Next Week`,
    prevDay: `Previous Day`,
    prevWeek: `Previous Week`,
    noTelematicsMessage: `Click HERE to connect the telematics to get usage reports in a data driven way`,
    noReportsMessage: `Check back after a day to see the usage reports`,
    noTiresMessage: `Configure the tires and check back after a day to get the usage reports`,
    download: `Download Report`,
    noDataDay: `No data for the selected date`,
    noDataWeek: `No data for the selected week`,
    reportType: `Report Type`,
    daily: `Daily`,
    weekly: `Weekly`,
    selectDay: `Select Date`,
    selectWeek: `Select Week`,
    kpiTitles: {
      mission_profile: 'Mission Profile',
      average_speed: `Average Speed`,
      minimum_change_in_elevation: `Minimum Change in elevation`,
      average_change_in_elevation: `Average Change in elevation`,
      maximum_change_in_elevation: `Maximum Change in elevation`,
      total_fuel_burnt: `Total Fuel Burnt`,
      total_co2_emissions: `Total CO2 Emissions`,
      total_distance_driven: `Total Distance Driven`,
      total_number_of_trips: `Total Number of Trips`,
      average_fuel_consumption: `Average Fuel Consumption`,
      total_time_on_the_move: `Total Time on the Move`,
      heat_map: `Heat Map`,
      idling_locations: `Idling Locations`,
      '3PMS_needed': `3PMS Needed`,
      fuel_level_drop: `Fuel Level Drop`,
      'm+s_needed': `M+S Needed`,
      distribution: `Distribution (%)`,
    },
  },

  uploadSummary: {
    vehicleUploadSummary: 'Vehicle Upload Summary',
    fleetName: 'Fleet Name',
    vehicleName: 'Vehicle Name',
    status: 'Upload Status',
    result: 'Result',
    downloadSummary: 'Download Summary',
    back: 'Back',
    fileType: `Select File Type`,
  },
  kpiChart: {
    days: 'Days',
    weeks: 'Weeks',
    download: 'Download',
  },
  telematicsModal: {
    title: `Vehicle Telematics Information`,
    noVehicle1: `No Registered Vehicle Currently Integrated`,
    noVehicle2: `No Vehicles Found`,
    noAccess: `N/A...You are not assigned this vehicle.`,
    downloadMsg: `Get unregistered vehicle list in right format`,
  },
  multiLayerReport: {
    selectFleet: 'Select Fleets',
    selectFleet2: 'Select Fleets & Dates',
    selectVehicles: 'Select Vehicles',
    selectKpis: 'Select Kpis',
    generateReport: 'Generate Report',
    downloadReport: 'Download Report',
    noTrackedVehicles: `No Tracked Vehicles`,
    select: `Select All`,
    deselect: `Deselect All`,
    mission_profile: 'Mission Profile',
    total_share_motorway: 'Total Share Motorway',
    total_share_regional: 'Total Share Regional',
    total_share_urban: 'Total Share Urban',
    total_number_of_trips: 'Total Number Of Trips',
    average_speed: 'Average Speed',
    total_distance_driven: 'Total Distance Driven',
    tire_configuration: 'Tire Configuration',
    waitMessage: `Generating report. This might take a while, please be patient`,
    waitMessage2: `Adding finishing touches...`,
    overview: `Tire Overview`,
    reportType: 'Report Type',
    startDate: `Start Date (optional)`,
    endDate: `End Date (optional)`,
    reportTypes: {
      list: `Vehicle List`,
      inventory: `Tire Inventory`,
      tireChangelog: `Tire changelog`,
      vehicleChangelog: `Vehicle changelog`,
      usage: `Vehicle Usage Reports`,
    },
    reportPageTitle: `Reports`,
    create: `Create`,
  },
  userManagement: {
    user: `User`,
    users: `Users`,
    invite: `Create New User`,
    inviteBtn: `Create User`,
    inviteDetails: `Fill in the details of the user you want to invite and select
    their role.`,
    accessType: 'Access Type',
    assignResourceDetails: `Select the resources you want the invited user to have access to`,
    assignResources: `Assign Resources`,
    deleteResources: `Unassign Resources`,
    lastLogin: `Last Login`,
    actions: `Actions`,
    verified: `Verified`,
    pending: `Pending`,
    successDelete: `User deleted successfully`,
    deletePrompt1: `Please confirm that you want to delete account of invited user`,
    deletePrompt2: `. Kindly note that this action cannot be reversed at any cost.`,
    deleteTooltip: `Delete User`,
    editAccess: 'Edit Accessibility',

    confirm: `Confirm`,
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    role: `Role`,
    position: 'Position',
    depot: 'Depot',
    locale: 'Locale',
    editTooltip: 'Edit Role',
    successEdit: `User role edited successfully`,
    editRole: 'Edit Role',
  },
  assetsWhitelisting: {
    selectTrailer: `Select Trailer(s)`,
    selectTruck: `Select Truck(s)`,
    success: `Operation Successful!`,
    noTrucksToDel: `User is not assigned trucks in this fleet`,
    noTrailersToDel: `User is not assigned trailers in this fleet`,
    noBusesToDel: `User is not assigned buses in this fleet`,
    selectBus: `Select Bus(s)`,
    noBuses: `No Buses in Fleet`,
    noTrucks: `No Trucks in Fleet`,
    noTrailers: `No Trailers in Fleet`,
    noFleets: `User not assigned any fleets`,
    currentlyAssigned: `Currently assigned: `,
  },
  changePassword: {
    enter_email: 'Enter your email',
    logout: 'You are about to get logged out in ',
    change_password: 'Change Password',
    message:
      ' By clicking on submit , you agree to our T&C as per this action is permanent and cannot be reversed at any cost.',
  },
  tireOperationsModal: {
    updateBtn: `Update Tire`,
    changeBtn: `Change Tire`,
    unmountBtn: `Move to Storage`,
    availableTires: `Available Tires`,
    noTiresMsg: `No Tires available`,
    uploadTireSuggestion: `Tire Not in Your Inventory? Upload a New Tire`,
    here: `Here`,
    updateHeading: `Update Tire Information`,
    unmountReasonLabel: `Select Reason for Unmount`,
    unmountDateLabel: `Unmount Date`,
    mountDateLabel: `Mount Date`,
    unmountTireTitle: `Unmount Old Tire At Axle Position`,
    mountTireTitle: `Select New Tire At Axle Position`,
    mountWarningMsg: `By clicking on this button, this tire(s) will be mounted in the selected position(s)`,
    eventDate: `Event Date`,
    backToHome: `Go to first page`,
    unmountReasons: {
      worn: `Worn out`,
      repair: `Repair`,
      warranty: `Warranty`,
      smashed: `Smashed`,
      puncture: `Puncture`,
      store: `Store`,
    },
    mountOne: `Mount on this position`,
    mountMany: `Mount on more positions`,
  },
  deleteTire: {
    label: `Enter Tire ID`,
    prompt: `Enter the tire ID`,
    delete: `Delete`,
    warning: `This action will permanently remove this tire from your
    inventory.`,
    warning2: `This action will permanently remove these tires from your
    inventory.`,
    deleteSuccessful: `Tire Successfully Deleted`,
  },
  manageTire: {
    title: `Tire Actions`,
    repairBtn: `Repair`,
    retreadBtn: `Retread`,
    regrooveBtn: `Regroove`,
    disposeBtn: `Dispose`,
    retreadComplete: `Retreading completed`,
    refurbished: `Tire refurbished`,
  },
  tireInventoryItems: {
    tDepthStorage: `Average Tread Depth Per Tire in Store`,
    tDepthInUse: `Average Tread Depth Per Tire in Use`,
    avgTreadDepthPerTireStore: 'Average Tread Depth/tire (storage)',
    avgTreadDepthPerTireInUse: 'Average Tread Depth/tire (In Use)',
    filter: `Filter`,
    size: `Tire Size`,
    status: `Tire Status`,
    vName: `Vehicle Name`,
    maxDepth: `Max. Tread Depth`,
    wornOut: `Worn Out`,
    totalTires: 'Total Tires',
    viewAllTires: 'View All Tires',
    addTires: 'Add Tires',
    unmerged: 'Unmerged',
    coupled: 'Coupled With Truck',
    titles: {
      mileage: `Mileage (km)`,
      name: `Vehicle Name`,
      treadDepth: `Last Tread Depth`,
      estTreadDepth: `Tread Depth (Est.)`,
      estimated_current_tread_depth: `Tread Depth (Est.)`,
      estimated_wear_out_date: `Wear Out Date (Est.)`,
      mountDate: `Mount Date`,
      mountDays: 'Mount Days',
      lastUpdate: `Last Update`,
      lastVehicleName: 'Last Vehicle Name',
      eventDate: `Event Date`,
      eventType: `Event Type`,
      position: `Mounted Position`,
      vehicle: `Vehicle`,
      pressure: `Tire Pressure`,
      actions: `Actions`,
      size: `Size`,
      brand: `Brand`,
      product_line: `Product Line`,
      status: `Status`,
      tire_id: `Tire Id`,
      inUse: 'In Use',
      inStore: 'In Store',
      monthsLeft: `Months Left (Est.)`,
      axlePosition: `Axle Position`,
      mission_profile: 'Mission Profile',
      isRetread: `Retreaded`,
      isRegrooved: `Regrooved`,
      tDepth: `Tread Depth`,
      tDepthEst: `Tread Depth (Est.)`,
      tire: `Tire / Tire ID`,
      km: `Mileage`,
      rfid: 'Rfid',
      lastEventType: 'Last Event Type',
    },
    noData: `No Data to Display`,
    statuses: {
      IN_USE: `In Use`,
      STORAGE: 'Storage',
      RECYCLING_BANK: `Recycling Bank`,
      RETREAD: `Retreading`,
      REGROOVE: `In store`,
      inUse: `In Use`,
      repair: 'Repair',
      storage: `Storage`,
      recyclingBank: `Recycling Bank`,
      retread: `Retreading`,
      all: `All`,
    },
    regrooved: `Regrooved`,
    dispose: `Dispose`,
  },
  swapAction: {
    success: `Tires successfully swapped`,
    info: `You are swapping the tire positions. Click button below to confirm action.`,
  },
  maintencePage: {
    title: 'Under Maintenance',
    description:
      'Scheduled maintenance is ongoing. We will be back online within the next hour. For urgent matters, please write us an email at info@co2opt.com or call +49 (0) 40 6077998 96',
    apology: 'Sorry for the inconvenience.',
  },
  tireRecommendation: {
    header: {
      estimatedPriceAllTires: 'Estimated price (all tires)',
      estimatedKmPrice: 'Estimated Cost/100Km (€)',
      fuelSaveLperKM: 'Fuel save litre/100km',
      theoreticalMileage: 'Theoretical Mileage',
      estimatedSavingsPotentialTco: 'Estimated TCO Savings (€)',
      tcoRating: 'TCO Rating',
      trailerBrand: 'Tire: Trailer',
      steerBrand: 'Tire: Steer ',
      driveBrand: 'Tire: Drive',
      buy: 'Buy',
      monthlyDistance: `Average Monthly Distance (km)`,
      savingsInTco: `Savings potential in TCO (€)`,
      amortization: `Amortization Period (months)`,
    },
    info: `We need the vehicle fully configured for recommendations
    specific to you and based on your usage. Add missing tires`,
    button: `See Recommendations Without Tire Config`,
    or: `OR`,
    noTireSizeMessage: `Tire analytics error: No tire size found. Tire sizes need to be configured for the vehicle in order to provide recommendations`,
  },
  escalateMessage: {
    successMessage: 'Message sent. Thanks for letting us know! ',
    header: 'Report to Us',
    iconTooltip: 'Report to Us',
    message: 'Message',
  },
  vehicleTypeDeleteModal: {
    remove: 'Remove',
    trucks: 'trucks',
    trailers: 'trailers',
    confirm: 'Are you sure you want to delete all ',
    delete: 'Delete',
    terms_and_condition:
      ' By clicking delete , you agree to our T&C as per which your saved data will be completely erased and this action is permanent and cannot be reversed at any cost',
  },
  eventHistoryModal: {
    revertSuccess: `Tire event reverted successfully`,
    dialogMessage: `You are reverting the last event on this tire...`,
    revertEvent: `Revert last event`,
  },
  tireForm: {
    brands: `Brands`,
    others: `Other Brands`,
  },
  deleteUserModal: {
    nameLabel: `Enter Your First name`,
    deleteUserPrompt: `By deleting your account, all saved data will be permanently erased.
    This includes account information and associated content. The data
    cannot be recovered. Please note that by deleting your account, you
    also accept our Terms and Conditions. These state that you are
    responsible for any loss of data or information associated with the
    deletion.`,
    deleteVehicleWarning: `When you delete your vehicle, all associated data will be permanently erased. This includes vehicle information and any related content. The deleted data cannot be recovered. Please note that by deleting your vehicle, you also accept our Terms and Conditions. These terms state that you are responsible for any loss of data or information associated with the deletion.`,
    deleteFleetWarning: `When you delete this fleet, all associated data will be permanently erased. This includes fleet information and any related content. The deleted data cannot be recovered. Please note that by deleting this fleet, you also accept our Terms and Conditions. These terms state that you are responsible for any loss of data or information associated with the deletion.`,
  },
  deleteResource: {
    truckName: `Truck name`,
    trailerName: `Trailer name`,
    fleetName: `Fleet name`,
  },
  tiresUpload: {
    brandMissing: `Can't find tire Brand?`,
    enterBtn: `Enter Manually`,
    brand: `Tire Brand`,
    productLine: `Product Line`,
  },
  headers: {
    name: 'Name',
    vin: 'vin',
    fleet_name: 'Fleet Name',
    reg_plate: 'Reg plate',
    tank_capacity: 'Tank capacity',
    fleet_location: 'Fleet Location',
    internal_grouping: 'Internal Grouping',
    chassis_number: 'Chassis number',
    odometer_reading: 'ODometer reading (Optional)',
    inspection_date: 'Inspection date (Optional)',
    safety_check_date: 'Safety check date (Optional)',
    axle_type: 'Axle type (optional)',
    tire_size: 'Tire size (optional)',
    steer_tire_size: 'Steer tire size (optional)',
    drive_tire_size: 'Drive tire size (optional)',
  },
  filterComponent: {
    filter: `Filter`,
    clear: `Clear all`,
    zeroMatches: `No matches`,
  },
  newFleetModal: {
    heading: `Add New Fleet`,
    fleetName: `Fleet Name`,
    yards: `Number of yards`,
    zip: `Zip Code`,
    country: `Select Country`,
    city: `Enter City`,
    cancel: `Cancel`,
    confirm: `Confirm`,
    location: 'Location',
  },
  settingsPage: {
    tireSettings: {
      header: 'Tire Prices',
      priceId: 'Tire Price',
      tireSize: 'Tire Size',
      brand: 'Brand',
      productLine: 'Product line',
      addTireLabel: 'Add Tire Price',
      createTirePrice: 'Create Tire Price',
      updateTirePrice: 'Update Tire Price',
      tirePrice: 'Tire Price',
    },
    notifications: {
      heading: `Subscribe or Unsubscribe to Email Notifications...`,
      selectUser: 'Select User',
      weeklyLabel: `Organization Summary`,
      fleetCheckRemainder: 'Fleetcheck Reminder',
      weeklyBody: `Get emails summarizing organization-wide activities`,
      fleetCheckDesc: `Get email of activities on fleet checking`,
      tDepth: `Low Tread Depth`,
      userReport: 'User Summary',
      tDepthBody: `Get an email summary when tire tread depth is between 3 and 5mm, or below 3mm`,
      userBodyDesc: 'Get emails with a personalized summary of your activities',
      select: `Select Days`,
      selectDay: `Select Day`,
      daily: 'daily',
      weekly: 'weekly',
      monthly: 'monthly',
      biannually: 'biannually',
      days: {
        monday: `Monday`,
        tuesday: `Tuesday`,
        wednesday: `Wednesday`,
        thursday: `Thursday`,
        friday: `Friday`,
        saturday: `Saturday`,
        sunday: `Sunday`,
      },
    },
  },
  tableauPage: {
    errorMessage: `Error Fetching data!`,
    refresh: `try again`,
  },
  axles: {
    '1-axle': '1-axle',
    '2-axle': '2-axle',
    '3-axle': '3-axle',
    '4-axle': '4-axle',
    '5-axle': '5-axle',
    '1-axle-twin': '1-axle-twin',
    '2-axle-twin': '2-axle-twin',
    null: 'N/A',
  },
  treadDepthSource: {
    EST: 'This value is our best approximation',
    GT: 'This value comes from real world data',
  },
  columnsEdit: {
    update: `Update`,
    editBtn: `Edit columns`,
    error: `Too many columns to display, please take one or more off`,
  },
  grooveTD: {
    selectGroove: 'Do you want to add groove tread depth ?',
    grooveTreadDepth: 'Groove Tread  Depth',
  },
  guidesPage: {
    title: {
      telematics: `Set up telematics`,
      upload_vehicles: `Upload vehicles`,
      addTires: `Add single and multiple tires`,
      'user-mgt': `Invite colleagues`,
      notifications: `Set up Notifications`,
      'fleet-settings': `Set up Optimal Pressure`,
    },
    description: {
      telematics: `Choose your telematics provider(s) and integrate with our app`,
      upload_vehicles: `Add your trucks, trailers or buses either individually or upload a sheet`,
      addTires: `Add tires to your inventory`,
      'user-mgt': `Invite people to join you on the app and give them varying access levels`,
      notifications: `Subscribe to or unsubscribe from Email notifications`,
      'fleet-settings': `Set the optimal tire pressure on axle basis`,
    },
    heading: `User Guides`,
    pageTitle: `Get acquainted with the app and complete tasks...`,
    tour: {
      notifications: `Here you can manage the email notifications you get`,
      optimalPressure: `Click to set/update the optimal tire pressure`,
      invite: `Invite a colleague here`,
      addVehicle: `Add a single vehicle or multiple ones simultaneously`,
      addTire: `Add a single tire or multiple ones simultaneously`,
    },
  },
};

export default en;

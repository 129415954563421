import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Breadcrumb from 'components/custom/Breadcrumb';
import { Box, Grid, Typography, useTheme, Modal, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { setTour, setTourSelector } from 'redux/users/ducks';
import { useTranslation } from 'react-i18next';
import useKpiList from 'hooks/useKpiList';

import { currencyFormatEUR, currencyFormatNonEU } from 'utils/helper';
import KpiTile from 'components/custom/KpiTile';
import DoughnutChart from 'components/custom/charts/DoughnutChart';
import Tile from 'components/Tile';
import BarChart from 'components/custom/charts/BarChart';
import Loading from 'components/Loading';
import i18next from 'i18next';
import TourContent from 'components/Tour/Content/TourContent';
import Tour from 'reactour';
import {
  fleetOverviewSelector,
  savingEstimateSelector,
  setSavingEstimate,
} from 'redux/fleets/ducks';
import { AspectRatio } from '@mui/icons-material';
import Close from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import { getSavingsEstimate } from '../../services/fleet_service';

const useStyles = makeStyles()(theme => {
  return {
    doughnutCard: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#fff',
      objectFit: 'contain',
      boxShadow: 'none',
      position: 'relative',
    },
    doughnut: {
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        width: 300,
      },
      [theme.breakpoints.up('xl')]: {
        width: '100%',
        height: '70%',
      },
    },
    header: {
      height: '60px',
      width: 'inherit',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      paddingInline: theme.spacing(2.5),
      alignItems: 'center',
      borderBottom: '0.5px solid #E6E6E6',
    },
    heading: { fontSize: '1.25rem', fontWeight: 600 },
    tabularDetailsBoxContainer: {
      height: '250px',
      // height: 'calc(100% - 60px)',
      display: 'flex',
      flexDirection: 'column',
      gridGap: theme.spacing(3),
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.5),
    },
    tabularDetailsBoxContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    label: {
      fontSize: '1rem',
    },
    value: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    container: {
      width: '100%',
      display: 'flex',
      backgroundColor: '#fff',
      borderRadius: 8,
      alignItems: 'center',
    },
    content: { width: '50%', height: '100%' },
    circularProgressWithLabelContainer: {
      width: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    kpiContainer: {
      display: 'flex',
      gap: 5,
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      height: '95vh',
      border: 'none',
      width: '97vw',
      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 3rem',
      // maxWidth: '1000px',
      position: 'relative',
    },
    action: {
      cursor: 'pointer',
    },
    loader: {
      width: '70vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

const dataIntegrationValues = [
  { label: 'Telematics Provider', value: 'None' },
  { label: 'Tankkarte', value: 'None' },

  { label: 'Driver Card', value: 'None' },
];

const tireMgtData = [
  { label: 'Vehicle Manufacturer:', value: 'None' },
  { label: 'Tire Brands', value: 'None' },

  { label: 'Tire Service Provider ', value: 'Vergoelst' },
  // { label: 'Application Deadline: ', value: '31st Dec, 2022' },
];

const savingPotentials = 'savingPotentials';

const SavingPotentials = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const isTour = useSelector(setTourSelector);
  const theme = useTheme();
  const fleetRes = useSelector(fleetOverviewSelector);
  const fleetOverviewRes = useSelector(savingEstimateSelector);
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const treadDepths = ['<2mm', '>2mm', '>3mm', '>5mm', '>8mm', '>10mm'];
  const [isLoading, setIsLoading] = useState(true);

  const [configStatusChart, setConfigStatusChart] = useState({
    key: 'vehicle-overview',
    title: t('barChart.vehicleOverview'),
    labels: [
      t('common.configured'),
      t('common.partialConfig'),
      t('homePage.unconfigured'),
    ],
    datasets: [],
    chartType: {
      nature: 'pie',
      attr: 'none',
    },
  });

  const [trackedStatusChart, setTrackedStatusChart] = useState({
    key: 'tracked-overview',
    title: t('kpiList.telematics'),
    labels: [t('homePage.tracked'), t('homePage.untracked')],
    datasets: [],
    isTracked: false,
  });
  const [savingsAchieved, setSavingsAchieved] = useState({
    key: 'savings-achieved',
    title: t('common.co2Emission'),
    labels: [
      t('common.rrc_reduction'),
      t('common.savings_reduction'),
      t('common.saved_regrooved'),
    ],
    datasets: [],
    isTracked: false,
  });

  const [tireTypeChart, setTireTypeChart] = useState({
    key: 'tire',
    title: t('kpiList.size'),
    labels: [],
    datasets: null,
    chartType: {
      nature: 'pie',
      attr: 'none',
    },
    totalTiresInUse: null,
    totalTires: null,
  });

  const [tiresByDepth, setTiresByDepth] = useState({
    key: 'tire-depth',
    title: t('kpiList.tDepth'),
    labels: treadDepths,
    datasets: null,
    chartType: {
      nature: 'bar',
      attr: 'none',
    },
    tiresBetween3and5: [],
    tiresBelow3: [],
  });

  const [brandsAndLinesChart, setBrandsAndLinesChart] = useState({
    key: 'tire-brands',
    title: t('kpiList.brandsAndLines'),
    labels: [],
    datasets: null,
    chartType: {
      nature: 'bar',
      attr: 'none',
    },
  });

  const { getKpiList } = useKpiList();
  const list = getKpiList({ fleetOverviewRes, fleetRes });

  useEffect(() => {
    const getResource = async () => {
      try {
        const path = window.location.pathname.includes('saving-potentials');
        const res = await getSavingsEstimate(!path);

        if (res?.status === 200) {
          const data = res?.data?.resp;
          dispatch(setSavingEstimate(data));
        }
      } catch (error) {
        console.error('E20089', error);
        // dispatch(setMaintenance(true));
      }
    };
    getResource();
  }, [dispatch]);

  const getDataSets = async () => {
    if (fleetOverviewRes) {
      const {
        vehicle_overview: {
          tracked_count,
          unTracked_count,
          partiallyConfigured,
          fullyConfigured,
          unConfigured,
        },
        tires_overview: {
          td_counts: {
            between_2mm_3mm,
            between_3mm_5mm,
            between_5mm_8mm,
            between_8mm_10mm,
            gt_10mm,
            less_2mm,
          },
          brand_product_line,
          tire_size_counts,
        },
        costs,
      } = fleetOverviewRes;

      await setConfigStatusChart({
        ...configStatusChart,
        datasets: [
          {
            label: 'Data',
            data: [fullyConfigured, partiallyConfigured, unConfigured],
          },
        ],
      });

      await setTrackedStatusChart({
        ...trackedStatusChart,
        datasets: [{ label: 'Data', data: [tracked_count, unTracked_count] }],
      });

      await setTiresByDepth({
        ...tiresByDepth,
        datasets: [
          {
            label: 'Data',
            data: [
              less_2mm,
              between_2mm_3mm,
              between_3mm_5mm,
              between_5mm_8mm,
              between_8mm_10mm,
              gt_10mm,
            ],
          },
        ],
      });

      await setBrandsAndLinesChart({
        ...brandsAndLinesChart,
        labels: Object.keys(brand_product_line),
        datasets: [{ label: 'Data', data: Object.values(brand_product_line) }],
      });

      await setTireTypeChart({
        ...tireTypeChart,
        labels: Object.keys(tire_size_counts),
        datasets: [{ label: 'Data', data: Object.values(tire_size_counts) }],
      });
      const co2_saved_rrc_reduction =
        Math.round(
          costs?.tire_related_savings_potential?.co2_achieved
            ?.co2_saved_rrc_reduction * 1000
        ) / 1000;
      const co2_savings_retreads =
        Math.round(
          costs?.tire_related_savings_potential?.co2_achieved
            ?.co2_savings_retreads * 1000
        ) / 1000;
      const co2_saved_regroove =
        Math.round(
          costs?.tire_related_savings_potential?.co2_achieved
            ?.co2_saved_regroove * 1000
        ) / 1000;

      if (costs?.tire_related_savings_potential?.co2_achieved?.co2_total) {
        setSavingsAchieved({
          ...savingsAchieved,
          datasets: [
            {
              label: 'Data',
              data: [
                co2_saved_rrc_reduction,
                co2_savings_retreads,
                co2_saved_regroove,
              ],
            },
          ],
        });
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDataSets();
    // eslint-disable-next-line
  }, [fleetOverviewRes]);

  const calculateSize = items => {
    let response = 0;
    switch (items.length) {
      case 1:
        response = 12;
        break;
      case 2:
        response = 6;
        break;
      case 3:
        response = 4;
        break;
      case 4:
        response = 3;
        break;

      default:
        response = 3;
        break;
    }

    return response;
  };

  const steps = [
    {
      selector: '#saving-potential-tiles',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.savingPotentials.metricTile')}
          page={savingPotentials}
          finalAction='Finish'
          goTo={() => {
            dispatch(setTour(false));
          }}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
  ];

  let x = list;
  if (fleets?.length > 0) {
    x = x.filter(item => !itemsToExclude.includes(item.key));
  }

  const kpiList = fleets?.length > 0 ? x : list;

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <Loading />
      </div>
    );
  }

  const renderKpiList = () => {
    return kpiList.map(kpi => (
      <Grid item xs={12} md={3} lg={calculateSize(kpiList)} key={kpi.key}>
        <Box className={classes.kpiContainer}>
          <KpiTile
            icon={kpi.icon}
            primaryLabel={kpi.text}
            secondaryValue={kpi.secondaryValue}
            value={
              kpi.isCurrency
                ? i18next.language === 'de'
                  ? currencyFormatEUR(kpi.value, kpi.isDecimalValue)
                  : currencyFormatNonEU(kpi.value, kpi.isDecimalValue)
                : kpi.value
            }
          />
        </Box>
      </Grid>
    ));
  };

  const renderTabularDetails = () => {
    if (fleets?.length === 0) {
      return (
        <>
          <Grid item lg={6} sm={12}>
            <TabularDetailsBox
              title={t('common.dataIntegration')}
              content={dataIntegrationValues}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <TabularDetailsBox
              title={t('common.tireMgt')}
              content={tireMgtData}
            />
          </Grid>
        </>
      );
    }
  };

  const renderDoughnutChart = () => {
    if (fleets?.length > 0) {
      return (
        <Grid item lg={6} xs={12}>
          <HeaderBox title={t('barChart.vehicleOverview')}>
            <DoughnutChart
              labels={configStatusChart.labels}
              dataSets={configStatusChart.datasets}
              title={t('barChart.vehicleOverview')}
              showTotal={true}
            />
          </HeaderBox>
        </Grid>
      );
    }
  };

  const renderKpiTelemetics = () => {
    if (fleets?.length > 0) {
      return (
        <Grid item lg={6} xs={12}>
          <HeaderBox title={t('kpiList.telematics')}>
            <DoughnutChart
              labels={trackedStatusChart.labels}
              dataSets={trackedStatusChart.datasets}
              title={t('kpiList.telematics')}
              showTotal={true}
            />
          </HeaderBox>
        </Grid>
      );
    }
  };

  const renderTireTypeDetails = () => {
    if (fleets?.length > 0 && tireTypeChart.datasets) {
      return (
        <Grid item lg={6} xs={12}>
          <HeaderBox title={t('kpiList.size')}>
            <DoughnutChart
              labels={tireTypeChart.labels}
              dataSets={tireTypeChart.datasets}
              title={t('kpiList.size')}
              showTotal={true}
            />
          </HeaderBox>
        </Grid>
      );
    }
  };

  const renderTireDepthDetails = () => {
    if (fleets?.length > 0 && tiresByDepth.datasets) {
      return (
        <Grid item lg={6} xs={12}>
          <HeaderBox title={t('kpiList.tDepth')}>
            <BarChart
              labels={tiresByDepth.labels}
              dataSets={tiresByDepth.datasets}
              title={t('kpiList.tDepth')}
              indexAxis={'x'}
            />
          </HeaderBox>
        </Grid>
      );
    }
  };

  const renderBrandsAndLinesChart = () => {
    if (fleets?.length > 0 && brandsAndLinesChart.datasets) {
      return (
        <Grid item lg={6} xs={12}>
          <HeaderBox
            title={t('kpiList.brandsAndLines')}
            setOpen={setOpen}
            open={open}
          >
            <BarChart
              labels={brandsAndLinesChart.labels}
              dataSets={brandsAndLinesChart.datasets}
              title={t('kpiList.brandsAndLines')}
              indexAxis={'x'}
              width={700}
            />
          </HeaderBox>
        </Grid>
      );
    }
  };

  const renderSavingsAchieved = () => {
    if (savingsAchieved.datasets.length) {
      return (
        <Grid item lg={6} xs={12}>
          <HeaderBox
            title={`${t('common.co2Emission')} (${t('kpiList.tonnes')})`}
          >
            <DoughnutChart
              labels={savingsAchieved.labels}
              dataSets={savingsAchieved.datasets}
              title={t('common.co2Emission')}
              showTotal={true}
            />
          </HeaderBox>
        </Grid>
      );
    }
  };
  return (
    <div id='saving-potential-tiles'>
      <Breadcrumb />
      <Grid container spacing={2}>
        {renderKpiList()}
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        {renderTabularDetails()}
        {renderDoughnutChart()}
        {renderKpiTelemetics()}
        {renderTireTypeDetails()}
        {renderTireDepthDetails()}
        {renderBrandsAndLinesChart()}
        {renderSavingsAchieved()}
      </Grid>
      {/* {isGlobalComponentTourComplete && ( */}
      <Tour
        startAt={0}
        steps={steps}
        isOpen={isTour}
        accentColor={theme.palette.primary.main}
        onRequestClose={() => dispatch(setTour(false))}
        showNavigation={false}
        showButtons={false}
      />
      {/* )} */}
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
      >
        <Paper className={classes.paper}>
          <HeaderBox
            title={t('kpiList.brandsAndLines')}
            setOpen={setOpen}
            open={open}
          >
            <BarChart
              labels={brandsAndLinesChart.labels}
              dataSets={brandsAndLinesChart.datasets}
              title={t('kpiList.brandsAndLines')}
              indexAxis={'x'}
              height={400}
              width={1000}
            />
          </HeaderBox>
        </Paper>
      </Modal>
    </div>
  );
};

const HeaderBox = props => {
  const { title, children, setOpen, open } = props;
  const { classes } = useStyles();
  return (
    <Tile>
      <Box className={classes.header}>
        <Typography className={classes.heading}>{title}</Typography>
        {!!setOpen && (
          <Box className={classes.action} onClick={() => setOpen(!open)}>
            {!open ? <AspectRatio /> : <Close />}
          </Box>
        )}
      </Box>
      <Box className={classes.tabularDetailsBoxContainer}>{children}</Box>
    </Tile>
  );
};

const TabularDetailsBox = props => {
  const { title, content } = props;
  const { classes } = useStyles();
  return (
    <HeaderBox title={title}>
      {content?.map(({ label, value }) => (
        <Box className={classes.tabularDetailsBoxContent}>
          <Typography className={classes.label}>{label}</Typography>
          <Typography className={classes.value}>{value}</Typography>
        </Box>
      ))}
    </HeaderBox>
  );
};

export default SavingPotentials;

const itemsToExclude = [
  'avg-km-per-asset',
  'avg-fuel-consumption',
  'no-of-assets',
];

import { fork } from '@redux-saga/core/effects';
import onboardingSaga from './onborading/sagas';
import { telematicsSaga } from './telematicsProvider/sagas';
import { usersSaga } from './users/sagas';
import { fleetsSaga } from './fleets/sagas';

function* rootSaga() {
  yield fork(usersSaga);
  yield fork(onboardingSaga);
  yield fork(telematicsSaga);
  yield fork(fleetsSaga);
}

export default rootSaga;

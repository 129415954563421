import React, { useState } from 'react';
import { Button, Chip, IconButton, Typography } from '@mui/material';
import Box from '@mui/system/Box';
import { Cached } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TRACKED_LS, USER_LS, USER_ROLES } from 'utils/string_utils';
import { Link, useParams } from 'react-router-dom';
import { setTrackedVehiclesIdsSelector } from 'redux/fleets/ducks';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { setUserSelector } from 'redux/users/ducks';
import { useStyles, formatDate } from './TireConfig';

import TireGrooveModal from '../TireGrooveModal';
import InsightsIcon from '@mui/icons-material/Insights';
export const TireInformationBox = props => {
  const {
    data,
    setOpenUpdate,
    setOpenModifyTireModal,
    setOperation,
    setIsRegrooved,
  } = props;
  const trackedVehicles = useSelector(setTrackedVehiclesIdsSelector);
  const tracked = trackedVehicles || TRACKED_LS;
  const { classes: styles } = useStyles();
  const { t } = useTranslation();
  const userLs = JSON.parse(USER_LS);
  const userDetails = useSelector(setUserSelector) || userLs;
  const userIsAReporter = userDetails?.iam_role === USER_ROLES.reporter;
  const params = useParams();
  const { vehicle_id, fleet_id } = params;
  const [grooveOpen, setGrooveOpen] = useState(false);

  const handleCloseGroove = () => {
    setGrooveOpen(false);
  };
  const columns = [
    {
      key: 'mount_date',
      title: t('tireInventoryItems.titles.mountDate'),
    },
    {
      key: 'last_event_date',
      title: t('tireInventoryItems.titles.lastUpdate'),
    },
    {
      key: 'last_tread_depth',
      title: t('tireInventoryItems.titles.tDepth'),
    },
    {
      key: 'estimated_tread_depth',
      title: t('tireInventoryItems.titles.tDepthEst'),
    },
    {
      key: 'position',
      title: t('tireInventoryItems.titles.axlePosition'),
    },
    {
      key: 'mission_profile',
      title: t('tireInventoryItems.titles.mission_profile'),
    },
    {
      key: 'isRetread',
      title: 'Status',
    },
    {
      key: 'rfid',
      title: 'RFID',
    },
    {
      key: 'last_pressure',
      title: t('tireInventoryItems.titles.pressure'),
    },
    {
      key: 'dot',
      title: 'Dot (ww/yy)',
    },
  ];

  if (tracked?.includes(vehicle_id)) {
    columns.splice(4, 0, {
      key: 'mileage',
      title: 'Mileage',
    });
  }

  const unit = (key, value) => {
    switch (key) {
      case 'estimated_tread_depth':
      case 'last_tread_depth':
        return value ? `${parseFloat(value)} mm` : 'N/A';
      case 'mileage':
        return value !== null && value !== 0
          ? `${parseFloat(value).toFixed(2)} km`
          : 'Pending';
      case 'isRetread':
        return <Chip label={value ? 'Retreaded' : 'New'} />;
      case 'mount_date':
        return formatDate(value);
      case 'last_event_date':
        return formatDate(value);
      default:
        return value ?? 'N/A';
    }
  };

  const renderColumns = () => {
    return columns.map((column, i) => {
      return (
        <Box
          key={i}
          display='flex'
          flexDirection='column'
          gap={'10px'}
          width={'120px'}
        >
          <span className={styles.top}>{column.title}</span>
          <span className={styles.bottom} data-hook={`tire_${column.key}`}>
            {unit(column.key, data[column.key])}
          </span>
        </Box>
      );
    });
  };

  const renderTireOperations = () => {
    if (!userIsAReporter) {
      return (
        <Box
          width='100%'
          display='flex'
          justifyContent='space-evenly'
          gap={'10px'}
        >
          <Button
            variant='contained'
            color='primary'
            className={styles.btns}
            startIcon={<BiPencil />}
            onClick={() => setOpenUpdate(true)}
            data-hook='update-tire-button'
          >
            {t('tireConfigPage.updateTireBtn')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={styles.btns}
            startIcon={<BiPencil />}
            onClick={() => {
              setIsRegrooved(true);
            }}
          >
            {t('common.tireInventory.updateRegrooveHeading')}
          </Button>
          <Button
            variant='outlined'
            color='primary'
            className={styles.btns}
            startIcon={<Cached />}
            data-hook='change-tire-button'
            onClick={() => {
              setOperation('tire_change');
              setOpenModifyTireModal(true);
            }}
          >
            {t('tireConfigPage.changeTireBtn')}
          </Button>
          <Button
            variant='outlined'
            color='primary'
            className={styles.btns}
            startIcon={<BiTrash />}
            data-hook='unmount-tire-button'
            onClick={() => {
              setOperation('storage');
              setOpenModifyTireModal(true);
            }}
          >
            {t('tireConfigPage.unmountTireBtn')}
          </Button>
        </Box>
      );
    }
  };
  return (
    <>
      <Box display='flex' flexDirection='column' gap={'16px'}>
        <Box display='flex' alignItems='center' gap={'20px'}>
          <Typography>
            {data?.brand} {data?.product_line}
          </Typography>
          <Box className={styles.fleetIdContainer}>
            <Link
              className={styles.link}
              color='primary'
              to={`/tire-inventory/${fleet_id}/${data?.system_tire_id}`}
            >
              {data?.tire_id}
            </Link>
          </Box>
          {data?.grooves_tread_depth && (
            <IconButton
              aria-label='delete'
              color='primary'
              onClick={() => setGrooveOpen(true)}
            >
              <InsightsIcon />
            </IconButton>
          )}
        </Box>

        <Box display='flex' gap={'35px'} flexWrap='wrap'>
          {renderColumns()}
        </Box>
        {renderTireOperations()}
        {data?.grooves_tread_depth && (
          <TireGrooveModal
            open={grooveOpen}
            handleClose={handleCloseGroove}
            tire={data}
          />
        )}
      </Box>
    </>
  );
};

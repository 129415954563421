import React, { useState } from 'react';

import { Box, Fade, Modal, Paper, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  setChangeInFleet,
  fleetUpdatedSelector,
  fleetOverviewSelector,
  fetchFleets,
  fetchVehiclesForFleets,
} from 'redux/fleets/ducks';
import { setAccessTokenSelector } from 'redux/users/ducks';
import { useSelector, useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import { fleetCsvUpload } from 'services/fleet_service';
import { setNotification } from 'redux/notifications/ducks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { VEHICLE } from 'utils/string_utils';
import { useParams } from 'react-router-dom';
import CsvUploader from '../CsvUploader';
import AddSingleVehicle from './AddSingleVehicle';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 150,
      overflow: 'auto',
    },
    paper: {
      width: '770px',
      maxHeight: '710px',
      border: 'none',
      padding: '48px 98px 48px 98px',
      overflow: 'auto',
    },
    tab: {
      textTransform: 'unset',
    },
  };
});

const PLATE = 'reg_plate';

const VehicleUploads = props => {
  const {
    open,
    setOpen,
    vehicleType = null,
    title,
    headers,
    asset_type,
    header1,
    header2,
    flex_direction,
    page,
    setIsVehicleUpload = () => {},
    rows,
  } = props;
  const { classes } = useStyles();
  const overview = useSelector(fleetOverviewSelector);
  const fleets = overview?.fleets;
  const configs = overview?.general_configs;
  const access_token = useSelector(setAccessTokenSelector);
  // eslint-disable-next-line
  const [step, setStep] = useState(1);
  const params = useParams();
  const { fleet_id } = params;
  const [newFleet, setNewFleet] = useState(null);
  const [flag, setFlag] = useState(false);
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const fleetTracker = useSelector(fleetUpdatedSelector);
  const [dateError, setDateError] = useState(null);
  const [dateError1, setDateError1] = useState(null);
  const navigate = useNavigate();
  const [errors, setErrors] = useState({
    vin_chassis: null,
    reg_plate: null,
    odometer: null,
    tank_capacity: null,
  });
  const [values, setValues] = useState({
    fleet_name: '',
    location: '',
    name: '',
    reg_plate: '',
    internal_grouping: '',
    tank_capacity: '',
    vin_chassis: '',
    odometer_reading: '',
    inspection_date: null,
    safety_check_date: null,
    optimum_air_pressure: configs[vehicleType],
  });
  const [tabValue, setTabValue] = useState(0);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const disableCondition =
    dateError ||
    dateError1 ||
    Object.values(errors).some(error => error !== null) ||
    isSubmitting;

  const handleChange = e => {
    if (e.target.name === VEHICLE.VIN_CHASSIS) {
      if (e.target.value.length >= 17 && e.target.value.length <= 20) {
        setErrors({ ...errors, vin_chassis: null });
      }
    }
    if (e.target.name === PLATE) {
      if (e.target.value.length > 12) {
        setErrors({
          ...errors,
          reg_plate: t('singleVehicleUpload.regPlateCharsWarning'),
        });
      } else {
        setErrors({ ...errors, reg_plate: null });
      }
    }
    if (e.target.name === 'odometer_reading') {
      if (e.target.value < 0) {
        setErrors({ ...errors, odometer: 'Invalid entry' });
      } else {
        setErrors({ ...errors, odometer: null });
      }
    }
    if (e.target.name === VEHICLE.TANK) {
      if (e.target.value.includes('.')) {
        setErrors({ ...errors, tank_capacity: 'Enter Integers' });
      } else {
        setErrors({ ...errors, tank_capacity: null });
      }
    }

    if (e.target.id?.includes('pressure')) {
      if (parseFloat(e.target.value) > 15 || parseFloat(e.target.value) < 5) {
        setErrors({
          ...errors,
          optimum_air_pressure: {
            ...errors.optimum_air_pressure,
            [e.target.name]: t('common.pressureValueError'),
          },
        });
      } else {
        setErrors({
          ...errors,
          optimum_air_pressure: {
            ...errors.optimum_air_pressure,
            [e.target.name]: null,
          },
        });
      }
      setValues({
        ...values,
        optimum_air_pressure: {
          ...values.optimum_air_pressure,
          [e.target.name]: e.target.value,
        },
      });

      return;
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };
  const blurHandler = e => {
    if (e.target.name === VEHICLE.VIN_CHASSIS) {
      if (values[VEHICLE.VIN_CHASSIS].length < 17) {
        setErrors({
          ...errors,
          vin_chassis: `${
            vehicleType === VEHICLE.vehicle_type.TRUCK.toLowerCase() ||
            vehicleType === VEHICLE.vehicle_type.BUS.toLowerCase()
              ? 'Vin'
              : 'Chassis number'
          } ${t('singleVehicleUpload.vinCharsWarning')}`,
        });
      }
      if (values[VEHICLE.VIN_CHASSIS].length > 20) {
        setErrors({
          ...errors,
          vin_chassis: `${
            vehicleType === VEHICLE.vehicle_type.TRUCK.toLowerCase() ||
            vehicleType === VEHICLE.vehicle_type.BUS.toLowerCase()
              ? 'Vin'
              : 'Chassis number'
          } ${t('singleVehicleUpload.vinCharsWarning2')}`,
        });
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
    setStep(1);

    setValues({
      fleet_name: '',
      location: '',
      name: '',
      reg_plate: '',
      internal_grouping: '',
      tank_capacity: '',
      vin_chassis: '',
      odometer_reading: '',
      inspection_date: null,
      safety_check_date: null,
    });
    setNewFleet(null);
    setFlag(false);

    setErrors({
      vin_chassis: null,
      reg_plate: null,
      odometer: null,
    });
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    setFlag(true);
    setIsVehicleUpload(true);
    let obj = {
      axle_type: values.axle_type,
      steer_tire_size: values.steer_tire_size,
      drive_tire_size: values.drive_tire_size,
      tire_size: values.tire_size,
      inspection_date: values.inspection_date,
      safety_check_date: values.safety_check_date,
      odometer_reading: values.odometer_reading,
      // optimum_air_pressure: values?.optimum_air_pressure,
    };
    if (values.axle_type) {
      obj = { ...obj, optimum_air_pressure: values?.optimum_air_pressure };
    }
    const filteredObject = Object.entries(obj).reduce((acc, [key, value]) => {
      if (value !== null && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
    const isTruckOrBus =
      vehicleType === VEHICLE.vehicle_type.TRUCK ||
      vehicleType === VEHICLE.vehicle_type.BUS;
    const payload = {
      fleet_name: newFleet === null ? values.fleet_name : newFleet,
      location: values.location,
      assets: [
        {
          name: values.name,
          reg_plate: values.reg_plate,
          [VEHICLE.VIN_CHASSIS]: values.vin_chassis,
          internal_grouping: values.internal_grouping,
          vehicle_type: vehicleType.toUpperCase(),
          ...(isTruckOrBus && {
            tank_capacity: parseInt(values.tank_capacity),
          }),
          ...filteredObject,
        },
      ],
    };
    try {
      const res = await fleetCsvUpload(payload, access_token);
      if (res.status === 200) {
        const summary_ = res?.data?.resp?.[`vehicle_summary`];
        const updatedFleet = res?.data?.resp?.fleet?.fleet_id;
        if (summary_[0].upload_status === 'fail') {
          dispatch(
            setNotification(
              t('singleVehicleUpload.duplicateError'),
              'error',
              true
            )
          );
          setFlag(false);
        } else {
          if (fleet_id !== undefined) {
            dispatch(
              fetchVehiclesForFleets(
                access_token,
                updatedFleet,
                page,
                rows,
                () => {}
              )
            );
          } else {
            navigate('/fleets-data');
          }
          dispatch(
            setNotification(t('singleVehicleUpload.success'), 'success', true)
          );
          dispatch(setChangeInFleet(!fleetTracker));
          handleClose();
          dispatch(fetchFleets());
        }
      } else {
        setFlag(false);
        dispatch(setNotification('An error has occurred', 'error', true));
      }
    } catch (err) {
      setFlag(false);
      console.error(err);
      dispatch(setNotification('An error has occurred', 'error', true));
    } finally {
      setIsSubmitting(false);
      setIsVehicleUpload(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderTab = () => {
    if (tabValue === 0) {
      return (
        <AddSingleVehicle
          fleets={fleets}
          setNewFleet={setNewFleet}
          flag={flag}
          setDateError={setDateError}
          setDateError1={setDateError1}
          errors={errors}
          setErrors={setErrors}
          values={values}
          setValues={setValues}
          disableCondition={disableCondition}
          handleChange={handleChange}
          blurHandler={blurHandler}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          vehicleType={vehicleType}
          isSubmitting={isSubmitting}
        />
      );
    } else if (tabValue === 1) {
      return (
        <CsvUploader
          title={title}
          headers={headers}
          asset_type={asset_type}
          header1={header1}
          header2={header2}
          flex_direction={flex_direction}
          page={page}
        />
      );
    }
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
      data-hook='vehicle-upload-modal'
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Typography variant='h5' gutterBottom align='left'>
            {t('singleVehicleUpload.add')}{' '}
            {vehicleType === null
              ? t('singleVehicleUpload.vehicle')
              : vehicleType === VEHICLE.vehicle_type.TRUCK.toLowerCase()
              ? t('singleVehicleUpload.truck')
              : vehicleType === VEHICLE.vehicle_type.TRAILER
              ? t('singleVehicleUpload.trailer')
              : t('singleVehicleUpload.bus')}
          </Typography>
          <Box width='100%'>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor='primary'
              textColor='primary'
              variant='fullWidth'
              scrollButtons='auto'
              data-hook='vehicle-type-change-tab'
            >
              <Tab
                data-hook='single-upload-tab'
                label={t('singleVehicleUpload.single')}
                className={classes.tab}
              ></Tab>
              <Tab
                data-hook='multiple-upload-tab'
                label={t('singleVehicleUpload.multiple')}
                className={classes.tab}
              ></Tab>
            </Tabs>
          </Box>
          {renderTab()}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default VehicleUploads;

import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';

const App = ({
  dataSets,
  xAxis = {
    display: false,
  },
  yAxis = {
    display: false,
  },
  kpis,
  chartWidth,
  chartHeight,
}) => {
  const theme = useTheme();

  const colorScheme = [
    {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.main,
    },
    {
      borderColor: '#0D0D0D',
      backgroundColor: '#0D0D0D',
    },
    {
      borderColor: '#A3A5B3',
      backgroundColor: '#A3A5B3',
    },
    {
      borderColor: '#C5C4C2',
      backgroundColor: '#C5C4C2',
    },
    {
      borderColor: '#ACAEAF',
      backgroundColor: '#ACAEAF',
    },
  ];
  return (
    <LineChart
      width={chartWidth}
      height={chartHeight ?? 300}
      data={dataSets}
      margin={{
        top: 5,
        right: 30,
        left: yAxis.display ? 20 : 0,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray='3 3' stroke='#ccc' />
      <XAxis dataKey='name' />
      <YAxis
        label={{
          value: yAxis.display ? `${yAxis.text} (${xAxis.text})` : '',
          angle: -90,
          position: 'insideBottomLeft',
        }}
      />
      <Tooltip />
      <Legend />
      {kpis?.map((kpi, index) => (
        <Line
          key={index}
          type='monotone'
          dataKey={kpi}
          stroke={colorScheme[index].borderColor}
          activeDot={{ r: 8 }}
        />
      ))}
      {/* <Line type='monotone' dataKey='uv' stroke='#82ca9d' /> */}
    </LineChart>
  );
};

export default App;

App.propTypes = {
  dataSets: PropTypes.array,
  xAxis: PropTypes.object,
  yAxis: PropTypes.object,
  kpis: PropTypes.array,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
};

import React from 'react';
import { FormControlLabel, Switch, Typography } from '@mui/material';

const CustomSwitch = props => {
  const handleChange = (e, val) => {
    props.onChange(val, props.name);
  };
  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={props.checked || false}
            onChange={handleChange}
            name={props.name}
            color={'primary'}
          />
        }
        label={<Typography variant='h5'>{props.label}</Typography>}
      />
    </>
  );
};

export default CustomSwitch;

import React, { useState } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { makeStyles } from 'tss-react/mui';
import classes from './Plans.module.css';
import MotionWrap from 'wrapper/MotionWrap';

const useStyles = makeStyles()(theme => {
  return {
    grid: {
      marginTop: 80,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      flexWrap: 'wrap',
      [theme.breakpoints.between(1280, 1600)]: {
        gap: 50,
      },
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '100%',
      minWidth: 300,
      position: 'relative',
      [theme.breakpoints.down(1280)]: {
        width: 300,
      },
      [theme.breakpoints.up('xl')]: {
        height: 950,
      },
      [theme.breakpoints.down('xl')]: {
        height: 750,
      },
      [theme.breakpoints.up(2100)]: {
        height: 900,
      },
      [theme.breakpoints.up(2400)]: {
        height: 1020,
      },
      [theme.breakpoints.up(3200)]: {
        height: 1200,
      },
    },
    container: {
      width: '100%',
      padding: '0px 20px',
    },
    specialOffer: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      fontSize: 'clamp(14px, 1vw, 2.5rem)',
      marginTop: '30px',
    },
    subButton: {
      width: '100%',
      height: '63px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '0px 0px 4px 4px',
      border: 'none',
      color: '#fff',
      alignSelf: 'center',
      fontSize: 'clamp(14px, 1vw, 2.5rem)',
      cursor: 'pointer',
    },
    planHeading: {
      fontSize: 'clamp(18px, 1.2vw, 2.5rem)',
      fontWeight: '700',
      color: theme.palette.primary.main,
      marginTop: 0,
      marginBottom: '20px',
    },
    title: {
      fontWeight: 700,
      fontSize: 'clamp(20px, 2vw, 3rem)',
    },
    plusText: {
      color: theme.palette.primary.main,
      fontSize: 'clamp(18px, 1.5vw, 2.5rem)',
    },
    commonSpacing: {
      marginTop: 42,
    },
  };
});

const variants = {
  scale: { scale: [1, 1.2, 1.2, 1, 1] },
};

const Plans = () => {
  const theme = useTheme();
  const [highlightedBtn, setHighlightedBtn] = useState(1);
  const [btnClicked, setBtnClicked] = useState(false);
  const { classes: styles } = useStyles();
  const xxl = useMediaQuery(`(min-width:2900px)`);
  const { t } = useTranslation();

  const checkState = num => {
    setBtnClicked(true);
    setHighlightedBtn(num);
    setTimeout(() => {
      setBtnClicked(false);
    }, 1000);
  };

  return (
    <Box>
      <div className={classes.top}>
        <Typography variant='h3' className={styles.title}>
          Upgrade
        </Typography>
        <div className={classes.buttonGrp}>
          <button
            className={classes.btn1}
            onClick={() => checkState(1)}
            style={{
              zIndex: highlightedBtn === 1 ? 2 : 1,
              background:
                highlightedBtn === 1 ? theme.palette.primary.main : '#D9D9D9',
              color: highlightedBtn === 1 ? '#fff' : '',
            }}
          >
            {t('plansPage.subAnnual')}
          </button>
          <button
            className={classes.btn2}
            onClick={() => checkState(2)}
            style={{
              zIndex: highlightedBtn === 2 ? 2 : 1,
              background:
                highlightedBtn === 2 ? theme.palette.primary.main : '#D9D9D9',
              color: highlightedBtn === 2 ? '#fff' : '',
            }}
          >
            {t('plansPage.subMonthly')}
          </button>
        </div>
        <div className={`${classes.buttonGrp} ${classes.md}`}>
          <button
            className={`${classes.btn1}`}
            onClick={() => checkState(1)}
            style={{
              zIndex: highlightedBtn === 1 ? 2 : 1,
              background:
                highlightedBtn === 1 ? theme.palette.primary.main : '#D9D9D9',
              color: highlightedBtn === 1 ? '#fff' : '',
            }}
          >
            {t('plansPage.subAnnual')}
          </button>
          <button
            className={`${classes.btn2}`}
            onClick={() => checkState(2)}
            style={{
              zIndex: highlightedBtn === 2 ? 2 : 1,
              background:
                highlightedBtn === 2 ? theme.palette.primary.main : '#D9D9D9',
              color: highlightedBtn === 2 ? '#fff' : '',
            }}
          >
            {t('plansPage.subMonthly')}
          </button>
        </div>
      </div>
      <Grid container spacing={2} className={styles.grid}>
        <Grid item lg={4}>
          <Paper className={styles.paper} elevation={2}>
            <Box
              // marginTop={42}
              width={'100%'}
              display='flex'
              flexDirection='column'
              alignItems='center'
              className={styles.commonSpacing}
            >
              <h6 className={styles.planHeading}>Climate Aware</h6>
              <span className={classes.amount}>€0</span>
              <span className={classes.amountSubtitle}>
                {t('plansPage.perMonth')}
              </span>
            </Box>
            <Box className={styles.container} style={{ marginTop: 65 }}>
              <Box className={styles.bulletContainer}>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('plansPage.digitalTwin')}</p>
                </div>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('plansPage.savings')}</p>
                </div>
              </Box>
            </Box>
            <button className={styles.subButton} disabled>
              {t('plansPage.yourPlan')}
            </button>
          </Paper>
        </Grid>
        <Grid item lg={4}>
          <Paper className={styles.paper} elevation={2}>
            <Box
              width={'100%'}
              display='flex'
              flexDirection='column'
              alignItems='center'
              className={styles.commonSpacing}
            >
              <h6 className={styles.planHeading}>Climate Friendly</h6>
              <motion.span
                className={classes.amount}
                animate={btnClicked ? 'scale' : ''}
                variants={variants}
              >{`${highlightedBtn === 1 ? 99 : 9}€ p. vehicle`}</motion.span>
              <span className={classes.amountSubtitle}>
                {highlightedBtn === 2
                  ? t('plansPage.monthBill')
                  : t('plansPage.annualBill')}
              </span>
              <span className={styles.specialOffer}>
                {t('plansPage.specialOffer')}
              </span>
            </Box>
            <Box className={styles.container} style={{ marginTop: 50 }}>
              <p className={classes.intro}>
                {t('plansPage.all')} "Climate Aware" {t('plansPage.features')}{' '}
                <span className={styles.plusText}>PLUS</span>
              </p>
              <Box className={styles.bulletContainer}>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('common.tireMgt')}</p>
                </div>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('plansPage.dataIntegration')}</p>
                </div>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('plansPage.tireRecommendations')}</p>
                </div>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('plansPage.serviceCenter')}</p>
                </div>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('plansPage.deminimis')}</p>
                </div>
              </Box>
            </Box>
            <button className={styles.subButton}>
              {t('plansPage.select')}
            </button>
          </Paper>
        </Grid>
        <Grid item lg={4}>
          <Paper className={styles.paper} elevation={2}>
            <div className={classes.enterprise}>{t('plansPage.tailored')}</div>
            <Box
              width={'100%'}
              display='flex'
              flexDirection='column'
              alignItems='center'
              position={'absolute'}
              top={xxl ? '7rem' : '6rem'}
              left='0'
              bottom='0'
              height='fit-content'
            >
              <span className={classes.amount}>Enterprise</span>
              <span className={classes.amountSubtitle}>
                {t('plansPage.customizedFeatures')}
              </span>
            </Box>
            <Box className={styles.container} style={{ marginTop: 180 }}>
              <p className={classes.intro}>{t('plansPage.onRequest')}</p>
              <Box className={styles.bulletContainer}>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('plansPage.featureRequests')}</p>
                </div>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('plansPage.customTelematics')}</p>
                </div>
                <div className={classes.bullets}>
                  <div className={classes.check}>
                    <Check color='primary' />
                  </div>
                  <p>{t('plansPage.installation')}</p>
                </div>
              </Box>
            </Box>
            <button className={styles.subButton}>
              {t('plansPage.contact')}
            </button>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MotionWrap(
  Plans,
  { opacity: 0 },
  { opacity: 1 },
  { opacity: 0 }
);

import React from 'react';
import { Box, Button, Card, Skeleton, Typography } from '@mui/material';
import Tile from 'components/Tile';
import Tasks from './Tasks';
import * as useClasses from './const';
import { useTranslation } from 'react-i18next';
import { setUserSelector } from 'redux/users/ducks';
import { USER_LS, USER_ROLES } from 'utils/string_utils';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function ActionsContainer(props) {
  const { classes } = useClasses.style();

  const { fleetPresent, loadingTasks, isLoading } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const userLs = JSON.parse(USER_LS);
  const userDetails = useSelector(setUserSelector) || userLs;
  const userIsAReporter = userDetails?.iam_role === USER_ROLES.reporter;

  return (
    <Card elevation={0}>
      <Tile infoComponent={t('tileToolTip.home.actionsRequired')}>
        <Box className={classes.header}>
          <Typography
            variant='subtitle1'
            gutterBottom
            fontWeight={900}
            data-hook={'home-actions'}
          >
            {t('homePage.nextActions')}
          </Typography>
        </Box>

        {fleetPresent ? (
          loadingTasks ? (
            <Box className={classes.isVehicleDataLoadingContainer}>
              <Box
                className={classes.topSkeleton}
                data-hook={'home-actions-loader'}
              >
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  className={classes.actionRequiredTableSkeleton}
                />
              </Box>
              <Box className={classes.commonSkeleton}>
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  className={classes.actionRequiredTableSkeleton}
                />
              </Box>
              <Box className={classes.commonSkeleton}>
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  className={classes.actionRequiredTableSkeleton}
                />
              </Box>
              <Box className={classes.commonSkeleton}>
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  className={classes.actionRequiredTableSkeleton}
                />
              </Box>
              <Box className={classes.commonSkeleton}>
                <Skeleton
                  variant='rectangular'
                  height='100%'
                  className={classes.actionRequiredTableSkeleton}
                />
              </Box>
            </Box>
          ) : (
            <Tasks data-hook={'available-tasks'} loading={isLoading} />
          )
        ) : (
          <Card className={classes.card}>
            <Typography
              variant='h3'
              className={classes.completeProfileTitle}
              data-hook={'complete-company-profile-text'}
            >
              {t('homePage.completeYourCompanyProfile')}
            </Typography>
            <Typography
              className={classes.completeProfileDesc}
              data-hook={'complete-company-profile-desc'}
            >
              {t('homePage.completeYourCompanyProfileDesc')}
            </Typography>

            {!userIsAReporter && (
              <Button
                variant='contained'
                color='primary'
                size='large'
                className={classes.btn}
                data-hook='configure-fleet-button'
                onClick={() => navigate('/fleets-data')}
              >
                {t('homePage.configureYourFleet')}
              </Button>
            )}
          </Card>
        )}
      </Tile>
    </Card>
  );
}

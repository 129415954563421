const INITIAL_STATE = {
  provider: 'transics',
  editData: null,
  formData: null,
  telematicsData: null,
};

export const TelematicsProviderActionTypes = {
  SET_EDIT_DATA: 'SET_EDIT_DATA',
  SET_FORM_DATA: 'SET_FORM_DATA',
  SET_PROVIDER: 'SET_PROVIDER',
  SET_TELEMATICS_DATA: 'SET_TELEMATICS_DATA',
  FETCH_TELEMATICS_DATA: 'FETCH_TELEMATICS_DATA',
};

export const setEditData = data => ({
  type: TelematicsProviderActionTypes.SET_EDIT_DATA,
  payload: { data },
});
export const setFormData = data => ({
  type: TelematicsProviderActionTypes.SET_FORM_DATA,
  payload: { data },
});
export const setProvider = value => ({
  type: TelematicsProviderActionTypes.SET_PROVIDER,
  payload: { value },
});
export const setTelematicsData = telematicsData => ({
  type: TelematicsProviderActionTypes.SET_TELEMATICS_DATA,
  payload: { telematicsData },
});

export const fetchTelematicsData = () => ({
  type: TelematicsProviderActionTypes.FETCH_TELEMATICS_DATA,
});

export const telematicsProviderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TelematicsProviderActionTypes.SET_EDIT_DATA:
      return { ...state, editData: action.payload.data };

    case TelematicsProviderActionTypes.SET_FORM_DATA:
      return { ...state, formData: action.payload.data };

    case TelematicsProviderActionTypes.SET_PROVIDER:
      return { ...state, provider: action.payload.value };
    case TelematicsProviderActionTypes.SET_TELEMATICS_DATA:
      return { ...state, telematicsData: action.payload.telematicsData };
    default:
      return state;
  }
};

export const setEditDataSelector = state => state.telematics.editData;
export const setFormDataSelector = state => state.telematics.formData;
export const setProviderSelector = state => state.telematics.provider;
export const telematicsDataSelector = state => state.telematics.telematicsData;

import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

function LinearProgressWithLabel(props) {
  return (
    <Box display='flex' alignItems='center'>
      <Box width='100%' mr={1}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant='body2' color='textSecondary'>{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
const useStyles = makeStyles()(theme => {
  return {
    root: {
      width: '100%',
    },
  };
});

export default function ProgressBar({ progress }) {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={progress} />
    </div>
  );
}

import React, { useState } from 'react';
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/system/Box';
import { UnfoldMore, Visibility, VisibilityOff } from '@mui/icons-material';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inviteUser } from 'services/auth_service';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorkforceQuery, setAccessTokenSelector } from 'redux/users/ducks';
import { setNotification } from 'redux/notifications/ducks';
import { USER_ROLES } from 'utils/string_utils';
import LoadAnimation from 'components/LoadAnimation';
import CustomTextField from 'components/custom/CustomTextField';
import { access_type_map } from 'utils/helper';
import { AssignResourcesModal } from '../AssetsWhitelisting/BulkUploadModal';

const useStyles = makeStyles()(theme => {
  return {
    inviteBtn: {
      textTransform: 'capitalize',
      fontSize: 18,
      background: '#e0e0e0',
      color: '#000',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    table: {
      marginTop: 20,
    },
    modalHead: {
      opacity: 0.5,
      marginBottom: 20,
    },
    submit: {
      outline: 'none',
      border: 'none',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      maxHeight: 45,
      padding: 10,
      borderRadius: 5,
      fontSize: 18,
      cursor: 'pointer',
      transition:
        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

      '&:disabled': { backgroundColor: 'grey' },
      '&:hover': { backgroundColor: '#49741c' },
    },
    closeBtn: {
      cursor: 'pointer',
    },
    grid: {
      width: '80%',
      backgroundColor: 'white',
    },
    input: {
      backgroundColor: '#fff',
    },
    nav: {
      textTransform: 'capitalize',
      textDecoration: 'none',
      color: 'inherit',
      fontSize: 'clamp(16px, 1vw, 2.5rem)',
    },
    crumb: {
      marginBottom: theme.spacing(3),
    },
    inviteBtnText: {
      cursor: 'pointer',
      fontSize: 'clamp(16px, 1vw, 2.5rem)',
    },
  };
});

const Invite = () => {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const access_token = useSelector(setAccessTokenSelector);

  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    first_name: '',
    last_name: '',
    email: '',
    access_type: '',
    iam_role: '',
    depot: '',
    password: '',
    locale: '',
  });
  const [showPassword, setShowPassword] = useState(false);

  const accessTypes = ['WEB_AND_MOBILE', 'WEB_ONLY', 'MOBILE_ONLY'];
  const roles = [USER_ROLES.service, USER_ROLES.reporter, USER_ROLES.admin];

  const valueChangeHandler = e => {
    setFormVal({
      ...formVal,
      [e.target.name]: e.target.value,
    });
  };

  const inviteUserHandler = async e => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const res = await inviteUser(formVal, access_token);
      if (res.status === 200) {
        dispatch(fetchWorkforceQuery());
        setUserId(res?.data?.resp?.user_id);
        setUsername(res?.data?.message?.split(' ')[0]);
        setStep(1);
        dispatch(
          setNotification('Invitation sent successfully!', 'success', true)
        );
      } else {
        dispatch(setNotification(res?.message, 'error', true));
      }
    } catch (error) {
      console.error('E20035', error);
      dispatch(
        setNotification('An error occurred. Please try again', 'error', true)
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderLocales = () => {
    const locales = ['en', 'de', 'pl'];
    return locales.map(local => {
      return (
        <MenuItem key={local} value={local}>
          {local}
        </MenuItem>
      );
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const renderSteps = () => {
    if (step === 0) {
      return (
        <>
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            padding={2}
          >
            <Typography variant='h5'>{t('userManagement.invite')}</Typography>
          </Box>
          <Divider />
          <Box padding={3}>
            <Typography className={classes.modalHead} variant='h6'>
              {t('userManagement.inviteDetails')}
            </Typography>
            <form
              id='invite-form'
              onSubmit={inviteUserHandler}
              className={classes.form}
            >
              <CustomTextField
                label={t('userManagement.firstName')}
                name={'first_name'}
                id={'first_name'}
                value={formVal['first_name']}
                onChange={valueChangeHandler}
                required
                variant={'outlined'}
                fullWidth
              />
              <CustomTextField
                label={t('userManagement.lastName')}
                name={'last_name'}
                id={'last_name'}
                value={formVal['last_name']}
                onChange={valueChangeHandler}
                required
                variant={'outlined'}
                fullWidth
              />
              <CustomTextField
                label={t('userManagement.email')}
                name={'email'}
                id={'email'}
                value={formVal['email']}
                onChange={valueChangeHandler}
                required
                type={'email'}
                variant={'outlined'}
                fullWidth
              />
              <CustomTextField
                variant='outlined'
                select
                label={t('userManagement.role')}
                id='iam_role'
                name='iam_role'
                required
                value={formVal['iam_role']}
                onChange={valueChangeHandler}
                SelectProps={{ IconComponent: () => null }}
                InputProps={{ endAdornment: <UnfoldMore /> }}
              >
                {roles.map(role => (
                  <MenuItem key={role} value={role}>
                    {role[0].toUpperCase() + role.slice(1)}
                  </MenuItem>
                ))}
              </CustomTextField>
              <CustomTextField
                variant='outlined'
                select
                label={t('userManagement.accessType')}
                id='access_type'
                name='access_type'
                required
                value={formVal['access_type']}
                onChange={valueChangeHandler}
                SelectProps={{ IconComponent: () => null }}
                InputProps={{ endAdornment: <UnfoldMore /> }}
              >
                {accessTypes.map(item => (
                  <MenuItem key={item} value={item}>
                    {access_type_map[item]}
                  </MenuItem>
                ))}
              </CustomTextField>
              <CustomTextField
                label={t('userManagement.depot')}
                name={'depot'}
                id={'depot'}
                value={formVal['depot']}
                onChange={valueChangeHandler}
                required
                variant={'outlined'}
                fullWidth
              />
              <FormControl fullWidth variant='outlined'>
                <InputLabel htmlFor='outlined-adornment-password'>
                  {t('userSettingsPage.password')}
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  margin={'normal'}
                  label={t('userSettingsPage.password')}
                  name={'password'}
                  required
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        edge='end'
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  value={formVal['password']}
                  onChange={valueChangeHandler}
                  fullWidth
                />
              </FormControl>
              <CustomTextField
                label={t('userManagement.locale')}
                name={'locale'}
                id={'locale'}
                select
                value={formVal['locale']}
                onChange={valueChangeHandler}
                required
                variant={'outlined'}
                SelectProps={{ IconComponent: () => null }}
                InputProps={{ endAdornment: <UnfoldMore /> }}
                fullWidth
              >
                {renderLocales()}
              </CustomTextField>
              <Box display='flex' gap={'5px'} alignSelf='flex-end'>
                <Button
                  disableElevation
                  variant='contained'
                  className={classes.inviteBtn}
                  onClick={() => navigate(-1)}
                >
                  {t('common.back')}
                </Button>
                <button
                  variant='contained'
                  color='primary'
                  form='invite-form'
                  disabled={isSubmitting}
                  className={classes.submit}
                  style={{ width: isSubmitting ? 105 : 'initial' }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={30} color='inherit' />
                  ) : (
                    t('userManagement.inviteBtn')
                  )}
                </button>
              </Box>
            </form>
          </Box>
        </>
      );
    } else if (step === 1) {
      return (
        <Box padding={3}>
          <Typography className={classes.modalHead} variant='h6'>
            {t('userManagement.assignResourceDetails')}
          </Typography>
          <Button fullWidth onClick={() => setOpen(true)}>
            {t('userManagement.assignResources')}
          </Button>
        </Box>
      );
    }
  };

  return (
    <>
      <Breadcrumbs
        className={classes.crumb}
        separator='>'
        aria-label='breadcrumb'
      >
        <NavLink
          color='inherit'
          variant='text'
          to='/settings'
          className={classes.nav}
        >
          {t('tabs.settings')}
        </NavLink>

        <Typography className={classes.inviteBtnText} color='textPrimary'>
          {t('userManagement.inviteBtn')}
        </Typography>
      </Breadcrumbs>
      <Box
        borderRadius={5}
        height='auto'
        width='100%'
        bgcolor='white'
        display='flex'
        flexDirection='column'
      >
        {renderSteps()}
      </Box>
      <AssignResourcesModal
        open={open}
        setOpen={setOpen}
        bulkUpload={false}
        userId={userId}
        userName={username}
      />
      <LoadAnimation loading={isLoading} setLoading={setIsLoading} />
    </>
  );
};

export default Invite;

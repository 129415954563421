import { Grid, Box, Typography } from '@mui/material';
import steer_logo from 'assets/svg/home/rec_steer.svg';
import trailer_logo from 'assets/svg/home/rec_trailer.svg';
import rec_plus from 'assets/svg/home/rec_plus.svg';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

export const TireRecommendations = props => {
  const { t } = useTranslation();
  const { tireNames, active } = props;
  const [activeTires, setActiveTires] = useState({});

  useEffect(() => {
    if (active) {
      const activeTires = tireNames[active];
      setActiveTires(activeTires);
    }
  }, [tireNames, active]);

  return (
    <>
      {Object.keys(activeTires).length && (
        <Grid container spacing={2} style={{ marginTop: '10px' }}>
          <Grid item xs={12} md={5}>
            <Box
              style={{
                display: 'flex',
                width: '100%',
                padding: '12px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
                border: '1px solid var(--default-gray-200, #E5E7EB)',
                background: ' var(--Light-grey, #F6F9F7)',
              }}
            >
              <Box mx='0.5rem'>
                <img src={steer_logo} alt='steer' />
              </Box>
              <Typography variant='h6' fontWeight='bold' gutterBottom>
                <span style={{ color: '#6B7280' }}>
                  {t('tireRecommendation.header.steerBrand')}
                </span>
                :
                {activeTires.common_tires.steer
                  ? activeTires.common_tires.steer
                  : t('common.na')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={2}>
            <Box
              style={{
                display: 'flex',
                width: '100%',
                padding: '12px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={rec_plus} alt='plus' />
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              style={{
                display: 'flex',
                width: '100%',
                padding: '12px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
                border: '1px solid var(--default-gray-200, #E5E7EB)',
                background: ' var(--Light-grey, #F6F9F7)',
              }}
            >
              <Box mx='0.2rem'>
                <img src={trailer_logo} alt='trailer' />
              </Box>
              <Typography variant='h6' fontWeight='bold' gutterBottom>
                <span style={{ color: '#6B7280' }}>
                  {t('tireRecommendation.header.driveBrand')}{' '}
                </span>
                :{' '}
                {activeTires.common_tires.drive
                  ? activeTires.common_tires.drive
                  : t('common.na')}
              </Typography>
            </Box>
          </Grid>
          {activeTires.common_tires.trailer && (
            <Grid item xs={12} md={5}>
              <Box
                style={{
                  display: 'flex',
                  width: '100%',
                  padding: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '12px',
                  border: '1px solid var(--default-gray-200, #E5E7EB)',
                  background: ' var(--Light-grey, #F6F9F7)',
                }}
              >
                <Box mx='0.5rem'>
                  <img src={trailer_logo} alt='trailer' />
                </Box>
                <Typography variant='h6' fontWeight='bold' gutterBottom>
                  <span style={{ color: '#6B7280', margin: 10 }}>
                    {t('tireRecommendation.header.trailerBrand')}
                  </span>
                  :{' '}
                  {activeTires.common_tires.trailer
                    ? activeTires.common_tires.trailer
                    : t('common.na')}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

const INITIAL_STATE = {
  companyProfile: null,
  fleetProfile: null,
  tireManagement: null,
  tireService: null,
  tireBrand: [],
  organizationProfile: null,
};

export const OnboardingActionTypes = {
  SET_COMPANY_PROFILE: 'SET_COMPANY_PROFILE',
  SET_FLEET_PROFILE: 'SET_FLEET_PROFILE',
  SET_TIRE_MANAGEMENT_PROFILE: 'SET_TIRE_MANAGEMENT_PROFILE',
  SET_TIRE_SERVICE: 'SET_TIRE_SERVICE',
  SET_TIRE_BRAND: 'SET_TIRE_BRAND',
  ORGANIZATION_PROFILE: {
    GET: 'GET_ORGANIZATION_PROFILE',
    SET: 'SET_ORGANIZATION_PROFILE',
    UPDATE_TIRE_CONFIG: 'UPDATE_TIRE_CONFIG',
    UPDATE_TRAILER_CONFIG: 'UPDATE_TRAILER_CONFIG',
    UPDATE_TRUCK_CONFIG: 'UPDATE_TRUCK_CONFIG',
    UPDATE_BUS_CONFIG: 'UPDATE_BUS_CONFIG',
    UPDATE_ORGANIZATION_DATA: 'UPDATE_ORGANIZATION_DATA',
    SET_ORGANIZATION_DATA: 'SET_ORGANIZATION_DATA',
    SET_TIRE_CONFIG: 'SET_TIRE_CONFIG',
    SET_TRAILER_CONFIG: 'SET_TRAILER_CONFIG',
    SET_TRUCK_CONFIG: 'SET_TRUCK_CONFIG',
    SET_BUS_CONFIG: 'SET_BUS_CONFIG',
  },
};

export const setCompanyProfile = value => ({
  type: OnboardingActionTypes.SET_COMPANY_PROFILE,
  payload: { value },
});
export const setFleetProfile = value => ({
  type: OnboardingActionTypes.SET_FLEET_PROFILE,
  payload: { value },
});
export const setTireManagementProfile = value => ({
  type: OnboardingActionTypes.SET_TIRE_MANAGEMENT_PROFILE,
  payload: { value },
});
export const setTireService = value => ({
  type: OnboardingActionTypes.SET_TIRE_SERVICE,
  payload: { value },
});
export const setTireBrand = value => ({
  type: OnboardingActionTypes.SET_TIRE_BRAND,
  payload: { value },
});

export const onboardingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OnboardingActionTypes.SET_COMPANY_PROFILE:
      return { ...state, companyProfile: action.payload.value };
    case OnboardingActionTypes.SET_FLEET_PROFILE:
      return { ...state, fleetProfile: action.payload.value };
    case OnboardingActionTypes.SET_TIRE_MANAGEMENT_PROFILE:
      return { ...state, tireManagement: action.payload.value };
    case OnboardingActionTypes.SET_TIRE_SERVICE:
      return { ...state, tireService: action.payload.value };
    case OnboardingActionTypes.SET_TIRE_BRAND:
      return { ...state, tireBrand: action.payload.value };
    case OnboardingActionTypes.ORGANIZATION_PROFILE.SET:
      return { ...state, organizationProfile: action.payload };
    case OnboardingActionTypes.ORGANIZATION_PROFILE.SET_ORGANIZATION_DATA:
      return {
        ...state,
        organizationProfile: {
          ...state.organizationProfile,
          organization_data: action.payload,
        },
      };
    case OnboardingActionTypes.ORGANIZATION_PROFILE.SET_TIRE_CONFIG:
      return {
        ...state,
        organizationProfile: {
          ...state.organizationProfile,
          tire_management_data: action.payload,
        },
      };
    case OnboardingActionTypes.ORGANIZATION_PROFILE.SET_TRUCK_CONFIG:
      return {
        ...state,
        organizationProfile: {
          ...state.organizationProfile,
          general_configs: {
            ...state.organizationProfile.general_configs,
            truck: action.payload,
          },
        },
      };
    case OnboardingActionTypes.ORGANIZATION_PROFILE.SET_BUS_CONFIG:
      return {
        ...state,
        organizationProfile: {
          ...state.organizationProfile,
          general_configs: {
            ...state.organizationProfile.general_configs,
            bus: action.payload,
          },
        },
      };
    case OnboardingActionTypes.ORGANIZATION_PROFILE.SET_TRAILER_CONFIG:
      return {
        ...state,
        organizationProfile: {
          ...state.organizationProfile,
          general_configs: {
            ...state.organizationProfile.general_configs,
            trailer: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export const setOnboardingSelector = state => state.onboarding;

import React, { useCallback, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import AppBar from '@mui/material/AppBar';

import { Box, Button, Toolbar, Tooltip, Typography } from '@mui/material';
import ExploreIcon from '@mui/icons-material/Explore';
import Layout from 'components/Layout';
import { setUserSelector, setDatabaseData, setTour } from 'redux/users/ducks';

import * as CONST from './const';

import LanguageSwitcher from 'components/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import { USER_LS, USER_ROLES } from 'utils/string_utils';
import { CgArrowLongUpL } from 'react-icons/cg';
import { ReactComponent as Settings } from 'assets/svg/common/settings.svg';
import i18next from 'i18next';
import { HomeSearchBar } from '../Home/HomeSearchBar';
import { TbArrowGuide } from 'react-icons/tb';

const Dashboard = ({ children }) => {
  const { classes } = CONST.useStyles();
  const userLs = JSON.parse(USER_LS);
  const dispatch = useDispatch();
  const user = useSelector(setUserSelector) || userLs;
  const userIsAReporter = user?.iam_role === USER_ROLES.reporter;

  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.is_onboarded) {
      dispatch(setDatabaseData(user.customer_id));
    } else {
      dispatch(setDatabaseData('myFirstDatabase'));
    }
  }, [user, dispatch]);

  const renderSubsciptionButton = useCallback(() => {
    if (userIsAReporter) {
      return (
        <Typography variant='h6' className={classes.header1}>
          Read-only
        </Typography>
      );
    }

    return (
      <Button
        variant='contained'
        color='primary'
        id='eighth-step'
        onClick={() => navigate('/plans')}
        startIcon={<CgArrowLongUpL />}
        className={classes.upgradeBtn}
      >
        {t('subscriptionBtn')}
      </Button>
    );
  }, [userIsAReporter, classes, navigate, t]);

  return (
    <Layout>
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Box className={classes.headerContainer}>
              <Box />

              <Box alignSelf='flex-end' className={classes.subscriptionButton}>
                <Button
                  variant='outlined'
                  color='primary'
                  id='guide'
                  onClick={() => navigate('/guides')}
                  startIcon={<TbArrowGuide />}
                  className={classes.upgradeBtn}
                >
                  {t(`guidesPage.heading`)}
                </Button>
                <HomeSearchBar />
                {renderSubsciptionButton()}
                <Box display={'flex'} alignItems='center'>
                  <Tooltip title={t('common.pageTour')}>
                    <ExploreIcon
                      color='primary'
                      className={classes.tourIcon}
                      onClick={() => dispatch(setTour(true))}
                    />
                  </Tooltip>
                </Box>
                <Box>
                  <LanguageSwitcher flag={i18next.language} />
                </Box>
                <NavLink to='/settings'>
                  <Settings width={35} />
                </NavLink>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </Layout>
  );
};

export default React.memo(Dashboard);

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { setParameters } from 'redux/fleets/ducks';
import { useNavigate } from 'react-router-dom';
const useStyles = makeStyles()(() => {
  return {
    tileStyles: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px 2px 16px 16px',
      borderRadius: 12,
      border: '1px solid var(--default-gray-200, #E5E7EB)',
    },
    iconStyles: {
      height: 40,
      width: 40,
    },
    container: {
      transition: 'transform 0.2s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  };
});

export const VehicleOverviewCount = ({
  showIcon,
  src,
  count,
  name,
  size,
  identifier,
  category,
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderIcon = () => {
    if (!showIcon) {
      return;
    }
    return src;
  };

  const boxClickHandler = () => {
    if (identifier || identifier === 0) {
      dispatch(
        setParameters({
          vehicleTabValue: identifier,
          vehicleLocation: category,
          accessing: 'ONLY_VEHICLE',
        })
      );
      navigate('/overview/vehicle');
    }
  };

  return (
    <Grid
      item
      xs={size || 12}
      md={size || 4}
      onClick={boxClickHandler}
      className={identifier !== undefined && classes.container}
    >
      <Box className={classes.tileStyles}>
        <Box>{renderIcon()}</Box>
        <Box mx='1rem' width='100%'>
          <Typography
            variant='h6'
            gutterBottom
            color='textSecondary'
            data-hook={`${name}_title`}
            align='center'
            style={{
              wordWrap: 'break-word',
            }}
          >
            {name}
          </Typography>
          <Typography
            variant='subtitle1'
            fontWeight='bold'
            gutterBottom
            align='center'
          >
            {count}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

VehicleOverviewCount.propTypes = {
  name: PropTypes.string,
  src: PropTypes.elementType,
  count: PropTypes.number,
  showIcon: PropTypes.string || PropTypes.any,
  size: PropTypes.string || PropTypes.number,
  identifier: PropTypes.string,
  category: PropTypes.string,
};

import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import classes from './DrawerFooter.module.css';
import logo from 'assets/images/app_logo.svg';
import { makeStyles } from 'tss-react/mui';
import {
  ImprintLink,
  PrivacyPolicyLink,
  TnCLink,
} from 'pages/LoginPage/LoginPage';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles()(theme => {
  return {
    btn: {
      outline: 'none',
      border: 'none',
      backgroundColor: 'inherit',
      fontSize: 'inherit',
      color: theme.palette.primary.main,
      cursor: 'pointer',
      marginTop: '10px',
      padding: 0,
    },
  };
});

const DrawerFooter = props => {
  const { classes: styles } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box>
      <div className={classes.FooterWrapper}>
        {process.env.REACT_APP_THEME !== undefined ? (
          process.env.REACT_APP_THEME !== 'default' ? (
            <Box
              display='flex'
              flexDirection='row'
              justifyContent={'flex-start'}
            >
              <p>
                <span>Powered by &nbsp;</span>
              </p>{' '}
              <img src={logo} width={130} alt='logo' />
            </Box>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        <p>
          <div>
            Version:{' '}
            <span>
              {' '}
              {process.env.REACT_APP_VERSION}.
              {process.env.REACT_APP_GIT_VERSION}
            </span>
          </div>

          <button className={styles.btn} onClick={() => navigate('/whats-new')}>
            {t('common.changelog')}
          </button>

          <br />

          <Box
            width={'100%'}
            display='flex'
            justifyContent='space-between'
            flexWrap={'wrap'}
            gap={'4px'}
            mt={2}
          >
            <ImprintLink />
            <TnCLink />

            <PrivacyPolicyLink />
          </Box>
          <br />
        </p>
      </div>
    </Box>
  );
};

export default DrawerFooter;

import { Backdrop, Fade, Modal, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { makeStyles } from 'tss-react/mui';
import front from 'assets/info_graph_2/front.png';
import first from 'assets/info_graph_2/first.png';
import second from 'assets/info_graph_2/second.png';
import third from 'assets/info_graph_2/third.png';
import rear from 'assets/info_graph_2/rear.png';
import { useTheme } from '@mui/material';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      padding: '48px 98px 48px 98px',

      borderRadius: '32px',
    },
    groove_bottom: {
      height: 60,
      backgroundColor: '#0a0b0c',
    },
    groove_main: {
      width: 25,

      height: '351px',
    },
    groove_green: {
      height: 140,
      backgroundColor: theme.palette.primary.main,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      transition: 'height 0.2s',
      opacity: 0.7,
      '&:hover': {
        height: 135,
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
      },
    },
    groove_yellow: {
      height: 140,
      backgroundColor: 'yellow',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      transition: 'height 0.2s',
      opacity: 0.7,
      '&:hover': {
        height: 135,
        backgroundColor: 'yellow',
        opacity: 1,
      },
    },
    groove_red: {
      height: 140,
      backgroundColor: 'red',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      transition: 'height 0.2s',
      opacity: 0.7,
      '&:hover': {
        height: 135,
        backgroundColor: 'red',
        opacity: 1,
      },
    },
  };
});
const TireGrooveModal = ({ open, handleClose, tire }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  let temp = JSON.parse(JSON.stringify(tire?.grooves_tread_depth));
  delete temp['max_diff'];

  let arr = Object.keys(temp);
  const color_ = val => {
    if (val < 3) {
      return 'groove_red';
    } else if (val >= 3 && val < 5) {
      return 'groove_yellow';
    } else {
      return 'groove_green';
    }
  };
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      className={classes.modal}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: 200,
                backgroundImage: `url(${front})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                objectFit: 'contain',
                height: '200px',
              }}
            ></Box>
            {Object.keys(temp)
              .slice(0, -1)
              .map((item, i) => (
                <>
                  <Box className={classes.groove_main}>
                    <Box sx={{ margin: '1.5rem 0rem' }}>
                      <Typography variant='h5' color='primary.main'>
                        {temp[item]}
                      </Typography>
                    </Box>
                    <Box className={classes[color_(temp[item])]}></Box>

                    <Box className={classes.groove_bottom}></Box>
                    <Box sx={{ margin: '1.5rem 0rem' }}>
                      <Typography variant='h6'>{`G${item}`}</Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      width: 70,
                      backgroundImage: `url(${tire_map[item]})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      height: '200px',
                    }}
                  ></Box>
                </>
              ))}
            <Box className={classes.groove_main}>
              <Box sx={{ margin: '1.5rem 0rem' }}>
                <Typography variant='h5' color='primary.main'>
                  {temp[arr[arr.length - 1]]}
                </Typography>
              </Box>

              <Box className={classes[color_(temp[arr[arr.length - 1]])]}></Box>

              <Box className={classes.groove_bottom}></Box>
              <Box sx={{ margin: '1.5rem 0rem' }}>
                <Typography variant='h6'>{`G${
                  arr[arr.length - 1]
                }`}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: 200,
                backgroundImage: `url(${rear})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '200px',
              }}
            ></Box>
          </Box>
          <Typography variant='h5'>
            Groove Tread Depth{' '}
            <span style={{ color: theme.palette.primary.main }}>
              ( {tire.tire_id} )
            </span>
          </Typography>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default TireGrooveModal;
const tire_map = {
  1: first,
  2: second,
  3: third,
  4: first,
  5: second,
  6: third,
};

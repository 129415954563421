import React, { useState } from 'react';
import { Box, Button, Modal, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import { Close } from '@mui/icons-material';
import add_vehicle from 'assets/images/add_vechile.svg';
import AddSingleTire from 'components/AddSingleTire';
import { useTranslation } from 'react-i18next';
import AddMultipleTires from '../AddBulkTires/AddBulkTires';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.light,
      border: `1px dashed ${theme.palette.primary.main}`,
      borderRadius: 4,
      padding: '2rem 4rem',
      margin: '0rem 1rem',
    },
    mainBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem 2rem',
    },
    mainDiv: {
      padding: '4rem 2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#F4F5F6',
      overflow: 'auto',
      borderRadius: '10px',
      position: 'relative',
    },
    closeIcon: {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    btn: {
      textTransform: 'unset',
    },
  };
});

const UploadTiresModal = props => {
  const { open, setOpen, fleet } = props;
  const [openSingle, setOpenSingle] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className={classes.modal}
      >
        <motion.div
          initial={{ y: -50 }}
          animate={{ y: 0 }}
          transition={{ duration: 0.5 }}
          className={classes.mainDiv}
          data-hook='tire-add-selection-modal'
        >
          <Box
            className={classes.box}
            onClick={() => {
              setOpenSingle(true);
            }}
          >
            <Box margin='1.5rem 0px'>
              <img src={add_vehicle} alt='add' />
            </Box>

            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              disableElevation
              data-hook='single-upload'
            >
              {t('common.tireInventory.singleUpload')}
            </Button>
          </Box>
          <Box className={classes.box} onClick={() => setOpenBulk(true)}>
            <Box margin='1.5rem 0px'>
              <img src={add_vehicle} alt='add' />
            </Box>

            <Button
              variant='contained'
              color='primary'
              className={classes.btn}
              disableElevation
              data-hook='multiple-upload'
            >
              {t('common.tireInventory.multipleUpload')}
            </Button>
          </Box>
          <div className={classes.closeIcon} onClick={() => setOpen(false)}>
            <IconButton data-hook='close-upload-modal'>
              <Close />
            </IconButton>
          </div>
        </motion.div>
      </Modal>

      <AddSingleTire open={openSingle} setOpen={setOpenSingle} fleet={fleet} />
      <AddMultipleTires open={openBulk} setOpen={setOpenBulk} fleet={fleet} />
    </>
  );
};

export default UploadTiresModal;

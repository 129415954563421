import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Grid,
  Modal,
  Paper,
  Typography,
  IconButton,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Autocomplete,
  TextField,
} from '@mui/material';
import CustomTextField from 'components/custom/CustomTextField';
import CustomAutocomplete from 'components/custom/CustomAutocomplete';
import ActionButtons from './ActionButtons';
import CloseIcon from '@mui/icons-material/Close';
import {
  OnboardingActionTypes,
  setOnboardingSelector,
} from 'redux/onborading/ducks';
import { makeStyles } from 'tss-react/mui';
import { setAccessTokenSelector, setUserSelector } from 'redux/users/ducks';
import questions from 'assets/data/projectInfo.json';
import {
  busAxlePressurePositions,
  trailerAxlePressurePositions,
  truckAxlePressurePositions,
} from '../../../../pages/VehiclePage/TireConfig/const';
import {
  updatePressure,
  fleetOverview,
} from '../../../../services/fleet_service';
import { setNotification } from '../../../../redux/notifications/ducks';
import {
  fleetOverviewSelector,
  setFleetOverview,
} from '../../../../redux/fleets/ducks';
import { CircularProgress } from '@mui/material';

const useStyles = makeStyles()(theme => {
  return {
    dropDown: {
      width: '100%',
      marginBottom: '25px',
    },
    accordion: {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      marginBottom: 10,
      marginTop: 10,
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      maxHeight: '80vh',
    },
    accordionDetailsContainer: {
      maxHeight: 175,
      overflowY: 'scroll',
    },
    UpdateBtn: {
      position: 'absolute',
      bottom: '10px',
      right: '10px',
    },
    updatePressureButton: {
      alignSelf: 'flex-end',
      height: '40px',
      position: 'absolute',
      bottom: 20,
      right: 50,
      marginTop: 2,
    },
  };
});

const Vehicle = ({ vehicleType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const access_token = useSelector(setAccessTokenSelector);
  const [initialState, setInitialState] = useState({
    kmDrivenPerYear: '',
    noOfUnits: '',
    axelTypes: [],
    tireTypes: [],
  });

  const [state, setState] = useState({ ...initialState });
  const [open, setOpen] = useState(false);
  const organizationProfile = useSelector(
    setOnboardingSelector
  )?.organizationProfile;
  const vehicleData = organizationProfile?.['general_configs']?.[vehicleType];
  const usersInfo = useSelector(setUserSelector);
  const [vehicleConfig, setVehicleConfig] = useState({});
  const [axlePressureValues, setAxlePressureValues] = useState({});
  const [axleTypes, setAxleTypes] = useState([]);
  const [isExpanded] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const overviewDetails = useSelector(fleetOverviewSelector).general_configs;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    setIsSubmitting(false);
  }, [state]);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };
  const handleClear = () => {
    setState({ ...initialState });
  };
  let axleValues = [];

  useEffect(() => {
    const pressureDetails = overviewDetails?.[vehicleType]?.optimum_air_pressure
      ? overviewDetails?.[vehicleType]?.optimum_air_pressure
      : {};
    Object.keys(pressureDetails).forEach(key => {
      // eslint-disable-next-line
      axleValues = [...axleValues, key];
    });
    setAxleTypes(axleValues);
    setAxlePressureValues(pressureDetails ?? {});
  }, [overviewDetails]);

  function hasErrors() {
    for (const key in errors) {
      const innerObject = errors[key];
      for (const innerKey in innerObject) {
        if (innerObject[innerKey] !== null) {
          return true;
        }
      }
    }
    return false;
  }
  const handleAxlePressureValues = (e, axle, type) => {
    if (parseFloat(e.target.value) > 15 || parseFloat(e.target.value) < 5) {
      setErrors(prevState => ({
        ...prevState,
        [axle]: {
          ...prevState[axle],
          [type]: t('common.pressureValueError'),
        },
      }));
    } else {
      setErrors(prevState => ({
        ...prevState,
        [axle]: {
          ...prevState[axle],
          [type]: null,
        },
      }));
    }
    let pressureValues = JSON.parse(JSON.stringify(axlePressureValues));
    pressureValues[axle] = {
      ...(pressureValues[axle] || {}),
      [type]: e.target.value,
    };
    let validateFields = [];
    if (Object.keys(pressureValues).length !== axleTypes.length) {
      setDisabled(true);
    } else {
      axleTypes.forEach(item => {
        if (
          getVehiclePressurePosition()[item].length !==
          Object.values(pressureValues[item]).length
        ) {
          validateFields.push(item);
        } else if (Object.values(pressureValues[item]).length) {
          if (Object.values(pressureValues[item])?.some(item => !item)) {
            validateFields.push(item);
          }
        }
      });
      if (validateFields.length) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
    setAxlePressureValues(pressureValues);
  };

  const compareValues = () => {
    if (Object.entries(axlePressureValues)?.length !== axleTypes.length) {
      return false;
    }
    return true;
  };

  const handleUpdatePressure = async () => {
    const configs = {
      truck: 'TRUCK',
      trailer: 'TRAILER',
      bus: 'BUS',
    };
    let validate = compareValues();
    if (validate) {
      setLoading(true);
      const payload = {
        config: configs[vehicleType],
        optimum_air_pressure: axlePressureValues,
      };
      const response = await updatePressure(payload);
      if (response.status === 200) {
        setAxlePressureValues({});
        setErrors({});
        setOpen(false);
        // setAxleTypes([]);
        dispatch(
          setNotification('Pressure updated successfully', 'success', true)
        );
        setLoading(false);
        const fleetResponse = await fleetOverview(access_token);
        dispatch(setFleetOverview(fleetResponse.data.resp));
        return;
      }
    }
    dispatch(setNotification('please enter all fields', 'error', true));
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    const payload = {
      no_of_units: state['noOfUnits'],
      km_driven_per_year: state['kmDrivenPerYear'],
      axle_type_1: state['axelTypes'][0],
      axle_type_2: state['axelTypes'][1],
      tire_type_1: state['tireTypes'][0],
      tire_type_2: state['tireTypes'][1],
      axle_1_perc: vehicleData['axle_1_perc'],
      axle_2_perc: vehicleData['axle_2_perc'],
      tire_1_perc: vehicleData['tire_1_perc'],
      tire_2_perc: vehicleData['tire_2_perc'],
    };
    dispatch({
      type: OnboardingActionTypes.ORGANIZATION_PROFILE[
        vehicleType === 'trailer'
          ? 'UPDATE_TRAILER_CONFIG'
          : vehicleType === 'truck'
          ? 'UPDATE_TRUCK_CONFIG'
          : 'UPDATE_BUS_CONFIG'
      ],
      access_token: access_token,
      payload: payload,
    });
  };

  const commonProps = { required: false, onChange: handleChange };

  useEffect(() => {
    if (vehicleData) {
      const _initialData = {
        kmDrivenPerYear: vehicleData['km_driven_per_year'],
        noOfUnits: vehicleData['no_of_units'],
        axelTypes: [vehicleData['axle_type_1'], vehicleData['axle_type_2']],
        tireTypes: [vehicleData['tire_type_1'], vehicleData['tire_type_2']],
      };
      setInitialState({ ..._initialData });
      setState({ ..._initialData });
      setVehicleConfig(questions['general_configs'][vehicleType]);
    }
    setVehicleConfig(questions['general_configs'][vehicleType]);
  }, [vehicleData, vehicleType]);

  if (!vehicleData) {
    return (
      <Box
        display={'flex'}
        justifyContent='center'
        alignItems={'center'}
        height='100%'
        width='100%'
      >
        Please add details for {vehicleType} to continue
      </Box>
    );
  }

  const renderInputFields = axle => {
    return getVehiclePressurePosition()[axle].map((type, i) => {
      return (
        <Grid
          key={i}
          item
          xs={6}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <CustomTextField
            name={type}
            id={type}
            label={`position ${type}`}
            type='number'
            variant='outlined'
            required
            data-hook={`position_${axle}_${type}`}
            value={axlePressureValues?.[axle]?.[type]}
            onChange={e => handleAxlePressureValues(e, axle, type)}
            InputProps={{
              inputProps: {
                min: 5,
                max: 15,
                step: 0.1,
              },
            }}
            style={{ width: 120 }}
            fullWidth
            error={!!errors[axle]?.[type]}
            helperText={errors[axle]?.[type]}
          />
        </Grid>
      );
    });
  };

  const renderAxleTypes = () => {
    if (!axleTypes && !axleTypes.length) {
      return (
        <Box sx={{ display: 'flex' }}>
          {' '}
          <Typography align='center'>No Axle Types Selected</Typography>
        </Box>
      );
    }

    return axleTypes.map((axle, i) => {
      return (
        <Grid key={i} item xs={5} marginRight={2}>
          <Accordion
            className={classes.accordion}
            expanded={isExpanded}
            style={{
              marginTop: '15px',
              minHeight: '229.55px',
            }}
          >
            <AccordionSummary
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography variant='h6'>
                {t('common.tireInventory.update') +
                  ' ' +
                  axle +
                  ' ' +
                  t('common.optPressure')}
              </Typography>
            </AccordionSummary>
            <Box className={classes.accordionDetailsContainer}>
              <AccordionDetails className={classes.accordionDetails}>
                <Grid container spacing={3}>
                  {renderInputFields(axle)}
                </Grid>
              </AccordionDetails>
            </Box>
          </Accordion>
        </Grid>
      );
    });
  };

  const getVehiclePressurePosition = () => {
    switch (vehicleType) {
      case 'truck':
        return truckAxlePressurePositions;
      case 'trailer':
        return trailerAxlePressurePositions;
      case 'bus':
        return busAxlePressurePositions;
      default:
        return;
    }
  };
  const handleAxleTypes = (e, value) => {
    const pressureValues = {};
    value.forEach(key => {
      if (axlePressureValues.hasOwnProperty(key)) {
        pressureValues[key] = axlePressureValues[key];
      }
    });
    if (Object.keys(pressureValues).length !== value.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    setAxlePressureValues(pressureValues);
    setAxleTypes(value);
  };

  const renderUpdateOptimumModal = () => {
    const vehicleCategory = Object.keys(getVehiclePressurePosition());
    return (
      <Modal
        open={open}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '40px',
        }}
        data-hook='abcd-modal'
        onClose={() => {
          setAxlePressureValues(
            overviewDetails[vehicleType].optimum_air_pressure ?? {}
          );
          setErrors({});
          setOpen(false);
        }}
      >
        <Paper
          sx={{
            width: '750px',
            backgroundColor: 'white',
            padding: '2rem',
            height: '650px',
          }}
          style={{
            display: 'flex',
            borderRadius: '30px',
            position: 'relative',
            flexDirection: 'column',
          }}
        >
          <Grid
            item
            xs={12}
            mt={3}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Typography variant='h5'>{t('common.updateOpt')}</Typography>
          </Grid>
          <Grid marginTop={5} container spacing={3} width='100%'>
            <IconButton
              style={{
                position: 'absolute',
                right: 10,
                top: 10,
                cursor: 'pointer',
              }}
              onClick={() => {
                setAxlePressureValues(
                  overviewDetails[vehicleType].optimum_air_pressure ?? {}
                );
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                id='tags-outlined'
                data-hook='select-axle-type'
                options={vehicleCategory}
                getOptionLabel={option => option}
                filterSelectedOptions
                onChange={handleAxleTypes}
                value={axleTypes}
                renderInput={params => (
                  <TextField
                    {...params}
                    style={{ backgroundColor: '#fff' }}
                    variant='outlined'
                    label='Select Axle Type'
                  />
                )}
              />
            </Grid>
            <Grid
              display={'flex'}
              item
              xs={12}
              paddingLeft={0}
              flexWrap='wrap'
              style={{
                margin: '10px',
                height: '300px',
                overflowY: 'auto',
              }}
            >
              {renderAxleTypes()}
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2 }}>
            <Typography alignSelf={'center'} color='red'>
              {t('common.cautionMsg')}
            </Typography>
            <Button
              variant='contained'
              color='primary'
              className={classes.updatePressureButton}
              disabled={
                disabled ||
                Object.keys(axlePressureValues).length !== axleTypes.length ||
                loading ||
                hasErrors()
              }
              data-hook='update-optimum-pressure-button'
              onClick={handleUpdatePressure}
            >
              {loading ? (
                <CircularProgress
                  style={{
                    color: 'white',
                    width: '22px',
                    height: '22px',
                  }}
                />
              ) : (
                t('common.tireInventory.update')
              )}
            </Button>
          </Box>
        </Paper>
      </Modal>
    );
  };

  return (
    <>
      <Grid container position={'relative'} spacing={2} marginBottom={2}>
        <Grid item xs={12} lg={6}>
          <CustomTextField
            value={state['noOfUnits']}
            label={t(`customerSettingsPage.${vehicleType}.noOfUnits`)}
            {...commonProps}
            type='number'
            name='noOfUnits'
            onChange={handleChange}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomTextField
            value={state['kmDrivenPerYear']}
            label={t(
              `customerSettingsPage.${vehicleType}.totalDistanceTravelled`
            )}
            {...commonProps}
            type='number'
            name='kmDrivenPerYear'
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomAutocomplete
            maxOptionSelectionAllowed={2}
            multiple={true}
            limitTags={2}
            options={
              vehicleConfig?.['axle_types']?.['options']?.[usersInfo?.locale] ||
              []
            }
            label={t(`customerSettingsPage.${vehicleType}.selectAxelType`)}
            {...commonProps}
            value={state.axelTypes}
            name='axelTypes'
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomAutocomplete
            maxOptionSelectionAllowed={2}
            multiple={true}
            limitTags={2}
            options={
              vehicleConfig?.['tire_sizes']?.['options']?.[usersInfo?.locale] ||
              []
            }
            label={t(`customerSettingsPage.${vehicleType}.selectTireType`)}
            {...commonProps}
            value={state.tireTypes}
            name='tireTypes'
          />
        </Grid>
      </Grid>
      <Button
        style={{
          position: 'absolute',
          bottom: 36,
          left: 44,
        }}
        id='update-opt-pressure'
        color='primary'
        variant='contained'
        data-hook='update-opt-pressure'
        onClick={() => setOpen(true)}
      >
        <Typography style={{ textTransform: 'capitalize', fontSize: 18 }}>
          {t('common.updateOpt')}
        </Typography>
      </Button>
      <ActionButtons
        handleUpdate={handleUpdate}
        handleClear={handleClear}
        disabled={
          !(
            state['axelTypes'].length === 2 &&
            state['tireTypes'].length === 2 &&
            state['kmDrivenPerYear'] &&
            state['noOfUnits']
          ) || isSubmitting
        }
        isSubmitting={isSubmitting}
      />
      {renderUpdateOptimumModal()}
    </>
  );
};
export default Vehicle;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Slide, Snackbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  notificationSelector,
  setNotificationTimeout,
} from 'redux/notifications/ducks';
import { Check, Close, ErrorOutlineOutlined } from '@mui/icons-material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import styles from './Notification.module.css';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    progress: {
      width: '100%',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 10,
      width: 390,
      minHeight: 90,
      borderRadius: 10,
    },
    circle: {
      height: 70,
      width: 70,
      borderRadius: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '10%',
      marginLeft: 10,
    },
    checkMark: {
      fontSize: 30,
      color: '#fff',
    },
    snackbar: {
      zIndex: theme.zIndex.snackbar,
    },
  };
});

const Notification = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const notification = useSelector(notificationSelector);
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    dispatch(setNotificationTimeout());
  }, [dispatch]);

  function TransitionRight(props) {
    return <Slide {...props} direction='left' />;
  }

  return (
    <Snackbar
      open={notification.show}
      autoHideDuration={7000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      TransitionComponent={TransitionRight}
      className={classes.snackbar}
    >
      <Card className={classes.container}>
        <Box
          style={{
            backgroundColor:
              notification.type === 'success'
                ? 'green'
                : notification.type === 'error'
                ? 'red'
                : 'grey',
          }}
          className={classes.circle}
        >
          {notification.type === 'success' ? (
            <Check className={classes.checkMark} />
          ) : notification.type === 'error' ? (
            <ErrorOutlineOutlined className={classes.checkMark} />
          ) : (
            <NotificationsNoneIcon className={classes.checkMark} />
          )}
        </Box>
        <div className={styles.card}>
          <h3
            style={{
              color:
                notification.type === 'success'
                  ? 'green'
                  : notification.type === 'info'
                  ? 'grey'
                  : 'red',
            }}
          >
            {notification.type === 'success'
              ? 'Success!'
              : notification.type === 'error'
              ? 'Error...'
              : ''}
          </h3>
          <p>
            {notification?.translated
              ? t(notification?.message)
              : notification?.message || ''}
          </p>
        </div>
        <div className={styles.closeBtn} onClick={() => handleClose()}>
          <Close />
        </div>
      </Card>
    </Snackbar>
  );
};

export default Notification;

import { makeStyles } from 'tss-react/mui';
// import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
// import DashboardIcon from '@material-ui/icons/Dashboard';

const drawerWidth = 325;
// const xlDrawerWidth = 425;

export const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      // backgroundColor: 'blue',
      [theme.breakpoints.up('xl')]: {
        paddingTop: 40,
      },
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      position: 'fixed',
      [theme.breakpoints.up('sm')]: {
        // width: `calc(100% - ${drawerWidth}px)`,
        // marginLeft: drawerWidth,
      },
      [theme.breakpoints.up('xl')]: {
        width: `100%`,
        // marginLeft: xlDrawerWidth,
      },

      backgroundColor: '#F3F4F6',
      boxShadow: 'none',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(1),
      padding: theme.spacing(3),
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    customerInfo: {
      padding: '10px',
    },
    header: { fontWeight: '700', color: '#000' },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    subscriptionButton: {
      display: 'flex',
      justifyContent: 'center',
      gap: '1rem',
      alignItems: 'center',
    },
    header1: { color: '#000', opacity: 0.6, fontSize: 16 },
    tourIcon: { fontSize: '40px', cursor: 'pointer' },
    upgradeBtn: {
      fontSize: 'clamp(14px, 1vw, 2rem)',
      borderRadius: '24px',
      textTransform: 'unset',
    },
  };
});

import React from 'react';
import classes from './SomethingWentWrong.module.css';
import img2 from 'assets/images/confused.png';
import { Box } from '@mui/material';

const SomethingWentWrong = props => {
  return (
    <main className={classes.main}>
      <Box
        bgcolor='#ffffff'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        border='5px solid grey'
      >
        <div className={classes.topInfo}>
          <img src={img2} alt='error two' />
        </div>
        <div className={classes.infoText}>
          <p>Uh oh, something doesn't feel right!</p>
          <p>
            Please try to refresh this page or click the button below. Feel free
            to contact us if this issue persists.
          </p>
        </div>
      </Box>
    </main>
  );
};

export default SomethingWentWrong;

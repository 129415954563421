import axios from 'axios';
import { co2optBaseUrl, tireServiceMapUrl, appTelemetryUrl } from './config.js';

const co2OptBaseApi = axios.create({
  baseURL: co2optBaseUrl,
});

const dropzoneApi = axios.create({
  baseURL: `${co2optBaseUrl}/customer/vehicle-data-room`,
});

const tireServiceMapApi = axios.create({
  baseURL: tireServiceMapUrl,
});

const appTelemetryApi = axios.create({
  baseURL: appTelemetryUrl,
});

co2OptBaseApi.interceptors.request.use(
  config => {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

dropzoneApi.interceptors.request.use(
  config => {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

tireServiceMapApi.interceptors.request.use(
  config => {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

appTelemetryApi.interceptors.request.use(
  config => {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export { co2OptBaseApi, dropzoneApi, tireServiceMapApi, appTelemetryApi };

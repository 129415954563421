// Rules

import { TIRES } from 'utils/string_utils';

// Always use [`] for wrapping string value
// Think of a common strings which can be reuse and put those inside common object
export const pl = {
  common: {
    cautionMsg:
      'Uwaga : Regulacja ciśnienia w oponach w tym miejscu ma wpływ na wszystkie pojazdy w zależności od konfiguracji osi',
    updateCautionMsg:
      'Uwaga : wszystkie zamontowane opony obecne w tym pojeździe również zostaną przeniesione do nowej floty',
    updateOpt: 'Zaktualizuj optymalne ciśnienie',
    Unclassified: 'Niesklasyfikowane',
    rows: 'wydziwianie',
    showing: 'pokazywanie',
    of: 'z',
    results: 'wyniki',
    mounted: 'zamontowane',
    Unmounted: 'Nieoprawny',
    on: 'NA',
    assignResources: 'Przypisz zasób',
    optPressure: 'optymalne ciśnienie',
    noTiresAdded: 'Nie dodałeś jeszcze żadnych opon.  ',
    noVehiclesAdded: 'Nie dodałeś jeszcze żadnego pojazdu.   ',
    notelematicsIntegrated: 'Nie zintegrowałeś jeszcze telematyki. ',
    bulkUpload: 'Przydział masy',
    assignSuccessMsg: 'Pomyślnie przydziel zasoby',
    assignFailureMsg: 'Coś poszło nie tak',
    mergedTrucksTrailers: 'Połączone ciężarówki i przyczepy',
    inventory: {
      TireInventory: 'Inwentaryzacja opon',
    },
    tireOverview: 'Przegląd opon',
    noVehiclesPresent: 'Brak pojazdów',
    noTiresPresent: 'Brak opon',
    vehicleOverview: 'Przegląd pojazdu',
    inRange: 'w zakresie',
    managedTires: 'Zarządzane opony',
    totalTiresInUse: 'Opony w użyciu',
    ourRecommendation: 'Nasza rekomendacja',
    customize: 'Dostosuj',
    pleaseApplyMoreFilter: 'Proszę zastosować więcej filtrów!',
    and: 'I',
    imprint: 'Odcisk',
    tnc: 'Warunki',
    here: 'Tutaj',
    continue: 'Kontynuować',
    continueWithTestAccount:
      'Kliknij tutaj, aby uzyskać dostęp do konta testowego',
    createAccount: 'Utwórz konto',
    savingPotential: 'Potencjał oszczędności',
    noCsv: 'Nie znaleziono pliku .csv',
    uploadInstruction: 'Upuść tutaj plik .csv lub kliknij, aby go przesłać',
    uploadBtn: 'Wgrywać',
    integrationInfo: 'Telematyka',
    fleetSettings: 'Ustawienia floty',
    personalSettings: 'Ustawienia osobiste',
    profileSettings: 'Ustawienia profilu',
    notificationSettings: 'Powiadomienia',
    userMgt: 'Zarządzanie użytkownikami',
    tireMgt: 'Zarządzanie oponami',
    truck: 'Ciężarówka',
    trailer: 'Przyczepa',
    bus: 'Autobus',
    retread: 'Strategia bieżnikowania',
    goPremium: 'Aby skorzystać z tej funkcji, wybierz opcję Premium',
    premiumBtn: 'Kup premium',
    perYear: 'na rok',
    trucks: 'Ciężarówki / samochody dostawcze',
    per100Km: 'na 100 km',
    perLitre: 'na litr',
    seeMore: 'Zobacz więcej',
    co2Emission: 'Zmniejszona emisja CO2',
    savingsAchieved: 'Osiągnięte oszczędności',
    dataIntegration: 'Integracja danych',
    noOfTyres: 'Liczba opon',
    noOfVehicles: 'Liczba pojazdów',
    axelTypes: 'Typy osi',
    termsAndCondition: 'Warunki',
    privacyPolicy: 'Polityka prywatności',
    allRightsReserved: 'Wszelkie prawa zastrzeżone',
    configured: 'Skonfigurowane',
    notConfigured: 'Nie skonfigurowane',
    notConnected: 'Nie połączony',
    partialConfig: 'Część. ',
    yes: 'Tak',
    no: 'NIE',
    submitBtn: 'Składać',
    noVehicleFound: 'Nie znaleziono pojazdu',
    showMore: 'Pokaż więcej',
    showLess: 'Pokaż mniej',
    sessionExpired: 'Twoja sesja wygasła. ',
    back: 'Z powrotem',
    error: 'Wystąpił błąd. ',
    noDataFound: 'Nie znaleziono danych',
    apply: 'Stosować',
    confirm: 'Potwierdzać',
    cancel: 'Anulować',
    next: 'Następny',
    skip: 'Pominąć',
    configure: 'Skonfiguruj',
    download: 'Pobierać',
    mount: 'Uchwyt',
    unmount: 'Odmontuj',
    approve: 'Zatwierdzić',
    fleets: 'Floty',
    wait: 'Proszę czekać . ',
    na: 'Niedostępne',
    eg: 'Przykład: ',
    axleName: 'Nazwa osi',
    vehicles: 'Pojazdy',
    no_vehicles: 'Brak danych pojazdu',
    frequency: {
      daily: 'Codziennie',
      weekly: 'Co tydzień',
      monthly: 'Miesięcznie',
      biannually: 'Co pół roku',
      quaterly: 'Co kwartał',
    },
    axle_name: {
      steer: 'Steruj',
      drive: 'Napęd',
      trailer: 'Zwiastun',
      bus: 'Autobus',
    },
    changelog: 'Dziennik zmian',
    noVehicles:
      'W wybranej flocie nie ma żadnych pojazdów ani przyczep. aby pobrać raport.',
    tireInventory: {
      tooShortError:
        'Identyfikator opony musi składać się z co najmniej 5 znaków.',
      maxDepthError: `Głębokość bieżnika nie może przekraczać ${TIRES.MAX_DEPTH} mm`,
      alphanumericError: `Proszę wprowadzić tylko cyfry`,
      noSpecialCharsMessage: 'Wpisz tylko cyfry i litery, bez spacji',
      tireUploadedMsg: 'Opona została pomyślnie dodana',
      addTire: 'Dodaj nową oponę',
      addTires: 'Dodaj nowe opony',
      uploading: 'Przesyłanie opon',
      movedToStorageSuccess: 'Pomyślnie przeniesiono do magazynu',
      tireId: 'Identyfikator opony',
      optimum_pressure: 'ciśnienie',
      mileage: 'Aktualny przebieg',
      depth: 'Głębokość bieżnika',
      size: 'Rozmiar opony',
      unmountMsgWarning:
        'Spowoduje to zdemontowanie opony i przeniesienie jej do magazynu.',
      confirmBtn: 'Potwierdzać',
      editTooltip: 'Edytuj oponę',
      removeTooltip: 'Wyrzucić oponę',
      moveTooltip: 'Zdemontuj oponę',
      viewEventHistory: 'Wyświetl historię zdarzeń związanych z oponami',
      inventoryEmpty: 'Brak opon w magazynie',
      singleUpload: 'Dodaj pojedynczą oponę',
      multipleUpload: 'Dodaj wiele opon',
      tireBrand: 'Marka opon',
      optPressure: 'Optymalne ciśnienie dla tej pozycji wynosi',
      showMore: 'Pokaż więcej',
      showLess: 'Pokaż mniej',
      productLines: 'Linie produktów',
      update: 'Aktualizacja',
      tireUpdateHeading: 'Zaktualizuj informacje o oponach',
      updateRegrooveHeading: `Ponowne pogłębienie opony`,
      uploadBtn: 'Dodaj oponę',
      getMore: 'Załaduj więcej',
      noBrands: 'Brak dostępnych marek',
      no_of_tires: 'Liczba opon',
      steerTires: 'Liczba używanych opon na oś sterującą',
      driveTires: 'Liczba używanych opon napędowych',
      trailerTires: 'Liczba używanych opon przyczepy',
      manageTire: 'Kliknij, aby zarządzać oponami',
      uploadFile: `Prześlij plik`,
      disposeTires: `Usuń opony`,
    },

    actions: {
      cancel: 'Anulować',
      update: 'Aktualizacja',
      delete: 'Usuwać',
      download: 'Pobierać',
    },
    change_availablity: 'Zmiana dostępności',
    change_availablity_success: 'Zaktualizowano pomyślnie',
    change_availablity_failed:
      'Aktualizacja nie powiodła się, spróbuj ponownie',
    rrc_reduction: 'Redukcja RRC',
    savings_reduction: 'Savings Retread',
    saved_regrooved: 'Saved Regroove',
    pageTour: 'Wycieczka po stronie',
    mergedTrailer: 'Połączona przyczepa',
    example: 'Przykład',
    loadMessage: 'Przygotowywanie rzeczy...',
    deleteWarning:
      'Klikając usuń, zgadzasz się na nasze warunki, zgodnie z którymi T&C Twoje zapisane dane zostaną całkowicie usunięte, a działanie to jest trwałe i nie można go cofnąć za żadną cenę.',
    search: `Szukaj`,
    addFleet: 'Dodaj flotę',
    changeTire: 'Zmień oponę',
    unmountTire: 'Demontuj oponę',
    tire: 'Opona',
    vehicle: 'Pojazd',
    irregular_wear: 'Nieregularne zużycie',
    pressureValueError: 'Wartość powinna wynosić od 5 do 15',
  },
  verifyUser: {
    redirectToSignUpPage: 'Zostaniesz przekierowany na stronę rejestracji',
    ifNotRedirectedClick:
      'Jeśli nie nastąpi automatyczne przekierowanie, kliknij ',
  },
  fleetCheck: {
    fleetCheckUpdateMsg: 'Kontrola floty została pomyślnie zaktualizowana',
  },
  loginPage: {
    reduceTheCost: 'Zmodernizuj zarządzanie flotą',
    reduceTheCostMsg:
      'Zmniejsz całkowity koszt posiadania i emisję dwutlenku węgla dzięki naszym zoptymalizowanym planom zarządzania oponami',
    signInToYourAccount: 'Zaloguj się na swoje konto',
    forgotPassword: 'Zapomniałeś hasła ?',
    or: 'Lub',
    loginInAgreeMessageInitial: 'Klikając Zaloguj się, akceptujesz nasze',
    register: 'Zapisać się',
    login: 'Zaloguj sie',
    learnMore: 'Ucz się więcej',
    passwordComplexity: {
      number: 'Przynajmniej jeden numer',
      specialCharacter: 'Przynajmniej jeden znak specjalny',
      lowercase: 'Co najmniej jedna mała litera',
      uppercase: 'Przynajmniej jedna duża litera',
      length: 'Co najmniej 8 znaków',
    },
    invalid: 'niewłaściwy format wiadomości email',
    emailNotRegistered: 'Nie mamy konta zarejestrowanego na ten adres e-mail',
    startSaving: 'Zacznij oszczędzać',
    success: 'Zalogowano pomyślnie',
    leadWallHeading: 'Poświęć tylko minutę, aby...',
    leadWallButton: 'Przejdź do aplikacji',
    demo: 'Poproś o demonstrację',
  },
  error: {
    400: 'Do konta nie można zalogować się przy użyciu hasła, ponieważ było już ono zalogowane przy użyciu konta społecznościowego.',
    401: 'Zła nazwa użytkownika',
    402: 'Zbyt wiele nieudanych prób podania hasła. ',
    403: 'Złe hasło. ',
    406: 'Niezweryfikowane konto. ',
    407: 'Użytkownik został dezaktywowany z powodu próby złośliwego ataku.',
    errorOccuredInformLogin: 'Wystąpił błąd. ',
    errorLoadingData: 'Błąd ładowania danych',
  },
  homePage: {
    on: 'NA',
    overdue: 'Zaległy',
    completeYourCompanyProfile: 'Uzupełnij profil swojej firmy',
    completeYourCompanyProfileDesc:
      'Zaktualizuj informacje w swoim profilu i zoptymalizuj konfigurację opon',
    configureYourFleet: 'Skonfiguruj swoją flotę',
    savingPotential: 'Potencjał oszczędności',
    status: {
      profile: 'Stan profilu',
      org: 'Informacje o organizacji',
      tireMgmt: 'Informacje dotyczące zarządzania oponami',
      generalAsset: 'Ogólny przegląd pojazdów',
      fleet: 'Dane floty',
      retreadStrategy: 'Strategia bieżnikowania',
    },
    fleetNotConfigured: 'Flota nieskonfigurowana',
    infoBoardHeading:
      'Spotkaj się z nami na targach IAA Transportation w Hanowerze',
    infoBoardSubtitle: 'Jesteśmy w hali 13/C2020',
    appointmentBtn: 'Zarezerwuj wizytę',
    bookAnAppointment: 'Darmowa konsultacja',
    configure: 'Skonfiguruj',
    connectData: 'Połącz dane',
    vehicleType: 'Typ pojazdu',
    tireInfo: 'Informacje o oponach',
    liveTracking: 'Śledzenie na żywo',
    nextActions: 'Wybitne zadania',
    both: 'Obydwa',
    unconfigured: 'Nieskonfigurowane',
    tracked: 'Śledzone',
    untracked: 'Nieśledzony',
    connected: 'Połączony',
    infoNeeded: 'Potrzebne informacje',
    actions: 'działania',
    retreadStrategy: 'Strategia bieżnikowania',
    confiqure: 'Skonfiguruj',
    question: 'Czy wiedziałeś ?',
    operative_costs_eur: {
      text1: 'Opony kosztują Cię w eksploatacji ',
      text2: ' ale wpływają na wysokość rachunków za paliwo ',
    },
    tire_related_savings_potential: {
      text1: 'Możesz przynajmniej zaoszczędzić ',
      text2:
        ' całkowitego kosztu posiadania w ciągu roku dzięki zoptymalizowanemu zarządzaniu oponami',
    },
    taskBoard: {
      createdAt: 'Data utworzenia',
      description: 'Opis',
      source: 'Źródło',
      fleet: 'Flota',
      title: 'Tytuł',
      taskTitleMany: ' Opony o głębokości bieżnika < 3 mm',
      taskTitleSingle: ' Opona o głębokości bieżnika < 3 mm',
      loading: 'Ładuję zadania...',
      actions: 'działania',
    },
    moreBtn: 'Więcej zajęć',
    moreBtn2: '...Zobacz więcej',
    lessBtn: 'Zobacz mniej',
    recentActivityHeading: 'Ostatnia aktywność',
    lessThan6months: 'Wygasa za niecałe 6 miesięcy',
    dueIn6To12months: 'Wygasa za 6–12 miesięcy',
    moreThan12months: 'Pozostało ponad 12 miesięcy życia',
    lessThan3Months: 'Mniej niż 3 miesiące',
    merged: 'Połączone',
    atBottom: 'Wszyscy jesteście zajęci',
    initialAssessment: ' (Wstępna ocena)',
    searchbar: {
      noResults: `Nie znaleziono wyników dla podanego hasła wyszukiwania`,
      placeholder: `Szukaj według nazwy pojazdu, numeru rejestracyjnego lub identyfikatora opony`,
    },
    editHomeIcon: `Zmień widoczność`,
    dashboardEditTitle: `Wybierz sekcje do wyświetlenia`,
    dashboardSections: {
      recent_activities: `Ostatnie aktywności`,
      savings_potential: `Potencjał oszczędności`,
      task_board: `Tablica zadań`,
      tire_overview: `Przegląd opon`,
      tire_recommendation: `Rekomendacja opon`,
      vehicle_overview: `Przegląd pojazdów`,
    },
  },
  dataIntegrationPage: {
    provider: 'Dostawca',
    auth: 'Upoważnienie',
    telematicsAltMessage:
      'Nie chcesz jeszcze integrować swojego dostawcy telematyki?',
    manualUploadBtn: 'Zamiast tego prześlij dane telematyczne w formacie CSV',
    integratedProviders: 'Zintegrowani dostawcy telematyki',
    noTelematicsMessage: 'Brak zintegrowanego dostawcy usług telematycznych!',
    yellowfoxVehiclePlaceholder: 'Wprowadź jeden pojazd na raz',
    credentialsToken: 'Token danych uwierzytelniających',
    traceAvailable: 'Dane historyczne',
    trackAvailable: 'Dane śledzenia na żywo',
    infoLink: 'Gdzie to znaleźć?',
    TOS: 'Przesyłając dane uwierzytelniające, oświadczasz, że możesz to robić w imieniu swojej organizacji i że akceptujesz warunki korzystania z danych telematycznych opisane w naszej',
    detailsBtn: 'Patrz szczegóły',
  },
  csvReaderPage: {
    pageHeading: 'Prześlij dane telematyczne jako plik .csv',
    providerNameQtn:
      'Jak nazywa się dostawca usług telematycznych, od którego pozyskałeś dane CSV?',
    uploadHistoryHeading: 'Historia przesyłania pliku CSV',
    uploadHistoryMessage:
      'Prześlij dane telematyczne w formacie CSV, aby uzyskać optymalną konfigurację opon!',
  },
  csvUploaderPage: {
    add: 'Dodać',
    addMultipleTrucks: 'Dodaj wiele ciężarówek/vanów',
    addMultipleTrailers: 'Dodaj wiele zwiastunów',
    addMultipleBuses: 'Dodaj wiele autobusów',
    exampleFile: 'Przykładowy plik',
    supportedFormats: 'Obsługiwane formaty',
    sampleDownload: 'Pobierać',
    individualUpload: 'Przesyłanie indywidualnego pojazdu',
    bulkUpload: 'Przesyłanie zbiorcze',
    missingDataError: 'W wymaganych kolumnach brakuje danych. ',
  },
  supportPage: {
    address: 'Adres',
    talk: 'Spotkanie osobiste',
    genSupport: 'Ogólne wsparcie',
    formHeading: 'Napisz do nas wiadomość',
    send: 'Wysłać',
    subject: 'Temat',
    message: 'Wiadomość',
  },
  fleetOverviewPage: {
    tireProvider: 'Dostawca serwisu opon',
    deadline: 'Termin składania wniosków',
    telematics: 'Dostawca telematyki',
    driverCard: 'Karta kierowcy',
    tank: 'Tankkarta',
    overview: 'Przegląd',
  },
  kpiList: {
    emission: 'Emisje CO2',
    fuelCosts: 'Koszty paliwa',
    tireCosts: 'Koszty opon',
    tonnes: 'Tonnes',
    adminCosts: 'Koszty opon administratora',
    assets: 'Liczba aktywów',
    averageKm: 'Śr. ',
    averageFuelConsumption: 'Śr. ',
    assumedFuelCosts: 'Zakładane koszty paliwa',
    totalCostOfOwnership: 'Całkowity koszt posiadania',
    tireNumber: 'Liczba opon',
    brandsAndLines: 'Opony - marki i linie produktowe',
    tDepth: 'Opony — głębokość bieżnika',
    size: 'Opony - Rozmiar',
    telematics: 'Przegląd pojazdów - Telematyka',
  },
  pieChart: {
    costsOfOwnershipTitle: 'Całkowity koszt posiadania',
    label1: 'Inne koszta',
    label2: 'Koszty diesla',
    label3: 'Koszty opon',
    assetsMakeTitle: 'Producent pojazdów',
    tireTitle: 'Marki opon',
  },
  barChart: {
    tireNumberTitle: 'Liczba opon',
    axleTitle: 'Typy osi',
    vehicleOverview: 'Przegląd pojazdu',
  },
  tabs: {
    home: 'Dom',
    fleetsData: 'Flota',
    support: 'Wsparcie',
    tireinventory: 'Inwentarz opon',
    reports: 'Raporty',
    settings: 'Ustawienia',
    logout: 'Wyloguj',
    overview: 'Przegląd',
  },
  customerSettingsPage: {
    tabs: {
      organization: 'Organizacja',
      fleetCheck: 'Kontrola floty',
      tire: 'Opona',
    },
    organization: {
      name: 'Nazwa',
      noOfYards: 'Liczba jardów',
      zipcode: 'Kod pocztowy',
      city: 'Miasto',
      country: 'Kraj',
    },
    tire: {
      isHotRetread: 'Bieżnikowanie na gorąco',
      isColdRetread: 'Bieżnikowanie na zimno',
      isDeMinimis: 'De-minimis',
      preferredTireBrand1: 'Preferowana marka opon 1',
      preferredTireBrand2: 'Preferowana marka opon 2',
      preferredTire1Line: 'Preferowana marka opon 1 linia  ',
      preferredTire2Line: 'Preferowana marka opon 2. linia  ',
    },
    trailer: {
      noOfUnits: 'Liczba jednostek',
      totalDistanceTravelled: 'Całkowity przebyty dystans',
      selectAxelType: 'Wybierz typ osi',
      selectTireType: 'Wybierz typ opony',
    },
    truck: {
      noOfUnits: 'Liczba jednostek',
      totalDistanceTravelled: 'Całkowity przebyty dystans',
      selectAxelType: 'Wybierz typ osi',
      selectTireType: 'Wybierz typ opony',
    },
    bus: {
      noOfUnits: 'Liczba jednostek',
      totalDistanceTravelled: 'Całkowity przebyty dystans',
      selectAxelType: 'Wybierz typ osi',
      selectTireType: 'Wybierz typ opony',
    },
    actions: {
      cancel: 'Anulować',
      update: 'Aktualizacja',
    },
    fleets: {
      fleetCheckFrequency:
        'Częstotliwość sprawdzania pozostałej floty (maks.: 365 dni)',
      selectDays: 'Wybierz Dni',
    },
  },
  userSettingsPage: {
    fName: 'Imię',
    lName: 'Nazwisko',
    orgName: 'Nazwa organizacji',
    email: 'E-mail',
    country: 'Kraj',
    deleteAcct: 'Usuń konto',
    exportData: 'Eksportuj dane',
    notification: 'Powiadomienia',
    integration: 'Integracje',
    dataExp: 'Eksport danych',
    export: 'Eksport',
    changePassword: 'Zmień hasło',
    password: 'Hasło',
  },
  fleetsSection: {
    fleet: 'Flota',
    upload: 'Wgrywać',
    trailer: 'Przyczepy',
    filter: 'Filtruj/Szukaj',
    internalGrouping: 'Grupowanie wewnętrzne',
    options: 'Opcje',
    vehicleNumber: 'Nazwa pojazdu',
    numberPlate: 'Numer rejestracyjny',
    telematics: 'Połączenia telematyczne',
    notAvailbale: 'Nie dotyczy',
    configuration: 'Konfiguracja',
    make: 'Robić',
    vehicleType: 'Typ pojazdu',
    axleType: 'Typ osi',
    currentTireConfiguration: 'Aktualna konfiguracja opon',
    configureTires: 'Skonfiguruj opony',
    notConfigured: 'Nie skonfigurowane',
    integrateTelematics: 'Zintegruj telematykę',
    usageReports: 'Raporty użytkowania',
    tireRecommendation: 'Zalecenia dotyczące opon',
    uploadMore: 'prześlij więcej pojazdów',
    navToPage: 'Przejdź do strony pojazdu',
    expand: 'Zwiększać',
    searchBy: 'Wyszukaj według',
    regNumber: 'Numer rejestracyjny',
    changelogDownload:
      'Kliknij tutaj, aby zobaczyć ostatnie zmiany w swoich pojazdach',
    vehicleNotAvailable: 'Niedostępne',
    missingTireRecommendationData:
      'Aby uzyskać rekomendację dotyczącą opon, potrzebne są zarówno raporty dotyczące konfiguracji opon, jak i użytkowania. ',
    tireStatus: 'Stan opon',
    telematicsStatus: 'Stan telematyki',
    vehicleName: 'Nazwa pojazdu',
    tracked: 'Śledzone',
    untracked: 'Nieśledzony',
    partialConfig: 'Część. ',
    configured: 'Skonfigurowane',
    mountedTires: 'Zamontowane opony',
    customerName: 'Nazwa klienta',
    deleteModal: {
      fleetNamePrompt: 'Wprowadź nazwę floty ',
      title: 'Usuń flotę',
      fieldLabel: 'Nazwa floty',
    },
    export: 'Eksportuj do pliku Excel',
    tableauBtnInfo: 'Uzyskaj dostęp do interaktywnych spostrzeżeń',
    tableauBtnName: 'Wydajność opon',
    bulkUpload: `Przesyłanie zbiorcze`,
    addTruck: 'Dodaj ciężarówkę',
    addTrailer: 'Dodaj przyczepę',
    addBus: 'Dodaj autobus',
    edit: 'Edytuj',
    delete: 'Usuń',
    deleteFleet: `Usuń flotę`,
    moveVehicles: `Przenieś pojazdy`,
  },
  level1Modal: {
    vehicleName: 'Nazwa pojazdu',
    tiresizes: 'Rozmiary opon',
    axleTypes: 'Typy osi',
    step1: 'Konfiguracja pojazdu',
    step2: 'Konfiguracja opon',
    step3: 'Potwierdzać',
    infoBannerApplyToVehicles: 'Zastosuj do jednego/wielu',
    vehiclesSelectionOption: 'Tylko ten pojazd',
    show: 'Pokazywać',
    more: 'więcej',
    less: 'mniej',
    mountedDate: 'Data zamontowania',
    retreaded: 'Bieżnikować opony',
    treadDepth: 'Głębokość bieżnika',
    applyToVehicle: 'Zastosuj się do tego ',
    selectOther: 'Wybierz inny',
    confirmApply: 'Potwierdzać',
    next: 'Następny',
    back: 'Z powrotem',
  },
  custom_dropzone: {
    mediaCenter: 'Centrum multimedialne',
    vehicleFiles: 'Pliki pojazdów',
    infoText:
      'Przeciągnij lub upuść tutaj niektóre pliki lub kliknij, aby wybrać pliki',
    error: 'Wystąpił błąd. ',
  },
  plansPage: {
    subAnnual: 'Rocznie',
    subMonthly: 'Miesięczny',
    perMonth: 'Na miesiąc',
    digitalTwin: 'Cyfrowy bliźniak Twojej floty',
    dataIntegration: 'Integracja danych telematycznych',
    savings: 'Szacowane oszczędności',
    documentFetch: 'Pobieranie dokumentów z serwisu opon',
    yourPlan: 'Twój plan',
    all: 'Wszystko',
    features: 'Cechy',
    tireRecommendations:
      'Zalecenia dotyczące opon zoptymalizowanych pod względem TCO',
    serviceCenter: 'Najbliższy serwis opon',
    dataExport: 'Eksport danych BI',
    select: 'Wybierz plan',
    tireBooking: 'Rezerwacja opon',
    tailored: 'Dopasowane do Twojej firmy',
    customizedFeatures: 'Dostosowane funkcje',
    onRequest: 'Cena na żądanie:',
    featureRequests: 'Do 3 żądań funkcji',
    customTelematics: 'Niestandardowa telematyka',
    installation: 'Instalacja telematyczna',
    contact: 'Skontaktuj się z nami',
    monthBill: 'Rozliczane co miesiąc',
    annualBill: 'Plan roczny',
    specialOffer:
      'Specjalna limitowana oferta „InnoFleet”: wszystkie przyszłe funkcje dostępne bezpłatnie do 31.12.2023',
    deminimis: 'Deminis',
  },
  subscriptionBtn: 'Aktualizacja',
  dataRoom: {
    welcome: 'Powitanie ',
    vehiclePhotos: 'Zdjęcia',
    docs: 'Akta',
    noFiles: 'Nie przesłano jeszcze żadnych plików',
    altUpload:
      'Uprość proces przechowywania dokumentów pojazdu, korzystając z jednej z poniższych opcji:',
    whatsapp: 'Infolinia Whatsapp do wysyłania plików:',
    deleteConfirm: 'Czy na pewno chcesz usunąć plik(i)?',
    clear: 'Wyczyść wybór',
    uploadPhotosBtn: 'Załadować zdjęcia',
    uploadFilesBtn: 'Prześlij pliki',
    addFilesBtn: 'Dodaj więcej plików',
    addPhotosBtn: 'Dodaj więcej zdjęć',
    uploadPhotoTitle: 'Zdjęcia pojazdów',
    uploadFilesTitle: 'Dokumenty serwisowe pojazdu',
  },
  nearestService: {
    heading: 'Najbliższy serwis opon',
    noTelematics: 'Pojazd nie jest jeszcze śledzony. ',
    serviceUnavailable: `Nie udało się uzyskać lokalizacji tego pojazdu. Proszę spróbować ponownie później lub zgłosić ten problem.`,
  },
  releaseNotes: 'Informacje o wydaniu',
  singleVehicleUpload: {
    add: 'Dodać',
    vehicle: 'Pojazd',
    truck: 'Ciężarówki/vany',
    trailer: 'Przyczepa',
    bus: 'Autobus',
    next: 'Następny',
    vehicleName: 'Nazwa pojazdu',
    vin: 'Win',
    chassisNumber: 'Numer podwozia',
    fleetName: 'Nazwa floty',
    selectFleet: 'Wybierz Flotę',
    chooseFleet: 'Wybierz Flotę',
    addNewFleet: 'Dodaj nową flotę',
    fleetLocation: 'Lokalizacja floty',
    tankCapacity: 'Pojemność baku',
    regPlate: 'Tablicy rejestracyjnej',
    internalGrouping: 'Grupowanie wewnętrzne ',
    cancel: 'Anulować',
    addVehicleButton: 'Dodaj pojazd',
    duplicateError:
      'Jeden lub więcej wprowadzonych danych jest już używanych przez inny pojazd. ',
    success: 'Pojazd dodano pomyślnie',
    safetyCheckLabel: 'Data kontroli bezpieczeństwa',
    inspectionDateLabel: 'Data następnej kontroli',
    odometerLabel: 'Bieżący odczyt licznika kilometrów',
    vinCharsWarning: 'powinien mieć co najmniej 17 znaków',
    vinCharsWarning2: 'nie powinien przekraczać 20 znaków',
    regPlateCharsWarning: 'Wartość nie powinna przekraczać 12 znaków',
    single: 'Pojedyncze',
    multiple: 'Wielokrotne przesyłanie',
    axleType: 'Typ osi',
    steer: 'Rozmiar przedniego koła',
    drive: 'Rozmiar tylnego koła',
    tireSize: 'Rozmiar opony',
  },
  vehiclePage: {
    resourceBtn: 'Przypisz zasób',
    assignResources: 'Przypisz zasób',
    bulkUpload: 'Przydział masy',
    assignSuccessMsg: 'Pomyślnie przydziel zasoby',
    assignFailureMsg: 'Coś poszło nie tak',
    tireConfig: 'Konfiguracja opon',
    vehicleFile: 'Plik pojazdu',
    reports: 'Raporty użytkowania',
    recommendation: 'Zalecenia dotyczące opon',
    tireService: 'Najbliższy serwis opon',
    grouping: 'Grupowanie',
    regNumber: 'Numer rejestracyjny',
    brand: 'Marka pojazdu',
    chassis: 'Numer podwozia',
    type: 'Typ',
    drawerBtn1: 'Ciężarówki/vany',
    retread: 'Strategia bieżnikowania',
    tank: 'Pojemność baku',
    noAccessResponse:
      'Aby uzyskać dostęp do tej sekcji, zintegruj telematykę ze swoimi pojazdami',
    updateSuccess: 'Wpisy zostały pomyślnie zaktualizowane',
    tooltipUpdateIcon: 'Aktualizuj informacje o pojeździe',
    tankCapacity: 'Pojemność baku',
    inspection: 'Następna kontrola',
    safetyCheck: 'Następna kontrola bezpieczeństwa',
    odometer: 'Odczyt licznika kilometrów',
    odometerLabel: 'Licznik (Telematyka)',
    mergeStatus: `Stan scalania`,
    pending: 'Zaktualizuj brakujące informacje!',
    dialogPromptFirst1: 'Dołączasz ',
    dialogPromptFirst2: 'Odłączasz się ',
    dialogPromptSecond1: ' do tej ciężarówki...',
    dialogPromptSecond2: ' z tej ciężarówki...',
    dialogTitle: 'Dołącz nowy zwiastun',
    dialogTitle2: 'Odłącz przyczepę',
    selectLabel: 'Powiązany zwiastun: ',
    attachSuccess: 'Pomyślnie dołączono zwiastun!',
    steer: 'Rozmiar opony sterowanej',
    drive: 'Rozmiar opony napędowej',
    tireSize: 'Rozmiar opony',
    axle: 'Oś',
    deleteModal: {
      truckNamePrompt: 'Wpisz nazwę ciężarówki ',
      trailerNamePrompt: 'Wpisz nazwę przyczepy ',
      deleteTruck: 'Usuń ciężarówkę',
      deleteTrailer: 'Usuń zwiastun',
    },
    updateVehicleHeading: 'Aktualizacja informacji o pojeździe',
  },
  platformInfoRoute: {
    scheduleMeeting: 'Zaplanować spotkanie',
    writeMessage: 'Napisz do nas wiadomość',
    callUs: 'Zadzwoń do nas',
    tutorials: 'Poradniki',
  },
  tireConfigPage: {
    retreadConfigBtn: 'Skonfiguruj strategię bieżnikowania',
    brand: 'Marka',
    hotRetread: 'Gorący bieżnik',
    coldRetread: 'Bieżnikowanie na zimno',
    changelogDownload: 'Pobierz historię opon',
    axlConfiguredSuccess: 'Pomyślnie ustawiono rozmiar osi i opon',
    axleSelect: 'Wybierz typ osi',
    tireSelect: 'Wybierz Rozmiar opony',
    swapFail: 'Zamiana nie powiodła się. ',
    more: 'Więcej',
    confirm: 'Potwierdzać',
    configureTirePrompt: 'Skonfiguruj swoje opony',
    level2Modal: {
      noBrands: 'Brak marek dostępnych dla tego rozmiaru opon',
      tireBrand: 'Marka opon',
      others: 'Inni',
      showMore: 'Pokaż więcej',
      showLess: 'Pokaż mniej',
      productLines: 'Linie produktów',
      choose: 'Wybierz w tym celu typ konfiguracji',
      tireOnly: 'Tylko opona',
      axleOnly: 'Tylko oś',
      entireVehicle: 'Cały pojazd',
      apply: 'Stosować',
    },
    moreOptionsModal: {
      safetyCheckLabel: 'Data kontroli bezpieczeństwa',
      inspectionDateLabel: 'Data następnej kontroli',
      odometerLabel: 'Bieżący odczyt licznika kilometrów',
    },
    liftAxlBtn: 'Skonfiguruj osie podnoszone',
    liftAxle: {
      successMsg: 'Osie unoszone pomyślnie skonfigurowane!',
      errorMsg: 'Nie można podnosić wszystkich osi!',
      axle: 'Oś',
      lifted: 'wzniesiony',
      notLifted: 'obniżony',
    },
    axleLifted: 'Oś jest podniesiona',
    axleLowered: 'Oś obniżona',
    noRetreadMsg: 'Bieżnikowanie nie zostało skonfigurowane',
    tireRetreadInfo: 'Opona jest bieżnikowana',
    headingLeft: 'Szczegóły konfiguracji opon',
    tableView: 'Widok tabeli',
    updateTireBtn: 'Aktualizuj oponę',
    changeTireBtn: 'Zmień oponę',
    unmountTireBtn: 'Zdemontuj oponę',
    uploadServiceReportBtn: `Prześlij raport usługi`,
  },
  fleetsDataPage: {
    fleet: 'Flota',
    trucks: 'Ciężarówki/vany',
    buses: 'Autobusy',
    upload: 'Wgrywać',
    trailer: 'Przyczepy',
    noFleets: 'Brak dostępnych flot. ',
  },
  header: {
    homeDashboard: 'Panel główny',
    telematics: 'Telematyka',
    fleetsSection: 'Flota',
    changelog: 'Informacje o wydaniu',
    reports: 'Raporty',
    plans: '',
    settings: 'Ustawienia',
    fleet: 'Flota',
    inventory: 'Spis',
  },
  tileToolTip: {
    fleet: {
      trucks: 'Praca w toku',
      trailers: 'Praca w toku',
      vehicleInfo: 'Praca w toku',
    },
    telematics: {
      integratedProvides:
        'Są to różne złącza telematyczne dostępne do udostępniania danych. ',
      authorization:
        'Wymagane są tutaj poświadczenia niezbędne do autoryzacji w API Gateway.',
      connectedProviders: 'Są to dostawcy podłączeni do Twojego konta',
    },
    home: {
      actionsRequired: 'W tej sekcji wyświetlane są otwarte zadania. ',
    },
  },
  tour: {
    first:
      'Tutaj widzisz skonsolidowane informacje o swojej flocie w zakresie wymaganych działań, zarządzania aktywami i szacunkowych oszczędności',
    second:
      'Tutaj możesz umożliwić bezproblemową integrację swojego dostawcy telematyki z naszą aplikacją, po prostu ładując dane uwierzytelniające za pomocą zaledwie dwóch kliknięć',
    third:
      'Tutaj możesz skonfigurować swoją flotę, dodając pojazdy i aktualizując ich konfiguracje indywidualnie lub za pomocą przesyłania zbiorczego',
    fourth:
      'Tutaj możesz dodawać, aktualizować i zarządzać oponami w swoim ekwipunku.',
    fifth: 'Tutaj możesz sprawdzić raporty.',
    sixth: 'Tutaj możesz użyć różnych ustawień. ',
    seventh:
      'Tutaj możesz zwrócić się do nas o pomoc i zasoby niezbędne do zrozumienia zakresu produktu',
    eighth:
      'Tutaj możesz uaktualnić swój plan z wersji próbnej do wersji premium',
    page: {
      home: {
        actionsRequired:
          'Tutaj możesz zobaczyć różne działania wymagane dla Twoich pojazdów.',
        savingPotential:
          'Tutaj możesz zobaczyć wskaźniki potencjalnych oszczędności.',
        homeBasicInfos:
          'Tutaj możesz zobaczyć ogólny stan profilu swojej organizacji.',
      },
      telematics: {
        integratedTelematicsProvider:
          'Tutaj możesz zobaczyć informacje o dostawcach usług telematycznych.',
        provider:
          'Tutaj możesz wybrać obsługiwanych dostawców usług telematycznych.',
        authorization:
          'W tym miejscu możesz upoważnić siebie w imieniu swojej organizacji do wykonywania zadań na poziomie organizacji.',
      },
      fleet: {
        recentChanges:
          'Tutaj możesz sprawdzić ostatnie zmiany wprowadzone w Twoich flotach.',
        trucks:
          'Tutaj możesz dodawać ciężarówki lub aktualizować ich konfiguracje indywidualnie lub za pomocą przesyłania zbiorczego',
        trailers:
          'Tutaj możesz dodawać zwiastuny lub aktualizować ich konfiguracje indywidualnie lub za pomocą przesyłania zbiorczego',
        table:
          'Ta tabela pokazuje szczegółowe informacje dotyczące wszystkich pojazdów tej floty. ',
      },
      vehiclePage: {
        tireConfig: 'Tutaj możesz przeglądać i konfigurować opony.',
        vehicleFile:
          'Tutaj możesz przeglądać i przesyłać zdjęcia, filmy i pliki swoich pojazdów.',
        reports: 'Tutaj możesz zobaczyć raporty użytkowania swojego pojazdu.',
        recommendations:
          'Tutaj możesz na podstawie analizy uzyskać rekomendacje dotyczące odpowiednich opon do Twojego pojazdu.',
        tireService:
          'Tutaj możesz sprawdzić najbliższy serwis opon dla swojego pojazdu.',
        basicVehicleInfo:
          'Tutaj możesz zobaczyć podstawowe informacje o swoich pojazdach.',
      },
      savingPotentials: {
        metricTile:
          'Tutaj możesz zobaczyć różne wskaźniki potencjalnych oszczędności w formacie interaktywnym.',
      },
      settings: {
        profileSettings: 'Tutaj możesz zaktualizować swój profil.',
        fleetSettings:
          'Tutaj możesz zaktualizować ustawienia powiązane z Twoją organizacją oraz popularną markę opon, ciężarówek, przyczep lub strategię wycofywania stosowaną przez Twoją organizację.',
        userMgt:
          'Tutaj możesz zapraszać nowych użytkowników, usuwać istniejących, a także zarządzać przydzielonymi im zasobami',
      },
    },
  },
  retreadModal: {
    coldRetread: 'Czy wolisz bieżnikowanie na zimno?',
    hotRetread: 'Czy wolisz bieżnikowanie na gorąco?',
    casing: 'Jaka jest Twoja preferowana bieżnikowana osnowa?',
    retreadPreference: 'Czy bieżnikowanie jest preferowane?',
    apply: 'Zastosuj się do tego  ',
    others: 'Uwzględnij inne ',
    otherTrucks: 'Uwzględnij inne ciężarówki',
    otherTrailers: 'Dołącz inne zwiastuny',
    otherBuses: 'Uwzględnij inne autobusy',
    select: 'Wybierać',
  },
  recommendationPage: {
    noTires: 'Opony do tego pojazdu nie zostały jeszcze skonfigurowane',
    noTiresMessage:
      'Skonfiguruj opony i sprawdź ponownie po dniu, aby uzyskać zalecenia dotyczące opon',
    noTelematicsMessage:
      'Kliknij TUTAJ, aby połączyć się z telematyką i uzyskać rekomendacje opon na podstawie danych',
    noRecommendationMessage:
      'Wróć po dniu, aby zobaczyć zalecenia dotyczące opon',
    tireMgt: 'Przegląd zarządzania oponami',
    update: 'Zaktualizuj opony',
    book: 'Książka',
    exclusionPlaceholder: 'Wyklucz tutaj marki...',
    inclusionPlaceholder: 'Uwzględnij tutaj marki...',
    showMore: 'Pokaż więcej',
    collapse: 'Zawalić się',
    empty: 'Żadne wyniki nie pasują do wybranego filtra',
    customizeRecommendations: 'Dostosuj rekomendacje',
    runningReading: 'Przebieg',
    retreadable: 'Możliwość bieżnikowania',
    fuelConsumption: 'zużycie paliwa',
    rollingResistance: 'Opory toczenia',
    traction: 'Trakcja',
    carcassValue: 'Wartość obudowy',
    price: 'Cena',
    applyFilter: 'Nałóż filtr',
    partialConfiguredMessage: 'Niekompletny komplet opon. ',
    captions: {
      runningReading:
        'Przebieg będzie czynnikiem decydującym w rekomendacjach (wyższy przebieg życia jest lepszy)',
      retreadable:
        'Zaznacz to, jeśli chcesz, aby Twój opona mogła być regenerowana',
      rollingResistance:
        'Zaznacz to, jeśli chcesz, aby RRC był częścią obliczeń (niższy RRC jest lepszy)',
      traction:
        'Zaznacz to, jeśli chcesz opony o lepszej przyczepności (ustala to dolny limit dla przyczepności opon)',
      price:
        'Cena będzie czynnikiem decydującym w rekomendacjach (tańsza jest lepsza)',
      fuelConsumption:
        'Zaznacz, jeśli chcesz uwzględnić szacunki zużycia paliwa przez opony w obliczeniach (mniej paliwa jest lepsze)',
      sameBrand:
        'Dla ciężarówek i autobusów zapewni to, że marka na osiach kierujących i napędowych będzie taka sama.',
    },
    sameBrand: 'Ta sama marka',
  },
  retreadStrategy: {
    casting_type:
      'Jakiego rodzaju karkas chciałbyś zastosować w oponach bieżnikowanych dla swojej floty?',
    mount_type: 'Gdzie chcesz montować opony bieżnikowane?',
    delivery_schedule:
      'Jakiego harmonogramu dostaw oczekujesz od osłon swoich bieżników?',
  },
  usageReportsPage: {
    nextDay: 'Następny dzień',
    nextWeek: 'W następnym tygodniu',
    prevDay: 'Poprzedni dzień',
    prevWeek: 'Poprzedni tydzień',
    noTelematicsMessage:
      'Kliknij TUTAJ, aby połączyć telematykę i uzyskać raporty użytkowania oparte na danych',
    noReportsMessage:
      'Sprawdź ponownie po dniu, aby zobaczyć raporty użytkowania',
    noTiresMessage:
      'Skonfiguruj opony i sprawdź ponownie po dniu, aby uzyskać raporty użytkowania',
    download: 'Pobierz raport',
    noDataDay: 'Brak danych dla wybranej daty',
    noDataWeek: 'Brak danych dla wybranego tygodnia',
    reportType: 'Typ raportu',
    daily: 'Codziennie',
    weekly: 'Co tydzień',
    selectDay: 'Wybierz datę',
    selectWeek: 'Wybierz Tydzień',
    kpiTitles: {
      mission_profile: 'Profil misji',
      average_speed: 'Średnia prędkość',
      minimum_change_in_elevation: 'Minimalna zmiana wysokości',
      average_change_in_elevation: 'Średnia zmiana wysokości',
      maximum_change_in_elevation: 'Maksymalna zmiana wysokości',
      total_fuel_burnt: 'Całkowite spalone paliwo',
      total_co2_emissions: 'Całkowita emisja CO2',
      total_distance_driven: 'Całkowita przebyta odległość',
      total_number_of_trips: 'Całkowita liczba podróży',
      average_fuel_consumption: 'Średnie zużycie paliwa',
      total_time_on_the_move: 'Całkowity czas w ruchu',
      heat_map: 'Mapa ciepła',
      idling_locations: 'Miejsca na biegu jałowym',
      '3PMS_needed': 'Potrzebna godzina 15:00',
      fuel_level_drop: 'Spadek poziomu paliwa',
      'm+s_needed': 'Potrzebny MS',
      distribution: 'Dystrybucja (%)',
    },
  },
  uploadSummary: {
    vehicleUploadSummary: 'Podsumowanie przesyłania pojazdu',
    fleetName: 'Nazwa floty',
    vehicleName: 'Nazwa pojazdu',
    status: 'Prześlij stan',
    result: 'Wynik',
    downloadSummary: 'Pobierz podsumowanie',
    back: 'Z powrotem',
    fileType: 'Wybierz Typ pliku',
  },
  kpiChart: {
    days: 'Dni',
    weeks: 'Tygodnie',
    download: 'Pobierać',
  },
  telematicsModal: {
    title: 'Informacje telematyczne pojazdu',
    noVehicle1: 'Żaden zarejestrowany pojazd nie jest obecnie zintegrowany',
    noVehicle2: 'Nie znaleziono pojazdów',
    noAccess: 'Nie dotyczy... Nie przypisano Ci tego pojazdu.',
    downloadMsg:
      'Uzyskaj listę niezarejestrowanych pojazdów w odpowiednim formacie',
  },
  multiLayerReport: {
    selectFleet: 'Wybierz Floty',
    selectFleet2: 'Wybierz Floty',
    selectVehicles: 'Wybierz Pojazdy',
    selectKpis: 'Wybierz opcję Kpis',
    generateReport: 'Generuj raport',
    downloadReport: 'Pobierz raport',
    noTrackedVehicles: 'Brak pojazdów gąsienicowych',
    select: 'Zaznacz wszystko',
    deselect: 'Odznacz wszystkie',
    mission_profile: 'Profil misji',
    total_share_motorway: 'Całkowity udział autostrady',
    total_share_regional: 'Łączny udział regionalny',
    total_share_urban: 'Łączny udział Miejski',
    total_number_of_trips: 'Całkowita liczba podróży',
    average_speed: 'Średnia prędkość',
    total_distance_driven: 'Całkowita przebyta odległość',
    tire_configuration: 'Konfiguracja opon',
    waitMessage: 'Generowanie raportu. ',
    waitMessage2: 'Dodawanie wykończeń...',
    overview: 'Przegląd opon',
    reportType: 'Typ raportu',
    startDate: 'Data rozpoczęcia (opcjonalnie)',
    endDate: 'Data zakończenia (opcjonalnie)',
    reportTypes: {
      list: 'Lista pojazdów',
      inventory: 'Inwentarz opon',
      tireChangelog: 'Dziennik zmian opon',
      vehicleChangelog: 'Dziennik zmian pojazdu',
      usage: 'Raporty użytkowania pojazdu',
    },
    reportPageTitle: `Raporty`,
    create: `Utwórz`,
  },
  userManagement: {
    user: 'Użytkownik',
    users: 'Użytkownicy',
    invite: 'Utwórz nowego użytkownika',
    inviteBtn: 'Stwórz użytkownika',
    inviteDetails:
      'Wpisz dane użytkownika, którego chcesz zaprosić i wybierz\n    ',
    accessType: 'Typ dostępu',
    assignResourceDetails:
      'Wybierz zasoby, do których ma mieć dostęp zaproszony użytkownik',
    assignResources: 'Przypisz zasoby',
    deleteResources: 'Anuluj przypisanie zasobów',
    lastLogin: 'Ostatnie logowanie',
    actions: 'działania',
    verified: 'Zweryfikowano',
    pending: 'Aż do',
    successDelete: 'Pomyślnie usunięto użytkownika',
    deletePrompt1: 'Potwierdź, że chcesz usunąć konto zaproszonego użytkownika',
    deletePrompt2: '. ',
    deleteTooltip: 'Usuń użytkownika',
    editAccess: 'Zugänglichkeit bearbeiten',
    confirm: 'Potwierdzać',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    email: 'E-mail',
    role: 'Rola',
    position: 'Pozycja',
    depot: 'Magazyn',
    locale: 'widownia',
    editTooltip: 'Edytuj rolę',
    successEdit: 'Rola użytkownika została pomyślnie zmieniona',
    editRole: 'Edytuj rolę',
  },
  assetsWhitelisting: {
    selectTrailer: 'Wybierz zwiastun(y)',
    selectTruck: 'Wybierz ciężarówkę(-y)',
    success: 'Operacja Udana!',
    noTrucksToDel: 'Użytkownikowi nie przydzielono ciężarówek w tej flocie',
    noTrailersToDel: 'Użytkownikowi nie przypisano przyczep w tej flocie',
    noBusesToDel: 'Użytkownikowi nie przydzielono autobusów w tej flocie',
    selectBus: 'Wybierz autobus(y)',
    noBuses: 'Brak autobusów we flocie',
    noTrucks: 'Brak pojazdów ciężarowych we flocie',
    noTrailers: 'Brak przyczep we flocie',
    noFleets: 'Użytkownikowi nie przypisano żadnej floty',
    currentlyAssigned: 'Aktualnie przydzielone: ',
  },
  changePassword: {
    enter_email: 'Wprowadź swój email',
    logout: 'Zaraz się wylogujesz ',
    change_password: 'Zmień hasło',
    message: ' Klikając przycisk Prześlij, wyrażasz zgodę na nasze T',
  },
  tireOperationsModal: {
    updateBtn: 'Zaktualizuj oponę',
    changeBtn: 'Zmień oponę',
    unmountBtn: 'Przejdź do magazynu',
    availableTires: 'Dostępne opony',
    noTiresMsg: 'Brak dostępnych opon',
    uploadTireSuggestion: 'Opony nie ma w Twoim ekwipunku? ',
    here: 'Tutaj',
    updateHeading: 'Zaktualizuj informacje o oponach',
    unmountReasonLabel: 'Wybierz powód odmontowania',
    unmountDateLabel: 'Odmontuj datę',
    mountDateLabel: 'Data montażu',
    unmountTireTitle: 'Zdemontuj starą oponę w położeniu osi',
    mountTireTitle: 'Wybierz opcję Nowa opona w położeniu osi',
    mountWarningMsg: `Klikając ten przycisk, opona (opony) zostanie zamontowana w wybranych pozycjach.`,
    eventDate: 'Data wydarzenia',
    backToHome: 'Przejdź do pierwszej strony',
    unmountReasons: {
      worn: 'Zużyty',
      repair: 'Naprawa',
      warranty: 'Gwarancja',
      smashed: 'Rozbity',
      puncture: 'Przebicie',
      store: 'Składowanie',
    },
    mountOne: `Zamontuj na tej pozycji`,
    mountMany: `Zamontuj na więcej pozycjach`,
  },
  deleteTire: {
    label: 'Wprowadź identyfikator opony',
    prompt: 'Wprowadź identyfikator opony',
    delete: 'Usuwać',
    warning: 'Ta czynność spowoduje trwałe usunięcie tej opony z Twojego\n    ',
    warning2: 'To działanie trwale usunie te opony z Twojego inwentarza\n    ',
    deleteSuccessful: 'Opona została pomyślnie usunięta',
  },
  manageTire: {
    title: 'Działania opon',
  },
  tireInventoryItems: {
    tDepthStorage: 'Średnia głębokość bieżnika na oponę w sklepie',
    tDepthInUse: 'Średnia głębokość bieżnika na używaną oponę',
    avgTreadDepthPerTireStore:
      'Średnia głębokość bieżnika/opona (przechowywanie)',
    avgTreadDepthPerTireInUse: 'Średnia głębokość bieżnika/opona (w użyciu)',
    filter: 'Filtr',
    size: 'Rozmiar opony',
    status: 'Stan opon',
    vName: 'Nazwa pojazdu',
    maxDepth: 'Maks. ',
    wornOut: 'Zużyty',
    totalTires: 'Razem opony',
    viewAllTires: 'Wyświetl wszystkie opony',
    addTires: 'Dodaj opony',
    unmerged: 'Niepołączone',
    coupled: 'W połączeniu z ciężarówką',
    titles: {
      mileage: 'Przebieg',
      name: 'Nazwa pojazdu',
      treadDepth: 'Ostatnia głębokość bieżnika',
      estTreadDepth: 'Głębokość bieżnika (szac.)',
      estimated_current_tread_depth: 'Głębokość bieżnika (szac.)',
      estimated_wear_out_date: 'Data zużycia (szac.)',
      mountDate: 'Data montażu',
      mountDays: 'Dni Górskie',
      lastUpdate: 'Ostatnia aktualizacja',
      lastVehicleName: 'Ostatnia nazwa pojazdu',
      eventDate: 'Data wydarzenia',
      eventType: 'Typ wydarzenia',
      position: 'Pozycja zamontowana',
      vehicle: 'Pojazd',
      pressure: 'Ciśnienie w oponach',
      actions: 'działania',
      size: 'Rozmiar',
      brand: 'Marka',
      product_line: 'Linia produkcyjna',
      status: 'Status',
      tire_id: 'Identyfikator opony',
      inUse: 'W użyciu',
      inStore: 'W sklepie',
      monthsLeft: 'Pozostałe miesiące (szac.)',
      axlePosition: 'Pozycja osi',
      mission_profile: 'Profil misji',
      isRetread: 'Bieżnikowane',
      isRegrooved: `Usunięty`,
      tDepth: 'Głębokość bieżnika',
      tDepthEst: 'Głębokość bieżnika (Szacowana)',
      tire: 'Opona / ID opony',
      km: 'Przebieg',
      rfid: 'Rfid',
      lastEventType: 'Typ ostatniego zdarzenia',
    },
    noData: 'Brak danych do wyświetlenia',
    statuses: {
      IN_USE: 'W użyciu',
      STORAGE: 'Składowanie',
      RECYCLING_BANK: 'Banku Recyklingu',
      RETREAD: 'Bieżnikowanie',
      REGROOVE: 'W sklepie',
      repair: 'Naprawa',
      storage: `Przechowywanie`,
      recyclingBank: `Bank Recyklingu`,
      retread: `Bieżnikowanie`,
      all: `Wszystkie`,
    },
    regrooved: 'Pogłębione',
    dispose: 'Dysponować',
  },
  swapAction: {
    success: 'Wymiana opon przebiegła pomyślnie',
    info: 'Zamieniasz położenie opon. ',
  },
  maintencePage: {
    title: 'Trwają prace konserwacyjne',
    description: 'Planowana konserwacja jest w toku. ',
    apology: 'Przepraszam za niedogodności.',
  },
  tireRecommendation: {
    header: {
      estimatedPriceAllTires: 'Szacunkowa cena (wszystkie opony)',
      estimatedKmPrice: 'Szacowany koszt/100 km (€)',
      fuelSaveLperKM: 'Oszczędność paliwa w litrach/100 km',
      theoreticalMileage: 'Przebieg teoretyczny',
      estimatedSavingsPotentialTco: 'Szacowane oszczędności TCO (€)',
      tcoRating: 'Ocena całkowitego kosztu posiadania',
      trailerBrand: 'Opona: Przyczepa',
      steerBrand: 'Opona: Kieruj ',
      driveBrand: 'Opona: Jedź',
      buy: 'Kupić',
      monthlyDistance: 'Średni miesięczny dystans (km)',
      savingsInTco: 'Potencjał oszczędności w TCO (€)',
      amortization: 'Okres amortyzacji (miesiące)',
    },
    info: 'Potrzebujemy pojazdu w pełni skonfigurowanego, aby uzyskać rekomendacje\n    ',
    button: 'Zobacz zalecenia bez konfiguracji opon',
    or: 'LUB',
    noTireSizeMessage: 'Błąd analizy opon: nie znaleziono rozmiaru opony. ',
  },
  escalateMessage: {
    successMessage: 'Wiadomość wysłana.  ',
    header: 'Zgłoś się do nas',
    iconTooltip: 'Zgłoś się do nas',
    message: 'Wiadomość',
  },
  vehicleTypeDeleteModal: {
    remove: 'Usunąć',
    trucks: 'samochody ciężarowe',
    trailers: 'przyczepy',
    confirm: 'Czy na pewno chcesz usunąć wszystko? ',
    delete: 'Usuwać',
    terms_and_condition: ' Klikając Usuń, wyrażasz zgodę na nasze T',
  },
  eventHistoryModal: {
    revertSuccess: 'Pomyślnie przywrócono zdarzenie związane z oponą',
    dialogMessage: 'Cofasz ostatnie zdarzenie w tej oponie...',
    revertEvent: 'Cofnij ostatnie wydarzenie',
  },
  tireForm: {
    brands: 'Marki',
    others: 'inne marki',
  },
  deleteUserModal: {
    nameLabel: 'Wpisz swoje imię',
    deleteUserPrompt:
      'Usunięcie konta spowoduje trwałe usunięcie wszystkich zapisanych danych.\n    ',
    deleteVehicleWarning:
      'Kiedy usuniesz swój pojazd, wszystkie powiązane z nim dane zostaną trwale usunięte. ',
    deleteFleetWarning:
      'Kiedy usuniesz tę flotę, wszystkie powiązane dane zostaną trwale usunięte. ',
  },
  deleteResource: {
    truckName: 'Nazwa ciężarówki',
    trailerName: 'Nazwa przyczepy',
    fleetName: 'Nazwa floty',
  },
  tiresUpload: {
    brandMissing: 'Nie możesz znaleźć marki opon?',
    enterBtn: 'Wprowadzić ręcznie',
    brand: 'Marka opon',
    productLine: 'Linia produkcyjna',
  },
  headers: {
    name: 'Nazwa',
    vin: 'win',
    fleet_name: 'Nazwa floty',
    reg_plate: 'Płyta rej',
    tank_capacity: 'Pojemność baku',
    fleet_location: 'Lokalizacja floty',
    internal_grouping: 'Grupowanie wewnętrzne',
    chassis_number: 'Numer podwozia',
    odometer_reading: 'Odczyt licznika przebiegu (opcjonalnie)',
    inspection_date: 'Data kontroli (opcjonalnie)',
    safety_check_date: 'Data kontroli bezpieczeństwa (opcjonalnie)',
  },
  filterComponent: {
    filter: `Filtruj`,
    clear: `Wyczyść wszystko`,
    zeroMatches: `Brak dopasowań`,
  },
  newFleetModal: {
    heading: 'Dodaj nową flotę',
    fleetName: 'Nazwa floty',
    yards: 'Liczba magazynów',
    zip: 'Kod pocztowy',
    country: 'Wybierz kraj',
    city: 'Wprowadź miasto',
    cancel: 'Anuluj',
    confirm: 'Potwierdź',
    location: `Lokalizacja floty`,
  },
  settingsPage: {
    tireSettings: {
      header: 'Ceny opon',
      priceId: 'Cena opon',
      tireSize: 'Rozmiar opony',
      brand: 'Marka',
      productLine: 'Linia produkcyjna',
      addTireLabel: 'Dodaj cenę opon',
      createTirePrice: 'Utwórz cenę opon',
      updateTirePrice: 'Zaktualizuj cenę opon',
      tirePrice: 'Cena opon',
    },
    notifications: {
      heading: 'Subskrybuj lub anuluj subskrypcję powiadomień e-mail...',
      selectUser: 'Wybierz użytkownika',
      weeklyLabel: 'Podsumowanie organizacji',
      fleetCheckRemainder: 'Pozostała część kontroli floty',
      userReport: 'Podsumowanie użytkownika',
      weeklyBody: 'Otrzymuj e-maile podsumowujące działania całej organizacji.',
      fleetCheckDesc:
        'Otrzymuj e-maile z działaniami dotyczącymi sprawdzania floty',
      tDepth: 'Niski bieżnik',
      tDepthBody:
        'Otrzymaj podsumowanie e-mailem, gdy głębokość bieżnika opony będzie wynosić od 3 do 5 mm lub poniżej 3 mm.',
      userBodyDesc:
        'Erhalten Sie E-Mails mit einer personalisierten ZusammenfassungIhrer Aktivitäten',
      select: 'Wybierz dni',
      selectDay: `Wybierz dzień`,
      daily: 'dziennie',
      weekly: 'tygodniowo',
      monthly: 'co miesiąc',
      biannually: 'dwa razy w roku',
      days: {
        monday: `Poniedziałek`,
        tuesday: `Wtorek`,
        wednesday: `Środa`,
        thursday: `Czwartek`,
        friday: `Piątek`,
        saturday: `Sobota`,
        sunday: `Niedziela`,
      },
    },
  },
  tableauPage: {
    errorMessage: `Błąd podczas pobierania danych!`,
    refresh: `Spróbuj ponownie`,
  },
  axles: {
    '1-axle': '1-axle',
    '2-axle': '2-axle',
    '3-axle': '3-axle',
    '4-axle': '4-axle',
    '5-axle': '5-axle',
    '1-axle-twin': '1-axle-twin',
    '2-axle-twin': '2-axle-twin',
    null: 'N/A',
  },
  treadDepthSource: {
    EST: 'Ta wartość jest naszą najlepszą przybliżoną wartością',
    GT: 'Ta wartość pochodzi z danych rzeczywistych',
  },
  columnsEdit: {
    update: `Aktualizuj`,
    editBtn: `Spalten bearbeiten`,
    error: `Za dużo kolumn do wyświetlenia, proszę usunąć jedną lub więcej`,
  },
  grooveTD: {
    selectGroove: 'Czy chcesz dodać głębokość bieżnika rowka?',
    grooveTreadDepth: 'Głębokość bieżnika rowka',
  },
  guidesPage: {
    title: {
      telematics: `Skonfiguruj telematykę`,
      upload_vehicles: `Prześlij pojazdy`,
      addTires: `Dodaj pojedynczą oponę`,
      'user-mgt': `Zaproś współpracowników`,
      notifications: `Skonfiguruj powiadomienia`,
      optimal_pressure: `Ustaw optymalne ciśnienie`,
    },
    description: {
      telematics: `Wybierz swojego dostawcę telematyki i zintegrować go z naszą aplikacją`,
      upload_vehicles: `Dodaj swoje ciężarówki, przyczepy lub autobusy indywidualnie lub prześlij arkusz`,
      addTires: `Dodaj oponę do swojego inwentarza`,
      'user-mgt': `Zaproś osoby do korzystania z aplikacji i nadaj im różne poziomy dostępu`,
      notifications: `Subskrybuj lub wypisz się z powiadomień e-mail`,
      optimal_pressure: `Ustaw optymalne ciśnienie w oponach na podstawie osi`,
    },
    heading: `Przewodniki użytkownika`,
    pageTitle: 'Zapoznaj się z aplikacją i wykonaj zadania...',
    tour: {
      notifications:
        'Tutaj możesz zarządzać powiadomieniami e-mail, które otrzymujesz',
      optimalPressure:
        'Kliknij, aby ustawić/zaktualizować optymalne ciśnienie w oponach',
      invite: 'Zaproś tutaj kolegę',
      addVehicle: 'Dodaj pojedynczy pojazd lub kilka jednocześnie',
      addTire: 'Dodaj pojedynczą oponę lub kilka jednocześnie',
    },
  },
};

export default pl;

import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateGuideSelection } from 'redux/users/ducks';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    content: {
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.5),
    },
    typography: {
      fontWeight: 500,
    },
  };
});

const getHook = tab_name => {
  let hook = '';
  for (let i = 0; i < tab_name.length; i++) {
    if (tab_name.charAt(i) !== ' ') {
      hook += tab_name.charAt(i);
    } else {
      break;
    }
  }

  return hook;
};

export default function CustomAccordion({ items }) {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const guideSelectionSelector = useSelector(
    state => state.users.guideSelection
  );

  const handleClick = (e, selected) => {
    if (guideSelectionSelector !== selected) {
      dispatch(updateGuideSelection(selected));
    }
  };

  return (
    <div className={classes.root}>
      {items.map(({ summary, Component, id }, i) => (
        <Accordion
          key={i}
          id={id}
          expanded={id === guideSelectionSelector}
          onClick={e => handleClick(e, id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
            data-hook={`${getHook(summary)}_tab`}
          >
            <Typography variant='h5' className={classes.typography}>
              {summary}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.content}>
            <Component />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

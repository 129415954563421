import React, { useEffect } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import { VEHICLE } from 'utils/string_utils';
import CustomTextField from 'components/custom/CustomTextField';
import {
  optimalPressureByAxleType,
  vehicle_resource,
} from 'pages/VehiclePage/TireConfig/const';
import { useSelector } from 'react-redux';
import { tiresDbSelector } from 'redux/tireInventory/ducks';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from 'components/custom/CustomDatePicker';
import { fleetOverviewSelector } from 'redux/fleets/ducks';

const PLATE = 'reg_plate';

const useStyles = makeStyles()(theme => {
  return {
    date: {
      width: '100%',
      margin: 0,
    },
    grid: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    btnStyle: {
      flex: '30%',
      borderRadius: 24,
      textTransform: 'unset',
    },
  };
});

const AddSingleVehicle = props => {
  const tiresResource = useSelector(tiresDbSelector);
  const availableSizes = [...new Set(tiresResource?.sizes)];
  const { errors, setErrors, values, setValues, vehicleType, isSubmitting } =
    props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const general_configs = useSelector(fleetOverviewSelector)?.general_configs;
  const defaultOptimumAirPressure =
    general_configs[vehicleType]?.optimum_air_pressure;

  useEffect(() => {
    const x = errors.optimum_air_pressure || {};
    const y = values.optimum_air_pressure || {};
    if (Object.values(x).every(val => !val)) {
      setErrors({ ...errors, optimum_air_pressure: null });
    }
    if (Object.values(y).every(val => !val)) {
      setValues({ ...values, optimum_air_pressure: null });
    }
    // eslint-disable-next-line
  }, [errors.optimum_air_pressure, values.optimum_air_pressure]);

  useEffect(() => {
    if (values?.axle_type) {
      setValues({
        ...values,
        optimum_air_pressure:
          defaultOptimumAirPressure?.[values.axle_type] || null,
      });
    }
    // eslint-disable-next-line
  }, [values?.axle_type]);

  const renderFleetsItemList = () => {
    return props.fleets.map((fleet, i) => (
      <MenuItem
        value={fleet.fleet_name}
        data-hook={`${fleet.fleet_name}`}
        key={i}
      >
        {fleet.fleet_name}
      </MenuItem>
    ));
  };

  const renderFleetsField = () => {
    if (values.fleet_name === 'add') {
      return (
        <Box my='1.5rem'>
          <CustomTextField
            required
            name={'newFleet'}
            id='outlined-basic'
            label={t('singleVehicleUpload.fleetName')}
            onChange={e => props.setNewFleet(e.target.value)}
            variant='filled'
            placeholder={`${t('common.eg')} w5e1as3`}
            fullWidth
          />
        </Box>
      );
    }
  };

  const axlePressureFieldsView = () => {
    if (values.axle_type) {
      return optimalPressureByAxleType[values.axle_type]?.map(
        (field, index) => (
          <Grid key={field} item xs={6}>
            <CustomTextField
              name={field}
              label={`Axle-${index + 1} Optimal Air Pressure ${
                !!values?.optimum_air_pressure ? '' : '(optional)'
              }`}
              value={values?.optimum_air_pressure?.[field] || ''}
              onChange={props.handleChange}
              variant='filled'
              InputProps={{
                inputProps: {
                  min: 5,
                  max: 15,
                  step: 0.1,
                },
              }}
              type='number'
              id={`pressure-${index + 1}`}
              required={!!values?.optimum_air_pressure}
              fullWidth
              error={!!errors?.optimum_air_pressure?.[field]}
              helperText={errors?.optimum_air_pressure?.[field]}
              data-hook={`pressure-${index}`}
            />
          </Grid>
        )
      );
    }
  };

  const renderFleetsForm = () => {
    if (props.fleets === undefined) {
      return (
        <CustomTextField
          required
          name={'fleet_name'}
          label={t('singleVehicleUpload.fleetName')}
          onChange={props.handleChange}
          variant='filled'
          placeholder={`${t('common.eg')} w5e1as3`}
          fullWidth
        />
      );
    } else {
      return (
        <>
          <FormControl variant='filled' fullWidth>
            <InputLabel id='demo-simple-select-outlined-label'>
              {t('singleVehicleUpload.selectFleet')}
            </InputLabel>
            <Select
              required
              name='fleet_name'
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              value={values.fleet_name}
              onChange={props.handleChange}
              label={t('singleVehicleUpload.selectFleet')}
            >
              <MenuItem value=''>
                <em>{t('singleVehicleUpload.chooseFleet')}</em>
              </MenuItem>
              {renderFleetsItemList()}
              <MenuItem value='add'>
                {' '}
                + {t('singleVehicleUpload.addNewFleet')}
              </MenuItem>
            </Select>
          </FormControl>
          {renderFleetsField()}
        </>
      );
    }
  };

  const renderBusOrTrucksFields = (label, fieldName, hook) => {
    if (
      props.vehicleType === VEHICLE.vehicle_type.TRUCK.toLowerCase() ||
      props.vehicleType === VEHICLE.vehicle_type.BUS.toLowerCase()
    ) {
      return (
        <Grid item xs={6}>
          <FormControl
            variant='standard'
            style={{ width: '100%' }}
            data-hook={`${hook}`}
          >
            <InputLabel>{label}</InputLabel>
            <Select
              id={`${hook}_select`}
              variant='standard'
              native
              onChange={props.handleChange}
              inputProps={{
                name: fieldName,
                id: fieldName,
              }}
              fullWidth
            >
              <option aria-label='None' value='' />
              {availableSizes.map((option, i) => (
                <option key={i} data-hook={`${hook}_${i}`}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    }
  };

  const renderTrailerFields = () => {
    if (props.vehicleType === VEHICLE.vehicle_type.TRAILER) {
      return (
        <Grid item xs={6}>
          <FormControl variant='standard' style={{ width: '100%' }}>
            <InputLabel>{t('singleVehicleUpload.tireSize')}</InputLabel>
            <Select
              variant='standard'
              native
              onChange={props.handleChange}
              inputProps={{
                name: 'tire_size',
                id: 'tire_size',
              }}
              data-hook={`select-trailer-tire-size`}
              fullWidth
            >
              <option aria-label='None' value='' />
              {availableSizes.map((option, i) => (
                <option key={i}>{option}</option>
              ))}
            </Select>
          </FormControl>
        </Grid>
      );
    }
  };

  return (
    <Box width='100%' my='1rem'>
      <form onSubmit={props.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <CustomTextField
              name={'name'}
              required
              label={t('singleVehicleUpload.vehicleName')}
              onChange={props.handleChange}
              variant='filled'
              placeholder={`${t('common.eg')} 200`}
              fullWidth
              data-hook='vehicle-name-input'
            />
          </Grid>
          <Grid item xs={6} className={classes.grid}>
            <CustomTextField
              name={VEHICLE.VIN_CHASSIS}
              required
              label={
                props.vehicleType ===
                  VEHICLE.vehicle_type.TRUCK.toLowerCase() ||
                props.vehicleType === VEHICLE.vehicle_type.BUS.toLowerCase()
                  ? t('singleVehicleUpload.vin')
                  : t('singleVehicleUpload.chassisNumber')
              }
              onChange={props.handleChange}
              onBlur={props.blurHandler}
              variant='filled'
              placeholder={`${t('common.eg')} WVN12HAS340PM1SQ3`}
              fullWidth
              data-hook='vehicle-vin-input'
              error={errors[VEHICLE.VIN_CHASSIS] !== null}
            />
            <Box bottom={-15} color='red'>
              {errors[VEHICLE.VIN_CHASSIS]}
            </Box>
          </Grid>
          <Grid item xs={12}>
            {renderFleetsForm()}
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              required
              name={'location'}
              label={t('singleVehicleUpload.fleetLocation')}
              onChange={props.handleChange}
              variant='filled'
              placeholder={`${t('common.eg')} bandra-mumbai`}
              fullWidth
              data-hook='vehicle-location-input'
            />
          </Grid>
          {(props.vehicleType === VEHICLE.vehicle_type.TRUCK.toLowerCase() ||
            props.vehicleType === VEHICLE.vehicle_type.BUS.toLowerCase()) && (
            <Grid item xs={6}>
              <CustomTextField
                required
                type='number'
                name={VEHICLE.TANK}
                label={t('singleVehicleUpload.tankCapacity')}
                onChange={props.handleChange}
                variant='filled'
                placeholder={`${t('common.eg')} 400`}
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
                fullWidth
                data-hook='tank-capacity-input'
                error={errors[VEHICLE.TANK] !== null}
              />
              <Box bottom={-15} color='red'>
                {errors[VEHICLE.TANK]}
              </Box>
            </Grid>
          )}

          <Grid item xs={6} className={classes.grid}>
            <CustomTextField
              required
              name={PLATE}
              label={t('singleVehicleUpload.regPlate')}
              onChange={props.handleChange}
              variant='filled'
              placeholder={`${t('common.eg')} qhco1078`}
              fullWidth
              error={errors[PLATE] !== null}
              data-hook='vehicle-reg-input'
            />
            <Box color='red'>{errors[PLATE]}</Box>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              required
              name={'internal_grouping'}
              label={t('singleVehicleUpload.internalGrouping')}
              onChange={props.handleChange}
              variant='filled'
              placeholder={`${t('common.eg')} side-loader`}
              fullWidth
              data-hook='internal-grouping-input'
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name='odometer_reading'
              id='odometer_reading'
              label={t('singleVehicleUpload.odometerLabel')}
              type='number'
              onChange={props.handleChange}
              variant='filled'
              data-hook='odometer-reading-input'
              InputProps={{
                inputProps: {
                  min: 0,
                },
              }}
              fullWidth
              error={errors['odometer'] !== null}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant='standard' style={{ width: '100%' }}>
              <InputLabel htmlFor='age-native-simple'>
                {t('singleVehicleUpload.axleType')}
              </InputLabel>
              <Select
                variant='standard'
                native
                onChange={props.handleChange}
                inputProps={{
                  name: 'axle_type',
                  id: 'axle_type',
                }}
                fullWidth
                data-hook={`axle-type-select`}
              >
                <option aria-label='None' value='' />
                {vehicle_resource[props.vehicleType]?.axle_types.map(
                  (option, i) => (
                    <option key={i}>{option}</option>
                  )
                )}
              </Select>
            </FormControl>
          </Grid>
          {renderBusOrTrucksFields(
            t('singleVehicleUpload.steer'),
            'steer_tire_size',
            'select-steer-size'
          )}
          {renderBusOrTrucksFields(
            t('singleVehicleUpload.drive'),
            'drive_tire_size',
            'select-drive-size'
          )}
          {renderTrailerFields()}
          {axlePressureFieldsView()}
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDatePicker
                label={t('singleVehicleUpload.inspectionDateLabel')}
                value={
                  values.inspection_date
                    ? parseISO(values.inspection_date)
                    : null
                }
                minDate={Date.now()}
                onChange={date => {
                  if (date === null) {
                    props.setValues({ ...values, inspection_date: null });
                  } else {
                    props.setValues({
                      ...values,
                      inspection_date: moment(date).format('YYYY-MM-DD'),
                    });
                  }
                }}
                clear={() =>
                  props.setValues({ ...values, inspection_date: null })
                }
                required={false}
                format='yyyy.MM'
                views={['year', 'month']}
                data-hook='inspection-date-picker'
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDatePicker
                label={t('singleVehicleUpload.safetyCheckLabel')}
                value={
                  values.safety_check_date
                    ? parseISO(values.safety_check_date)
                    : null
                }
                data-testid='safety-check-date'
                onChange={date => {
                  if (date === null) {
                    props.setValues({
                      ...values,
                      safety_check_date: null,
                    });
                  } else {
                    props.setValues({
                      ...values,
                      safety_check_date: moment(date).format('YYYY-MM-DD'),
                    });
                  }
                }}
                minDate={Date.now()}
                clear={() =>
                  props.setValues({ ...values, safety_check_date: null })
                }
                format='yyyy.MM'
                views={['year', 'month']}
                required={false}
                data-hook='safety-check-date-picker'
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Box
          display='flex'
          justifyContent={'center'}
          marginTop='2rem'
          gap={'16px'}
        >
          <Button
            variant='outlined'
            color='primary'
            className={classes.btnStyle}
            disableElevation
            data-hook='close-modal-btn'
            onClick={() => props.handleClose()}
          >
            {t('singleVehicleUpload.cancel')}
          </Button>
          <Button
            type='submit'
            variant='contained'
            data-hook='add-vehicle-submit-btn'
            color='primary'
            className={classes.btnStyle}
            disableElevation
            disabled={props.flag || props.disableCondition}
          >
            {isSubmitting ? (
              <CircularProgress size={30} color='inherit' />
            ) : (
              t('singleVehicleUpload.add')
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddSingleVehicle;

import React, { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { updateTire } from 'services/fleet_service';
import { setNotification } from 'redux/notifications/ducks';
import { setChangeInVehicle, vehicleUpdatedSelector } from 'redux/fleets/ducks';
import {
  internalAxleName,
  stringLookup,
} from 'pages/VehiclePage/TireConfig/const';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      height: 'auto',
      border: 'none',

      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 3rem',
      maxWidth: '1000px',
      position: 'relative',
    },
    confirmBtn: {
      width: 100,
    },
  };
});

const SwapActionModal = ({ open, tires, setOpen, ids, swapInfo }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const oldPositions = {
    fromPosition: tires?.[0]?.position,
    toPosition: tires?.[1]?.position,
  };
  const missingInfo = !oldPositions.fromPosition ? 'from' : 'to';
  const thisObj = swapInfo[missingInfo];

  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [swapDate, setSwapDate] = useState(new Date());
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);
  const [typedYear, setTypedYear] = useState(swapDate.getFullYear());
  const confirmClickHandler = async () => {
    setIsSubmitting(true);
    const promises = [];
    const payload = {
      events: [
        {
          event_type: 'UNMOUNT_STORE',
          event_date: moment(swapDate).format('YYYY-MM-DD'),
        },
      ],
    };
    const presentTires = tires.filter(item => item);

    for (const element of presentTires) {
      try {
        const res = await updateTire(element.system_tire_id, payload);
        if (res.status === 200) {
          promises.push(res.status);
        } else {
          dispatch(setNotification(t('common.error'), 'error', true));
          throw new Error('Request failed');
        }
      } catch (error) {
        console.error(error);
        setIsSubmitting(false);
        return;
      }
    }
    await mountHandler(presentTires);
  };

  const mountHandler = async tires_ => {
    const missingPosition =
      stringLookup[internalAxleName[swapInfo.axle_type]]?.[thisObj.key]?.[
        thisObj.side
      ]?.[thisObj.index];

    let obj = {
      event_type: 'MOUNT',
      event_date: moment(swapDate).format('YYYY-MM-DD'),
    };

    const payload = {
      events: [
        {
          ...obj,
          position:
            tires_.length === 1 ? missingPosition : oldPositions.toPosition,
          axle_position_id:
            tires_.length === 1 ? tires_[0].axle_position_id : ids.to,
        },
      ],
    };

    try {
      const res = await updateTire(tires_[0].system_tire_id, payload);
      if (res.status === 200) {
        if (tires_.length > 1) {
          const payload2 = {
            events: [
              {
                ...obj,
                position: oldPositions.fromPosition,
                axle_position_id: ids.from,
              },
            ],
          };
          const res2 = await updateTire(tires_[1].system_tire_id, payload2);
          if (res2.status === 200) {
            successAction();
          } else {
            dispatch(setNotification(t('common.error'), 'error', true));
            throw new Error('An error occurred');
          }
        } else {
          successAction();
        }
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
        throw new Error('An error occurred');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const successAction = () => {
    dispatch(setNotification(t('swapAction.success'), 'success', true));
    dispatch(setChangeInVehicle(!vehicleDataHasChanged));
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const disableCondition =
    isSubmitting ||
    swapDate.toString() === 'Invalid Date' ||
    typedYear < 1900 ||
    typedYear > 2099;

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
      data-hook='swap-action-modal'
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Box>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={'Date of Swap'}
                  value={swapDate}
                  onChange={newValue => {
                    const year = newValue.getFullYear().toString();
                    setTypedYear(parseInt(year));
                    setSwapDate(newValue);
                  }}
                  maxDate={new Date()}
                  format='yyyy.MM.dd'
                  data-hook='swap-date-picker'
                />
              </LocalizationProvider>
            </Box>
            <Typography variant='h6'>{t('swapAction.info')}</Typography>
            <Button
              color='secondary'
              className={classes.confirmBtn}
              variant='contained'
              disabled={disableCondition}
              onClick={confirmClickHandler}
              data-hook='confirm-btn'
            >
              {isSubmitting ? (
                <CircularProgress size={30} color='inherit' />
              ) : (
                t('common.tireInventory.confirmBtn')
              )}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default SwapActionModal;

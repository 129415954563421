import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Tooltip,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import LineChart from 'components/custom/charts/LineChart';
import moment from 'moment';
import domtoimage from 'dom-to-image';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Loading from 'components/Loading';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
      height: '100%',
    },

    paper: {
      maxWidth: '80%',
      width: '100%',
      border: 'none',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2rem 3rem',
      position: 'relative',
      margin: 'auto',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
    },
    downloadIcon: {
      cursor: 'pointer',
    },
  };
});

const KpiChartModal = ({
  open,
  setOpen,
  kpi,
  data,
  reportType,
  chartTitle,
  allKpis,
  weekly,
}) => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = useState(false);
  const [allData, setAllData] = useState([]);
  const [selectedKpiData, setSelectedKpiData] = useState(null);

  const handleDownload = title => {
    domtoimage
      .toJpeg(document.getElementById(title), { quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = `${title}.jpeg`;
        link.href = dataUrl;
        link.click();
      });
  };

  const chartTitleReportType = title => {
    return `${title} - ${reportType}`;
  };

  const createAllDatas = () => {
    let allData = [];
    allKpis.forEach(i => {
      if (!i.kpis.some(j => kpi.includes(j))) {
        allData.push(createData(i));
      }
    });
    setAllData([...allData]);
  };

  useEffect(() => {
    if (showAll) {
      createAllDatas();
    } else {
      setAllData([]);
    }
    // eslint-disable-next-line
  }, [showAll]);

  const createData = kpiConfig => {
    const _kpi = kpiConfig?.kpis;
    const title = kpiConfig?.title;
    let temp = [];
    if (reportType === 'daily') {
      data.sort((a, b) => a.timestamp - b.timestamp);

      data.forEach(item => {
        const formattedTimestamp = moment
          .unix(item.timestamp)
          .format('YYYY-MM-DD');
        const kpiValues = {};

        if (_kpi?.length === 1) {
          kpiValues[_kpi[0]] = Math.round(item[_kpi[0]]);
        } else {
          kpiValues.total_share_motorway = Math.round(
            item.total_share_motorway
          );
          kpiValues.total_share_regional = Math.round(
            item.total_share_regional
          );
          kpiValues.total_share_urban = Math.round(item.total_share_urban);
        }

        temp.push({
          name: formattedTimestamp,
          ...kpiValues,
        });
      });
    } else {
      weekly.forEach(item => {
        const dateRange = `${item['start_date']} - ${item['end_date']}`;
        const kpiValues = {};

        if (_kpi?.length === 1) {
          kpiValues[_kpi[0]] = item[_kpi[0]];
        } else {
          kpiValues.total_share_motorway = item['total_share_motorway'];
          kpiValues.total_share_regional = item['total_share_regional'];
          kpiValues.total_share_urban = item['total_share_urban'];
        }

        temp.push({
          name: dateRange,
          ...kpiValues,
        });
      });
    }
    return { data: temp, arrKpi: _kpi, title };
  };

  useEffect(() => {
    if (open) {
      setAllData([]);
      const kpiConfig = allKpis.find(i => i?.kpis.some(j => kpi.includes(j)));
      setSelectedKpiData(createData(kpiConfig));
    }
    // eslint-disable-next-line
  }, [open]);

  const { classes } = useStyles();
  const handleClose = () => {
    setOpen(false);
    setSelectedKpiData(null);
    setAllData([]);
    setShowAll(false);
  };

  if (selectedKpiData?.data === null) {
    return <Loading />;
  }

  const renderGraphSection = () => {
    return allData.map(({ labels, data, title, arrKpi }, i) => {
      return (
        <Grid key={i} item sm={12} md={6} style={{ position: 'relative' }}>
          <GraphComponent
            titleReportType={chartTitleReportType(title)}
            data={data}
            chartTitle={title}
            reportType={reportType}
            handleDownload={handleDownload}
            isSelectedKpi={false}
            kpis={arrKpi}
            width={500}
          />
        </Grid>
      );
    });
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper bgcolor='#fff' className={classes.paper}>
          <GraphComponent
            titleReportType={chartTitleReportType(chartTitle)}
            data={selectedKpiData?.data}
            chartTitle={chartTitle}
            reportType={reportType}
            handleDownload={handleDownload}
            isSelectedKpi={true}
            kpis={selectedKpiData?.arrKpi}
            width={1000}
          />
          <div>
            <Button onClick={() => setShowAll(!showAll)} variant='outlined'>
              {showAll ? t('common.showLess') : t('common.showMore')}
            </Button>
          </div>
          {!!allData.length && (
            <Grid container spacing={2}>
              {renderGraphSection()}
            </Grid>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

const GraphComponent = ({
  titleReportType,
  labels,
  data,
  chartTitle,
  reportType,
  handleDownload,
  isSelectedKpi,
  kpis,
  width,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <Box width='100%' bgcolor='#fff' id={titleReportType}>
        <LineChart
          labels={labels}
          dataSets={data || []}
          xAxis={{
            display: true,
            text:
              reportType === 'daily' ? t('kpiChart.days') : t('kpiChart.weeks'),
          }}
          yAxis={{
            display: true,
            text: chartTitle,
          }}
          kpis={kpis}
          chartWidth={width}
        />
      </Box>
      {isSelectedKpi ? (
        <Box my='1rem'>
          <Button
            disableRipple={true}
            style={{ textTransform: 'unset' }}
            color='primary'
            size={'large'}
            variant='contained'
            disableElevation={true}
            endIcon={<CloudDownloadIcon />}
            onClick={() => handleDownload(titleReportType)}
          >
            {t('kpiChart.download')}
          </Button>
        </Box>
      ) : (
        <Box position='absolute' right={'0.5rem'} top={'1.5rem'}>
          <Tooltip title={t('kpiChart.download')}>
            <CloudDownloadIcon
              color='primary'
              className={classes.downloadIcon}
              onClick={() => handleDownload(titleReportType)}
            />
          </Tooltip>
        </Box>
      )}
    </>
  );
};

export default KpiChartModal;
KpiChartModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  kpi: PropTypes.any,
  data: PropTypes.any,
  reportType: PropTypes.string,
  chartTitle: PropTypes.any,
  allKpis: PropTypes.array,
  weekly: PropTypes.array,
};

import React, { useEffect, useState } from 'react';
import MotionWrap from 'wrapper/MotionWrap';
import FleetsFoundUI from './FleetsFoundUI';
import { useSelector } from 'react-redux';
import {
  profileStatusSelector,
  setAccessTokenSelector,
  setPageLevelTourSelector,
  setTaskSelector,
  updateTasks,
} from 'redux/users/ducks';
import {
  fleetOverviewSelector,
  setOverviewDetails,
  setTrackedVehiclesIdsSelector,
} from 'redux/fleets/ducks';

import { TRACKED_LS } from 'utils/string_utils';
import { useDispatch } from 'react-redux';
import { getVehicleOverviewDetails } from '../../services/fleet_service';

const Home = () => {
  const [isVehicleDataLoading, setIsVehicleDataLoading] = useState(true);
  const [tasksLoading, setTasksLoading] = useState(true);
  const [fleetsFound, setFleetsFound] = useState(true);
  const dispatch = useDispatch();
  const [isLoadingOverview, setIsLoadingOverview] = useState(true);
  const isTourRequired = !useSelector(setPageLevelTourSelector)?.home;
  const profileStatus = useSelector(profileStatusSelector);
  const allFleetsData = useSelector(fleetOverviewSelector)?.fleets;
  const trackedIds = useSelector(setTrackedVehiclesIdsSelector) || TRACKED_LS;
  const availableTasks = useSelector(setTaskSelector);
  const access_token = useSelector(setAccessTokenSelector);

  useEffect(() => {
    if (!allFleetsData) {
      return;
    }
    if (allFleetsData.length === 0) {
      setFleetsFound(false);
      setIsVehicleDataLoading(false);
    } else {
      setFleetsFound(true);
      setIsVehicleDataLoading(false);
    }
    if (access_token) {
      (async () => {
        const overviewDetails = await getVehicleOverviewDetails();
        dispatch(setOverviewDetails(overviewDetails));

        setIsLoadingOverview(false);
      })();
    }
  }, [allFleetsData, access_token, dispatch]);

  useEffect(() => {
    if (isTourRequired) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isTourRequired]);

  useEffect(() => {
    if (availableTasks !== null) {
      setTasksLoading(false);
    }
  }, [availableTasks]);
  useEffect(() => {
    const updateTire = async () => {
      dispatch(updateTasks(allFleetsData));
    };
    updateTire();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FleetsFoundUI
        isVehicleDataLoading={isVehicleDataLoading}
        profileStatus={profileStatus}
        fleetPresent={fleetsFound}
        fleets={allFleetsData}
        loadingTasks={tasksLoading}
        tracked={trackedIds}
        data-hook='fleets-found-ui'
        isLoadingOverview={isLoadingOverview}
      />
    </>
  );
};

export default MotionWrap(Home, { opacity: 0 }, { opacity: 1 }, { opacity: 0 });

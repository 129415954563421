import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';
import de from './de';
import pl from './pl';
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: true, // enable this if your facing issue with transalation not happening properly
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en,
      },
      de: {
        translation: de,
      },
      pl: {
        translation: pl,
      },
    },
  });

import React from 'react';
import 'chart.js/auto';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    noDataFoundContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      fontSize: '2rem',
      fontWeight: '1000',
    },
  };
});

const BarCharts = ({
  indexAxis = 'y',
  title,
  labels,
  dataSets,
  height,
  width,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  const buildChartData = datasets => {
    let temp = [];
    datasets?.[0].data?.forEach((_data, i) => {
      if (_data) temp.push({ label: labels[i], value: _data });
    });
    return temp;
  };
  const datas = buildChartData(dataSets);

  return (
    <Box height='100%'>
      {datas.length ? (
        <>
          {!!height ? (
            <BarChart data={datas} width={width} height={height ?? 270}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='label' height={60} />
              <YAxis />
              <Tooltip />
              <Bar dataKey='value' fill={fillColor} />
            </BarChart>
          ) : (
            <ResponsiveContainer>
              <BarChart data={datas}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='label' height={60} />
                <YAxis />
                <Tooltip />
                <Bar dataKey='value' fill={fillColor} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </>
      ) : (
        <Box className={classes.noDataFoundContainer}>
          {t('common.noDataFound')}
        </Box>
      )}
    </Box>
  );
};

export default BarCharts;

BarCharts.propTypes = {
  indexAxis: PropTypes.string,
  title: PropTypes.string,
  labels: PropTypes.array,
  dataSets: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
};

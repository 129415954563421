import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';
// import { withStyles } from '@mui/styles';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { RxCaretSort } from 'react-icons/rx';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTranslation } from 'react-i18next';

const StyledTableLabel = withStyles(TableSortLabel, theme => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.background.default),
    padding: 0,
    margin: 0,
  },
}));

const useStyles = makeStyles()(theme => {
  return {
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
    oddRow: {
      backgroundColor: '#F6F9F7',
    },
    pagination: {
      display: 'flex',
      flexDirection: 'column',
    },
    pageButton: {
      minWidth: '22px',
      padding: 0,
      margin: '0 4px',
      color: 'unset',
    },
    currentPageButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      minWidth: '32px',
      padding: 0,
      margin: '0 4px',
      borderRadius: '50px',
    },
    hideSelectField: {
      display: 'none',
    },
    labelRowsPerPage: {
      flexShrink: 0,
      position: 'absolute',
      left: 0,
      top: 15,
      width: 172,
      fontSize: '14px',
      lineHeight: '21px',
    },
    hiddenTableCell: {
      paddingBottom: 0,
      paddingTop: 0,
    },
  };
});

const CustomTable = ({
  columns,
  data,
  revealedColumns = columns?.map(column => column.key),
  rowsPerPage = 10,
  setRowsPerPage,
  freq,
  paginationHandler,
  filterCounter,
  searchQuery,
  loading,
  emptyMessage,
  total,
  isInitialRun = () => {},
  currentPage,
  ...rest
}) => {
  const { classes } = useStyles();
  const [openCollapsedRows, setOpenCollapsedRows] = useState(
    new Array(data.length).fill(false)
  );
  const theme = useTheme();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [pageNo, setPage] = useState(0);
  const { t } = useTranslation();

  function CustomTablePaginationActions(props) {
    const { onPageChange, page: thisPage, rowsPerPage } = props;
    const { classes } = useStyles();
    const page = currentPage ?? thisPage;
    let numPages;
    numPages = Math.ceil(total / rowsPerPage);

    const numButtonsToShow = 3;

    const generatePageButtons = () => {
      const buttons = [];
      const firstButton = Math.max(0, page - numButtonsToShow);
      const lastButton = Math.min(numPages - 1, page + numButtonsToShow);

      if (page > numButtonsToShow) {
        buttons.push(
          <Button
            key={0}
            onClick={event => onPageChange(event, 0)}
            className={classes.pageButton}
          >
            1
          </Button>
        );
        if (page > numButtonsToShow + 1) {
          buttons.push(<span key='ellipsis1'>...</span>);
        }
      }

      for (let num = firstButton; num <= lastButton; num++) {
        buttons.push(
          <Button
            key={num}
            onClick={event => onPageChange(event, num)}
            className={
              page === num ? classes.currentPageButton : classes.pageButton
            }
            data-hook={`page-${num}`}
          >
            {num + 1}
          </Button>
        );
      }

      if (page < numPages - numButtonsToShow - 1) {
        if (page < numPages - numButtonsToShow - 2) {
          buttons.push(<span key='ellipsis2'>...</span>);
        }
        buttons.push(
          <Button
            key={numPages - 1}
            onClick={event => onPageChange(event, numPages - 1)}
            className={classes.pageButton}
          >
            {numPages}
          </Button>
        );
      }

      return buttons;
    };

    return (
      <div className={classes.pagination}>
        <Box alignSelf='flex-end' display='flex'>
          <Button
            onClick={event => onPageChange(event, page - 1)}
            className={classes.pageButton}
            disabled={page === 0}
            color='primary'
          >
            <KeyboardArrowLeft />
          </Button>
          {generatePageButtons()}
          <Button
            onClick={event => onPageChange(event, page + 1)}
            className={classes.pageButton}
            // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            disabled={page >= numPages - 1}
            color='primary'
          >
            <KeyboardArrowRight />
          </Button>
        </Box>
      </div>
    );
  }

  const toggleRow = rowIndex => {
    const newOpenCollapsedRows = [...openCollapsedRows];
    newOpenCollapsedRows[rowIndex] = !newOpenCollapsedRows[rowIndex];
    setOpenCollapsedRows(newOpenCollapsedRows);
  };

  const handleSort = property => {
    const isAscending = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAscending ? 'desc' : 'asc');
  };

  const sortedData = [...data].sort((a, b) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];

    if (order === 'asc') {
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue);
      } else {
        return aValue - bValue;
      }
    } else {
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return bValue.localeCompare(aValue);
      } else {
        return bValue - aValue;
      }
    }
  });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    paginationHandler(newPage);
  };

  const renderTableBody = () => {
    if (loading) {
      return renderLoader();
    }
    return tableBody();
  };

  const renderLoader = () => {
    return (
      <TableRow>
        <TableCell colSpan={9} align='center'>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            textAlign='center'
            width={'100%'}
            className='testloader'
          >
            <CircularProgress size={50} value={75} width={100} height={100} />
            <Typography style={{ marginTop: '1rem' }} variant={'determinate'}>
              Please wait , Fetching data...
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
    );
  };
  function sortByKey(arr) {
    return arr.sort((a, b) => {
      const order = { actions: 1 };
      return (order[a.key] || 0) - (order[b.key] || 0);
    });
  }

  const tableBody = () => {
    if (sortedData.length) {
      return (
        <TableBody data-hook='custom-table-body'>
          {sortedData.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <TableRow
                key={rowIndex}
                className={`${classes.root} ${
                  rowIndex % 2 !== 0 && classes.oddRow
                }`}
              >
                {columns
                  .filter(item => revealedColumns?.includes(item.key))
                  .map((column, i) =>
                    column.key === 'more' ? (
                      <TableCell key={i}>
                        {
                          <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => {
                              toggleRow(rowIndex);
                            }}
                          >
                            {
                              <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                bgcolor={'#DEF2CC'}
                                color={theme.palette.primary.main}
                                borderRadius='50%'
                                width='32px'
                                height='32px'
                                data-hook={`toggle-${rowIndex}`}
                              >
                                {!openCollapsedRows[rowIndex] ? (
                                  <ExpandMore
                                    data-hook={`toggleExpand-${rowIndex}`}
                                  />
                                ) : (
                                  <ExpandLess
                                    data-hook={`toggleCollapse-${rowIndex}`}
                                  />
                                )}
                              </Box>
                            }
                          </IconButton>
                        }
                      </TableCell>
                    ) : (
                      <TableCell key={column.key}>
                        {column.render
                          ? column.render(row, i)
                          : row[column.key]}
                      </TableCell>
                    )
                  )}
              </TableRow>
              <TableRow>
                <TableCell className={classes.hiddenTableCell} colSpan={12}>
                  <Collapse
                    in={openCollapsedRows[rowIndex]}
                    timeout='auto'
                    unmountOnExit
                  >
                    <Box margin={1} data-hook='hidden-data'>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            {columns
                              .filter(
                                item => !revealedColumns?.includes(item.key)
                              )
                              .map((column, j) => (
                                <TableCell key={j}>{column.title}</TableCell>
                              ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            {columns
                              .filter(
                                item => !revealedColumns?.includes(item.key)
                              )
                              .map((column, k) => (
                                <TableCell key={k}>
                                  {column.render
                                    ? column.render(row)
                                    : row[column.key]}
                                </TableCell>
                              ))}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      );
    } else {
      return (
        <TableRow>
          <TableCell colSpan={9} align='center'>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              textAlign='center'
              width={'100%'}
              className='testloader'
            >
              <Typography
                style={{ marginTop: '1rem', fontSize: 20 }}
                variant={'determinate'}
              >
                {emptyMessage ?? 'No Tires present'}
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      );
    }
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    isInitialRun(false);
  };

  return (
    <TableContainer data-hook='fleets-table-data'>
      <Table>
        <TableHead>
          <TableRow>
            {sortByKey(columns)
              .filter(item => revealedColumns?.includes(item.key))
              .map(column => (
                <TableCell key={column.key} data-hook={column?.dataHook}>
                  {column.canSort ? (
                    <StyledTableLabel
                      active={orderBy === column.key}
                      direction={orderBy === column.key ? order : 'asc'}
                      onClick={() => handleSort(column.key)}
                    >
                      {column.title}
                      <RxCaretSort size={24} />
                    </StyledTableLabel>
                  ) : (
                    column.title
                  )}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        {renderTableBody()}
        <TableFooter>
          <TableRow>
            <TablePagination
              // sx={{
              //   '& .MuiTablePagination-select': {
              //     display: 'none',
              //   },
              //   '& .MuiTablePagination-selectIcon': {
              //     display: 'none',
              //   },
              // }}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              labelRowsPerPage={
                !!total && !loading ? (
                  <>
                    <div className={classes.labelRowsPerPage}>
                      {`${t('common.showing')} ${sortedData.length} ${t(
                        'common.of'
                      )} ${total} ${t('common.results')}`}
                    </div>
                    <span>{t('common.rows')}</span>
                  </>
                ) : (
                  <span>{t('common.rows')}</span>
                )
              }
              // colSpan={3}
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={pageNo}
              onPageChange={handleChangePage}
              ActionsComponent={CustomTablePaginationActions}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={({ from, to, count }) => ''}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;

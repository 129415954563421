import { useState } from 'react';
import { getVehiclesForFleet } from 'services/fleet_service';

const useFetchAllVehicles = access_token => {
  const [isLoading, setIsLoading] = useState(false);
  const [allVehicles, setAllVehicles] = useState([]);

  const fetchFleets = async data => {
    setIsLoading(true);
    try {
      const id = data?.map(fleet => fleet.fleet_id) || [];
      if (id.length > 0) {
        let temp = [];
        for (let i = 0; i < id.length; i++) {
          try {
            const res = await getVehiclesForFleet(
              { fleet_id: id[i] },
              access_token,
              true
            );
            const allData = res?.data?.resp?.vehicles || [];
            temp = temp.concat(allData);
          } catch (error) {
            console.error('E00021', error);
          }
        }
        setAllVehicles(temp);
      }
    } catch (error) {
      console.error('E20009', error);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, allVehicles, fetchFleets };
};

export default useFetchAllVehicles;

import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import CustomTextField from '../custom/CustomTextField';
import PropTypes from 'prop-types';
import { useState } from 'react';
import LoadAnimation from '../LoadAnimation';
const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: '770px',
      // width: '100%',
      height: 'auto',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      padding: '2rem 3rem',
    },
    form: {
      marginTop: theme.spacing(5),
      width: 574,
    },
    formControl: {
      width: '100%',
    },
    btns: {
      flex: '30%',
      borderRadius: 24,
      textTransform: 'unset',
    },
  };
});

const AddFleet = ({
  open,
  closeHandler,
  payload,
  setPayload,
  submit,
  isSubmitting,
  setIsSubmitting,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(false);

  const changeHandler = e => {
    setPayload({ ...payload, [e.target.name]: e.target.value.trim() });
  };

  const isNotBlank = input => {
    const regex = /\S/;
    return regex.test(input);
  };

  const isButtonDisabled = Object.values(payload).some(
    item => item === '' || !item || !isNotBlank(item)
  );

  return (
    <>
      <Modal
        className={classes.modal}
        open={open}
        onClose={closeHandler}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Typography variant='h5' data-hook='Add-new-fleet-title'>
              {t('newFleetModal.heading')}
            </Typography>
            <Grid container spacing={5} className={classes.form}>
              <Grid item xs={12}>
                <CustomTextField
                  required
                  name='fleet_name'
                  data-hook='fleet-name_1'
                  id='fleet_name'
                  label={t('newFleetModal.fleetName')}
                  onChange={changeHandler}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  required
                  name='location'
                  data-hook='fleet-location'
                  id='location'
                  label={t('newFleetModal.location')}
                  onChange={changeHandler}
                  variant='outlined'
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  display='flex'
                  width='100%'
                  justifyContent='space-between'
                  gap={'16px'}
                >
                  <Button
                    variant='outlined'
                    color='primary'
                    data-hook='cancel-button'
                    className={classes.btns}
                    onClick={closeHandler}
                  >
                    {t('newFleetModal.cancel')}
                  </Button>
                  <Button
                    variant='contained'
                    data-hook='confirm-button'
                    color='primary'
                    className={classes.btns}
                    disabled={isButtonDisabled || isDisabled}
                    onClick={e => {
                      submit(e);
                      setIsDisabled(true);
                    }}
                  >
                    {t('newFleetModal.confirm')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
      <LoadAnimation loading={isSubmitting} setLoading={setIsSubmitting} />
    </>
  );
};

export default AddFleet;

AddFleet.propTypes = {
  open: PropTypes.bool,
  payload: PropTypes.object,
  setPayload: PropTypes.func,
  closeHandler: PropTypes.func,
  submit: PropTypes.func,
};

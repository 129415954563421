import React from 'react';
import { Badge, IconButton, InputBase } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Search } from '@mui/icons-material';
import { ReactComponent as Filter } from 'assets/svg/common/filterIcon.svg';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles()(theme => {
  return {
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      width: 355,
      border: '1px solid #D1D5DB',
      borderRadius: 8,
    },
  };
});

const SearchBar = ({ handleSearch, setOpen, filterCounter, dataHook }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className={classes.searchBar}>
      <IconButton aria-label='search'>
        <Search color='primary' />
      </IconButton>
      <InputBase
        placeholder={`${t('common.search')}...`}
        data-hook={dataHook}
        fullWidth
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleSearch}
      />
      {!!setOpen && (
        <IconButton aria-label='filter' onClick={() => setOpen(true)}>
          <Badge
            badgeContent={filterCounter}
            data-hook='filter-counter'
            color='primary'
          >
            <Filter fill={theme.palette.primary.main} />
          </Badge>
        </IconButton>
      )}
    </div>
  );
};

export default SearchBar;

// Public Pages
import LoginPage from '../pages/LoginPage';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import ResetLoader from 'pages/ResetLoader';
import VerificationPage from 'pages/VerificationPage';
import VerificationLoader from 'pages/VerificationLoader';
import TermsConditions from 'pages/TermsCondition';
import PrivacyPolicy from 'pages/PrivacyPolicy';

// Auth Protected Pages
import TelematicsDataIntegration from 'components/TelematicsDataIntegration';
import Home from 'pages/Home';
import Settings from 'pages/Settings';
import Plans from 'pages/Plans';
import Logout from 'pages/Logout';
import FleetsSection from 'pages/FleetsSection';
import VehiclePage from 'pages/VehiclePage';
import Changelog from 'pages/Changelog';
import SavingPotential from 'pages/SavingPotentials';
import MultiLayeredReport from 'pages/MultiLayeredReport';
import VerifyInvitedUser from 'pages/VerifyInvitedUser';
import CompleteSignup from 'pages/CompleteSignup';
import Invite from 'pages/Settings/UserManagement/Invite';
import Inventory from 'pages/TireInventory';
import { ROUTES } from 'utils/string_utils';
import FleetTireInventory from 'pages/TireInventory/TireInventoryItems/FleetTireInventory';
import SingleTire from 'pages/TireInventory/TireInventoryItems/SingleTire';
import EmptyFleet from 'pages/FleetsSection/FleetsSectionWithoutFleet';
import TableauPage from 'pages/TableauPage';
import TireOverviewPage from 'pages/VehicleAndTireOverview/TireOverviewPage';
import VehicleOverviewPage from 'pages/VehicleAndTireOverview/VehicleOverviewPage';
import UserGuide from 'pages/UserGuide';

const publicRoutes = [
  {
    path: '/login',
    exact: true,
    component: LoginPage,
    isMotion: true,
    header: 'login',
  },
  // Deleted SSOSignup Component
  {
    path: '/user/forgot-password',
    component: ForgotPassword,
    header: 'user-forgot-password',
  },
  { path: '/reset-password', component: ResetLoader, header: 'reset-password' },
  {
    path: '/user/reset-password/:userToken',
    component: ResetPassword,
    header: 'user-reset-password-userToken',
  },

  { path: '/verify', component: VerificationLoader, header: 'verify' },
  {
    path: '/user/verify/:token',
    component: VerificationPage,
    header: 'user-verify-token',
  },
  {
    path: '/user/verify-user-invitation/:token',
    component: VerifyInvitedUser,
    header: 'user-verify-user-invitation-token',
  },
  {
    path: `${ROUTES.COMPLETE_SIGN_UP_BASE}/:token`,
    component: CompleteSignup,
    header: 'complete-sign-up-token',
  },
  {
    path: '/terms-conditions',
    component: TermsConditions,
    header: 'terms-conditions',
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    header: 'privacy-policy',
  },
  { path: '/logout', component: Logout, header: 'logout' },
];

const authProtectedRoutes = [
  { path: '/home', component: Home, header: 'homeDashboard' },
  {
    path: '/home/saving-potentials',
    component: SavingPotential,
    header: 'homeDashboard',
  },
  {
    path: '/fleets-data/:fleet_id',
    component: FleetsSection,
    header: 'fleet',
  },
  {
    path: '/fleets-data',
    component: EmptyFleet,
    header: 'fleet',
  },
  {
    path: '/fleets-data/:fleet_id/:vehicle_type/:vehicle_id',
    component: VehiclePage,
    header: 'fleet',
    props: ['setting-page'],
  },
  {
    path: '/telematics-data/provider-integration',
    component: TelematicsDataIntegration,
    header: 'telematics',
  },
  {
    path: '/settings',
    component: Settings,
    header: 'settings',
    props: ['setting-page'],
  },

  { path: '/settings/invite', component: Invite, header: 'settings' },
  { path: '/plans', component: Plans, header: 'plans' },
  { path: '/whats-new', component: Changelog, header: 'changelog' },

  {
    path: '/reports',
    component: MultiLayeredReport,
    header: 'reports',
    isConditional: true,
  },

  {
    path: ROUTES.TIRE_INVENTORY,
    component: Inventory,
    header: 'inventory',
    isConditional: true,
  },
  {
    path: `${ROUTES.TIRE_INVENTORY}/:fleet_id`,
    component: FleetTireInventory,
    header: 'inventory',
  },
  {
    path: '/:src/:fleet_id/tableau',
    component: TableauPage,
    // header: 'fleet',
  },
  {
    path: `${ROUTES.TIRE_INVENTORY}/:fleet_id/:tire_id`,
    component: SingleTire,
    header: 'inventory',
  },
  {
    path: '/overview/tire',
    component: TireOverviewPage,
    header: 'overview',
  },
  {
    path: '/overview/vehicle',
    component: VehicleOverviewPage,
    header: 'overview',
  },
  {
    path: '/guides',
    component: UserGuide,
    header: 'guides',
  },
];

export { publicRoutes, authProtectedRoutes };

const INITIAL_STATE = {
  message: null,
  show: false,
  type: 'success',
  translated: false,
};

export const NotificationsActionTypes = {
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_NOTIFICATION_TIMEOUT: 'SET_NOTIFICATION_TIMEOUT',
};

export const setNotification = (message, type, show, translated) => ({
  type: NotificationsActionTypes.SET_NOTIFICATION,
  payload: { message, type, show, translated },
});

export const setNotificationTimeout = () => ({
  type: NotificationsActionTypes.SET_NOTIFICATION_TIMEOUT,
});

export const notificationsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NotificationsActionTypes.SET_NOTIFICATION:
      const { message, type, show, translated } = action.payload;
      return { ...state, message, type, show, translated };
    case NotificationsActionTypes.SET_NOTIFICATION_TIMEOUT:
      return { ...state, show: false, translated: false };
    default:
      return state;
  }
};

export const notificationSelector = state => state.notifications;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import CustomAutocomplete from 'components/custom/CustomAutocomplete';
import CustomSwitch from 'components/custom/CustomSwitch';
import ActionButtons from './ActionButtons';
import questions from 'assets/data/projectInfo.json';
import { setAccessTokenSelector, setUserSelector } from 'redux/users/ducks';
import {
  OnboardingActionTypes,
  setOnboardingSelector,
} from 'redux/onborading/ducks';

const Tire = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(setUserSelector);
  const access_token = useSelector(setAccessTokenSelector);
  const organizationProfile = useSelector(
    setOnboardingSelector
  )?.organizationProfile;

  const tireManagementData = organizationProfile?.['tire_management_data'];
  const [initialState, setInitialState] = useState({
    isHotRetread: false,
    isColdRetread: false,
    isDeMinimis: false,
    percRetread: 0,
    preferredTireBrand1: '',
    preferredTireBrand2: '',
    preferredTire1Line: '',
    preferredTire2Line: '',
  });
  const [state, setState] = useState({});
  const [availableTireBrandOptions, setAvailableTireBrandOptions] = useState(
    []
  );
  const [preferredTire1LineOptions, setPreferredTire1LineOptions] = useState(
    []
  );
  const [preferredTire2LineOptions, setPreferredTire2LineOptions] = useState(
    []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsSubmitting(false);
  }, [state]);

  const handleChange = (val, name) => {
    if (name === state.preferredTireBrand1 && val === '') {
      setAvailableTireBrandOptions([
        ...availableTireBrandOptions,
        state.preferredTireBrand1,
      ]);
      setPreferredTire1LineOptions([]);
    }
    if (name === state.preferredTireBrand2 && val === '') {
      setAvailableTireBrandOptions([
        ...availableTireBrandOptions,
        state.preferredTireBrand2,
      ]);
      setPreferredTire2LineOptions([]);
    }
    setState({ ...state, [name]: val });
  };

  const handleClear = () => {
    setState({ ...initialState });
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    const payload = {
      is_hot_retread: state['isHotRetread'],
      is_cold_retread: state['isColdRetread'],
      is_de_minimis: state['isDeMinimis'],
      perc_retread: state['percRetread'],
      preferred_tire_brand_1: state['preferredTireBrand1'],
      preferred_tire_line_1: state['preferredTire1Line'],
      preferred_tire_brand_2: state['preferredTireBrand2'],
      preferred_tire_line_2: state['preferredTire2Line'],
      how_service_today: tireManagementData['how_service_today'],
      where_service_today: tireManagementData['where_service_today'],
      preferred_service_provider:
        tireManagementData['preferred_service_provider'],
    };
    dispatch({
      type: OnboardingActionTypes.ORGANIZATION_PROFILE.UPDATE_TIRE_CONFIG,
      access_token: access_token,
      payload: payload,
    });
  };
  const commonProps = { required: false, onChange: handleChange };

  useEffect(() => {
    if (tireManagementData) {
      const _initialData = {
        isHotRetread: tireManagementData['is_hot_retread'],
        isColdRetread: tireManagementData['is_cold_retread'],
        isDeMinimis: tireManagementData['is_de_minimis'],
        percRetread: tireManagementData['perc_retread'],
        preferredTireBrand1: tireManagementData['preferred_tire_brand_1'],
        preferredTireBrand2: tireManagementData['preferred_tire_brand_2'],
        preferredTire1Line: tireManagementData['preferred_tire_line_1'],
        preferredTire2Line: tireManagementData['preferred_tire_line_2'],
      };
      setInitialState({ ..._initialData });
      setState({ ..._initialData });
    }
  }, [tireManagementData]);

  useEffect(() => {
    if (state.preferredTireBrand1) {
      const preferredTireBrandOptions =
        questions['tire_management']['preferred_tire_brands']['options'][
          user['locale']
        ];
      setAvailableTireBrandOptions(
        [...preferredTireBrandOptions].filter(
          option =>
            ![state.preferredTireBrand1, state.preferredTireBrand2].includes(
              option
            )
        )
      );
      const tireLineOptions =
        questions['tire_management']['preferred_tire_lines']['options'][
          user['locale']
        ][state.preferredTireBrand1];
      if (!tireLineOptions.includes(state.preferredTire1Line)) {
        setState({ ...state, preferredTire1Line: '' });
      }
      setPreferredTire1LineOptions([...tireLineOptions]);
    }
    //eslint-disable-next-line
  }, [state.preferredTireBrand1]);

  useEffect(() => {
    if (state.preferredTireBrand2) {
      const preferredTireBrandOptions =
        questions['tire_management']['preferred_tire_brands']['options'][
          user['locale']
        ];
      setAvailableTireBrandOptions(
        [...preferredTireBrandOptions].filter(
          option =>
            ![state.preferredTireBrand1, state.preferredTireBrand2].includes(
              option
            )
        )
      );
      const tireLineOptions =
        questions['tire_management']?.['preferred_tire_lines']?.['options']?.[
          user['locale']
        ]?.[state?.preferredTireBrand2] ?? [];

      if (!tireLineOptions?.includes(state.preferredTire2Line)) {
        setState({ ...state, preferredTire2Line: '' });
      }
      setPreferredTire2LineOptions([...tireLineOptions]);
    } else {
      setPreferredTire2LineOptions([]);
    }
    //eslint-disable-next-line
  }, [state.preferredTireBrand2]);

  useEffect(() => {
    if (user['locale']) {
      setAvailableTireBrandOptions([
        ...questions['tire_management']['preferred_tire_brands']['options'][
          user['locale']
        ],
      ]);
    }
    //eslint-disable-next-line
  }, [user['locale']]);

  if (!tireManagementData) {
    return <></>;
  }

  return (
    <>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} lg={4}>
          <CustomSwitch
            {...commonProps}
            checked={state['isHotRetread']}
            label={t('customerSettingsPage.tire.isHotRetread')}
            {...commonProps}
            name='isHotRetread'
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomSwitch
            {...commonProps}
            checked={state['isColdRetread']}
            label={t('customerSettingsPage.tire.isColdRetread')}
            {...commonProps}
            name='isColdRetread'
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomSwitch
            {...commonProps}
            checked={state['isDeMinimis']}
            label={t('customerSettingsPage.tire.isDeMinimis')}
            {...commonProps}
            name='isDeMinimis'
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <CustomAutocomplete
            {...commonProps}
            options={availableTireBrandOptions}
            label={t('customerSettingsPage.tire.preferredTireBrand1')}
            name='preferredTireBrand1'
            data-hook='pfd-tire-1'
            value={state['preferredTireBrand1']}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {state.preferredTireBrand1 && (
            <CustomAutocomplete
              {...commonProps}
              options={preferredTire1LineOptions}
              label={t('customerSettingsPage.tire.preferredTire1Line')}
              name='preferredTire1Line'
              data-hook='pfd-tire-2'
              value={state['preferredTire1Line']}
            />
          )}
        </Grid>

        <Grid item xs={12} lg={6}>
          <CustomAutocomplete
            {...commonProps}
            options={availableTireBrandOptions}
            label={t('customerSettingsPage.tire.preferredTireBrand2')}
            name='preferredTireBrand2'
            value={state['preferredTireBrand2']}
            data-hook='pfd-tire-3'
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {state.preferredTireBrand2 && (
            <CustomAutocomplete
              {...commonProps}
              options={preferredTire2LineOptions}
              label={t('customerSettingsPage.tire.preferredTire2Line')}
              name='preferredTire2Line'
              value={state['preferredTire2Line']}
              data-hook='pfd-tire-4'
            />
          )}
        </Grid>
      </Grid>
      <ActionButtons
        handleUpdate={handleUpdate}
        handleClear={handleClear}
        disabled={
          !(
            state['preferredTireBrand1'] &&
            state['preferredTireBrand2'] &&
            state['preferredTire1Line'] &&
            state['preferredTire2Line']
          ) || isSubmitting
        }
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default Tire;

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, NavLink, useParams } from 'react-router-dom';
import {
  fleetOverviewSelector,
  fleetWideTireEventHistorySelector,
  setFleetWideTireHistoryData,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  tireInventoryDataSelector,
  unprocessedTireInventoryDataSelector,
} from 'redux/fleets/ducks';
import {
  profileStatusSelector,
  setAccessTokenSelector,
  setUserSelector,
} from 'redux/users/ducks';
import {
  calculateDays,
  fetchFleetTireEventHistory,
  fetchTireInventoryDataAndProcess,
  formatDate,
} from '../TireInventory';
import { Box, Breadcrumbs, Tooltip, Typography } from '@mui/material';
import CustomTable from 'components/custom/CustomTable';
import CloseIcon from '@mui/icons-material/Close';

import {
  NA,
  SERVICE_MAIL_ID,
  TIRES,
  tireStatuses,
  USER_LS,
  USER_ROLES,
  VEHICLE,
} from 'utils/string_utils';
import {
  Check,
  Delete,
  Edit,
  Eject,
  ReportProblem,
  Visibility,
} from '@mui/icons-material';
import { NAComponent, WearOutInfo } from './FleetTireInventory';
import { inventoryChangeSelector } from 'redux/tireInventory/ducks';
import {
  fetchSingleTireEventHistory,
  readTire,
  revertEvent,
} from 'services/fleet_service';
import i18next from 'i18next';
import TireEventHistoryModal from '../TireEventHistoryModal';
import {
  NotificationsActionTypes,
  setNotification,
} from 'redux/notifications/ducks';
import ManageTire from '../ManageTire';
import UpdateTire from '../UpdateTire';
import { ReportTireIssueModal } from './ReportTireIssueModal';
import { sendGeneralMail } from 'services/auth_service';
import LoadAnimation from 'components/LoadAnimation';
import BuildIcon from '@mui/icons-material/Build';
import ModifyTireModal from 'pages/VehiclePage/TireConfig/TireOperationsModal/ModifyTireModal';
import DeleteTire from '../DeleteTire';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      height: 'auto',
      border: 'none',

      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 3rem',
      maxWidth: '1000px',
      position: 'relative',
    },
    pointer: {
      cursor: 'pointer',
    },
    label: {
      fontWeight: theme.typography.fontWeightLight,
    },
    escalateModal: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px',
    },
    escalateCard: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      position: 'relative',
    },
    escapeSubmit: {
      position: 'absolute',
      bottom: '-10px',
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },
    reportIcon: {
      cursor: 'pointer',
      color: '#f78934',
    },
    closeIcon: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
    },
    vehicle: {
      textDecoration: 'none',
      padding: 5,
      color: theme.palette.primary.main,

      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    nav: { color: '#343434' },
    breadcrumbs: {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    currentPath: {
      fontSize: '1.125rem',
      fontWeight: 700,
      color: '#343434',
    },
  };
});

const initialPayload = {
  isRetread: true,
  [TIRES.TREAD_DEPTH]: '',
  tire_id: '',
  mileage: '',
};
const SingleTire = () => {
  const params = useParams();
  const { fleet_id, tire_id } = params;
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const { classes } = useStyles();
  const [fleetName, setFleetName] = useState('');
  const [loading, setLoading] = useState(true);
  const userLs = JSON.parse(USER_LS);
  // const [apiError, setApiError] = useState({ ...errorInitalState });
  const tireInventoryData = useSelector(tireInventoryDataSelector);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const access_token = useSelector(setAccessTokenSelector);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [escalateMessage, setEscalateMessage] = useState('');
  const [tire, setTire] = useState([]);
  const [id, setId] = useState(null);
  const [userTireId, setUserTireId] = useState(null);
  const [open2, setOpen2] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [items, setItems] = useState({
    brand: '',
    line: '',
    currentSize: '',
    treadDepth: '',
    retreadStatus: '',
  });
  const [tireData, setTireData] = useState(null);
  const [eventHistoryData, setEventHistoryData] = useState(null);
  const [payload, setPayload] = useState({ ...initialPayload });
  const [tireEventHistoryIsOpen, setTireEventHistoryIsOpen] = useState(false);
  const [tireEventHistoryIsLoading, setTireEventHistoryIsLoading] =
    useState(false);
  const fleetTireEventHistory = useSelector(fleetWideTireEventHistorySelector);
  const tireInventoryData_ = tireInventoryData?.[fleet_id]?.originalData;
  const [isReverting, setIsReverting] = useState(false);
  const [openRevertDialog, setOpenRevertDialog] = useState(false);
  const [openEscalate, setOpenEscalate] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const userProfile = useSelector(profileStatusSelector);
  const currInventoryChangeValue = useSelector(inventoryChangeSelector);
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);
  const currentLanguage = i18n.language;
  const visibleColumns = [
    'more',
    'tire_id',
    'vehicle_name',
    'last_tread_depth',
    'estimated_tread_depth',
    'remaining_months',
    'size',
    'mileage',
    'actions',
    'tireStatus',
  ];
  const [openDeleteTire, setOpenDeleteTire] = useState(false);
  const userDetails = useSelector(setUserSelector) || userLs;
  const userIsReporter = userDetails?.iam_role === USER_ROLES.reporter;

  useEffect(() => {
    if (fleet_id) {
      const fleet = fleets?.find(fleet => fleet.fleet_id === fleet_id);
      setFleetName(fleet?.fleet_name);
    }
    /*eslint-disable-next-line*/
  }, [fleet_id, fleets]);

  useEffect(() => {
    if (fleet_id && access_token) {
      getTires();
    }
    /*eslint-disable-next-line*/
  }, [access_token, fleet_id, currInventoryChangeValue]);

  const getTires = async () => {
    let thisTire = [];
    try {
      const res = await readTire(tire_id);
      if (res.status === 200) {
        const resource = res?.data?.resp;
        thisTire = [
          {
            ...resource,
            status: resource.vehicle_id ? 'inUse' : 'inStore',
            tireStatus: tireStatuses[resource.status],
          },
        ];
      } else {
        setTire([]);
      }
      setTire(thisTire);
    } catch (error) {
      console.error('E20045');
    } finally {
      setLoading(false);
    }
  };

  const editClickHandler = tire_ => {
    setOpenEdit(true);
    setItems({
      brand: tire_.brand,
      currentSize: tire_.size,
      line: tire_.product_line,
      treadDepth: tire_.last_tread_depth,
      retreadStatus: tire_.isRetread,
    });
    setUserTireId(tire_.tire_id);
    setId(tire_.system_tire_id);
    setTireData(tire_);
  };

  const closeHandler = () => {
    setOpen2(false);
  };

  const revertEventHandler = async () => {
    setIsReverting(true);
    const systemId = eventHistoryData?.[0]?.system_tire_id;
    try {
      const res = await revertEvent(systemId);
      if (res?.status === 200) {
        dispatch(
          setNotification(t('eventHistoryModal.revertSuccess'), 'success', true)
        );
        setOpenRevertDialog(false);
        const data = await fetchTireInventoryDataAndProcess({
          fleet_id: fleet_id,
          inventoryData: fleetInventoryData,
          refetch: true,
        });
        dispatch(setTireInventoryData(data, fleet_id));
        dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
        const eventHistoryData = await fetchFleetTireEventHistory(fleet_id);
        dispatch(setFleetWideTireHistoryData(eventHistoryData, fleet_id));
        const updated = data?.originalData.filter(
          tire => tire.system_tire_id === tire_id
        );
        setTire(updated);
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20085', error);
    } finally {
      setIsReverting(false);
    }
  };

  const viewTireEventsHandler = async tire => {
    setTireEventHistoryIsLoading(true);
    setTireEventHistoryIsOpen(true);
    try {
      if (!fleetTireEventHistory?.[fleet_id]) {
        const data = await fetchFleetTireEventHistory(fleet_id);
        dispatch(setFleetWideTireHistoryData(data, fleet_id));
        let filter = data?.filter(item => item.tire_id === tire.tire_id);

        if (filter.length === 0) {
          const res = await fetchSingleTireEventHistory(tire.system_tire_id);
          filter = res?.data.resp;
        }
        setEventHistoryData(filter);
      } else {
        let filter = fleetTireEventHistory[fleet_id]?.filter(
          item => item.tire_id === tire.tire_id
        );
        if (filter.length === 0) {
          const res = await fetchSingleTireEventHistory(tire.system_tire_id);
          filter = res?.data.resp;
        }
        setEventHistoryData(filter);
      }
    } catch (error) {
      console.error('E20072', error);
    } finally {
      setTireEventHistoryIsLoading(false);
    }
  };

  const handleEscalateModalOpen = row => {
    setOpenEscalate(true);
    setRowSelected(row);
  };

  const handleEscalateModalClose = row => {
    setOpenEscalate(false);
    setRowSelected({});
  };

  const handleEscalateMessage = e => {
    setEscalateMessage(e.target.value);
  };

  const handleEscalaeSubmit = async e => {
    e.preventDefault();
    try {
      const res = await sendGeneralMail(
        SERVICE_MAIL_ID,
        `Escalate Mail- Tire Inventory - Customer id - ${userProfile.customer_id}`,
        `Escalate Message- ${escalateMessage} for cusomer id- ${
          userProfile.customer_id
        }.. Row details
        ${JSON.stringify(rowSelected)}
        `
      );
      if (res?.status === 200) {
        handleEscalateModalClose({});
        setEscalateMessage('');
        dispatch({
          type: NotificationsActionTypes.SET_NOTIFICATION,
          payload: {
            message: t('escalateMessage.successMessage'),
            type: 'success',
            show: true,
          },
        });
      }
    } catch (err) {
      dispatch(setNotification(t('common.error'), 'error', true));
    }
  };

  const formatNumber = num => {
    if (num !== 'N/A') {
      const formattedNumber = num?.toLocaleString('de-DE', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 2,
        useGrouping: false,
      });

      if (currentLanguage === 'de') {
        return formattedNumber;
      } else {
        return num;
      }
    } else return null;
  };

  function editButton(row) {
    return (
      row.tireStatus !== tireStatuses.REPAIR &&
      row.tireStatus !== tireStatuses.RETREADING &&
      !userIsReporter && (
        <Tooltip
          title={t('common.tireInventory.editTooltip')}
          onClick={() => editClickHandler(row)}
        >
          <Edit className={classes.pointer} />
        </Tooltip>
      )
    );
  }

  function deleteTireButton(row) {
    return (
      row.tireStatus !== tireStatuses.IN_USE &&
      !userIsReporter && (
        <Tooltip
          title={t('common.tireInventory.removeTooltip')}
          onClick={() => {
            setId(row.system_tire_id);
            setOpenDeleteTire(true);
          }}
        >
          <Delete className={classes.pointer} color='secondary' />
        </Tooltip>
      )
    );
  }

  function manageTireButton(row) {
    return (
      (row.status.includes('BANK') ||
        row.tireStatus === tireStatuses.RETREADING ||
        row.status === 'REPAIR') &&
      !userIsReporter && (
        <Tooltip
          title={t('common.tireInventory.manageTire')}
          onClick={() => {
            setId(row.system_tire_id);
            setOpenDeleteDialog(true);
            setTireData(row);
          }}
        >
          <BuildIcon className={classes.pointer} />
        </Tooltip>
      )
    );
  }

  let columns = useMemo(
    () => [
      {
        key: 'more',
        title: t('tireConfigPage.more'),
        canSort: false,
      },
      {
        key: 'tire_id',
        title: t('tireInventoryItems.titles.tire_id'),
        render: row => <TireIdBox row={row} />,
        size: 80,
        canSort: true,
      },
      {
        key: 'vehicle_name',
        title: t('tireInventoryItems.titles.name'),
        render: row => (
          <>
            {row?.vehicle_name ? (
              <Link
                className={classes.vehicle}
                color='primary'
                to={`/fleets-data/${fleet_id}/${row.vehicle_type.toLowerCase()}s/${
                  row.vehicle_id
                }`}
              >
                <NAComponent val={row?.vehicle_name} />
              </Link>
            ) : (
              <NAComponent val={row?.vehicle_name} />
            )}
          </>
        ),
        canSort: true,
      },
      {
        key: 'last_tread_depth',
        title: t('tireInventoryItems.titles.treadDepth'),
        render: row => <NAComponent val={formatNumber(row.last_tread_depth)} />,
        canSort: true,
      },
      {
        key: 'estimated_tread_depth',
        title: t('tireInventoryItems.titles.estimated_current_tread_depth'),
        filterVariant: 'range',
        render: row => (
          <NAComponent val={formatNumber(row?.estimated_tread_depth)} />
        ),
        canSort: true,
      },
      {
        key: 'remaining_months',
        title: t('tireInventoryItems.titles.monthsLeft'),
        filterVariant: 'range',
        render: row => <WearOutInfo val={row?.remaining_months || 'N/A'} />,
        canSort: true,
      },
      {
        key: 'size',
        title: t('tireInventoryItems.titles.size'),
        render: row => <NAComponent val={row.size} />,
        canSort: true,
      },

      {
        key: 'mileage',
        title: t('tireInventoryItems.titles.mileage'),
        render: row => (
          <NAComponent
            val={row?.mileage && Math.round(parseFloat(row.mileage))}
          />
        ),
        canSort: true,
      },
      {
        key: 'isRetread',
        title: t('tireInventoryItems.titles.isRetread'),
        render: row => <div>{row.isRetread ? <Check /> : <CloseIcon />}</div>,
        enableColumnActions: false,
      },
      {
        key: 'actions',
        title: t('tireInventoryItems.titles.actions'),
        render: row => (
          <Box display='flex' gap={'15px'}>
            {!!row[VEHICLE.ID] && !userIsReporter && (
              <Tooltip
                title={t('common.tireInventory.moveTooltip')}
                onClick={() => {
                  setUserTireId(row.tire_id);
                  setId(row.system_tire_id);
                  setOpen2(true);
                  setPayload({
                    ...payload,
                    [TIRES.TREAD_DEPTH]:
                      row.estimated_tread_depth ?? row.last_tread_depth,
                    tire_id: row.tire_id,
                    mileage: row.mileage?.toFixed(2),
                  });
                  setTireData(row);
                }}
              >
                <Eject className={classes.pointer} />
              </Tooltip>
            )}
            {editButton(row)}

            <Tooltip
              title={t('common.tireInventory.viewEventHistory')}
              onClick={() => viewTireEventsHandler(row)}
            >
              <Visibility className={classes.pointer} />
            </Tooltip>
            {!userIsReporter && (
              <Tooltip
                title={t('escalateMessage.iconTooltip')}
                onClick={() => handleEscalateModalOpen(row)}
              >
                <ReportProblem size='small' className={classes.reportIcon} />
              </Tooltip>
            )}
            {manageTireButton(row)}
            {deleteTireButton(row)}
          </Box>
        ),
      },
      {
        key: 'mount_date',
        title: t('tireInventoryItems.titles.mountDate'),
        render: row => <NAComponent val={formatDate(row?.mount_date)} />,
        enableColumnActions: false,
      },
      {
        key: 'mount_days',
        title: t('tireInventoryItems.titles.mountDays'),
        render: row => (
          <NAComponent
            val={row?.mount_date ? calculateDays(row?.mount_date) : NA}
          />
        ),
        enableColumnActions: false,
      },
      {
        key: 'axle_position',
        title: t('tireInventoryItems.titles.axlePosition'),
        render: row => <NAComponent val={row?.axle_position} />,
      },
      {
        key: 'last_event_date',
        title: t('tireInventoryItems.titles.lastUpdate'),
        render: row => <NAComponent val={formatDate(row?.last_event_date)} />,
      },
      {
        key: 'mission_profile',
        title: t('tireInventoryItems.titles.mission_profile'),
        render: row => <NAComponent val={row?.mission_profile} />,
      },
    ],
    /*eslint-disable-next-line*/
    [fleetTireEventHistory, tireInventoryData_, i18next.language]
  );

  if (tire[0]?.tireStatus !== tireStatuses.IN_USE) {
    columns = columns.filter(
      column =>
        column.key !== 'mount_days' &&
        column.key !== 'mount_date' &&
        column.key !== 'vehicle_name' &&
        column.key !== 'axle_position'
    );
    columns = [
      ...columns.slice(0, 7),
      {
        key: 'tireStatus',
        title: tire[0]?.status.includes('BANK') ? 'Unmount Reason' : 'Status',
        render: row => <NAComponent val={row.status && row.tireStatus} />,
        size: 80,
        canSort: true,
      },
      ...columns.slice(7),
    ];
  }

  const renderTireEventHistoryModal = () => {
    if (tireEventHistoryIsOpen) {
      return (
        <TireEventHistoryModal
          open={tireEventHistoryIsOpen}
          setOpen={setTireEventHistoryIsOpen}
          isLoading={tireEventHistoryIsLoading}
          data={eventHistoryData}
          setData={setEventHistoryData}
          setIsLoading={setTireEventHistoryIsLoading}
          revertEventFn={revertEventHandler}
          isReverting={isReverting}
          setIsReverting={setIsReverting}
          open2={openRevertDialog}
          setOpen2={setOpenRevertDialog}
        />
      );
    }
  };

  const renderUpdateTireModal = () => {
    if (openEdit) {
      return (
        <UpdateTire
          current={items}
          tireId={userTireId}
          id={id}
          fleetId={fleet_id}
          open={openEdit}
          setOpen={setOpenEdit}
          tireData={tireData}
          tireInventoryAction={true}
          setTire={setTire}
        />
      );
    }
  };

  const renderModifyTireModal = () => {
    if (open2) {
      return (
        <ModifyTireModal
          open={open2}
          setOpen={setOpen2}
          presentTire={tireData}
          operation={'storage'}
          handleClose={closeHandler}
        />
      );
    }
  };

  return (
    <>
      <LoadAnimation loading={loading} setLoading={setLoading} />
      <div className={classes.breadcrumbs}>
        <Breadcrumbs separator='>' aria-label='breadcrumb'>
          <NavLink
            color='inherit'
            variant='text'
            to='/tire-inventory/'
            className={classes.nav}
          >
            Tire Inventory
          </NavLink>
          <NavLink
            color='inherit'
            variant='text'
            to={`/tire-inventory/${fleet_id}`}
            className={classes.nav}
          >
            {fleetName}
          </NavLink>
          <Typography className={classes.currentPath} color='textPrimary'>
            {tire_id}
          </Typography>
        </Breadcrumbs>
      </div>
      <Box maxWidth={'95%'}>
        <CustomTable
          columns={columns}
          data={tire}
          revealedColumns={visibleColumns}
        />
      </Box>
      {renderTireEventHistoryModal()}
      <ManageTire
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        id={id}
        tireInventoryAction={true}
        data={tireData}
        setTire={val => setTire([val])}
      />
      {renderUpdateTireModal()}
      {renderModifyTireModal()}
      <ReportTireIssueModal
        classes={classes}
        t={t}
        rowSelected={rowSelected}
        openEscalate={openEscalate}
        escalateMessage={escalateMessage}
        handleEscalateModalClose={handleEscalateModalClose}
        handleEscalateMessage={handleEscalateMessage}
        handleEscalaeSubmit={handleEscalaeSubmit}
      />
      <DeleteTire
        open={openDeleteTire}
        setOpen={setOpenDeleteTire}
        id={id}
        tireInventoryAction={true}
      />
    </>
  );
};

export default SingleTire;

export const unmountOptions = ['Store', 'Worn out', 'Repair'];
const TireIdBox = ({ row }) => {
  return (
    <React.Fragment>
      {row?.tire_id ? (
        <>
          <Typography>{row.brand}</Typography>
          <Typography>{row.product_line}</Typography>
          <span style={{ fontWeight: 400 }}>{row.tire_id}</span>
        </>
      ) : (
        <NAComponent val={row.tire_id} />
      )}
    </React.Fragment>
  );
};

import React from 'react';
import { Box, Grid, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils/string_utils';
import { useTranslation } from 'react-i18next';
import { MdOutlineTireRepair } from 'react-icons/md';
import { ReactComponent as Tire } from 'assets/svg/home/tire.svg';
import { ReactComponent as Tire3 } from 'assets/svg/home/tire_3.svg';
import * as useClasses from 'pages/Home/const';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setParameters } from 'redux/fleets/ducks';
import PropTypes from 'prop-types';

const useStyles = makeStyles()(() => {
  return {
    tileStyles: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      flexWrap: 'wrap',
      padding: '16px 32px 16px 16px',
      borderRadius: 12,
      border: '1px solid var(--default-gray-200, #E5E7EB)',
    },
    iconStyles: {
      height: 40,
      width: 40,
    },
    container: {
      transition: 'transform 0.2s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.05)',
      },
    },
  };
});

export const TireOverview = ({
  isLoading,
  Count,
  tiresByDepth,
  outServicing,
}) => {
  const { t } = useTranslation();

  const { classes: new_classes } = useStyles();
  const theme = useTheme();

  const iconConfig = {
    fill: theme.palette.primary.main,
  };

  const { classes } = useClasses.style();

  const overviewDetails = useSelector(state => state.fleet.tireOverview);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const renderLoading = () => {
    return (
      <Box
        display='flex'
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        width='100%'
        height='100%'
      >
        <Count
          icon={<MdOutlineTireRepair size={60} {...iconConfig} />}
          isLoading={isLoading}
        />
      </Box>
    );
  };

  const renderNoTyreState = () => {
    return (
      <Box display='flex' flexDirection={'column'} justifyContent={'center'}>
        <Count
          icon={<MdOutlineTireRepair size={60} {...iconConfig} />}
          isLoading={isLoading && !overviewDetails}
        />
        <Typography
          variant='h6'
          component='span'
          className={classes.manageTiresText}
        >
          {t('common.noTiresAdded')}{' '}
          <Link to='/tire-inventory' className={classes.tireInventoryLink}>
            Tire Inventory page
          </Link>
        </Typography>
      </Box>
    );
  };

  const Lessthan3months = () => {
    if (overviewDetails)
      return (
        <Grid
          item
          xs={12}
          md={6}
          lg={4}
          onClick={() => categoryClickHandler('less_3mon', 4)}
          className={new_classes.container}
        >
          <Box className={new_classes.tileStyles}>
            <Tooltip
              title={
                <>
                  <h5>{t('homePage.lessThan3Months')}</h5>
                  <ul className={classes.list}>
                    {outServicing.lessThan3months.map(item => (
                      <li key={item.tire_id}>
                        <Link
                          to={`${ROUTES.TIRE_INVENTORY}/${item.fleet_id}/${item.system_tire_id}`}
                          className={classes.tireLink}
                        >
                          {item.tire_id}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              }
              placement='right'
              interactive
            >
              <Box>
                <Tire fill={theme.palette.primary.main} alt='tire' />
              </Box>
            </Tooltip>
            <Box mx='1rem' style={{ width: '150px' }}>
              <Typography
                variant='h6'
                gutterBottom
                color='textSecondary'
                align='center'
              >
                {t('homePage.lessThan3Months')}
              </Typography>
              <Typography
                variant='subtitle1'
                fontWeight='bold'
                gutterBottom
                align='center'
              >
                {overviewDetails.within_3mon}
              </Typography>
            </Box>
          </Box>
        </Grid>
      );
  };

  const categoryClickHandler = (location, value) => {
    dispatch(
      setParameters({
        tireTabValue: value,
        tireLocation: location,
        accessing: 'ONLY_TIRE',
      })
    );
    navigate('/overview/tire');
  };

  const renderTyreOverview = () => {
    if (isLoading) {
      return renderLoading();
    }
    if (!overviewDetails) {
      return renderNoTyreState();
    }
    if (overviewDetails) {
      return (
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            onClick={() => categoryClickHandler('IN_USE', 0)}
            className={new_classes.container}
          >
            <Box className={new_classes.tileStyles}>
              <Box>
                <Tire fill={theme.palette.primary.main} alt='tire' />
              </Box>
              <Box mx='1rem' style={{ width: '150px' }}>
                <Typography
                  variant='h6'
                  gutterBottom
                  color='textSecondary'
                  data-hook='tires_in_use'
                  align='center'
                >
                  {t('common.totalTiresInUse')}
                </Typography>
                <Typography
                  variant='subtitle1'
                  fontWeight='bold'
                  gutterBottom
                  align='center'
                >
                  {overviewDetails.tires_in_use}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            onClick={() => categoryClickHandler('less_3mm', 1)}
            className={new_classes.container}
          >
            <Box className={new_classes.tileStyles}>
              <Tooltip
                title={
                  <ul className={classes.list}>
                    {tiresByDepth.tiresBelow3.map(item => (
                      <li key={item.tire_id}>
                        <Link
                          to={`${ROUTES.TIRE_INVENTORY}/${item.fleet_id}/${item.system_tire_id}`}
                          className={classes.tireLink}
                        >
                          {item.tire_id}
                        </Link>
                      </li>
                    ))}
                  </ul>
                }
                placement='right'
                interactive
                disableHoverListener={tiresByDepth.tiresBelow3.length === 0}
              >
                <Box>
                  <Tire3 fill={theme.palette.primary.main} alt='tire' />
                </Box>
              </Tooltip>
              <Box mx='1rem' style={{ width: '150px' }}>
                <Typography
                  variant='h6'
                  gutterBottom
                  color='textSecondary'
                  align='center'
                >
                  {`< 3mm`}
                </Typography>
                <Typography
                  variant='subtitle1'
                  fontWeight='bold'
                  gutterBottom
                  align='center'
                >
                  {overviewDetails.less_3mm}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            onClick={() => categoryClickHandler('3_5_mm', 2)}
            className={new_classes.container}
          >
            <Box className={new_classes.tileStyles}>
              <Tooltip
                title={
                  <ul className={classes.list}>
                    {tiresByDepth?.tiresBetween3and5.map(item => (
                      <li key={item.tire_id}>
                        <Link
                          to={`${ROUTES.TIRE_INVENTORY}/${item.fleet_id}/${item.system_tire_id}`}
                          className={classes.tireLink}
                        >
                          {item.tire_id}
                        </Link>
                      </li>
                    ))}
                  </ul>
                }
                placement='right'
                interactive
                disableHoverListener={
                  tiresByDepth?.tiresBetween3and5?.length === 0
                }
              >
                <Box>
                  <Tire fill={theme.palette.primary.main} alt='tire' />
                </Box>
              </Tooltip>
              <Box mx='1rem' style={{ width: '150px' }}>
                <Typography
                  variant='h6'
                  gutterBottom
                  color='textSecondary'
                  align='center'
                >
                  {`3-5mm`}
                </Typography>
                <Typography
                  variant='subtitle1'
                  fontWeight='bold'
                  gutterBottom
                  align='center'
                >
                  {overviewDetails.between_3mm_5mm}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            onClick={() => categoryClickHandler('grooves_td_diff_gt_2', 3)}
            className={new_classes.container}
          >
            <Box className={new_classes.tileStyles}>
              <Tooltip
                placement='right'
                interactive
                disableHoverListener={
                  overviewDetails.grooves_td_diff_gt_2 === 0
                }
              >
                <Box>
                  <Tire fill={theme.palette.primary.main} alt='tire' />
                </Box>
              </Tooltip>
              <Box mx='1rem'>
                <Typography
                  variant='h6'
                  gutterBottom
                  color='textSecondary'
                  align='center'
                >
                  {t('common.irregular_wear')}
                </Typography>
                <Typography
                  variant='subtitle1'
                  fontWeight='bold'
                  gutterBottom
                  align='center'
                >
                  {overviewDetails.grooves_td_diff_gt_2}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {Lessthan3months()}
        </Grid>
      );
    }
    return <></>;
  };

  return (
    <Paper
      style={{
        height: '100%',
        padding: '16px 20px',
        boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
      }}
    >
      <Box style={{ marginBottom: '1rem' }}>
        <Typography
          variant='subtitle1'
          fontWeight={900}
          gutterBottom
          data-hook='tire-overview-title'
        >
          {t('common.tireOverview')}
        </Typography>
      </Box>
      <Box>{renderTyreOverview()}</Box>
    </Paper>
  );
};

TireOverview.propTypes = {
  outServicing: PropTypes.object,
  tiresByDepth: PropTypes.object,
  isLoading: PropTypes.bool,
  Count: PropTypes.elementType,
};

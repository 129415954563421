import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { setNotification } from 'redux/notifications/ducks';
import {
  setModalState,
  setUserSelector,
  setAccessTokenSelector,
  setToken,
  setUser,
} from 'redux/users/ducks';
import { deleteUser } from 'services/auth_service';
import { useTranslation } from 'react-i18next';
import CustomTextField from 'components/custom/CustomTextField';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      maxWidth: 600,
      padding: '0rem 1rem',
    },

    title: {
      fontWeight: 700,
      margin: '2rem 0rem',
    },
    fieldTitle: {
      textAlign: 'right',
      paddingTop: '0.5rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    deleteBtn: {
      margin: '28px 0px',
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
    },
  };
});

const DeleteUserModalBody = () => {
  const { classes } = useStyles();
  const [firstName, setFirstName] = useState('');
  const dispatch = useDispatch();
  const user = useSelector(setUserSelector);
  const access_token = useSelector(setAccessTokenSelector);
  const { t } = useTranslation();

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const res = await deleteUser(access_token);
      dispatch(setModalState(false));
      if (res?.status === 200) {
        dispatch(setUser(null));
        dispatch(setToken(null));
        localStorage.clear();
        window.location.reload();
      } else if (res?.status === 401) {
        dispatch(
          setNotification(
            'In case you no longer wish to use our services, contact us for further assistance',
            'error',
            true
          )
        );
      } else if (res?.status === 409) {
        dispatch(setNotification(res?.message, 'error', true));
      } else {
        console.error('E10004', 'status not 200');
        dispatch(setNotification('Request Failed', 'error', true));
      }
    } catch (err) {
      console.error(err);
      dispatch(setNotification('Request Failed', 'error', true));
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <IconButton
          style={{ float: 'right' }}
          onClick={() => dispatch(setModalState(false))}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={handleSubmit}>
          <CustomTextField
            type='text'
            label={t('deleteUserModal.nameLabel')}
            name='first'
            required='true'
            fontSize={16}
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
          <Box width='100%' mx='auto'>
            <Button
              color={'secondary'}
              type={'submit'}
              variant={'contained'}
              className={classes.deleteBtn}
              fullWidth
              disabled={firstName === user.first_name ? false : true}
            >
              <Typography variant='h6' align='center'>
                {t('common.actions.delete')}
              </Typography>
            </Button>
          </Box>
        </form>
        <Box my='1rem'>
          <Typography variant='subtitle2' gutterBottom align='center'>
            {t('deleteUserModal.deleteUserPrompt')}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DeleteUserModalBody;

import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  Typography,
  useTheme,
} from '@mui/material';
import moment from 'moment';

import { ReactComponent as Wheel } from 'assets/svg/activity/wheel.svg';
import { ReactComponent as Truck } from 'assets/svg/common/truckIcon.svg';
import { ReactComponent as Fleet } from 'assets/svg/activity/fleet.svg';
import { ReactComponent as Trailer } from 'assets/svg/common/trailerIcon.svg';
import { ReactComponent as Bus } from 'assets/svg/common/busLogo.svg';
import { useTranslation } from 'react-i18next';
import * as useClasses from './const';
import { getEventHistory } from '../../services/fleet_service';
import { useSelector } from 'react-redux';
import { setAccessTokenSelector } from '../../redux/users/ducks';
function EventsLogDrawer(props) {
  const {
    open,
    closeHandler,
    page,
    transformText,
    getUsername,
    setPage,
    setResource,
  } = props;

  const [events, setEvents] = useState([]);
  const [moreContentIsLoading, setMoreContentIsLoading] = useState(false);
  const [allContentLoaded, setAllContentLoaded] = useState(false);
  const access_token = useSelector(setAccessTokenSelector);
  const theme = useTheme();
  const { classes } = useClasses.style();

  const { t } = useTranslation();

  const resourcePic = {
    TRUCK: (
      <Truck
        style={{ height: 40, width: 40 }}
        fill={theme.palette.primary.main}
      />
    ),
    BUS: (
      <Bus
        style={{ height: 40, width: 40 }}
        fill={theme.palette.primary.main}
      />
    ),
    TRAILER: (
      <Trailer
        style={{ height: 40, width: 40 }}
        fill={theme.palette.primary.main}
      />
    ),
    FLEET: <Fleet fill={theme.palette.primary.main} alt='fleet' />,
    TIRE: <Wheel fill={theme.palette.primary.main} alt='tire' />,
  };

  useEffect(() => {
    const fetchEventLogs = async () => {
      try {
        const res = await getEventHistory(page);
        if (res?.status === 200) {
          const activities = res.data.resp.map(item => {
            return {
              ...item,
              fleetId: item.activity.fleet_id || null,
            };
          });

          setEvents(activities);
          setResource(activities);
        }
      } catch (error) {
        console.error('E20087', error);
      }
    };
    fetchEventLogs();
    // eslint-disable-next-line
  }, [getUsername, access_token]);

  const handleScroll = async event => {
    const TOLERANCE = 1;
    const atBottom =
      event.target.scrollHeight - parseInt(event.target.scrollTop) <=
      event.target.clientHeight + TOLERANCE;
    const isMultipleOf50 = events.length % 50 === 0;
    if (atBottom && isMultipleOf50 && !moreContentIsLoading) {
      setMoreContentIsLoading(true);
      await loadMoreEvents();
      setMoreContentIsLoading(false);
    } else if (atBottom && !isMultipleOf50) {
      setAllContentLoaded(true);
    }
  };

  const loadMoreEvents = async () => {
    setAllContentLoaded(false);
    const nextPage = page + 1;
    try {
      const res = await getEventHistory(nextPage);

      if (res?.status === 200) {
        const data = res?.data?.resp;

        if (data.length > 0) {
          const events_ = data;
          setEvents(prevEvents => [...prevEvents, ...events_]);
          setPage(nextPage);
        }
      }
    } catch (error) {
      console.error('E20099', error);
    }
  };

  return (
    <Drawer
      anchor='right'
      role='presentation'
      data-hook='events-log-drawer'
      open={open}
      style={{
        marginTop: 100,
      }}
      onClose={closeHandler}
      PaperProps={{
        style: {
          width: '20%',
        },
      }}
    >
      <div
        className={classes.drawer}
        onScroll={handleScroll}
        style={{
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Box className={classes.drawerContent}>
          <Typography variant='h6' data-hook={'recent-activity-heading'}>
            {t('homePage.recentActivityHeading')}
          </Typography>
          {events.map(item => (
            <Box key={item?.event_id} display='flex' flexDirection='column'>
              <Box display='flex'>
                <Box>
                  <Box
                    display='flex'
                    justifyContent='center'
                    className={classes.picBox}
                    data-hook={'event-type-image'}
                  >
                    {resourcePic[item.entity_type]}
                  </Box>
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  data-hook={'event-text'}
                >
                  {transformText(item)}
                  <span
                    style={{
                      opacity: 0.4,
                    }}
                    data-hook={'event-time'}
                  >
                    {moment(item.createdAt).fromNow()}
                  </span>
                </Box>
              </Box>
            </Box>
          ))}
          {!moreContentIsLoading ? (
            <Button
              color='primary'
              variant='text'
              onClick={handleScroll}
              className={classes.loadMoreBtn}
              data-hook={'show-more-button'}
            >
              {!allContentLoaded
                ? t('common.tireInventory.showMore')
                : t('homePage.atBottom')}
            </Button>
          ) : (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              data-hook={'circular-loader'}
            >
              <CircularProgress size={30} />
            </Box>
          )}
        </Box>
      </div>
    </Drawer>
  );
}

export default memo(EventsLogDrawer);

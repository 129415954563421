import React from 'react';
import { Card, CardContent, Fab, Grid, Modal, Typography } from '@mui/material';
import { Send, Cancel } from '@mui/icons-material';
import CustomTextField from 'components/custom/CustomTextField';
import { CustomizedTextField } from './FleetTireInventory';

export function ReportTireIssueModal(props) {
  return (
    <Modal
      open={props.openEscalate}
      onClose={props.handleEscalateModalClose}
      className={props.classes.escalateModal}
      closeAfterTransition
      data-hook='escalate-modal'
    >
      <Card className={props.classes.escalateCard}>
        <CardContent>
          <Typography variant='h4' align='center' gutterBottom>
            {props.t('escalateMessage.header')}
          </Typography>
          <Cancel
            className={props.classes.closeIcon}
            onClick={props.handleEscalateModalClose}
          />
          <form onSubmit={props.handleEscalaeSubmit}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  position: 'relative',
                }}
              >
                <CustomTextField
                  value={props.escalateMessage}
                  label={props.t('escalateMessage.message')}
                  multiline
                  fullWidth
                  rows={4}
                  onChange={props.handleEscalateMessage}
                  name='escalate message'
                  data-hook='escalate_message'
                />
                <Fab
                  color='primary'
                  aria-label='send'
                  onClick={props.handleEscalaeSubmit}
                  disabled={!props.escalateMessage}
                  className={props.classes.escapeSubmit}
                >
                  <Send />
                </Fab>
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.tire_id || 'N/A'}
                  label={props.t('tireInventoryItems.titles.tire_id')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.vehicle_name || 'N/A'}
                  label={props.t('tireInventoryItems.titles.name')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.size || 'N/A'}
                  label={props.t('tireInventoryItems.titles.size')}
                />
              </Grid>{' '}
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.brand || 'N/A'}
                  label={props.t('tireInventoryItems.titles.brand')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.product_line || 'N/A'}
                  label={props.t('tireInventoryItems.titles.product_line')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.last_tread_depth || 'N/A'}
                  label={props.t('tireInventoryItems.titles.treadDepth')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.estimated_tread_depth || 'N/A'}
                  label={props.t(
                    'tireInventoryItems.titles.estimated_current_tread_depth'
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.estimated_wear_out_date || 'N/A'}
                  label={props.t(
                    'tireInventoryItems.titles.estimated_wear_out_date'
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.last_km_driven || 'N/A'}
                  label={props.t('tireInventoryItems.titles.mileage')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={
                    props.rowSelected.status
                      ? props.t(
                          `tireInventoryItems.titles.${props.rowSelected.status}`
                        )
                      : '-'
                  }
                  label={props.t('tireInventoryItems.titles.status')}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <CustomizedTextField
                  value={props.rowSelected.last_event_date || '-'}
                  label={props.t('tireInventoryItems.titles.lastUpdate')}
                />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}

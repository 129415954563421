import React from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/system/Box';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomTextField from 'components/custom/CustomTextField';
import { VEHICLE } from 'utils/string_utils';
import CustomDatePicker from 'components/custom/CustomDatePicker';

const MoreOptions = ({
  resource,
  resourceStatic,
  setResource,
  padding,
  setError,
  setError1,
  vehicle,
  status,
  isTelematicsAvailable,
}) => {
  const { t } = useTranslation();
  const isNotTrailer =
    vehicle?.vehicle_type === VEHICLE.vehicle_type.TRUCK ||
    vehicle?.vehicle_type === VEHICLE.vehicle_type.BUS;

  const isVehicleConfigOperation = Object.keys(resource).length <= 3;
  const renderOdoMeterReadingInput = () => {
    if (!vehicle?.odometer_reading) {
      return (
        <CustomTextField
          name='odometer_reading'
          id='odometer_reading'
          data-hook='odometer_reading'
          label={t('tireConfigPage.moreOptionsModal.odometerLabel')}
          type='number'
          value={resource.odometer_reading}
          onChange={e =>
            setResource({
              ...resource,
              odometer_reading: e.target.value,
            })
          }
          variant='outlined'
          InputProps={{
            inputProps: {
              min: 0,
            },
          }}
          fullWidth
        />
      );
    }
  };
  const renderInspectionDateInput = () => {
    return (
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CustomDatePicker
            label={t('tireConfigPage.moreOptionsModal.inspectionDateLabel')}
            value={
              resource.inspection_date
                ? parseISO(resource.inspection_date)
                : null
            }
            minDate={Date.now()}
            onChange={date => {
              if (date === null) {
                setResource({
                  ...resource,
                  inspection_date: null,
                });
              } else {
                setResource({
                  ...resource,
                  inspection_date: moment(date).format('YYYY-MM-DD'),
                });
              }
            }}
            format='yyyy.MM'
            views={['year', 'month']}
            fullWidth
            data-hook='inspection-date-picker'
          />
        </LocalizationProvider>
      </Box>
    );
  };

  const renderSafetyCheckDateInput = () => {
    return (
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CustomDatePicker
            label={t('tireConfigPage.moreOptionsModal.safetyCheckLabel')}
            value={
              resource.safety_check_date
                ? parseISO(resource.safety_check_date)
                : null
            }
            onChange={date => {
              if (date === null) {
                setResource({
                  ...resource,
                  safety_check_date: null,
                });
              } else {
                setResource({
                  ...resource,
                  safety_check_date: moment(date).format('YYYY-MM-DD'),
                });
              }
            }}
            minDate={Date.now()}
            views={['year', 'month']}
            format='yyyy.MM'
            fullWidth
            data-hook='safety-date-picker'
          />
        </LocalizationProvider>
      </Box>
    );
  };

  const renderStatus = () => {
    if (status === 'new') {
      return (
        <Box
          width='100%'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          gap={'10px'}
          paddingX={padding ?? 15}
          marginTop={'30px'}
        >
          {renderOdoMeterReadingInput()}
          {renderInspectionDateInput()}
          {renderSafetyCheckDateInput()}
        </Box>
      );
    }

    const removeLetters = str => {
      const numbers = str.toString().match(/\d+/g);

      return numbers ? numbers.join('') : '';
    };
    return (
      <>
        {isNotTrailer && (
          <Grid item xs={6}>
            <CustomTextField
              name='tank_capacity'
              id='tank_capacity'
              label={t('vehiclePage.tankCapacity')}
              type='text'
              value={
                resource.tank_capacity
                  ? removeLetters(resource.tank_capacity)
                  : resource.tank_capacity
              }
              onChange={e => {
                setResource({
                  ...resource,
                  tank_capacity: removeLetters(e.target.value),
                });
              }}
              variant='outlined'
              fullWidth
            />
          </Grid>
        )}
        {!isTelematicsAvailable && (
          <Grid item xs={!isVehicleConfigOperation ? 6 : 12}>
            <CustomTextField
              name='odometer_reading'
              id='odometer_reading'
              label={t('tireConfigPage.moreOptionsModal.odometerLabel')}
              type='text'
              value={removeLetters(resource.odometer_reading)}
              onChange={e => {
                setResource({
                  ...resource,
                  odometer_reading: removeLetters(e.target.value),
                });
              }}
              variant='outlined'
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={!isVehicleConfigOperation ? 6 : 12}>
          {renderInspectionDateInput()}
        </Grid>
        <Grid item xs={!isVehicleConfigOperation ? 6 : 12}>
          {renderSafetyCheckDateInput()}
        </Grid>
      </>
    );
  };

  return <>{renderStatus()}</>;
};

export default MoreOptions;

MoreOptions.propTypes = {
  resource: PropTypes.object,
  setResource: PropTypes.func,
  padding: PropTypes.any,
  setError: PropTypes.func,
  setError1: PropTypes.func,
  vehicle: PropTypes.object,
  status: PropTypes.any,
};

import {
  Badge,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import SearchBar from 'components/custom/SearchTemp';
import { NAComponent } from '../TireInventory/TireInventoryItems/FleetTireInventory';
import { Link } from 'react-router-dom';
import { BsDownload } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const CommonGridItem = props => {
  const {
    title,
    currentTab,
    tabs,
    handleChange,
    overview,
    setSearchQuery,
    results,
    identifier,
    downloadResources,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const getTotalForCurrentTab = item => {
    if (identifier?.includes('tire')) {
      return overview?.[item.category];
    }
    return item.count;
  };

  const getPath = result => {
    let link = `/fleets-data/${
      result?.fleet_id
    }/${result?.vehicle_type?.toLowerCase()}s/${result?.vehicle_id}`;
    if (identifier === 'tire') {
      link = `/tire-inventory/${result?.fleet_id}/${result?.system_tire_id}`;
    }

    return link;
  };

  const getDisplayName = result => {
    let name = `${result?.name} [${result?.vehicle_type}]`;
    if (identifier === 'tire') {
      name = `${result?.brand} ${result?.product_line} - ${result?.tire_id}`;
    }

    return name;
  };
  const isLoadingSearchResults = useSelector(
    state => state.fleet.isLoadingSearchResults
  );

  return (
    <>
      <Grid item xs={12}>
        <Box
          display='flex'
          alignItems='center'
          gap={'20px'}
          position={'relative'}
        >
          <Typography variant='h5' data-hook='overview-title'>
            {title}
          </Typography>
          <SearchBar
            hook={'overview'}
            setSearchQuery={setSearchQuery}
            show={show}
            setShow={setShow}
          />
          <Tooltip title={t('common.download')}>
            <IconButton
              onClick={downloadResources}
              disabled={!tabs[currentTab].count}
            >
              <BsDownload
                size={'22px'}
                data-hook='bs-download-button'
                color={
                  tabs[currentTab].count ? theme.palette.primary.main : 'grey'
                }
              />
            </IconButton>
          </Tooltip>
          {!isLoadingSearchResults && show && (
            <Paper
              elevation={3}
              sx={{
                position: 'absolute',
                top: '100%',
                left: identifier !== 'tire' ? 238 : 190,
                zIndex: 99,
                padding: theme.spacing(2),
                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                backdropFilter: 'blur(15px)',
                width: !identifier === 'tire' ? 340 : 354,
                maxHeight: 440,
                overflow: 'auto',
              }}
            >
              {results?.length ? (
                results.map(result => (
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: theme.palette.primary.link,
                    }}
                    color='primary'
                    to={getPath(result)}
                  >
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton
                          component='a'
                          data-hook='search-results'
                          href={getPath(result)}
                        >
                          <NAComponent val={getDisplayName(result)} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Link>
                ))
              ) : (
                <span data-hook='vehicle-not-found'>
                  Your search did not return a{' '}
                  {identifier === 'tire' ? 'tire' : 'vehicle'}
                </span>
              )}
            </Paper>
          )}
        </Box>
        <Box marginTop={'10px'}>
          <Tabs
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            value={currentTab}
            onChange={handleChange}
          >
            {tabs.map((item, i) => (
              <Tab
                key={i}
                id={item.id}
                label={
                  <Badge
                    badgeContent={getTotalForCurrentTab(item)}
                    color='primary'
                    max={9999}
                    showZero
                  >
                    <Typography variant='h6'>{item.label}</Typography>
                  </Badge>
                }
                sx={{ textTransform: 'none' }}
              ></Tab>
            ))}
          </Tabs>
        </Box>
        <Box
          // height={!identifier?.includes('tire') ? '600px' : '500px'}
          overflow='auto'
        >
          {tabs.map((item, i) => (
            <div key={i}>
              {currentTab === i && <Box mt={2}>{item.component}</Box>}
            </div>
          ))}
        </Box>
      </Grid>
    </>
  );
};

export default CommonGridItem;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { setUserSelector } from 'redux/users/ducks';
import { USER_LS, USER_ROLES } from 'utils/string_utils';

const ActionButtons = props => {
  const userLs = JSON.parse(USER_LS);
  const userDetails = useSelector(setUserSelector) || userLs;
  const { t } = useTranslation();

  const userIsAReporter = userDetails?.iam_role === USER_ROLES.reporter;
  return (
    <>
      {!userIsAReporter ? (
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={3}
          // marginTop={'5px'}
        >
          <Grid item>
            <Button
              color='inherit'
              variant='contained'
              onClick={props.handleClear}
              sx={{ textTransform: 'unset' }}
            >
              <Typography variant='h5' style={{ fontSize: 20 }}>
                {t('customerSettingsPage.actions.cancel')}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              onClick={props.handleUpdate}
              disabled={props.disabled}
              sx={{ textTransform: 'unset', width: 120 }}
              data-hook={props.datahook ? props.datahook : 'Update-pref-button'}
              data-cy='update-preference-button'
            >
              {props?.isSubmitting ? (
                <CircularProgress size={30} color='inherit' />
              ) : (
                <Typography variant='h5' style={{ fontSize: 20 }}>
                  {t('customerSettingsPage.actions.update')}
                </Typography>
              )}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default ActionButtons;

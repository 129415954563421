import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Card, CardContent } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      margin: '0rem 0.5rem',
      padding: '48px 68px 22px',
      boxShadow:
        '0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)',
      borderRadius: 4,
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0px',
      },
    },
    title: {
      fontSize: 14,
    },
    links: {
      color: '#007FFF',
      cursor: 'pointer',
    },
  };
});

const VerificationLoader = () => {
  const { classes } = useStyles();
  let navigate = useNavigate();
  useEffect(() => {
    setTimeout(function () {
      navigate('/home');
    }, 10000);
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      style={{
        scrollBehavior: 'unset',
      }}
      width='100%'
      height='100vh'
      bgcolor='#ccc'
    >
      <Box
        height='100%'
        display='flex'
        flexGrow={1}
        justifyContent='center'
        alignItems='center'
      >
        <Card className={classes.root}>
          <CardContent>
            <Typography variant='h5' gutterBottom align='left'>
              Confirm Your Email Address
            </Typography>
            <Typography variant='h6' gutterBottom align='left'>
              A confirmation email has been sent on your registered email .
              Click on the confirmation link in the email to activate your
              account
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default VerificationLoader;

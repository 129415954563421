const INITIAL_STATE = {
  inventoryChanged: false,
  brands: null,
  euTires: null,
};

export const InventoryActionTypes = {
  SET_INVENTORY_CHANGED: 'SET_INVENTORY_CHANGED',
  SET_EU_TIRES: 'SET_EU_TIRES',
  SET_BRANDS: 'SET_BRANDS',
};

export const setChangeInInventory = value => ({
  type: InventoryActionTypes.SET_INVENTORY_CHANGED,
  payload: value,
});

export const setTiresDb = value => ({
  type: InventoryActionTypes.SET_EU_TIRES,
  payload: value,
});

export const setTiresBrands = value => ({
  type: InventoryActionTypes.SET_BRANDS,
  payload: value,
});
export const inventoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InventoryActionTypes.SET_INVENTORY_CHANGED:
      return { ...state, inventoryChanged: action.payload };
    case InventoryActionTypes.SET_EU_TIRES:
      return { ...state, euTires: action.payload };
    case InventoryActionTypes.SET_BRANDS:
      return { ...state, brands: action.payload };
    default:
      return state;
  }
};

export const inventoryChangeSelector = state =>
  state.inventory.inventoryChanged;
export const tiresDbSelector = state => state.inventory.euTires;
export const tiresBrandsSelector = state => state.inventory.brands;

import React, { useState } from 'react';
import {
  Box,
  Button,
  Fab,
  Fade,
  IconButton,
  Modal,
  Paper,
  Typography,
} from '@mui/material';

import { Close } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MultipleTireAddForm from 'pages/TireInventory/MultipleTireAddForm';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import { setAccessTokenSelector } from 'redux/users/ducks';
import { addTire, fleetOverview } from 'services/fleet_service';
import { TIRES } from 'utils/string_utils';
import { fetchTireInventoryDataAndProcess } from 'pages/TireInventory/TireInventory';
import {
  setFleetOverview,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  unprocessedTireInventoryDataSelector,
} from 'redux/fleets/ducks';
import ProgressBar from './ProgressBar';
import { makeStyles } from 'tss-react/mui';
import UploadTires from './UploadTires';
const useStyles = makeStyles()(() => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      maxWidth: '1000px',
      width: 'auto',
      height: 'auto',
      border: 'none',

      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 3rem',
      position: 'relative',
    },
    next: {
      alignSelf: 'flex-end',
      textTransform: 'Capitalize',
      marginTop: 20,
    },
    backButton: {
      position: 'absolute',
      left: 5,
      top: '50%',
      transform: 'translate(0, -50%)',
    },
    closeIcon: {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer',
    },
    btns: {
      textTransform: 'unset',
    },
  };
});

function generateRandomCharacters() {
  let generatedOutput = '';
  let storedCharacters =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let totalCharacterSize = storedCharacters.length;
  for (let index = 0; index < 9; index++) {
    generatedOutput += storedCharacters.charAt(
      Math.floor(Math.random() * totalCharacterSize)
    );
  }
  return generatedOutput;
}

const UPLOAD = 1;
const GENERATE = 0;
const AddMultipleTires = ({ open, setOpen, fleet }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [tireBrand, setTireBrand] = useState(null);
  const [productLine, setProductLine] = useState(null);

  const [size, setSize] = useState(null);
  const [progress, setProgress] = useState(0);
  const [flag, setFlag] = useState(false);

  const [error, setError] = useState({
    [TIRES.TIRE_ID]: null,
    [TIRES.TREAD_DEPTH]: null,
    tire_pressure: null,
  });
  const [payload, setPayload] = useState({
    isRetread: false,
    last_tread_depth: '',
    no_of_tires: 1,
    isRegrooved: false,
  });

  const [step, setStep] = useState(0);
  const [axleName, setAxleName] = useState(null);
  const [checked, setChecked] = useState(false);
  const access_token = useSelector(setAccessTokenSelector);
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);
  const [selection, setSelection] = useState(null);
  const [grooves, setGrooves] = useState({
    1: null,
  });
  const handleChangeSwitch = event => {
    setChecked(event.target.checked);
  };
  const handleClose = () => {
    setOpen(false);
    setPayload({
      isRetread: true,
      last_tread_depth: '',
      no_of_tires: 1,
    });
    setSize(null);
    setProductLine(null);
    setTireBrand(null);
    setError({
      [TIRES.TIRE_ID]: null,
      [TIRES.TREAD_DEPTH]: null,
    });
    setGrooves({
      1: null,
    });
    generateRandomCharacters();
    setAxleName(null);
    setStep(0);
    setFlag(false);
    setProgress(0);
    setSelection(null);
  };

  const handleChange = async e => {
    if (e.target.name === TIRES.TREAD_DEPTH) {
      const depthError =
        parseFloat(e.target.value) > TIRES.MAX_DEPTH
          ? t('common.tireInventory.maxDepthError')
          : null;

      setError({
        ...error,
        [TIRES.TREAD_DEPTH]: depthError,
      });
    }
    if (e.target.name === 'tire_pressure') {
      if (parseFloat(e.target.value) > 15 || parseFloat(e.target.value) < 5) {
        setError({
          ...error,
          tire_pressure: t('common.pressureValueError'),
        });
      } else {
        setError({
          ...error,
          tire_pressure: null,
        });
      }
    }

    setPayload({
      ...payload,
      [e.target.name]:
        e.target.name === TIRES.RETREAD || e.target.name === TIRES.REGROOVE
          ? e.target.checked
          : e.target.value,
    });
  };
  const submitFormHandler = async e => {
    setFlag(true);
    e.preventDefault();

    const payload_ = {
      fleet_id: fleet,
    };

    const tires = Array.from({ length: payload.no_of_tires }, (_, i) => ({
      tire_id: `${Date.now().toString(24)}${(i + 1).toString()}`,
      brand: tireBrand,
      product_line: productLine,
      size: size,
      tread_depth: payload.last_tread_depth,
      isRetread: payload.isRetread,
      isRegrooved: payload.isRegrooved,
      ...(payload.tire_pressure !== '' && {
        pressure: payload.tire_pressure,
      }),
      grooves_tread_depth: checked ? grooves : {},
    }));

    const updatedPayload = { ...payload_, tires };

    try {
      const res = await addTire(access_token, updatedPayload);

      if (res.status === 200) {
        const data = await fetchTireInventoryDataAndProcess({
          fleet_id: fleet,
          inventoryData: fleetInventoryData,
          refetch: true,
        });
        dispatch(setTireInventoryData(data, fleet));
        dispatch(setUnprocessedTireInventoryData(data.res, fleet));
        dispatch(
          setNotification('All tires successfully uploaded', 'success', true)
        );

        const fleetResponse = await fleetOverview(access_token);
        dispatch(setFleetOverview(fleetResponse.data.resp));
        handleClose();
      } else {
        dispatch(
          setNotification(
            'An error has occurred. Please try again.',
            'error',
            true
          )
        );
      }
    } catch (error) {
      console.error('E20047', error);
    }

    dispatch(
      setNotification('All tires successfully uploaded', 'success', true)
    );
    handleClose();
  };

  const renderAxleList = () => {
    return axle_Name.map((item, i) => (
      <Button
        key={i}
        variant='contained'
        color='primary'
        disableElevation
        style={{
          textTransform: 'unset',
          opacity: axleName !== item && axleName !== null ? 0.5 : 1,
        }}
        onClick={() => {
          if (axleName === item) {
            setAxleName(null);
          } else {
            setAxleName(item);
          }
        }}
        disabled={axleName !== item && axleName !== null ? true : false}
        data-hook={item}
      >
        {item}
      </Button>
    ));
  };

  const renderFleetsSteps = () => {
    switch (step) {
      case 0:
        return (
          <Box
            display='flex'
            flexDirection='column'
            data-hook='axle-select-view'
          >
            <h2>Axle Name</h2>
            <Box
              width='100%'
              display='flex'
              justifyContent='center'
              flexWrap='wrap'
              gap={'5px'}
            >
              {renderAxleList()}
            </Box>
            <Button
              className={classes.next}
              variant='outlined'
              color='primary'
              disabled={axleName === null}
              onClick={() => setStep(1)}
              data-hook='next-button'
            >
              Next
            </Button>
          </Box>
        );

      case 1:
        return (
          <div data-hook='tire-form-modal'>
            <h2>{t('common.tireInventory.addTires')}</h2>
            <MultipleTireAddForm
              productLine={productLine}
              tireBrand={tireBrand}
              setTireBrand={setTireBrand}
              size={size}
              setSize={setSize}
              error={error}
              handleClose={handleClose}
              setProductLine={setProductLine}
              handleChange={handleChange}
              payload={payload}
              setPayload={setPayload}
              submitFormHandler={submitFormHandler}
              axleName={axleName}
              grooves={grooves}
              checked={checked}
              setGrooves={setGrooves}
              handleChangeSwitch={handleChangeSwitch}
              caller='inventory'
            />
            <Box
              className={classes.backButton}
              data-hook='back-btn'
              onClick={() => setStep(0)}
            >
              <Fab color='primary' aria-label='next' size='small'>
                <ArrowBackIcon />
              </Fab>
            </Box>
          </div>
        );

      default:
        <></>;
    }
  };

  const renderAddTiresSection = () => {
    if (flag) {
      return (
        <Box>
          <Typography variant='h6' gutterBottom align='center'>
            {t('common.tireInventory.uploading')}
          </Typography>
          <ProgressBar progress={progress} />
        </Box>
      );
    } else {
      return <>{renderFleetsSteps()}</>;
    }
  };

  function actionsSelectionView() {
    return (
      selection === null && (
        <Box display='flex' alignItems='center' gap='10px'>
          <Button
            variant='contained'
            color='primary'
            className={classes.btns}
            data-hook='generateTiresBtn'
            onClick={() => setSelection(GENERATE)}
          >
            {t('common.tireInventory.multipleUpload')}
          </Button>
          <Button
            variant='contained'
            color='inherit'
            className={classes.btns}
            data-hook='uploadTiresBtn'
            onClick={() => setSelection(UPLOAD)}
          >
            {t('common.tireInventory.uploadFile')}
          </Button>
        </Box>
      )
    );
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          {actionsSelectionView()}
          {selection === UPLOAD && <UploadTires fleetId={fleet} />}
          {selection === GENERATE && renderAddTiresSection()}
          <div
            className={classes.closeIcon}
            data-hook='close-modal-btn'
            onClick={handleClose}
          >
            <IconButton>
              <Close />
            </IconButton>
          </div>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AddMultipleTires;

const axle_Name = ['STEER', 'DRIVE', 'TRAILER', 'BUS'];

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import {
  setModalState,
  setUser,
  setToken,
  workforceSelector,
  setUserSelector,
} from 'redux/users/ducks';
import { useFormik } from 'formik';

import { resetvalidationSchema } from 'utils/string_utils';
import { ResetLinkRequest } from 'services/auth_service';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      maxWidth: 600,
      padding: '0rem 2rem',
      [theme.breakpoints.down('xs')]: {
        maxWidth: 300,
      },
    },

    title: {
      fontWeight: 700,
      margin: '2rem 0rem',
    },
    fieldTitle: {
      textAlign: 'right',
      paddingTop: '0.5rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    input_box: {
      margin: '0.5rem 0rem',
    },
    submitBtn: {
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
    },
  };
});

const ChangePasswordModalBody = () => {
  const { classes } = useStyles();
  const [transition, setTransition] = useState(false);
  const [counter, setCounter] = useState(5);
  const workforce = useSelector(workforceSelector);
  const user = useSelector(setUserSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    if (counter === 0) {
      dispatch(setUser(null));
      dispatch(setToken(null));
      localStorage.clear();
      window.location.reload();
    }
    // eslint-disable-next-line
  }, [counter]);
  useEffect(() => {
    function counterFunction() {
      let myInterval = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
      return () => clearInterval(myInterval);
    }
    if (transition) {
      counterFunction();
    }
  }, [transition, counter]);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetvalidationSchema,
    onSubmit: async values => {
      try {
        let account = workforce?.workforceData.filter(
          x => x.email === values.email
        );
        let flag = false;
        if (account.length) {
          flag =
            account[0]['name'] === [user.first_name, user.last_name].join(' ');
        }

        if (flag) {
          const res = await ResetLinkRequest(values.email);
          if (res?.status === 200) {
            dispatch(
              setNotification(
                'Change password link has been sent to registered email',
                'success',
                true
              )
            );
            setTransition(true);
          } else if (res?.status === 401) {
            dispatch(
              setNotification(
                'Wrong username. Please sign-up in order to use our services.',
                'error',
                true
              )
            );
          } else {
            dispatch(
              setNotification(
                'An error has occurred. Please try again.',
                'error',
                true
              )
            );
          }
        } else {
          dispatch(
            setNotification(
              'Enter email linked for this account',
              'error',
              true
            )
          );
        }
      } catch (err) {
        console.error(err);
        dispatch(
          setNotification(
            'An error has occurred. Please try again.',
            'error',
            true
          )
        );
      }
    },
  });

  return (
    <Card className={classes.root}>
      {transition ? (
        <CardContent>
          <Typography variant='subtitle1' gutterBottom align='center'>
            {t('changePassword.logout')}
            {counter} seconds
          </Typography>
        </CardContent>
      ) : (
        <CardContent>
          <IconButton
            style={{ float: 'right' }}
            onClick={() => dispatch(setModalState(false))}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant='h5' gutterBottom align='center' noWrap>
            {t('changePassword.change_password')}
          </Typography>
          <Box my='1rem'>
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                fullWidth
                variant='outlined'
                className={classes.input_box}
              >
                <TextField
                  label={t('changePassword.enter_email')}
                  id='outlined-adornment-password'
                  margin={'normal'}
                  name={'email'}
                  placeholder={`${t('common.eg')} abc@co2opt.com`}
                  type={'text'}
                  required
                  variant={'outlined'}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
              </FormControl>

              <Box width='100%' mx='auto'>
                <Button
                  color={'primary'}
                  type={'submit'}
                  variant={'contained'}
                  className={classes.submitBtn}
                  fullWidth
                >
                  <Typography variant='h6' align='center'>
                    Submit
                  </Typography>
                </Button>
              </Box>
            </form>
            <Box my='1rem'>
              <Typography variant='subtitle2' gutterBottom align='center'>
                {t('changePassword.message')}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      )}
    </Card>
  );
};

export default ChangePasswordModalBody;

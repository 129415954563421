import React from 'react';
import truck from 'assets/images/truck _logo_1.png';
import trailer from 'assets/images/trailer_logo_1.png';
import { Backdrop, Modal, Paper, Typography } from '@mui/material';
import classes from './AttachAnimation.module.css';

const AttachAnimation = ({ open, handleClose, t }) => {
  const [attached, setAttached] = React.useState(false);

  React.useEffect(() => {
    setAttached(!attached);
    const timeout = setTimeout(() => {
      handleClose();
    }, 3000);
    return () => clearTimeout(timeout);

    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.container}>
        <img
          src={truck}
          alt='Truck'
          className={`${classes.truck} ${attached ? classes.attached : ''}`}
        />
        <img src={trailer} alt='Trailer' className={classes.trailer} />
        <Typography
          className={classes.h5}
          variant='subtitle'
          color='primary'
          style={{ marginBottom: 20 }}
        >
          {t('vehiclePage.attachSuccess')}
        </Typography>
      </Paper>
    </Modal>
  );
};

export default AttachAnimation;

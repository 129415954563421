import { co2OptBaseApi } from '../axios.config';

const customer_api_common_route = '/customer';

export const getOrganizationProfile = async access_token => {
  return await co2OptBaseApi.get(`${customer_api_common_route}/profile`, {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const updateOrganizationData = async (access_token, payload) => {
  return await co2OptBaseApi.post(
    `${customer_api_common_route}/info`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
};

export const updateTireConfig = async (access_token, payload) => {
  return await co2OptBaseApi.post(
    `${customer_api_common_route}/tire-management`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
};
export const updateTruckConfig = async (access_token, payload) => {
  return await co2OptBaseApi.post(
    `${customer_api_common_route}/truck-config`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
};
export const updateTrailerConfig = async (access_token, payload) => {
  return await co2OptBaseApi.post(
    `${customer_api_common_route}/trailer-config`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
};
export const updateBusConfig = async (access_token, payload) => {
  return await co2OptBaseApi.post(
    `${customer_api_common_route}/bus-config`,
    payload,
    {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    }
  );
};

import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Fade,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import xlsx from 'assets/images/xlsx.png';
import csv from 'assets/images/csv.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    xlsx: {
      height: 30,
      width: 30,
    },
    csv: {
      height: 25,
      width: 25,
    },
    container: {
      width: 340,
      padding: 40,
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    btns: {
      alignSelf: 'flex-start',
      height: 56,
    },
    cancel: {
      borderRadius: 24,
      textTransform: 'unset',
      width: '100%',
    },
    title: {
      fontWeight: 900,
    },
  };
});

const Downloads = props => {
  const { dialogOpen, setDialogOpen, handleDownload, title } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={dialogOpen}
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
      onClose={() => setDialogOpen(false)}
      closeAfterTransition
      data-hook='download-file-modal'
    >
      <Fade in={dialogOpen}>
        <Paper className={classes.container}>
          {title && (
            <Typography
              variant='h6'
              className={classes.title}
              data-hook='download-file-title'
            >
              {title}
            </Typography>
          )}
          <Typography variant='caption' align='center'>
            {t('uploadSummary.fileType')}
          </Typography>
          <Box display='flex' flexDirection='column'>
            <Button
              variant='text'
              startIcon={<img className={classes.xlsx} src={xlsx} alt='xlsx' />}
              className={classes.btns}
              onClick={() => handleDownload('xlsx')}
              data-hook='download-xlsx'
            >
              XLSX
            </Button>
            <Button
              variant='text'
              startIcon={<img className={classes.csv} src={csv} alt='csv' />}
              className={classes.btns}
              onClick={() => handleDownload('csv')}
            >
              CSV
            </Button>
          </Box>
          <Button
            variant='outlined'
            color='primary'
            className={classes.cancel}
            data-hook='download-cancel-btn'
            onClick={() => setDialogOpen(false)}
          >
            {t('common.cancel')}
          </Button>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default Downloads;

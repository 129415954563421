import React, { useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { fleetOverview, updateTire } from 'services/fleet_service';
import { setNotification } from 'redux/notifications/ducks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  setFleetOverview,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  unprocessedTireInventoryDataSelector,
  updateTireInventoryData,
} from 'redux/fleets/ducks';
import { TIRES, TIRE_INVENTORY, tireStatuses } from 'utils/string_utils';
import { fetchTireInventoryDataAndProcess } from './TireInventory';
import { useParams } from 'react-router';
import CustomTextField from 'components/custom/CustomTextField';
import { makeStyles } from 'tss-react/mui';
import CustomDatePicker from 'components/custom/CustomDatePicker';
import { setAccessTokenSelector } from 'redux/users/ducks';
import {
  inventoryChangeSelector,
  setChangeInInventory,
} from 'redux/tireInventory/ducks';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      // maxWidth: 500,
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 10,
      padding: 20,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    btn: {
      margin: '28px 0px',
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
    },
    boxes: {
      padding: 10,
      // border: `3px solid grey`,
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      position: 'relative',

      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    icons: {
      height: 90,
      width: 90,
      fill: theme.palette.primary.main,
    },
    statusBox: {
      borderRadius: 4,
      border: '1.5px solid #6b7280',
      padding: '1px 12px 1px 12px',
    },
    btns: {
      minWidth: '100px',
      textTransform: 'unset',
      marginTop: 50,
      borderRadius: 24,
      flex: '10%',
    },
    selected: {
      // transform: 'scale(0.8)',
      border: '2px solid grey',
    },
  };
});

const RETREAD = 'RETREADING';
const RETREADED = 'RETREAD';
const REGROOVE = 'REGROOVE';
const REPAIR = 'REPAIR';
const REFURBISH = 'REFURBISH';
const ManageTire = ({
  open,
  setOpen,
  id,
  tireInventoryAction,
  data,
  setTire,
  location = 'ALL',
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const [itemSelected, setItemSelected] = useState(null);
  const [eventType, setEventType] = useState(null);
  const [treadDepth, setTreadDepth] = useState('');
  const params = useParams();
  const { tire_id } = params;
  const access_token = useSelector(setAccessTokenSelector);
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);
  const currInventoryChangeValue = useSelector(inventoryChangeSelector);

  const itemClickHandler = index => {
    const events = {
      [REPAIR_INDEX]: REPAIR,
      [RETREAD_INDEX]: RETREAD,
      [REGROOVE_INDEX]: REGROOVE,
      [DISPOSE_INDEX]: 'DISPOSE',
      [RETREADING_INDEX]: RETREADED,
      [REFURBISH_INDEX]: REFURBISH,
    };
    const event = events[index];
    setEventType(event);
    setItemSelected(index);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);

    const createEvent = () => {
      const baseEvent = {
        event_type: eventType,
        event_date: moment(eventDate).format('YYYY-MM-DD'),
      };

      return eventType === REGROOVE && treadDepth
        ? { ...baseEvent, tread_depth: treadDepth }
        : baseEvent;
    };

    const payload_ = {
      events: [createEvent()],
    };

    try {
      const res = await updateTire(id, payload_);

      if (res.status === 200) {
        dispatch(setNotification(t('common.success'), 'success', true));

        if (tireInventoryAction) {
          const inventoryActionType =
            eventType === 'DISPOSE'
              ? TIRE_INVENTORY.UPDATE.DELETE
              : TIRE_INVENTORY.UPDATE.UPDATE;

          dispatch(
            updateTireInventoryData(
              '',
              res?.data?.resp?.tire_id,
              res?.data?.resp?.fleet_id,
              inventoryActionType
            )
          );
        }

        const data = await fetchTireInventoryDataAndProcess({
          fleet_id: res?.data?.resp?.fleet_id,
          inventoryData: fleetInventoryData,
          refetch: true,
          location,
        });

        dispatch(setTireInventoryData(data, res?.data?.resp?.fleet_id));
        dispatch(
          setUnprocessedTireInventoryData(data.res, res?.data?.resp?.fleet_id)
        );
        dispatch(setChangeInInventory(!currInventoryChangeValue));
        const fleetResponse = await fleetOverview(access_token);
        dispatch(setFleetOverview(fleetResponse.data.resp));
        handleClose();

        if (!!tire_id) {
          const updatedTire = {
            ...res?.data?.resp,
            tireStatus: tireStatuses[res?.data?.resp?.status],
          };
          setTire(updatedTire);
        }
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20059', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setItemSelected(null);
  };

  const getButtonStyle = currentIndex => {
    return itemSelected === currentIndex ? 'contained' : 'outlined';
  };

  const renderManageTireSection = () => {
    if (data?.status.includes('BANK')) {
      return (
        <Box
          width='100%'
          display='flex'
          justifyContent='space-evenly'
          gap={'10px'}
          data-hook='manage-tires'
        >
          <Button
            variant={getButtonStyle(REPAIR_INDEX)}
            color='primary'
            className={classes.btns}
            onClick={() => itemClickHandler(REPAIR_INDEX)}
            data-hook='manage-tire'
          >
            {t('manageTire.repairBtn')}
          </Button>
          <Button
            variant={getButtonStyle(RETREAD_INDEX)}
            color='primary'
            className={classes.btns}
            onClick={() => itemClickHandler(RETREAD_INDEX)}
            data-hook='retread-button'
          >
            {t('manageTire.retreadBtn')}
          </Button>
          <Button
            variant={getButtonStyle(REGROOVE_INDEX)}
            color='primary'
            className={classes.btns}
            onClick={() => itemClickHandler(REGROOVE_INDEX)}
            data-hook='regroove-btn'
          >
            {t('manageTire.regrooveBtn')}
          </Button>
          <Button
            variant={getButtonStyle(DISPOSE_INDEX)}
            color='secondary'
            className={classes.btns}
            onClick={() => itemClickHandler(DISPOSE_INDEX)}
            data-hook='dispose-btn'
          >
            {t('manageTire.disposeBtn')}
          </Button>
        </Box>
      );
    }
  };

  const renderTireStatuses = () => {
    switch (data?.tireStatus) {
      case tireStatuses.RETREADING:
        return (
          <Button
            variant={getButtonStyle(RETREADING_INDEX)}
            color='primary'
            className={classes.btns}
            onClick={() => itemClickHandler(RETREADING_INDEX)}
            data-hook='retread-complete'
          >
            {t('manageTire.retreadComplete')}
          </Button>
        );

      case tireStatuses.REPAIR:
        return (
          <Button
            variant={getButtonStyle(REFURBISH_INDEX)}
            color='primary'
            className={classes.btns}
            onClick={() => itemClickHandler(REFURBISH_INDEX)}
            data-hook='refurbished'
          >
            {t('manageTire.refurbished')}
          </Button>
        );

      default:
        <></>;
    }
  };

  const renderEvent = () => {
    if (eventType === REGROOVE) {
      return (
        <Box width='100%' my={1}>
          <CustomTextField
            name={TIRES.TREAD_DEPTH}
            required={true}
            id={TIRES.TREAD_DEPTH}
            label={t('common.tireInventory.depth')}
            onChange={e => setTreadDepth(e.target.value)}
            variant='outlined'
            value={treadDepth}
            type='number'
            InputProps={{
              inputProps: {
                min: 0,
                max: 20,
                step: 'any',
              },
            }}
            data-hook='tread-depth'
            fullWidth
          />
        </Box>
      );
    }
  };

  const renderSelectedItem = () => {
    if (itemSelected !== null) {
      return (
        <Box width='100%' marginTop={'20px'}>
          <form onSubmit={handleSubmit}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CustomDatePicker
                label={t('tireOperationsModal.eventDate')}
                value={eventDate}
                onChange={date => setEventDate(date)}
                fullWidth
                data-hook='date-picker'
              />
            </LocalizationProvider>
            {renderEvent()}

            <Box width='100%' mx='auto'>
              <Button
                color={eventType === 'DISPOSE' ? 'secondary' : 'primary'}
                type='submit'
                variant='contained'
                className={classes.btns}
                fullWidth
                disabled={isSubmitting || eventDate === null}
                data-hook='confirm-button'
              >
                <Typography variant='h6' align='center'>
                  {'Confirm'}
                </Typography>
              </Button>
            </Box>
          </form>
        </Box>
      );
    }
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Paper className={classes.root}>
          <IconButton
            style={{ alignSelf: 'flex-end' }}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>

          <Box display='flex' alignItems='center' gap={'20px'}>
            <Typography>{data?.tire_id}</Typography>
            <Box className={classes.statusBox}>{data?.tireStatus}</Box>
          </Box>
          {renderManageTireSection()}
          {renderTireStatuses()}
          {renderSelectedItem()}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ManageTire;

const REPAIR_INDEX = 0;
const RETREAD_INDEX = 1;
const REGROOVE_INDEX = 2;
const DISPOSE_INDEX = 3;
const RETREADING_INDEX = 4;
const REFURBISH_INDEX = 5;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IconButton, Modal, Backdrop } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';

import ChangePasswordModalBody from '../modalBody/ChangePasswordModalBody';
import {
  setModalStateSelector,
  setModalStatusSelector,
} from 'redux/users/ducks';
import { setModalState } from 'redux/users/ducks';
import DeleteUserModalBody from 'components/modalBody/DeleteUserModalBody';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    main: {
      height: '100vh',
      backgroundColor: '#F3F4F6',
      width: '100vw',
      overflow: 'auto',

      paddingBottom: '3rem',
    },
    mainBox: {
      width: '50%',
      margin: '0px auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      boxShadow:
        '0px 0px 2px rgba(145, 158, 171, 0.24), 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
      padding: 2,
      borderRadius: 16,
      height: '100%',
      marginTop: '1.5rem',
    },
    iconBtn: {
      float: 'right',
      color: '#fff',
    },
  };
});

const Layout = ({ children }) => {
  const { classes } = useStyles();
  const dispatch = useDispatch(setModalState);
  const modalState = useSelector(setModalStateSelector);
  const modalStatus = useSelector(setModalStatusSelector);

  const handleClose = () => {
    dispatch(setModalState(false));
  };

  return (
    <>
      <main className={classes.main} data-hook='container-main'>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={modalState}
          onClose={handleClose}
          closeAfterTransition
          slots={Backdrop}
          slotProps={{
            timeout: 500,
          }}
        >
          {modalStatus === 'change-password' ? (
            <ChangePasswordModalBody />
          ) : modalStatus === 'delete-user' ? (
            <DeleteUserModalBody />
          ) : modalStatus === 'tire-provider' ? (
            <div className={classes.mainBox}>
              <IconButton
                className={classes.iconBtn}
                onClick={() => {
                  dispatch(setModalState(false));
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <iframe
              name='iframe_a'
              src='http://info.cern.ch/'
              title='W3Schools Free Online Web Tutorials'
              allowFullScreen={true}
            ></iframe>
          )}
        </Modal>
        {children}
      </main>
    </>
  );
};

export default Layout;

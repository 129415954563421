import axios from 'axios';
import { co2OptBaseApi } from '../axios.config';
import { decrypter } from '../utils/Encrypter';

const user_api_common_routes = '/user';
export const base_url = `${process.env.REACT_APP_CO2OPT_BASE_URL}/user`;
export const sso_url = process.env.REACT_APP_SSO_URL;
export const messenger_url = `${process.env.REACT_APP_CO2OPT_BASE_URL}/messenger`;
export const Signup = async data => {
  const form = new FormData();
  form.append('email', data.email);
  form.append('password', data.password);
  form.append('first_name', data.firstName);
  form.append('last_name', data.lastName);

  form.append('organization_name', data.organizationName);
  form.append('zip_code', data.pincode);
  form.append('country', data.country);

  return await co2OptBaseApi
    .post(`${user_api_common_routes}/sign-up`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10033', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const Login = async data => {
  const form = new FormData();

  form.append('email', data.email);
  form.append('password', data.password);

  return await co2OptBaseApi
    .post(`${user_api_common_routes}/sign-in`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10034', error);

      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message
            ? error.response.data.message
            : error.response.data.return_message,
        };
      }
    });
};

export const silentLogin = async data => {
  return await co2OptBaseApi
    .get(`${user_api_common_routes}/sso/get-site-access/${data.code}`)
    .then(res => res)
    .catch(function (error) {
      console.error('E10035', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const Verify = async token => {
  return await co2OptBaseApi
    .get(`${user_api_common_routes}/verify/${token}`)
    .then(res => res)
    .catch(function (error) {
      console.error('E10036', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const ResetLinkRequest = async email => {
  return await co2OptBaseApi
    .get(`${user_api_common_routes}/reset-password/${email}`)
    .then(res => res)
    .catch(function (error) {
      console.error('E10037', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const ResetPasswordRequest = async (userToken, newPassword) => {
  const form = new FormData();

  form.append('user_token', userToken);
  form.append('new_password', newPassword);

  return await co2OptBaseApi
    .post(`${user_api_common_routes}/reset-password`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10038', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const getAccessToken = async () => {
  return await co2OptBaseApi
    .get(
      `${user_api_common_routes}/get-access-token/${decrypter(
        localStorage.getItem('refresh_token')
      )}`
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10039', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const inviteUser = (data, token) => {
  return co2OptBaseApi
    .post(`${user_api_common_routes}/invite-user`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20034', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};

export const verifyInvitedUser = token => {
  return co2OptBaseApi
    .get(`${user_api_common_routes}/verify-user-invitation/${token}`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20036', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};

export const completeSignupQuery = (password, locale, token) => {
  const payload = { password, locale };

  return co2OptBaseApi
    .post(
      `${user_api_common_routes}/confirm-user-invitation/${token}`,
      payload,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E20037', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};

export const getWorkForceData = () => {
  return co2OptBaseApi
    .get(`user/workforce-data`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20038', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};

export const deleteInvitedUser = payload => {
  return co2OptBaseApi
    .delete(`${user_api_common_routes}/workforce-data`, {
      data: payload,
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20042', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};
export const editInvitedUser = payload => {
  return co2OptBaseApi
    .put(`${user_api_common_routes}/workforce-data`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E20042', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};

export const changePassword = async (
  access_token,
  old_password,
  new_password,
  email
) => {
  const form = new FormData();

  form.append('old_password', old_password);
  form.append('new_password', new_password);
  form.append('email', email);

  return await co2OptBaseApi
    .post(`${user_api_common_routes}/change-password`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10040', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const deleteUser = async access_token => {
  return await co2OptBaseApi
    .delete(`${user_api_common_routes}/delete-user`, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10041', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};
export const exportUserData = async (access_token, email) => {
  const form = new FormData();

  form.append('email', email);

  return await co2OptBaseApi
    .post(`${user_api_common_routes}/export-user-data`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10042', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const microsoftLogin = async () => {
  return await co2OptBaseApi
    .get(`${user_api_common_routes}/microsoft/sign-in`, {
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10043', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const linkedInLogin = async () => {
  return await co2OptBaseApi
    .get(`${user_api_common_routes}/linkedin/sign-in`, {
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10044', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const getSSOUser = async () => {
  return await axios
    .get(`${sso_url}/get-user`, {
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10045', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const updateOnboardingStatus = async (access_token, status, email) => {
  const form = new FormData();

  form.append('isOnboarding', status);
  form.append('email', email);
  return await co2OptBaseApi
    .post(`${user_api_common_routes}/update-onboarding`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10046', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const checkUserExist = async email => {
  return await co2OptBaseApi
    .get(`${user_api_common_routes}/site-check/${email}`)
    .then(res => res)
    .catch(function (error) {
      return error.response;
    });
};

export const sendGeneralMail = async (email, subject, message) => {
  return await co2OptBaseApi
    .post(
      `${messenger_url}/send-mail`,
      {
        email: email,
        subject: subject,
        message: message,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const updateDashboard = payload => {
  return co2OptBaseApi
    .put(`user/homepage-preference`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E20138', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};

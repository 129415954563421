import { calcAvgValues } from 'pages/TireInventory/TireInventory';
import { TIRE_INVENTORY } from 'utils/string_utils';

//State
const INITIAL_STATE = {
  overviewData: null,
  fleets: undefined,
  activeFleet: undefined,
  activeVehicle: undefined,
  trackedVehiclesIds: null,
  allTrackedVehiclesInfo: null,
  fleetUpdated: false,
  vehicleUpdated: false,
  savingEstimate: null,
  ourRecommendedTireConfig: null,
  tireInventoryData: null,
  vehiclesForFleets: null,
  tireEventHistory: null,
  rawTireInventoryData: null,
  allUsageReports: null,
  tires: null,
  vehicles: null,
  tireOverview: null,
  vehicleOverview: null,
  vehicleValue: 0,
  tireValue: 0,
  parameters: null,
  searchResults: null,
  vehicle_status: null,
  isLoadingOverviewData: true,
  isLoadingSearchResults: false,
  rec_data: null,
};

//Actions
export const FleetActionTypes = {
  SET_OVERVIEW_DATA: 'SET_OVERVIEW_DATA',
  SET_FLEETS: 'SET_FLEETS',
  SET_ACTIVE_FLEET: 'SET_ACTIVE_FLEET',
  SET_ACTIVE_VEHICLE: 'SET_ACTIVE_VEHICLE',
  SET_TRACKED_VEHICLES_IDS: 'SET_TRACKED_VEHICLES_IDS',
  SET_TRACKED_VEHICLES_INFO: 'SET_TRACKED_VEHICLES_INFO',
  SET_FLEET_UPDATED: 'SET_FLEET_UPDATED',
  SET_VEHICLE_UPDATED: 'SET_VEHICLE_UPDATED',
  SET_SAVING_ESTIMATE: 'SET_SAVING_ESTIMATE',
  SET_OUR_RECOMM_TIRE_CONFIG: 'SET_OUR_RECOMM_TIRE_CONFIG',
  SET_TIRE_INVENTORY_DATA: 'SET_TIRE_INVENTORY_DATA',
  UPDATE_TIRE_INVENTORY_DATA: 'UPDATE_TIRE_INVENTORY_DATA',
  DELETE_TIRE_INVENTORY_DATA: 'DELETE_TIRE_INVENTORY_DATA',
  FETCH_FLEETS_DATA: 'FETCH_FLEETS_DATA',
  UPDATE_FLEET_AFTER_DELETION: 'UPDATE_FLEET_AFTER_DELETION',
  SET_VEHILCES_FOR_FLEETS: 'SET_VEHILCES_FOR_FLEETS',
  SET_ALL_FLEET_VEHICLES: 'SET_ALL_FLEET_VEHICLES',
  FETCH_VEHILCES_FOR_FLEETS: 'FETCH_VEHILCES_FOR_FLEETS',
  SET_FLEET_WIDE_TIRE_HISTORY_DATA: 'SET_FLEET_WIDE_TIRE_HISTORY_DATA',
  SET_UNPROCESSED_TIRE_INVENTORY_DATA: 'SET_UNPROCESSED_TIRE_INVENTORY_DATA',
  SET_ALL_USAGE_REPORTS: 'SET_ALL_USAGE_REPORTS',
  SET_OVERVIEW_DETAILS: 'SET_OVERVIEW_DETAILS',
  SET_TYRE_RECOMMENDATIONS: 'SET_TYRE_RECOMMENDATIONS',
  FETCH_OVERVIEW_DETAILS: 'FETCH_OVERVIEW_DETAILS',
  SET_TIRE_OVERVIEW: 'SET_TIRE_OVERVIEW',
  SET_VEHICLE_OVERVIEW: 'SET_VEHICLE_OVERVIEW',
  SET_TIRES: 'SET_TIRES',
  SET_VEHICLES: 'SET_VEHICLES',
  SET_PARAMETERS: 'SET_PARAMETERS',
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_VEHICLE_STATUS: 'SET_VEHICLE_STATUS',
  SET_LOAD_STATUS: 'SET_LOAD_STATUS',
  SET_SEARCH_STATUS: 'SET_SEARCH_STATUS',
  SET_REC_DATA: 'SET_REC_DATA',
};
//Action Creators

export const fetchFleets = () => ({
  type: FleetActionTypes.FETCH_FLEETS_DATA,
});
export const fetchVehiclesForFleets = (
  access_token,
  id,
  page_no,
  per_page,
  setLoading,
  search_query
) => {
  return {
    type: FleetActionTypes.FETCH_VEHILCES_FOR_FLEETS,
    payload: {
      id,
      page_no,
      per_page,
      setLoading,
      access_token,
      search_query,
    },
  };
};
export const setActiveFleet = value => ({
  type: FleetActionTypes.SET_ACTIVE_FLEET,
  payload: value,
});
export const setActiveVehicle = value => ({
  type: FleetActionTypes.SET_ACTIVE_VEHICLE,
  payload: value,
});
export const setFleetOverview = value => ({
  type: FleetActionTypes.SET_OVERVIEW_DATA,
  payload: value,
});
export const setTrackVehiclesIds = value => ({
  type: FleetActionTypes.SET_TRACKED_VEHICLES_IDS,
  payload: value,
});
export const setTrackedVehiclesInfo = value => ({
  type: FleetActionTypes.SET_TRACKED_VEHICLES_INFO,
  payload: value,
});
export const setChangeInFleet = value => ({
  type: FleetActionTypes.SET_FLEET_UPDATED,
  payload: value,
});

export const updateFleetAfterDeletion = value => ({
  type: FleetActionTypes.UPDATE_FLEET_AFTER_DELETION,
  payload: value,
});

export const setChangeInVehicle = value => ({
  type: FleetActionTypes.SET_VEHICLE_UPDATED,
  payload: value,
});
export const setSavingEstimate = value => ({
  type: FleetActionTypes.SET_SAVING_ESTIMATE,
  payload: value,
});

export const setOverviewDetails = value => ({
  type: FleetActionTypes.SET_OVERVIEW_DETAILS,
  payload: value,
});

export const setOurRecommendationTireConfig = (value, vehicleId) => ({
  type: FleetActionTypes.SET_OUR_RECOMM_TIRE_CONFIG,
  payload: { value, vehicleId },
});
export const setTireInventoryData = (value, fleetId) => ({
  type: FleetActionTypes.SET_TIRE_INVENTORY_DATA,
  payload: { value, fleetId },
});

export const setUnprocessedTireInventoryData = (value, fleetId) => ({
  type: FleetActionTypes.SET_UNPROCESSED_TIRE_INVENTORY_DATA,
  payload: { value, fleetId },
});

export const setVehiclesForFleet = (id, data) => ({
  type: FleetActionTypes.SET_VEHILCES_FOR_FLEETS,
  payload: { id, data },
});

export const setAllFleetVehicles = data => ({
  type: FleetActionTypes.SET_ALL_FLEET_VEHICLES,
  payload: data,
});

export const setTyreRecommendations = data => ({
  type: FleetActionTypes.SET_TYRE_RECOMMENDATIONS,
  payload: data,
});

export const updateTireInventoryData = (
  value,
  tireId,
  fleetId,
  updateType
) => ({
  type: FleetActionTypes.UPDATE_TIRE_INVENTORY_DATA,
  payload: { value, tireId, fleetId, updateType },
});
export const deleteTireInventoryData = (tireId, fleetId) => ({
  type: FleetActionTypes.DELETE_TIRE_INVENTORY_DATA,
  payload: { tireId, fleetId },
});
export const setFleetWideTireHistoryData = (value, fleetId) => ({
  type: FleetActionTypes.SET_FLEET_WIDE_TIRE_HISTORY_DATA,
  payload: { value, fleetId },
});

export const setUsageReports = value => ({
  type: FleetActionTypes.SET_ALL_USAGE_REPORTS,
  payload: value,
});

export const fetchOverviewDetails = params => ({
  type: FleetActionTypes.FETCH_OVERVIEW_DETAILS,
  payload: params,
});

export const setTireOverview = tireOverview => ({
  type: FleetActionTypes.SET_TIRE_OVERVIEW,
  payload: tireOverview,
});

export const setVehicleOverview = vehicleOverview => ({
  type: FleetActionTypes.SET_VEHICLE_OVERVIEW,
  payload: vehicleOverview,
});

export const setTires = tires => ({
  type: FleetActionTypes.SET_TIRES,
  payload: tires,
});

export const setVehicles = vehicles => ({
  type: FleetActionTypes.SET_VEHICLES,
  payload: vehicles,
});

export const setParameters = value => ({
  type: FleetActionTypes.SET_PARAMETERS,
  payload: value,
});

export const setSearchResults = value => ({
  type: FleetActionTypes.SET_SEARCH_RESULTS,
  payload: value,
});
export const setVehicleStatus = value => ({
  type: FleetActionTypes.SET_VEHICLE_STATUS,
  payload: value,
});
export const setLoadStatus = value => ({
  type: FleetActionTypes.SET_LOAD_STATUS,
  payload: value,
});
export const setSearchStatus = value => ({
  type: FleetActionTypes.SET_SEARCH_STATUS,
  payload: value,
});
export const setRecData = value => ({
  type: FleetActionTypes.SET_REC_DATA,
  payload: value,
});
//Reducer
export const fleetReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FleetActionTypes.SET_OVERVIEW_DATA:
      return { ...state, overviewData: action.payload };
    case FleetActionTypes.SET_FLEETS:
      return {
        ...state,
        overviewData: { ...state.overviewData, fleets: action.payload },
      };
    case FleetActionTypes.UPDATE_FLEET_AFTER_DELETION:
      return {
        ...state,
        overviewData: {
          ...state.overviewData,
          fleets: state.overviewData?.fleets.filter(
            fleet => !action.payload.includes(fleet.fleet_id)
          ),
        },
      };
    case FleetActionTypes.SET_VEHILCES_FOR_FLEETS:
      return {
        ...state,
        vehiclesForFleets: {
          ...state.vehiclesForFleets,
          [action.payload.id]: action.payload.data,
        },
      };
    case FleetActionTypes.SET_ALL_FLEET_VEHICLES:
      return {
        ...state,
        allFleetVehicles: action.payload,
      };
    case FleetActionTypes.SET_ACTIVE_FLEET:
      return { ...state, activeFleet: action.payload };
    case FleetActionTypes.SET_ACTIVE_VEHICLE:
      return { ...state, activeVehicle: action.payload };
    case FleetActionTypes.SET_TRACKED_VEHICLES_IDS:
      return { ...state, trackedVehiclesIds: action.payload };
    case FleetActionTypes.SET_TRACKED_VEHICLES_INFO:
      return { ...state, allTrackedVehiclesInfo: action.payload };
    case FleetActionTypes.SET_FLEET_UPDATED:
      return { ...state, fleetUpdated: action.payload };
    case FleetActionTypes.SET_VEHICLE_UPDATED:
      return { ...state, vehicleUpdated: action.payload };
    case FleetActionTypes.SET_SAVING_ESTIMATE:
      return { ...state, savingEstimate: action.payload };
    case FleetActionTypes.SET_OUR_RECOMM_TIRE_CONFIG:
      return {
        ...state,
        ourRecommendedTireConfig: {
          ...state.ourRecommendedTireConfig,
          [action.payload.vehicleId]: action.payload.value,
        },
      };
    case FleetActionTypes.SET_TIRE_INVENTORY_DATA:
      return {
        ...state,
        tireInventoryData: {
          ...state.tireInventoryData,

          [action.payload.fleetId]: action.payload.value,
        },
      };
    case FleetActionTypes.SET_UNPROCESSED_TIRE_INVENTORY_DATA:
      return {
        ...state,
        rawTireInventoryData: {
          ...state.rawTireInventoryData,

          [action.payload.fleetId]: action.payload.value,
        },
      };
    case FleetActionTypes.SET_FLEET_WIDE_TIRE_HISTORY_DATA:
      return {
        ...state,
        tireEventHistory: {
          ...state.tireEventHistory,

          [action.payload.fleetId]: action.payload.value,
        },
      };
    case FleetActionTypes.UPDATE_TIRE_INVENTORY_DATA:
      let originalData =
        state.tireInventoryData[action.payload.fleetId]?.originalData;
      let processedData =
        state.tireInventoryData[action.payload.fleetId]?.processedData;

      if (action.payload.updateType === TIRE_INVENTORY.UPDATE.UPDATE) {
        originalData = originalData.map(data => {
          if (data.system_tire_id === action.payload.value.system_tire_id) {
            return { ...action.payload.value };
          } else return data;
        });
      } else if (action.payload.updateType === TIRE_INVENTORY.UPDATE.ADD) {
        originalData = [{ ...action.payload.value }, ...originalData];
      } else if (action.payload.updateType === TIRE_INVENTORY.UPDATE.DELETE) {
        originalData = originalData.filter(
          data => data.tire_id !== action.payload.tireId
        );
      } else if (
        action.payload.updateType === TIRE_INVENTORY.UPDATE.MOVED_TO_STORAGE
      ) {
        originalData = originalData.map(data => {
          if (data.system_tire_id === action.payload.value.system_tire_id) {
            return { ...action.payload.value, status: 'inStore' };
          } else return data;
        });
      }

      const averageValues = calcAvgValues(originalData);
      processedData = { ...processedData, avgValues: averageValues };

      return {
        ...state,
        tireInventoryData: {
          ...state.tireInventoryData,
          [action.payload.fleetId]: { originalData, processedData },
        },
      };

    case FleetActionTypes.DELETE_TIRE_INVENTORY_DATA:
      originalData =
        state.tireInventoryData[action.payload.fleetId]?.originalData;
      processedData =
        state.tireInventoryData[action.payload.fleetId]?.processedData;

      originalData = originalData.filter(
        data => data.tire_id !== action.payload.tireId
      );
      const _averageValues = calcAvgValues(originalData);
      processedData = { ...processedData, avgValues: _averageValues };

      return {
        ...state,
        tireInventoryData: {
          ...state.tireInventoryData,
          [action.payload.fleetId]: { originalData, processedData },
        },
      };
    case FleetActionTypes.SET_ALL_USAGE_REPORTS:
      return {
        ...state,
        allUsageReports: action.payload,
      };

    case FleetActionTypes.SET_OVERVIEW_DETAILS:
      const {
        data: { resources },
      } = action.payload;
      return {
        ...state,
        overviewDetails: resources,
        vehicleOverview: resources.vehicle_overview,
        tireOverview: resources.tire_overview,
      };

    case FleetActionTypes.SET_TYRE_RECOMMENDATIONS:
      return {
        ...state,
        tireRecommendations: action.payload,
      };
    case FleetActionTypes.SET_TIRE_OVERVIEW:
      return {
        ...state,
        tireOverview: action.payload,
      };
    case FleetActionTypes.SET_VEHICLE_OVERVIEW:
      return {
        ...state,
        vehicleOverview: action.payload,
      };
    case FleetActionTypes.SET_TIRES:
      return {
        ...state,
        tires: action.payload,
      };
    case FleetActionTypes.SET_VEHICLES:
      return {
        ...state,
        vehicles: action.payload,
      };

    case FleetActionTypes.SET_PARAMETERS:
      return {
        ...state,
        parameters: action.payload,
      };
    case FleetActionTypes.SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };
    case FleetActionTypes.SET_VEHICLE_STATUS:
      return {
        ...state,
        vehicle_status: action.payload,
      };
    case FleetActionTypes.SET_LOAD_STATUS:
      return {
        ...state,
        isLoadingOverviewData: action.payload,
      };
    case FleetActionTypes.SET_SEARCH_STATUS:
      return {
        ...state,
        isLoadingSearchResults: action.payload,
      };
    case FleetActionTypes.SET_REC_DATA:
      return {
        ...state,
        rec_data: action.payload,
      };
    default:
      return state;
  }
};

export const fleetOverviewSelector = state => state.fleet.overviewData;
export const activeFleetSelector = state => state.fleet.activeFleet;
export const activeVehicleSelector = state => state.fleet.activeVehicle;
export const setTrackedVehiclesIdsSelector = state =>
  state.fleet.trackedVehiclesIds;
export const allTrackedVehiclesSelector = state =>
  state.fleet.allTrackedVehiclesInfo;
export const fleetUpdatedSelector = state => state.fleet.fleetUpdated;
export const vehicleUpdatedSelector = state => state.fleet.vehicleUpdated;
export const savingEstimateSelector = state => state.fleet.savingEstimate;
export const ourRecommendedTireConfigSelector = state =>
  state.fleet.ourRecommendedTireConfig;

export const tireInventoryDataSelector = state => state.fleet.tireInventoryData;
export const unprocessedTireInventoryDataSelector = state =>
  state.fleet.rawTireInventoryData;
export const vehiclesForFleetSelector = state => state.fleet.vehiclesForFleets;
export const allVehiclesSelector = state => state.fleet.allFleetVehicles;
export const fleetWideTireEventHistorySelector = state =>
  state.fleet.tireEventHistory;
export const allUsageReportsSelector = state => state.fleet.allUsageReports;
export const setVehicleStatusSelector = state => state.fleet.vehicle_status;
export const setRecDataSelector = state => state.fleet.rec_data;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Typography, Grid, Box, Paper, Button, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Loading from 'components/Loading';

import MotionWrap from 'wrapper/MotionWrap';

import truck_vector from 'assets/images/truck_vector.svg';
import trailer_vector from 'assets/images/trailer_vector.svg';

import CsvUploader from '../CsvUploader';
import { Add } from '@mui/icons-material';
import { fleetOverviewSelector } from 'redux/fleets/ducks';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import {
  setAvailableTasks,
  setTaskSelector,
  setUserSelector,
  updateGuideSelection,
} from 'redux/users/ducks';
import {
  TRAILER_HEADERS,
  TRUCK_AND_BUSES_HEADERS,
  USER_LS,
  USER_ROLES,
  VEHICLE,
} from 'utils/string_utils';
import bus_icon from 'assets/images/bus_icon.png';
import { useDispatch } from 'react-redux';
import VehicleUploads from '../VehicleUploads';
import Tour from 'reactour';
import TourContent from 'components/Tour/Content';

const useStyles = makeStyles()(theme => {
  return {
    box: {
      margin: '2rem 0px',
    },
    noFleets: {
      boxShadow: theme.palette.boxShadow,
      borderRadius: '8px',
      padding: '1rem 1.5rem',
    },
    uploadSection: {
      maxHeight: '65vh',
      padding: '1rem 0rem',
      overflowY: 'scroll',
    },
    headings: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #ccc',
      padding: '1rem 2rem',
      minHeight: '170px',
    },
    paper: {
      boxShadow: theme.palette.boxShadow,
      borderRadius: '8px',
    },
    loading: {
      width: '70vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    btns: {
      // flex: '30%',
      borderRadius: 24,
      textTransform: 'unset',
      width: 146,
      height: 40,
      fontSize: '16px',
      lineHeight: '16px',
    },
  };
});

const operate_map = {
  truck: VEHICLE.vehicle_type.TRUCKS,
  bus: VEHICLE.vehicle_type.BUSES,
  trailer: VEHICLE.vehicle_type.TRAILERS,
};
const FleetsData = () => {
  const { classes } = useStyles();
  const [loading, setLoading] = useState(true);
  const userLs = JSON.parse(USER_LS);
  const [entries, setEntries] = useState(null);
  // eslint-disable-next-line
  const { t } = useTranslation();

  const overviewData = useSelector(fleetOverviewSelector);
  const userDetails = useSelector(setUserSelector) || userLs;
  const [vehicleType, setVehicleType] = useState('');
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const userIsAReporter = userDetails?.iam_role === USER_ROLES.reporter;
  const dispatch = useDispatch();
  const availableTasks = useSelector(setTaskSelector);
  const theme = useTheme();
  const guideSelectionSelector = useSelector(
    state => state.users.guideSelection
  );
  const [showTour, setShowTour] = useState(
    guideSelectionSelector === 'upload_vehicles' ? true : false
  );
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    if (overviewData !== null) {
      let temp = overviewData.general_configs;
      let operate_arr = [];
      for (let i = 0; i < Object.values(temp).length; i++) {
        if (Object.values(temp)[i] !== null) {
          operate_arr.push(operate_map[Object.keys(temp)[i]]);
        }
      }

      const truckIndex = operate_arr.findIndex(
        item => item === VEHICLE.vehicle_type.TRUCKS
      );

      if (truckIndex !== -1) {
        operate_arr.splice(truckIndex, 1);

        operate_arr.unshift(VEHICLE.vehicle_type.TRUCKS);
      }
      setEntries(operate_arr);

      setLoading(false);
    }
  }, [overviewData]);
  useEffect(() => {
    const timeout = setTimeout(() => setFlag(true), 1000);

    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    return () => {
      dispatch(updateGuideSelection(null));
    };
  }, [dispatch]);
  if (overviewData?.['fleets'].length) {
    !availableTasks && dispatch(setAvailableTasks([]));
    return (
      <Navigate to={`/fleets-data/${overviewData['fleets'][0].fleet_id}`} />
    );
  }

  const styling = {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  };

  const steps = [
    {
      selector: '#vehicle-upload-btn-trucks',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t(`guidesPage.tour.addVehicle`)}
          finalAction='Done'
          goTo={() => setShowTour(false)}
          hideSkip={true}
        />
      ),
      style: styling,
    },
  ];

  const renderTourFromGuide = () => {
    if (flag) {
      return (
        <Tour
          startAt={0}
          steps={steps}
          isOpen={showTour}
          accentColor={theme.palette.primary.main}
          onRequestClose={() => setShowTour(false)}
          showNavigation={false}
          showButtons={false}
          showNNumber={false}
          badgeContent={() => ``}
        />
      );
    }
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <Loading />
      </div>
    );
  }
  const determineSize = () => {
    const operatedVehicles = overviewData.general_configs;
    let count = 0;
    Object.entries(operatedVehicles).forEach(item => {
      if (item[1] === null) {
        count++;
      }
    });
    if (count === 2) return 12;
    if (count === 0) return 4;
    if (count === 1) return 6;
  };

  const renderVehicleType = entry => {
    switch (entry) {
      case VEHICLE.vehicle_type.TRUCKS:
        return (
          <>
            <img src={truck_vector} alt='truck' /> {t('fleetsDataPage.trucks')}
          </>
        );
      case VEHICLE.vehicle_type.TRAILERS:
        return (
          <>
            <img src={trailer_vector} alt='trailer' />{' '}
            {t('fleetsDataPage.trailer')}
          </>
        );
      case VEHICLE.vehicle_type.BUSES:
        return (
          <>
            <img src={bus_icon} alt='trailer' /> {t('fleetsDataPage.buses')}
          </>
        );
      default:
        return <></>;
    }
  };

  const renderVehicleUploadModal = () => {
    if (openUploadModal) {
      return (
        <VehicleUploads
          vehicleType={vehicleType}
          open={openUploadModal}
          setOpen={setOpenUploadModal}
          title={singleUploaderConfig[vehicleType]['title']}
          headers={singleUploaderConfig[vehicleType]['headers']}
          asset_type={singleUploaderConfig[vehicleType]['asset_type']}
          header1={singleUploaderConfig[vehicleType]['header1']}
          header2={singleUploaderConfig[vehicleType]['header2']}
          flex_direction='column'
        />
      );
    }
  };

  const renderUser = () => {
    if (!userIsAReporter) {
      return entries.map((entry, i) => (
        <Grid item lg={determineSize()} key={i}>
          <Box className={classes.box}>
            <Paper elevation={0} className={classes.paper}>
              <Box className={classes.headings}>
                <Box width='100%' display='flex'>
                  <Box flexGrow={1}>
                    <Typography variant='h4' gutterBottom>
                      {renderVehicleType(entry)}
                    </Typography>

                    <Typography
                      variant='h5'
                      gutterBottom
                      style={{ marginLeft: '45px' }}
                    >
                      {t('fleetsDataPage.upload')}{' '}
                      {entry === VEHICLE.vehicle_type.TRUCKS ? (
                        <>{t('fleetsDataPage.trucks')}</>
                      ) : entry === VEHICLE.vehicle_type.BUSES ? (
                        <>{t('fleetsDataPage.buses')}</>
                      ) : (
                        <>{t('fleetsDataPage.trailer')}</>
                      )}
                    </Typography>
                  </Box>
                  <Button
                    className={classes.btns}
                    startIcon={<Add />}
                    variant='contained'
                    color='primary'
                    id={`vehicle-upload-btn-${entry}`}
                    data-hook='vehicle-upload-btn'
                    onClick={() => {
                      setVehicleType(
                        entry === VEHICLE.vehicle_type.BUSES
                          ? entry.slice(0, -2)
                          : entry.slice(0, -1)
                      );
                      setOpenUploadModal(true);
                    }}
                  >
                    {t('singleVehicleUpload.add')}
                  </Button>
                </Box>
              </Box>

              <Box className={classes.uploadSection}>
                <CsvUploader
                  title={csvUploaderConfig[entry]['title']}
                  headers={csvUploaderConfig[entry]['headers']}
                  asset_type={csvUploaderConfig[entry]['asset_type']}
                  header1={csvUploaderConfig[entry]['header1']}
                  header2={csvUploaderConfig[entry]['header2']}
                />
              </Box>
            </Paper>
          </Box>
          {renderVehicleUploadModal()}
        </Grid>
      ));
    } else {
      return (
        <Grid item xs={12}>
          <Box className={classes.box}>
            <Paper elevation={0} className={classes.noFleets}>
              <Typography variant='h5'>
                {t('fleetsDataPage.noFleets')}
              </Typography>
            </Paper>
          </Box>
        </Grid>
      );
    }
  };

  return (
    <Grid container spacing={2}>
      {renderUser()}
      {renderTourFromGuide()}
    </Grid>
  );
};

export default MotionWrap(
  FleetsData,
  { opacity: 0 },
  { opacity: 1 },
  { opacity: 0 }
);

const csvUploaderConfig = {
  trailers: {
    title: 'Trailer .csv Upload',
    headers: TRAILER_HEADERS,
    asset_type: VEHICLE.vehicle_type.TRAILERS,
    header1: 'fleet_name',
    header2: 'fleet_location',
  },
  trucks: {
    title: 'Truck .csv Upload',
    headers: TRUCK_AND_BUSES_HEADERS,
    asset_type: VEHICLE.vehicle_type.TRUCKS,
    header1: 'fleet_name',
    header2: 'fleet_location',
  },
  buses: {
    title: 'Bus .csv Upload',
    headers: TRUCK_AND_BUSES_HEADERS,
    asset_type: VEHICLE.vehicle_type.BUSES,
    header1: 'fleet_name',
    header2: 'fleet_location',
  },
};
const singleUploaderConfig = {
  trailer: {
    title: 'Trailer .csv Upload',
    headers: TRAILER_HEADERS,
    asset_type: VEHICLE.vehicle_type.TRAILERS,
    header1: 'fleet_name',
    header2: 'fleet_location',
  },
  truck: {
    title: 'Truck .csv Upload',
    headers: TRUCK_AND_BUSES_HEADERS,
    asset_type: VEHICLE.vehicle_type.TRUCKS,
    header1: 'fleet_name',
    header2: 'fleet_location',
  },
  bus: {
    title: 'Bus .csv Upload',
    headers: TRUCK_AND_BUSES_HEADERS,
    asset_type: VEHICLE.vehicle_type.BUSES,
    header1: 'fleet_name',
    header2: 'fleet_location',
  },
};

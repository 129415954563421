import { Grid, Typography } from '@mui/material';
import React from 'react';
import { GiSatelliteCommunication } from 'react-icons/gi';
import { ReactComponent as Tire } from 'assets/svg/home/tire.svg';
import { FaUpload, FaUserPlus } from 'react-icons/fa';
import { MdNotificationsActive } from 'react-icons/md';
import { useSelector } from 'react-redux';
import {
  allTrackedVehiclesSelector,
  fleetOverviewSelector,
} from 'redux/fleets/ducks';
import { workforceSelector } from 'redux/users/ducks';
import TaskCard from './TaskCard';
import { useTranslation } from 'react-i18next';

const UserGuide = () => {
  let managedVehicles = useSelector(allTrackedVehiclesSelector);
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const tireInventoryDetails = useSelector(state => state.fleet.overviewData);
  const overviewDetails = useSelector(fleetOverviewSelector)?.general_configs;
  const workforce = useSelector(workforceSelector)?.workforceData || [];

  const { t } = useTranslation();
  const hasTotalGreaterThanZero = Object.values(
    tireInventoryDetails?.metrics || {}
  ).some(metric => metric.total_tires > 0);

  const isAxlePressureSet = Object.values(overviewDetails || {}).some(
    item => item?.optimum_air_pressure
  );
  const tasks = [
    {
      link: '/telematics-data/provider-integration',
      icon: <GiSatelliteCommunication size={30} />,
      id: 'telematics',
      ...(managedVehicles && { isComplete: true }),
    },
    {
      link: '/fleets-data',
      icon: <FaUpload fill='grey' size={30} />,
      id: 'upload_vehicles',
      ...(fleets?.length && { isComplete: true }),
    },
    {
      link: '/tire-inventory',
      icon: <Tire fill={'grey'} />,
      id: 'addTires',
      ...(hasTotalGreaterThanZero && { isComplete: true }),
      ...(fleets?.length === 0 && { hide: true }),
    },

    {
      link: '/settings',
      icon: <FaUserPlus fill='grey' size={30} />,
      id: 'user-mgt',
      ...(workforce.filter(user => user.name !== 'CO2OPT BOT').length > 1 && {
        isComplete: true,
      }),
    },
    {
      link: '/settings',
      icon: <MdNotificationsActive fill='grey' size={30} />,
      id: 'notifications',
    },
    {
      link: '/settings',
      icon: <Tire fill='grey' size={30} />,
      id: 'fleet-settings',
      ...(isAxlePressureSet && { isComplete: true }),
    },
  ];

  return (
    <div>
      <Typography variant='h5'>{t(`guidesPage.pageTitle`)}</Typography>
      <Grid container spacing={3} marginTop={4}>
        {tasks.map((task, i) => {
          return <TaskCard key={i} {...task} />;
        })}
      </Grid>
    </div>
  );
};

export default UserGuide;

import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      height: 12,
      marginBottom: theme.spacing(1),
    },
    border: {
      borderBottom: '2px solid lightgray',
      width: '100%',
    },
    content: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontWeight: 500,
      fontSize: 12,
    },
  };
});

const DividerWithText = ({ label }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.border} />
      {label && (
        <>
          <span className={classes.content}>{label}</span>
          <div className={classes.border} />
        </>
      )}
    </div>
  );
};
export default DividerWithText;

import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { USER_LS, USER_ROLES } from 'utils/string_utils';
import { ReactComponent as Mounted } from 'assets/svg/common/mountedTire.svg';
import { ReactComponent as Unmounted } from 'assets/svg/common/unmounted.svg';
import { setUserSelector } from 'redux/users/ducks';
import { useStyles, tireColor, RetreadInfo, RegrooveInfo } from './TireConfig';

export const TireComponent = props => {
  const {
    row,
    selectedTire,
    tireClickHandler,
    side,
    axle,
    index,
    tire,
    setSelectedTire,
    setOptIndex,
  } = props;
  const { classes: styles } = useStyles();
  const userLs = JSON.parse(USER_LS);
  const userDetails = useSelector(setUserSelector) || userLs;
  const userIsAReporter = userDetails?.iam_role === USER_ROLES.reporter;

  useEffect(() => {
    if (!!tire && row?.tire_id === selectedTire?.tire_id) {
      setSelectedTire({
        type: axle,
        side: side,
      });
      setOptIndex(index);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box
      paddingX={'10px'}
      position='relative'
      marginBottom='25px'
      marginRight='10px'
      height='90px'
      display='flex'
      justifyContent='center'
      alignItems='center'
      onClick={() => tireClickHandler(axle, side, index)}
    >
      {!!row ? (
        <>
          <Mounted
            className={`${styles.tire} ${
              !row?.estimated_tread_depth &&
              row?.tire_id === selectedTire?.tire_id
                ? styles.highlightedTire
                : ''
            }
          }`}
          />
          {!!row?.estimated_tread_depth && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              position='absolute'
              width='36px'
              height='90px'
              borderRadius='24px'
              bgcolor={tireColor(row?.estimated_tread_depth)}
              top={0}
              style={{ opacity: 0.5 }}
              className={`${styles.tire} ${
                row?.tire_id === selectedTire?.tire_id
                  ? styles.highlightedTire
                  : ''
              }`}
            />
          )}
          {row?.isRetread && <RetreadInfo>R</RetreadInfo>}
          {row?.isRegrooved && <RegrooveInfo>N</RegrooveInfo>}
        </>
      ) : (
        <>
          <Unmounted />
          {!userIsAReporter && (
            <Box
              position='absolute'
              display='flex'
              justifyContent='center'
              alignItems='center'
              width='32px'
              height='32px'
              borderRadius='50%'
              top={0}
              className={styles.unmounted}
            >
              <Add />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

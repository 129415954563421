import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { setUserSelector } from 'redux/users/ducks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    spacing: {
      marginTop: '1rem',
    },
  };
});

const Loading = ({ message }) => {
  const user = useSelector(setUserSelector);
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <>
      {user !== null ? (
        <Box
          width='100%'
          height='100%'
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          textAlign='center'
        >
          {user.is_onboarded ? (
            <>
              <Typography className={classes.spacing} variant={'body1'}>
                Integrate Your Telematics Data
              </Typography>
            </>
          ) : (
            <>
              <CircularProgress size={50} />
              <Typography
                className={classes.spacing}
                variant={'body1'}
                data-hook='loading-message'
              >
                {message || t('common.loadMessage')}
              </Typography>
            </>
          )}
        </Box>
      ) : (
        <>
          <CircularProgress size={50} />
          <Typography
            className={classes.spacing}
            variant={'body1'}
            data-hook='loading-message'
          >
            Getting your things ready . . .
          </Typography>
        </>
      )}
    </>
  );
};

export default Loading;

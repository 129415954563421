import _ from 'lodash';
import axios from 'axios';
import { TIRES } from 'utils/string_utils';

import { co2OptBaseApi } from '../axios.config';
import { checkTire } from './fleet_service';

export const csv_url = process.env.REACT_APP_CSV_URL; // D
export const telematics_url = `${process.env.REACT_APP_CO2OPT_BASE_URL}/telematics`;

export const truck_data_url = process.env.REACT_APP_TRUCK_DATA;
export const truck_url = process.env.REACT_APP_TRUCK_URL;
export const fleet_status_url = process.env.REACT_APP_FLEET_URL;
export const chart_url = process.env.REACT_APP_CHART_URL;
const telematics_api_common_route = 'telematics';
const customer_common_route = '/customer';

axios.defaults.timeout = 1000 * 60 * 10;

// GET API REF
// /organization/fleet-overview/{user_id}/{customer_id}/{fuel_cost}/{average_fuel_consumption}

export const csvUpload = async (payload, access_token) => {
  return await axios
    .post(
      `http://3.68.232.250:2000/data-integration/upload-csv`,

      payload,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        maxContentLength: 100000000,
        maxBodyLength: 1000000000,
      }
    )

    .then(res => res)
    .catch(function (error) {
      console.error('E10048', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

export const csvFormData = async access_token => {
  return await axios
    .get(`${csv_url}/user/data/manual/get-qna-options`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(res => res.data.ret_obj)
    .catch(function (error) {
      console.error('E10049', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const csvUploadHistory = async (user, access_token) => {
  return await axios
    .get(
      `http://3.68.232.250:2000/data-integration/upload-history/${user.customer_id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
    .then(res => res.data)
    .catch(function (error) {
      console.error('E10050', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const getTelematicsFormData = async (access_token, provider) => {
  return await co2OptBaseApi
    .get(`${telematics_api_common_route}/credentials-needed`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        protocol: provider,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10051', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const getManagedTelematicsVehicles = async (
  // access_token,
  credentialsToken
) => {
  return await co2OptBaseApi
    .get(`${telematics_api_common_route}/managed-vehicles`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        credentials_token: credentialsToken,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20006', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};
export const telematicsProviderIntegration = async (
  access_token,
  provider,
  formVal
) => {
  return await co2OptBaseApi
    .post(`${telematics_api_common_route}/register`, formVal, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        protocol: provider,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10052', error);
      if (error.response) {
        return { response: error.response.data };
      }

      return error;
    });
};

export const updateTelematicsProviderIntegration = async (
  access_token,
  user,
  telemetryData,
  id,
  provider,
  formData
) => {
  const form = new FormData();

  form.append('email', user.email);
  form.append('organization_name', user.organization_name);
  form.append('provider_name', provider);
  form.append('id', id);
  form.append('keys', formData);

  for (let i = 0; i < formData.length; i++) {
    form.append(formData[i], telemetryData[formData[i]]);
  }

  return await co2OptBaseApi
    .post(
      `${telematics_api_common_route}/update-telemetry-authorization`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10053', error);
      if (error.response) {
        return { err: error.response };
      }
    });
};

export const getTelematicsData = () => {
  return co2OptBaseApi
    .get(`/telematics/registered-telematics`)
    .then(res => res)
    .catch(function (error) {
      console.error('E10054', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const deleteTelemtricIntegration = async (
  access_token,
  id,
  provider
) => {
  const form = new FormData();
  form.append('provider_name', provider);
  form.append('id', id);
  return await co2OptBaseApi
    .post(
      `${telematics_api_common_route}/delete-telemetry-authorization`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10055', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};
export const addTruckData = async (
  access_token,
  user,
  truckData,
  mountedTyers
) => {
  const form = new FormData();
  form.append('email', user.email);
  form.append('vehicleNo', truckData.truckNo);
  form.append('trailer', truckData.trailer);
  form.append('mountedTyers', mountedTyers);
  return await axios
    .post(`${truck_url}/add-vehicle`, form, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10056', error);
      if (error.response) {
        return { status: error.response.status };
      }
    });
};

export const getVehicleQuestions = async access_token => {
  return await axios
    .get(`${truck_url}/get-vehicle-questions`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(res => res.data.questions)
    .catch(function (error) {
      console.error('E10057', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

export const getFleetStatusData = async access_token => {
  return await axios
    .get(`${fleet_status_url}/get-fleet-status-data`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10058', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

export const getChartsData = async access_token => {
  return await axios(`${chart_url}/get-chart-data`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  })
    .then(res => res)
    .catch(function (error) {
      console.error('E10059', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

export const getTruckData = async (access_token, vehicleId) => {
  return await axios
    .post(
      `${truck_data_url}/get-truck-data`,
      { vehicleId: vehicleId },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10060', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};
export const setDatabase = async (access_token, databaseName) => {
  return await axios
    .post(
      `${truck_data_url}/select-database`,
      { databaseName: databaseName },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10061', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

export const getAllTruckData = async access_token => {
  return await axios
    .post(`${truck_data_url}/get-all-truck-data`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10062', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

export const fleetConfig = async data => {
  return await co2OptBaseApi
    .post(`${customer_common_route}/fleet-config`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10063', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

export const getVehicleChangelogData = vehicle_id => {
  return co2OptBaseApi
    .get(`${customer_common_route}/change-log-vehicle`, {
      params: {
        vehicle_id: vehicle_id,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20030', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

export const getFleetChangelogData = (access_token, fleet_id) => {
  return co2OptBaseApi
    .get(`${customer_common_route}/change-log-fleet`, {
      params: {
        fleet_id: fleet_id,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20032', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

const tireIdChangeHandler = async (
  e,
  setError,
  error,
  setPayload,
  payload,
  tireId,
  t,
  token,
  setIsAutoGenerated = null
) => {
  const pattern = /^[a-zA-Z0-9]*$/;
  const isValidInput = pattern.test(e.target.value);
  if (e.target.name === TIRES.TIRE_ID) {
    if (e.target.value.length >= 5 && e.target.value.length <= 20) {
      if (e.target.value !== tireId) {
        if (setIsAutoGenerated !== null) setIsAutoGenerated(false);
        try {
          if (!isValidInput) {
            setError({
              ...error,
              [TIRES.TIRE_ID]: t('common.tireInventory.noSpecialCharsMessage'),
            });
          } else {
            const res = await checkTire(e.target.value);
            if (res.status === 400) {
              setError({
                ...error,
                [TIRES.TIRE_ID]: res?.data?.message,
              });
            } else
              setError({
                ...error,
                [TIRES.TIRE_ID]: null,
              });
          }
        } catch (error) {
          console.error('E20054', error);
        }
      } else
        setError({
          ...error,
          [TIRES.TIRE_ID]: null,
        });
    } else if (e.target.value.length < 5 || e.target.value.length > 20) {
      setError({
        ...error,
        [TIRES.TIRE_ID]: t('common.tireInventory.tooShortError'),
      });
    } else
      setError({
        ...error,
        [TIRES.TIRE_ID]: null,
      });
  }
  setPayload({
    ...payload,
    [e.target.name]: e.target.value,
  });
};

const commonChangeHandler = async (
  e,
  setError,
  error,
  setPayload,
  payload,
  tireId,
  t,
  token,
  setIsAutoGenerated = null
) => {
  const pattern = /^[a-zA-Z0-9]*$/;
  const isValidInput = pattern.test(e.target.value);
  if (e.target.name === TIRES.TIRE_ID) {
    if (e.target.value.length >= 5 && e.target.value.length <= 20) {
      if (e.target.value !== tireId) {
        if (setIsAutoGenerated !== null) setIsAutoGenerated(false);
        try {
          if (!isValidInput) {
            setError({
              ...error,
              [TIRES.TIRE_ID]: t('common.tireInventory.noSpecialCharsMessage'),
            });
          } else {
            const res = await checkTire(e.target.value);
            if (res.status === 400) {
              setError({
                ...error,
                [TIRES.TIRE_ID]: res?.data?.message,
              });
            } else
              setError({
                ...error,
                [TIRES.TIRE_ID]: null,
              });
          }
        } catch (error) {
          console.error('E20054', error);
        }
      } else
        setError({
          ...error,
          [TIRES.TIRE_ID]: null,
        });
    } else if (e.target.value.length < 5 || e.target.value.length > 20) {
      setError({
        ...error,
        [TIRES.TIRE_ID]: t('common.tireInventory.tooShortError'),
      });
    } else
      setError({
        ...error,
        [TIRES.TIRE_ID]: null,
      });
  }
  if (e.target.name === TIRES.TREAD_DEPTH) {
    if (parseFloat(e.target.value) > TIRES.MAX_DEPTH) {
      setError({
        ...error,
        [TIRES.TREAD_DEPTH]: t('common.tireInventory.maxDepthError'),
      });
    } else
      setError({
        ...error,
        [TIRES.TREAD_DEPTH]: null,
      });
  }
  if (e.target.name === TIRES.PRESSURE) {
    const value = e.target.value.length > 0 ? e.target.value : null;
    if (parseFloat(e.target.value) > 15 || parseFloat(e.target.value) < 5) {
      setError({
        ...error,
        [TIRES.PRESSURE]: t('common.pressureValueError'),
      });
    } else {
      setError({
        ...error,
        [TIRES.PRESSURE]: null,
      });
      setPayload({ ...payload, pressure: value });
      return;
    }
  }
  if (e.target.name === TIRES.RETREAD || e.target.name === TIRES.REGROOVE) {
    setPayload({
      ...payload,
      [e.target.name]: e.target.checked,
    });
    return;
  }

  setPayload({
    ...payload,
    [e.target.name]: e.target.value,
  });
};

export const debouncedCommonChangeHandler = commonChangeHandler;
export const debouncedTireIdChangeHandler = _.debounce(
  tireIdChangeHandler,
  700
);

export function getProtocol(vehicleId, managedVehicles) {
  if (managedVehicles) {
    for (let item of managedVehicles) {
      if (item.registered_and_tracked.includes(vehicleId)) {
        return item.protocol;
      }
    }
  }
  return null;
}

export const appSearch = async search_string => {
  return await co2OptBaseApi
    .get(`${customer_common_route}/wild-search`, {
      params: { search_string },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20163', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.message,
        };
      }
    });
};

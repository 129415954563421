import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { tiresDbSelector } from 'redux/tireInventory/ducks';
import { useSelector } from 'react-redux';
import { fleetOverviewSelector } from 'redux/fleets/ducks';

const useStyles = makeStyles()(theme => {
  return {
    form: {
      margin: '0 1rem',
    },
  };
});

const TireSizeConfig = ({
  size,
  setSize,
  configData,
  truckTire = {},
  setTruckTire = () => {},
  trailerTire,
  setTrailerTire,
  initial,
}) => {
  const { classes } = useStyles();
  const tiresResource = useSelector(tiresDbSelector);
  const availableSizes = [...new Set(tiresResource?.sizes)];
  const overview = useSelector(fleetOverviewSelector);

  const handleChange = e => {
    setTruckTire({
      ...truckTire,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const tire1Percentage =
      overview.general_configs?.[configData.vehicle_type]?.tire_1_perc;
    const tire2Percentage =
      overview.general_configs?.[configData.vehicle_type]?.tire_2_perc;
    const tireType =
      overview.general_configs?.[configData.vehicle_type]?.[
        tire1Percentage > tire2Percentage ? 'tire_type_1' : 'tire_type_2'
      ];
    const initialSteerTireSize = tireType?.split(' ')[0];
    const initialDriveTireSize = tireType?.split(' ')[3];
    setTruckTire({
      ...truckTire,
      steer: initialSteerTireSize,
      drive: initialDriveTireSize,
    });
    setTrailerTire(tireType);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (truckTire?.steer !== '' && truckTire?.drive !== '') {
      let value = [
        truckTire?.steer,
        'Steer',
        '+',
        truckTire?.drive,
        'Drive',
      ].join(' ');
      setSize(value);
    } else {
      setSize(null);
    }
    // eslint-disable-next-line
  }, [truckTire, truckTire]);

  const renderSteer = () => {
    if (!initial.steer) {
      return (
        <FormControl variant='outlined' className={classes.form}>
          <InputLabel htmlFor='outlined-age-native-simple'>Steer</InputLabel>
          <Select
            native
            value={truckTire.steer}
            onChange={handleChange}
            label='Steer'
            name='steer'
            fullWidth
          >
            <option aria-label='None' value='' />
            {availableSizes.map((item, i) => (
              <option key={i} label={item} value={item} />
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  const renderDrive = () => {
    if (!initial.drive) {
      return (
        <FormControl variant='outlined' className={classes.form}>
          <InputLabel htmlFor='outlined-age-native-simple'>Drive</InputLabel>
          <Select
            native
            value={truckTire.drive}
            onChange={handleChange}
            label='Drive'
            name='drive'
            fullWidth
          >
            <option aria-label='None' value='' />
            {availableSizes.map((item, i) => (
              <option key={i} label={item} value={item} />
            ))}
          </Select>
        </FormControl>
      );
    }
  };

  const renderTireSizeConfig = () => {
    if (
      configData.vehicle_type.toLowerCase() === 'truck' ||
      configData.vehicle_type.toLowerCase() === 'bus'
    ) {
      return (
        <Box display='flex' flexDirection={'row'}>
          {renderSteer()}
          {renderDrive()}
        </Box>
      );
    }

    return (
      <Box>
        <FormControl variant='outlined' className={classes.form}>
          <InputLabel htmlFor='outlined-age-native-simple'>Tire</InputLabel>
          <Select
            native
            value={trailerTire}
            onChange={e => {
              if (e.target.value === '') {
                setSize(null);
              } else {
                setSize(e.target.value);
              }

              setTrailerTire(e.target.value);
            }}
            label='Tire'
            name='Tire'
            fullWidth
          >
            <option aria-label='None' value='' />
            {availableSizes.map((item, i) => (
              <option label={item} value={item} />
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <Box
      width='100%'
      display='flex'
      justifyContent='space-evenly'
      flexWrap='wrap'
      rowGap={'5px'}
      marginTop={'30px'}
    >
      {renderTireSizeConfig()}
    </Box>
  );
};

export default TireSizeConfig;

TireSizeConfig.propTypes = {
  size: PropTypes.any,
  setSize: PropTypes.func,
  configData: PropTypes.object,
  truckTire: PropTypes.any,
  setTruckTire: PropTypes.func,
  trailerTire: PropTypes.any,
  setTrailerTire: PropTypes.func,
};

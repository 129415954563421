import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
  useTheme,
} from '@mui/material';
import SearchBar from 'components/custom/SearchTemp';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';

import { appSearch } from 'services/util_services';
import { debounce } from 'lodash';
import styles from './HomeSearchBar.module.css';

const useStyles = makeStyles()(theme => {
  return {
    paper: {
      position: 'absolute',
      top: '100%',
      right: 0,
      zIndex: 99,
      padding: theme.spacing(2),
      backgroundColor: '#fff',
      backdropFilter: 'blur(15px)',
      width: 355,
      maxHeight: 340,
      overflow: 'auto',
    },
    searchHeading: {
      background: '#F0F0F0',
      margin: '-15px',
      padding: '5px',
      borderRadius: '8px',
      marginBottom: '10px',
      marginTop: '5px',
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.primary.link,
      padding: 0,
    },
  };
});

export function HomeSearchBar() {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(null);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async text => {
      setLoading(true);
      try {
        if (text.length > 0) {
          const res = await appSearch(text);
          if (res?.status === 200) {
            if (res?.data?.resources) {
              setSearchResults(res?.data?.resources);
            } else {
              revert();
            }
          } else {
            revert();
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }, 700),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    const handleDebounce = debounce(() => {
      setLoading(true);
      if (searchQuery?.length > 0) {
        debouncedSearch(searchQuery);
      } else {
        setSearchResults(null);
      }
    }, 700);

    handleDebounce();

    return () => {
      handleDebounce.cancel();
    };
  }, [searchQuery, debouncedSearch]);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const revert = () => {
    setSearchResults(null);
  };

  function formatSearchResult(result, searchTerm) {
    const hasNameHits = result.name
      .toLowerCase()
      .includes(searchTerm?.toLowerCase());
    const hasRegPlateHits = result.reg_plate
      .toLowerCase()
      .includes(searchTerm?.toLowerCase());

    if (hasNameHits && hasRegPlateHits) {
      return `${result.name} (${result.reg_plate})`;
    } else if (hasNameHits && !hasRegPlateHits) {
      return result.name;
    } else if (hasRegPlateHits && !hasNameHits) {
      return result.reg_plate;
    }
  }

  const highlightSearchTerm = (text, term) => {
    if (!text) return '';
    const regex = new RegExp(term, 'gi');
    return text.replace(
      regex,
      match => `<span style="background-color: yellow">${match}</span>`
    );
  };

  function SearchResult({ result, searchTerm, entity }) {
    return (
      <div
        data-testid='search-options'
        dangerouslySetInnerHTML={{
          __html: highlightSearchTerm(
            entity === 'tire'
              ? result.tire_id
              : formatSearchResult(result, searchTerm),
            searchTerm
          ),
        }}
      />
    );
  }

  function vehicleSearchResultsView() {
    return (
      searchResults?.vehicles?.length > 0 && (
        <>
          <Typography className={classes.searchHeading} variant='h5'>
            Vehicles:
          </Typography>
          {searchResults.vehicles.map((result, i) => (
            <Link
              key={i}
              className={classes.link}
              color='primary'
              to={`/fleets-data/${
                result?.fleet_id
              }/${result?.vehicle_type?.toLowerCase()}s/${result?.vehicle_id}`}
            >
              <List style={{ padding: 0 }}>
                <ListItem disablePadding>
                  <ListItemButton component='a'>
                    <SearchResult
                      result={result}
                      searchTerm={searchQuery}
                      entity={'vehicle'}
                    />
                  </ListItemButton>
                </ListItem>
              </List>
            </Link>
          ))}
        </>
      )
    );
  }

  function tireSearchResultsView() {
    return (
      searchResults?.tires?.length > 0 && (
        <>
          <Typography className={classes.searchHeading} variant='h5'>
            Tires:
          </Typography>
          {searchResults.tires.map((result, i) => (
            <List key={i} style={{ padding: 0 }}>
              <ListItem disablePadding>
                <ListItemButton component='a'>
                  <Link
                    className={classes.link}
                    color='primary'
                    to={`/tire-inventory/${result?.fleet_id}/${result?.system_tire_id}`}
                  >
                    <SearchResult
                      result={result}
                      searchTerm={searchQuery}
                      entity={'tire'}
                    />
                  </Link>
                </ListItemButton>
              </ListItem>
            </List>
          ))}
        </>
      )
    );
  }

  function searchResultsView() {
    return (
      showSearchResults && (
        <Paper
          elevation={3}
          className={classes.paper}
          style={{ width: i18n.language !== 'pl' ? 470 : 600 }}
          data-testid='search-options'
        >
          {loading ? (
            <div
              style={{ borderTop: `4px solid ${theme.palette.primary.main}` }}
              className={styles.loader}
            ></div>
          ) : searchResults?.['vehicles'].length ||
            searchResults?.['tires'].length ? (
            <>
              {vehicleSearchResultsView()}
              {tireSearchResultsView()}
            </>
          ) : (
            <span>{t('homePage.searchbar.noResults')}</span>
          )}
        </Paper>
      )
    );
  }

  return (
    <Box position={'relative'}>
      <SearchBar
        data-hook='search-bar'
        setSearchQuery={setSearchQuery}
        // ref={inputRef}
        debouncedSearch={debouncedSearch}
        show={showSearchResults}
        setShow={setShowSearchResults}
        placeholder={t('homePage.searchbar.placeholder')}
        width={i18n.language !== 'pl' ? 470 : 500}
        hook='home'
      />
      {searchResultsView()}
    </Box>
  );
}

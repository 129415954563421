import { makeStyles } from 'tss-react/mui';

export const style = makeStyles()(theme => {
  return {
    card: {
      backgroundColor: '#fff',
      width: '100%',
      height: 'calc(100% - 60px)',
      boxShadow: theme.palette.boxShadow,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    card2: {
      backgroundColor: '#fff',
      height: '100%',
      boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.04)',
    },
    btn: {
      textTransform: 'none',
      marginTop: theme.spacing(4),
    },
    btn2: {
      color: '#343434',
      width: 120,
      border: '1px solid #343434',
      height: 40,
      fontSize: 12,
      fontWeight: 600,
      padding: 10,
      textTransform: 'capitalize',
      [theme.breakpoints.up(2600)]: {
        height: 60,
        width: 150,
      },
      [theme.breakpoints.up(3840)]: {
        height: 90,
        width: 260,
        fontSize: 30,
      },
    },
    btn_general: {
      width: 120,
      height: 40,
      fontSize: 12,
      fontWeight: 600,
      padding: 10,
      textTransform: 'capitalize',
      [theme.breakpoints.up(2600)]: {
        height: 60,
        width: 150,
      },
      [theme.breakpoints.up(3840)]: {
        height: 90,
        width: 260,
        fontSize: 30,
      },
    },
    modal: {
      width: '70%',
      height: '90%',
      margin: '2em auto',
      backgroundColor: '#F4F5F6',
      overflowY: 'scroll',
    },
    savings: {
      marginLeft: 1,
      [theme.breakpoints.down('md')]: {
        marginLeft: 'unset',
        marginTop: 1,
      },
    },
    tableCard: {
      height: '100%',
      boxShadow: '0px 8px 30px rgba(0, 0, 0, 0.04)',
    },
    tableContainer: {
      height: 400,
    },
    imgTruck: {
      width: 50,
      height: 50,
    },
    imgTrailer: {
      width: 60,
      height: 20,
    },
    kpi: {
      width: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      gap: 60,
      padding: 5,
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
    kpiText: {
      fontWeight: 700,
      fontSize: 'clamp(12px, 0.8vw, 2rem)',
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      textAlign: 'center',
    },
    filterBtn: {
      textTransform: 'capitalize',
      fontSize: '1vw',
      fontWeight: 600,
    },
    header: {
      height: '60px',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      paddingInline: theme.spacing(2.5),
      paddingBlock: theme.spacing(1.5),
      alignItems: 'center',
      borderBottom: '0.5px solid #E6E6E6',
    },
    heading: { fontSize: 'clamp(18px, 1.3vw, 3rem)', fontWeight: 600 },
    bottomCard: {
      height: 186,
      // padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.up(2560)]: {
        height: 300,
      },
    },
    carouselHeading: {
      fontWeight: 600,
      fontSize: 'clamp(20px, 1.3vw, 3rem)',
    },
    cellHeading1: {
      fontSize: '1vw',
      backgroundColor: '#f3f2f2',
      // backgroundColor: '#ffffff',
      fontWeight: 600,
      display: 'flex',
      justifyContent: 'space-between',
      padding: '1.5rem',
    },
    cellHeading: {
      fontSize: '1vw',
      backgroundColor: '#f3f2f2',
      // backgroundColor: '#ffffff',
      fontWeight: 600,
    },
    circle: {
      position: 'absolute',
    },
    circle1: {
      zIndex: 1,
    },
    circle2: {
      color: '#BFBFBF',
    },
    chip: {
      borderRadius: 4,
      fontWeight: 900,
      margin: '0.25rem 0px',
      textTransform: 'uppercase',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      [theme.breakpoints.up(3840)]: {
        fontSize: 30,
      },
    },
    actionRequiredTableSkeleton: {
      borderRadius: 5,
    },
    dot: {
      height: 10,
      width: 10,
      borderRadius: '50%',
      cursor: 'pointer',
      transition: 'background-color 0.6s ease',
    },
    valueContainer: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    retreadStrategyContainer: {
      display: 'flex',
      flexDirection: 'row',
      paddingInline: '1.5rem',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    isVehicleDataLoadingContainer: {
      width: 'calc(100% - 8px)',
      height: 'calc(100% - 76px)',
      display: 'flex',
      flexDirection: 'column',
      gridGap: 10,
      padding: 2,
    },
    infoNeededContainer: {
      display: 'flex',
      width: '100%',
      marginLeft: theme.spacing(1),
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    topSkeleton: {
      height: '15%',
      width: '100%',
    },
    commonSkeleton: {
      height: '21%',
      width: '100%',
    },
    tableHeader: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      maxHeight: '100px',
    },
    label: {
      fontSize: 16,
      fontWeight: 600,
    },
    value: {
      fontSize: 16,
      fontWeight: 800,
    },
    drawerContent: {
      // marginInline: 3,
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      padding: theme.spacing(1.5),
      height: '100%',
      gap: 20,
    },
    latest: {
      padding: theme.spacing(1.5),
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      // fontWeight: 'bold',
    },
    bold: {
      fontWeight: 1000,
      opacity: 1,
      fontSize: 16,
      // color: theme.palette.primary.main,
    },
    textContainer: {
      // maxHeight: '1.5em',
      overflow: 'hidden',
      position: 'relative',
    },
    textContent: {
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
    },
    expanded: {
      '-webkit-line-clamp': 'unset',
      maxHeight: 200,
      overflow: 'auto',
    },
    moreBtn2: {
      // position: 'absolute',
      bottom: 0,
      right: 0,
      background: 'none',
      border: 'none',
      color: '#6598e6',
      cursor: 'pointer',
    },
    moreBtn: {
      flex: '10%',
      textTransform: 'unset',
      maxHeight: 40,
      width: 'fit-content',
    },
    picBox: {
      marginTop: 10,
      marginRight: 30,
      borderRadius: '10px',
      // backgroundColor: 'green',
      padding: 10,
      color: '#fff',
    },
    mainEventDisplay: {
      flex: '70%',
      maxHeight: 70,
      overflowX: 'auto',
      padding: '0 5px',
    },
    tireInventoryLink: { color: '#3577DC' },
    manageTiresText: { color: 'grey' },
    list: {
      maxHeight: 150,
      overflowY: 'auto',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    tireLink: {
      color: '#fff',
      // textDecoration: 'none',
    },
    commonTypography: {
      fontSize: '1vw',
      fontWeight: 600,
    },
    configureBtn: {
      textTransform: 'unset',
      margin: '0.6rem auto',
    },
    completeProfileTitle: {
      fontWeight: 700,
      fontSize: 'clamp(15px, 1vw, 2.5rem)',
    },
    completeProfileDesc: {
      fontWeight: 400,
      fontSize: 'clamp(12px, 0.8vw, 2rem)',
    },
    loadMoreBtn: {
      textTransform: 'unset',
    },
  };
});

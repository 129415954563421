import React, { useState } from 'react';
import {
  Button,
  Chip,
  CircularProgress,
  Switch,
  Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setChangeInVehicle, vehicleUpdatedSelector } from 'redux/fleets/ducks';
import { setNotification } from 'redux/notifications/ducks';
import { updateVehicle } from 'services/fleet_service';
import { VEHICLE } from 'utils/string_utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      maxWidth: '1000px',
      width: 'auto',
      height: 'auto',
      border: 'none',

      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 3rem',
      position: 'relative',
    },
    navBtns: {
      textTransform: 'unset',
      width: '30%',
      borderRadius: 24,
      alignSelf: 'flex-start',
    },
    chip: {
      minWidth: 78,
    },
  };
});

const LiftAxleConfiguration = ({ open, setOpen, axles, vehicle }) => {
  const { classes } = useStyles();
  const initialState = Array.from(axles, (_, index) => ({
    [`Axle-${index}`]: axles[index] === 'T' ? true : false,
  }));
  const [checkedState, setCheckedState] = useState(initialState);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, index, label) => {
    // let current = checkedState;

    const newState = [...checkedState];
    const allAxlesLifted = newState
      .filter(item => Object.keys(item)[0] !== label)
      .every(obj => Object.values(obj)[0] === true);

    newState[index] = {
      [label]: !allAxlesLifted ? event.target.checked : false,
    };

    if (allAxlesLifted) {
      dispatch(
        setNotification(t('tireConfigPage.liftAxle.errorMsg'), 'error', true)
      );
    }

    setCheckedState(newState);
  };

  function areArraysEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i][`Axle-${i}`] !== array2[i][`Axle-${i}`]) {
        return false;
      }
    }

    return true;
  }

  const confirmHandler = async () => {
    const lift_axle = checkedState
      .map(obj => (Object.values(obj)[0] ? 'T' : 'F'))
      .join('');
    const payload = {
      name: vehicle?.name,
      [vehicle.vehicle_type === VEHICLE.vehicle_type.TRUCK
        ? 'vin'
        : 'chassis_number']: vehicle?.vin ?? vehicle?.chassis_number,
      reg_plate: vehicle?.reg_plate,
      lift_axle,
    };
    try {
      if (vehicle.lift_axle !== lift_axle) {
        setIsSubmitting(true);
        const res = await updateVehicle(
          payload,
          vehicle?.vehicle_id,
          vehicle?.vehicle_type
        );
        if (res.status === 200) {
          dispatch(
            setNotification(
              t('tireConfigPage.liftAxle.successMsg'),
              'success',
              true
            )
          );
          dispatch(setChangeInVehicle(!vehicleDataHasChanged));
          handleClose();
        }
      }
    } catch (error) {
      console.error('E20091', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderAxles = () => {
    return axles.map((axle, i) => (
      <RowContent
        key={i}
        label={`Axle-${i}`}
        checkedStatus={checkedState}
        setState={setCheckedState}
        data-hook={`Axle-${i}`}
        index={i}
        t={t}
        changeHandler={handleChange}
      />
    ));
  };

  const updateButton = () => {
    if (!isSubmitting) {
      return (
        <Typography variant='h6' align='center'>
          {t('common.actions.update')}
        </Typography>
      );
    }
    return <CircularProgress size={30} color='inherit' />;
  };

  return (
    <>
      <Typography variant='h5' data-hook='configure-axle-title'>
        Configure Lift Axles
      </Typography>
      <Box padding={'20px'}>
        <Box
          display='flex'
          justifyContent={'space-between'}
          alignItems='center'
          gap={'20px'}
          marginBottom={2}
        >
          <Typography variant='h6' className={classes.label}>
            {t('tireConfigPage.liftAxle.axle')}
          </Typography>
          <Typography variant='h6' className={classes.label}>
            Action
          </Typography>
          <Box>
            {' '}
            <Typography variant='h6' className={classes.label}>
              Status
            </Typography>
          </Box>
        </Box>
        {renderAxles()}
        <Box
          display='flex'
          flexDirection='column'
          gap={'10px'}
          alignItems='center'
          justifyContent='center'
          width='100%'
        >
          <Button
            variant='contained'
            color='primary'
            className={classes.navBtns}
            data-hook='axle-status-update-button'
            onClick={confirmHandler}
            disabled={
              isSubmitting || areArraysEqual(checkedState, initialState)
            }
          >
            {updateButton()}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default LiftAxleConfiguration;

const RowContent = ({ label, checkedStatus, index, changeHandler, t }) => {
  const { classes } = useStyles();

  return (
    <Box display='flex' flexDirection='column' justifyContent='center'>
      <Box
        display='flex'
        justifyContent={'space-between'}
        alignItems='center'
        borderBottom='1px solid #F0F0F0'
        marginBottom={2}
        gap={'40px'}
      >
        <Typography variant='h6' className={classes.label}>
          {label}
        </Typography>
        <Switch
          checked={checkedStatus?.[index]?.[label]}
          onChange={event => changeHandler(event, index, label)}
          name={label}
          data-hook={`axle-switch-${index}`}
          color='primary'
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        <Box
          whiteSpace={'noWrap'}
          display='flex'
          alignItems='center'
          gap={'2px'}
        >
          {' '}
          <Chip
            label={
              checkedStatus?.[index]?.[label]
                ? t('tireConfigPage.liftAxle.lifted')
                : t('tireConfigPage.liftAxle.notLifted')
            }
            data-hook={`axle-status-${index}`}
            className={classes.chip}
            style={{
              backgroundColor: checkedStatus?.[index]?.[label]
                ? '#FFEDD5'
                : '#DEF2CC',
              color: checkedStatus?.[index]?.[label] ? '#F97316' : '#4D7C0F',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
LiftAxleConfiguration.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  axles: PropTypes.any,
  vehicle: PropTypes.object,
};

import { Grid, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { VehicleOverviewCount } from 'components/VehicleOverview/VehicleOverviewCount';
import { ReactComponent as Trailer } from 'assets/svg/home/trailer.svg';
import { ReactComponent as Truck } from 'assets/svg/home/truck_home.svg';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  fetchOverviewDetails,
  fleetOverviewSelector,
  setParameters,
  setSearchResults,
  setVehicles,
} from 'redux/fleets/ducks';
import CustomTable from 'components/custom/CustomTableTemp';
import i18next from 'i18next';
import { NAComponent } from '../../TireInventory/TireInventoryItems/FleetTireInventory';
import { Link } from 'react-router-dom';
import Loading from 'components/Loading';
import CommonGridItem from '../CommonGridItem';
import { getOverviewDetails } from 'services/fleet_service';
import * as XLSX from 'xlsx';
import LoadAnimation from 'components/LoadAnimation';
import { debounce } from 'lodash';
import { renderStatus } from 'utils/helper';

const VehicleOverviewPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const vehicleOverview = useSelector(state => state.fleet.vehicleOverview);
  const vehicles = useSelector(state => state.fleet.vehicles);
  const bus = vehicleOverview?.bus;
  const trailer = vehicleOverview?.trailer;
  const truck = vehicleOverview?.truck;
  const configurations = {
    partconfig: bus?.partConfig + trailer?.partConfig + truck?.partConfig,
    fullConfig: bus?.fullConfig + trailer?.fullConfig + truck?.fullConfig,
    unConfig: bus?.unConfig + trailer?.unConfig + truck?.unConfig,
  };
  const isLoading = useSelector(state => state.fleet.isLoadingOverviewData);
  const parameters = useSelector(state => state.fleet.parameters);
  const results = useSelector(state => state.fleet.searchResults);
  const [size, setSize] = useState(null);
  const dispatch = useDispatch();
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const [value, setValue] = useState(parameters?.vehicleTabValue || 0);

  const [params_, setParams_] = useState({
    accessing: 'ONLY_VEHICLE',
    vehicle_location: parameters?.vehicleLocation || 'fullyConfig',
    page_no: 0,
  });
  const vehicleResults = results?.vehicles;
  const [downloadedResources, setDownloadedResources] = useState({
    fullyConfig: null,
    partConfig: null,
    unConfig: null,
    merged: null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isInitial, setIsInitial] = useState(true);

  useEffect(() => {
    dispatch(fetchOverviewDetails(params_));

    //eslint-disable-next-line
  }, [fleets]);

  useEffect(() => {
    return () => {
      if (!window.location.pathname.includes('overview')) {
        // dispatch(setParameters(null));
        dispatch(setSearchResults(null));
      }
    };
  }, [dispatch]);

  useEffect(() => {
    const busCount = vehicleOverview?.bus?.count;
    const truckCount = vehicleOverview?.truck.count;
    const trailerCount = vehicleOverview?.trailer.count;

    const x = [];
    if (!!busCount) {
      x.push(busCount);
    }
    if (!!trailerCount) {
      x.push(trailerCount);
    }
    if (!!truckCount) {
      x.push(truckCount);
    }

    if (x.length > 2) {
      setSize(4);
    } else if (x.length === 2) {
      setSize(6);
    } else setSize(12);
  }, [vehicleOverview]);

  const vehicleColumns = useMemo(
    () => [
      {
        key: 'vehicle_name',
        title: t('headers.name'),
        render: row => (
          <Link
            style={{
              textDecoration: 'none',
              padding: 5,
              color: theme.palette.primary.main,
            }}
            data-hook={`${row.vehicle_id}-vehicle`}
            color='primary'
            to={`/fleets-data/${
              row.fleet_id
            }/${row.vehicle_type.toLowerCase()}s/${row.vehicle_id}`}
          >
            <NAComponent val={row?.vehicle_name} />{' '}
            {renderStatus(row?.metadata?.interchange?.status)}
          </Link>
        ),
        size: 80,
        canSort: true,
        dataHook: 'name',
      },
      {
        key: 'fleet_name',
        title: t('headers.fleet_name'),
        filterVariant: 'range',
        canSort: true,
        dataHook: 'fleet',
      },
      {
        key: 'vehicle_type',
        title: t('fleetsSection.vehicleType'),
        render: row => (
          <span>
            {row.vehicle_type.charAt(0).toUpperCase() +
              row.vehicle_type.slice(1)}
          </span>
        ),
        filterVariant: 'range',
        canSort: true,
        dataHook: 'vehicle-type',
      },
    ],
    /*eslint-disable-next-line*/
    [i18next.language]
  );
  const partial =
    vehicleOverview?.bus?.partConfig +
    vehicleOverview?.trailer?.partConfig +
    vehicleOverview?.truck?.partConfig;
  const full =
    vehicleOverview?.bus?.fullConfig +
    vehicleOverview?.trailer?.fullConfig +
    vehicleOverview?.truck?.fullConfig;
  const unconfig =
    vehicleOverview?.bus?.unConfig +
    vehicleOverview?.trailer?.unConfig +
    vehicleOverview?.truck?.unConfig;

  useEffect(() => {
    if (!isInitial) {
      fetchVehiclePaginatedData();
    }
    // eslint-disable-next-line
  }, [rowsPerPage, isInitial]);

  const fetchVehiclePaginatedData = async (page, location) => {
    try {
      const params = {
        accessing: 'ONLY_VEHICLE',
        vehicle_location: location || params_.vehicle_location,
        page_no: page,
        per_page: rowsPerPage,
      };
      dispatch(fetchOverviewDetails(params));
    } catch (error) {
      console.error('E20045');
    }
  };

  const vehicleData = [
    {
      label: t('common.configured'),
      component: (
        <VehicleTable
          vehicles={vehicles}
          columns={vehicleColumns}
          fetchPaginatedData={fetchVehiclePaginatedData}
          count={full}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: 'fullyConfig',
      count: full,
    },
    {
      label: t('common.partialConfig'),
      component: (
        <VehicleTable
          vehicles={vehicles}
          columns={vehicleColumns}
          fetchPaginatedData={fetchVehiclePaginatedData}
          count={partial}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: 'partConfig',
      count: partial,
    },
    {
      label: t('homePage.unconfigured'),
      component: (
        <VehicleTable
          vehicles={vehicles}
          columns={vehicleColumns}
          fetchPaginatedData={fetchVehiclePaginatedData}
          count={unconfig}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: 'unConfig',
      count: unconfig,
    },
    {
      label: t('homePage.overdue'),
      component: (
        <VehicleTable
          vehicles={vehicles}
          columns={vehicleColumns}
          fetchPaginatedData={fetchVehiclePaginatedData}
          count={vehicleOverview?.overdueFleetcheckCount}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: 'overdueFleetcheck',
      count: vehicleOverview?.overdueFleetcheckCount,
    },
    {
      label: t('homePage.merged'),
      component: (
        <VehicleTable
          vehicles={vehicles}
          columns={vehicleColumns}
          fetchPaginatedData={fetchVehiclePaginatedData}
          count={vehicleOverview?.truck?.merged}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: 'merged',
      count: vehicleOverview?.truck?.merged,
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setParams_({
      ...params_,
      vehicle_location: vehicleData[newValue].id,
      accessing: 'ONLY_VEHICLE',
    });
    dispatch(
      setParameters({
        vehicleTabValue: newValue,
        vehicleLocation: vehicleData[newValue].id,
        accessing: 'ONLY_VEHICLE',
      })
    );
    dispatch(setVehicles([]));
    if (vehicleData[newValue].count) {
      fetchVehiclePaginatedData(0, vehicleData[newValue].id);
    }
  };
  const [searchQuery, setSearchQuery] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  useEffect(() => {
    if (searchQuery?.length >= 2) {
      searchedDataHandler();
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  const searchedDataHandler = debounce(async () => {
    const params = {
      accessing: 'ONLY_VEHICLE',
      search_string: searchQuery,
    };
    try {
      dispatch(fetchOverviewDetails(params));
    } catch (err) {
      console.error(err);
    }
  }, 400);
  useEffect(() => {
    return () => {
      searchedDataHandler.cancel();
    };
  }, [searchedDataHandler]);

  if (!vehicles) {
    return <Loading />;
  }

  return (
    <div style={{ maxHeight: '80vh' }}>
      {isDownloading && (
        <LoadAnimation
          loading={isDownloading}
          setLoading={setIsDownloading}
          useMultiple={true}
        />
      )}
      <Grid container spacing={2} height={'100px'}>
        {!!truck?.count && (
          <VehicleOverviewCount
            src={<Truck stroke={theme.palette.primary.main} />}
            showIcon={configurations.partconfig}
            count={truck?.count}
            name={t('common.truck')}
            size={size}
          />
        )}
        {!!trailer?.count && (
          <VehicleOverviewCount
            src={<Trailer fill={theme.palette.primary.main} />}
            showIcon={configurations.partconfig}
            count={trailer?.count}
            name={t('common.trailer')}
            size={size}
          />
        )}
        {!!bus?.count && (
          <VehicleOverviewCount
            src={<Truck stroke={theme.palette.primary.main} />}
            showIcon={configurations.partconfig}
            count={bus?.count}
            name={t('common.bus')}
            size={size}
          />
        )}
      </Grid>
      <Grid container marginTop={'24px'}>
        <CommonGridItem
          title={t('common.vehicleOverview')}
          currentTab={value}
          tabs={vehicleData}
          handleChange={handleChange}
          overview={vehicleOverview}
          setSearchQuery={setSearchQuery}
          results={vehicleResults}
          downloadResources={() =>
            fetchAllResources(
              vehicleData,
              value,
              downloadedResources,
              setDownloadedResources,
              'ONLY_VEHICLE',
              {},
              setIsDownloading
            )
          }
        />
      </Grid>
    </div>
  );
};

export default VehicleOverviewPage;

const VehicleTable = ({
  vehicles,
  columns,
  fetchPaginatedData,
  count,
  loading,
  rowsPerPage,
  setRowsPerPage,
  setIsInitial,
}) => {
  return (
    <CustomTable
      data={vehicles || []}
      columns={columns}
      emptyMessage={'No vehicles available'}
      paginationHandler={fetchPaginatedData}
      freq={count}
      loading={loading}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      isInitialRun={setIsInitial}
      total={count}
    />
  );
};

export const fetchAllResources = async (
  data,
  value,
  downloadedResorces,
  setDownloadedResorces,
  entry,
  overview = {},
  setLoading
) => {
  let params =
    entry === 'ONLY_VEHICLE'
      ? {
          accessing: entry,
          vehicle_location: data[value].id,
          per_page: data[value].count,
        }
      : {
          accessing: entry,
          tire_location: data[value].id,
          per_page: overview?.[data[value].category],
        };
  let toDownload;
  if (
    !downloadedResorces[data[value].id] ||
    downloadedResorces[data[value].id]?.length === 0
  ) {
    try {
      setLoading(true);
      const res = await getOverviewDetails(params);
      if (res.status === 200) {
        toDownload =
          res.data.resources?.[entry === 'ONLY_VEHICLE' ? 'vehicles' : 'tires'];
        setDownloadedResorces({
          ...downloadedResorces,
          [data[value].id]: toDownload,
        });
      }
    } catch (error) {
      console.error('E20111', error);
    } finally {
      setLoading(false);
    }
  } else {
    toDownload = downloadedResorces[data[value].id];
  }
  toDownload = toDownload.map(obj => {
    Object.keys(obj).forEach(key => {
      if (obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  });
  if (toDownload.length > 0) {
    const ws = XLSX.utils.json_to_sheet(toDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
    XLSX.writeFile(workbook, `${data[value].label}.xlsx`);
  }
};

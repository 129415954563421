import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  fleetOverviewSelector,
  fleetWideTireEventHistorySelector,
  setFleetOverview,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  tireInventoryDataSelector,
  unprocessedTireInventoryDataSelector,
} from 'redux/fleets/ducks';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  NA,
  SERVICE_MAIL_ID,
  TIRES,
  tireStatuses,
  USER_LS,
  USER_ROLES,
  VEHICLE,
} from 'utils/string_utils';
import {
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  Fade,
  Grid,
  IconButton,
  Modal,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import CustomTable from 'components/custom/CustomTableTemp';
import {
  profileStatusSelector,
  setAccessTokenSelector,
  setUserSelector,
  tableauDataSelector,
} from 'redux/users/ducks';
import {
  fetchTireInventoryDataAndProcess,
  formatDate,
  calculateDays,
  getRemainingMonths,
  calcAvgValues,
} from '../TireInventory';
import {
  Check,
  Close,
  Delete,
  DeleteForever,
  Edit,
  Eject,
  ReportProblem,
  Visibility,
} from '@mui/icons-material';
import BuildIcon from '@mui/icons-material/Build';
import ManageTire from '../ManageTire';
import UpdateTire from '../UpdateTire';
import {
  bulkDelete,
  fetchSingleTireEventHistory,
  fleetOverview,
  getFilteredTireInventoryData,
  getTireInventoryData,
  revertEvent,
} from 'services/fleet_service';
import {
  NotificationsActionTypes,
  setNotification,
} from 'redux/notifications/ducks';
import Breadcrumb from 'components/custom/Breadcrumb';
import TireEventHistoryModal from '../TireEventHistoryModal';
import CustomTextField from 'components/custom/CustomTextField';

import { sendGeneralMail } from 'services/auth_service';
import { currentDate } from 'utils/helper';
import { ReportTireIssueModal } from './ReportTireIssueModal';
import CloseIcon from '@mui/icons-material/Close';
import { TireIdColumn } from 'pages/VehiclePage/TireConfig/TireConfig';
import ModifyTireModal from 'pages/VehiclePage/TireConfig/TireOperationsModal/ModifyTireModal';
import { BsDownload } from 'react-icons/bs';
import * as XLSX from 'xlsx';
import SearchBar from 'components/custom/SearchTemp';
import DeleteTire from '../DeleteTire';
import { makeStyles } from 'tss-react/mui';
import { TireInventoryFIlterDrawer } from 'components/FIlterDrawer/TireInventoryFilterDrawer';
import ColumnsEditDrawer from 'components/ColumnsEditDrawer';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import {
  inventoryChangeSelector,
  setChangeInInventory,
} from 'redux/tireInventory/ducks';
import { debounce } from 'lodash';
import LoadAnimation from 'components/LoadAnimation';

export const NAComponent = ({ val, hook }) => {
  return (
    <span data-cy={hook ? hook : ''} style={{ fontWeight: 400 }}>
      {val === null || val === undefined ? NA : val}
    </span>
  );
};
const useStyles = makeStyles()(theme => {
  return {
    root: {
      maxWidth: 500,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      height: 'auto',
      border: 'none',
      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 3rem',
      maxWidth: '1000px',
      position: 'relative',
    },
    pointer: {
      cursor: 'pointer',
    },
    label: {
      fontWeight: theme.typography.fontWeightLight,
    },
    escalateModal: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '40px',
    },
    escalateCard: {
      width: '100%',
      height: '100%',
      overflow: 'auto',
      position: 'relative',
    },
    escapeSubmit: {
      position: 'absolute',
      bottom: '-10px',
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },
    reportIcon: {
      cursor: 'pointer',
      color: '#f78934',
    },
    closeIcon: {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(2),
      color: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',
    },
    vehicle: {
      textDecoration: 'none',
      padding: 5,
      color: theme.palette.primary.main,

      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    list: {
      maxHeight: 150,
      overflowY: 'auto',
      listStyle: 'none',
      margin: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
    },
    tireLink: {
      color: '#fff',
    },
    tab: {
      minWidth: 102,
      textTransform: 'none',
    },
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      width: 355,
      border: '1px solid #D1D5DB',
      borderRadius: 8,
    },
    btn: {
      textTransform: 'unset',
    },
  };
});

const initialPayload = {
  isRetread: true,
  [TIRES.TREAD_DEPTH]: '',
  tire_id: '',
  mileage: '',
};

const errorInitalState = { isError: false, errorMessage: '' };
const init = [
  { key: 'vehicle_name' },
  { key: 'last_tread_depth' },
  { key: 'estimated_tread_depth' },
  { key: 'remaining_months' },
  { key: 'size' },
  { key: 'mileage' },
  { key: 'isRetread' },
  { key: 'isRegrooved' },
  { key: 'mount_date' },
  { key: 'last_event_date' },
  { key: 'axle_position' },
  { key: 'mission_profile' },
  { key: 'rfid' },
  { key: 'tireStatus' },
];
const visibleColumns = [
  'multi',
  'more',
  'tire_id',
  'vehicle_name',
  'last_tread_depth',
  'estimated_tread_depth',
  'remaining_months',
  'size',
  'mileage',
  'actions',
  'tireStatus',
];
const FleetTireInventory = () => {
  const { t, i18n } = useTranslation();
  const userLs = JSON.parse(USER_LS);

  const titlesArray = {
    vehicle_name: t('tireInventoryItems.titles.name'),
    last_tread_depth: t('tireInventoryItems.titles.treadDepth'),
    estimated_tread_depth: t(
      'tireInventoryItems.titles.estimated_current_tread_depth'
    ),
    remaining_months: t('tireInventoryItems.titles.monthsLeft'),
    mileage: t('tireInventoryItems.titles.mileage'),
    isRetread: t('tireInventoryItems.titles.isRetread'),
    isRegrooved: t('tireInventoryItems.titles.isRegrooved'),
    mount_date: t('tireInventoryItems.titles.mountDate'),
    last_event_date: t('tireInventoryItems.titles.lastUpdate'),
    axle_position: t('tireInventoryItems.titles.axlePosition'),
    mission_profile: t('tireInventoryItems.titles.mission_profile'),
    rfid: 'RFID',
    status: 'Status',
    brand: t('tireInventoryItems.titles.brand'),
    product_line: t('tireInventoryItems.titles.product_line'),
    estimated_wear_out_date: t(
      'tireInventoryItems.titles.estimated_wear_out_date'
    ),
    tire_id: t('tireInventoryItems.titles.tire_id'),
    last_event_type: t('tireInventoryItems.titles.lastEventType'),
    size: t('tireInventoryItems.titles.size'),
    position: t('tireInventoryItems.titles.position'),
    vehicle_id: `${t('tireInventoryItems.titles.vehicle')} Id`,
  };
  const params = useParams();
  const { classes } = useStyles();
  const { fleet_id } = params;
  const access_token = useSelector(setAccessTokenSelector);
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const [payload, setPayload] = useState({ ...initialPayload });
  const [openEdit, setOpenEdit] = useState(false);
  const [fleetId, setFleetId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userTireId, setUserTireId] = useState(null);
  const [rowSelected, setRowSelected] = useState({});
  const availableTableauData = useSelector(tableauDataSelector);
  const [showBtn, setShowBtn] = useState({ found: false, data: null });
  const [items, setItems] = useState({
    brand: '',
    line: '',
    currentSize: '',
    treadDepth: '',
    retreadStatus: '',
  });
  const [id, setId] = useState(null);
  const [openEscalate, setOpenEscalate] = useState(false);
  const [escalateMessage, setEscalateMessage] = useState('');
  const [isReverting, setIsReverting] = useState(false);
  const [openRevertDialog, setOpenRevertDialog] = useState(false);
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);

  const [tireLocations, setTireLocations] = useState({
    inUse: null,
    recyclingBank: null,
    storage: null,
    retreading: null,
    repair: null,
    all: null,
  });
  const today = currentDate();
  const tireInventoryData = useSelector(tireInventoryDataSelector);
  const dispatch = useDispatch();
  const userProfile = useSelector(profileStatusSelector);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fleetName, setFleetName] = useState();
  const [apiError, setApiError] = useState({ ...errorInitalState });
  const [tireEventHistoryIsOpen, setTireEventHistoryIsOpen] = useState(false);
  const [tireEventHistoryIsLoading, setTireEventHistoryIsLoading] =
    useState(false);
  const [eventHistoryData, setEventHistoryData] = useState(null);
  const [location, setLocation] = useState('IN_USE');
  const fleetTireEventHistory = useSelector(fleetWideTireEventHistorySelector);
  const tireInventoryData_ = tireInventoryData?.[fleet_id]?.originalData;
  const currentLanguage = i18n.language;
  const [tireData, setTireData] = useState(null);
  const [value, setValue] = useState(0);
  const [filterCounter, setFilterCounter] = useState(0);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [regrooveCheck, setRegrooveCheck] = useState({});
  const [treadFilterValues, setTreadFilterValues] = useState({});
  const [isDownloading, setIsDownloading] = useState(false);
  const filterValues = (arr, values) => {
    return arr.filter(item => !values.includes(item.key));
  };
  const [filterOptions, setFilterOptions] = useState(
    value === 0
      ? init
      : filterValues(init, [
          'vehicle_id',
          'vehicle_name',
          'axle_position',
          'mount_date',
        ])
  );

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterParameters, setFilterParameters] = useState({});
  const [checkedFilters, setCheckedFilters] = useState({});
  const [dates, setDates] = useState({});
  const [tireDetails, setTireDetails] = useState({});
  const [tireTotals, setTotals] = useState({});
  const tireInventoryDetails = useSelector(state => state.fleet.overviewData);
  const [searchQuery, setSearchQuery] = useState(null);
  const fleetParams = useParams();
  const [openDeleteTire, setOpenDeleteTire] = useState(false);
  const userDetails = useSelector(setUserSelector) || userLs;

  const userIsReporter = userDetails?.iam_role === USER_ROLES.reporter;

  const categorizeTires = (tires, categorizedTires) => {
    tires?.forEach(tire => {
      switch (tire.status) {
        case 'STORAGE':
        case 'REFURBISH':
        case 'RETREAD':
        case 'REGROOVE':
          categorizedTires.storage.push(tire);
          break;
        case 'STORE_BANK':
        case 'WARRANTY_BANK':
        case 'PUNCTURE_BANK':
        case 'SMASH_BANK':
        case 'WORN_BANK':
        case 'REPAIR_BANK':
          categorizedTires.recyclingBank.push(tire);
          break;
        case 'STORE':
        case 'WARRANTY':
        case 'PUNCTURE':
        case 'SMASH':
        case 'WORN':
        case 'REPAIR':
          categorizedTires.repair.push(tire);
          break;
        case 'RETREADING':
          categorizedTires.retreading.push(tire);
          break;
        case 'IN_USE':
          categorizedTires.inUse.push(tire);
          break;
        default:
          break;
      }
    });
  };
  const [currentPage, setCurrentPage] = useState(0);
  const getTireMetrics = loc => {
    const metrics = tireInventoryDetails?.metrics?.[fleet_id]?.[loc];
    if (metrics) {
      return parseInt(metrics);
    } else return 0;
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isInitial, setIsInitial] = useState(true);
  const [tabData, setTabData] = useState({
    REPAIR: null,
    RETREADING: null,
    IN_USE: tireInventoryData_,
    STORAGE: null,
    RECYCLING_BANK: null,
    ALL: null,
  });
  const currInventoryChangeValue = useSelector(inventoryChangeSelector);
  const [payloadFilterValues, setPayloadFilterValues] = useState(null);

  useEffect(() => {
    updateTabCounts(access_token, dispatch);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (tireInventoryDetails) {
      const totalTires =
        tireInventoryDetails.metrics[fleetParams.fleet_id]?.total_tires;
      getTireMetrics('store_bank');

      const categories = {
        IN_USE: getTireMetrics('in_use'),
        REPAIR: getTireMetrics('repair'),

        RETREADING: getTireMetrics('retreading'),
        RECYCLING_BANK:
          getTireMetrics('repair_bank') +
          getTireMetrics('worn_bank') +
          getTireMetrics('warranty_bank') +
          getTireMetrics('smash_bank') +
          getTireMetrics('puncture_bank'),
        STORAGE:
          getTireMetrics('storage') +
          getTireMetrics('store') +
          getTireMetrics('retread') +
          getTireMetrics('regroove') +
          getTireMetrics('refurbish'),
        ALL: totalTires,
      };

      setTireDetails(categories);
      setTotals(categories);
    }
    // eslint-disable-next-line
  }, [tireInventoryDetails, fleetParams.fleet_id]);

  async function FetchData(loc) {
    const reportsLength = tireDetails[loc || location];
    if (reportsLength) {
      setIsDownloading(true);
      try {
        const res = await getTireInventoryData(
          fleet_id,
          loc || location,
          0,
          reportsLength
        );

        if (res.status === 200) {
          return res.data.resp.tires;
        }
      } catch (error) {
        console.error('E20109', error);
      } finally {
        setIsDownloading(false);
      }
    }
  }

  useEffect(() => {
    const check = availableTableauData?.filter(
      item => item.fleet_id === fleet_id
    );

    if (check?.length) {
      setShowBtn({ found: true, data: check });
    }
  }, [fleet_id, availableTableauData]);

  useEffect(
    () => {
      const allTires = tireInventoryData?.[fleet_id]?.originalData;
      const categorizedTires = {
        inUse: [],
        recyclingBank: [],
        storage: [],
        retreading: [],
        repair: [],
        all: [],
      };
      categorizeTires(allTires, categorizedTires);

      setTireLocations({
        ...tireLocations,
        ...categorizedTires,
        all: allTires,
      });
    },
    // eslint-disable-next-line
    [tireInventoryData?.[fleet_id], value]
  );

  const fetchPaginatedData = async (page, loc) => {
    setCurrentPage(page);
    setSelectedTires([]);
    loc && setLocation(loc);
    try {
      setLoading(true);
      setApiError({ ...errorInitalState });
      if (tireDetails[loc || location]) {
        const data = await fetchTireInventoryDataAndProcess({
          fleet_id,
          inventoryData: fleetInventoryData,
          refetch: true,
          location: loc || location,
          isPaginated: true,
          pageNo: page,
          perpage: rowsPerPage,
          ...(payloadFilterValues !== null &&
            JSON.stringify(payloadFilterValues) !== '{}' && {
              filter_values: payloadFilterValues,
            }),
          // search_string: loc === location ? searchQuery : '',
          ...(searchQuery?.length && { search_string: searchQuery }),
        });
        dispatch(setTireInventoryData(data, fleet_id));
        setTabData(current => {
          return {
            ...current,
            [loc || location]: data?.originalData,
          };
        });
      } else {
        setTabData(current => {
          return {
            ...current,
            [loc || location]: [],
          };
        });
      }
    } catch (error) {
      console.error('E20045');
      setApiError({
        isError: true,
        errorMessage: t('error.errorLoadingData'),
      });
    } finally {
      setLoading(false);
    }
  };

  const getTires = async () => {
    try {
      setLoading(true);
      setApiError({ ...errorInitalState });

      const data = await fetchTireInventoryDataAndProcess({
        fleet_id,
        inventoryData: fleetInventoryData,
        refetch: true,
        location,
        isPaginated: true,
        pageNo: 0,
        perpage: rowsPerPage,
      });
      dispatch(setTireInventoryData(data, fleet_id));
      dispatch(setChangeInInventory(!currInventoryChangeValue));
      setCurrentPage(0);
    } catch (error) {
      console.error('E20045');
      setApiError({
        isError: true,
        errorMessage: t('error.errorLoadingData'),
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchFilterOptions = async () => {
    try {
      const res = await getFilteredTireInventoryData(fleet_id, location, {
        filter_options: true,
      });
      let filter_options = res?.data?.resp;
      let current = Object.keys(filter_options).map(x => ({
        key: x,
      }));
      setFilterOptions(filter_options);
      current.forEach(option => {
        option.title = titlesArray[option.key];

        option.options = filter_options[option.key];

        option.hasInitialValues = filter_options[option.key]?.length
          ? true
          : false;
      });

      setSelectedFilters([]);
      setFilterParameters({});
      setFilterCounter(0);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (fleet_id && access_token) {
      fetchFilterOptions();
    }
    /*eslint-disable-next-line*/
  }, [access_token, fleet_id, location, handleUnmount]);

  useEffect(() => {
    if (fleet_id) {
      const fleet = fleets?.find(fleet => fleet.fleet_id === fleet_id);
      setFleetName(fleet?.fleet_name);
    }
  }, [fleet_id, fleets]);

  const downloadExcel = async () => {
    // let reports = [];
    const reports = await FetchData();

    const worksheet = XLSX.utils.json_to_sheet(reports);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const sheetName =
      fleetName +
      '_tire_inventory_' +
      t(`tireInventoryItems.statuses.${tabs[value].id}`) +
      '_' +
      today +
      '.xlsx';
    XLSX.writeFile(workbook, sheetName);
  };

  const handleDataFormatForExcel = data => {
    const rows = data.map(row => ({
      [t('tireInventoryItems.titles.tire_id')]: row.tire_id || NA,
      [t('tireInventoryItems.titles.name')]: row.vehicle_name || NA,
      [t('tireInventoryItems.titles.size')]: row.size || NA,
      [t('tireInventoryItems.titles.brand')]: row.brand || NA,
      [t('tireInventoryItems.titles.product_line')]: row.product_line || NA,
      [t('tireInventoryItems.titles.treadDepth')]: row.last_tread_depth || NA,
      [t('tireInventoryItems.titles.estimated_current_tread_depth')]:
        row.estimated_tread_depth || NA,
      [t('tireInventoryItems.titles.mileage')]:
        Math.round(parseFloat(row.mileage ?? row.last_km_driven)) || NA,
      [t('tireInventoryItems.titles.monthsLeft')]: row.remaining_months || NA,
      [t('tireInventoryItems.titles.isRetread')]: row.isRetread,
      [t('tireInventoryItems.titles.mountDate')]: row.mount_date || NA,
      [t('tireInventoryItems.titles.mountDays')]: row.mount_date || NA,
      [t('tireInventoryItems.titles.axlePosition')]: row.axle_position || NA,
      [t('tireInventoryItems.titles.status')]: row.status
        ? t(`tireInventoryItems.statuses.${row.status}`)
        : NA,
      [t('tireInventoryItems.titles.lastUpdate')]: row.last_event_date || NA,
      [t('tireInventoryItems.titles.lastVehicleName')]:
        row.last_vehicle_name || NA,
      [t('tireInventoryItems.titles.mission_profile')]:
        row.mission_profile || NA,
      RFID: row.rfid || NA,
    }));

    return rows;
  };

  var handleUnmount = row => {
    setUserTireId(row.tire_id);
    setId(row.system_tire_id);
    setOpen2(true);
    setPayload({
      ...payload,
      [TIRES.TREAD_DEPTH]: row.estimated_tread_depth ?? row.last_tread_depth,
      tire_id: row.tire_id,
      mileage: row.mileage?.toFixed(2),
    });
    setTireData(row);
  };

  const [selectedTires, setSelectedTires] = useState([]);
  const checkChangeHandler = row => {
    if (!selectedTires.includes(row.system_tire_id)) {
      setSelectedTires(items => [...items, row.system_tire_id]);
    } else {
      setSelectedTires(items =>
        items.filter(item => item !== row.system_tire_id)
      );
    }
  };

  const columns = useMemo(
    () => [
      {
        key: 'multi',
        title: '',
        canSort: false,
        render: row => (
          <Checkbox
            onChange={() => checkChangeHandler(row)}
            size='small'
            disabled={row.tireStatus === tireStatuses.IN_USE}
            checked={selectedTires.includes(row.system_tire_id)}
          />
        ),
        dataHook: 'multi',
      },
      {
        key: 'more',
        title: t('tireConfigPage.more'),
        canSort: false,
        dataHook: 'more',
      },
      {
        key: 'tire_id',
        title: t('tireInventoryItems.titles.tire_id'),
        render: row => <TireIdColumn row={row} />,
        size: 80,
        canSort: true,
        dataHook: 'tire-id',
      },
      {
        key: 'vehicle_name',
        title: t('tireInventoryItems.titles.name'),
        render: row => (
          <>
            {row?.vehicle_name ? (
              <Link
                className={classes.vehicle}
                color='primary'
                to={`/fleets-data/${fleet_id}/${row.vehicle_type.toLowerCase()}s/${
                  row.vehicle_id
                }`}
                data-hook={`vehicle_${row.tire_id}`}
              >
                <NAComponent val={row?.vehicle_name} />
              </Link>
            ) : (
              <NAComponent val={row?.vehicle_name} />
            )}
          </>
        ),
        canSort: true,
        dataHook: 'vehicle-name',
      },
      {
        key: 'last_tread_depth',
        title: t('tireInventoryItems.titles.treadDepth'),
        render: row => (
          <NAComponent
            val={formatNumber(row.last_tread_depth)}
            hook={`last_tread_depth_${row?.tire_id}`}
          />
        ),
        canSort: true,
        dataHook: 'last-tread-depth',
      },
      {
        key: 'estimated_tread_depth',
        title: t('tireInventoryItems.titles.estimated_current_tread_depth'),
        filterVariant: 'range',
        render: row => (
          <NAComponent val={formatNumber(row?.estimated_tread_depth)} />
        ),
        canSort: true,
        dataHook: 'estimated-tread-depth',
      },
      {
        key: 'remaining_months',
        title: t('tireInventoryItems.titles.monthsLeft'),
        filterVariant: 'range',
        render: row => <WearOutInfo val={row?.remaining_months} />,
        canSort: true,
        dataHook: 'remaining-months',
      },
      {
        key: 'size',
        title: t('tireInventoryItems.titles.size'),
        render: row => <NAComponent val={row.size} />,
        canSort: true,
        dataHook: 'sizes',
      },
      {
        key: 'mileage',
        title: t('tireInventoryItems.titles.mileage'),
        render: row => (
          <NAComponent
            val={row?.mileage && Math.round(parseFloat(row.mileage))}
          />
        ),
        canSort: true,
        dataHook: 'mileage',
      },
      {
        key: 'isRetread',
        title: t('tireInventoryItems.titles.isRetread'),
        render: row => <div>{row.isRetread ? <Check /> : <CloseIcon />}</div>,
        enableColumnActions: false,
        dataHook: 'is-retread',
      },
      {
        key: 'actions',
        dataHook: 'actions',
        title: t('tireInventoryItems.titles.actions'),
        render: row => (
          <Box display='flex' gap={'15px'}>
            {row[VEHICLE.ID] && !userIsReporter && location !== 'STORAGE' && (
              <Tooltip
                title={t('common.tireInventory.moveTooltip')}
                onClick={() => {
                  handleUnmount(row);
                }}
                data-hook={`unmount_tire-${row.tire_id}`}
              >
                <Eject className={classes.pointer} />
              </Tooltip>
            )}
            {row.tireStatus !== tireStatuses.REPAIR &&
              row.tireStatus !== tireStatuses.RETREADING &&
              !userIsReporter && (
                <Tooltip
                  title={t('common.tireInventory.editTooltip')}
                  onClick={() =>
                    editClickHandler(
                      tireInventoryData?.[fleet_id]?.originalData,
                      row
                    )
                  }
                  data-hook={`edit_tire-${row.tire_id}`}
                >
                  <Edit className={classes.pointer} />
                </Tooltip>
              )}

            <Tooltip
              dataHook={'event-history'}
              data-cy={`event_history_${row.tire_id}`}
              title={t('common.tireInventory.viewEventHistory')}
              onClick={() => viewTireEventsHandler(row.system_tire_id)}
            >
              <Visibility className={classes.pointer} />
            </Tooltip>
            <Tooltip
              dataHook={'icon-tooltip'}
              data-cy={`report_${row.tire_id}`}
              title={t('escalateMessage.iconTooltip')}
              onClick={() => handleEscalateModalOpen(row)}
            >
              <ReportProblem size='small' className={classes.reportIcon} />
            </Tooltip>
            {(row?.status?.includes('BANK') ||
              row.tireStatus === tireStatuses.RETREADING ||
              row?.status === 'REPAIR') && (
              <Tooltip
                title={t('common.tireInventory.manageTire')}
                onClick={() => {
                  setId(row.system_tire_id);
                  setOpenDeleteDialog(true);
                  setTireData(row);
                }}
                dataHook={'manage-tire'}
              >
                <BuildIcon
                  className={classes.pointer}
                  sx={{ display: userIsReporter && 'none' }}
                />
              </Tooltip>
            )}
            {row.tireStatus !== tireStatuses.IN_USE && (
              <Tooltip
                title={t('common.tireInventory.removeTooltip')}
                onClick={() => {
                  setId(row.system_tire_id);
                  setOpenDeleteTire(true);
                }}
                dataHook={'delete-tire'}
              >
                <Delete
                  className={classes.pointer}
                  color='secondary'
                  sx={{ display: userIsReporter && 'none' }}
                />
              </Tooltip>
            )}
          </Box>
        ),
      },
      {
        key: 'mount_date',
        dataHook: 'mount-date',
        title: t('tireInventoryItems.titles.mountDate'),
        render: row => <NAComponent val={formatDate(row?.mount_date)} />,
        enableColumnActions: false,
      },
      {
        key: 'mount_days',
        dataHook: 'mount-datys',
        title: t('tireInventoryItems.titles.mountDays'),
        render: row => (
          <NAComponent
            val={row?.mount_date ? calculateDays(row?.mount_date) : NA}
          />
        ),
        enableColumnActions: false,
      },
      {
        key: 'axle_position',
        dataHook: 'axle-position',
        title: t('tireInventoryItems.titles.axlePosition'),
        render: row => <NAComponent val={row?.axle_position} />,
      },
      {
        key: 'last_event_date',
        dataHook: 'last-event-date',
        title: t('tireInventoryItems.titles.lastUpdate'),
        render: row => <NAComponent val={formatDate(row?.last_event_date)} />,
      },
      {
        key: 'last_vehicle_name',
        dataHook: 'last-vehicle-name',
        title: t('tireInventoryItems.titles.lastVehicleName'),
        render: row => <NAComponent val={row?.last_vehicle_name} />,
      },
      {
        key: 'mission_profile',
        dataHook: 'mission-profile',
        title: t('tireInventoryItems.titles.mission_profile'),
        render: row => <NAComponent val={row?.mission_profile} />,
      },
      {
        key: 'last_pressure',
        dataHook: 'pressure',
        title: t('tireInventoryItems.titles.pressure'),
        render: row => (
          <NAComponent
            val={row?.last_pressure}
            hook={`pressure_${row?.tire_id}`}
          />
        ),
      },
      {
        key: 'rfid',
        dataHook: 'rfid',
        title: 'RFID',
        render: row => <NAComponent val={row?.rfid} />,
      },
    ],
    /*eslint-disable-next-line*/
    [fleetTireEventHistory, tireInventoryData_, i18next.language, selectedTires]
  );

  const excludedKeys = [
    'mount_days',
    'mount_date',
    'vehicle_name',
    'axle_position',
  ];
  const notInUse = columns.filter(column => !excludedKeys.includes(column.key));

  const indexPosition = 7;
  const storageTabColumns = [
    ...notInUse.slice(0, indexPosition),
    {
      key: 'tireStatus',
      title: 'Status',
      render: row => <NAComponent val={row.status && row.tireStatus} />,
      size: 80,
      canSort: true,
    },
    ...notInUse.slice(indexPosition),
  ];

  const recyclingTabColumns = [
    ...notInUse.slice(0, indexPosition),
    {
      key: 'tireStatus',
      title: 'Unmount Reason',
      render: row => <NAComponent val={row.status && row.tireStatus} />,
      size: 80,
      canSort: true,
    },
    ...notInUse.slice(indexPosition),
  ];
  const allTabColumns = [
    ...columns.slice(0, indexPosition),
    {
      key: 'tireStatus',
      title: 'Status',
      render: row => <NAComponent val={row.status && row.tireStatus} />,
      size: 80,
      canSort: true,
    },
    ...columns.slice(indexPosition),
  ];
  const [activeColumns, setActiveColumns] = useState(visibleColumns);

  const closeFilterHandler = () => {
    setOpenFilterDrawer(false);
  };

  const filterCheckChangeHandler = (e, isCheck) => {
    const value = e.target.value;
    const isChecked = e.target.checked;
    const key = e.target.name;
    let obj;

    if (isCheck) {
      setSelectedFilters(prevSelectedFilters => {
        if (isChecked) {
          return [...prevSelectedFilters, value];
        } else {
          return prevSelectedFilters.filter(item => item !== value);
        }
      });
      obj = filterParameters;
      if (isChecked) {
        if (!!obj[key]) {
          obj[key] = [...obj[key], value];
        } else {
          obj[key] = [value];
        }
      } else {
        if (!!obj[key]) {
          if (obj[key]?.length > 1) {
            let temp = obj[key]?.filter(item => item !== value);
            obj[key] = temp;
          } else delete obj[key];
        }
      }
    }
    setFilterParameters(obj);
  };

  const searchedDataHandler = debounce(async () => {
    setLoading(true);
    try {
      const res = await getTireInventoryData(
        fleet_id,
        location,
        0,
        rowsPerPage,
        {
          search_string: searchQuery,
          ...(selectedFilters.length > 0 && {
            filter_values: payloadFilterValues,
          }),
        }
      );
      let dataExists = false;
      if (res.status === 200) {
        setCurrentPage(0);
        let count = res?.data?.resp.count;
        let tires = res?.data?.resp.tires;

        if (count) {
          tires = JSON.parse(JSON.stringify(tires)).map(_item => {
            if (_item?.estimated_wear_out_date) {
              _item['remaining_months'] = getRemainingMonths(
                _item.estimated_wear_out_date
              );
            } else {
              _item['remaining_months'] = 'N/A';
            }
            if (_item?.estimated_tread_depth === null) {
              _item['estimated_tread_depth'] = 'N/A';
            }
            _item.tireStatus = tireStatuses[_item.status];
            return _item;
          });
          const avgValues = calcAvgValues(tires);
          dispatch(
            setTireInventoryData(
              {
                originalData: tires,
                processedData: { avgValues },
                dataExists,
                res,
                count,
              },
              fleet_id
            )
          );
        } else {
          dispatch(
            setTireInventoryData(
              {
                originalData: [],
                processedData: {},
                dataExists,
                res,
                count: 0,
              },
              fleet_id
            )
          );
        }
        dispatch(setChangeInInventory(!currInventoryChangeValue));
      } else {
        dispatch(
          setTireInventoryData(
            {
              originalData: [],
              processedData: {},
              dataExists,
              res,
            },
            fleet_id
          )
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, 700);

  useEffect(() => {
    if (searchQuery) {
      searchedDataHandler();
    } else if (payloadFilterValues) {
      fetchPaginatedData();
    } else {
      getTires();
    }
    // eslint-disable-next-line
  }, [searchQuery, access_token]);

  useEffect(() => {
    return () => {
      searchedDataHandler.cancel();
    };
  }, [searchedDataHandler]);

  const filterApplyHandler = async filterValues => {
    try {
      if (
        (selectedFilters.length && Object.values(checkedFilters).length) ||
        searchQuery?.length > 0
      ) {
        setPayloadFilterValues(filterValues);
        const res = await getTireInventoryData(
          fleet_id,
          location,
          0,
          rowsPerPage,
          {
            filter_values: filterValues,
            ...(searchQuery?.length > 0 && { search_string: searchQuery }),
          }
        );

        let dataExists = false;
        if (res.status === 200) {
          let count = res?.data?.resp.count;
          let tires = res?.data?.resp.tires;
          dispatch(setChangeInInventory(!currInventoryChangeValue));
          setCurrentPage(0);
          if (count) {
            tires = JSON.parse(JSON.stringify(tires)).map(_item => {
              if (_item?.estimated_wear_out_date) {
                _item['remaining_months'] = getRemainingMonths(
                  _item.estimated_wear_out_date
                );
              } else {
                _item['remaining_months'] = 'N/A';
              }
              if (_item?.estimated_tread_depth === null) {
                _item['estimated_tread_depth'] = 'N/A';
              }
              _item.tireStatus = tireStatuses[_item.status];
              return _item;
            });
            const avgValues = calcAvgValues(tires);
            dispatch(
              setTireInventoryData(
                {
                  originalData: tires,
                  processedData: { avgValues },
                  dataExists,
                  res,
                  count,
                },
                fleet_id
              )
            );
          } else {
            dispatch(
              setTireInventoryData(
                {
                  originalData: [],
                  processedData: {},
                  dataExists,
                  res,
                  count: 0,
                },
                fleet_id
              )
            );
          }
        } else {
          dispatch(
            setTireInventoryData(
              {
                originalData: [],
                processedData: {},
                dataExists,
                res,
                count: 0,
              },
              fleet_id
            )
          );
        }
      } else {
        setPayloadFilterValues(null);
        getTires();
      }
    } catch (err) {
      console.error(err);
    }

    setFilterCounter(Object.keys(filterValues).length);
    setOpenFilterDrawer(false);
  };

  const filterSearchHandler = (event, filter) => {
    let val = event.target.value;
    const temp = JSON.parse(JSON.stringify(filterOptions));
    if (val.length > 1) {
      const hits = filter?.options?.filter(hit => {
        const stringWithoutSpaces = hit.replace(/\s+/g, '');
        return stringWithoutSpaces.toLowerCase().includes(val);
      });
      const index = temp?.findIndex(item => item.key === filter.key);
      temp[index].options = hits;
    }
  };

  const deleteSelectedFilterHandler = item => {
    setSelectedFilters(prevSelectedFilters => {
      return prevSelectedFilters.filter(clicked => clicked !== item);
    });
    let key = '';
    let obj = filterParameters;
    Object.entries(filterParameters)?.forEach(value => {
      if (value[1]?.includes(item)) {
        key = value[0];
      }
    });
    if (!!obj[key]) {
      if (obj[key]?.length > 1) {
        let temp = obj[key]?.filter(item_ => item_ !== item);
        obj[key] = temp;
      } else delete obj[key];
    }
  };

  const tabs = [
    {
      label: t('tireInventoryItems.statuses.IN_USE'),
      component: (
        <>
          <TopBar
            setOpenFilterDrawer={setOpenFilterDrawer}
            filterCounter={selectedFilters.length}
            download={downloadExcel}
            setSearchQuery={setSearchQuery}
            setRevealedColumns={setActiveColumns}
            columns={columns}
            activeColumns={activeColumns}
            location={location}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
          />

          <CustomTable
            columns={columns}
            data={tabData.IN_USE || []}
            revealedColumns={activeColumns.filter(item => item !== 'multi')}
            freq={getTireMetrics('in_use')}
            paginationHandler={fetchPaginatedData}
            filterCounter={filterCounter}
            searchQuery={searchQuery}
            loading={loading}
            total={tireDetails.IN_USE}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            isInitialRun={setIsInitial}
            currentPage={currentPage}
          />
        </>
      ),
      id: 'IN_USE',
      count: tireDetails.IN_USE,
    },
    {
      label: t('tireInventoryItems.statuses.STORAGE'),
      component: (
        <>
          <TopBar
            setOpenFilterDrawer={setOpenFilterDrawer}
            filterCounter={selectedFilters.length}
            download={downloadExcel}
            setSearchQuery={setSearchQuery}
            setRevealedColumns={setActiveColumns}
            columns={storageTabColumns}
            activeColumns={activeColumns}
            selectedTires={selectedTires}
            setSelectedTires={setSelectedTires}
            location={location}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
          />

          <CustomTable
            columns={storageTabColumns}
            data={tabData.STORAGE || []}
            revealedColumns={activeColumns}
            freq={
              getTireMetrics('storage') +
              getTireMetrics('store') +
              getTireMetrics('retread') +
              getTireMetrics('regroove') +
              getTireMetrics('refurbish')
            }
            paginationHandler={fetchPaginatedData}
            filterCounter={filterCounter}
            searchQuery={searchQuery}
            loading={!tabData.STORAGE || loading}
            total={tireDetails.STORAGE}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            isInitialRun={setIsInitial}
            currentPage={currentPage}
          />
        </>
      ),
      id: 'STORAGE',
      count: tireDetails.STORAGE,
    },
    {
      label: t('tireInventoryItems.statuses.RECYCLING_BANK'),
      component: (
        <>
          <TopBar
            setOpenFilterDrawer={setOpenFilterDrawer}
            filterCounter={selectedFilters.length}
            download={downloadExcel}
            setSearchQuery={setSearchQuery}
            setRevealedColumns={setActiveColumns}
            columns={recyclingTabColumns}
            activeColumns={activeColumns}
            selectedTires={selectedTires}
            setSelectedTires={setSelectedTires}
            location={location}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
          />
          <CustomTable
            columns={recyclingTabColumns}
            data={tabData.RECYCLING_BANK || []}
            revealedColumns={activeColumns}
            freq={
              getTireMetrics('repair_bank') +
              getTireMetrics('worn_bank') +
              getTireMetrics('warranty_bank') +
              getTireMetrics('smash_bank') +
              getTireMetrics('puncture_bank')
            }
            paginationHandler={fetchPaginatedData}
            filterCounter={filterCounter}
            searchQuery={searchQuery}
            loading={!tabData.RECYCLING_BANK || loading}
            total={tireDetails.RECYCLING_BANK}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            isInitialRun={setIsInitial}
            currentPage={currentPage}
          />
        </>
      ),
      id: 'RECYCLING_BANK',
      count: tireDetails.RECYCLING_BANK,
    },
    {
      label: t('tireInventoryItems.statuses.RETREAD'),
      component: (
        <>
          <TopBar
            setOpenFilterDrawer={setOpenFilterDrawer}
            filterCounter={selectedFilters.length}
            download={downloadExcel}
            setSearchQuery={setSearchQuery}
            setRevealedColumns={setActiveColumns}
            columns={notInUse}
            activeColumns={activeColumns}
            selectedTires={selectedTires}
            setSelectedTires={setSelectedTires}
            location={location}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
          />
          <CustomTable
            columns={notInUse}
            data={tabData.RETREADING || []}
            revealedColumns={activeColumns}
            freq={getTireMetrics('retreading')}
            paginationHandler={fetchPaginatedData}
            filterCounter={filterCounter}
            searchQuery={searchQuery}
            loading={!tabData.RETREADING || loading}
            total={tireDetails.RETREADING}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            isInitialRun={setIsInitial}
            currentPage={currentPage}
          />
        </>
      ),
      id: 'RETREADING',
      count: tireDetails.RETREADING,
    },
    {
      label: t('tireInventoryItems.statuses.repair'),
      component: (
        <>
          <TopBar
            setOpenFilterDrawer={setOpenFilterDrawer}
            filterCounter={selectedFilters.length}
            download={downloadExcel}
            setSearchQuery={setSearchQuery}
            setRevealedColumns={setActiveColumns}
            columns={notInUse}
            activeColumns={activeColumns}
            selectedTires={selectedTires}
            setSelectedTires={setSelectedTires}
            location={location}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
          />
          <CustomTable
            columns={notInUse}
            data={tabData.REPAIR || []}
            revealedColumns={activeColumns}
            freq={getTireMetrics('repair')}
            paginationHandler={fetchPaginatedData}
            filterCounter={filterCounter}
            loading={!tabData.REPAIR || loading}
            searchQuery={searchQuery}
            total={tireDetails.REPAIR}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            isInitialRun={setIsInitial}
            currentPage={currentPage}
          />
        </>
      ),
      id: 'REPAIR',
      count: tireDetails.REPAIR,
    },
    {
      label: t('tireInventoryItems.statuses.all'),
      component: (
        <>
          <TopBar
            setOpenFilterDrawer={setOpenFilterDrawer}
            filterCounter={selectedFilters.length}
            download={downloadExcel}
            setSearchQuery={setSearchQuery}
            setRevealedColumns={setActiveColumns}
            columns={allTabColumns}
            activeColumns={activeColumns}
            selectedTires={selectedTires}
            setSelectedTires={setSelectedTires}
            location={location}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
          />
          <CustomTable
            columns={allTabColumns}
            data={tabData.ALL || []}
            loading={loading}
            revealedColumns={activeColumns}
            freq={getTireMetrics('total_tires')}
            isLoading={!tabData.ALL || loading}
            paginationHandler={fetchPaginatedData}
            error={apiError}
            downloadParams={{
              handleDataFormatForExcel: handleDataFormatForExcel,
              sheetName: fleetName + '_tire_inventory_data_' + today + '.xlsx',
            }}
            filterCounter={filterCounter}
            searchQuery={searchQuery}
            total={tireDetails.ALL}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            isInitialRun={setIsInitial}
            currentPage={currentPage}
          />
        </>
      ),
      id: 'ALL',
      count: tireDetails.ALL,
    },
  ];

  const closeHandler = async () => {
    setOpen2(false);
    fetchPaginatedData();
    await updateTabCounts(access_token, dispatch);
  };

  const handleEscalateModalOpen = row => {
    setOpenEscalate(true);
    setRowSelected(row);
  };

  const handleEscalateModalClose = row => {
    setOpenEscalate(false);
    setRowSelected({});
  };

  const handleEscalateMessage = e => {
    setEscalateMessage(e.target.value);
  };

  const handleEscalaeSubmit = async e => {
    e.preventDefault();
    try {
      const res = await sendGeneralMail(
        SERVICE_MAIL_ID,
        `Escalate Mail- Tire Inventory - Customer id - ${userProfile.customer_id}`,
        `Escalate Message- ${escalateMessage} for cusomer id- ${
          userProfile.customer_id
        }.. Row details
        ${JSON.stringify(rowSelected)}
        `
      );
      if (res?.status === 200) {
        handleEscalateModalClose({});
        setEscalateMessage('');
        dispatch({
          type: NotificationsActionTypes.SET_NOTIFICATION,
          payload: {
            message: t('escalateMessage.successMessage'),
            type: 'success',
            show: true,
          },
        });
      }
    } catch (err) {
      dispatch(setNotification(t('common.error'), 'error', true));
    }
  };

  const editClickHandler = (fleetId, tire_) => {
    setOpenEdit(true);
    setItems({
      brand: tire_.brand,
      currentSize: tire_.size,
      line: tire_.product_line,
      treadDepth: tire_.last_tread_depth,
      retreadStatus: tire_.isRetread,
    });
    setUserTireId(tire_.tire_id);
    setId(tire_.system_tire_id);
    setFleetId(fleetId);
    setTireData(tire_);
  };

  const viewTireEventsHandler = async id => {
    setTireEventHistoryIsLoading(true);
    setTireEventHistoryIsOpen(true);
    try {
      const res = await fetchSingleTireEventHistory(id);
      let filter = res?.data.resp;
      setEventHistoryData(filter);
    } catch (error) {
      console.error('E20072', error);
    } finally {
      setTireEventHistoryIsLoading(false);
    }
  };

  const formatNumber = num => {
    if (num === 'N/A') {
      return null;
    }

    const formattedNumber = num?.toLocaleString('de-DE', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
      useGrouping: false,
    });

    return currentLanguage === 'de' ? formattedNumber : num;
  };

  const revertEventHandler = async () => {
    setIsReverting(true);

    try {
      const systemId = eventHistoryData?.[0]?.system_tire_id;
      const res = await revertEvent(systemId);

      if (res?.status === 200) {
        handleRevertSuccess();
        viewTireEventsHandler(systemId);
      } else {
        handleRevertError();
      }
    } catch (error) {
      console.error('E20085', error);
    } finally {
      setIsReverting(false);
    }
  };

  useEffect(() => {
    if (!isInitial) {
      fetchPaginatedData();
    }
    // eslint-disable-next-line
  }, [rowsPerPage, isInitial]);

  useEffect(() => {
    setTabData(current => {
      return {
        ...current,
        [location]: tireInventoryData?.[fleet_id]?.originalData,
      };
    });
    setTireDetails({
      ...tireDetails,
      [location]: tireInventoryData?.[fleet_id]?.count,
    });
    // eslint-disable-next-line
  }, [currInventoryChangeValue]);

  const handleRevertSuccess = async () => {
    dispatch(
      setNotification(t('eventHistoryModal.revertSuccess'), 'success', true)
    );
    setOpenRevertDialog(false);

    const data = await fetchTireInventoryDataAndProcess({
      fleet_id,
      inventoryData: fleetInventoryData,
      refetch: true,
      location,
      isPaginated: true,
      pageNo: currentPage,
      perpage: rowsPerPage,
    });
    dispatch(setTireInventoryData(data, fleet_id));
    dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
    dispatch(setChangeInInventory(!currInventoryChangeValue));
    await updateTabCounts(access_token, dispatch);
  };

  const handleRevertError = () => {
    dispatch(setNotification(t('common.error'), 'error', true));
  };

  const handleChange = async (event, newValue) => {
    setTabData(current => {
      return {
        ...current,
        [location]: [],
      };
    });
    setSelectedFilters([]);
    setSearchQuery(null);
    setLocation(location_map[newValue]);
    setValue(newValue);
    fetchPaginatedData(null, location_map[newValue]);
    // FetchData(location_map[newValue]);
    setTireDetails(tireTotals);
  };

  const renderTabsList = () => {
    if (tabs.length) {
      return tabs.map((item, i) => (
        <Tab
          id={item.id}
          className={classes.tab}
          label={
            <Badge
              badgeContent={item.count}
              color='primary'
              max={9999}
              showZero
            >
              <Typography variant='h6'>{item.label}</Typography>
            </Badge>
          }
          onClick={() => {
            setSelectedTires([]);
            setRowsPerPage(10);
          }}
          key={item.id}
          data-hook={item.id}
        />
      ));
    }
  };

  const renderTireEventHistoryModal = () => {
    if (tireEventHistoryIsOpen) {
      return (
        <TireEventHistoryModal
          data-hook='event-history-modal'
          open={tireEventHistoryIsOpen}
          setOpen={setTireEventHistoryIsOpen}
          isLoading={tireEventHistoryIsLoading}
          data={eventHistoryData}
          setData={setEventHistoryData}
          setIsLoading={setTireEventHistoryIsLoading}
          revertEventFn={revertEventHandler}
          isReverting={isReverting}
          setIsReverting={setIsReverting}
          open2={openRevertDialog}
          setOpen2={setOpenRevertDialog}
          isReporter={userIsReporter}
        />
      );
    }
  };

  const renderUpdateTireModal = () => {
    if (openEdit) {
      return (
        <UpdateTire
          data-hook='update-tires'
          current={items}
          tireId={userTireId}
          id={id}
          fleetId={fleetId}
          regrooveCheck={regrooveCheck}
          setRegrooveCheck={setRegrooveCheck}
          open={openEdit}
          setOpen={setOpenEdit}
          updateFromTd={true}
          tireData={tireData}
          tireInventoryAction={true}
          last_pressure={tireData.last_pressure}
          location={location}
          page={currentPage}
          perPage={rowsPerPage}
        />
      );
    }
  };

  const renderModifyTyreModal = () => {
    if (open2) {
      return (
        <ModifyTireModal
          data-hook='modify-tire-modal'
          open={open2}
          setOpen={setOpen2}
          presentTire={tireData}
          operation={'storage'}
          handleClose={closeHandler}
          location={location}
          perPage={rowsPerPage}
          currentPage={currentPage}
        />
      );
    }
  };

  const renderFilterDrawer = () => {
    if (openFilterDrawer) {
      return (
        <TireInventoryFIlterDrawer
          data-hook='filter-drawer'
          open={openFilterDrawer}
          closeHandler={closeFilterHandler}
          filters={filterOptions}
          selectedFilters={selectedFilters}
          filterParameters={filterParameters}
          dates={dates}
          setDates={setDates}
          regroovedCheck={regrooveCheck}
          setRegroveCheck={setRegrooveCheck}
          checkedFilters={checkedFilters}
          setCheckedFilters={setCheckedFilters}
          setSelectedFilters={setSelectedFilters}
          setFilterParameters={setFilterParameters}
          handleChange={filterCheckChangeHandler}
          apply={filterApplyHandler}
          deleteHandler={deleteSelectedFilterHandler}
          filterSearchHandler={filterSearchHandler}
          treadFilterValues={treadFilterValues}
          setTreadFilterValues={setTreadFilterValues}
        />
      );
    }
  };

  const renderToolTip = () => {
    if (showBtn?.found) {
      return (
        <Tooltip title={t('fleetsSection.tableauBtnInfo')}>
          <Link
            color='primary'
            to={`/tire-inventory/${fleet_id}/tableau`}
            style={{
              textDecoration: 'none',
              marginLeft: 10,
            }}
          >
            <Button
              color='primary'
              variant='outlined'
              sx={{
                textTransform: 'unset',
              }}
            >
              {t('fleetsSection.tableauBtnName')}
            </Button>
          </Link>
        </Tooltip>
      );
    }
  };

  const renderTabList = () => {
    if (tabs.length) {
      return tabs.map((item, i) => (
        <div hidden={value !== i} key={item.id}>
          {value === i && <Box mt={2}>{item.component}</Box>}
        </div>
      ));
    }
  };

  return (
    <>
      <LoadAnimation loading={isDownloading} />
      <Box
        display='flex'
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Breadcrumb customLastPath={fleetName} />
        {renderToolTip()}
      </Box>
      <Grid item xs={12} md={10} lg={12} xl={12}>
        <Box position='static' className={classes.appBar}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            scrollButtons='auto'
            aria-label='tire locations'
            data-hook='tabs'
          >
            {renderTabsList()}
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12} md={10} lg={12} xl={12}>
        {renderTabList()}
      </Grid>
      {renderTireEventHistoryModal()}

      <ManageTire
        data-hook='manage-tires'
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        id={id}
        tireInventoryAction={true}
        data={tireData}
        location={location}
      />
      {renderUpdateTireModal()}
      {renderModifyTyreModal()}
      <ReportTireIssueModal
        data-hook='report-tire-issue-modal'
        classes={classes}
        t={t}
        rowSelected={rowSelected}
        openEscalate={openEscalate}
        escalateMessage={escalateMessage}
        handleEscalateModalClose={handleEscalateModalClose}
        handleEscalateMessage={handleEscalateMessage}
        handleEscalaeSubmit={handleEscalaeSubmit}
      />
      {renderFilterDrawer()}
      <DeleteTire
        data-hook='delete-tire'
        open={openDeleteTire}
        setOpen={setOpenDeleteTire}
        id={id}
        tireInventoryAction={true}
        location={location}
        page={currentPage}
        perPage={rowsPerPage}
      />
    </>
  );
};

export const CustomizedTextField = ({ value, label }) => {
  return <CustomTextField value={value} label={label} disabled size='small' />;
};

export default FleetTireInventory;

export const WearOutInfo = ({ val }) => {
  const getColorCode = timePeriod => {
    if (timePeriod <= 6) {
      return 'red';
    } else if (timePeriod > 6 && timePeriod <= 12) {
      return 'yellow';
    } else if (timePeriod > 12) {
      return 'green';
    } else {
      return 'black';
    }
  };
  return <Chip label={val} style={{ color: getColorCode(val) }} />;
};

const TopBar = ({
  setOpenFilterDrawer,
  filterCounter,
  download,
  setSearchQuery,
  selectedTires,
  setSelectedTires,
  location,
  columns,
  setRevealedColumns,
  activeColumns,
  rowsPerPage,
  currentPage,
}) => {
  const [editColumnsIsOpen, setEditColumnsIsOpen] = useState(false);
  const shownColumns = columns.filter(column =>
    activeColumns.includes(column.key)
  );
  const hiddenColumns = columns.filter(
    column => !activeColumns.includes(column.key)
  );
  const { t } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const { classes } = useStyles();
  let dispatch = useDispatch();
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);
  const params = useParams();
  const { fleet_id } = params;
  const access_token = useSelector(setAccessTokenSelector);

  const handleClose = () => {
    setOpen(false);
  };

  const confirmBtnClickHandler = async e => {
    e.preventDefault();
    const payload = {
      system_tire_ids: selectedTires,
      event_type: 'DISPOSE',
      event_date: moment(eventDate).format('YYYY-MM-DD'),
    };

    try {
      const res = await bulkDelete(payload);
      if (res.status === 200) {
        dispatch(setNotification(t('common.success'), 'success', true));

        const data = await fetchTireInventoryDataAndProcess({
          fleet_id,
          inventoryData: fleetInventoryData,
          refetch: true,
          location,
          isPaginated: true,
          pageNo: currentPage,
          perpage: rowsPerPage,
        });
        dispatch(setTireInventoryData(data, fleet_id));
        dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
        setSelectedTires([]);
        await updateTabCounts(access_token, dispatch);
        handleClose();
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20106', error);
    }
  };

  const [items, setItems] = useState({
    list1: shownColumns,
    list2: hiddenColumns,
  });
  const toggleDrawer = newOpen => () => {
    setEditColumnsIsOpen(newOpen);
  };
  return (
    <>
      <Box
        width='100%'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
      >
        {' '}
        <SearchBar
          setOpen={setOpenFilterDrawer}
          filterCounter={filterCounter}
          setSearchQuery={setSearchQuery}
          hook={'inventory'}
        />
        <Box display='flex' alignItems='center' gap={'10px'}>
          {selectedTires?.length > 1 && (
            <Badge badgeContent={selectedTires.length} color='secondary'>
              <IconButton
                onClick={() => {
                  setOpen(true);
                }}
              >
                <DeleteForever color='secondary' />
              </IconButton>
            </Badge>
          )}
          <Tooltip title={t('common.download')}>
            <IconButton onClick={download}>
              <BsDownload
                size={'22px'}
                data-hook='bs-download-button'
                color={theme.palette.primary.main}
              />
            </IconButton>
          </Tooltip>
          <Button
            sx={{ textTransform: 'unset' }}
            onClick={() => setEditColumnsIsOpen(true)}
          >
            {t('columnsEdit.editBtn')}
          </Button>
          <ColumnsEditDrawer
            items={items}
            setItems={setItems}
            setActiveColumns={setRevealedColumns}
            editColumnsIsOpen={editColumnsIsOpen}
            toggleDrawer={toggleDrawer}
            setToggleDrawer={setEditColumnsIsOpen}
          />
        </Box>
      </Box>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        data-hook='delete-tires-modal'
      >
        <Fade in={open}>
          <Card className={classes.root}>
            <CardContent>
              <IconButton
                style={{ float: 'right' }}
                onClick={() => handleClose()}
                data-hook='close-modal'
              >
                <Close />
              </IconButton>
              <Typography variant='h5' align='center'>
                {t('common.tireInventory.disposeTires')}
              </Typography>
              <form onSubmit={confirmBtnClickHandler}>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label={'Event Date'}
                      value={eventDate}
                      onChange={date => {
                        setEventDate(date);
                      }}
                      format='yyyy.MM.dd'
                      sx={{ width: '100%' }}
                      data-hook='event-date-picker'
                    />
                  </LocalizationProvider>
                </Box>

                <Box width='100%' my={'1rem'}>
                  <Button
                    color='secondary'
                    type='submit'
                    variant='contained'
                    className={classes.btn}
                    fullWidth
                    data-hook='delete-button'
                  >
                    <Typography variant='h6' align='center'>
                      {t('deleteTire.delete')}
                    </Typography>
                  </Button>
                </Box>
              </form>
              <Box my='1rem'>
                <Typography variant='subtitle2' gutterBottom align='center'>
                  {t('deleteTire.warning2')}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </>
  );
};
const location_map = {
  0: 'IN_USE',
  1: 'STORAGE',
  2: 'RECYCLING_BANK',
  3: 'RETREADING',
  4: 'REPAIR',
  5: 'ALL',
};

async function updateTabCounts(access_token, dispatch) {
  const fleetResponse = await fleetOverview(access_token);
  dispatch(setFleetOverview(fleetResponse.data.resp));
}

import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getTireInventoryData } from 'services/fleet_service';
import { TRACKED_LS, VEHICLE } from 'utils/string_utils';
import {
  setTrackedVehiclesIdsSelector,
  setUnprocessedTireInventoryData,
  unprocessedTireInventoryDataSelector,
} from 'redux/fleets/ducks';
import { useDispatch } from 'react-redux';
import { getRemainingMonths } from 'pages/TireInventory/TireInventory';
import { ReactComponent as Steering } from 'assets/svg/axles/steering2.svg';
import { ReactComponent as Other } from 'assets/svg/axles/support.svg';
import { Box } from '@mui/material';
import { withStyles } from 'tss-react/mui';

const useKpis = (vehicleConfig = []) => {
  const trackedLs = TRACKED_LS;
  const { t } = useTranslation();
  const [hasTruck, setHasTruck] = useState(null);
  const [loadingInProgress, setLoadingInProgress] = useState(true);
  const [tireDataTransformedLoader, setTireDataTransformedLoader] =
    useState(true);
  const tracked = useSelector(setTrackedVehiclesIdsSelector) || trackedLs;
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);
  const dispatch = useDispatch();
  const iconConfig = {
    opacity: 0.6,
  };
  const tireInventoryDetails = useSelector(state => state.fleet.overviewData);
  const [configStatusChart, setConfigStatusChart] = useState({
    key: 'vehicle-overview',
    title: t('barChart.vehicleOverview'),
    labels: [
      t('common.configured'),
      t('common.partialConfig'),
      t('homePage.unconfigured'),
    ],
    datasets: [],
    chartType: {
      nature: 'pie',
      attr: 'none',
    },
  });
  const [trackedStatusChart, setTrackedStatusChart] = useState({
    key: 'tracked-overview',
    title: t('kpiList.telematics'),
    labels: ['Tracked', 'Untracked'],
    datasets: [],
    isTracked: false,
  });
  const [tireTypeChart, setTireTypeChart] = useState({
    key: 'tire',
    title: t('kpiList.size'),
    labels: [],
    datasets: null,
    chartType: {
      nature: 'pie',
      attr: 'none',
    },
    totalTiresInUse: null,
    totalTires: null,
  });
  const [tiresByDepth, setTiresByDepth] = useState({
    key: 'tire-depth',
    title: t('kpiList.tDepth'),
    labels: treadDepths,
    datasets: null,
    chartType: {
      nature: 'bar',
      attr: 'none',
    },
    tiresBetween3and5: [],
    tiresBelow3: [],
  });
  const [tiresProcessed, setTiresProcessed] = useState(false);
  const [outOfServiceGrouping, setOutOfServiceGrouping] = useState({
    key: 'out-of-service-date',
    lessThan6months: [],
    dueIn6To12months: [],
    moreThan12months: [],
    lessThan3months: [],
  });
  const [tiresByAxleType, setTiresByAxleType] = useState([]);

  const [brandsAndLinesChart, setBrandsAndLinesChart] = useState({
    key: 'tire-brands',
    title: t('kpiList.brandsAndLines'),
    labels: [],
    datasets: null,
    chartType: {
      nature: 'bar',
      attr: 'none',
    },
  });
  const [allTires, setAllTires] = useState(0);

  const vehicleAndTireOperations = useCallback(
    async (vehicles, fleets) => {
      setTireDataTransformedLoader(true);
      const tireSizes = [];
      const matches = [];
      const matches2 = [];
      const treadDepthsArr = [];
      const brandsAndLines = [];
      const x = [0, 2, 3, 5, 8, 10];
      let allTires_ = [];
      const hasTruck_ = vehicles?.some(
        vehicle => vehicle.type === VEHICLE.vehicle_type.TRUCK
      );
      setHasTruck(hasTruck_);
      let tracked_ = 0;
      let untracked_ = 0;
      let configured = 0;
      let partConfigured = 0;
      let unconfigured = 0;

      if (vehicles && vehicles.length) {
        for (const vehicle of vehicles) {
          if (tracked?.includes(vehicle.vehicle_id)) {
            tracked_ = tracked_ + 1;
          }
          if (!tracked?.includes(vehicle.vehicle_id)) {
            untracked_ = untracked_ + 1;
          }
          if (
            vehicle.axle_type !== null &&
            vehicle.mounted_tires?.every(item => item.position !== null)
          ) {
            configured = configured + 1;
          }
          if (
            vehicle.axle_type !== null &&
            vehicle.mounted_tires?.some(item => item.position === null)
          ) {
            partConfigured = partConfigured + 1;
          }
          if (vehicle.axle_type === null) {
            unconfigured = unconfigured + 1;
          }
        }
        setTrackedStatusChart({
          ...trackedStatusChart,
          datasets: [{ label: 'Data', data: [tracked_, untracked_] }],
        });

        const asset = [configured, partConfigured, unconfigured];
        setConfigStatusChart({
          ...configStatusChart,
          fullyConfigured: configured,
          datasets: [{ label: 'Data', data: asset }],
        });
      }

      for (const fleet of fleets) {
        try {
          let res = {};
          if (fleetInventoryData?.[fleet.fleet_id]) {
            res = fleetInventoryData[fleet.fleet_id];
          } else {
            res = await getTireInventoryData(
              fleet.fleet_id,
              'ALL',
              0,
              tireInventoryDetails.metrics[fleet.fleet_id].total_tires
            );
            dispatch(setUnprocessedTireInventoryData(res, fleet.fleet_id));
          }
          if (res?.status === 200) {
            const data = res?.data?.resp;
            allTires_ = data?.concat(allTires_);
            data?.forEach(tire => {
              if (!tireSizes.includes(tire.size)) {
                tireSizes.push(tire.size);
              }
              if (
                !brandsAndLines.includes(`${tire.brand} ${tire.product_line}`)
              ) {
                brandsAndLines.push(
                  `${tire.brand.trim()} ${tire.product_line.trim()}`
                );
              }
            });
          } else {
            throw new Error('No tires found!');
          }
        } catch (error) {
          console.error('E20075', error);
        }
      }

      const tiresInUse = allTires_.filter(tire => !!tire.vehicle_id);

      if (allTires_?.length) {
        for (const size of tireSizes) {
          const filteredTires = allTires_.filter(tire => tire.size === size);
          matches.push(filteredTires.length);
        }

        setTireTypeChart({
          ...tireTypeChart,
          labels: tireSizes,
          totalTiresInUse: tiresInUse.length,
          totalTires: allTires_.length,
          datasets: [{ label: 'Data', data: matches }],
        });

        let tiresBelow3 = tiresInUse.filter(tire => {
          const treadDepth = tire.estimated_tread_depth;
          return treadDepth !== null && treadDepth < 3;
        });
        let tiresBetween3and5 = tiresInUse.filter(tire => {
          const treadDepth = tire.estimated_tread_depth;
          return treadDepth !== null && treadDepth < 5 && treadDepth >= 3;
        });

        const lessThan6months = tiresInUse.filter(tire => {
          return getRemainingMonths(tire?.estimated_wear_out_date) < 6;
        });
        const dueIn6To12months = tiresInUse.filter(tire => {
          const months = getRemainingMonths(tire?.estimated_wear_out_date);
          return months >= 6 && months < 12;
        });
        const moreThan12months = tiresInUse.filter(tire => {
          const months = getRemainingMonths(tire?.estimated_wear_out_date);
          return months >= 12;
        });
        const lessThan3months = tiresInUse.filter(tire => {
          return getRemainingMonths(tire?.estimated_wear_out_date) < 3;
        });

        for (let i = 0; i < x.length; i++) {
          const filteredTires = allTires_.filter(tire => {
            if (i === x.length - 1) {
              return tire.last_tread_depth >= x[i];
            }
            return (
              tire.last_tread_depth >= x[i] && tire.last_tread_depth < x[i + 1]
            );
          });
          treadDepthsArr.push(filteredTires.length);
        }
        setTiresByDepth({
          ...tiresByDepth,
          tiresBetween3and5,
          tiresBelow3,
          datasets: [{ label: 'Data', data: treadDepthsArr }],
        });
        setTiresProcessed(true);
        setOutOfServiceGrouping({
          ...outOfServiceGrouping,
          moreThan12months,
          dueIn6To12months,
          lessThan6months,
          lessThan3months,
        });

        for (const entry of brandsAndLines) {
          const filteredTires = allTires_.filter(tire => {
            const regex = / (.+)/;
            const match = entry.match(regex);
            const result = match ? match[1] : '';

            return (
              tire.brand.trim() === entry.split(' ')[0].trim() &&
              tire.product_line.trim() === result
            );
          }).length;

          matches2.push(filteredTires);
        }
        setBrandsAndLinesChart({
          ...brandsAndLinesChart,
          labels: brandsAndLines,
          datasets: [{ label: 'Data', data: matches2 }],
        });
      } else {
        setTireTypeChart({
          ...tireTypeChart,
          totalTiresInUse: 0,
        });
        setTiresProcessed(true);
      }
      setLoadingInProgress(false);
      setTireDataTransformedLoader(false);
    },
    // eslint-disable-next-line
    [fleetInventoryData, vehicleConfig]
  );
  const getTiresByAxle = useCallback(
    inventoryData => {
      if (inventoryData !== null) {
        let outputData = [];
        Object.entries(inventoryData).forEach(item => {
          if (item[1] !== undefined) {
            outputData = outputData.concat(item[1].originalData);
          }
        });
        setAllTires(outputData.length);
        const steerTires = outputData.filter(tire => {
          return tire?.axle_type === 'STEER';
        });
        const driveTires = outputData.filter(tire => {
          return tire?.axle_type === 'DRIVE';
        });
        const trailerTires = outputData.filter(tire => {
          return tire?.axle_type === 'TRAILER';
        });

        setTiresByAxleType([
          {
            key: 'steer-tires',
            value: steerTires.length,
            text: t('common.tireInventory.steerTires'),
            icon: (
              <IconBox>
                <Steering {...iconConfig} />
              </IconBox>
            ),
          },
          {
            key: 'drive-tires',
            value: driveTires.length,
            text: t('common.tireInventory.driveTires'),
            icon: (
              <IconBox>
                <Other {...iconConfig} />
              </IconBox>
            ),
          },
          {
            key: 'trailer-tires',
            value: trailerTires.length,
            text: t('common.tireInventory.trailerTires'),
            icon: (
              <IconBox>
                <Other {...iconConfig} />
              </IconBox>
            ),
          },
        ]);
      }
    },
    // eslint-disable-next-line
    [t]
  );
  return {
    vehicleAndTireOperations,
    configStatusChart,
    tireTypeChart,
    tiresByDepth,
    trackedStatusChart,
    brandsAndLinesChart,
    hasTruck,
    loadingInProgress,
    tireDataTransformedLoader,
    outOfServiceGrouping,
    tiresByAxleType,
    getTiresByAxle,
    allTires,
    tiresProcessed,
  };
};

export const IconBox = withStyles(Box, {
  root: {
    height: 45,
    width: 45,
    display: 'flex',
    alignItems: 'center',
  },
});

export default useKpis;
const treadDepths = ['<2mm', '>2mm', '>3mm', '>5mm', '>8mm', '>10mm'];

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import CustomTextField from 'components/custom/CustomTextField';
import ActionButtons from './ActionButtons';
import { setAccessTokenSelector } from 'redux/users/ducks';
import {
  OnboardingActionTypes,
  setOnboardingSelector,
} from 'redux/onborading/ducks';
import { useTranslation } from 'react-i18next';

const Organization = () => {
  const dispatch = useDispatch();
  const access_token = useSelector(setAccessTokenSelector);
  const organizationProfile = useSelector(
    setOnboardingSelector
  )?.organizationProfile;
  const organizationData = organizationProfile?.['organization_data'];
  const [state, setState] = useState({});
  const { t } = useTranslation();
  const [initialState, setInitialState] = useState({
    name: '',
    noOfYards: '',
    zipCode: '',
    city: '',
    country: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setIsSubmitting(false);
  }, [state]);

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleClear = () => {
    setState({ ...initialState });
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);
    const payload = {
      name: state['name'],
      no_of_yards: state['noOfYards'],
      city: state['city'],
      country: state['country'],
      zip_code: state['zipCode'],
      update_history: organizationData[''],
    };
    dispatch({
      type: OnboardingActionTypes.ORGANIZATION_PROFILE.UPDATE_ORGANIZATION_DATA,
      access_token: access_token,
      payload: payload,
    });
  };
  const commonProps = { required: false, onChange: handleChange };
  useEffect(() => {
    if (organizationData) {
      const _initialData = {
        name: organizationData['name'],
        noOfYards: organizationData['no_of_yards'],
        zipCode: organizationData['zip_code'],
        city: organizationData['city'],
        country: organizationData['country'],
      };
      setInitialState({ ..._initialData });
      setState({ ..._initialData });
    }
  }, [organizationData]);

  if (!organizationData) {
    return <></>;
  }

  return (
    <>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} lg={6}>
          <CustomTextField
            value={state['name']}
            label={t('customerSettingsPage.organization.name')}
            {...commonProps}
            name='name'
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomTextField
            value={state['noOfYards']}
            label={t('customerSettingsPage.organization.noOfYards')}
            {...commonProps}
            type='number'
            name='noOfYards'
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            value={state['zipCode']}
            label={t('customerSettingsPage.organization.zipcode')}
            {...commonProps}
            type='number'
            name='zipCode'
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            value={state['city']}
            label={t('customerSettingsPage.organization.city')}
            {...commonProps}
            name='city'
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <CustomTextField
            value={state['country']}
            label={t('customerSettingsPage.organization.country')}
            {...commonProps}
            name='country'
          />
        </Grid>
      </Grid>
      <ActionButtons
        handleUpdate={handleUpdate}
        handleClear={handleClear}
        disabled={
          !(
            state['name'] &&
            state['noOfYards'] &&
            state['country'] &&
            state['city'] &&
            state['zipCode']
          ) || isSubmitting
        }
        isSubmitting={isSubmitting}
      />
    </>
  );
};

export default Organization;

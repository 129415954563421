import React from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { fleetOverviewSelector } from 'redux/fleets/ducks';
import { useSelector } from 'react-redux';
import { EDIT, TIRES } from 'utils/string_utils';
import { tiresDbSelector } from 'redux/tireInventory/ducks';
import CustomTextField from 'components/custom/CustomTextField';
import { makeStyles } from 'tss-react/mui';
import { Close } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
const useStyles = makeStyles()(theme => {
  return {
    others: {
      width: 300,
      // margin: '0.5rem 1rem',
    },
    moreBtn: {
      margin: '0.15rem 0.15rem',
      textTransform: 'unset',
    },
    linesGrid: {
      maxHeight: 200,
      overflowY: 'scroll',
    },
    objectFit: {
      objectFit: 'contain',
    },
    btn: {
      textTransform: 'unset',
      borderRadius: 24,
      cursor: 'pointer',
      // color: theme.palette.primary.main,
    },
    retread: {
      border: '1px solid grey',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.8),
      opacity: 0.8,
    },
  };
});

const MultipleTireAddForm = props => {
  const {
    productLine,
    tireBrand,
    setTireBrand,
    size,
    setSize,
    error,
    handleClose,
    setProductLine,
    handleChange,

    submitFormHandler,
    payload,
    setPayload,
    axleName,
    // eventDate,
    // setDate,
    caller,
    grooves,
    setGrooves,
    checked = false,
    handleChangeSwitch,
    handleGrooveTD = null,
    setGrooveChanged = null,
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const overviewData = useSelector(fleetOverviewSelector);
  const tiresResource = useSelector(tiresDbSelector);
  const sorted = tiresResource?.sorted?.[axleName]?.[size];
  const availableSizes = tiresResource?.sizes;
  const resource = sorted?.[tireBrand];
  const defaultTreadDepth = sorted?.[tireBrand]?.[productLine];
  const condition_edit = false;
  const theme = useTheme();

  const condition_add =
    !productLine ||
    !tireBrand ||
    !size ||
    error[TIRES.TIRE_ID] !== null ||
    error[TIRES.TREAD_DEPTH] !== null;

  const tiresToShow = entry => {
    const capitalizeBrand = brand =>
      brand === 'KUMHO' ? 'KumhoTire' : capitalize(brand);

    if (sorted) {
      const preferredBrands = [
        overviewData?.tire_management_data['preferred_tire_brand_1'],
        overviewData?.tire_management_data['preferred_tire_brand_2'],
      ].map(brand => capitalizeBrand(brand));

      return Object.keys(sorted).filter(value =>
        entry === 1
          ? preferredBrands.includes(capitalizeBrand(value))
          : !preferredBrands.includes(capitalizeBrand(value))
      );
    } else {
      return [];
    }
  };

  const [showAltBrandField, setShowAltBrandField] = React.useState(false);

  React.useEffect(() => {
    const updatedPayload = {
      ...payload,
      last_tread_depth: defaultTreadDepth?.td ?? '',
    };
    setPayload(updatedPayload);
    // eslint-disable-next-line
  }, [defaultTreadDepth]);

  const revert = () => {
    setTireBrand(null);
    setProductLine(null);
  };

  const renderTireBrandsList = () => {
    return Object.keys(resource)?.map((item, i) => (
      <Button
        key={i}
        variant='contained'
        color='primary'
        disableElevation
        style={{
          margin: '0.15rem 0.15rem',

          textTransform: 'unset',
          opacity: productLine !== item && productLine !== null ? 0.5 : 1,
          backgroundColor:
            Object.keys(resource).length === 1 &&
            productLine !== item &&
            'rgba(0, 0, 0, 0.12)',
        }}
        data-hook='set-product-line'
        onClick={() => {
          if (productLine === item) {
            setProductLine(null);
          } else {
            setProductLine(item);
          }
        }}
        disabled={productLine !== item && productLine !== null ? true : false}
      >
        <Box className={classes.objectFit}>{item}</Box>
      </Button>
    ));
  };

  const renderTireBrands = () => {
    if (tireBrand !== null) {
      return (
        <Box my='1rem' className={classes.linesGrid}>
          <Typography variant='h5' gutterBottom align='left'>
            {t('common.tireInventory.productLines')} :
          </Typography>
          <Box
            display='flex'
            flexDirection='row'
            flexWrap='wrap'
            alignItems='center'
            data-hook='product-lines'
          >
            {renderTireBrandsList()}
            <Box></Box>
          </Box>
        </Box>
      );
    }
  };

  return (
    <div>
      <form onSubmit={submitFormHandler}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              width='100%'
              marginBottom={2}
              gap={'20px'}
            >
              <Box
                flex={6}
                display='flex'
                flexDirection='column'
                position='relative'
              >
                <CustomTextField
                  required
                  name='no_of_tires'
                  id='no_of_tires'
                  type='number'
                  label={t('common.tireInventory.no_of_tires')}
                  onChange={handleChange}
                  defaultValue={payload.no_of_tires}
                  variant='outlined'
                  error={error[TIRES.TIRE_ID] !== null}
                  InputProps={{
                    inputProps: {
                      min: 1,
                    },
                  }}
                  data-hook='no-of-tires'
                />
                <Box position='absolute' bottom={-30} color='red'>
                  {error[TIRES.TIRE_ID]}
                </Box>
              </Box>

              {caller === 'inventory' && (
                <Autocomplete
                  id='tire-size-auto-complete'
                  options={availableSizes || []}
                  getOptionLabel={option => option}
                  style={{
                    flex: 6,
                  }}
                  value={size}
                  data-hook='set-size'
                  onChange={(e, value) => {
                    if (e.target.value !== undefined) {
                      if (size === value) {
                        setSize(null);
                      } else {
                        setSize(value);
                      }
                    } else {
                      setSize(null);
                    }
                    setProductLine(null);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label={t('common.tireInventory.size')}
                      variant='outlined'
                    />
                  )}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <Typography variant='h5' gutterBottom align='left'>
                {t('common.tireInventory.tireBrand')} :
              </Typography>
              {size && (
                <Box>
                  {tiresToShow(1)?.length > 0 || tiresToShow(2)?.length > 0 ? (
                    <Box
                      display='flex'
                      flexDirection='row'
                      flexWrap='wrap'
                      width='100%'
                      gap={'10px'}
                    >
                      {tiresToShow(1)?.map((item, i) => (
                        <Button
                          key={i}
                          variant='outlined'
                          color='primary'
                          data-hook='set-tire-brand'
                          disableElevation
                          style={{
                            // margin: '0.15rem 0.15rem',
                            textTransform: 'unset',
                            opacity:
                              tireBrand !== item && tireBrand !== null
                                ? 0.5
                                : 1,
                            height: 55,
                          }}
                          onClick={() => {
                            if (tireBrand === item) {
                              revert();
                            } else {
                              setTireBrand(item);
                            }
                          }}
                          disabled={
                            tireBrand !== item && tireBrand !== null
                              ? true
                              : false
                          }
                        >
                          <Box className={classes.objectFit}>
                            <img
                              src={require(`../../assets/images/brands/${
                                item === 'KUMHO'
                                  ? 'kumhotire'
                                  : item.toLowerCase()
                              }.png`)}
                              style={{ width: 175 }}
                              alt={item || 'brand'}
                            />
                          </Box>
                        </Button>
                      ))}
                      {tiresToShow(2)?.length > 0 && (
                        <Box>
                          <Autocomplete
                            id='combo-box-demo'
                            className={classes.others}
                            options={tiresToShow(2).sort()}
                            disabled={
                              tireBrand !== null &&
                              tiresToShow(1).includes(tireBrand)
                            }
                            getOptionLabel={option => option}
                            value={
                              tiresToShow(2)?.findIndex(
                                item => item === tireBrand
                              ) !== -1
                                ? tireBrand
                                : ''
                            }
                            data-hook='is-retread'
                            onChange={(e, value) => {
                              if (e.target.value !== undefined) {
                                if (tireBrand === value) {
                                  revert();
                                } else {
                                  setTireBrand(value);
                                  setProductLine(null);
                                }
                              } else {
                                revert();
                              }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                label={
                                  tiresToShow(1)?.length
                                    ? t('tireForm.others')
                                    : t('tireForm.brands')
                                }
                                data-hook='brands'
                                variant='outlined'
                              />
                            )}
                          />
                        </Box>
                      )}
                    </Box>
                  ) : (
                    <Box color={'tomato'}>
                      No brands/product lines present for this tire size. Please
                      enter them manually below
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            {renderTireBrands()}
          </Grid>
          <>
            {/* <Grid item xs={12}>
              {rednerShowAltBrands()}
            </Grid> */}
            {/* <Grid item xs={12}>
              {showAltBrandField && (
                <CustomTextField
                  name='product_line'
                  id='product_line'
                  label={t('tiresUpload.productLine')}
                  onChange={e => {
                    setProductLine(e.target.value.toUpperCase());
                    if (e.target.value === '') {
                      setProductLine(null);
                    }
                  }}
                  data-hook='upload-line'
                  variant='outlined'
                  disabled={tireBrand === null}
                  type='text'
                  fullWidth
                />
              )}
            </Grid> */}
          </>
          <Grid item xs={6} position='relative'>
            <Box
              flex={6}
              display='flex'
              flexDirection='column'
              position='relative'
              marginBottom={1}
            >
              <CustomTextField
                name='last_tread_depth'
                required
                id='last_tread_depth'
                label={t('common.tireInventory.depth')}
                onChange={handleChange}
                variant='outlined'
                value={payload.last_tread_depth}
                type='number'
                fullWidth
                data-hook='last-tread-depth'
                error={error[TIRES.TREAD_DEPTH] !== null}
                InputProps={{
                  inputProps: {
                    min: 0,
                    max: 20,
                    step: 'any',
                  },
                }}
              />
              <Box position='absolute' bottom={-30} color='red'>
                {error[TIRES.TREAD_DEPTH]}
              </Box>
            </Box>
            {defaultTreadDepth?.td && (
              <Tooltip
                title={t(`treadDepthSource.${defaultTreadDepth?.td_source}`)}
                placement='top'
                sx={{ cursor: 'pointer' }}
              >
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: '#CCCCCC',
                    borderRadius: '50%',
                    width: '25px',
                    height: '25px',
                    fontSize: '12px',
                    color: '#000',
                    position: 'absolute',
                    right: 5,
                    top: '45%',
                  }}
                >
                  i
                </Box>
              </Tooltip>
            )}
          </Grid>

          <Grid item xs={6}>
            <FormGroup className={classes.retread}>
              <FormControlLabel
                control={
                  <Checkbox
                    data-hook='is-retread'
                    checked={payload.isRetread}
                    name='isRetread'
                    color='primary'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={t('level1Modal.retreaded')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              name='tire_pressure'
              // required
              id='tire_pressure'
              label={t('tireInventoryItems.titles.pressure')}
              onChange={handleChange}
              variant='outlined'
              value={payload?.tire_pressure}
              type='number'
              InputProps={{
                inputProps: {
                  min: 5,
                  max: 15,
                  step: 0.1,
                },
              }}
              fullWidth
              error={error['tire_pressure'] !== null}
              helperText={error['tire_pressure']}
            />
          </Grid>
          <Grid item xs={6}>
            <FormGroup className={classes.retread}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={payload.isRegrooved}
                    name='isRegrooved'
                    color='primary'
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                }
                label={t('level1Modal.regrooved')}
              />
            </FormGroup>
          </Grid>

          <Box
            position={'relative'}
            width={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={'30px'}
            marginTop={'20px'}
          >
            <Grid item xs={12} width={'95%'}>
              {!showAltBrandField ? (
                <Box
                  display='flex'
                  alignItems='center'
                  gap={'10px'}
                  marginLeft={'24px'}
                >
                  <Typography align='left'>
                    {t('tiresUpload.brandMissing')}
                  </Typography>
                  <span
                    className={classes.btn}
                    style={{ color: theme.palette.primary.main }}
                    data-hook='manual-entry-btn'
                    onClick={() => setShowAltBrandField(true)}
                  >
                    {t('tiresUpload.enterBtn')}
                  </span>
                </Box>
              ) : (
                <Box marginLeft={'24px'} width={'100%'}>
                  <CustomTextField
                    name='tire_brand'
                    required
                    id='tire_brand'
                    label={t('tiresUpload.brand')}
                    onChange={e => {
                      setTireBrand(e.target.value.toUpperCase());
                      if (e.target.value === '') {
                        setTireBrand(null);
                      }
                    }}
                    variant='outlined'
                    type='text'
                    fullWidth
                    data-hook='tire_brand_alt'
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={12} width={'95%'} marginLeft={'24px'}>
              {showAltBrandField && (
                <CustomTextField
                  name='product_line'
                  id='product_line'
                  label={t('tiresUpload.productLine')}
                  onChange={e => {
                    setProductLine(e.target.value.toUpperCase());
                    if (e.target.value === '') {
                      setProductLine(null);
                    }
                  }}
                  variant='outlined'
                  disabled={tireBrand === null}
                  type='text'
                  fullWidth
                  data-hook='product_line_alt'
                />
              )}
            </Grid>
            <IconButton
              sx={{
                position: 'absolute',
                right: 0,
                top: '36%',
                height: '50px',
                width: '50px',
                alignSelf: 'flex-end',
                display: showAltBrandField ? 'block' : 'none',
              }}
              data-hook='hide-manual-entry-fields'
              onClick={() => {
                setShowAltBrandField(false);
                revert();
              }}
            >
              <Close />
            </IconButton>
          </Box>
          {caller !== EDIT && (
            <Grid item xs={12}>
              <Box
                width='100%'
                display='flex'
                flexDirection={'row'}
                // justifyContent={'center'}
                alignItems={'center'}
              >
                <Typography variant='h6' align='left'>
                  Do you want to add groove tread depth ?
                </Typography>
                <Switch
                  checked={checked}
                  onChange={handleChangeSwitch}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                {checked && (
                  <Tooltip title='Add Groove'>
                    <IconButton
                      aria-label='delete'
                      disabled={Object.keys(grooves).length === 6}
                      size='large'
                      color='primary'
                      onClick={() =>
                        setGrooves({
                          ...grooves,
                          [Object.keys(grooves).length + 1]: null,
                        })
                      }
                    >
                      <AddCircleOutlineIcon fontSize='large' />
                    </IconButton>
                  </Tooltip>
                )}
                {checked && Object.keys(grooves).length === 6 && (
                  <Typography variant='body1' align='left' color='secondary'>
                    ( Max Groove Limit : 6 )
                  </Typography>
                )}
              </Box>
            </Grid>
          )}
          {caller !== EDIT && checked && (
            <Grid item xs={12}>
              <Box
                width='60%'
                display='flex'
                flexDirection={'row'}
                // justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid container spacing={2}>
                  {Object.keys(grooves).map((item, i) => (
                    <Grid item xs={4} key={i}>
                      <Box
                        display='flex'
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        <TextField
                          style={{ width: 150 }}
                          id='outlined-basic'
                          label={`G-${item}`}
                          inputProps={{
                            step: 0.1,
                            min: 0,
                            max: 20,
                          }}
                          value={grooves[item]}
                          onChange={e =>
                            setGrooves({
                              ...grooves,
                              [item]: parseFloat(e.target.value),
                            })
                          }
                          variant='outlined'
                          type='number'
                          size='small'
                        />
                        {i === Object.keys(grooves).length - 1 && i !== 0 && (
                          <Tooltip title='Remove Groove'>
                            <IconButton
                              aria-label='delete'
                              size='small'
                              color='secondary'
                              onClick={() => {
                                let temp = JSON.parse(JSON.stringify(grooves));
                                delete temp[Object.keys(grooves).length];
                                setGrooves(temp);
                              }}
                            >
                              <DeleteOutlineIcon fontSize='medium' />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          )}
          {caller === EDIT && payload.grooves_tread_depth && (
            <Grid item xs={12}>
              <Typography
                variant='h6'
                data-hook='groove-tread-depth'
                gutterBottom
                align='left'
              >
                Grooves Tread Depth
              </Typography>
              <Grid container spacing={3} sx={{ marginTop: '0.25rem' }}>
                {Object.keys(payload.grooves_tread_depth)
                  .slice(0, -1)
                  .map((item, i) => (
                    <Grid item xs={4} key={i}>
                      <Box
                        display='flex'
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        <TextField
                          style={{ width: 150 }}
                          id='outlined-basic'
                          label={`G-${item}`}
                          inputProps={{
                            step: 0.1,
                            min: 0,
                            max: 20,
                          }}
                          value={payload.grooves_tread_depth[item]}
                          onChange={e => {
                            setGrooveChanged(true);
                            handleGrooveTD(item, e.target.value);
                          }}
                          variant='outlined'
                          type='number'
                          size='small'
                        />
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Box display='flex' justifyContent='center' marginTop='2rem'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.btn}
            disabled={caller === EDIT ? condition_edit : condition_add}
            disableElevation
            data-hook='single-upload'
            data-cy='finishOperation-btn'
          >
            {t('singleVehicleUpload.add')}
          </Button>
          {caller !== 'inventory' && (
            <Button
              className={classes.btn}
              variant='outlined'
              color='primary'
              disableElevation
              data-hook='cancel-button'
              onClick={() => handleClose()}
            >
              {t('singleVehicleUpload.cancel')}
            </Button>
          )}
        </Box>
      </form>
    </div>
  );
};

export default MultipleTireAddForm;

const capitalize = value => {
  return value.charAt(0) + value.slice(1).toLowerCase();
};

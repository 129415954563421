import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import App from './App';
import ErrorBoundaryWrapper from './components/ErrorBoundaryWrapper';

import reportWebVitals from './reportWebVitals';
import { default_theme, korne_theme, best_drive_theme } from './styles/theme';
import store from './redux/store';
import './language/i18n';
import './styles/index.css';
import { SentryErrorBoundryWrapper } from './wrapper/SentryErrorWrapper';

Sentry.init({
  environment: process.env.REACT_APP_ENV || 'dev',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [`${process.env.REACT_APP_URL}`],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const theme_map = {
  default: default_theme,
  krone: korne_theme,
  bestDrive: best_drive_theme,
};

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<></>}>
    <ThemeProvider
      theme={
        theme_map[process.env.REACT_APP_THEME] !== undefined
          ? Object.keys(theme_map).includes(process.env.REACT_APP_THEME)
            ? theme_map[process.env.REACT_APP_THEME]
            : default_theme
          : default_theme
      }
    >
      <Provider store={store}>
        <CssBaseline enableColorScheme />
        <ErrorBoundaryWrapper
          component={
            <SentryErrorBoundryWrapper>
              <App />
            </SentryErrorBoundryWrapper>
          }
        />
      </Provider>
    </ThemeProvider>
  </Suspense>
);

reportWebVitals();

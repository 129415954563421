import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  getOverviewDetails,
  getVehicleOverviewDetails,
} from 'services/fleet_service';
import {
  FleetActionTypes,
  setLoadStatus,
  setOverviewDetails,
  setSearchResults,
  setSearchStatus,
  setTireOverview,
  setTires,
  setVehicleOverview,
  setVehicles,
} from './ducks';

function* getVehicleOverview() {
  try {
    const overviewDetails = yield call(getVehicleOverviewDetails);
    yield put(setOverviewDetails(overviewDetails));
  } catch (error) {
    // Handle error if necessary
  }
}

function* fetchOverviewDetailsSaga(action) {
  try {
    if (!action.payload.search_string) {
      yield put(setLoadStatus(true));
    } else {
      yield put(setSearchStatus(true));
    }

    const res = yield call(getOverviewDetails, {
      accessing: action.payload.accessing,
      tire_location: action.payload.tire_location,
      vehicle_location: action.payload.vehicle_location,
      page_no: action.payload.page_no,
      search_string: action.payload.search_string,
      per_page: action.payload.per_page,
    });

    if (res.status === 200) {
      if (!action.payload.search_string) {
        const resources = res.data.resources;
        yield put(setTireOverview(resources.tire_overview));
        yield put(setVehicleOverview(resources.vehicle_overview));
        if (resources?.tires) {
          yield put(setTires(resources.tires));
        }
        if (resources?.vehicles) {
          yield put(setVehicles(resources.vehicles));
        }
      } else {
        yield put(setSearchResults(res?.data?.resources));
      }
    }
  } catch (error) {
    console.error('E20114', error);
  } finally {
    yield put(setLoadStatus(false));
    yield put(setSearchStatus(false));
  }
}

export function* fleetsSaga() {
  yield takeEvery('GET_VEHICLE_OVERVIEW', getVehicleOverview);
  yield takeLatest(
    FleetActionTypes.FETCH_OVERVIEW_DETAILS,
    fetchOverviewDetailsSaga
  );
}

/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import { useSelector } from 'react-redux';
import { setUserSelector } from 'redux/users/ducks';
import { USER_LS, USER_ROLES } from 'utils/string_utils';
import { Autocomplete } from '@mui/material';

export default function Tags(props) {
  const userLs = JSON.parse(USER_LS);
  const userDetails = useSelector(setUserSelector) || userLs;
  const userIsAReporter = userDetails?.iam_role === USER_ROLES.reporter;

  const handleChange = (e, val) => {
    props.onChange(val, props.name);
  };

  const displayOption = option => {
    if (typeof option === 'string') return option;
    if (typeof option === 'object' && !Array.isArray(option))
      return option[props.optionLabelKey] || option.name;
    if (typeof option === 'number') return option;
    return 'NaN';
  };

  const disableOptions = option => {
    if (props.maxOptionSelectionAllowed) {
      if (props.value.length === props.maxOptionSelectionAllowed) return true;
    }
  };

  return (
    <Autocomplete
      multiple={props?.multiple}
      fullWidth
      limitTags={props?.limitTags}
      options={props?.options?.map(option => displayOption(option))}
      value={props?.value || null}
      getOptionDisabled={option => disableOptions(option)}
      onChange={handleChange}
      disabled={userIsAReporter}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant='outlined'
            style={{ fontSize: 24 }}
            label={option}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={params => (
        <TextField
          {...params}
          variant='outlined'
          label={props?.label}
          InputProps={{
            ...params.InputProps,
            style: { fontSize: 24 },
          }}
          InputLabelProps={{ style: { fontSize: props?.fontSize || 24 } }}
        />
      )}
    />
  );
}

import React, { useState } from 'react';
import * as XLSX from 'xlsx';

import {
  Box,
  Button,
  Chip,
  LinearProgress,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import { parse } from 'json2csv';
import { useTranslation } from 'react-i18next';
import {
  fetchFleets,
  fleetUpdatedSelector,
  setChangeInFleet,
} from 'redux/fleets/ducks';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Downloads from 'components/Downloads';
import { makeStyles, withStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: 'fit-content',
      height: '90%',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      padding: '2rem 3rem',
    },
    table: {
      minWidth: 1050,
    },
  };
});

const BorderLinearProgress = withStyles(LinearProgress, (theme, props) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
  btn: {
    textTransform: 'unset',
    margin: 'auto 1rem',
  },
}));
const StyledTableCell = withStyles(TableCell, (theme, props) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 18,
  },
  body: {
    fontSize: 22,
  },
}));

const UploadSummary = ({
  open,
  setOpen,
  rows,
  progress,
  handleOnRemoveFile,
  successful,
  closeHandler,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const fleetTracker = useSelector(fleetUpdatedSelector);
  const dispatch = useDispatch();

  const handleDownload = fileType => {
    const fields = [
      'fleet_name',
      'vehicle_name',
      'upload_status',
      'upload_result',
    ];
    const opts = { fields };
    if (fileType === 'xlsx') {
      const worksheet = XLSX.utils.json_to_sheet(rows);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Summary');
      XLSX.writeFile(workbook, `Upload Summary.xlsx`);
    } else {
      try {
        const csv = parse(rows, opts);
        var blob = new Blob([csv], { type: 'text/csv' });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'Upload Summary.csv';
        a.click();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleClose = () => {
    if (progress === 100 && successful > 0) {
      setOpen(false);
      dispatch(setChangeInFleet(!fleetTracker));
      dispatch(fetchFleets());
    } else {
      setOpen(false);
    }

    handleOnRemoveFile();
    closeHandler();
  };

  const renderTableRows = () => {
    return rows.map(row => (
      <TableRow key={row.name}>
        <StyledTableCell component='th' scope='row'>
          {row.fleet_name}
        </StyledTableCell>
        <StyledTableCell align='right'>{row.vehicle_name}</StyledTableCell>
        <StyledTableCell align='right'>
          {row.upload_status === null ? (
            'Processing...'
          ) : (
            <Chip
              label={row.upload_status}
              clickable
              color={row.upload_status === 'success' ? 'primary' : 'secondary'}
              deleteIcon={
                row.status === 'success' ? <DoneIcon /> : <CancelIcon />
              }
            />
          )}
        </StyledTableCell>
        <TableCell align='right'>
          {row.upload_result === null ? 'Fetching...' : row.upload_result}
        </TableCell>
      </TableRow>
    ));
  };

  const renderTable = () => {
    if (rows) {
      return (
        <>
          <TableContainer component={Paper} style={{ maxHeight: 500 }}>
            <Table
              stickyHeader
              className={classes.table}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {' '}
                    {t('uploadSummary.fleetName')}
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    {t('uploadSummary.vehicleName')}
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    {t('uploadSummary.status')}&nbsp;
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    {t('uploadSummary.result')}&nbsp;
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{renderTableRows()}</TableBody>
            </Table>
          </TableContainer>
          <Box
            width='100%'
            display='flex'
            flexDirection={'row'}
            justifyContent='center'
            alignItems={'center'}
            gap={'10px'}
            my='1rem'
          >
            <Button
              disableElevation
              variant='contained'
              color='primary'
              onClick={() => setDialogOpen(true)}
              className={classes.btn}
              data-hook='open-summary-modal-btn'
            >
              {t('uploadSummary.downloadSummary')}
            </Button>
            <Button
              disableElevation
              variant='outlined'
              color='primary'
              onClick={handleClose}
              className={classes.btn}
              data-hook='open-upload-summary-modal-btn'
            >
              {t('uploadSummary.back')}
            </Button>
          </Box>
          <Downloads
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            handleDownload={handleDownload}
          />
        </>
      );
    }
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Paper className={classes.paper}>
        <Typography variant='h5' gutterBottom align='left'>
          {t('uploadSummary.vehicleUploadSummary')}
        </Typography>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          my='2rem'
        >
          <Box width='100%' flexGrow={1} mr='0.5rem'>
            <BorderLinearProgress variant='determinate' value={progress} />
          </Box>

          <Typography variant='body1' color='textSecondary'>{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
        {renderTable()}
      </Paper>
    </Modal>
  );
};

export default UploadSummary;

UploadSummary.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  rows: PropTypes.array,
  progress: PropTypes.number,
  handleOnRemoveFile: PropTypes.func,
  successful: PropTypes.any,
  closePopover: PropTypes.func,
};

import React from 'react';
import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import { IconAndCount } from 'pages/Home/FleetsFoundUI';
import { useTranslation } from 'react-i18next';
import { BsTruckFlatbed } from 'react-icons/bs';
import { VehicleOverviewCount } from './VehicleOverviewCount';

import { ReactComponent as Merged } from 'assets/svg/home/merged_home.svg';
import { ReactComponent as Truck } from 'assets/svg/home/truck_home.svg';
import { ReactComponent as Trailer } from 'assets/svg/home/trailer.svg';
import { ReactComponent as Configured } from 'assets/svg/home/config.svg';
import { ReactComponent as Unconfigured } from 'assets/svg/home/not_config.svg';
import { ReactComponent as PartlyConfigured } from 'assets/svg/home/part_config.svg';
import PropTypes from 'prop-types';

import * as useClasses from 'pages/Home/const';
import { useSelector } from 'react-redux';

const FULL_CONFIG = 0;
const PART_CONFIG = 1;
const UNCONFIG = 2;
const MERGED = 4;
const OVERDUE = 3;

export const VehicleOverview = ({ isLoading }) => {
  const { t } = useTranslation();
  const { classes } = useClasses.style();
  const theme = useTheme();
  const vechilesOverviewinfo = useSelector(
    state => state.fleet.vehicleOverview
  );
  const iconConfig = {
    fill: theme.palette.primary.main,
  };

  const renderLoader = () => {
    return (
      <Box
        display='flex'
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        width='100%'
        height='100%'
      >
        <IconAndCount
          icon={<BsTruckFlatbed size={60} {...iconConfig} />}
          isLoading={isLoading}
        />
      </Box>
    );
  };

  const renderNoVehicleState = () => {
    return (
      <Box display='flex' flexDirection={'column'} justifyContent={'center'}>
        <IconAndCount
          icon={<BsTruckFlatbed size={60} {...iconConfig} />}
          count={0}
        />
        <Typography
          variant='h6'
          component='span'
          className={classes.manageTiresText}
        >
          {t('common.noVehiclesAdded')}{' '}
          <Link to='/fleets-data' className={classes.tireInventoryLink}>
            Fleets page
          </Link>
        </Typography>
      </Box>
    );
  };

  const renderVehicleOverview = () => {
    if (isLoading) {
      return renderLoader();
    }

    if (!vechilesOverviewinfo) {
      return renderNoVehicleState();
    }

    const { bus, trailer, truck, overdueFleetcheckCount } =
      vechilesOverviewinfo;

    const configurations = {
      partconfig: bus.partConfig + trailer.partConfig + truck.partConfig,
      fullConfig: bus.fullConfig + trailer.fullConfig + truck.fullConfig,
      unConfig: bus.unConfig + trailer.unConfig + truck.unConfig,
    };

    return (
      <Grid container spacing={2}>
        <VehicleOverviewCount
          src={<Truck stroke={theme.palette.primary.main} />}
          showIcon={true}
          count={truck.count}
          name={t('common.truck')}
        />
        <VehicleOverviewCount
          src={<Truck stroke={theme.palette.primary.main} />}
          showIcon={true}
          count={bus.count}
          name={t('common.bus')}
        />
        <VehicleOverviewCount
          src={<Merged fill={theme.palette.primary.main} />}
          showIcon={true}
          count={truck.merged}
          name={t('homePage.merged')}
          identifier={MERGED}
          category={'merged'}
        />
        <VehicleOverviewCount
          src={<Trailer fill={theme.palette.primary.main} />}
          showIcon={true}
          count={trailer.count}
          name={t('common.trailer')}
        />
        <VehicleOverviewCount
          src={<Configured fill={theme.palette.primary.main} />}
          showIcon={true}
          count={configurations.fullConfig}
          name={t('common.configured')}
          identifier={FULL_CONFIG}
          category={'fullyConfig'}
        />
        <VehicleOverviewCount
          src={<PartlyConfigured fill={theme.palette.primary.main} />}
          showIcon={true}
          count={configurations.partconfig}
          name={t('common.partialConfig')}
          identifier={PART_CONFIG}
          category={'partConfig'}
        />
        <VehicleOverviewCount
          src={<Unconfigured fill={theme.palette.primary.main} />}
          showIcon={true}
          count={configurations.unConfig}
          name={t('homePage.unconfigured')}
          identifier={UNCONFIG}
          category={'unConfig'}
        />
        <VehicleOverviewCount
          src={<Truck stroke={theme.palette.primary.main} />}
          showIcon={true}
          count={overdueFleetcheckCount}
          name={t('homePage.overdue')}
          identifier={OVERDUE}
          category={'overdueFleetcheck'}
        />
      </Grid>
    );
  };

  return (
    <Paper
      style={{
        height: '100%',
        padding: '16px 20px',
        boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
      }}
    >
      <Box style={{ marginBottom: '1rem' }}>
        <Typography variant='subtitle1' gutterBottom fontWeight={900}>
          {t('common.vehicleOverview')}
        </Typography>
      </Box>
      <Box>{renderVehicleOverview()}</Box>
    </Paper>
  );
};

VehicleOverview.propTypes = {
  isLoading: PropTypes.bool,
};

import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Dashboard from 'pages/Dashboard';
import NotFound from './pages/404';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  useTheme,
  Typography,
  Box,
} from '@mui/material';
import {
  setAccessTokenSelector,
  setUser,
  setToken,
  setUserSelector,
  setFuelCostsSelector,
  setFuelConsumptionSelector,
} from './redux/users/ducks';
import { checkUserExist, getAccessToken } from './services/auth_service';
import { encrypter } from './utils/Encrypter';
import { publicRoutes, authProtectedRoutes } from 'routes/Routes';
import './styles/App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fetchRecData, getSavingsEstimate } from 'services/fleet_service';
import {
  fleetOverviewSelector,
  setRecData,
  setSavingEstimate,
} from 'redux/fleets/ducks';
import Sidebar from 'components/Sidebar/Sidebar';
import PlatformInfoButton from './components/PlatformInfoButton';
import { useTranslation } from 'react-i18next';

import Notification from './components/Notification';
import { notificationSelector } from './redux/notifications/ducks';

import Maintenance from './pages/LoginPage/Maintenance';
import { setMaintenance, setMaintenanceSelector } from './redux/users/ducks';
import { USER_LS } from './utils/string_utils';

function Animated() {
  const userLs = JSON.parse(USER_LS);
  const loc = useLocation();
  const storage = localStorage.getItem('refresh_token');

  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const user = useSelector(setUserSelector) || userLs;

  const fleets = useSelector(fleetOverviewSelector)?.fleets;

  const authRoutes = authProtectedRoutes.filter(route =>
    fleets?.length === 0 ? !route.isConditional : route
  );

  useEffect(() => {
    if (!storage) {
      setOpen(true);
    }
  }, [storage]);

  if (
    user === null &&
    !storage &&
    authRoutes.some(
      item =>
        item.path.includes(
          loc.pathname.includes('/fleets-data')
            ? loc.pathname.split('/')[1]
            : loc.pathname
        ) || `${item.path}/` === loc.pathname
    )
  ) {
    return <Navigate to='/login' />;
  }

  if (
    !storage &&
    authRoutes.some(item =>
      item.path.includes(
        loc.pathname.includes('/fleets-data')
          ? loc.pathname.split('/')[1]
          : loc.pathname
      )
    ) &&
    loc.pathname !== '/'
  ) {
    return (
      <Dialog open={open}>
        <DialogTitle>
          <Box display='flex' flexDirection='column'>
            <Typography>{t('common.sessionExpired')}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display='flex' justifyContent='center'>
            <Link
              onClick={() => {
                setOpen(false);
                localStorage.clear();
              }}
              style={{ fontSize: 24 }}
              href='/login'
            >
              {t('loginPage.login')}
            </Link>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <AnimatePresence mode='wait'>
      <Routes location={loc} key={loc.pathname}>
        {publicRoutes.map(route => {
          const { component: Component, path, porps } = route;
          return (
            <Route
              key={path}
              path={path}
              element={
                <div>
                  <Component {...porps} />
                </div>
              }
            />
          );
        })}
        {authRoutes.map(route => {
          const { component: Component, path, header, props } = route;

          return (
            <Route
              path={path}
              key={path}
              element={
                <Dashboard header={header}>
                  <Component {...props} />
                </Dashboard>
              }
            />
          );
        })}
        <Route path='/' element={<Navigate to='/login' />}></Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </AnimatePresence>
  );
}

const App = () => {
  const access_token = useSelector(setAccessTokenSelector);
  const dispatch = useDispatch();
  const theme = useTheme();
  const notification = useSelector(notificationSelector);
  const storage = localStorage.getItem('refresh_token');
  const authToken = localStorage.getItem('access_token');
  const costs = useSelector(setFuelCostsSelector);
  const consumption = useSelector(setFuelConsumptionSelector);
  const isMaintenance = useSelector(setMaintenanceSelector);

  const checkBackendAvailablity = async () => {
    try {
      const res = await checkUserExist(process.env.REACT_APP_TEST_EMAIL);
      if (res !== undefined) {
        if (res?.status === 200 || res?.status === 201) {
          dispatch(setMaintenance(false));
        } else {
          dispatch(setMaintenance(true));
        }
      } else {
        dispatch(setMaintenance(true));
      }
    } catch (err) {
      dispatch(setMaintenance(true));
    }
  };

  useEffect(() => {
    checkBackendAvailablity();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = theme.favicon;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const getResource = async () => {
      try {
        const path = window.location.pathname.includes('saving-potentials');
        const res = await getSavingsEstimate(!path);

        if (res?.status === 200) {
          const data = res?.data?.resp;
          dispatch(setSavingEstimate(data));
          // dispatch(setMaintenance(false));
        }
        //else {
        //  dispatch(setMaintenance(true));
        // }
      } catch (error) {
        console.error('E20027', error);
        // dispatch(setMaintenance(true));
      }
    };
    const getRecData = async () => {
      try {
        const res = await fetchRecData();

        if (res?.status === 200) {
          dispatch(setRecData(res?.data?.resp));
        }
      } catch (err) {
        console.error(err);
      }
    };
    if (access_token) {
      getResource();
      getRecData();
      localStorage.setItem('overviewSelection', 'vehicle');
    }

    /*eslint-disable-next-line*/
  }, [access_token, consumption, costs]);

  const clearUserSession = () => {
    dispatch(setUser(null));
    dispatch(setToken(null));
    localStorage.clear();
    window.location.reload();
  };

  async function getNewToken() {
    const res = await getAccessToken();

    if (storage !== null) {
      if (res !== undefined) {
        if (res.status === 200) {
          dispatch(setUser(res.data.user));
          dispatch(setToken(res.data.access_token));

          localStorage.setItem(
            'refresh_token',
            encrypter(res.data.refresh_token)
          );
        } else {
          clearUserSession();
        }
      } else {
        clearUserSession();
      }
    }
  }

  useEffect(() => {
    if (access_token !== null) {
      // initalActions();
      const interValid = setInterval(() => {
        getNewToken();
      }, 1000 * 60 * 45);
      return () => clearInterval(interValid);
    } else {
      if (storage !== null) {
        getNewToken();
      }
    }
    // eslint-disable-next-line
  }, [access_token]);

  if (isMaintenance) return <Maintenance />;
  return (
    <div className='App'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{theme.site_title}</title>
      </Helmet>
      <Router>
        {authToken && <Sidebar />}
        <Animated />

        <PlatformInfoButton />
        {notification.show && <Notification />}
      </Router>
    </div>
  );
};

export default App;

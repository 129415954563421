import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Layout from 'components/Layout';

import { resetPasswordUser } from 'redux/users/ducks';
import { setStatusSelector } from 'redux/users/ducks';
import { passwordResetValidationSchema } from 'utils/string_utils';
import logo from 'assets/images/logo.png';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      minWidth: 275,
      margin: '0rem 0.5rem',
      padding: '48px 68px 22px',
      boxShadow:
        '0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)',
      borderRadius: 4,
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0px',
      },
    },
    title: {
      fontSize: 14,
    },
    links: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    img: {
      width: '100%',
      height: '100%',
    },
    btn: {
      margin: '28px 0px',
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
    },
  };
});

const ResetPassword = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const status = useSelector(setStatusSelector);
  const [showPassword, setShow] = useState(false);
  let navigate = useNavigate();
  let { userToken } = useParams();

  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: passwordResetValidationSchema,
    onSubmit: async values => {
      dispatch(resetPasswordUser(userToken, values.password));

      formik.resetForm();
    },
  });
  useEffect(() => {
    if (status === 'reset') {
      navigate('/login');
    }
    // eslint-disable-next-line
  }, [status]);
  const handleClickShowPassword = () => {
    setShow(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  return (
    <Layout>
      <Box
        style={{
          scrollBehavior: 'unset',
        }}
        width='100vw'
        height='100vh'
        bgcolor='#FAFAFA'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <Box my='1rem'>
          <img src={logo} alt='Logo' className={classes.img} />
        </Box>
        <Card className={classes.root}>
          <CardContent>
            <Typography variant='h4' gutterBottom align='left'>
              Reset Your Passsword
            </Typography>
            <Typography variant='p' gutterBottom align='left'>
              This will be your new password
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <FormControl
                fullWidth
                variant='outlined'
                style={{ margin: '0.5rem 0rem' }}
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                  Password
                </InputLabel>
                <OutlinedInput
                  id='outlined-adornment-password'
                  margin={'normal'}
                  label={'Password'}
                  name={'password'}
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  required
                  variant={'outlined'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  fullWidth
                />
              </FormControl>
              <Box width='100%' mx='auto'>
                <Button
                  color={'primary'}
                  type={'submit'}
                  variant={'contained'}
                  className={classes.btn}
                  fullWidth
                >
                  <Typography variant='h6' align='center'>
                    Submit
                  </Typography>
                </Button>
              </Box>

              <Typography variant='h6' gutterBottom align='center'>
                <Link to='/'>
                  <span className={classes.links}>{' < '}Back To Login</span>
                </Link>
              </Typography>
            </form>
          </CardContent>
        </Card>
        <Box my='1rem'>
          <Typography variant='subtitle1' color='textSecondary' gutterBottom>
            &copy;Co2Opt&nbsp;.&nbsp;Contact&nbsp;.&nbsp;Privacy&Terms
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
};

export default ResetPassword;

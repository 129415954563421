import React from 'react';
import { motion } from 'framer-motion';

const MotionWrap = (Component, initial, animate, exit) =>
  function HOC() {
    return (
      <motion.div
        initial={initial}
        animate={animate}
        exit={exit}
        transition={{
          duration: 0.4,
          ease: [0.6, -0.05, 0.01, 0.99],
        }}
      >
        <Component />
      </motion.div>
    );
  };

export default MotionWrap;

import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import { setAccessTokenSelector, setUserSelector } from 'redux/users/ducks';
import { useTranslation } from 'react-i18next';
import { UploadFile } from './Helper/Helper';
import PropTypes from 'prop-types';

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

const useStyles = makeStyles()(theme => {
  return {
    container: {
      padding: 50,
      backgroundColor: '#FFFFFF',
    },
    step2: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tagHeading: {
      fontSize: 20,
      marginBottom: 20,
      color: theme.palette.primary.main,
    },
    radio: {
      opacity: 0,
      width: 0,
    },
    radioBtn: {
      padding: '10px 20px',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: 'pointer',
    },
    previewBox: {
      display: 'flex',
      gap: 10,
      flexWrap: 'wrap',
      marginBottom: 20,
      padding: 10,
    },
    previewBoxImg: {
      height: 120,
      width: 120,
      borderRadius: 5,
    },
  };
});

const steps = ['Select Files', 'Tag Files'];
const image_ext = {
  'image/jpg': ['.jpg'],
  'image/jpeg': ['.jpeg'],
  'image/png': ['.png'],
  'image/tiff': ['.tif'],
};
const pdf_ext = {
  'application/pdf': ['.pdf'],
};
const VEHICLE_PHOTOS = 'Vehicle Photos';
const Dropzone = ({ fileTag, customer1, fileCheck }) => {
  const [tag, setTag] = useState();
  const [toggle, setToggle] = useState(false);
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const access_token = useSelector(setAccessTokenSelector);
  const user = useSelector(setUserSelector);
  // eslint-disable-next-line
  const [customer, setCustomerNew] = useState({
    ...user,
    vehicle: {
      id: customer1.vehicle_id,
    },
  });

  const { t } = useTranslation();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    textAlign: 'center',
    borderRadius: '10px',
    p: 4,
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: fileTag === VEHICLE_PHOTOS ? image_ext : pdf_ext,
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = useCallback(() => {
    return Object.keys(completed).length;
  }, [completed]);

  const isLastStep = useCallback(() => {
    return activeStep === totalSteps() - 1;
  }, [activeStep]);

  const allStepsCompleted = useCallback(() => {
    return completedSteps() === totalSteps();
  }, [completedSteps]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    setTag(null);
  };
  const upload = useCallback(() => {
    setToggle(true);
    var formData = new FormData();
    formData.append('tag', tag);
    acceptedFiles.map(async (item, index) => {
      formData.append(item.name, item);
      if (index === acceptedFiles.length - 1 && tag != null) {
        try {
          const res = await UploadFile(formData, customer, access_token);
          if (res.status_code === 200) {
            setToggle(false);
            handleReset();
            fileCheck(true);
          }
        } catch (error) {
          setToggle(false);
          dispatch(setNotification(t('custom_dropzone.error'), 'error', true));
          handleReset();
          console.error('E20001', error);
        }
      }
    });
  }, [dispatch, acceptedFiles, access_token, tag, customer, t, fileCheck]);

  const handleNext = useCallback(() => {
    if (activeStep === 0) {
      if (acceptedFiles.length === 0) {
        alert('Please select files to upload');
      } else {
        const newActiveStep =
          isLastStep() && !allStepsCompleted()
            ? steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
      }
    } else {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? steps.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
    }
  }, [
    acceptedFiles.length,
    activeStep,
    allStepsCompleted,
    completed,
    isLastStep,
  ]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      handleNext();
    }
    // eslint-disable-next-line
  }, [acceptedFiles.length]);
  useEffect(() => {
    if (acceptedFiles.length !== 0 && !!tag) {
      upload();
    }
    //eslint-disable-next-line
  }, [tag]);

  return (
    <div className={classes.container}>
      <Modal
        open={toggle}
        onClose={() => setToggle(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <CircularProgress color='success' />
          <h4 className='test-secondary'>Uploading</h4>
        </Box>
      </Modal>
      <br />
      <br />
      <br />
      <Typography variant='h5'>
        {fileTag === VEHICLE_PHOTOS
          ? t('dataRoom.uploadPhotoTitle')
          : t('dataRoom.uploadFilesTitle')}
      </Typography>
      <Box sx={{ width: '100%' }}>
        <div style={{ padding: 50 }}>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {activeStep === 0 ? (
                <div>
                  <Container
                    {...getRootProps({ isFocused, isDragAccept, isDragReject })}
                  >
                    <input {...getInputProps()} data-hook='file-drop-zone' />
                    <p style={{ fontSize: 'clamp(14px, 1vw, 1.5rem)' }}>
                      {t('custom_dropzone.infoText')}
                    </p>
                  </Container>
                </div>
              ) : (
                ''
              )}
              {activeStep === 1 ? (
                <div>
                  <div
                    className={classes.step2}
                    style={{
                      display: acceptedFiles.length > 0 ? 'flex' : 'none',
                    }}
                  >
                    {!!fileTag ? (
                      <>
                        <div className={classes.previewBox}>
                          {acceptedFiles.map(file =>
                            !file.type.includes('pdf') ? (
                              <img
                                className={classes.previewBoxImg}
                                src={URL.createObjectURL(file)}
                                alt=''
                              />
                            ) : (
                              <>
                                <p>{file.name}</p>
                                <br />
                              </>
                            )
                          )}
                        </div>
                        <Button
                          variant='contained'
                          color='primary'
                          sx={{ mr: 1 }}
                          onClick={() => setTag(fileTag)}
                        >
                          {t('common.uploadBtn')}
                        </Button>
                      </>
                    ) : (
                      <div>
                        <h5 className={classes.tagHeading}>Tag files</h5>
                        <input
                          type='radio'
                          className={classes.radio}
                          name='options-outlined'
                          onClick={e => setTag(e.target.value)}
                          value={'Vehicle Photos'}
                          id='btn1'
                        />
                        <label className={classes.radioBtn} htmlFor='btn1'>
                          Vehicle Photos
                        </label>
                        &nbsp;&nbsp;
                        <input
                          type='radio'
                          className={classes.radio}
                          name='options-outlined'
                          onClick={e => setTag(e.target.value)}
                          value={'Vehicle Service Documents'}
                          id='btn2'
                        />
                        <label className={classes.radioBtn} htmlFor='btn2'>
                          Vehicle Service Documents
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ''
              )}
              <Box sx={{ display: 'flex', flexDirection: 'row', mt: 10 }}>
                {activeStep !== 0 ? (
                  <Button
                    onClick={handleBack}
                    variant='outlined'
                    color='primary'
                    sx={{ mr: 1 }}
                  >
                    {t('common.back')}
                  </Button>
                ) : (
                  ''
                )}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </div>
  );
};

export default Dropzone;
Dropzone.propTypes = {
  fileTag: PropTypes.string,
  customer1: PropTypes.object,
  fileCheck: PropTypes.func,
};

import React, { useEffect, useState, useRef } from 'react';
import { deleteFile, FetchFile } from 'components/Dropzone/Helper/Helper';
import pdf from 'assets/images/pdf1.png';
import Modal from '@mui/material/Modal';
import Loading from 'components/Loading';
import {
  AddCircleOutline,
  GetApp,
  Delete,
  Close,
  ViewList,
  ViewModule,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Fab,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { darken, useTheme } from '@mui/material/styles';
import { setAccessTokenSelector, setUserSelector } from 'redux/users/ducks';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Dropzone from 'components/Dropzone/Dropzone';
import { setNotification } from 'redux/notifications/ducks';
import ImageViewer from './ImageViewer';
import LoadAnimation from 'components/LoadAnimation';
import { USER_LS, USER_ROLES } from 'utils/string_utils';
import AddIcon from '@mui/icons-material/Add';
import vehicle_img from 'assets/svg/vehicle/image.svg';

const useStyles = makeStyles()(theme => {
  return {
    container: {
      margin: '1rem 0rem',
      width: '100%',
    },
    btn: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: '#fafafa',
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      borderRadius: 2,
      marginBottom: 5,

      '&:hover': {
        backgroundColor: theme.palette.primary.alt1,
      },
    },
    clear: {
      padding: 10,
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      borderRadius: 2,
      marginBottom: 5,
      textTransform: 'capitalize',
    },
    delete: {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      color: '#fafafa',
      width: 110,
      padding: 10,
      marginBottom: 5,
      textTransform: 'capitalize',

      '&:hover': {
        backgroundColor: theme.palette.secondary.alt,
      },
    },
    main: {
      display: 'flex',
      gap: 10,
      flexDirection: 'column',
    },
    photos: {
      display: 'flex',
      gap: 10,
      flexDirection: 'column',
      padding: 20,
    },
    alt: {
      fontSize: 'clamp(10px, 0.5vw, 2rem)',
      alignSelf: 'flex-end',
      textAlign: 'right',
      opacity: 0.7,
      border: '1px solid grey',
      padding: 5,
      borderRadius: '5px',
    },
    heading: {
      fontWeight: 700,
      fontSize: 'clamp(20px, 1.5vw, 3rem)',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
    },
    box2: {
      display: 'flex',
      gap: 10,
    },
    box3: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    viewAction: {
      height: '200px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      alignItems: 'center',
      position: 'absolute',
      right: 2,
      top: '50%',
      transform: 'translate(-50%, 0)',
    },
    imageViewAction: {
      height: '200px',
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      alignItems: 'center',
      position: 'absolute',
      right: -50,
      top: '50%',
      transform: 'translate(0%, -50%)',
    },
    mainElementContainer: {
      overflowY: 'scroll',
      height: '100%',
      overflowX: 'hidden',
    },
    imageContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
    title: {
      color: '#fff',
    },
    titleBar: {
      background:
        'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    imgStyle: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    canvasStyle: {
      width: '100%',
      height: '100%',
      overflow: 'scroll',
      position: 'relative',
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonContainer: {
      display: 'flex',
      gap: 10,
      position: 'absolute',
    },
    button: {
      padding: '6px 16px',
      fontSize: '1rem',
      minWidth: '64px',
      boxSizing: 'border-box',
      textAlign: 'center',
      textTransform: 'unset',
      borderRadius: '4px',
      cursor: 'pointer',
      border: '1px solid transparent',
      color: '#fff',
      maxHeight: '40px',
      backgroundColor: theme.palette.primary.main,
      transition: 'background-color 0.3s, color 0.3s',
      '&:hover': {
        backgroundColor: darken(theme.palette.primary.main, 0.4),
      },
    },
    commonBtn: {
      margin: '0rem 1rem',
      textTransform: 'unset',
      boxShadow: 'none',
      borderRadius: '30px',
    },
  };
});

const VEHICLE_PHOTOS = 'Vehicle Photos';
const VEHICLE_DOCS = 'Vehicle Service Documents';
const PHOTOS = 'photos';
const DOCS = 'documents';
const PDF_EXT = 'pdf';

const FileList = () => {
  const userLs = JSON.parse(USER_LS);
  const user = useSelector(setUserSelector) || userLs;
  const params = useParams();
  const { vehicle_id } = params;
  // eslint-disable-next-line
  const [customer, setCustomer] = useState({
    name: user?.first_name,
    vehicle: {
      id: vehicle_id,
    },
  });
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [new_open, setNewOpen] = useState(false);
  const [media, setMedia] = useState('photos');
  const [view, setView] = useState('grid');
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentFile(null);
    removeNavButtons();
  };
  const handleNewOpen = () => {
    setNewOpen(true);
  };
  const handleNewClose = () => {
    setNewOpen(false);
    setCurrentImage(null);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    textAlign: 'center',
    borderRadius: '10px',
    p: 4,
  };
  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    textAlign: 'center',
    borderRadius: '40px',
    p: 4,
  };
  const [Images, setImages] = useState([]);
  const [Files, setFiles] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const access_token = useSelector(setAccessTokenSelector);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [fileType, setFileType] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [fileTag, setFileTag] = useState(undefined);
  // eslint-disable-next-line
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  };

  const getFiles = async () => {
    try {
      setIsLoading(true);
      const res = await FetchFile(customer, access_token);
      if (res.success) {
        setIsLoading(false);
        setImages([]);
        setFiles([]);
        const allImages = [];
        const allFiles = [];
        res.links.forEach((file, index) => {
          const x = res.details[index]?.Key.toString().split('/')[3].split('.');
          const ext = x[x.length - 1];
          const tag = res.details[index]?.Key.toString().split('/')[2];
          const uploadDate = res.details[index]?.LastModified;

          if (tag === VEHICLE_PHOTOS) {
            allImages.push({
              ext: ext,
              src: ext === 'pdf' ? pdf : file,
              downloadSrc: file,
              thumbnail: ext === 'pdf' ? pdf : file,
              thumbnailWidth: 550,
              thumbnailHeight: 450,
              caption: res.details[index]?.Key.toString().split('/')[3],
              path: res.details[index]?.Key,
              uploadDate,
            });
          } else if (tag === VEHICLE_DOCS) {
            allFiles.push({
              ext: ext,
              src: ext === 'pdf' ? pdf : file,
              downloadSrc: file,
              thumbnail: ext === 'pdf' ? pdf : file,
              thumbnailWidth: 150,
              thumbnailHeight: 180,
              caption: res.details[index]?.Key?.toString().split('/')[3],
              thumbnailCaption: truncate(
                res.details[index]?.Key?.toString().split('/')[3],
                20
              ),
              path: res.details[index]?.Key,
              uploadDate,
            });
          }
          setImages(
            allImages.sort(
              (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
            )
          );
          setFiles(
            allFiles.sort(
              (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
            )
          );
        });
      }
    } catch (error) {
      console.error('E20000', error);
    } finally {
      // setToggle(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getFiles();
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const revertToDefault = () => {
      setToggle(false);
      setConfirmDelete(false);
      setFilesToDelete([]);
      setOpenDialog(false);
    };
    const deleteFilesHandler = async () => {
      try {
        const payload = { files: filesToDelete };

        const res = await deleteFile(payload);

        if (res?.status_code === 200) {
          dispatch(setNotification('Delete Successful', 'success', true));
          if (fileTag === PHOTOS) {
            const filtered = Images.filter(item => !item.isSelected);
            setImages(filtered);
          } else {
            if (lightboxIsOpen) {
              Files[currentFile].isSelected = true;
            }
            const filtered = Files.filter(item => !item.isSelected);
            setFiles(filtered);
          }
          // getFiles();
        } else {
          dispatch(setNotification('An error occurred', 'error', true));
        }
      } catch (error) {
        console.error('E20029', error);
        dispatch(setNotification('An error occurred', 'error', true));
      } finally {
        revertToDefault();
      }
    };

    if (confirmDelete) {
      deleteFilesHandler();
    }
    setNewOpen(false);
    setOpen(false);
    // eslint-disable-next-line
  }, [filesToDelete, confirmDelete]);

  const deleteHandler = async file => {
    let found = [];
    if (file === PHOTOS) {
      found = Images.filter(image => image.isSelected);
    } else if (file === DOCS) {
      found = Files.filter(file => file.isSelected);
    } else {
      found = [];
    }
    setFileTag(file);
    const path = [];
    if (found.length) {
      found.forEach(image => {
        path.push(image?.path);
      });
      setToggle(true);
      setOpenDialog(true);
    } else {
      if (file === PHOTOS) {
        const x = Images[currentImage]?.path;
        path.push(x);
      } else {
        const x = Files[currentFile]?.path;
        path.push(x);
      }
      setToggle(true);
      setOpenDialog(true);
    }
    setFilesToDelete(path);
  };

  const selectImageHandler = index => {
    const nextImages = Images.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image
    );
    setImages(nextImages);
  };

  const selectFileHandler = index => {
    const nextFiles = Files.map((file, i) =>
      i === index ? { ...file, isSelected: !file.isSelected } : file
    );
    setFiles(nextFiles);
  };
  const checkIfNewFileUploaded = feedback => {
    if (feedback) {
      setToggle(false);
      setDropzoneOpen(false);
      getFiles();
      dispatch(setNotification('Uploaded Successfully', 'success', true));
    }
  };

  const fetchFile = async file => {
    const pdfJS = await import('pdfjs-dist/build/pdf');
    pdfJS.GlobalWorkerOptions.workerSrc =
      window.location.origin + '/pdf.worker.min.js';
    const pdf = await pdfJS.getDocument({
      url: file.downloadSrc,
    }).promise;

    setCurrentFile(file);

    const numberOfPages = pdf.numPages;

    const numPages = pdf.numPages;
    let currentPage = 1;
    const renderPage = async pageNum => {
      const page = await pdf.getPage(pageNum).then(res => res);
      const viewport = page.getViewport({ scale: 1.5 });

      // Prepare canvas using PDF page dimensions.
      const canvas = canvasRef.current;
      const canvasContext = canvas?.getContext('2d');
      if (canvas) {
        // Ensure canvasRef.current is not null before accessing its properties
        canvas.height = viewport.height;
        canvas.width = viewport.width;
      }

      // Render PDF page into canvas context.
      const renderContext = { canvasContext, viewport };
      await page.render(renderContext).promise;
    };

    // Function to handle page navigation
    const goToPage = pageNum => {
      if (pageNum >= 1 && pageNum <= numPages) {
        renderPage(pageNum);
        currentPage = pageNum;
      }
    };

    // Initial render of the first page
    renderPage(currentPage);

    const buttonContainer = document.createElement('div');
    buttonContainer.className = classes.buttonContainer;
    buttonContainer.id = 'btnContainer';
    buttonContainer.style.position = 'absolute';
    buttonContainer.style.bottom = '10px';
    buttonContainer.style.left = '45%';
    buttonContainer.style.zIndex = '9999';

    const prevPageButton = document.createElement('button');
    prevPageButton.id = 'prevPageButton';
    prevPageButton.textContent = 'Prev';
    prevPageButton.className = classes.button;
    prevPageButton.addEventListener('click', () => {
      goToPage(currentPage - 1);
    });

    const nextPageButton = document.createElement('button');
    nextPageButton.id = 'nextPageButton';
    nextPageButton.textContent = 'Next';
    nextPageButton.className = classes.button;
    nextPageButton.addEventListener('click', () => {
      goToPage(currentPage + 1);
    });

    if (numberOfPages > 1) {
      buttonContainer.appendChild(prevPageButton);
      buttonContainer.appendChild(nextPageButton);

      document.body.appendChild(buttonContainer);
    }
  };

  const fileDownloader = async () => {
    var link = document.createElement('a');
    link.download = currentFile.caption;
    link.target = '_blank';

    // Construct the URI
    link.href = currentFile.downloadSrc;
    document.body.appendChild(link);
    link.click();

    // Cleanup the DOM
    document.body.removeChild(link);
  };

  const removeNavButtons = () => {
    const btnContainer = document.getElementById('btnContainer');
    btnContainer && btnContainer.remove();
  };

  useEffect(() => {
    if (!open) {
      const btnContainer = document.getElementById('btnContainer');
      btnContainer && btnContainer.remove();
    }
  }, [open]);

  const imageDownloader = async () => {
    const image = await fetch(currentImage.downloadSrc);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = currentImage.caption;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={classes.container}>
      <LoadAnimation
        loading={isLoading}
        setLoading={setIsLoading}
        message='Fetching Files...'
      />
      <Modal
        open={toggle}
        onClose={() => setToggle(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={{ zIndex: 9999 }}
        data-hook='filelist-modal'
      >
        <Box sx={style}>
          {!openDialog && (
            <Box>
              <CircularProgress />
              <h4>Fetching Files</h4>
            </Box>
          )}
          {openDialog && (
            <Box>
              <Typography>{t('dataRoom.deleteConfirm')}</Typography>
              <Box className={classes.box} justifyContent='center'>
                <Button
                  size='small'
                  color='primary'
                  data-hook='approve-button'
                  onClick={() => setConfirmDelete(true)}
                >
                  {t('common.yes')}
                </Button>
                <Button
                  size='small'
                  color='secondary'
                  onClick={() => {
                    setToggle(false);
                  }}
                  data-hook='disapprove-button'
                >
                  {t('common.no')}
                </Button>
              </Box>
            </Box>
          )}
          {confirmDelete && (
            <Box sx={style} data-hook='circular-loader'>
              <Box>
                <CircularProgress />
                <h4>Please wait...</h4>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
      <Modal
        open={dropzoneOpen}
        onClose={() => {
          setDropzoneOpen(false);
          setFileType('');
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        data-hook='dropzone'
      >
        <Box sx={style1}>
          <Dropzone
            fileTag={fileType === PHOTOS ? VEHICLE_PHOTOS : VEHICLE_DOCS}
            customer1={{ vehicle_id }}
            fileCheck={checkIfNewFileUploaded}
          />
        </Box>
      </Modal>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          padding: '16px 20px 64px 20px',
          flexDirection: 'column',
          // alignItems: 'flex-start',
          borderRadius: '16px',
          background: 'var(--default-white, #FFF)',
        }}
      >
        <Box
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box display='flex'>
            <Button
              sx={{
                backgroundColor:
                  media === 'photos' ? theme.palette.primary.main : 'grey',
              }}
              variant='contained'
              className={classes.commonBtn}
              data-hook='photo-tab'
              onClick={() => setMedia('photos')}
            >
              Photos
            </Button>
            <Button
              sx={{
                backgroundColor:
                  media === 'docs' ? theme.palette.primary.main : 'grey',
              }}
              variant='contained'
              className={classes.commonBtn}
              onClick={() => setMedia('docs')}
              data-hook='docs-tab'
            >
              Documents
            </Button>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <IconButton
              onClick={() => {
                view === 'grid' ? setView('list') : setView('grid');
              }}
            >
              {view === 'grid' ? <ViewList /> : <ViewModule />}
            </IconButton>
            {user?.iam_role !== USER_ROLES.reporter && (
              <Box data-hook='add-button'>
                <IconButton
                  aria-label='delete'
                  onClick={() => {
                    if (media === 'docs') {
                      setDropzoneOpen(true);
                      setFileType('docs');
                    } else {
                      setDropzoneOpen(true);
                      setFileType(PHOTOS);
                    }
                  }}
                >
                  <AddIcon fontSize={'medium'} />
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
        {media === 'photos' ? (
          <div style={{ width: '100%', margin: '2rem 0rem' }}>
            {Images !== undefined && Images.length > 0 ? (
              <ImageViewer
                data-hook='image-viewer'
                data={Images}
                srcKey='downloadSrc'
                onClick={(index, item) => {
                  if (item.ext === PDF_EXT) {
                    fetchFile(item);
                    handleOpen();
                    selectFileHandler(index);
                  } else {
                    setCurrentImage(item);
                    handleNewOpen();
                    selectImageHandler(index);
                  }
                }}
                view={view}
              />
            ) : (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src={vehicle_img} alt='img' />
                <Box my={1} data-hook='no-files'>
                  <Typography variant='h6'>{t('dataRoom.noFiles')}</Typography>
                </Box>
                {user?.iam_role !== USER_ROLES.reporter && (
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    endIcon={<AddCircleOutline />}
                    style={{ boxShadow: 'none' }}
                    onClick={() => {
                      setDropzoneOpen(true);
                      setFileType(PHOTOS);
                    }}
                    data-hook='bottom-add-button'
                  >
                    {`${
                      Images.length > 0
                        ? t('dataRoom.addPhotosBtn')
                        : t('dataRoom.uploadPhotosBtn')
                    }`}
                  </Button>
                )}
              </Box>
            )}

            <br />
          </div>
        ) : (
          <div style={{ width: '100%', margin: '2rem 0rem' }}>
            {Files !== undefined && Files.length > 0 ? (
              <ImageViewer
                data-hook='file-viewer'
                data={Files}
                srcKey='src'
                onClick={(index, item) => {
                  if (item.ext === PDF_EXT) {
                    fetchFile(item);
                    handleOpen();
                    selectFileHandler(index);
                  } else {
                    setCurrentImage(item);
                    handleNewOpen();
                    selectImageHandler(index);
                  }
                }}
                view={view}
              />
            ) : (
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img src={vehicle_img} alt='img' />
                <Box my={1}>
                  <Typography variant='h6'>{t('dataRoom.noFiles')}</Typography>
                </Box>
                {user?.iam_role !== USER_ROLES.reporter && (
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    endIcon={<AddCircleOutline />}
                    style={{ boxShadow: 'none' }}
                    onClick={() => {
                      setDropzoneOpen(true);
                      setFileType('docs');
                    }}
                    data-hook='add-button'
                  >
                    {`${
                      Files.length > 0
                        ? t('dataRoom.addFilesBtn')
                        : t('dataRoom.uploadFilesBtn')
                    }`}
                  </Button>
                )}
              </Box>
            )}
          </div>
        )}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        className={classes.modal}
        data-hook='pdf-viewer'
      >
        {currentFile === null ? (
          <Box width='80vw'>
            <Loading />
          </Box>
        ) : (
          <FileViewer
            mainElement={
              <canvas ref={canvasRef} className={classes.canvasStyle} />
            }
            downloadAction={fileDownloader}
            deleteAction={() => deleteHandler(DOCS)}
            cancelAction={() => {
              setOpen(false);
              removeNavButtons();
            }}
          />
        )}
      </Modal>
      <Modal
        open={new_open}
        onClose={handleNewClose}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        className={classes.modal}
        data-hook='image-viewer'
      >
        {currentImage === null ? (
          <Loading />
        ) : (
          <Box
            width='50vw'
            height='100vh'
            display={'flex'}
            flexDirection='column'
            position='relative'
          >
            <Box
              width='100%'
              height='100%'
              display='flex'
              justifyContent={'center'}
              alignItems='center'
            >
              <img
                src={currentImage?.downloadSrc}
                className={classes.imgStyle}
                alt={currentImage?.caption}
              />
            </Box>
            <Box className={classes.imageViewAction}>
              <Tooltip title={t('common.actions.download')}>
                <Fab
                  onClick={() => imageDownloader()}
                  size='small'
                  color='primary'
                >
                  <GetApp fontSize='small' />
                </Fab>
              </Tooltip>
              <Tooltip title={t('common.actions.delete')}>
                <Fab
                  onClick={() => deleteHandler(PHOTOS)}
                  size='small'
                  color='secondary'
                >
                  <Delete fontSize='small' />
                </Fab>
              </Tooltip>
              <Tooltip title={t('common.actions.cancel')}>
                <Fab onClick={() => setNewOpen(false)} size='small'>
                  <Close fontSize='small' />
                </Fab>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Modal>
    </div>
  );
};

const FileViewer = ({
  mainElement,
  deleteAction,
  downloadAction,
  cancelAction,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      width='600px'
      height='100vh'
      maxHeight='800px'
      display={'flex'}
      flexDirection='column'
      position='relative'
    >
      <Box className={classes.mainElementContainer}>
        <Box
          height='800px'
          width='590px'
          display='flex'
          justifyContent={'center'}
          alignItems='center'
        >
          {mainElement}
        </Box>
      </Box>
      <Box className={classes.viewAction}>
        <Tooltip title={t('common.actions.download')}>
          <Fab onClick={downloadAction} size='small' color='primary'>
            <GetApp fontSize='small' />
          </Fab>
        </Tooltip>
        <Tooltip title={t('common.actions.delete')}>
          <Fab onClick={deleteAction} size='small' color='secondary'>
            <Delete fontSize='small' />
          </Fab>
        </Tooltip>
        <Tooltip title={t('common.actions.cancel')}>
          <Fab onClick={cancelAction} size='small'>
            <Close fontSize='small' />
          </Fab>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default FileList;

import { Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  getFleetCheckRemainderFrequency,
  updateFleetCheckFrequency,
} from '../../../../services/fleet_service';
import { setAccessTokenSelector } from 'redux/users/ducks';
import { useSelector } from 'react-redux';
import ActionButtons from './ActionButtons';
import { setNotification } from 'redux/notifications/ducks';
import { useDispatch } from 'react-redux';

export const FleetCheck = () => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fleetCheckFrequency, setFleetCheckFrequency] = useState(0);
  const access_token = useSelector(setAccessTokenSelector);
  const dispatch = useDispatch();
  let InitialFrequency;

  useEffect(() => {
    async function getRemainder() {
      const res = await getFleetCheckRemainderFrequency(access_token);
      if (res.status === 200) {
        const {
          data: {
            resp: { fleetcheck_reminder_days },
          },
        } = res;
        setFleetCheckFrequency(fleetcheck_reminder_days);
        // eslint-disable-next-line
        InitialFrequency = fleetcheck_reminder_days;
      }
    }
    getRemainder();
  }, []);

  const handleUpdate = async () => {
    try {
      setIsSubmitting(true);
      const res = await updateFleetCheckFrequency(
        access_token,
        fleetCheckFrequency
      );
      if (res.status === 200) {
        dispatch(
          setNotification(t('fleetCheck.fleetCheckUpdateMsg'), 'success', true)
        );
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const handleChange = ({ target: { value } }) => {
    if (value <= 365 && value >= 0) {
      setFleetCheckFrequency(value);
    }
  };

  return (
    <>
      <Grid container spacing={2} marginBottom={2}>
        <Grid
          item
          xs={12}
          lg={4}
          style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
        >
          <label
            style={{ fontSize: 18, fontWeight: 13 }}
            data-hook='fleet-check-label'
          >
            {t('customerSettingsPage.fleets.fleetCheckFrequency')}
          </label>
          <TextField
            id='outlined-basic'
            label={t('customerSettingsPage.fleets.selectDays')}
            variant='outlined'
            value={fleetCheckFrequency}
            onChange={handleChange}
            type='number'
            data-hook='fleet-check-days-input'
          />
        </Grid>
      </Grid>
      <ActionButtons
        handleUpdate={handleUpdate}
        disabled={isSubmitting || InitialFrequency === fleetCheckFrequency}
        isSubmitting={isSubmitting}
      />
    </>
  );
};

import React, { useState } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import Box from '@mui/system/Box';
import { getVehiclesForFleet, updateVehicle } from 'services/fleet_service';
import { VEHICLE } from 'utils/string_utils';
import { useDispatch } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import { useTranslation } from 'react-i18next';
import {
  fleetOverviewSelector,
  setChangeInVehicle,
  setVehiclesForFleet,
  vehicleUpdatedSelector,
} from 'redux/fleets/ducks';
import { useSelector } from 'react-redux';
import MoreOptions from './TireConfig/MoreOptions';
import { useParams, useNavigate } from 'react-router-dom';
import CustomTextField from 'components/custom/CustomTextField';
import { optimalPressureByAxleType } from './TireConfig/const';
import { setAccessTokenSelector } from 'redux/users/ducks';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      width: '770px',
      height: 'auto',
      border: 'none',

      padding: '48px 98px 48px 98px',
      position: 'relative',
    },
    navBtns: {
      flex: '30%',
      borderRadius: 24,
      textTransform: 'unset',
    },
  };
});

const VehicleEditModal = ({
  open,
  setOpen,
  vehicle,
  caller,
  isTelematicsAvailable,
  isMerged,
}) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);
  const access_token = useSelector(setAccessTokenSelector);
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payload, setPayload] = useState({
    odometer_reading: vehicle?.odometer_reading ?? '',
    inspection_date: vehicle?.inspection_date ?? null,
    safety_check_date: vehicle?.safety_check_date ?? null,
    tank_capacity: vehicle.metadata?.tank_capacity,
    name: vehicle?.name,
    reg_plate: vehicle?.reg_plate,
    internal_grouping: vehicle?.internal_grouping,
    optimum_air_pressure: vehicle?.optimum_air_pressure,
    fleet_id: '',
  });
  const staticPayload = {
    odometer_reading: vehicle?.odometer_reading ?? '',
    inspection_date: vehicle?.inspection_date ?? null,
    safety_check_date: vehicle?.safety_check_date ?? null,
    tank_capacity: vehicle.metadata?.tank_capacity ?? '',
    name: vehicle?.name,
    reg_plate: vehicle?.reg_plate,
    internal_grouping: vehicle?.internal_grouping,
    optimum_air_pressure: vehicle?.optimum_air_pressure,
  };

  const params = useParams();
  const { fleet_id } = params;
  const [errors, setErrors] = useState({ reg_plate: null });

  React.useEffect(() => {
    const x = errors.optimum_air_pressure || {};
    if (Object.values(x).every(val => !val)) {
      setErrors({ ...errors, optimum_air_pressure: null });
    }
    // eslint-disable-next-line
  }, [errors.optimum_air_pressure]);

  const submitHandler = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    let copy = { ...payload };
    if (copy.odometer_reading === '') {
      copy.odometer_reading = staticPayload.odometer_reading;
    }
    if (copy.tank_capacity === '') {
      copy.tank_capacity = staticPayload.tank_capacity;
    }
    const filteredObject = Object.entries(copy).reduce((acc, [key, value]) => {
      if (value !== null && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});

    const { tank_capacity, ...rest } = filteredObject;
    const payload_ =
      vehicle?.vehicle_type === VEHICLE.vehicle_type.TRAILER1
        ? rest
        : filteredObject;
    const resource = {
      name: payload?.name,
      vin_chassis: vehicle?.vin_chassis,
      reg_plate: payload?.reg_plate,
      vehicle_type: vehicle?.vehicle_type,
      ...payload_,
    };

    try {
      if (JSON.stringify(payload) !== JSON.stringify(staticPayload)) {
        const res = await updateVehicle(resource, vehicle?.vehicle_id);
        if (res.status === 200) {
          dispatch(
            setNotification(t('vehiclePage.updateSuccess'), 'success', true)
          );
          dispatch(setChangeInVehicle(!vehicleDataHasChanged));
          handleClose();
          const res2 = await getVehiclesForFleet(
            { fleet_id: fleet_id },
            access_token
          );
          dispatch(setVehiclesForFleet(fleet_id, res2));
          if (payload.fleet_id) {
            navigateToVehiclePage();
          }
        } else {
          dispatch(setNotification(res?.data?.return_message, 'error', true));
        }
      }
    } catch (error) {
      console.error('E20071', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const navigateToVehiclePage = () => {
    const vehicleType =
      vehicle.vehicle_type.toLowerCase() === 'bus'
        ? 'buses'
        : `${vehicle.vehicle_type.toLowerCase()}s`;
    navigate(
      `/fleets-data/${payload.fleet_id}/${vehicleType}/${vehicle.vehicle_id}`
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  function vehicleInfoFieldsView() {
    return (
      <Grid container spacing={3}>
        <MoreOptions
          resource={payload}
          resourceStatic={staticPayload}
          setResource={setPayload}
          padding={0}
          vehicle={vehicle}
          isTelematicsAvailable={isTelematicsAvailable}
        />
        <Grid item xs={6}>
          <CustomTextField
            name='name'
            id='name'
            label={t('headers.name')}
            type='text'
            data-hook='vehicle_edit_name'
            value={payload.name}
            onChange={e =>
              setPayload({
                ...payload,
                name: e.target.value,
              })
            }
            variant='outlined'
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            name='reg_plate'
            id='reg_plate'
            label={t('headers.reg_plate')}
            data-hook='vehicle_edit_reg_plate'
            value={payload.reg_plate}
            onChange={e => {
              if (e.target.value.length > 12) {
                setErrors({
                  ...errors,
                  reg_plate: t('singleVehicleUpload.regPlateCharsWarning'),
                });
              } else if (e.target.value === '') {
                setErrors({
                  ...errors,
                  reg_plate: '',
                });
              } else {
                setErrors({ ...errors, reg_plate: null });
              }
              setPayload({
                ...payload,
                reg_plate: e.target.value,
              });
            }}
            variant='outlined'
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            error={errors['reg_plate'] !== null}
            helperText={errors['reg_plate']}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            name='internal_grouping'
            id='internal_grouping'
            label={t('headers.internal_grouping')}
            type='text'
            data-hook='vehicle_edit_internal_grouping'
            value={payload.internal_grouping}
            onChange={e =>
              setPayload({
                ...payload,
                internal_grouping: e.target.value,
              })
            }
            variant='outlined'
            InputProps={{
              inputProps: {
                min: 0,
              },
            }}
            fullWidth
          />
        </Grid>
        {axlePressureFieldsView()}
        <Grid item xs={6}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id='demo-simple-select-helper-label'>
              Select New Fleet Location
            </InputLabel>
            <Select
              disabled={isMerged}
              labelId='demo-simple-select-helper-label'
              id='demo-simple-select-helper'
              data-hook='select-new-location-dropdown'
              value={payload?.fleet_id}
              label='Select New Fleet Location'
              onChange={e =>
                setPayload({
                  ...payload,
                  fleet_id: e.target.value,
                })
              }
            >
              <MenuItem value=''>
                <em>None</em>
              </MenuItem>
              {renderFleets()}
            </Select>
            {isMerged && (
              <Typography>
                NOTE : This vehicle can't be moved please decouple it from
                merged vehicle
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  const renderFleets = () => {
    return fleets
      .filter(fleet => fleet.fleet_id !== fleet_id)
      .map((fleet, i) => {
        return (
          <MenuItem
            key={i}
            data-hook={`${fleet.fleet_name}`}
            value={fleet.fleet_id}
          >
            {fleet.fleet_name}
          </MenuItem>
        );
      });
  };

  const axlePressureFieldsView = () => {
    const changeHandler = (e, field) => {
      if (parseFloat(e.target.value) > 15 || parseFloat(e.target.value) < 5) {
        setErrors({
          ...errors,
          optimum_air_pressure: {
            ...errors.optimum_air_pressure,
            [e.target.name]: t('common.pressureValueError'),
          },
        });
      } else {
        setErrors({
          ...errors,
          optimum_air_pressure: {
            ...errors.optimum_air_pressure,
            [e.target.name]: null,
          },
        });
      }
      setPayload({
        ...payload,
        optimum_air_pressure: {
          ...payload.optimum_air_pressure,
          [field]: e.target.value,
        },
      });

      if (e.target.value === '') {
        const obj = { ...payload };
        let x = obj.optimum_air_pressure;
        delete x[field];
        if (JSON.stringify(x) === '{}') {
          obj.optimum_air_pressure = null;
        }
        setPayload(obj);
      }
    };
    if (vehicle.axle_type) {
      return optimalPressureByAxleType[vehicle.axle_type]?.map(
        (field, index) => (
          <Grid key={field} item xs={6}>
            <CustomTextField
              name={field}
              data-hook={`vehicle_edit_opt_pressure_${index}`}
              label={`Axle-${index + 1} Optimal Air Pressure`}
              value={payload.optimum_air_pressure?.[field]}
              onChange={e => changeHandler(e, field)}
              id={`pressure-${index + 1}`}
              type='number'
              required={!!payload?.optimum_air_pressure}
              InputProps={{
                inputProps: {
                  min: 5,
                  max: 15,
                  step: 0.1,
                },
              }}
              fullWidth
              error={!!errors?.optimum_air_pressure?.[field]}
              helperText={errors?.optimum_air_pressure?.[field]}
            />
          </Grid>
        )
      );
    }
  };

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
        data-hook='vehicle-delete-modal'
      >
        <Fade in={open}>
          <Paper className={classes.paper}>
            <Box display='flex' flexDirection='column' gap={'30px'}>
              <Typography
                variant='h4'
                align='center'
                data-hook='update_vehicle_info'
              >
                {t('vehiclePage.updateVehicleHeading')}
              </Typography>
              <form onSubmit={submitHandler}>
                {vehicleInfoFieldsView()}

                {payload.fleet_id && (
                  <Typography
                    data-hook='caution-message'
                    alignSelf={'center'}
                    color='red'
                  >
                    {t('common.updateCautionMsg')}
                  </Typography>
                )}
                <Grid item xs={12} marginTop={'40px'}>
                  <Box display='flex' gap={'10px'} alignSelf='center'>
                    <Button
                      variant='outlined'
                      color='primary'
                      className={classes.navBtns}
                      onClick={handleClose}
                      data-hook='cancel-update-button'
                    >
                      {t('common.actions.cancel')}
                    </Button>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.navBtns}
                      type='submit'
                      data-hook='update-vehicle-button'
                      disabled={
                        isSubmitting ||
                        Object.values(errors).some(error => error !== null) ||
                        payload.tank_capacity === ''
                      }
                    >
                      {isSubmitting ? (
                        <CircularProgress size={30} color='inherit' />
                      ) : (
                        <Typography variant='h6' align='center'>
                          {t('common.actions.update')}
                        </Typography>
                      )}
                    </Button>
                  </Box>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Fade>
      </Modal>
    </>
  );
};

export default VehicleEditModal;

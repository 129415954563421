export const daily = [
  {
    timestamp: 1662560828,
    missionProfile: 'Long distance +',
    average_speed: {
      value: 80,
      units: 'kmph',
    },

    total_fuel_burnt: {
      value: 250,
      units: 'Liters',
    },
    total_distance_driven: {
      value: 1000,
      units: 'km',
    },
    total_number_of_trips: {
      value: 12,
      units: 'Trips',
    },
    total_time_on_the_move: {
      value: 16,
      units: 'hrs',
    },
    heat_map: {
      value: 'Click to View',
      units: '',
    },
  },
  {
    timestamp: 1662360828,
    missionProfile: 'Long distance +',
    average_speed: {
      value: 72,
      units: 'kmph',
    },

    total_fuel_burnt: {
      value: 290,
      units: 'Liters',
    },
    total_distance_driven: {
      value: 1200,
      units: 'km',
    },
    total_number_of_trips: {
      value: 13,
      units: 'Trips',
    },
    total_time_on_the_move: {
      value: 17,
      units: 'hrs',
    },
    heat_map: {
      value: 'Click to View',
      units: '',
    },
  },
  {
    timestamp: 1662460828,
    missionProfile: 'Long distance +',
    average_speed: {
      value: 83,
      units: 'kmph',
    },

    total_fuel_burnt: {
      value: 200,
      units: 'Liters',
    },
    total_distance_driven: {
      value: 980,
      units: 'km',
    },
    total_number_of_trips: {
      value: 9,
      units: 'Trips',
    },
    total_time_on_the_move: {
      value: 13,
      units: 'hrs',
    },
    heat_map: {
      value: 'Click to View',
      units: '',
    },
  },
];
export const weekly = [
  {
    timestamp: 1662727443,
    mission_profile: 'regional',
    total_share_motorway: 53.977968176254585,
    total_share_regional: 38.3108935128519,
    total_share_urban: 7.466340269277846,
    average_speed: 75.59895216796072,
    minimum_change_in_elevation: null,
    average_change_in_elevation: null,
    maximum_change_in_elevation: null,
    total_fuel_burnt: null,
    total_co2_emissions: null,
    total_distance_driven: 0,
    total_number_of_trips: 0,
    average_fuel_consumption: 30,
    total_time_on_the_move: null,
    heat_map: null,
    idling_locations: null,
  },
  {
    timestamp: 1662124141,
    mission_profile: 'regional',
    total_share_motorway: 53.977968176254585,
    total_share_regional: 38.3108935128519,
    total_share_urban: 7.466340269277846,
    average_speed: 75.59895216796072,
    minimum_change_in_elevation: null,
    average_change_in_elevation: null,
    maximum_change_in_elevation: null,
    total_fuel_burnt: null,
    total_co2_emissions: null,
    total_distance_driven: 0,
    total_number_of_trips: 0,
    average_fuel_consumption: 30,
    total_time_on_the_move: null,
    heat_map: null,
    idling_locations: null,
  },
  {
    timestamp: 1661524141,
    mission_profile: 'regional',
    total_share_motorway: 53.977968176254585,
    total_share_regional: 38.3108935128519,
    total_share_urban: 7.466340269277846,
    average_speed: 75.59895216796072,
    minimum_change_in_elevation: null,
    average_change_in_elevation: null,
    maximum_change_in_elevation: null,
    total_fuel_burnt: null,
    total_co2_emissions: null,
    total_distance_driven: 0,
    total_number_of_trips: 0,
    average_fuel_consumption: 30,
    total_time_on_the_move: null,
    heat_map: null,
    idling_locations: null,
  },
];

export const new_resource = {
  status_code: 200,
  message: 'Daily report available for the vehicle',
  resource: {
    oyqurhc63: [
      {
        timestamp: 1662425404,
        mission_profile: 'regional',
        total_share_motorway: 53.977968176254585,
        total_share_regional: 38.3108935128519,
        total_share_urban: 7.466340269277846,
        average_speed: 75.59895216796072,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: 300,
        total_co2_emissions: 200,
        total_distance_driven: 140,
        total_number_of_trips: null,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661646794,
        mission_profile: 'regional',
        total_share_motorway: 0,
        total_share_regional: 0,
        total_share_urban: 100,
        average_speed: 90.854212008000003,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: 300,
        total_co2_emissions: 200,
        total_distance_driven: 140,
        total_number_of_trips: 4,
        average_fuel_consumption: 30,
        total_time_on_the_move: 8,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1663200544,
        mission_profile: 'regional',
        total_share_motorway: 42.64919941775837,
        total_share_regional: 48.03493449781659,
        total_share_urban: 9.315866084425037,
        average_speed: 61.320201583428975,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1663116255,
        mission_profile: 'regional',
        total_share_motorway: 41.72749391727494,
        total_share_regional: 45.742092457420924,
        total_share_urban: 12.408759124087592,
        average_speed: 65.01299757625488,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1663030162,
        mission_profile: 'regional',
        total_share_motorway: 38.59910581222057,
        total_share_regional: 48.8822652757079,
        total_share_urban: 12.518628912071536,
        average_speed: 56.28879457262311,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662943199,
        mission_profile: 'regional',
        total_share_motorway: 25.683060109289617,
        total_share_regional: 52.64116575591985,
        total_share_urban: 21.67577413479053,
        average_speed: 48.39628066211967,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662856799,
        mission_profile: 'regional',
        total_share_motorway: 0,
        total_share_regional: 0,
        total_share_urban: 100,
        average_speed: 9.854212008000003e-16,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662770399,
        mission_profile: 'regional',
        total_share_motorway: 0,
        total_share_regional: 0,
        total_share_urban: 100,
        average_speed: 9.854212008000003e-16,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662682936,
        mission_profile: 'regional',
        total_share_motorway: 34.93377483443709,
        total_share_regional: 43.377483443708606,
        total_share_urban: 21.52317880794702,
        average_speed: 51.95178221020678,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662595311,
        mission_profile: 'regional',
        total_share_motorway: 39.722863741339495,
        total_share_regional: 38.106235565819865,
        total_share_urban: 22.170900692840647,
        average_speed: 61.28954342365774,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662508978,
        mission_profile: 'regional',
        total_share_motorway: 31.639344262295083,
        total_share_regional: 44.91803278688525,
        total_share_urban: 23.442622950819672,
        average_speed: 55.076190244803776,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662340044,
        mission_profile: 'regional',
        total_share_motorway: 55.12820512820513,
        total_share_regional: 36.247086247086244,
        total_share_urban: 8.158508158508159,
        average_speed: 66.60632946458612,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662250044,
        mission_profile: 'regional',
        total_share_motorway: 0,
        total_share_regional: 0,
        total_share_urban: 100,
        average_speed: 9.854212008000001e-16,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662163644,
        mission_profile: 'regional',
        total_share_motorway: 0,
        total_share_regional: 0,
        total_share_urban: 100,
        average_speed: 9.854212008000003e-16,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1662077047,
        mission_profile: 'regional',
        total_share_motorway: 45.96774193548387,
        total_share_regional: 21.774193548387096,
        total_share_urban: 32.25806451612903,
        average_speed: 59.573124550744346,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661990402,
        mission_profile: 'regional',
        total_share_motorway: 48.39857651245551,
        total_share_regional: 32.50296559905101,
        total_share_urban: 18.74258600237248,
        average_speed: 68.85575663581412,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661904005,
        mission_profile: 'regional',
        total_share_motorway: 36.22704507512521,
        total_share_regional: 55.75959933222037,
        total_share_urban: 7.6794657762938225,
        average_speed: 58.43030131921753,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661818111,
        mission_profile: 'regional',
        total_share_motorway: 33.575581395348834,
        total_share_regional: 38.662790697674424,
        total_share_urban: 27.03488372093023,
        average_speed: 57.33431580141948,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661731896,
        mission_profile: 'regional',
        total_share_motorway: 48.87573964497041,
        total_share_regional: 44.73372781065088,
        total_share_urban: 5.9171597633136095,
        average_speed: 64.81513021441266,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661560394,
        mission_profile: 'regional',
        total_share_motorway: 0,
        total_share_regional: 0,
        total_share_urban: 100,
        average_speed: 9.854212008000001e-16,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661472001,
        mission_profile: 'regional',
        total_share_motorway: 47.088186356073216,
        total_share_regional: 36.43926788685524,
        total_share_urban: 16.306156405990016,
        average_speed: 69.94362016375476,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661386692,
        mission_profile: 'regional',
        total_share_motorway: 47.78761061946903,
        total_share_regional: 45.76485461441214,
        total_share_urban: 6.32111251580278,
        average_speed: 67.60228885861441,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661302506,
        mission_profile: 'regional',
        total_share_motorway: 37.07250341997264,
        total_share_regional: 49.247606019151846,
        total_share_urban: 13.132694938440492,
        average_speed: 56.2355614454974,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661213684,
        mission_profile: 'regional',
        total_share_motorway: 31.931464174454828,
        total_share_regional: 50.77881619937694,
        total_share_urban: 16.822429906542055,
        average_speed: 54.43845213862165,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661126415,
        mission_profile: 'regional',
        total_share_motorway: 52.437574316290124,
        total_share_regional: 39.71462544589774,
        total_share_urban: 7.253269916765754,
        average_speed: 62.95549933865803,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1661043127,
        mission_profile: 'regional',
        total_share_motorway: 0,
        total_share_regional: 0,
        total_share_urban: 100,
        average_speed: 9.854212008000001e-16,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1660956145,
        mission_profile: 'regional',
        total_share_motorway: 0,
        total_share_regional: 0,
        total_share_urban: 100,
        average_speed: 9.854212008e-16,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
      {
        timestamp: 1660897758,
        mission_profile: 'regional',
        total_share_motorway: 39.175257731958766,
        total_share_regional: 36.597938144329895,
        total_share_urban: 24.22680412371134,
        average_speed: 60.215932395518564,
        minimum_change_in_elevation: null,
        average_change_in_elevation: null,
        maximum_change_in_elevation: null,
        total_fuel_burnt: null,
        total_co2_emissions: null,
        total_distance_driven: 0,
        total_number_of_trips: 0,
        average_fuel_consumption: 30,
        total_time_on_the_move: null,
        heat_map: null,
        idling_locations: null,
      },
    ],
  },
};

export const keyValue = {
  mission_profile: null,
  total_share_motorway: '%',
  total_share_regional: '%',
  total_share_urban: '%',
  average_speed: 'kmph',
  total_distance_driven: 'km',
  total_number_of_trips: null,
  average_fuel_consumption: 'l/100km',
  maximum_change_in_elevation: '%',
  fuel_level_drop: '% tank',
  total_fuel_burnt: 'l',
  total_co2_emissions: 'kg',
  total_time_on_the_move: 'hrs',
};

export const increase = (num, reportType) => {
  if (reportType === 'daily') {
    if (num === daily.length - 1) {
      return 0;
    }
    return num + 1;
  } else {
    if (num === weekly.length - 1) {
      return 0;
    }
    return num + 1;
  }
};

export const decrease = (num, reportType) => {
  if (reportType === 'daily') {
    if (num === 0) {
      return daily.length - 1;
    }
    return num - 1;
  } else {
    if (num === 0) {
      return weekly.length - 1;
    }
    return num - 1;
  }
};

import React from 'react';
import styles from './PopElement.module.css';

function PopElement(props) {
  return (
    <div
      className={
        props.isLoading
          ? `${styles.popElement} ${styles.pop}`
          : styles.popElement
      }
    >
      {props.children}
    </div>
  );
}

export default PopElement;

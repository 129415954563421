import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { updateVehicleStatus } from 'services/fleet_service';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';

const status_list = [
  {
    label: 'Auslieferer',
    value: 'AL',
  },
  {
    label: 'Rücklieferer',
    value: 'RL',
  },
];
const VehicleStatusModal = ({
  open,
  handleClose,
  vehicle,
  fetchVehicleData,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState(
    vehicle?.metadata?.interchange?.status ?? null
  );
  const [name, setName] = useState(
    vehicle?.metadata?.interchange?.name ?? null
  );
  let dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      let temp = {
        ...vehicle.metadata,
        interchange: {
          status: status,
          name: name,
        },
      };

      const res = await updateVehicleStatus(temp, vehicle.vehicle_id);
      if (res?.status === 200) {
        dispatch(
          setNotification(
            t('common.change_availablity_success'),
            'success',
            true
          )
        );
        fetchVehicleData();
      } else {
        dispatch(
          setNotification(t('common.change_availablity_failed'), 'error', true)
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(setNotification(t('common.error'), 'error', true));
    } finally {
      setSubmitting(false);
      handleClose();
    }
  };

  //   try {
  //     setSubmitting(true);
  //     let temp = {
  //       ...vehicle.metadata,
  //       interchange: {
  //         status: status,
  //         name: name,
  //       },
  //     };

  //     const res = await updateVehicleStatus(temp, vehicle.vehicle_id);
  //     if (res?.status === 200) {
  //       dispatch(
  //         setNotification(
  //           t('common.change_availablity_success'),
  //           'success',
  //           true
  //         )
  //       );
  //       fetchVehicleData();
  //     } else {
  //       dispatch(
  //         setNotification(t('common.change_availablity_failed'), 'error', true)
  //       );
  //     }
  //   } catch (err) {
  //     console.error(err);
  //     dispatch(setNotification(t('common.error'), 'error', true));
  //   } finally {
  //     setSubmitting(false);
  //     setStatus(null);
  //     handleClose();
  //   }
  // };
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      open={open}
      onClose={handleClose}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'scroll',
      }}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
      data-hook='vehicle-delete-modal'
    >
      <Fade in={open}>
        <Paper style={{ width: '400', padding: '1rem 2rem' }}>
          <Typography
            variant='h5'
            align='center'
            data-hook='update_vehicle_info'
          >
            {t('common.change_availablity')}
          </Typography>
          <Select
            fullWidth
            style={{ margin: '1rem 0rem' }}
            labelId='demo-simple-select-outlined-label'
            id='demo-simple-select-outlined'
            value={status}
            onChange={e => setStatus(e.target.value)}
            label=''
          >
            {status_list.map((item, i) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>

          <TextField
            label={t('fleetsSection.customerName')}
            name={'customer_name'}
            id={'customer_name'}
            style={{ margin: '1rem 0rem' }}
            value={name}
            onChange={e => setName(e.target.value)}
            required
            variant={'outlined'}
            fullWidth
          />
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleSubmit()}
              fullWidth
              data-hook='update-vehicle-button'
            >
              {isSubmitting ? (
                <CircularProgress size={30} color='inherit' />
              ) : (
                <Typography variant='h6' align='center'>
                  {t('common.actions.update')}
                </Typography>
              )}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default VehicleStatusModal;

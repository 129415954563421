// import React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useCharts = () => {
  const { t } = useTranslation();
  const getPieCharts = useCallback(
    (data = {}) => {
      const { fleetOverviewRes } = data;

      const pieCharts = [];

      // Total Cost of Ownership Estimates
      const estimateData = [
        fleetOverviewRes?.costs?.operative_costs_eur?.total || 0,
        fleetOverviewRes?.costs?.operative_costs_eur?.fuel || 0,
        fleetOverviewRes?.costs?.operative_costs_eur?.tires || 0,
      ];

      pieCharts.push({
        key: 'total-cost-of-ownership-estimates',
        title: t('pieChart.costsOfOwnershipTitle'),
        labels: [
          t('pieChart.label1'),
          t('pieChart.label2'),
          t('pieChart.label3'),
        ],
        datasets: [
          {
            label: 'Costs',
            data: estimateData,
          },
        ],
        chartType: {
          nature: 'pie',
          attr: 'none',
        },
      });

      const assetLabels = [];
      const assetData = [];
      for (const key in fleetOverviewRes?.fleet_details?.asset_make || {}) {
        assetLabels.push(key);
        assetData.push(fleetOverviewRes?.fleet_details?.asset_make?.[key] || 0);
      }
      pieCharts.push({
        key: 'assets-make',
        title: t('pieChart.assetsMakeTitle'),
        labels: assetLabels,
        datasets: [
          {
            label: 'Class',
            data: assetData,
          },
        ],
        chartType: {
          nature: 'pie',
          attr: 'none',
        },
      });
      // -- Assets Make

      // Tire Brands
      const brandLabel = [];
      const brandData = [];
      fleetOverviewRes?.fleet_details?.tire_brands?.map(brand => {
        brandLabel.push(brand?.brand || '');
        brandData.push(brand?.nos || 0);
        return null;
      });
      pieCharts.push({
        key: 'tire-brands',
        title: t('pieChart.tireTitle'),
        labels: brandLabel,
        datasets: [
          {
            label: 'Class',
            data: brandData,
          },
        ],
        chartType: {
          nature: 'pie',
          attr: 'none',
        },
      });
      // -- Tire Brands

      return pieCharts;
    },
    [t]
  );

  const getBarCharts = useCallback(
    (data = {}) => {
      const { fleetOverviewRes } = data;
      const barCharts = [];

      // No of Tires
      const tiresData = [
        0,
        fleetOverviewRes?.fleet_details?.total_no_of_tires?.steer || 0,
        0,
        fleetOverviewRes?.fleet_details?.total_no_of_tires?.drive || 0,
        0,
        fleetOverviewRes?.fleet_details?.total_no_of_tires?.trailer || 0,
        0,
      ];
      barCharts.push({
        key: 'no-of-tires',
        title: t('common.noOfTyres'),
        labels: ['', 'Steer', '', 'Drive', '', 'Trailer', ''],
        datasets: [
          {
            // TODO: get right value
            label: 'Dataset',
            data: tiresData,
          },
        ],
        chartType: {
          nature: 'bar',
          attr: 'vertical',
        },
      });
      // -- No of Tires

      // Axle Types
      const assetLabel = [''];
      const assetData = [0];
      fleetOverviewRes?.fleet_details?.asset_types?.map(asset => {
        assetLabel.push(asset?.type || '');
        assetData.push(asset?.nos || 0);
        assetLabel.push('');
        assetData.push(0);
        return null;
      });
      barCharts.push({
        key: 'axle-types',
        title: t('common.axelTypes'),
        labels: assetLabel,
        datasets: [
          {
            // TODO: get right value
            label: 'Dataset',
            data: assetData,
          },
        ],
        chartType: {
          nature: 'bar',
          attr: 'vertical',
        },
      });
      // -- Axle Types

      return barCharts;
    },
    [t]
  );

  return {
    getPieCharts,
    getBarCharts,
  };
};

export default useCharts;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import NoSsr from '@mui/material/NoSsr';

import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useAutocomplete,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CheckIcon from '@mui/icons-material/Check';
import {
  filteringRecommendationOptions,
  InputWrapper,
  Listbox,
  Tag,
} from './const';
import { useNavigate, useParams } from 'react-router-dom';
import { getFilteredTireRecommendations } from 'services/fleet_service';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import { common, NA, VEHICLE } from 'utils/string_utils';
import { tiresBrandsSelector } from 'redux/tireInventory/ducks';
import {
  ourRecommendedTireConfigSelector,
  setOurRecommendationTireConfig,
} from 'redux/fleets/ducks';
import { useMemo } from 'react';
import i18next from 'i18next';
import {
  checkTireConfig,
  currencyFormatEUR,
  currencyFormatNonEU,
  currentDate,
} from 'utils/helper';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import OurRecommendationCard from './OurRecommendationCard';
import PropTypes from 'prop-types';
import CustomTable from 'components/custom/CustomTable';
import CustomizedCheckbox from 'components/custom/CustomCheckbox/CustomCheckbox';
import steer_logo from 'assets/svg/home/rec_steer.svg';
import trailer_logo from 'assets/svg/home/rec_trailer.svg';
import { IOSSwitch } from '../TireConfig/TireConfig';
export const formattedValue = value => {
  return i18next.language === 'de'
    ? currencyFormatEUR(parseFloat(value).toFixed(2)).slice(0, -1)
    : currencyFormatNonEU(parseFloat(value).toFixed(2)).slice(1);
};

export const formattedValueWithAbs = val => {
  return i18next.language === 'de'
    ? currencyFormatEUR(Math.abs(parseFloat(val).toFixed(2))).slice(0, -1)
    : currencyFormatNonEU(Math.abs(parseFloat(val).toFixed(2))).slice(1);
};

const NaNChecker = val => {
  if (isNaN(parseInt(val))) {
    return NA;
  }
  return val;
};

const useStyles = makeStyles()(theme => {
  return {
    table: {
      minWidth: 700,
    },
    head: {
      fontSize: 'clamp(22px, 1.5vw, 3rem)',
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      padding: '20px 0px',
    },
    navBtn: {
      textTransform: 'unset',
    },
    expand: {
      '&:hover': {
        backgroundColor: '#fafafa',
      },
    },
    filterButton: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
    },
    inputWrapperContainer: {
      width: '100%',
      position: 'relative',
    },
    inputWrapperList: {
      position: 'absolute',
      // top: theme.spacing(6),
    },
    alwaysTop: {
      position: 'absolute',
      zIndex: 9999,
    },
    noTires: {
      fontWeight: '900',
      fontSize: 'clamp(12px, 1vw, 2rem)',
    },
    card: {
      height: '100%',
      width: '100%',
      minHeight: '500px',
    },
    cardContainer: {
      width: '500px',
      minHeight: '250px',
      borderRadius: theme.spacing(1),
      borderColor: theme.palette.primary.main,
      border: '2px solid #69A628',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
    },
    or: {
      alignSelf: 'center',
    },
  };
});

const errorInitalState = { isError: false, errorMessage: '' };
// const REF = 'Reference';
const CustomisableTireRecommendations = ({
  isTracked,
  vehicleData,
  // payload,
}) => {
  const { classes } = useStyles();
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const brandsFetched = useSelector(tiresBrandsSelector);
  const ourRecommendedTireConfig = useSelector(
    ourRecommendedTireConfigSelector
  );

  // eslint-disable-next-line
  const [tireIsConfigured, setTireIsConfigured] = useState(true);
  const [tireIsPartiallyConfigured, setTireIsPartiallyConfigured] = useState();
  const { vehicle_type, vehicle_id } = params;
  const navigate = useNavigate();
  const [arr, setArr] = useState([]);
  const [ourRecommendationData, setOurRecommendationData] = useState([]);
  const [customizedRecommendationData, setCustomizedRecommendationData] =
    useState([]);
  const [arr1, setArr1] = useState([]);
  const [switchedOn, setSwitchedOn] = useState(false);
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
  } = useAutocomplete({
    id: 'excluded-brands',
    multiple: true,
    options: arr,
    getOptionLabel: option => option,
  });

  const {
    getRootProps: getRootProps2,
    getInputProps: getInputProps2,
    getTagProps: getTagProps2,
    getListboxProps: getListboxProps2,
    getOptionProps: getOptionProps2,
    groupedOptions: groupedOptions2,
    value: value2,
    focused: focused2,
    setAnchorEl: setAnchorEl2,
  } = useAutocomplete({
    id: 'included-tires',
    multiple: true,
    options: arr1,
    getOptionLabel: option => option,
  });
  // eslint-disable-next-line
  const today = currentDate();

  const [filterApplied, setFilterApplied] = useState(false);
  // eslint-disable-next-line
  const [filterType, setFilterType] = useState(common.ourRecommendation);
  const [error, setError] = useState({ ...errorInitalState });

  let tireRecommendationFilterNames = {};
  filteringRecommendationOptions.forEach(({ name }) => {
    tireRecommendationFilterNames[name] = false;
  });
  const [filtersChecked, setFiltersChecked] = useState({
    ...tireRecommendationFilterNames,
  });

  const filterOptions =
    params.vehicle_type === 'trucks' || params.vehicle_type === 'buses'
      ? filteringRecommendationOptions
      : filteringRecommendationOptions.filter(
          option => option.name !== 'same_brand'
        );
  useEffect(() => {
    if (brandsFetched !== null) {
      setArr(brandsFetched.sort());
      setArr1(brandsFetched.sort());
    }
  }, [brandsFetched]);

  useEffect(() => {
    if (!switchedOn) {
      setCustomizedRecommendationData([]);
      if (!ourRecommendationData?.length) createPayloadAndCall(true);
      setFilterApplied(false);
    } else {
      setFiltersChecked({ ...makeAllFiltersChecked() });
    }
    /*eslint-disable-next-line*/
  }, [switchedOn]);
  // eslint-disable-next-line
  const handleFilterType = event => {
    setFilterType(event.target.value);
    setError({ ...errorInitalState });

    // setShowMore(false);
  };

  const makeAllFiltersChecked = () => {
    let _filtersChecked = {};
    Object.keys(filtersChecked).forEach(key => {
      _filtersChecked[key] = true;
    });
    return _filtersChecked;
  };

  const handleCheckbox = (name, checked) => {
    setFiltersChecked({ ...filtersChecked, [name]: checked });
  };
  // eslint-disable-next-line
  const buyCell = {
    accessorKey: 'buy',
    header: t('tireRecommendation.header.buy'),
    enableColumnFilter: false,
    enableColumnActions: false,
    Cell: () => <ShoppingCartIcon onClick={() => {}} color='primary' />,
  };
  const generateCustomHeading = () => {
    if (
      vehicle_type === VEHICLE.vehicle_type.TRUCKS ||
      vehicle_type === VEHICLE.vehicle_type.TRUCK ||
      vehicle_type === VEHICLE.vehicle_type.BUSES ||
      vehicle_type === VEHICLE.vehicle_type.BUS
    ) {
      return [
        {
          key: 'steer_brand',
          title: t('vehiclePage.recommendation'),
          render: row => (
            <>
              <Box
                my={'0.5rem'}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                data-hook='steer-line'
              >
                <img
                  src={steer_logo}
                  alt='steer'
                  style={{ margin: '0rem 0.25rem' }}
                />
                <span style={{ display: 'block' }}>
                  {row.steer_brand + ' ' + row.steer_product}
                </span>
              </Box>

              <Box
                my={'0.5rem'}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                data-hook='trailer-line'
              >
                <img
                  src={trailer_logo}
                  alt='steer'
                  style={{ margin: '0rem 0.25rem' }}
                />
                <span style={{ display: 'block' }}>
                  {row.drive_brand + ' ' + row.drive_product}
                </span>
              </Box>
            </>
          ),
        },

        ...columns_new,
      ];
    } else
      return [
        {
          columns_new: 'trailer_brand',
          title: t('vehiclePage.recommendation'),

          render: row => (
            <Box
              my={'0.5rem'}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <img
                src={trailer_logo}
                alt='steer'
                style={{ margin: '0rem 0.25rem' }}
              />
              <span style={{ display: 'block' }}>
                {row.trailer_brand + ' ' + row.trailer_product}
              </span>
            </Box>
          ),
        },
        ...columns_new,
      ];
  };
  const columns_new = [
    {
      key: 'estimated_savings_potential_tco_lifetime',
      title: t('tireRecommendation.header.savingsInTco'),

      render: row => (
        <span
          style={{
            color:
              parseFloat(row.estimated_savings_potential_tco_lifetime).toFixed(
                2
              ) < 0
                ? 'red'
                : '#000',
          }}
        >
          {NaNChecker(
            formattedValueWithAbs(row.estimated_savings_potential_tco_lifetime)
          )}
        </span>
      ),
    },
    {
      key: 'estimated_savings_potential_tco_runtime',
      title: t('tireRecommendation.header.estimatedSavingsPotentialTco'),

      render: row => (
        <span
          style={{
            color:
              parseFloat(row.estimated_savings_potential_tco).toFixed(2) < 0
                ? 'red'
                : '#000',
          }}
        >
          {NaNChecker(
            formattedValueWithAbs(row.estimated_savings_potential_tco)
          )}
        </span>
      ),
    },
    {
      key: 'estimated_km_price_tires',
      title: t('tireRecommendation.header.estimatedKmPrice'),

      render: row => (
        <span
          style={{
            color:
              Math.round(parseFloat(row.estimated_km_price_tires)) < 0
                ? 'red'
                : '#000',
          }}
        >
          {NaNChecker(formattedValueWithAbs(row.estimated_km_price_tires))}
        </span>
      ),
    },
    {
      key: 'roi_in_months',
      title: t('tireRecommendation.header.amortization'),

      render: row => (
        <span
          style={{
            color:
              Math.round(parseFloat(row.estimated_km_price_tires)) < 0
                ? 'red'
                : '#000',
          }}
        >
          {NaNChecker(formattedValueWithAbs(row.roi_in_months))}
        </span>
      ),
    },
    {
      key: 'theoretical_mileage',
      title: t('tireRecommendation.header.theoreticalMileage'),

      render: row => (
        <span
          style={{
            color:
              Math.round(parseFloat(row.theoretical_mileage)) < 0
                ? 'red'
                : '#000',
          }}
        >
          {NaNChecker(formattedValueWithAbs(row.theoretical_mileage))}
        </span>
      ),
    },
    {
      key: 'avg_monthly_distance',
      title: t('tireRecommendation.header.monthlyDistance'),

      render: row => (
        <span
          style={{
            color:
              Math.round(parseFloat(row.avg_monthly_distance)) < 0
                ? 'red'
                : '#000',
          }}
        >
          {NaNChecker(formattedValueWithAbs(row.avg_monthly_distance))}
        </span>
      ),
    },
    {
      key: 'fuel_save_l_per_100km',
      title: t('tireRecommendation.header.fuelSaveLperKM'),

      render: row => (
        <span
          style={{
            color:
              Math.round(parseFloat(row.fuel_save_l_per_100km)) < 0
                ? 'red'
                : '#000',
          }}
        >
          {NaNChecker(formattedValueWithAbs(row.fuel_save_l_per_100km))}
        </span>
      ),
    },
    {
      key: 'estimated_price_all_tires',
      title: t('tireRecommendation.header.estimatedPriceAllTires'),

      render: row => (
        <span
          style={{
            color:
              Math.round(parseFloat(row.estimated_price_all_tires)) < 0
                ? 'red'
                : '#000',
          }}
        >
          {NaNChecker(formattedValueWithAbs(row.estimated_price_all_tires))}
        </span>
      ),
    },
  ];

  const customizedTableHeadings = useMemo(
    () => generateCustomHeading(),
    /*eslint-disable-next-line*/
    [vehicle_type, i18next.language]
  );

  const fetchRecommendations = async payload => {
    try {
      setIsLoading(true);
      setError({ isError: false, errorMessage: '' });
      let res;
      if (!switchedOn) {
        res = await getFilteredTireRecommendations(payload);
      } else {
        res = await getFilteredTireRecommendations(payload, true);
      }
      if (res?.status === 200) {
        if (switchedOn) {
          setCustomizedRecommendationData(res.data?.resp?.ret_obj);
        } else {
          setOurRecommendationData(res.data?.resp);
          dispatch(setOurRecommendationTireConfig(res.data?.resp, vehicle_id));
        }
      } else if (res?.status === 500) {
        setError({
          isError: true,
          errorMessage: t('common.error'),
        });
      } else {
        setError({
          isError: true,
          errorMessage: res?.data?.return_message,
        });
      }
    } catch (error) {
      setCustomizedRecommendationData([]);
      setError({
        isError: true,
        errorMessage:
          error?.data?.return_message || t('error.errorLoadingData'),
      });
    } finally {
      setIsLoading(false);
    }
  };
  const handleSwitchChange = event => {
    setSwitchedOn(!switchedOn);
  };

  const handleFilterApplied = async () => {
    setFilterApplied(true);
    createPayloadAndCall(false);
  };
  // eslint-disable-next-line
  const createPayloadAndCall = async allFilters => {
    let _payload = {
      vehicle_type:
        vehicle_type === VEHICLE.vehicle_type.BUSES
          ? vehicle_type.toUpperCase().slice(0, -2)
          : vehicle_type.toUpperCase().slice(0, -1),
      exclude: [],
      include: [],
      vehicle_id,
    };

    if (allFilters) {
      _payload = {
        ..._payload,
        ...makeAllFiltersChecked(),
      };
    } else {
      _payload = {
        ..._payload,
        ...filtersChecked,
        exclude: value,
        include: value2,
      };
    }
    if (tireIsPartiallyConfigured || vehicleData?.axle_type !== null) {
      await fetchRecommendations(_payload);
    }
  };
  useEffect(() => {
    if (vehicleData?.axle_type === null) {
      setTireIsConfigured(false);
    } else {
      if (checkTireConfig(vehicleData)) {
        setTireIsConfigured(true);
      } else {
        setTireIsPartiallyConfigured(true);
      }
    }
  }, [vehicleData]);

  useEffect(() => {
    if (brandsFetched !== null) {
      const latest = brandsFetched.filter(item => !value2?.includes(item));
      setArr(latest.sort());
    }
  }, [value2, brandsFetched]);

  useEffect(() => {
    if (brandsFetched !== null) {
      const latest = brandsFetched.filter(item => !value?.includes(item));
      setArr1(latest.sort());

      // eslint-disable-next-line
    }
  }, [value, brandsFetched]);

  useEffect(() => {
    if (!ourRecommendedTireConfig?.[vehicle_id]) {
      createPayloadAndCall(true);
    } else {
      // setOurRecommendationData(ourRecommendedTireConfig?.[vehicle_id]);
    }
    /*eslint-disable-next-line*/
  }, [ourRecommendedTireConfig]);

  const seeTireRecommendationHandler = () => {
    setTireIsPartiallyConfigured(false);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  if (!tireIsConfigured) {
    return (
      <Card className={classes.container}>
        <Typography
          variant='h6'
          align='center'
          gutterBottom
          color='error'
          className={classes.noTires}
        >
          {t('tireRecommendation.noTireSizeMessage')}
        </Typography>
      </Card>
    );
  }

  return (
    <Box my='1.5rem'>
      {isTracked ? (
        <Box>
          {tireIsPartiallyConfigured ? (
            <Paper
              style={{
                width: '100%',
                // height: '100%',
                padding: '16px 20px',
                boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
              }}
            >
              <Box my={1}>
                <Typography variant='subtitle1' fontWeight='bold' gutterBottom>
                  {t('common.ourRecommendation')}
                </Typography>
              </Box>
              <Typography variant='body1' color={'textSecondary'}>
                {t('tireRecommendation.info')}
              </Typography>

              <Button
                variant='outlined'
                style={{
                  textTransform: 'unset',
                  borderRadius: 30,
                  margin: '1rem 0rem',
                }}
                color='primary'
                onClick={seeTireRecommendationHandler}
              >
                {t('tireRecommendation.button')}
              </Button>
            </Paper>
          ) : (
            <>
              <Box p={1}>
                <Box
                  display='flex'
                  flexDirection={'row'}
                  width='100%'
                  justifyContent={'flex-end'}
                >
                  <FormControlLabel
                    control={
                      <IOSSwitch
                        name='checkedB'
                        checked={switchedOn}
                        onChange={handleSwitchChange}
                      />
                    }
                    label={t('common.customize')}
                  />
                </Box>
                {switchedOn && (
                  <>
                    <Paper
                      style={{
                        width: '100%',
                        padding: '16px 20px',
                        boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
                      }}
                    >
                      <Box my={1}>
                        <Typography
                          variant='subtitle1'
                          fontWeight='bold'
                          gutterBottom
                        >
                          {t('common.customize')}{' '}
                          {t('vehiclePage.recommendation')}
                        </Typography>
                      </Box>
                      <Box
                        display='flex'
                        flexWrap={'wrap'}
                        justifyContent='space-around'
                        style={{
                          padding: '10px 8px',
                          borderRadius: '12px',
                          background: 'var(--Light-grey, #F6F9F7)',
                        }}
                      >
                        {filterOptions.map(({ key, name }, i) => (
                          <Box
                            display='flex'
                            alignItems={'center'}
                            flexDirection='column'
                            gap={'0.5px'}
                            key={i}
                          >
                            <CustomizedCheckbox
                              name={name}
                              handleChange={handleCheckbox}
                              checked={filtersChecked[name]}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '2px',
                              }}
                            >
                              <Typography variant='caption'>
                                {t(`recommendationPage.${[key]}`)}
                              </Typography>
                              <Tooltip
                                title={t(
                                  `recommendationPage.captions.${[key]}`
                                )}
                                placement='top'
                                sx={{ cursor: 'pointer' }}
                              >
                                <Box
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    background: 'grey',
                                    borderRadius: '50%',
                                    width: '10px',
                                    fontSize: '8px',
                                    color: '#fff',
                                  }}
                                >
                                  ?
                                </Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                      <Grid
                        container
                        spacing={3}
                        style={{}}
                        sx={{
                          padding: '1rem 2rem',
                        }}
                      >
                        <Grid item lg={5}>
                          <NoSsr>
                            <div className={classes.inputWrapperContainer}>
                              <div {...getRootProps()}>
                                <InputWrapper
                                  ref={setAnchorEl}
                                  className={focused ? 'focused' : ''}
                                >
                                  {value.map((option, index) => (
                                    <Tag
                                      label={option}
                                      {...getTagProps({ index })}
                                    />
                                  ))}

                                  <input
                                    placeholder={t(
                                      'recommendationPage.exclusionPlaceholder'
                                    )}
                                    {...getInputProps()}
                                  />
                                </InputWrapper>
                              </div>
                              {groupedOptions?.length > 0 ? (
                                <div className={classes.alwaysTop}>
                                  <Listbox
                                    {...getListboxProps()}
                                    className={classes.inputWrapperList}
                                  >
                                    {groupedOptions.map((option, index) => (
                                      <li
                                        {...getOptionProps({ option, index })}
                                      >
                                        <span>{option}</span>
                                        <CheckIcon fontSize='small' />
                                      </li>
                                    ))}
                                  </Listbox>
                                </div>
                              ) : null}
                            </div>
                          </NoSsr>
                        </Grid>
                        <Grid item lg={5}>
                          <div className={classes.inputWrapperContainer}>
                            <div {...getRootProps2()}>
                              <InputWrapper
                                ref={setAnchorEl2}
                                className={focused2 ? 'focused' : ''}
                              >
                                {value2.map((option, index) => (
                                  <Tag
                                    label={option}
                                    {...getTagProps2({ index })}
                                  />
                                ))}

                                <input
                                  placeholder={t(
                                    'recommendationPage.inclusionPlaceholder'
                                  )}
                                  {...getInputProps2()}
                                />
                              </InputWrapper>
                            </div>
                            {groupedOptions2?.length > 0 ? (
                              <div className={classes.alwaysTop}>
                                <Listbox
                                  {...getListboxProps2()}
                                  className={classes.inputWrapperList}
                                >
                                  {groupedOptions2.map((option, index) => (
                                    <li {...getOptionProps2({ option, index })}>
                                      <span>{option}</span>
                                      <CheckIcon fontSize='small' />
                                    </li>
                                  ))}
                                </Listbox>
                              </div>
                            ) : null}
                          </div>
                        </Grid>
                        <Grid item lg={2}>
                          <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            sx={{
                              textTransform: 'unset',
                              borderRadius: 30,
                              marginTop: '6px',
                            }}
                            disableElevation
                            onClick={handleFilterApplied}
                          >
                            {t(`recommendationPage.applyFilter`)}
                          </Button>
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
              </Box>

              <Box p={1}>
                {switchedOn && filterApplied && customizedRecommendationData ? (
                  <>
                    {error?.isError ? (
                      <Typography variant='h6' color='secondary'>
                        {error.errorMessage}
                      </Typography>
                    ) : (
                      <>
                        <Box my={1}>
                          <Typography
                            variant='subtitle1'
                            fontWeight='bold'
                            gutterBottom
                          >
                            {t('vehiclePage.recommendation')}
                          </Typography>
                        </Box>
                        <CustomTable
                          columns={customizedTableHeadings}
                          data={customizedRecommendationData}
                        />
                      </>
                    )}
                  </>
                ) : !switchedOn && ourRecommendationData ? (
                  <>
                    <OurRecommendationCard
                      data={
                        (ourRecommendationData?.length > 0 &&
                          ourRecommendationData) ||
                        ourRecommendedTireConfig?.[vehicle_id]
                      }
                      columns={customizedTableHeadings}
                      vehicleType={vehicle_type}
                      isLoading={isLoading}
                      error={error}
                    />
                  </>
                ) : (
                  ''
                )}
              </Box>
            </>
          )}
        </Box>
      ) : (
        <Card className={classes.container}>
          <Button
            variant='text'
            color='primary'
            className={classes.navBtn}
            onClick={() => navigate('/telematics-data/provider-integration')}
          >
            <Typography
              variant='h6'
              align='center'
              gutterBottom
              color='error'
              className={classes.noTires}
            >
              {t('recommendationPage.noTelematicsMessage')}
            </Typography>
          </Button>
        </Card>
      )}
    </Box>
  );
};

export default CustomisableTireRecommendations;

CustomisableTireRecommendations.propTypes = {
  isTracked: PropTypes.any,
  vehicleData: PropTypes.object,
  payload: PropTypes.object,
};

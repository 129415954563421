import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Tooltip,
  useTheme,
  ClickAwayListener,
  Skeleton,
} from '@mui/material';

import Tile from '../../Tile';
import { Edit } from '@mui/icons-material';
import {
  setAvgConsumption,
  setFuelConsumptionSelector,
  setFuelCosts,
  setFuelCostsSelector,
} from 'redux/users/ducks';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles } from 'tss-react/mui';

const defaultHeight = 120;

const useStyles = makeStyles()(theme => {
  return {
    root: {
      color: '#343434',
      width: '100%',
      minWidth: '100%',
      borderRadius: 8,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      float: 'left',
      boxShadow: '0px 9.59195px 35.9698px rgba(0, 0, 0, 0.04)',
      height: '120px',
    },

    primaryLabel: {
      fontSize: '1.1rem',
      position: 'absolute',
      bottom: theme.spacing(1.5),
      lineHeight: '1.5rem',
      minHeight: '1.5rem',
      textTransform: 'capitalize',
    },
    secondaryValue: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    value: {
      fontSize: '1.7rem',
      fontWeight: 600,
      marginTop: theme.spacing(2),
      lineHeight: '2.19rem',
    },
    styledBoxContainer: {
      display: 'flex',
      gridGap: 5,
    },
    formInput: {
      position: 'absolute',
      top: 75,
      height: 30,
      maxWidth: 150,
    },
    iconAbsolute: { position: 'absolute', top: theme.spacing(2) },
  };
});

const KpiTile = props => {
  const { classes } = useStyles();
  const {
    icon,
    primaryLabel,
    secondaryValue,
    value,
    heightScale,
    border,
    paddingY,
    paddingX,
    children,
    isLoading,
    isEditable,
    iconAbsolute,
    particularHeight,
    labelColor,
    showTooltip = true,
  } = props;
  const theme = useTheme();
  return (
    <Tile>
      <Box
        className={classes.root}
        style={{
          border: border,
          height:
            particularHeight ||
            defaultHeight * heightScale + (heightScale - 1) * theme.spacing(2),
          paddingBlock: theme.spacing(paddingY),
          paddingLeft: theme.spacing(paddingX),
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {children ? (
            <>
              {/* {children} */}
              <CustomLabel
                text={primaryLabel}
                style={classes.primaryLabel}
                left={paddingX}
                isLoading={isLoading}
                canEdit={isEditable}
              />
            </>
          ) : (
            <>
              <Box
                display='flex'
                alignItems='center'
                alignSelf='flex-start'
                gap='10px'
              >
                {value ? (
                  <CustomLabel
                    isLoading={isLoading}
                    text={value}
                    style={classes.value}
                    canEdit={isEditable}
                    show={showTooltip}
                  />
                ) : (
                  ''
                )}
                {secondaryValue ? (
                  <CustomLabel
                    isLoading={isLoading}
                    text={secondaryValue}
                    style={classes.secondaryValue}
                    canEdit={isEditable}
                  />
                ) : (
                  ''
                )}
              </Box>
              {primaryLabel ? (
                <CustomLabel
                  isLoading={isLoading}
                  text={primaryLabel}
                  style={classes.primaryLabel}
                  left={paddingX}
                  canEdit={isEditable}
                  textColor={labelColor}
                />
              ) : (
                ''
              )}
            </>
          )}
        </Box>
        <Box marginRight={'30px'} marginTop={'10px'}>
          {icon && (
            <CustomIcon
              icon={icon}
              isLoading={isLoading}
              iconAbsolute={iconAbsolute}
            />
          )}
          <Box />
        </Box>
      </Box>
    </Tile>
  );
};

const CustomIcon = ({ isLoading, icon, iconAbsolute }) => {
  const { classes } = useStyles();
  return (
    <Box
      width='fit-content'
      className={iconAbsolute ? classes.iconAbsolute : ''}
    >
      {isLoading ? (
        <Skeleton variant='circular' height='50px' width='50px' />
      ) : (
        icon
      )}
    </Box>
  );
};

const CustomLabel = ({ text, style, isLoading, canEdit, textColor, show }) => {
  const [openEditBox, setOpenEditBox] = React.useState(false);
  const fuelConsumptionRef = useRef('');
  const fuelCostRef = useRef('');
  const dispatch = useDispatch();
  const costs = useSelector(setFuelCostsSelector);
  const consumption = useSelector(setFuelConsumptionSelector);
  const theme = useTheme();
  const { classes } = useStyles();

  const submitHandler = e => {
    e.preventDefault();
    const assumptions = { lsCosts: costs, lsConsumption: consumption };
    if (text.toString().includes('Liters')) {
      const newConsumption = fuelConsumptionRef.current.value;
      dispatch(setAvgConsumption(newConsumption));
      localStorage.setItem(
        'assumptions',
        JSON.stringify({
          ...assumptions,
          lsConsumption: newConsumption,
        })
      );
    } else {
      const newFuelCost = fuelCostRef.current.value;
      dispatch(setFuelCosts(newFuelCost));
      localStorage.setItem(
        'assumptions',
        JSON.stringify({
          ...assumptions,
          lsCosts: newFuelCost,
        })
      );
    }
    setOpenEditBox(false);
  };

  return (
    <>
      {openEditBox && canEdit && (
        <ClickAwayListener
          disableReactTree={true}
          onClickAway={() => setOpenEditBox(false)}
        >
          <form onSubmit={submitHandler}>
            <input
              className={classes.formInput}
              type='number'
              step='any'
              placeholder='Enter new value...'
              ref={
                text.toString().includes('Liters')
                  ? fuelConsumptionRef
                  : fuelCostRef
              }
            />
          </form>
        </ClickAwayListener>
      )}
      <Tooltip title={text} arrow disableHoverListener={!show ? true : false}>
        <StyledBox className={style}>
          <Box
            className={classes.styledBoxContainer}
            style={{ color: textColor ? textColor : '' }}
          >
            {isLoading ? <Skeleton variant='rectangular' /> : text}
            {canEdit &&
              (text.toString().includes('Liters') ||
                text.toString().includes('€')) && (
                <Box
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setOpenEditBox(true);
                  }}
                  fontSize={10}
                  color={theme.palette.primary.main}
                >
                  <Edit fontSize='small' />
                </Box>
              )}
          </Box>
        </StyledBox>
      </Tooltip>
    </>
  );
};

const StyledBox = withStyles(Box, {
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
  },
});

KpiTile.propTypes = {
  icon: PropTypes.any,
  primaryLabel: PropTypes.string,
  secondaryValue: PropTypes.string,
  value: PropTypes.any,
  heightScale: PropTypes.number,
  border: PropTypes.string,
  padding: PropTypes.number,
};

KpiTile.defaultProps = {
  primaryLabel: '-',
  secondaryValue: '',
  value: '',
  heightScale: 1,
  padding: 1,
  paddingY: 1.5,
  paddingX: 2.5,
};

export default KpiTile;

import React from 'react';
import { useTheme } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
export default function App({ labels, dataSets, title, showTotal }) {
  const theme = useTheme();
  const bgColorData = [
    theme.palette.primary.main,
    '#6c5b7b',
    '#355c7d',
    '#4d4d4f',
    '#43464B',
    '#36454F',
    '#2F4858',
    '#2B2D42',
    '#536162',
    '#435058',
    '#424642',
    '#333c47',
    '#3B3F3F',
    '#272838',
    '#3A3E3B',
    '#3E3D42',
  ];

  const borderColorData = [
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
    '#d2ddc7',
  ];
  const buildChartData = datasets => {
    let temp = [];
    for (let i = 0; i < datasets?.length; i++) {
      temp.push({
        label: datasets[i].label,
        data: datasets[i].data,
        borderColor: borderColorData.slice(0, datasets[i].data.length),
        backgroundColor: bgColorData.slice(0, datasets[i].data.length),
        borderWidth: 1,
      });
    }
    return temp;
  };
  const data = {
    labels: labels,
    datasets: buildChartData(dataSets),
  };
  const options = {
    marginLeft: '100px',
    responsive: true,
    maintainAspectRatio: false,
    title: {
      display: true,
      fontSize: 20,
    },
    legend: {
      display: true,
      position: 'right',
    },
    plugins: {
      legend: {
        position: 'left',
        align: 'center',
        labels: {
          boxWidth: 16,
          padding: 12,
        },
      },
    },
  };

  const textCenter = {
    id: 'text-center',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart;
      ctx.save();
      ctx.font = 'bold 20px sans-serif';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillStyle = '#000000';

      ctx.fillText(
        Math.round(data?.datasets[0]?.data?.reduce((a, b) => a + b, 0) * 1000) /
          1000,
        chart.getDatasetMeta(0).data[0]?.x,
        chart.getDatasetMeta(0).data[0]?.y
      );
    },
  };

  return (
    <Doughnut
      data={data}
      options={options}
      plugins={showTotal ? [textCenter] : []}
    />
  );
}

App.propTypes = {
  labels: PropTypes.array,
  dataSets: PropTypes.array,
  title: PropTypes.string,
  showTotal: PropTypes.bool,
};

import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Link } from '@mui/material';

import Layout from 'components/Layout';
import Loading from 'components/Loading';

import { verifyInvitedUser } from 'redux/users/ducks';
import { notificationSelector } from 'redux/notifications/ducks';
import { setStatusSelector } from 'redux/users/ducks';
import { NOTIFICATIONS, ROUTES } from 'utils/string_utils';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      margin: '0rem 0.5rem',
    },
    title: {
      fontSize: 14,
    },
    links: {
      color: '#007FFF',
      cursor: 'pointer',
    },
    main: {
      width: '100vw',
      height: '100vh',
      scrollBehavior: 'unset',
      backgroundColor: '#ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      height: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: theme.palette.common.white,
      flexDirection: 'column',
      width: '700px',
      borderRadius: theme.shape.borderRadius,
    },
  };
});

const VerifyInvitedUser = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  let { token } = useParams();
  const status = useSelector(setStatusSelector);
  let navigate = useNavigate();
  const { t } = useTranslation();

  const msg = useSelector(notificationSelector);
  useEffect(() => {
    async function userVerification() {
      dispatch(verifyInvitedUser(token));
    }
    userVerification();
    // eslint-disable-next-line
  }, []);
  const navigateRoute = () => {
    navigate(`${ROUTES.COMPLETE_SIGN_UP_BASE}/${token}`);
  };
  useEffect(() => {
    setTimeout(function () {
      if (status === 'verified') {
        navigateRoute();
      }
    }, 3000);
    // eslint-disable-next-line
  }, [status]);
  return (
    <Layout>
      {msg.message !== null ? (
        <Box className={classes.main}>
          <Box className={classes.content}>
            <>
              <Typography variant='h4' gutterBottom align='center'>
                {msg.message}
              </Typography>
            </>
            {msg.type === NOTIFICATIONS.success && (
              <>
                <Typography variant='h6' gutterBottom>
                  {t('verifyUser.redirectToSignUpPage')}
                </Typography>
                <Typography variant='h6' gutterBottom>
                  {t('verifyUser.ifNotRedirectedClick')}
                  <Link component='button' variant='h6' onClick={navigateRoute}>
                    {t('common.here')}
                  </Link>
                </Typography>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </Layout>
  );
};

export default VerifyInvitedUser;

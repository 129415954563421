import React, { useState } from 'react';
import { Close, Event } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import moment from 'moment';

const { DatePicker } = require('@mui/x-date-pickers');

function DatePickerField(props) {
  const {
    setOpen,
    label,
    value,
    id,
    disabled,
    ownerState,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  return (
    <FormControl
      variant='outlined'
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      fullWidth={ownerState.fullWidth}
    >
      <InputLabel>{label ?? ''}</InputLabel>
      <OutlinedInput
        margin={'normal'}
        label={label ? label : ''}
        required={ownerState.required}
        type={'text'}
        endAdornment={
          <InputAdornment position='end'>
            {value && !!ownerState.clear && (
              <IconButton onClick={ownerState.clear}>{<Close />}</IconButton>
            )}
            <IconButton onClick={() => setOpen?.(prev => !prev)}>
              {<Event />}
            </IconButton>
          </InputAdornment>
        }
        value={value ? moment(value).format(props.format ?? 'YYYY.MM.DD') : ''}
        onChange={null}
        onKeyDown={() => setOpen?.(prev => !prev)}
      />
    </FormControl>
  );
}

function CustomDatePicker(props) {
  const [open, setOpen] = useState(false);
  return (
    <Box position='relative'>
      <DatePicker
        slots={{ field: DatePickerField, ...props.slots }}
        slotProps={{ field: { setOpen } }}
        {...props}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        format={props.format ?? 'YYYY.MM.DD'}
      />
    </Box>
  );
}

export default CustomDatePicker;

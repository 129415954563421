import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Fade,
  Modal,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import Loading from 'components/Loading';
import { RowContent } from '../TireInventory';
import CustomTable from 'components/custom/CustomTable';
import { Cancel, Restore } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: 'fit-content',
      minWidth: '350px',
      maxHeight: '90%',
      border: 'none',
      display: 'flex',
      overflow: 'auto',
      padding: '2rem 3rem',
      position: 'relative',
    },
    infoBox: {
      padding: 10,
      boxShadow: '0 2px 2px rgba(0, 0, 0, 0.3)',
      borderRadius: 5,
      transition: 'boxShadow 0.3s ease-in-out',

      '&:hover': {
        boxShadow: '0 2px 2px rgba(0, 0, 0, 0.5)',
      },
    },
    closeIcon: {
      right: 0,
      color: 'rgba(0,0,0,0.5)',
      cursor: 'pointer',

      '&:hover': {
        transform: 'scale(1.1)',
      },
    },
    buttons: {
      textTransform: 'capitalize',
      minWidth: 85,
    },
  };
});

const NAComponent = ({ val }) => {
  return <span>{val || 'N/A'}</span>;
};

const TireEventHistoryModal = ({
  open,
  setOpen,
  data,
  setData,
  isLoading,
  setIsLoading,
  revertEventFn,
  isReverting,
  open2,
  setOpen2,
  setIsReverting,
  isReporter,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      {
        key: 'event_date',
        title: t('tireInventoryItems.titles.eventDate'),
        render: row => <NAComponent val={row?.event_date} />,
        size: 80,
        enableColumnActions: false,
      },
      {
        key: 'event_type',
        title: t('tireInventoryItems.titles.eventType'),
        render: row => <NAComponent val={row?.event_type} />,
        size: 80,
        enableColumnActions: false,
      },
      {
        key: 'km_driven',
        title: t('tireInventoryItems.titles.mileage'),
        render: row => <NAComponent val={row?.km_driven} />,
        size: 80,
        enableColumnActions: false,
      },
      {
        key: 'tread_depth',
        title: t('tireInventoryItems.titles.treadDepth'),
        render: row => <NAComponent val={row?.tread_depth} />,
        size: 80,
        enableColumnActions: false,
      },
      {
        key: 'position',
        title: t('tireInventoryItems.titles.position'),
        render: row => <NAComponent val={row?.position} />,
        size: 80,
        enableColumnActions: false,
      },
      {
        key: 'vehicle_name',
        title: t('tireInventoryItems.titles.vehicle'),
        render: row => <NAComponent val={row?.vehicle_name} />,
        size: 80,
        enableColumnActions: false,
      },
      {
        key: 'pressure',
        title: t('tireInventoryItems.titles.pressure'),
        render: row => <NAComponent val={row?.pressure} />,
        size: 80,
        enableColumnActions: false,
      },
    ],
    // eslint-disable-next-line
    []
  );

  const handleClose = () => {
    setOpen(false);
    setData(null);
    setIsLoading(false);
  };

  const closeModalHandler = () => {
    setOpen2(false);
    setIsReverting(false);
  };

  function revertButton() {
    return (
      !['CREATE', 'DISPOSE'].includes(data?.[0]?.event_type) &&
      !isReporter && (
        <Tooltip
          title={t('eventHistoryModal.revertEvent')}
          onClick={() => setOpen2(true)}
          data-hook='revert_button'
        >
          <Restore
            color='primary'
            style={{
              color: theme.palette.primary.main,
            }}
            className={classes.closeIcon}
          />
        </Tooltip>
      )
    );
  }

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Paper className={classes.paper} data-hook='event_history_modal'>
          {isLoading || isReverting ? (
            <Loading />
          ) : data?.length > 0 ? (
            <Box display='flex' flexDirection='column' gap={'10px'}>
              <Box display='flex' justifyContent='space-between'>
                <Box width={500} className={classes.infoBox}>
                  <RowContent
                    label={t('tireInventoryItems.titles.tire_id')}
                    value={data[0]?.tire_id}
                  />
                  <RowContent
                    label={t('tireInventoryItems.titles.brand')}
                    value={data[0]?.brand}
                  />
                  <RowContent
                    label={t('tireInventoryItems.titles.product_line')}
                    value={data[0]?.product_line}
                  />
                  <RowContent
                    label={t('tireInventoryItems.titles.size')}
                    value={data[0]?.size}
                  />
                </Box>
                <Box
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-between'
                >
                  <Cancel
                    className={classes.closeIcon}
                    onClick={handleClose}
                    data-hook='close_modal'
                  />
                  {revertButton()}
                </Box>
              </Box>
              <Box height='50%'>
                <CustomTable
                  columns={columns}
                  data={data || []}
                  pageSize={30}
                  isLoading={isLoading}
                  showTopToolbar={false}
                />
              </Box>
              <Dialog open={open2} onClose={closeModalHandler}>
                <DialogContent>
                  <Box display='flex' flexDirection='column' gap={'20px'}>
                    <Typography variant='h6'>
                      {t('eventHistoryModal.dialogMessage')}
                    </Typography>
                    <Box display='flex' gap={'10px'} alignSelf='center'>
                      <Button
                        variant='outlined'
                        size='small'
                        className={classes.buttons}
                        onClick={closeModalHandler}
                        data-hook='cancel_revert'
                      >
                        {t('common.cancel')}
                      </Button>
                      <Button
                        color='primary'
                        variant='contained'
                        className={classes.buttons}
                        onClick={revertEventFn}
                        disabled={isReverting}
                        data-hook='confirm_revert'
                      >
                        {isReverting ? (
                          <CircularProgress size={30} />
                        ) : (
                          t('common.confirm')
                        )}
                      </Button>
                    </Box>
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
          ) : (
            <div>{t('tireInventoryItems.noData')}</div>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

TireEventHistoryModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.array,
  setData: PropTypes.func,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  revertEventFn: PropTypes.func,
  isReverting: PropTypes.bool,
  open2: PropTypes.bool,
  setOpen2: PropTypes.func,
  setIsReverting: PropTypes.func,
  isReporter: PropTypes.bool,
};

export default TireEventHistoryModal;

// Rules

import { TIRES } from 'utils/string_utils';

// Always use [`] for wrapping string value
// Think of a common strings which can be reuse and put those inside common object
export const de = {
  common: {
    cautionMsg:
      'Achtung : Die hier vorgenommene Anpassung des Reifendrucks wirkt sich auf alle Fahrzeuge basierend auf ihrer Achskonfiguration aus',
    updateCautionMsg:
      'Achtung : Alle in diesem Fahrzeug vorhandenen montierten Reifen werden ebenfalls in eine neue Flotte übernommen',
    updateOpt: 'Aktualisieren Sie den optimalen Druck',
    assignResources: 'Ressource zuweisen',
    Unclassified: 'Nicht klassifiziert',
    optPressure: 'Optimaler Druck',
    rows: 'Zeilen',
    noVehiclesPresent: 'Keine Fahrzeuge vorhanden',
    noTiresPresent: 'Keine Reifen vorhanden',
    noTiresAdded:
      'Sie haben noch keine Reifen hinzugefügt. . Reifen verwalten auf ',
    noVehiclesAdded:
      'Sie haben noch keine Fahrzeuge hinzugefügt. Fahrzeuge verwalten auf  ',
    notelematicsIntegrated:
      'Sie haben die Telematik noch nicht integriert. Telematik einbinden am',
    inventory: {
      TireInventory: 'Reifenbestand',
    },
    showing: 'zeigen',
    of: 'von',
    mounted: 'montiert',
    Unmounted: 'Unmontiert',
    on: 'An',
    bulkUpload: 'Massenzuweisung',
    assignSuccessMsg: 'Ressourcen erfolgreich zuweisen',
    assignFailureMsg: 'Etwas ist schief gelaufen',
    mergedTrucksTrailers: 'Zusammengelegte LKW und Trailer',
    tireOverview: 'Reifen-Übersicht',
    vehicleOverview: 'Fahrzeug-Übersicht',
    inRange: 'in Reichweite',
    managedTires: 'Verwaltete Reifen',
    totalTiresInUse: `Reifen im Einsatz`,
    ourRecommendation: 'Unsere Empfehlung',
    customize: 'anpassen',
    pleaseApplyMoreFilter: 'Bitte weitere Filter anwenden!',
    here: 'hier',
    continue: `Fortsetzen`,
    continueWithTestAccount: 'Für Testkonto Hier klicken',
    createAccount: `Konto anlegen`,
    savingPotential: `Einsparpotenzial`,
    noCsv: `Keine .csv Datei gefunden`,
    uploadInstruction: `Legen Sie die .csv Datei hier ab oder klicken Sie auf Hochladen`,
    uploadBtn: `Hochladen`,
    integrationInfo: `Datenintegration`,
    fleetSettings: 'Flotteneinstellungen',
    personalSettings: 'Persönliche Einstellungen',
    profileSettings: 'Profileinstellungen',
    notificationSettings: 'Benachrichtigungen',
    userMgt: `Benutzerverwaltung`,
    tireMgt: `Reifenmanagement`,
    truck: 'LKW',
    trailer: 'Trailer',
    bus: 'Bus',
    retread: 'runderneuern',
    goPremium: `Um diese Funktion zu verwenden, wechseln Sie bitte zu Premium`,
    premiumBtn: 'Premium nutzen',
    perYear: 'pro Jahr',
    trucks: 'LKWs/Vans',
    per100Km: 'pro 100 Km',
    perLitre: 'pro Liter',
    seeMore: 'Siehe Mehr',
    co2Emission: 'CO2-Emissionen reduziert',
    savingsAchieved: 'Einsparungen erzielt',
    dataIntegration: 'Integration von Daten',
    noOfTyres: 'Anzahl der Reifen',
    noOfVehicles: 'Anzahl der Fahrzeuge',

    axelTypes: 'Achsen-Typen',
    termsAndCondition: 'Bedingungen und Konditionen',
    privacyPolicy: 'Datenschutzerklärung',
    allRightsReserved: 'Alle Rechte vorbehalten',
    configured: 'Konfiguriert',
    partialConfig: 'Teilkonfiguriert',
    notConfigured: 'Nicht konfiguriert',
    notConnected: 'Nicht verbunden',
    yes: `Ja`,
    no: `Nein`,
    submitBtn: `einreichen`,
    noVehicleFound: 'Kein Fahrzeug gefunden',
    showMore: 'Mehr anzeigen',
    showLess: 'Weniger anzeigen',
    sessionExpired: `Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an`,
    back: `Zurück`,
    error: `Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut`,
    noDataFound: 'Keine Daten gefunden',
    apply: `Anwenden`,
    telemtics_support:
      'Bitte kontaktieren Sie Ihren CO2OPT-Ansprechpartner, um Telematikdaten hinzuzufügen',
    confirm: `Bestätigen`,
    cancel: `Abbrechen`,
    next: `Nächste`,
    skip: `Überspringen`,
    configure: `Konfigurieren`,
    download: 'Herunterladen',
    mount: `Montieren`,
    unmount: `Demontieren`,
    approve: 'Genehmigen Sie',
    fleets: `Flotten`,
    wait: `Bitte warten . . .`,
    recentActivity: 'Neuste Aktivitäten',
    tire: 'Reifen',
    na: 'Nicht verfügbar',
    eg: 'Beispiel: ',
    results: 'Ergebnissen',
    axleName: 'Art der Achse',
    vehicles: 'Fahrzeuge',
    no_vehicles: 'Keine Fahrzeugdaten',
    frequency: {
      daily: 'Täglich',
      weekly: 'Wöchentlich',
      monthly: 'Monatlich',
      biannually: 'Halbjährlich',
      quaterly: 'Vierteljährlich',
    },
    axle_name: {
      steer: 'Vorderachse',
      drive: 'Antriebsachse',
      trailer: 'Trailer',
      bus: 'Bus',
    },
    rrc_reduction: 'RRC-Ermäßigung',
    savings_reduction: 'Ersparnis Runderneuerung',
    saved_regrooved: 'Eingesparte Runderneuerung',
    changelog: 'Änderungsprotokoll',
    change_availablity: 'Verfügbarkeiten ändern',
    change_availablity_success: 'Erfolgreich aktualisiert',
    change_availablity_failed:
      'Aktualisierung fehlgeschlagen, erneut versuchen',
    noVehicles:
      'In der ausgewählten Flotte sind keine Fahrzeuge oder Anhänger vorhanden. Bitte fügen Sie einige hinzu, um den Bericht herunterzuladen.',
    tireInventory: {
      tooShortError: `Die Reifen-ID muss mindestens 5 Zeichen lang sein.`,
      maxDepthError: `Die Profiltiefe darf ${TIRES.MAX_DEPTH} mm nicht überschreiten `,
      alphanumericError: `Bitte geben Sie nur Zahlen ein.`,
      noSpecialCharsMessage: `Bitte nur Zahlen und Buchstaben ohne Leerzeichen eingeben`,
      tireUploadedMsg: `Reifen erfolgreich hinzugefügt`,
      addTire: `Neuen Reifen hinzufügen`,
      addTires: 'Neue Reifen hinzufügen',
      uploading: 'hochladen',
      movedToStorageSuccess: `Erfolgreich in Lager verschoben`,
      tireId: `Reifen-ID`,
      optimum_pressure: 'Druck',
      mileage: `Aktueller Kilometerstand`,
      depth: `Profiltiefe`,
      size: `Reifengröße`,
      unmountMsgWarning: `Dies wird diesen Reifen demontieren und ihn in das Lager verschieben.`,
      confirmBtn: `Bestätigen`,
      editTooltip: `Reifen bearbeiten`,
      removeTooltip: `Reifen entsorgen`,
      moveTooltip: `Reifen demontieren`,
      viewEventHistory: `Reifen-Ereignisverlauf anzeigen`,
      inventoryEmpty: `Noch keine Reifen im Lager`,
      singleUpload: `Einzelnen Reifen hinzufügen`,
      multipleUpload: 'Mehrere Reifen hinzufügen',
      tireBrand: `Reifenmarke`,
      optPressure: 'Optimaler Druck für diese Position ist',
      showMore: `Mehr anzeigen`,
      showLess: `Weniger anzeigen`,
      productLines: `Produktlinien`,
      update: `Aktualisieren`,
      tireUpdateHeading: `Reifeninformationen aktualisieren`,
      updateRegrooveHeading: `Reifen nachschneiden`,
      uploadBtn: `Reifen hinzufügen`,
      getMore: `Mehr laden`,
      noBrands: `Keine Marken verfügbar`,
      no_of_tires: 'Anzahl der Reifen',
      steerTires: `Lenkachsen`,
      driveTires: `Antriebsachsen`,
      trailerTires: `Anhängerachsen`,
      manageTire: `Klicken Sie, um den Reifen zu verwalten`,
      uploadFile: `Datei hochladen`,
      disposeTires: `Reifen entsorgen`,
    },
    actions: {
      cancel: 'Abbrechen',
      update: 'Aktualisieren',
      delete: 'Löschen',
      download: 'Herunterladen',
    },
    and: 'und',
    imprint: 'Impressum',
    tnc: 'Nutzungsbedingung',
    pageTour: 'Seitenrundgang',
    mergedTrailer: 'Zusammengefasster Trailer',
    example: 'Beispiel',
    loadMessage: `Seite wird geladen ...`,
    deleteWarning: `Durch das Klicken auf Löschen stimmen Sie unseren AGB zu, gemäß denen Ihre gespeicherten Daten vollständig gelöscht werden und diese Aktion dauerhaft und unwiderruflich ist, ohne Möglichkeit der Wiederherstellung, unabhängig von den Kosten`,
    search: `Suchen`,
    addFleet: 'Fuhrpark hinzufügen',
    changeTire: 'Reifen wechseln',
    unmountTire: 'Reifen demontieren',
    success: `Die Operation war erfolgreich!`,
    vehicle: 'Fahrzeug',
    irregular_wear: 'Unregelmäßige Abnutzung',
    pressureValueError: 'Der Wert sollte zwischen 5 und 15 liegen',
  },
  verifyUser: {
    redirectToSignUpPage: 'Sie werden zur Anmeldeseite weitergeleitet',
    ifNotRedirectedClick:
      'Wenn Sie nicht automatisch weitergeleitet werden, dann klicken Sie bitte hier',
  },
  fleetCheck: {
    fleetCheckUpdateMsg: 'Flottencheck erfolgreich aktualisiert',
  },
  loginPage: {
    reduceTheCost: `Modernisieren Sie Ihr Fuhrparkmanagement`,
    reduceTheCostMsg: `Reduzieren Sie die Gesamtbetriebskosten und CO2-Emissionen durch unsere optimierten Reifenmanagementpläne`,
    signInToYourAccount: `Melden Sie sich mit Ihrem Konto an`,
    forgotPassword: `Passwort vergessen?`,
    or: `oder`,
    loginInAgreeMessageInitial:
      'Wenn Sie sich anmelden, akzeptieren Sie unsere',

    register: 'Registrieren',
    login: 'Anmelden',
    learnMore: 'Mehr erfahren',
    passwordComplexity: {
      number: 'Mindestens eine Nummer',
      specialCharacter: 'Mindestens ein Sonderzeichen',
      lowercase: 'Mindestens ein Kleinbuchstabe',
      uppercase: 'Mindestens ein Großbuchstabe',
      length: 'Mindestens 8 Zeichen',
    },
    invalid: 'Ungültige E-Mail',
    emailNotRegistered: 'Es ist kein Konto mit dieser E-Mail registriert',
    startSaving: 'Start',
    success: 'Erfolgreich eingeloggt',
    //TODO: German
    leadWallHeading: 'Take a minute to ...',
    leadWallButton: 'Weiter zur App',
    demo: `Demo anfordern`,
  },
  error: {
    406: 'Der Benutzer wurde aufgrund eines versuchten bösartigen Angriffs deaktiviert.',
    407: 'Unverifiziertes Konto. Bitte überprüfen Sie Ihre Mailbox (inklusive Spam-Ordner) auf eine E-Mail zur Benutzerverifizierung von uns.',
    403: 'Falsches Passwort. Maximal fünf Versuche.',
    402: 'Zu viele fehlgeschlagene Passworteingaben. Ihr Konto wurde vorübergehend aus Sicherheitsgründen deaktiviert. Fordern Sie ein neues Passwort über Passwort vergessen an.',
    400: 'Das Konto kann nicht mit dem Passwort angemeldet werden, da es bereits mit einem sozialen Konto angemeldet wurde',
    401: 'Falscher Benutzername',
    errorOccuredInformLogin:
      'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder benachrichtigen Sie den CO2OPT-Administrator.',
    errorLoadingData: 'Fehler beim Laden von Daten',
  },
  homePage: {
    on: 'An',
    overdue: 'Überfällig',
    completeYourCompanyProfile: `Vervollständigen Sie Ihr Profil`,
    completeYourCompanyProfileDesc: `Mit Ihrem vollständigen Profil können Sie die höchsten Einsparungen erzielen`,
    configureYourFleet: `Flotte konfigurieren`,
    savingPotential: `Einsparpotenzial`,
    status: {
      profile: `Profilstatus`,
      org: `Organisations-Info`,
      tireMgmt: `Reifenmanagement-Info`,
      generalAsset: `Fahrzeug-Info`,
      fleet: `Flotten-Info`,
      retreadStrategy: `Runderneuerungstrategie`,
    },
    fleetNotConfigured: `Flotte nicht konfiguriert`,
    infoBoardHeading: `Treffen Sie uns auf der IAA Transportation Messe in Hannover`,
    infoBoardSubtitle: `Wir sind in Halle 13/C2020`,
    appointmentBtn: `Termin vereinbaren`,
    bookAnAppointment: `Kostenlose Beratung`,
    configure: `Konfigurieren`,
    connectData: `Daten verbinden`,
    vehicleType: `Fahrzeugtyp`,
    tireInfo: `Reifen-Info`,
    liveTracking: `Live-Tracking`,
    nextActions: `Offene Aufgaben`,
    both: `Beide`,
    unconfigured: `Unkonfiguriert`,
    tracked: `Verfolgt`,
    untracked: `Nicht verfolgt`,
    connected: `Verbunden`,
    infoNeeded: 'Information erfordelich',
    actions: 'Maßnahmen',
    retreadStrategy: 'Runderneurungstrategie',
    confiqure: 'Konfigurieren',
    question: `Frage`,
    operative_costs_eur: {
      text1: `Reifen kosten Sie betrieblich `,
      text2: ` beeinflussen aber Ihre Kraftstoffrechnungen im Wert von `,
    },
    tire_related_savings_potential: {
      text1: `Sie können mindestens  `,
      text2: ` der Gesamtbetriebskosten in einem Jahr durch optimiertes Reifenmanagement einsparen`,
    },
    taskBoard: {
      createdAt: `Erstellungsdatum`,
      description: `Beschreibung`,
      source: `Quelle`,
      fleet: `Flotte`,
      title: `Titel`,
      taskTitleMany: ` Reifen mit Profiltiefe < 3mm`,
      taskTitleSingle: ` Reifen mit Profiltiefe < 3mm`,
      loading: `Aufgaben laden...`,
      actions: `Aktionen`,
      contactUs: `CO2OPT-Support kontaktieren`,
      changePayload: `Nutzlast ändern`,
      configureTires: `Reifen am Fahrzeug konfigurieren`,
      done: `Fertig`,
      after: `Nach dem Service`,
      before: `Vor dem Service`,
    },
    moreBtn: `Weitere Aktivitäten`,
    moreBtn2: `...Mehr sehen`,
    lessBtn: `Weniger sehen`,
    recentActivityHeading: `Letzte Aktivitäten`,
    lessThan6months: `Fällig in weniger als 6 Monaten`,
    dueIn6To12months: `Fällig innerhalb von 6 - 12 Monaten`,
    moreThan12months: `Fällig in mehr als 12 Monaten`,
    lessThan3Months: 'Weniger als 3 Monate',
    merged: `Zusammengeführt`,
    atBottom: `Kein neuen Nachrichten mehr`,
    initialAssessment: ` (Erste Bewertung)`,
    searchbar: {
      noResults: `Keine Ergebnisse für Ihren Suchbegriff gefunden`,
      placeholder: `Suche nach Fahrzeugnamen, Kennzeichen oder Reifen-ID`,
    },
    editHomeIcon: `Ändern, was sichtbar ist`,
    dashboardEditTitle: `Abschnitte zum Anzeigen auswählen`,
    dashboardSections: {
      recent_activities: `Neuste Aktivitäten`,
      savings_potential: `Einsparpotenzial`,
      task_board: `Offene Aufgaben`,
      tire_overview: `Fahrzeug-Übersicht`,
      tire_recommendation: `Reifenempfehlungen`,
      vehicle_overview: `Reifen-Übersicht`,
    },
  },
  dataIntegrationPage: {
    provider: `Anbieter`,
    auth: `Freigabe`,
    telematicsAltMessage: `Sie möchten Ihren Telematikanbieter noch nicht integrieren?`,
    manualUploadBtn: `Laden Sie stattdessen die Telematikdaten als .csv Datei hoch`,
    integratedProviders: `Integrierte Telematikanbieter`,
    noTelematicsMessage: `Kein Telematikanbieter integriert!`,
    submitBtn: `Bestätigen`,
    yellowfoxVehiclePlaceholder: `Geben Sie jeweils ein Fahrzeug ein`,
    credentialsToken: `Zugangstoken`,
    traceAvailable: `Historische Daten`,
    trackAvailable: `Live Daten`,
    infoLink: `Wo finde ich das?`,
    TOS: `Indem Sie die Anmeldedaten übermitteln, erklären Sie, dass Sie dazu im Namen Ihrer Organisation berechtigt sind und dass Sie die Bedingungen für die Nutzung der Telematikdaten akzeptieren, die in unseren Bedingungen und Konditionen bestehen`,
    detailsBtn: `Siehe Einzelheiten`,
  },
  csvReaderPage: {
    pageHeading: `Telematikdaten als CSV-Datei hochladen`,
    providerNameQtn: `Wie heißt der Telematikanbieter, von dem Sie die CSV-Daten erhalten haben?`,
    uploadHistoryHeading: `CSV-Uploadverlauf`,
    uploadHistoryMessage: `Laden Sie Telematikdaten als CSV-Datei hoch, um optimale Reifenkonfigurationen zu erhalten!`,
  },
  csvUploaderPage: {
    add: 'Hinzufügen',
    addMultipleTrucks: 'Fügen Sie mehrere LKWs hinzu',
    addMultipleTrailers: 'Fügen Sie mehrere Trailer hinzu',
    addMultipleBuses: 'Fügen Sie mehrere Busse hinzu',
    exampleFile: `Beispiel-Datei`,
    supportedFormats: 'Unterstützte Formate',

    sampleDownload: `Beispiel herunterladen`,
    individualUpload: 'Einzelnes Fahrzeug hinzufügen',
    bulkUpload: 'Mehrere Fahrzeuge hinzufügen',
    missingDataError: `Benötigte Spalte(n) enthalten keine Daten. Bitte überprüfen Sie die Datei!`,
    drag: 'Datei hier ablegen oder ',
    browse: 'durchsuchen',
    csv: 'CSV herunterladen',
    xlsx: 'XLSX herunterladen',
  },
  supportPage: {
    address: `Adresse`,
    talk: `Persönliches Gespräch`,
    genSupport: `Support`,
    formHeading: `Nachricht senden`,
    send: `Senden`,
    subject: `Thema`,
    message: `Nachricht`,
  },
  fleetOverviewPage: {
    tireProvider: `Reifenserviceanbieter`,
    deadline: `Bewerbungsfrist De-minimis`,
    telematics: `Telematikanbieter`,
    driverCard: `Fahrerkarte`,
    tank: `Tankkarte`,
    overview: `Übersicht`,
  },
  kpiList: {
    emission: `CO2 Emissionen`,
    fuelCosts: `Dieselkosten`,
    tireCosts: `Reifenkosten`,
    tonnes: `Tonnen`,
    adminCosts: `Administrationskosten Reifen`,
    assets: `Anzahl Fahrzeuge`,
    averageKm: `Laufleistung / Fahrzeug / Jahr`,
    averageFuelConsumption: `Durchschn. Dieselverbrauch`,
    assumedFuelCosts: `Durchschn. Dieselkosten`,
    totalCostOfOwnership: 'Gesamtbetriebskosten',
    tireNumber: `Anzahl der Reifen`,
    brandsAndLines: `Reifen - Marke und Produktlinien`,
    tDepth: `Reifen - Profiltiefe`,
    size: `Reifen - Größe`,
    telematics: `Fahrzeugübersicht - Telematik`,
  },
  pieChart: {
    costsOfOwnershipTitle: `Einschätzung der Kosten`,
    label1: `Sonstige Kosten`,
    label2: `Dieselkosten`,
    label3: `Reifenkosten`,

    assetsMakeTitle: `Fahrzeughersteller`,
    tireTitle: `Reifenmarken`,
  },
  barChart: {
    tireNumberTitle: `Anzahl Reifen`,
    axleTitle: `Achstypen`,
    vehicleOverview: `Fahrzeug-Übersicht`,
  },
  tabs: {
    home: `Home`,
    fleetsData: `Flotte`,
    support: `Hilfe`,
    reports: 'Berichte',
    settings: `Einstellungen`,
    tireinventory: `Reifenbestand`,
    logout: `Ausloggen`,
    overview: `Übersicht`,
  },
  customerSettingsPage: {
    tabs: {
      organization: 'Organisation',
      fleetCheck: 'Flottecheck',
      tire: 'Reifen',
    },
    organization: {
      name: 'Name',
      noOfYards: 'Anzahl der Standorte',
      zipcode: 'PLZ',
      city: 'Stadt',
      country: 'Land',
    },
    tire: {
      isHotRetread: 'Heißrunderneuert',
      isColdRetread: 'Kaltrunderneuert',
      isDeMinimis: 'De-minimis',
      preferredTireBrand1: 'Bevorzugte Reifenmarke 1',
      preferredTireBrand2: 'Bevorzugte Reifenmarke 2',
      preferredTire1Line: 'Bevorzugte Produktlinie 1',
      preferredTire2Line: 'Bevorzugte Produktlinie 2 ',
    },
    trailer: {
      noOfUnits: 'Anzahl der Einheiten',
      totalDistanceTravelled: 'Zurückgelegte Gesamtstrecke',
      selectAxelType: 'Achsentyp auswählen',
      selectTireType: 'Reifentyp auswählen',
    },
    truck: {
      noOfUnits: 'Anzahl der Einheiten',
      totalDistanceTravelled: 'Zurückgelegte Gesamtstrecke',
      selectAxelType: 'Achsentyp auswählen',
      selectTireType: 'Reifentyp auswählen',
    },
    bus: {
      noOfUnits: 'Anzahl der Einheiten',
      totalDistanceTravelled: 'Zurückgelegte Gesamtstrecke',
      selectAxelType: 'Achsentyp auswählen',
      selectTireType: 'Reifentyp auswählen',
    },
    actions: {
      cancel: 'Abbrechen',
      update: 'Aktualisieren',
    },
    fleets: {
      fleetCheckFrequency: 'Flottencheck-Resthäufigkeit (maximal: 365 Tage)',
      selectDays: 'Wählen Sie Tage aus',
    },
  },
  userSettingsPage: {
    fName: `Vorname`,
    lName: `Nachname`,
    orgName: `Firmenname`,
    email: `E-mail`,
    country: `Land`,
    deleteAcct: `Konto löschen`,
    exportData: `Daten exportieren`,
    notification: `Benachrichtigungen`,
    integration: `integrieren`,
    dataExp: `Datenexporte`,
    export: `exportieren`,
    changePassword: `Passwort ändern`,
    password: `Passwort`,
  },
  fleetsSection: {
    fleet: 'Flotte',
    upload: 'Hochladen',
    trailer: 'Trailer',
    filter: 'Filter/Suche',
    internalGrouping: 'Interne Gruppierung',
    options: 'Optionen',
    vehicleNumber: 'Fahrzeug Name',
    numberPlate: 'Kennzeichen',
    telematics: 'Telematikverbindung',
    notAvailbale: 'N/A',
    //
    configuration: 'Konfigurieren',
    make: 'Marke',
    vehicleType: 'Fahrzeugtyp',
    axleType: 'Achsentyp',
    currentTireConfiguration: 'Aktuelle Reifenkonfiguration',
    configureTires: 'Reifenkonfiguration',
    notConfigured: 'Nicht konfiguriert',
    integrateTelematics: 'Telematikintegration',
    usageReports: 'Nutzungsprofil',
    tireRecommendation: 'Reifenempfehlung',
    uploadMore: `Weitere Fahrzeuge hochladen`,
    navToPage: `Zur Fahrzeugseite gehen`,
    expand: `Erweitern`,
    searchBy: `Suche nach`,
    regNumber: `Registrierungsnummer`,
    changelogDownload: `Klicken Sie hier, um die letzten Änderungen an Ihren Fahrzeugen anzuzeigen`,
    vehicleNotAvailable: `Nicht vorhanden`,
    missingTireRecommendationData:
      'Um eine Reifenempfehlung zu erhalten, werden Berichte über die Reifenkonfiguration und -nutzung benötigt.  ',
    tireStatus: `Reifenstatus`,
    telematicsStatus: `Telematik-Status`,
    vehicleName: `Name des Fahrzeugs`,
    tracked: `Verfolgt`,
    untracked: `Unverfolgt`,
    partialConfig: 'Teilkonfiguriert',
    configured: 'Konfiguriert',
    mountedTires: 'Montierte Reifen',
    customerName: 'Kunde Name',
    deleteModal: {
      fleetNamePrompt: `Eingabe des Flottennamens `,
      title: `Flotte löschen`,
      fieldLabel: `Name der Flotte`,
    },
    export: `Exportieren in eine Excel-Datei`,
    tableauBtnInfo: `Zugang zu interaktiven Erkenntnissen`,
    tableHeaders: {
      name: 'Fahrzeugname',
      reg_plate: 'Kennzeichen',
      vehicle_type: 'Fahrzeugtyp',
      telematicsProvider: 'Telematikverbindungen',
      axle_type: 'Achstypen',
      internal_grouping: 'Interne Gruppierung',
    },
    tableauBtnName: `Reifen-Analytik`,
    bulkUpload: `Massen-Upload`,
    addTruck: `LKW hinzufügen`,
    addTrailer: `Anhänger hinzufügen`,
    addBus: `Bus hinzufügen`,
    edit: 'Bearbeiten',
    delete: 'Löschen',
    deleteFleet: `Flotte löschen`,
    moveVehicles: `Fahrzeuge verschieben`,
  },
  level1Modal: {
    vehicleName: 'Fahrzeugname',
    tiresizes: 'Reifengrößen',
    axleTypes: 'Achsentypen',
    step1: 'Fahrzeugkonfiguration',
    step2: 'Reifenkonfiguration',
    step3: 'Prüfen & Bestätigen',
    infoBannerApplyToVehicles: 'Ein / mehrere Fahrzeug(e) konfigurieren',
    vehiclesSelectionOption: 'Nur dieses Fahrzeug',
    show: `Anzeigen`,
    more: `Mehr`,
    less: `Weniger`,
    mountedDate: `Montagetag`,
    retreaded: `Runderneuerung`,
    treadDepth: `Profiltiefe`,
    applyToVehicle: `Konfiguration nur für diesen LKW `,
    selectOther: `Konfiguration für mehrere Fahrzeuge`,
    confirmApply: `Bestätigen`,
    next: `Weiter`,
    back: 'Zurück',
  },
  custom_dropzone: {
    mediaCenter: 'Dokumentenmappe',
    vehicleFiles: 'Fahrzeugdateien',
    infoText:
      'Drag`n`drop von Dateien oder klicken Sie hier, um Dateien auszuwählen',
    error: `Es ist ein Fehler aufgetreten. Stellen Sie sicher, dass die Dateigröße 5 MB nicht überschreitet, und versuchen Sie es erneut.`,
  },
  plansPage: {
    subAnnual: `Jährlich`,
    subMonthly: `Monatlich`,
    perMonth: `Pro Monat`,
    digitalTwin: `Digitaler Zwilling Ihrer Flotte`,
    dataIntegration: `Telematikdaten-Analyse live`,
    savings: `Geschätzte Einsparungen`,
    documentFetch: `Reifenservicedokumente abrufen`,
    yourPlan: `Ihr Plan`,
    all: `Alle`,
    features: `Services`,
    tireRecommendations: `Reifenempfehlung basierend auf Gesamtkostenanalyse`,
    serviceCenter: `Nächster Reifenserviceanbieter`,
    dataExport: `BI-Datenexport`,
    select: `Plan auswählen`,
    tireBooking: `Reifenbuchung`,
    tailored: `Passend für Ihr Unternehmen`,
    customizedFeatures: `Kundenindividuelle Services`,
    onRequest: `Preis auf Anfrage:`,
    featureRequests: `Bis zu 3 Services`,
    customTelematics: `Kundentelematik`,
    installation: `Anbindung Telematik`,
    contact: `Kontaktieren Sie uns`,
    monthBill: `Monatliche Rechnung`,
    annualBill: `Jährliche Rechnung`,
    specialOffer: `Limitiertes Angebot “InnoFleet”: Alle zukünftigen Funktionen enthalten bis 31.03.2023`,
    deminimis: `Automatische De-minimis Bewerbung`,
  },
  subscriptionBtn: `Upgrade`,
  dataRoom: {
    welcome: `Willkommen `,
    vehiclePhotos: `Fahrzeugfotos`,
    docs: `Servicedokumente`,
    noFiles: `Noch keine Dateien hochgeladen`,
    altUpload: `Vereinfachen Sie den Arbeitsablauf bei der Pflege Ihrer Fahrzeugdokumente mit einer der folgenden Optionen:`,
    whatsapp: `WhatsApp-Hotline zum Senden der Dateien:`,
    deleteConfirm: `Sind Sie sicher, dass Sie die Datei(en) löschen möchten?`,
    clear: `Auswahl entfernen`,
    uploadPhotosBtn: `Fotos hochladen`,
    uploadFilesBtn: `Dateien hochladen`,
    addFilesBtn: `Weitere Dateien hinzufügen`,
    addPhotosBtn: `Weitere Fotos hinzufügen`,
    uploadPhotoTitle: `Fahrzeug-Fotos`,
    uploadFilesTitle: `Fahrzeug-Service-Dokumente`,
  },
  nearestService: {
    heading: `Nächstes Reifenservice-Center`,
    noTelematics: `Fahrzeug noch nicht verfolgt. Um Ihren Telematikanbieter zu verknüpfen, klicken Sie HIER`,
    serviceUnavailable: `Die Standortinformationen für dieses Fahrzeug konnten nicht abgerufen werden. Bitte versuchen Sie es später erneut oder melden Sie dieses Problem.`,
  },
  releaseNotes: `Versionshinweise`,
  singleVehicleUpload: {
    add: 'Hinzufügen',
    vehicle: 'Fahrzeug',
    truck: 'LKWs / Van',
    trailer: 'Trailer',
    bus: 'Bus',
    next: 'Weiter',
    vehicleName: 'Fahrzeugname',
    vin: 'FIN',
    chassisNumber: 'Chassisnummer',
    fleetName: 'Flotten Name',
    selectFleet: 'Flotte wählen',
    chooseFleet: 'Flotte wählen',
    addNewFleet: 'Flotte hinzufügen',
    fleetLocation: 'Flottenstandort',
    tankCapacity: 'Tankkapazität',
    regPlate: 'Kennzeichen',
    internalGrouping: 'Interne Gruppierung ',
    cancel: 'Abbruch',
    addVehicleButton: 'Fahrzeug hinzufügen',
    duplicateError: `Ein oder mehrere eingegebene Daten werden bereits von einem anderen Fahrzeug verwendet. Bitte prüfen und erneut versuchen.`,
    success: `Fahrzeug erfolgreich hinzugefügt`,
    safetyCheckLabel: `Datum der Sicherheitsüberprüfung`,
    inspectionDateLabel: `Nächster Inspektionstermin`,
    odometerLabel: `Aktueller Stand des Kilometerzählers`,
    vinCharsWarning: `sollte mindestens 17 Zeichen lang sein`,
    vinCharsWarning2: `sollte nicht länger als 20 Zeichen sein`,
    single: `Einzeln`,
    multiple: `Mehreren`,
    regPlateCharsWarning: `Kennzeichen darf nicht mehr als 12 Zeichen lang sein`,
    axleType: `Achsart`,
    steer: `Vorderreifengröße`,
    drive: `Hinterreifengröße`,
    tireSize: `Reifengröße`,
  },
  vehiclePage: {
    resourceBtn: 'Ressource zuweisen',
    assignResources: 'Ressource zuweisen',
    bulkUpload: 'Massenzuweisung',
    assignSuccessMsg: 'Ressourcen erfolgreich zuweisen',
    assignFailureMsg: 'Etwas ist schief gelaufen',
    tireConfig: `Reifenkonfiguration`,
    vehicleFile: `Fahrzeugdatei`,
    reports: `Nutzungsberichte`,
    recommendation: `Reifenempfehlungen`,
    tireService: `Nächstes Reifenservice-Center`,
    grouping: `Gruppierung`,
    regNumber: `Registrationsnummer`,
    brand: `Fahrzeugmarke`,
    chassis: `Fahrgestellnummer:`,
    type: `Typ`,
    drawerBtn1: `LKW/Lieferwagen`,
    retread: `runderneuern`,
    tank: `Tankinhalt`,
    noAccessResponse: `Integrieren Sie die Telematik für Ihre Fahrzeuge, um auf diesen Bereich zuzugreifen`,
    updateSuccess: `Einträge erfolgreich aktualisiert`,
    tooltipUpdateIcon: `Fahrzeuginformationen aktualisieren`,
    tankCapacity: `Tankinhalt`,
    inspection: `Nächste Inspektion`,
    safetyCheck: `Nächster Sicherheitscheck`,
    odometer: `Stand des Kilometerzählers`,
    odometerLabel: 'Kilometerstand (Telematik)',
    mergeStatus: `Zusammenführungsstatus`,
    pending: `Fehlende Informationen aktualisieren`,
    dialogPromptFirst1: `Sie befestigen `,
    dialogPromptFirst2: `Sie lösen `,
    dialogPromptSecond1: ` an diesem LKW...`,
    dialogPromptSecond2: ` von diesem LKW...`,
    dialogTitle: `Neuen Trailer anhängen`,
    dialogTitle2: `Abkoppeln des Trailer`,
    selectLabel: `Verknüpfter Trailer: `,
    attachSuccess: `Trailer erfolgreich angehängt!`,
    steer: `Größe des Lenkradreifens`,
    drive: `Größe des Antriebsreifens`,
    tireSize: `Reifengröße`,
    axle: `Achse`,
    deleteModal: {
      truckNamePrompt: `Geben Sie den LKW-Namen ein `,
      trailerNamePrompt: `Geben Sie den Anhängernamen ein `,
      deleteTruck: `LKW löschen`,
      deleteTrailer: `Anhänger löschen`,
    },
    updateVehicleHeading: 'Aktualisieren',
  },
  platformInfoRoute: {
    scheduleMeeting: 'Termin vereinbaren',
    writeMessage: 'Email schreiben',
    callUs: 'Rufen Sie uns an',
    tutorials: 'Anleitungen',
  },
  tireConfigPage: {
    retreadConfigBtn: `Konfigurieren Sie die Runderneuerungstrategie`,
    brand: `Marke`,
    hotRetread: `Heißrunderneuerung`,
    coldRetread: `Kaltrunderneuerung`,
    changelogDownload: `Reifenhistorie herunterladen`,
    axlConfiguredSuccess: `Achse und Reifengröße wurden erfolgreich konfiguriert`,
    axleSelect: `Achsentyp auswählen`,
    tireSelect: `Reifengröße auswählen`,
    swapFail: `Tauschen fehlgeschlagen. Reifen passt nicht!`,
    more: `Mehr`,
    confirm: `Bestätigen`,
    configureTirePrompt: `Bitte konfigurieren Sie Ihre Reifen`,
    level2Modal: {
      noBrands: `Für diese Reifengröße sind keine Marken verfügbar`,
      tireBrand: 'Reifenmarken :',
      others: 'Weitere Marken',
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger anzeigen',
      productLines: 'Produktlinien',
      choose:
        'Für welche Konfiguration sollen die Reifendetails übernommen werden',
      tireOnly: 'Nur diese Position',
      axleOnly: 'Für die gesamte Achse',
      entireVehicle: 'Für das gesamte Fahrzeug',
      apply: 'Bestätigen',
    },
    moreOptionsModal: {
      safetyCheckLabel: `Datum der Sicherheitsüberprüfung`,
      inspectionDateLabel: `Nächster Inspektionstermin`,
      odometerLabel: `Aktueller Stand des Kilometerzählers`,
    },
    liftAxlBtn: `Lift-Achsen Konfigurieren`,
    liftAxle: {
      successMsg: `Lift-Achsen erfolgreich konfiguriert!`,
      errorMsg: `Alle Achsen können nicht angehoben werden!`,
      axle: `Achse`,
      lifted: `angehoben`,
      notLifted: `nicht angehoben`,
    },
    axleLifted: `Achse ist angehoben`,
    axleLowered: `Achse ist abgesenkt`,
    noRetreadMsg: `Runderneuern nicht konfiguriert`,
    tireRetreadInfo: `Reifen ist runderneuert`,
    headingLeft: `Reifenaufbau Details`,
    tableView: `Tabellenansicht`,
    updateTireBtn: `Reifen aktualisieren`,
    changeTireBtn: `Reifen wechseln`,
    unmountTireBtn: `Reifen demontieren`,
    uploadServiceReportBtn: `Servicebericht hochladen`,
  },
  fleetsDataPage: {
    fleet: 'Flotte',
    trucks: 'LKWs/Vans',
    buses: 'Busse',
    upload: 'Upload',
    trailer: 'Anhänger',
    noFleets: `Es sind keine Flotten verfügbar. Bitte wenden Sie sich an Ihren Admin`,
  },
  header: {
    homeDashboard: 'Home',
    telematics: 'Datenintegration',
    fleetsSection: `Flotte`,
    changelog: `Versionshinweise`,
    reports: 'Berichte',
    plans: `Pläne`,
    settings: `Einstellungen`,
    fleet: 'Flotte',
    inventory: 'Inventar',
    activities: 'Aktivitäten',
  },
  tileToolTip: {
    fleet: {
      trucks: 'Lädt',
      trailers: 'Lädt',
      vehicleInfo: 'Lädt',
    },
    telematics: {
      integratedProvides:
        'Dies sind die verschiedenen Telematik-Anbieter, die verfügbar sind, um Ihre Daten zu teilen',
      authorization:
        'Die erforderlichen Zugangsdaten zur Autorisierung im API-Gateway werden hier benötigt',
      connectedProviders: `Dies sind die Anbieter, die mit Ihrem Konto verbunden sind`,
    },
    home: {
      actionsRequired: 'Handlungen erforderlich',
    },
  },
  tour: {
    first:
      'Hier sehen Sie die konsolidierten Informationen über Ihren Fuhrpark in Bezug auf erforderliche Maßnahmen, Asset Management und geschätzte Einsparungen',
    second:
      'Hier können Sie die nahtlose Integration Ihres Telematik-Anbieters mit unserer App ermöglichen, indem Sie die Zugangsdaten mit nur zwei Klicks eingeben',
    third:
      'Hier können Sie Ihren Fuhrpark konfigurieren, indem Sie Fahrzeuge hinzufügen und deren Konfigurationen entweder einzeln oder per Massen-Upload aktualisieren',
    fourth:
      'Hier können Sie sich an uns wenden, um Hilfe und die notwendigen Informationen zu erhalten, um den Umfang des Produkts vollumfänglich zu verstehen',
    fifth:
      'Hier können Sie Ihr Abonnement von der Testversion auf ein Premium-Abonnement wechseln',
    page: {
      home: {
        actionsRequired:
          'Hier können Sie verschiedene Aktionen sehen, die für Ihre Fahrzeuge erforderlich sind.',
        savingPotential: 'Hier können Sie ihr Einsparpotential sehen.',
        homeBasicInfos:
          'Hier können Sie den Gesamtstatus Ihres Organisationsprofils sehen.',
      },
      telematics: {
        integratedTelematicsProvider:
          'Hier können Sie die Informationen zu den Telematikanbietern sehen.',

        provider:
          'Hier können Sie die unterstützten Telematikanbieter auswählen.',
        authorization:
          'Hier können Sie sich im Namen Ihrer Organisation autorisieren, Aufgaben auf Organisationsebene durchzuführen.',
      },
      fleet: {
        recentChanges:
          'Hier können Sie die neusten Änderungen an Ihren Flotten überprüfen.',
        trucks:
          'Hier können Sie LKWs hinzufügen oder ihre Konfigurationen einzeln oder per Massenupload aktualisieren.',
        trailers:
          'Hier können Sie Anhänger hinzufügen oder ihre Konfigurationen einzeln oder per Massenupload aktualisieren.',
        table:
          'Diese Tabelle zeigt Details zu allen Fahrzeugen dieser Flotte an. Sie können einzelne oder mehrere LKW und Anhänger hinzufügen, den Fuhrpark löschen, die letzten Änderungen herunterladen und auch zur Seite des jeweiligen Fahrzeugs navigieren, um es zu konfigurieren.',
      },
      vehiclePage: {
        tireConfig: 'Hier können Sie Reifen anzeigen und konfigurieren.',
        vehicleFile:
          'Hier können Sie die Fotos, Videos und Dateien Ihrer Fahrzeuge anzeigen und hochladen.',
        reports:
          'Hier können Sie die Nutzungsberichte Ihres Fahrzeugs einsehen.',
        recommendations:
          'Hier erhalten Sie auf der Grundlage von Analysen Empfehlungen für geeignete Reifen für Ihre Fahrzeuge.',
        tireService:
          'Hier können Sie den nächstgelegenen Reifenservice für Ihre Fahrzeuge sehen.',
        basicVehicleInfo:
          'Hier können Sie die grundlegenden Informationen zu Ihren Fahrzeugen einsehen.',
      },
      savingPotentials: {
        metricTile:
          'Hier können Sie die verschiedenen Metriken für Einsparpotenziale in einem interaktiven Format sehen.',
      },
      settings: {
        profileSettings: 'Hier können Sie Ihr Profil aktualisieren.',
        fleetSettings:
          'Hier können Sie die Einstellungen für Ihre Organisation und die von Ihrer Organisation verwendeten Reifenmarke, LKW, Anhänger oder Rückzugsstrategie aktualisieren.',
        userMgt: `Hier können Sie neue Benutzer einladen, vorhandene Benutzer löschen sowie zugewiesene Ressourcen verwalten.`,
      },
    },
  },
  retreadModal: {
    coldRetread: `Bevorzugen Sie kaltrunderneuerte Reifen?`,
    hotRetread: `Bevorzugen Sie heißrunderneuerte Reifen?`,
    casing: `Was ist Ihre bevorzugte runderneuerte Karkasse?`,
    retreadPreference: `Wird Runderneuerung bevorzugt?`,
    apply: `anwenden`,
    others: `andere`,
    otherTrucks: `Andere LKW einbeziehen`,
    otherTrailers: `Andere Anhänger einbeziehen`,
    otherBuses: `Andere Busse einbeziehen`,
    select: `auswählen`,
  },
  recommendationPage: {
    noTires: `Die Reifen für dieses Fahrzeug sind noch nicht konfiguriert`,
    noTiresMessage: `Konfigurieren Sie die Reifen und erhalten Sie am nächsten Tag Ihre Reifenempfehlung`,
    noTelematicsMessage: `Klicken Sie HIER, um die Telematik zu verbinden und datengesteuert Reifenempfehlungen zu erhalten`,
    noRecommendationMessage: `Erhalten Sie am nächsten Tag Ihre Reifenempfehlung`,
    tireMgt: `Reifenmanagement-Übersicht`,
    update: `Reifen aktualisieren`,
    book: `buchen`,
    exclusionPlaceholder: `Marken hier ausschließen...`,
    customizeRecommendations: 'Empfehlungen anpassen',
    runningReading: 'Laufleistung',
    retreadable: 'Runderneuerbar',
    rollingResistance: 'Rollwiderstand',
    fuelConsumption: 'brandstofverbruik',
    traction: 'Traktion',
    carcassValue: 'Karkassenwert',
    price: 'Preis',
    applyFilter: 'Filter anwenden',
    partialConfiguredMessage: `Unvollständiger Reifensatz. Bitte überprüfen Sie, ob alle Reifen montiert sind.`,
    captions: {
      runningReading:
        'Laufleistung wäre ein entscheidender Faktor bei den Empfehlungen (höhere Laufleistung ist besser)',
      retreadable:
        'Setzen Sie dies ein, wenn Sie möchten, dass Ihr Reifen wiederverwendbar ist',
      rollingResistance:
        'Setzen Sie dies ein, wenn Sie möchten, dass RRC Teil der Berechnungen ist (geringerer RRC ist besser)',
      traction:
        'Setzen Sie dies ein, wenn Sie Reifen mit besserer Traktion wünschen (dies legt eine untere Grenze für die Traktion der Reifen fest)',
      price:
        'Preis wäre ein entscheidender Faktor bei den Empfehlungen (günstiger ist besser)',
      fuelConsumption:
        'Aktivieren Sie dies, wenn Sie möchten, dass die Kraftstoffverbrauchsschätzungen für die Reifen eine Rolle bei den Berechnungen spielen (weniger Kraftstoff ist besser)',
      sameBrand:
        'Bei Lastkraftwagen und Bussen stellt dies sicher, dass die Marke an Lenk- und Antriebsachsen identisch ist',
    },
    sameBrand: 'Gleiche Marke',
  },
  retreadStrategy: {
    casting_type:
      'Welche Art von Karkasse möchten Sie für die runderneuerten Reifen Ihres Fuhrparks verwenden?',
    mount_type: 'Wo sollen die runderneuerten Reifen montiert werden?',
    delivery_schedule:
      'Welche Art von Lieferung wünschen Sie für Ihre runderneuerten Reifen?',
  },
  usageReportsPage: {
    nextDay: `Nächster Tag`,
    nextWeek: `Nächste Woche`,
    prevDay: `Gestern`,
    prevWeek: `Vorherige Woche`,
    noTelematicsMessage: `Klicken Sie HIER, um die Telematik zu verbinden und Nutzungsberichte auf datenbasierte Weise zu erhalten`,
    noReportsMessage: `Erhalten Sie am nächsten Tag die Nutzungsberichte`,
    noTiresMessage: `Konfigurieren Sie die Reifen und erhalten Sie am nächsten Tag die Nutzungsberichte`,
    download: `Bericht herunterladen`,
    noDataDay: `Keine Daten für das ausgewählte Datum`,
    noDataWeek: `Keine Daten für die ausgewählte Woche`,
    reportType: `Berichtstyp`,
    daily: `täglich`,
    weekly: `wöchentlich`,
    selectDay: `Datum auswählen`,
    selectWeek: `Woche auswählen`,
    kpiTitles: {
      mission_profile: `Missionsprofil`,
      average_speed: `Durchschnittsgeschwindigkeit`,
      minimum_change_in_elevation: `Minimale Änderung der Höhenlage`,
      average_change_in_elevation: `Durchschnittliche Änderung der Höhenlage`,
      maximum_change_in_elevation: `Maximale Änderung der Höhenlage`,
      total_fuel_burnt: `Gesamtbrennstoff Verbrannt`,
      total_co2_emissions: `Gesamte CO2-Emissionen`,
      total_distance_driven: `Gesamte Fahrstrecke`,
      total_number_of_trips: `Gesamtzahl der Fahrten`,
      average_fuel_consumption: `Durchschnittlicher Kraftstoffverbrauch`,
      total_time_on_the_move: `Gesamte Bewegungszeit,
      heat_map: Wärmekarte`,
      idling_locations: `Standorte mit Leerlauf`,
      '3PMS_needed': `3PMS Benötigt`,
      fuel_level_drop: `Kraftstoffpegelabfall`,
      'm+s_needed': `M+S Benötigt`,
      distribution: `Verteilung (%)`,
    },
  },
  uploadSummary: {
    vehicleUploadSummary: 'Fahrzeug-Upload Zusammenfassung',
    fleetName: 'Flottenname',
    vehicleName: 'Fahrzeugname',
    status: 'Upload-Status',
    result: 'Ergebnisse',
    downloadSummary: 'Download Zusammenfassung',
    back: 'Zurück',
    fileType: `Dateityp auswählen`,
  },
  kpiChart: {
    days: 'Tage',
    weeks: 'Wochen',
    download: 'Download',
  },
  telematicsModal: {
    title: `Fahrzeugtelematik-Informationen`,
    noVehicle1: `Derzeit ist kein registriertes Fahrzeug integriert`,
    noVehicle2: `Keine Fahrzeuge gefunden`,
    noAccess: `N/A...Dieses Fahrzeug wird Ihnen nicht zugewiesen.`,
    downloadMsg: `Erhalte eine Liste nicht registrierter Fahrzeuge im richtigen Format`,
  },
  multiLayerReport: {
    selectFleet: 'Flotte auswählen',
    selectFleet2: 'Flotte und Termin auswählen',
    selectVehicles: 'Fahrzeuge auswählen',
    selectKpis: 'Kpis auswählen',
    generateReport: 'Bericht generieren',
    downloadReport: 'Bericht herunterladen',
    noTrackedVehicles: `Keine verfolgten Fahrzeuge`,
    select: `Alle auswählen`,
    deselect: `Alle abwählen`,
    mission_profile: 'Einsatzprofil',
    total_share_motorway: 'Totaler Anteil Autobahn',
    total_share_regional: 'Totaler Anteil Regional',
    total_share_urban: 'Totaler Anteil urbane Straßen',
    total_number_of_trips: 'Totale Anzahl der Fahrten',
    average_speed: 'Durchschnittliche Geschwindigkeit',
    total_distance_driven: 'Totale Gesamtstrecke',
    tire_configuration: 'Reifenkonfiguration',
    waitMessage: `Bericht wird erstellt. Dies kann eine Weile dauern, bitte haben Sie etwas Geduld.`,
    waitMessage2: `Den letzten Schliff geben...`,
    overview: `Reifenübersicht`,
    reportType: 'Berichtstyp',
    startDate: `Startdatum (optional)`,
    endDate: `Enddatum (optional)`,
    reportTypes: {
      list: `Fahrzeugliste`,
      inventory: `Reifen-Inventar`,
      tireChangelog: `Änderungsprotokoll für Reifen`,
      vehicleChangelog: `Änderungsprotokoll für Fahrzeuge`,
      usage: `Fahrzeugnutzungsberichte`,
    },
    reportPageTitle: `Bericht`,
    create: `Erstellen`,
  },
  userManagement: {
    user: `Benutzer`,
    users: `Benutzer`,
    invite: `Neuen Benutzer erstellen`,
    inviteBtn: `Benutzer erstellen`,
    inviteDetails: `Geben Sie die Details des Benutzers ein, den Sie einladen möchten, und wählen Sie seine Rolle aus.`,

    assignResourceDetails: `Wählen Sie die Ressourcen aus, auf die der eingeladene Benutzer Zugriff haben soll`,
    assignResources: `Ressourcen zuweisen`,
    deleteResources: `Zuweisung aufheben`,
    lastLogin: `Letzter login`,
    actions: `Aktionen`,
    verified: `Bestätigt`,
    pending: `Ausstehend`,
    accessType: 'Zugangstyp',
    successDelete: `User deleted successfully`,
    deletePrompt1: `Bitte bestätigen Sie, dass Sie das Konto des eingeladenen Benutzers löschen möchten`,
    deletePrompt2: `. Bitte beachten Sie, dass diese Aktion möglicherweise nicht rückgängig gemacht werden kann.`,
    deleteTooltip: `Benutzer löschen`,
    editAccess: 'Barrierefreiheit bearbeiten',
    confirm: `bestätigen`,
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    role: `Rolle`,
    position: 'Position im Unternehmen',
    depot: 'Depot',
    locale: 'Gebietsschema',
    editTooltip: 'Bearbeiten Rolle',
    successEdit: `Benutzerrolle erfolgreich bearbeitet`,
    editRole: 'Bearbeiten Role',
  },
  assetsWhitelisting: {
    selectTrailer: `Anhänger auswählen`,
    selectTruck: `LKW auswählen`,
    success: `Operation erfolgreich!`,
    noTrucksToDel: `Dem Benutzer sind in dieser Flotte keine LKW zugewiesen`,
    noTrailersToDel: `Dem Benutzer werden in dieser Flotte keine Anhänger zugewiesen`,
    noTrucks: `Keine LKWs in der Flotte`,
    noTrailers: `Keine Anhänger in der Flotte`,
    noFleets: `Benutzer hat keine Flotten zugewiesen`,
    noBusesToDel: `Dem Benutzer sind in dieser Flotte keine Busse zugewiesen`,
    selectBus: `Bus auswählen`,
    noBuses: `Keine Busse in der Flotte`,
    currentlyAssigned: `Derzeit zugewiesen: `,
  },
  changePassword: {
    enter_email: 'Bitte geben Sie Ihre Email ein',
    logout: 'Sie sind dabei, sich abzumelden',
    change_password: 'Ändern Sie Ihr Passwort',
    message:
      ' Indem Sie auf "Absenden" klicken, erklären Sie sich mit unseren allgemeinen Geschäftsbedingungen einverstanden.',
  },
  tireOperationsModal: {
    updateBtn: `Update Reifen`,
    changeBtn: `Reifen wechseln`,
    unmountBtn: `In Lager verschieben`,
    availableTires: `Verfügbare Reifen`,
    noTiresMsg: `Keine Reifen verfügbar`,
    uploadTireSuggestion: `Reifen nicht in Ihrem Inventar? Laden Sie einen neuen Reifen hoch`,
    here: `Hier`,
    updateHeading: `Reifeninformationen aktualisieren`,
    unmountReasonLabel: `Grund für Demontage auswählen`,
    unmountDateLabel: `Demontage-Datum`,
    mountDateLabel: `Montagedatum`,
    unmountTireTitle: `Alten Reifen an der Achse demontieren`,
    mountTireTitle: `Neuen Reifen an der Achsenposition auswählen`,
    mountWarningMsg: `Durch Klicken auf diese Schaltfläche wird dieser Reifen bzw. diese Reifen in den ausgewählten Positionen montiert.`,
    eventDate: `Veranstaltungsdatum`,
    backToHome: `Zur Startseite`,
    unmountReasons: {
      worn: `Verschleiß`,
      repair: `Reparatur`,
      warranty: `Garantie`,
      smashed: `Anfahrverletzung`,
      puncture: `Einfahrverletzung`,
      store: `Einlagerung`,
    },
    mountOne: `An dieser Position montieren`,
    mountMany: `An mehreren Positionen montieren`,
  },
  deleteTire: {
    label: `Geben Sie die Reifen-ID ein`,
    prompt: `Geben Sie die Reifen-ID ein`,
    delete: `Löschen`,
    warning: `Diese Aktion entfernt diesen Reifen dauerhaft aus Ihrem Bestand.`,
    deleteSuccessful: `Reifen erfolgreich gelöscht`,
    warning2: `Diese Aktion entfernt diese Reifen dauerhaft aus Ihrem Bestand..`,
  },
  manageTire: {
    title: `Reifen-Aktionen`,
    repairBtn: `Reparieren`,
    retreadBtn: `Runderneuern`,
    regrooveBtn: `Rillen erneuern`,
    disposeBtn: `Entsorgen`,
    retreadComplete: `Runderneuerung abgeschlossen`,
    refurbished: `Reifen überholt`,
  },
  tireInventoryItems: {
    tDepthStorage: `Durchschnittliche Profiltiefe im Lager`,
    tDepthInUse: `Durchschnittliche Profiltiefe bei Verwendung`,
    avgTreadDepthPerTireStore:
      'Durchschnittliche Profiltiefe/Reifen (Lagerung)',
    avgTreadDepthPerTireInUse:
      'Durchschnittliche Profiltiefe/Reifen (in Gebrauch)',
    viewAllTires: 'Alle Reifen anzeigen',
    addTires: 'Reifen hinzufügen',
    filter: `Filter`,
    size: `Reifengröße`,
    status: `Reifenstatus`,
    vName: `Fahrzeugname`,
    maxDepth: `Maximale Profiltiefe`,
    wornOut: `Abgenutzt`,
    totalTires: 'Reifen insgesamt',
    unmerged: 'Nicht zusammengeführt',
    coupled: 'Gekoppelt mit LKW',
    titles: {
      mileage: `Laufleistung seit Montage (Km)`,
      name: `Fahrzeugname`,
      treadDepth: `Letzte Profiltiefe (mm)`,
      estTreadDepth: `Profiltiefeprognose (ca.)`,
      estimated_current_tread_depth: `Aktuellen Profiltiefeprognose (ca.) (mm)`,
      estimated_wear_out_date: `Verschleißdatum (ca.)`,
      mountDate: `Montagedatum`,
      mountDays: 'Tage in Nutzung',
      lastUpdate: `Letztes Update`,
      lastVehicleName: 'Letzter Fahrzeugname',
      eventDate: `Ereignisdatum`,
      eventType: `Ereignistyp`,
      position: `Montageposition`,
      vehicle: `Fahrzeug`,
      pressure: `Reifendruck`,
      actions: `Aktionen`,
      size: `Größe`,
      brand: `Marke`,
      product_line: `Produktlinie`,
      status: `Status`,
      tire_id: `Reifen-ID`,
      inUse: 'In Nutzung',
      inStore: 'Auf Lager',
      monthsLeft: `Monate übrig (ca.)`,
      axlePosition: `Achsenposition`,
      mission_profile: 'Missionsprofil',
      isRetread: `Runderneuert`,
      isRegrooved: `Nachgeschnitten`,
      tDepth: `Letzte Profiltiefe`,
      tDepthEst: `Letzte Profiltiefe (ca.)`,
      tire: `Reifen / Reifen-ID`,
      km: `Kilometerstand`,
      lastEventType: 'Letzter Ereignistyp',
    },
    noData: `Keine Daten verfügbar`,
    statuses: {
      IN_USE: 'In Benutzung',
      STORAGE: 'Lagerung',
      RECYCLING_BANK: 'Recyclingbank',
      RETREAD: 'Runderneuerung',
      REGROOVE: 'Im Lager',
      storage: `Speicher`,
      repair: 'Reparatur',
      recyclingBank: `Runderneuerung`,
      retread: `Runderneuerung`,
      all: `Alle`,
    },
    regrooved: `Nachgeschnitten`,
    dispose: `Entsorgen`,
  },
  swapAction: {
    success: `Reifen erfolgreich getauscht`,
    info: `Sie tauschen die Reifenpositionen aus. Klicken Sie auf die Schaltfläche unten, um die Aktion zu bestätigen.`,
  },
  maintencePage: {
    title: 'Unter Wartung',
    description:
      'Es werden derzeit planmäßige Wartungsarbeiten durchgeführt. Versuchen Sie es bitte zu einem anderen Zeitpunkt erneut.',
    apology: 'Entschuldigung für die Unannehmlichkeiten.',
  },
  tireRecommendation: {
    header: {
      estimatedPriceAllTires: 'Geschätzter Preis (alle Reifen)',
      estimatedKmPrice: 'Geschätzte Kosten/100Km (€)',
      fuelSaveLperKM: 'Kraftstoffeinsparung Liter/100km',
      theoreticalMileage: 'Theoretischer Kilometerstand',
      estimatedSavingsPotentialTco: 'Gesamtkostenreduzierung (€)',
      tcoRating: 'TCO Bewertung',
      trailerBrand: 'Reifen: Trailer',
      steerBrand: 'Reifen: Vorderachsreifen',
      driveBrand: 'Reifen: Antriebsachsreifen',
      buy: 'Kaufen',
      monthlyDistance: `Durchschnittliche monatliche Entfernung (km)`,
      savingsInTco: `Einsparungspotenzial bei den TCO (€)`,
      amortization: `Amortisationszeitraum (Monate)`,
    },
    info: `Wir benötigen das Fahrzeug vollständig konfiguriert für Empfehlungen die speziell auf Sie zugeschnitten sind und auf Ihrer Nutzung basieren. Fehlende Reifen hinzufügen`,
    button: `Siehe Empfehlungen ohne Reifenkonfiguration`,
    or: `ODER`,
    noTireSizeMessage: `Fehler bei der Reifenanalyse: Keine Reifengröße gefunden. Die Reifengrößen müssen für das Fahrzeug konfiguriert werden, um Empfehlungen geben zu können`,
  },
  escalateMessage: {
    successMessage:
      'Nachricht gesendet. Danke, dass Sie uns informiert haben! ',
    header: 'Melden Sie uns',
    iconTooltip: 'Melden Sie uns',
    message: 'Nachricht',
  },
  vehicleTypeDeleteModal: {
    remove: 'entfernen',
    trucks: 'LKWs',
    trailers: 'Trailers',
    confirm: 'Sind Sie sicher, dass Sie alles löschen wollen?',
    delete: 'löschen',
    terms_and_condition:
      'Mit dem Klick auf Löschen erklären Sie sich mit unseren AGB einverstanden, wonach Ihre gespeicherten Daten vollständig gelöscht werden. Dieser Vorgang kann danach nicht mehr rückgängig gemacht werden.',
  },
  eventHistoryModal: {
    revertSuccess: `Reifenereignis erfolgreich rückgängig gemacht`,
    dialogMessage: `Sie machen das letzte Ereignis an diesem Reifen rückgängig...`,
    revertEvent: `Letztes Ereignis rückgängig machen`,
  },
  tireForm: {
    brands: `Marken`,
    others: `Andere Marken`,
  },
  deleteUserModal: {
    nameLabel: `Geben Sie Ihren Vornamen ein`,
    deleteUserPrompt: `Durch das Löschen des Kontos werden alle gespeicherten Daten unwiderruflich gelöscht. Dies umfasst Kontoinformationen sowie zugehörige Inhalte. Die Daten können nicht wiederhergestellt werden. Bitte beachten Sie, dass Sie durch das Löschen Ihres Kontos auch unsere Nutzungsbedingungen akzeptieren. Diese besagen, dass Sie für den Verlust jeglicher Daten oder Informationen verantwortlich sind, die mit dem Löschen einhergehen.`,
    deleteVehicleWarning: `Durch das Löschen Ihres Fahrzeugs werden alle zugehörigen Daten dauerhaft gelöscht. Dies umfasst Fahrzeuginformationen und jeglichen zugehörigen Inhalt. Die gelöschten Daten können nicht wiederhergestellt werden. Bitte beachten Sie, dass Sie durch das Löschen Ihres Fahrzeugs auch unsere Allgemeinen Geschäftsbedingungen akzeptieren. In diesen Bedingungen wird festgelegt, dass Sie für etwaigen Datenverlust oder Informationsverlust, der mit der Löschung einhergeht, verantwortlich sind.`,
    deleteFleetWarning: `Durch das Löschen dieser Flotte werden alle zugehörigen Daten dauerhaft gelöscht. Dies umfasst Flotteninformationen und jeglichen zugehörigen Inhalt. Die gelöschten Daten können nicht wiederhergestellt werden. Bitte beachten Sie, dass Sie durch das Löschen dieser Flotte auch unsere Allgemeinen Geschäftsbedingungen akzeptieren. In diesen Bedingungen wird festgelegt, dass Sie für etwaigen Datenverlust oder Informationsverlust, der mit der Löschung einhergeht, verantwortlich sind.`,
  },
  deleteResource: {
    truckName: `LKW-Name`,
    trailerName: `Anhängername`,
    fleetName: `Flottenname`,
  },
  tiresUpload: {
    brandMissing: `Reifenmarke nicht gefunden?`,
    enterBtn: `Manuell eingeben`,
    brand: `Reifenmarke`,
    productLine: `Produktlinie`,
  },
  headers: {
    name: 'Name',
    vin: 'Fahrgestellnummer',
    fleet_name: 'Flottenname',
    reg_plate: 'Kennzeichen',
    tank_capacity: 'Tankkapazität',
    fleet_location: 'Flottenstandort',
    internal_grouping: 'Interne Gruppierung',
    chassis_number: 'Fahrgestellnummer',
    odometer_reading: 'Kilometerstand (optional)',
    inspection_date: 'Inspektion (optional)',
    safety_check_date: 'Sicherheitscheck (optional)',
    axle_type: 'Achsart (optional)',
    tire_size: 'Reifengröße (optional)',
    steer_tire_size: 'Vorderreifengröße (optional)',
    drive_tire_size: 'Hinterreifengröße (optional)',
  },
  filterComponent: {
    filter: `Filtern`,
    clear: `Alles löschen`,
    zeroMatches: `Keine Treffer`,
  },
  newFleetModal: {
    heading: 'Neue Flotte hinzufügen',
    fleetName: 'Flottenname',
    yards: 'Anzahl der Lagerhäuser',
    zip: 'Postleitzahl',
    country: 'Land auswählen',
    city: 'Stadt eingeben',
    cancel: 'Abbrechen',
    confirm: 'Bestätigen',
    location: `Fahrzeugstandort`,
  },
  settingsPage: {
    tireSettings: {
      header: 'Reifenpreise',
      priceId: 'Reifenpreis',
      tireSize: 'Reifengröße',
      brand: 'Marke',
      productLine: 'Produktlinie',
      addTireLabel: 'Reifenpreis hinzufügen',
      createTirePrice: 'Reifenpreis erstellen',
      updateTirePrice: 'Reifenpreis aktualisieren',
      tirePrice: 'Reifenpreis',
    },
    notifications: {
      heading: 'E-Mails abonnieren oder abbestellen...',
      selectUser: 'Nutzer wählen',
      weeklyLabel: 'Zusammenfassung der Organisation',
      fleetCheckRemainder: 'Verbleibender Flottenscheck',
      userReport: 'Benutzerzusammenfassung',
      weeklyBody:
        'Erhalten Sie E-Mails mit einer Zusammenfassung unternehmensweiter Aktivitäten.',
      fleetCheckDesc:
        'Erhalten Sie E-Mails über Aktivitäten zur Flottenüberprüfung',
      tDepth: 'Geringes Reifenprofil',
      tDepthBody:
        'Erhalten Sie eine E-Mail-Zusammenfassung, wenn die Profiltiefe des Reifens zwischen 3 und 5 mm oder unter 3 mm liegt.',
      userBodyDesc:
        'Otrzymuj e-maile ze spersonalizowanym podsumowaniem Twoich działań',
      select: 'Tage auswählen',
      selectDay: `Tag auswählen`,
      daily: 'täglich',
      weekly: 'wöchentlich',
      monthly: 'monatlich',
      biannually: 'halbjährlich',
      days: {
        monday: `Montag`,
        tuesday: `Dienstag`,
        wednesday: `Mittwoch`,
        thursday: `Donnerstag`,
        friday: `Freitag`,
        saturday: `Samstag`,
        sunday: `Sonntag`,
      },
    },
  },
  tableauPage: {
    errorMessage: `Fehler beim Abrufen von Daten!`,
    refresh: `Erneut versuchen`,
  },
  axles: {
    '1-axle': '1-Achse',
    '2-axle': '2-Achsen',
    '3-axle': '3-Achsen',
    '4-axle': '4-Achsen',
    '5-axle': '5-Achsen',
    '1-axle-twin': '1-Achs-Zweier',
    '2-axle-twin': '2-Achs-Zweier',
    null: 'N/A',
  },
  treadDepthSource: {
    EST: 'Dieser Wert ist unsere beste Annäherung',
    GT: 'Dieser Wert stammt aus realen Daten',
  },
  columnsEdit: {
    update: `Aktualisieren`,
    editBtn: `Spalten bearbeiten`,
    error: `Zu viele Spalten zum Anzeigen, bitte entfernen Sie eine oder mehrere`,
  },
  grooveTD: {
    selectGroove: 'Möchten Sie die Profiltiefe der Rillen erhöhen?',
    grooveTreadDepth: 'Rillenprofiltiefe',
  },
  guidesPage: {
    title: {
      telematics: `Telematik einrichten`,
      upload_vehicles: `Fahrzeuge hochladen`,
      addTires: `Einzelnen Reifen hinzufügen`,
      'user-mgt': `Kollegen einladen`,
      notifications: `Benachrichtigungen einrichten`,
      'fleet-settings': `Optimalen Druck einrichten`,
    },
    description: {
      telematics: `Wählen Sie Ihren Telematikanbieter und integrieren Sie ihn in unsere App`,
      upload_vehicles: `Fügen Sie Ihre LKWs, Anhänger oder Busse einzeln hinzu oder laden Sie eine Liste hoch`,
      addTires: `Fügen Sie einen Reifen zu Ihrem Inventar hinzu`,
      'user-mgt': `Laden Sie Personen ein, die App zu nutzen, und geben Sie ihnen unterschiedliche Zugriffsrechte`,
      notifications: `Abonnieren oder Abbestellen von E-Mail-Benachrichtigungen`,
      'fleet-settings': `Stellen Sie den optimalen Reifendruck je Achse ein`,
    },
    heading: `Benutzerhandbücher`,
    pageTitle:
      'Machen Sie sich mit der App vertraut und erledigen Sie Aufgaben...',

    tour: {
      notifications:
        'Hier können Sie die E-Mail-Benachrichtigungen verwalten, die Sie erhalten',
      optimalPressure:
        'Klicken Sie hier, um den optimalen Reifendruck festzulegen/zu aktualisieren',
      invite: 'Laden Sie hier einen Kollegen ein',
      addVehicle:
        'Fügen Sie ein einzelnes Fahrzeug oder mehrere gleichzeitig hinzu',
      addTire:
        'Fügen Sie einen einzelnen Reifen oder mehrere gleichzeitig hinzu',
    },
  },
};

export default de;

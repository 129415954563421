import React, { useState } from 'react';

import { deleteVehicle } from 'services/fleet_service';
import { setNotification } from 'redux/notifications/ducks';
import { useSelector, useDispatch } from 'react-redux';
import { setAccessTokenSelector } from 'redux/users/ducks';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DeleteResource from 'components/DeleteResource/DeleteResource';

const VehicleDeleteModal = ({
  open,
  setOpen,
  vehicle,
  fetchFleetVehicle = () => {},
}) => {
  const [vehicleName, setVehicle] = useState('');
  const dispatch = useDispatch();
  const access_token = useSelector(setAccessTokenSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async e => {
    setIsSubmitting(true);
    e.preventDefault();

    try {
      const res = await deleteVehicle(access_token, vehicle);
      if (res.status === 200) {
        dispatch(
          setNotification('Vehicle Successfully Deleted', 'success', true)
        );
        handleClose();
        const status = await fetchFleetVehicle(false);
        if (status === 200) {
          navigate(`/fleets-data/${vehicle.fleet_id}`);
        } else {
          navigate(`/fleets-data`);
        }
      } else {
        dispatch(
          setNotification('An error occurred , Try Again', 'error', true)
        );
      }
    } catch (err) {
      console.error(err);
      dispatch(setNotification('An error occurred , Try Again', 'error', true));
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setVehicle('');
  };

  return (
    <DeleteResource
      open={open}
      t={t}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      setResource={setVehicle}
      resourceName={vehicleName}
      resource={vehicle}
      action='vehicleDelete'
      isSubmitting={isSubmitting}
    />
  );
};

export default VehicleDeleteModal;

import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import SomethingWentWrong from 'pages/SomethingWentWrong';
import { sendTelegramMessage } from 'utils/telegram';
import PropTypes from 'prop-types';

const ErrorFallback = props => {
  const { error, resetErrorBoundary } = props;
  console.error('E10064', {
    props,
  });
  // TODO: report error to telegram
  useEffect(() => {
    error && sendTelegramMessage({ message: 'E10064 error:' + error });
  }, [error]);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SomethingWentWrong />
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const myErrorHandler = (error, info) => {
  // TODO: report error & info to telegram
  // Do something with the error
  // E.g. log to an error logging client here
  try {
    console.error('E10065', { error, info });
    sendTelegramMessage({ message: 'E10065 error.message:' + error?.message });
    sendTelegramMessage({ message: 'E10065 error.stack:' + error?.stack });
    sendTelegramMessage({
      message: 'E10065 info.componentStack:' + info?.componentStack,
    });
  } catch (error) {
    console.error('E10065', error);
  }
};

const ErrorBoundaryWrapper = props => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={myErrorHandler}
    >
      {props.component}
    </ErrorBoundary>
  );
};
export default ErrorBoundaryWrapper;
ErrorBoundaryWrapper.propTypes = {
  error: PropTypes.any,
  resetErrorBoundary: PropTypes.func,
};

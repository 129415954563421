import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import i18next from 'i18next';
import en from 'assets/images/UK.jpg';
import de from 'assets/images/ger.png';
import pl from 'assets/images/poland.png';
import classes from './LanguageSwitcher.module.css';
import { useSelector } from 'react-redux';
import { setLocaleSelector } from 'redux/users/ducks';
import { ReactComponent as UK } from 'assets/svg/common/ukFlag.svg';
import { ReactComponent as German } from 'assets/svg/common/deFlag.svg';
import { ReactComponent as Polish } from 'assets/svg/common/plFlag.svg';
import { Box } from '@mui/material';

const Languages = {
  en: <UK className={classes.svg} />,
  de: <German className={classes.svg} />,
  pl: <Polish className={classes.svg} />,
  'en-US': <UK className={classes.svg} />,
};

const FlagView = ({ language = 'en' }) => {
  return <div>{Languages[language]}</div>;
};

const LanguageSwitcher = ({ flag }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const lang = useSelector(setLocaleSelector);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box key={lang}>
      <Button
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      >
        <div
          className={classes.container}
          aria-controls='simple-menu'
          aria-haspopup='true'
          onClick={handleClick}
        >
          <FlagView language={flag || i18next.language} />
        </div>
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            i18next.changeLanguage('en');
            handleClose();
          }}
        >
          <div className={classes.item}>
            <img src={en} alt='uk flag' className={classes.flagDropdown} />
            <span>English</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            i18next.changeLanguage('de');
            handleClose();
          }}
        >
          <div className={classes.item}>
            <img src={de} alt='german flag' className={classes.flagDropdown} />
            <span>Deutsch</span>
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => {
            i18next.changeLanguage('pl');
            handleClose();
          }}
        >
          <div className={classes.item}>
            <img src={pl} alt='poland flag' className={classes.flagDropdown} />
            <span>Polish</span>
          </div>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default LanguageSwitcher;

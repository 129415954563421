import React from 'react';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
    },
  };
});

const CopyRights = ({ fontSize }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <Box
      className={classes.root}
      style={{
        fontSize: fontSize,
      }}
    >
      <CopyrightIcon style={{ fontSize: fontSize }} />
      <Box
        className={classes.content}
        style={{
          fontSize: fontSize,
        }}
      >
        <span>CO2OPT GmbH</span>
        <span>{t('common.allRightsReserved')}</span>
      </Box>
    </Box>
  );
};

export default CopyRights;

CopyRights.propTypes = {
  fontSize: PropTypes.number,
};

import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Chip,
  ClickAwayListener,
  Fab,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Step,
  StepLabel,
  Stepper,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import { darken, useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import * as XLSX from 'xlsx';

import {
  ArrowDownward,
  ArrowUpward,
  Cached,
  ExpandMore,
} from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import supportLogo from 'assets/images/support.png';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import { updateVehicle, uploadServiceReport } from 'services/fleet_service';
import { getVehicleChangelogData } from 'services/util_services';
import { axl_wise, tireLookup } from './const';
import classes from './TireConfig.module.css';

import { TRACKED_LS, USER_LS, USER_ROLES, VEHICLE } from 'utils/string_utils';
import Downloads from 'components/Downloads';
import AxleConfig from './AxleConfig';
import TireSizeConfig from './TireSizeConfig';
import { Link, useParams } from 'react-router-dom';
import TireOperationsModal from 'pages/VehiclePage/TireConfig/TireOperationsModal';
import {
  setChangeInVehicle,
  setTrackedVehiclesIdsSelector,
  vehicleUpdatedSelector,
} from 'redux/fleets/ducks';
import MoreOptions from './MoreOptions';
import moment from 'moment';
import LiftAxleConfiguration from './LiftAxleConfiguration/LiftAxleConfiguration';
import PropTypes from 'prop-types';
import LoadAnimation from 'components/LoadAnimation';
import SwapActionModal from 'pages/FleetsSection/FleetsSectionModals/SwapActionModal';
import { NAComponent } from 'pages/TireInventory/TireInventoryItems/FleetTireInventory';
import CustomTable from 'components/custom/CustomTable';
import { BiPencil, BiTrash } from 'react-icons/bi';
import UpdateTire from 'pages/TireInventory/UpdateTire';
import ModifyTireModal from './TireOperationsModal/ModifyTireModal';
import { ReactComponent as Steering } from 'assets/svg/common/steeringWheel.svg';
import { ReactComponent as Drive } from 'assets/svg/common/driveAxle.svg';
import { ReactComponent as Trailer } from 'assets/svg/common/trailerAxle.svg';
import { BsDownload } from 'react-icons/bs';
import { setUserSelector } from 'redux/users/ducks';
import { TireComponent } from './TireComponent';
import { TireInformationBox } from './TireInformationBox';
import ServiceDocUpload from './ServiceDocUpload';
import vergolst from 'assets/images/Vergoelst.png';
import driver from 'assets/images/Driver.png';
import { styled } from '@mui/system';
import { isEqual } from 'lodash';
import { processData } from 'pages/MultiLayeredReport/AllReports';
import TireGrooveModal from '../TireGrooveModal';

import InsightsIcon from '@mui/icons-material/Insights';

export const useStyles = makeStyles()(theme => {
  return {
    btn: {
      marginTop: '-3rem',
    },
    editBtn: {
      flex: '20%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '26px',
      backgroundColor: '#525d73',
      height: 'inherit',
      borderRadius: '0px 4px 4px 0px',
      color: '#ffffff',
      padding: '15px',
      boxShadow: '0px 1px 2px rgba(198, 228, 246, 0.05)',
      cursor: 'pointer',
    },
    paper: {
      height: 'auto',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      alignItems: 'center',
      textAlign: 'center',
      padding: '2rem 3rem',
    },
    container: {
      overflowX: 'auto',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    brands: { fontWeight: '900', fontSize: 'clamp(14px, 0.6vw, 1.5rem)' },
    card: {
      height: '100%',
      width: '100%',
      minHeight: 300,
      padding: '10px 0px',
      position: 'relative',
    },
    retreadBtn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    tireBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '135px',
      height: '70px',
      margin: '0.25rem 0.5rem',
    },
    configRetreadBtn: {
      fontSize: '1rem',
    },
    truthy: {
      color: theme.palette.primary.main,
    },
    false: {
      color: theme.palette.secondary.main,
    },
    retreadContainer: {
      padding: '5px',
      width: '95%',
    },
    retreatContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tabularDataContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      paddingInline: theme.spacing(1),
      alignItems: 'center',
    },
    subContent: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      flex: '45%',
      justifyContent: 'center',
    },
    fleetConfigureContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    cardContent: {
      display: 'flex',
      alignItems: 'center',
    },
    backButton: {
      alignSelf: 'flex-start',
      marginLeft: '1.5rem',
    },
    bold: {
      fontWeight: theme.typography.fontWeightMedium,
      flex: '70%',
    },
    navBtns: {
      textTransform: 'unset',
    },
    img: {
      width: 50,
      marginTop: 20,
    },
    draggableBox: {
      cursor: 'move',
    },
    tooltip: {
      position: 'relative',
    },
    item: {
      display: 'flex',
      gap: 15,
      alignItems: 'center',

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    paper2: {
      height: 'auto',
      border: 'none',
      padding: '1rem',
    },
    unmounted: {
      background: '#DEF2CC',
      color: theme.palette.primary.main,
    },
    tire: {
      borderRadius: 14,

      '&:hover': {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 14,
        height: 90,
      },
    },
    highlightedTire: {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: 14,
      height: 90,
    },
    fleetIdContainer: {
      borderRadius: 4,
      border: '1.5px solid #6b7280',
      padding: '1px 12px 1px 12px',

      '&:hover': {
        background: darken(theme.palette.primary.alt, 0.2),
      },
    },
    top: {
      opacity: 0.7,
    },
    bottom: {
      fontWeight: 700,
    },
    btns: {
      width: '100%',
      textTransform: 'unset',
      marginTop: 50,
      borderRadius: 24,
    },
    link: {
      padding: 5,
      textDecoration: 'none',
      color: 'unset',
    },
    link2: {
      padding: 5,
      textDecoration: 'none',
      color: 'unset',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
  };
});

export const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  margin: theme.spacing(1),
  '& .MuiSwitch-switchBase': {
    padding: 1,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#52D869',
      border: '6px solid #fff',
    },
  },
  '& .MuiSwitch-thumb': {
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}));

export const TireIdColumn = ({ row }) => {
  const { classes: styles } = useStyles();
  const params = useParams();
  const { fleet_id } = params;
  const [grooveOpen, setGrooveOpen] = useState(false);

  const handleCloseGroove = () => {
    setGrooveOpen(false);
  };
  // let lastSavedAxlePositionId = localStorage.getItem('position_id');

  return (
    <React.Fragment>
      {row?.tire_id ? (
        <>
          <Typography>{row.brand}</Typography>
          <Typography>{row.product_line}</Typography>
          <Link
            className={styles.link2}
            color='primary'
            to={`/tire-inventory/${fleet_id}/${row?.system_tire_id}`}
            data-hook={`navigate_to_${row.tire_id}`}
          >
            {row?.tire_id}
          </Link>
          {row?.grooves_tread_depth && (
            <IconButton
              aria-label='delete'
              color='primary'
              onClick={() => setGrooveOpen(true)}
            >
              <InsightsIcon />
            </IconButton>
          )}
        </>
      ) : (
        <NAComponent val={row.tire_id} />
      )}
      {row?.grooves_tread_depth && (
        <TireGrooveModal
          open={grooveOpen}
          handleClose={handleCloseGroove}
          tire={row}
        />
      )}
    </React.Fragment>
  );
};

const axleMapping = {
  '6x2': '6x2',
  '8x2': '8x2',
  '8x4SS': 'X',
  '10X4TS': 'X',
};

const TireConfig = ({ vehicle: configData, usageData }) => {
  const { classes: styles } = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const { t, i18n } = useTranslation();
  const [axleType, setAxleType] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [tireSize, setTireSize] = useState('');
  const [config, setConfig] = useState(null);
  const [defaultConfig, setDefaultConfig] = useState(null);
  const params = useParams();
  const { vehicle_id, fleet_id } = params;
  const [tDepth, setTDepth] = useState(null);
  const [selectedTire, setSelectedTire] = useState(null);
  const [usageDataMileage, setUsageDataMileage] = useState(null);
  const [openNew, setOpenNew] = useState(false);
  const [index, setIndex] = useState(null);
  const [addOns, setAddOns] = useState(null);
  const [usedTires, setUsedTires] = useState([]);
  const [allMountedTires, setAllMountedTires] = useState([]);
  const [tiresToSwap, setTiresToSwap] = useState([]);
  const [axlIds, setAxlIds] = useState({ from: '', to: '' });
  const [openSwap, setOpenSwap] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const [additionalPayload, setAdditionalPayload] = useState({
    odometer_reading: '',
    inspection_date: null,
    safety_check_date: null,
  });
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);
  const [dateError, setDateError] = useState(null);
  const [dateError1, setDateError1] = useState(null);
  const [truckTire, setTruckTire] = useState({
    steer: '',
    drive: '',
  });
  const [truckTireStatic, setTruckTireStatic] = useState({
    steer: null,
    drive: null,
  });
  const [trailerTire, setTrailerTire] = useState('');
  const [liftAxleConfig, setLiftAxleConfig] = useState([]);
  const [openLiftAxleModal, setOpenLiftAxleModal] = useState(false);
  const [tireType, setTiretype] = useState('');
  const [items, setItems] = useState({
    brand: '',
    line: '',
    currentSize: '',
    treadDepth: '',
    retreadStatus: '',
    pressure: '',
  });
  // const [tireData, setTireData] = useState(null);
  const [switchedOn, setSwitchedOn] = useState(false);
  let vehicleConfigured = true;
  const [thisTire, setThisTire] = useState(null);
  const trackedVehicles = useSelector(setTrackedVehiclesIdsSelector);
  const tracked = trackedVehicles || TRACKED_LS;
  const steps = [];
  const [isEditSelected, setIsEditSelected] = useState(false);
  const [isRegroovedSelected, setIsRegroovedSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [operation, setOperation] = useState('');
  const [isEmptyPositionClicked, setIsEmptyPositionClicked] = useState(false);
  const userLs = JSON.parse(USER_LS);
  const userDetails = useSelector(setUserSelector) || userLs;
  const userIsAReporter = userDetails?.iam_role === USER_ROLES.reporter;
  const isDraggable = !userIsAReporter ? true : false;
  const [optIndex, setOptIndex] = useState(0);
  const [regroveCheck, setRegrooveCheck] = useState(false);

  if (
    (configData?.vehicle_type === VEHICLE.vehicle_type.BUS1 ||
      configData?.vehicle_type === VEHICLE.vehicle_type.TRUCK1) &&
    (!configData?.tire_size?.steer_tire_size ||
      !configData?.tire_size?.drive_tire_size)
  ) {
    vehicleConfigured = false;
    steps.push(t('tireConfigPage.tireSelect'));
  }
  if (
    configData?.vehicle_type === VEHICLE.vehicle_type.TRAILER1 &&
    !configData?.tire_size?.tire_size
  ) {
    vehicleConfigured = false;
    steps.push(t('tireConfigPage.tireSelect'));
  }
  if (configData?.axle_type === null) {
    steps.unshift(t('tireConfigPage.axleSelect'));
    vehicleConfigured = false;
  }
  if (
    configData?.safety_check_date === null ||
    configData?.inspection_date === null ||
    configData?.odometer_reading === null
  ) {
    steps.push(t('tireConfigPage.more'));
    steps.push(t('tireConfigPage.confirm'));
  }

  const currentLanguage = i18n.language;

  const tireSizeStatic =
    configData.vehicle_type === VEHICLE.vehicle_type.TRAILER1
      ? configData.tire_size.tire_size
      : configData.vehicle_type === VEHICLE.vehicle_type.TRUCK1 ||
        configData.vehicle_type === VEHICLE.vehicle_type.BUS1
      ? configData.tire_size.steer_tire_size !== null &&
        configData.tire_size.drive_tire_size !== null
        ? [
            configData.tire_size.steer_tire_size,
            'Steer',
            '+',
            configData.tire_size.drive_tire_size,
            'Drive',
          ].join(' ')
        : null
      : null;
  useEffect(() => {
    const processTireInfo = async tire => {
      return { ...tire, status: tire.position ? 200 : 404 };
    };
    setLoading(true);
    let timer;

    const currentLiftConfig = configData?.lift_axle;
    const liftAxleArr = currentLiftConfig?.split('');

    if (currentLiftConfig) {
      setLiftAxleConfig(liftAxleArr);
    }

    // if axle_type is present
    if (!!configData?.axle_type) {
      setAxleType(configData.axle_type);
      const temp = { ...config };
      const axl = configData?.axle_type;
      const mountedTires = configData?.mounted_tires;
      setAllMountedTires(mountedTires);
      const resource = Object.keys(axl_wise[axl]);

      if (tireSizeStatic !== null) {
        setTireSize(tireSizeStatic);
      }
      for (const element of resource) {
        temp[element] = {
          right: null,
          left: null,
        };
      }
      const limit = mountedTires?.filter(tire => tire.position !== null).length;
      let count = 1;
      if (limit === 0) {
        setConfig(temp);
        setDefaultConfig(temp);
        setAddOns(JSON.parse(JSON.stringify(temp)));
        setLoading(false);
      } else {
        mountedTires?.forEach(async (tire, i) => {
          let position = tire.position;
          if (configData?.axle_type === '6x2') {
            if (position === '2SL') position = '2DL';
            if (position === '2SR') position = '2DR';
          }
          if (position) {
            // setTireIsConfigured(true);
            let axleType;
            if (configData?.axle_type.includes('twin')) {
              axleType = 'twin';
            } else {
              axleType =
                configData?.axle_type !== '6x2' &&
                configData?.axle_type !== '8x2' &&
                configData?.axle_type !== '8x4'
                  ? configData.axle_type === '8x4SS' ||
                    configData?.axle_type === '10x4TS'
                    ? 'X'
                    : 'others'
                  : configData?.axle_type;
            }

            const tireType = tireLookup[axleType][position]?.axle;
            const side = tireLookup[axleType][position]?.side;
            const index = tireLookup[axleType][position]?.index;
            if (axleType === 'twin') {
              temp[tireType][side] = [null, null];
            } else {
              temp[tireType][side] = tireType.includes('drive')
                ? [null, null]
                : [null];
            }

            try {
              const res = await processTireInfo(tire);
              if (res.status === 200) {
                const data = res;
                // const obj = data
                temp[tireType]?.[side]?.splice(index, 1, data);
              }
            } catch (error) {
              console.error('E20069', error);
            }
            if (count === limit) {
              timer = setTimeout(() => {
                setConfig(temp);
                setDefaultConfig(temp);
                setAddOns(JSON.parse(JSON.stringify(temp)));
                setLoading(false);
              }, 1000);
            } else {
              count = count + 1;
            }
          }
        });
      }
    } else {
      setLoading(false);

      if (tireSizeStatic !== null) {
        setTireSize(tireSizeStatic);
        setTrailerTire(tireSizeStatic);
      }
    }
    setTruckTire({
      drive: configData?.tire_size.drive_tire_size,
      steer: configData?.tire_size.steer_tire_size,
    });
    setTruckTireStatic({
      drive: configData?.tire_size.drive_tire_size,
      steer: configData?.tire_size.steer_tire_size,
    });

    return () => clearTimeout(timer);
    //eslint-disable-next-line
  }, [configData, regroveCheck]);

  useEffect(() => {
    setThisTire(prev => {
      let row;
      if (prev) {
        row = prev;
      } else {
        row = configData?.mounted_tires?.filter(tire => !!tire.position)[0];
      }

      setParameters(row);
      return row;
    });

    //eslint-disable-next-line
  }, [isEmptyPositionClicked]);

  const isStepOptional = step => {
    if (filteredSteps.length > 3) {
      return step === 2;
    }
  };

  const isStepSkipped = step => {
    return skipped.has(step);
  };

  const handleSkip = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const downloadChangelogHandler = async fileType => {
    try {
      let res = await getVehicleChangelogData(configData.vehicle_id);
      if (res.status === 200) {
        if (fileType === 'csv') {
          const blob = new Blob([res?.data], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${configData?.vehicle_id}_changelog.csv`;
          a.click();
        } else {
          const thisData = processData(res.data);
          const ws = XLSX.utils.json_to_sheet(thisData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
          XLSX.writeFile(
            workbook,
            `Vehicle_${configData?.vehicle_id}_changelog.xlsx`
          );
        }
      }
    } catch (error) {
      console.error('E20031', error);
    }
    setOpenDialog(false);
  };

  const nextStepClickHandler = async () => {
    const isBusOrTruck =
      configData.vehicle_type === VEHICLE.vehicle_type.TRUCK1 ||
      configData.vehicle_type === VEHICLE.vehicle_type.BUS1;

    if (activeStep === filteredSteps.length - 1) {
      let payload = {
        name: configData.name,
        reg_plate: configData.reg_plate,
        internal_grouping: configData.internal_grouping,
        axle_type: axleType,
        vin_chassis: configData.vin_chassis,
        vehicle_type: configData.vehicle_type.toUpperCase(),
        ...(isBusOrTruck && {
          steer_tire_size: tireSize.split(' ')[0],
          drive_tire_size: tireSize.split(' ')[3],
          tank_capacity: configData.tank_capacity,
        }),
        ...(!isBusOrTruck && { tire_size: trailerTire }),
      };
      if (isBusOrTruck) {
        // if tire sizes are available, don't send in payload
        truckTireStatic.drive && delete payload['drive_tire_size'];
        truckTireStatic.steer && delete payload['steer_tire_size'];
      }
      const optionalStepIndex = steps.findIndex(
        step => step === t('tireConfigPage.more')
      );
      const filteredObject = Object.entries(additionalPayload).reduce(
        (acc, [key, value]) => {
          if (value !== null && value !== '') {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      if (!isStepSkipped(optionalStepIndex)) {
        payload = {
          ...payload,
          ...filteredObject,
        };
      }
      try {
        const res = await updateVehicle(payload, vehicle_id);
        if (res.status === 200) {
          dispatch(
            setNotification(
              t('tireConfigPage.axlConfiguredSuccess'),
              'success',
              true
            )
          );
          // configChanged(true);
          dispatch(setChangeInVehicle(!vehicleDataHasChanged));
          setLoading(true);
        }
      } catch (error) {
        console.error('E20067', error);
      }
    } else {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep(prev => prev + 1);
      setSkipped(newSkipped);
    }
  };

  const [swapInfo, setSwapInfo] = useState(null);
  const swapBoxes = (from, to) => {
    const info = {
      axle_type: configData.axle_type,
      from,
      to,
    };
    setSwapInfo(info);
    let config_ = JSON.parse(JSON.stringify(config));

    const from_ = config_?.[from['key']]?.[from['side']]?.[from['index']];
    const to_ = config_?.[to['key']]?.[to['side']]?.[to['index']];

    const tires_ = [from_, to_];
    let ids = {};

    if (
      configData.vehicle_type === VEHICLE.vehicle_type.TRUCK ||
      configData.vehicle_type === VEHICLE.vehicle_type.BUS
    ) {
      ids = { from: from_?.axle_position_id, to: to_?.axle_position_id };

      if (from_ && to_) {
        config_[from['key']][from['side']][from['index']] = to_;
        config_[to['key']][to['side']][to['index']] = from_;
      }
      // setConfig(config_);
    } else {
      ids = { from: from_?.axle_position_id, to: to_?.axle_position_id };
      config_[from['key']][from['side']] = to_;
      config_[to['key']][to['side']] = from_;
      // setConfig(config_);
    }
    setTiresToSwap(tires_);
    setAxlIds(ids);
    if (tires_.some(item => item?.position) && !isEqual(from_, to_)) {
      setOpenSwap(true);
    }
  };

  /* The dragstart event is fired when the user starts dragging an element or text selection */
  /* event.target is the source element : that is dragged */
  /* Firefox requires calling dataTransfer.setData for the drag to properly work */
  const handleDragStart = data => event => {
    let fromBox = JSON.stringify(data);
    event.dataTransfer.setData('dragContent', fromBox);
  };

  /* The dragover event is fired when an element or text selection is being dragged */
  /* over a valid drop target (every few hundred milliseconds) */
  /* The event is fired on the drop target(s) */
  const handleDragOver = data => event => {
    event.preventDefault(); // Necessary. Allows us to drop.
    return false;
  };

  /* Fired when an element or text selection is dropped on a valid drop target */
  /* The event is fired on the drop target(s) */
  const handleDrop = data => event => {
    event.preventDefault();

    let fromBox = JSON.parse(event.dataTransfer.getData('dragContent'));
    let toBox = data;

    if (fromBox['key'].split('_')[0] === toBox['key'].split('_')[0]) {
      swapBoxes(fromBox, toBox);
    } else {
      dispatch(setNotification(t('tireConfigPage.swapFail'), 'error', true));
    }
    return false;
  };

  const formatNumber = num => {
    const formattedNumber = num?.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: false,
    });

    if (currentLanguage === 'de') {
      return formattedNumber + ' mm';
    } else {
      return parseFloat(num).toFixed(2) + ' mm';
    }
  };

  const itemClickHandler = index => {
    if (index === 1) {
      setIsEditSelected(true);
    } else if (index === 2) {
      // setIsChangeTireSelected(true);
      setOperation('tire_change');
      setOpenNew(true);
    } else {
      // setIsDeleteSelected(true);
      setOperation('storage');
      setOpenNew(true);
    }
    // handleClose();
  };

  const tireClickHandler = (axle, side, index) => {
    const row = config[axle]?.[side]?.[index];
    if (axle.includes('drive')) {
      setTiretype('drive_tire_size');
    } else if (axle.includes('steer')) {
      setTiretype('steer_tire_size');
    } else {
      setTiretype('tire_size');
    }
    if (!row) {
      !userIsAReporter && setIsEmptyPositionClicked(true);
    }
    setThisTire(config[axle]?.[side]?.[index]);
    setSelectedTire({
      type: axle,
      side: side,
    });
    setIndex(index);
    // setTireData(row);
    setItems({
      brand: row?.brand,
      line: row?.product_line,
      currentSize: row?.size,
      treadDepth: row?.last_tread_depth,
      retreadStatus: row?.isRetread,
    });
    if (defaultConfig[axle]?.[side]?.[index]?.estimated_tread_depth) {
      setTDepth(defaultConfig[axle]?.[side]?.[index]?.estimated_tread_depth);
    } else {
      setTDepth(defaultConfig[axle]?.[side]?.[index]?.last_tread_depth);
    }
    setUsageDataMileage(
      config[axle]?.[side]?.[index]?.last_km_driven?.toFixed(2)
    );
  };

  const handleActionsClick = (e, row) => {
    setAnchorEl(e.currentTarget);
    // setTireData(row);
    setItems({
      brand: row?.brand,
      line: row?.product_line,
      currentSize: row?.size,
      treadDepth: row?.last_tread_depth,
      retreadStatus: row.isRetread,
    });

    setSelectedTire({
      type: tireLookup[axleMapping[row?.axle_type] ?? 'others'][row?.position]
        ?.axle,
      side: tireLookup[axleMapping[row?.axle_type] ?? 'others'][row?.position]
        .side,
    });
    setIndex(
      tireLookup[axleMapping[row?.axle_type] ?? 'others'][row?.position]?.index
    );
    if (row?.estimated_tread_depth) {
      setTDepth(row?.estimated_tread_depth);
    } else {
      setTDepth(row?.last_tread_depth);
    }
    setUsageDataMileage(row?.last_km_driven?.toFixed(2));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setParameters = useCallback(row => {
    // setTireData(row);

    setItems({
      brand: row?.brand,
      line: row?.product_line,
      currentSize: row?.size,
      treadDepth: row?.last_tread_depth,
      retreadStatus: row?.isRetread,
    });
    setUsageDataMileage(row?.last_km_driven?.toFixed(2));
  }, []);
  let disableCondition;
  if (activeStep === 0) {
    disableCondition = axleType === null;
  } else {
    disableCondition =
      axleType === null || tireSize === null || dateError || dateError1;
  }

  let filteredSteps = steps;

  if (
    configData?.inspection_date &&
    configData?.safety_check_date &&
    configData?.odometer_reading
  ) {
    filteredSteps = steps.filter(step => step !== t('tireConfigPage.more'));
  }

  const closeModifyTireModalHandler = () => {
    setOpenNew(false);
    setAnchorEl(null);
    setUsedTires([]);
  };
  const sortByPosition = (a, b) => {
    const desiredOrder =
      configData.vehicle_type === VEHICLE.vehicle_type.TRUCK
        ? [
            '1SL',
            '1SR',
            '1DL',
            '1DLI',
            '1DRI',
            '1DR',
            '2SL',
            '2SR',
            '2DL',
            '2DLI',
            '2DRI',
            '2DR',
            '3DL',
            '3DLI',
            '3DRI',
            '3DR',
          ]
        : [
            '1TL',
            '1TR',
            '2TL',
            '2TR',
            '3TL',
            '3TR',
            '4TL',
            '4TR',
            '5TL',
            '5TR',
          ];
    const indexA = desiredOrder.indexOf(a.position);
    const indexB = desiredOrder.indexOf(b.position);

    // If either value is not found in the desiredOrder array, keep them in place
    if (indexA === -1 || indexB === -1) {
      return indexA - indexB;
    }

    return indexA - indexB;
  };

  const columns = [
    {
      key: 'position',
      title: t('tireInventoryItems.titles.axlePosition'),
      canSort: true,
      render: row => <NAComponent val={row.position} />,
    },
    {
      key: 'tire_id',
      title: t('tireInventoryItems.titles.tire'),
      canSort: true,
      render: row => <TireIdColumn row={row} />,
    },
    {
      key: 'mount_date',
      title: t('tireInventoryItems.titles.mountDate'),
      canSort: true,
      render: row => <NAComponent val={formatDate(row.mount_date)} />,
    },
    {
      key: 'last_event_date',
      title: t('tireInventoryItems.titles.lastUpdate'),
      canSort: true,
      render: row => <NAComponent val={formatDate(row.last_event_date)} />,
    },
    {
      key: 'last_tread_depth',
      title: t('tireInventoryItems.titles.tDepth'),
      canSort: true,
      render: row => <NAComponent val={formatNumber(row.last_tread_depth)} />,
    },
    {
      key: 'estimated_tread_depth',
      title: t('tireInventoryItems.titles.tDepthEst'),
      canSort: true,
      render: row => (
        <NAComponent
          val={
            row.estimated_tread_depth && formatNumber(row.estimated_tread_depth)
          }
        />
      ),
    },
    {
      key: 'isRetread',
      title: 'Status',
      canSort: true,
      render: row => <Chip label={row.isRetread ? 'Retreaded' : 'New tire'} />,
    },
    {
      key: 'rfid',
      title: 'RFID',
      render: row => <NAComponent val={row.rfid} />,
    },
    {
      key: 'actions',
      title: t('tireInventoryItems.titles.actions'),
      canSort: false,
      render: row => (
        <>
          <IconButton
            onClick={e => handleActionsClick(e, row)}
            disabled={userIsAReporter}
            style={{ cursor: 'pointer' }}
          >
            <MoreVertIcon color='primary' />
          </IconButton>

          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            className={classes.menu}
            onClose={handleClose}
          >
            <MenuItem
              className={styles.item}
              onClick={() => itemClickHandler(1)}
            >
              <BiPencil size={20} style={{ opacity: 0.5 }} />
              <span>Update Tire</span>
            </MenuItem>
            <MenuItem
              className={styles.item}
              onClick={() => itemClickHandler(2)}
            >
              <Cached size={20} style={{ opacity: 0.5 }} />
              <span>Change tire</span>
            </MenuItem>
            <MenuItem
              className={styles.item}
              onClick={() => itemClickHandler(3)}
            >
              <BiTrash size={20} style={{ opacity: 0.5 }} />
              <span>Move to storage</span>
            </MenuItem>
          </Menu>
        </>
      ),
    },
  ];

  if (tracked?.includes(vehicle_id)) {
    columns.splice(6, 0, {
      key: 'mileage',
      title: t('tireInventoryItems.titles.km'),
      canSort: true,
      render: row => (
        <NAComponent
          val={
            row?.mileage ? Math.round(parseFloat(row.mileage)) + ' km' : null
          }
        />
      ),
    });
  }

  const handleSwitchChange = event => {
    setSwitchedOn(!switchedOn);
  };

  const [openUploadServiceReportMenu, setOpenUploadServiceReportMenu] =
    useState(false);
  const anchorRef = React.useRef(null);
  const [uploadType, setUploadType] = useState(null);
  const [openDropzone, setOpenDropzone] = useState(false);
  const [files, setFiles] = useState([]);
  const [step, setStep] = useState(0);

  const handleToggle = () => {
    setOpenUploadServiceReportMenu(prevOpen => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenUploadServiceReportMenu(false);
    }
  }

  const handleCloseMenu = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenUploadServiceReportMenu(false);
  };

  const menuItemHandler = event => {
    const choice = event.target.alt;
    setUploadType(choice);
    setOpenDropzone(true);
    handleCloseMenu(event);
  };

  const closeDropzoneHandler = () => {
    setOpenDropzone(false);
    setFiles([]);
  };

  const [popUpCode, setPopUpCode] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const upload = async () => {
    setLoading(true);
    var formData = new FormData();
    const item = files[0];
    formData.append('document', item);

    try {
      if (uploadType) {
        const res = await uploadServiceReport(uploadType, formData);
        if (res.status === 200) {
          dispatch(setNotification(t('common.success'), 'success', true));
          dispatch(setChangeInVehicle(!vehicleDataHasChanged));
          setLoading(true);
          setStep(0);
          closeDropzoneHandler();
        } else {
          dispatch(setNotification(res?.data.return_message, 'error', true));
          const resource = res?.data;
          if (res?.data.popUpCode) {
            if (res.data.popUpCode === 'one') {
              dispatch(
                setNotification(res?.data.return_message, 'error', true)
              );
              return;
            }
            setPopUpCode(res?.data.popUpCode);

            setModalContent(resource);
          }
        }
      } else {
        dispatch(
          setNotification(
            'Some error occurred, please reload page',
            'error',
            true
          )
        );
      }
    } catch (error) {
      console.error('E20101', error);
    } finally {
      setLoading(false);
    }
  };
  const showLiftAxleView =
    configData?.vehicle_type === VEHICLE.vehicle_type.TRAILER &&
    !configData?.axle_type?.includes('1') &&
    !!liftAxleConfig?.length &&
    !userIsAReporter;

  const isTrailer =
    configData?.vehicle_type ===
    VEHICLE.vehicle_type.TRAILER.toLocaleUpperCase();

  return (
    <>
      <LoadAnimation loading={loading} setLoading={setLoading} />
      <Box
        width='100%'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        marginBottom={'16px'}
      >
        <Typography>{t('tireConfigPage.headingLeft')}</Typography>
        <Box
          display='flex'
          gap='20px'
          alignItems='center'
          justifyContent='center'
        >
          {isTrailer && (
            <Button
              startIcon={<ExpandMore />}
              color='primary'
              ref={anchorRef}
              aria-controls={
                openUploadServiceReportMenu ? 'menu-list-grow' : undefined
              }
              aria-haspopup='true'
              onClick={handleToggle}
              sx={{
                textTransform: 'unset',
                display: userIsAReporter && 'none',
              }}
              data-hook='service-doc-upload'
            >
              {t('tireConfigPage.uploadServiceReportBtn')}
            </Button>
          )}
          <Popper
            open={openUploadServiceReportMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseMenu}>
                    <MenuList
                      autoFocusItem={openUploadServiceReportMenu}
                      id='menu-list-grow'
                      onKeyDown={handleListKeyDown}
                      className={styles.list}
                    >
                      <MenuItem onClick={menuItemHandler}>
                        <img src={driver} alt='driver' style={{ width: 150 }} />
                      </MenuItem>
                      <MenuItem onClick={menuItemHandler} data-hook='vergolst'>
                        <img
                          src={vergolst}
                          alt='vergolst'
                          style={{ width: 150 }}
                        />
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          {configData?.axle_type && (
            <Tooltip title={t('tireConfigPage.changelogDownload')}>
              <IconButton onClick={() => setOpenDialog(true)}>
                <BsDownload size={'22px'} color={theme.palette.primary.main} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <FormControlLabel
          control={
            <IOSSwitch
              name='checkedB'
              checked={switchedOn}
              data-hook='table-view-switch'
              onChange={handleSwitchChange}
            />
          }
          label={t('tireConfigPage.tableView')}
        />
      </Box>
      {vehicleConfigured && switchedOn ? (
        <CustomTable
          columns={
            !userIsAReporter
              ? columns
              : columns.filter(item => item.key !== 'actions')
          }
          data-hook='tire-config-table-view'
          data={
            configData?.mounted_tires
              ?.filter(tire => tire.position !== null)
              .sort(sortByPosition) ?? []
          }
        />
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={4}>
            {vehicleConfigured && !!config && (
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='center'
                alignItems='center'
                width='100%'
                height='100%'
              >
                <Box className={styles.content}>
                  {configData?.vehicle_type === VEHICLE.vehicle_type.TRUCK ||
                  configData?.vehicle_type === VEHICLE.vehicle_type.BUS ? (
                    Object.entries(axl_wise[configData?.axle_type] ?? {}).map(
                      (item, i) => (
                        <Box
                          key={i}
                          display='flex'
                          flexDirection='row'
                          justifyContent='space-between'
                          alignItems='center'
                          width='100%'
                        >
                          <Box
                            display='flex'
                            flexDirection='row'
                            justifyContent='center'
                            alignItems='center'
                            flex={6}
                          >
                            {Array(item[1] / 2)
                              .fill()
                              .map((_, i) => (
                                <div
                                  key={config[item[0]]?.['left']?.[i]?.tire_id}
                                  draggable={isDraggable}
                                  className={styles.draggableBox}
                                  onDragStart={handleDragStart({
                                    key: item[0],
                                    side: 'left',
                                    index: i,
                                  })}
                                  onDragOver={handleDragOver({
                                    key: item[0],
                                    side: 'left',
                                    index: i,
                                  })}
                                  onDrop={handleDrop({
                                    key: item[0],
                                    side: 'left',
                                    index: i,
                                  })}
                                >
                                  <TireComponent
                                    row={config[item[0]]?.['left']?.[i]}
                                    tireClickHandler={tireClickHandler}
                                    setOptIndex={setOptIndex}
                                    axle={item[0]}
                                    side={'left'}
                                    setSelectedTire={setSelectedTire}
                                    tire={selectedTire}
                                    index={i}
                                    selectedTire={thisTire}
                                  />
                                </div>
                              ))}
                            {item[0]?.split('_')[0] === 'steer' ? (
                              <Box marginTop={'-20px'} paddingX={'15px'}>
                                <Steering />
                              </Box>
                            ) : item[0]?.split('_')[0] === 'drive' ? (
                              <Box paddingX={'15px'} marginTop={'-20px'}>
                                {configData.axle_type === '6x2' && i === 2 ? (
                                  <Steering />
                                ) : (
                                  <Drive />
                                )}
                              </Box>
                            ) : (
                              <Box>
                                <img
                                  width='25px'
                                  src={supportLogo}
                                  alt='Support'
                                />
                              </Box>
                            )}
                            {Array(item[1] / 2)
                              .fill()
                              .map((_, i) => (
                                <div
                                  key={config[item[0]]?.['right']?.[i]?.tire_id}
                                  draggable={isDraggable}
                                  className={styles.draggableBox}
                                  onDragStart={handleDragStart({
                                    key: item[0],
                                    side: 'right',
                                    index: i,
                                  })}
                                  onDragOver={handleDragOver({
                                    key: item[0],
                                    side: 'right',
                                    index: i,
                                  })}
                                  onDrop={handleDrop({
                                    key: item[0],
                                    side: 'right',
                                    index: i,
                                  })}
                                >
                                  <TireComponent
                                    row={config[item[0]]?.['right']?.[i]}
                                    tireClickHandler={tireClickHandler}
                                    setOptIndex={setOptIndex}
                                    axle={item[0]}
                                    setSelectedTire={setSelectedTire}
                                    tire={selectedTire}
                                    side={'right'}
                                    index={i}
                                    selectedTire={thisTire}
                                  />
                                </div>
                              ))}
                          </Box>
                        </Box>
                      )
                    )
                  ) : (
                    <>
                      {configData.axle_type.includes('twin') ? (
                        <Box width='100%'>
                          {Object.entries(axl_wise[configData.axle_type]).map(
                            (item, j) => (
                              <Box
                                key={j}
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                                alignItems='center'
                              >
                                {Array(item[1] / 2)
                                  .fill()
                                  .map((_, i) => (
                                    <div
                                      key={
                                        config[item[0]]?.['left']?.[i]?.tire_id
                                      }
                                      draggable={isDraggable}
                                      className={styles.draggableBox}
                                      onDragStart={handleDragStart({
                                        key: item[0],
                                        side: 'left',
                                        index: i,
                                      })}
                                      onDragOver={handleDragOver({
                                        key: item[0],
                                        side: 'left',
                                        index: i,
                                      })}
                                      onDrop={handleDrop({
                                        key: item[0],
                                        side: 'left',
                                        index: i,
                                      })}
                                    >
                                      <TireComponent
                                        row={config[item[0]]?.['left']?.[i]}
                                        tireClickHandler={tireClickHandler}
                                        setOptIndex={setOptIndex}
                                        axle={item[0]}
                                        setSelectedTire={setSelectedTire}
                                        tire={selectedTire}
                                        side={'left'}
                                        index={i}
                                        selectedTire={thisTire}
                                      />
                                    </div>
                                  ))}

                                <Box paddingX={'25px'} position='relative'>
                                  {liftAxleConfig.length > 0 &&
                                    configData?.axle_type !== '1-axle' && (
                                      <Tooltip
                                        title={
                                          colorCode?.[liftAxleConfig[j]] ===
                                          '#A3E635'
                                            ? t('tireConfigPage.axleLowered')
                                            : t('tireConfigPage.axleLifted')
                                        }
                                      >
                                        <Box
                                          width={24}
                                          height={24}
                                          borderRadius='50%'
                                          bgcolor={
                                            colorCode?.[liftAxleConfig[j]]
                                          }
                                          color={
                                            arrowColorCode?.[liftAxleConfig[j]]
                                          }
                                          position='absolute'
                                          top={-30}
                                          left='40%'
                                        >
                                          {arrowDirection?.[liftAxleConfig[j]]}
                                        </Box>
                                      </Tooltip>
                                    )}
                                  <Trailer />
                                </Box>
                                {Array(item[1] / 2)
                                  .fill()
                                  .map((_, i) => (
                                    <div
                                      key={
                                        config[item[0]]?.['right']?.[i]?.tire_id
                                      }
                                      draggable={isDraggable}
                                      className={styles.draggableBox}
                                      onDragStart={handleDragStart({
                                        key: item[0],
                                        side: 'right',
                                        index: i,
                                      })}
                                      onDragOver={handleDragOver({
                                        key: item[0],
                                        side: 'right',
                                        index: i,
                                      })}
                                      onDrop={handleDrop({
                                        key: item[0],
                                        side: 'right',
                                        index: i,
                                      })}
                                    >
                                      <TireComponent
                                        row={config[item[0]]?.['right']?.[i]}
                                        tireClickHandler={tireClickHandler}
                                        setOptIndex={setOptIndex}
                                        axle={item[0]}
                                        side={'right'}
                                        setSelectedTire={setSelectedTire}
                                        tire={selectedTire}
                                        index={i}
                                        selectedTire={thisTire}
                                      />
                                    </div>
                                  ))}
                              </Box>
                            )
                          )}
                        </Box>
                      ) : (
                        <Box width='100%'>
                          {/* {comment- cylinder-svg} */}
                          {Object.entries(axl_wise[configData.axle_type]).map(
                            (item, i) => (
                              <Box
                                key={i}
                                display='flex'
                                flexDirection='row'
                                justifyContent='center'
                                alignItems='center'
                              >
                                <div
                                  draggable={isDraggable}
                                  className={styles.draggableBox}
                                  onDragStart={handleDragStart({
                                    key: item[0],
                                    side: 'left',
                                    index: 0,
                                  })}
                                  onDragOver={handleDragOver({
                                    key: item[0],
                                    side: 'left',
                                    index: 0,
                                  })}
                                  onDrop={handleDrop({
                                    key: item[0],
                                    side: 'left',
                                    index: 0,
                                  })}
                                  data-hook={'left-' + i}
                                >
                                  <TireComponent
                                    row={config[item[0]]?.['left']?.[0]}
                                    tireClickHandler={tireClickHandler}
                                    setOptIndex={setOptIndex}
                                    side={'left'}
                                    axle={item[0]}
                                    setSelectedTire={setSelectedTire}
                                    tire={selectedTire}
                                    index={0}
                                    selectedTire={thisTire}
                                    id={'left-' + i}
                                  />
                                </div>
                                <Box paddingX={'25px'} position='relative'>
                                  {liftAxleConfig.length > 0 &&
                                    configData?.axle_type !== '1-axle' && (
                                      <Tooltip
                                        title={
                                          colorCode?.[liftAxleConfig[i]] ===
                                          '#A3E635'
                                            ? t('tireConfigPage.axleLowered')
                                            : t('tireConfigPage.axleLifted')
                                        }
                                      >
                                        <Box
                                          width={24}
                                          height={24}
                                          borderRadius='50%'
                                          bgcolor={
                                            colorCode?.[liftAxleConfig[i]]
                                          }
                                          color={
                                            arrowColorCode?.[liftAxleConfig[i]]
                                          }
                                          position='absolute'
                                          top={-30}
                                          left='40%'
                                        >
                                          {arrowDirection?.[liftAxleConfig[i]]}
                                        </Box>
                                      </Tooltip>
                                    )}
                                  <Trailer />
                                </Box>
                                <div
                                  draggable={isDraggable}
                                  className={styles.draggableBox}
                                  onDragStart={handleDragStart({
                                    key: item[0],
                                    side: 'right',
                                    index: 0,
                                  })}
                                  onDragOver={handleDragOver({
                                    key: item[0],
                                    side: 'right',
                                    index: 0,
                                  })}
                                  onDrop={handleDrop({
                                    key: item[0],
                                    side: 'right',
                                    index: 0,
                                  })}
                                  data-hook={'right-' + i}
                                >
                                  <TireComponent
                                    row={config[item[0]]?.['right']?.[0]}
                                    tireClickHandler={tireClickHandler}
                                    setOptIndex={setOptIndex}
                                    axle={item[0]}
                                    side={'right'}
                                    setSelectedTire={setSelectedTire}
                                    tire={selectedTire}
                                    index={0}
                                    selectedTire={thisTire}
                                  />
                                </div>
                              </Box>
                            )
                          )}
                        </Box>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            )}
          </Grid>

          {thisTire && (
            <Grid item xs={8}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper elevation={0} className={styles.paper2}>
                    <TireInformationBox
                      data={thisTire}
                      setOpenUpdate={setIsEditSelected}
                      setOpenModifyTireModal={setOpenNew}
                      setOperation={setOperation}
                      setIsRegrooved={setIsRegroovedSelected}
                    />
                  </Paper>
                </Grid>
                {showLiftAxleView && (
                  <Grid item xs={12}>
                    <Paper elevation={0} className={styles.paper2}>
                      <LiftAxleConfiguration
                        open={openLiftAxleModal}
                        setOpen={setOpenLiftAxleModal}
                        axles={liftAxleConfig}
                        vehicle={configData}
                      />
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {!vehicleConfigured && !loading && (
        <Paper style={{ padding: '1rem 2rem' }}>
          <Box width='100%'>
            {steps?.length && (
              <Stepper
                activeStep={activeStep}
                // alternativeLabel
                style={{ backgroundColor: 'transparent' }}
              >
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  if (isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant='caption'>Optional</Typography>
                    );
                  }
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
            {activeStep ===
              steps.findIndex(
                step => step === t('tireConfigPage.axleSelect')
              ) && (
              <AxleConfig
                configData={configData}
                axle={axleType}
                setAxle={setAxleType}
              />
            )}
            {activeStep ===
              steps.findIndex(
                step => step === t('tireConfigPage.tireSelect')
              ) && (
              <TireSizeConfig
                truckTire={truckTire}
                trailerTire={trailerTire}
                setTrailerTire={setTrailerTire}
                setTruckTire={setTruckTire}
                configData={configData}
                size={tireSize}
                setSize={setTireSize}
                initial={truckTireStatic}
              />
            )}
            {activeStep ===
              steps.findIndex(step => step === t('tireConfigPage.more')) && (
              <MoreOptions
                resource={additionalPayload}
                setResource={setAdditionalPayload}
                setError={setDateError}
                setError1={setDateError1}
                vehicle={configData}
                status='new'
              />
            )}
            <Box
              width='100%'
              display='flex'
              justifyContent='space-between'
              px={2}
              marginTop={2}
            >
              <Box
                className={classes.backButton}
                visibility={activeStep === 0 ? 'hidden' : 'visible'}
                onClick={() => setActiveStep(prev => prev - 1)}
              >
                <Fab color='primary' aria-label='back' size='small'>
                  <ArrowBackIcon />
                </Fab>
              </Box>
              {isStepOptional(activeStep) && (
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleSkip}
                  className={styles.navBtns}
                >
                  {t('common.skip')}
                </Button>
              )}
              <Button
                variant='contained'
                color='primary'
                className={styles.navBtns}
                onClick={nextStepClickHandler}
                disabled={disableCondition}
              >
                {activeStep < steps.length - 1
                  ? t('common.next')
                  : t('common.confirm')}
              </Button>
              {activeStep === steps.length - 1 && (
                <Box visibility='hidden'></Box>
              )}
            </Box>
          </Box>
        </Paper>
      )}
      <Downloads
        t={t}
        dialogOpen={openDialog}
        setDialogOpen={setOpenDialog}
        handleDownload={downloadChangelogHandler}
      />

      {openNew && (
        <ModifyTireModal
          open={openNew}
          setOpen={setOpenNew}
          usedTires={usedTires}
          setUsedTires={setUsedTires}
          presentTire={thisTire}
          operation={operation}
          handleClose={closeModifyTireModalHandler}
          setHighlightedTire={setThisTire}
          vehicle={configData}
          index={optIndex}
          axle={axleType}
          tire={selectedTire}
        />
      )}
      {isEmptyPositionClicked && (
        <TireOperationsModal
          tire={selectedTire}
          tireType={tireType}
          size={tireSize}
          open={isEmptyPositionClicked}
          setOpen={setIsEmptyPositionClicked}
          setThisTire={setThisTire}
          newConfig={config}
          setConfig={setConfig}
          index={index}
          axle={axleType}
          addOns={addOns}
          setAddOns={setAddOns}
          staticConfig={defaultConfig}
          setUsedTires={setUsedTires}
          usedTires={usedTires}
          usageMileage={usageDataMileage}
          tireTreadDepth={tDepth}
          vehicleType={configData.vehicle_type}
          mountedTires={allMountedTires}
          vehicle={configData}
        />
      )}
      {openSwap && (
        <SwapActionModal
          tires={tiresToSwap}
          ids={axlIds}
          open={openSwap}
          setOpen={setOpenSwap}
          swapInfo={swapInfo}
        />
      )}
      {openLiftAxleModal && (
        <LiftAxleConfiguration
          open={openLiftAxleModal}
          setOpen={setOpenLiftAxleModal}
          axles={liftAxleConfig}
          vehicle={configData}
        />
      )}
      {(isEditSelected || isRegroovedSelected) && (
        <UpdateTire
          current={items}
          regrooveCheck={regroveCheck}
          setRegrooveCheck={setRegrooveCheck}
          tire={selectedTire}
          vehicleType={configData.vehicle_type}
          tireId={thisTire.tire_id}
          isRetread={thisTire.isRetread}
          id={thisTire.system_tire_id}
          dot={thisTire.dot}
          axle={axleType}
          index={optIndex}
          fleetId={fleet_id}
          open={isEditSelected || isRegroovedSelected}
          isRegroovedSelected={isRegroovedSelected}
          setIsRegrooved={setIsRegroovedSelected}
          setOpen={setIsEditSelected}
          tireData={thisTire}
          last_pressure={thisTire.last_pressure}
          tireInventoryAction={true}
          close={() => setAnchorEl(null)}
          setHighlightedTire={setThisTire}
          vehicle={configData}
        />
      )}

      {openDropzone && (
        <ServiceDocUpload
          open={openDropzone}
          category={uploadType}
          close={closeDropzoneHandler}
          files={files}
          setFiles={setFiles}
          uploadDocument={upload}
          activeStep={step}
          setActiveStep={setStep}
          content={modalContent}
          setContent={setModalContent}
          popUpCode={popUpCode}
          setPopUpCode={setPopUpCode}
          configData={configData}
          loading={loading}
          setLoading={setLoading}
        />
      )}
    </>
  );
};

export default React.memo(TireConfig);
TireConfig.propTypes = {
  vehicle: PropTypes.object,
  usageData: PropTypes.any,
};

export function formatDate(date) {
  return date ? moment(date).format('YYYY.MM.DD') : '';
}

const colorCode = { F: '#A3E635', T: '#FED7AA' };
export function tireColor(value) {
  if (value === null) {
    return '';
  } else if (value <= 3) {
    return 'rgba(255, 0, 0, 0.3)';
  } else if (value >= 3 && value <= 5) {
    return 'rgba(255, 200, 0, 0.5)';
    // return 'rgba(255, 255, 0, 0.3)';
  } else if (value > 5) {
    return '';
  }
}
const arrowColorCode = { F: '#4D7C0F', T: '#F97316' };
const arrowDirection = { F: <ArrowDownward />, T: <ArrowUpward /> };

export const RetreadInfo = ({ children }) => {
  return (
    <Box
      height={20}
      width={20}
      borderRadius='50%'
      position='absolute'
      bgcolor='#F5F5F5'
      top={10}
      bottom={0}
      left={'30%'}
      display='flex'
      alignItems='center'
      justifyContent='center'
      color='#08639a'
    >
      {children}
    </Box>
  );
};
export const RegrooveInfo = ({ children }) => {
  return (
    <Box
      height={20}
      width={20}
      borderRadius='50%'
      position='absolute'
      bgcolor='#F5F5F5'
      top={50}
      bottom={0}
      left={'30%'}
      display='flex'
      alignItems='center'
      justifyContent='center'
      color='#08639a'
    >
      {children}
    </Box>
  );
};

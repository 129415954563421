import React, { useState, useEffect, useCallback, memo } from 'react';
import Tour from 'reactour';
import {
  Backdrop,
  Box,
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Modal,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import SavingPotential from '../FleetOverview/SavingPotential';
import {
  fetchWorkforceQuery,
  setAccessTokenSelector,
  setTour,
  setTourSelector,
  workforceSelector,
} from 'redux/users/ducks';
import moment from 'moment';
import TourContent from 'components/Tour/Content';

import { useDispatch, useSelector } from 'react-redux';
import * as CONST from './const';
import VehicleOverview from 'components/VehicleOverview';

import {
  fleetOverviewSelector,
  setTrackedVehiclesIdsSelector,
} from 'redux/fleets/ducks';
import useKpis from 'hooks/useKpis';

import { Timeline, ViewComfy } from '@mui/icons-material';
import { ActionsContainer } from './ActionsContainer';
import EventsLogDrawer from './EventsLogDrawer';
import { decodeSource, decodeActivity } from './event_const';

import { ReactComponent as Truck } from 'assets/svg/common/truckIcon.svg';
import { ReactComponent as Fleet } from 'assets/svg/activity/fleet.svg';
import { ReactComponent as Trailer } from 'assets/svg/common/trailerIcon.svg';
import { ReactComponent as Bus } from 'assets/svg/common/busLogo.svg';
import { ReactComponent as Wheel } from 'assets/svg/activity/wheel.svg';
import TireOverview from '../../components/TireOverview';

import TireRecomm from './TireRecom';
import { updateDashboard } from 'services/auth_service';
import DashboardSettings from './DashboardSettings';
import PropTypes from 'prop-types';

const taskResourceTypes = {
  TRUCK: 'Truck',
  BUS: 'Bus',
  TRAILER: 'Trailer',
  FLEET: 'Fleet',
  TIRE: 'Tire',
};

const useStyles = makeStyles()(() => {
  return {
    tileStyles: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      alignItems: 'center',
      padding: '16px 32px 16px 16px',
      borderRadius: 12,
      border: '1px solid var(--default-gray-200, #E5E7EB)',
    },
    iconStyles: {
      height: 40,
      width: 40,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };
});

const FleetsFoundUI = ({
  profileStatus,
  fleetPresent,
  isVehicleDataLoading,
  loadingTasks,
  isLoadingOverview,
}) => {
  const { classes } = CONST.style();
  let dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const isTour = useSelector(setTourSelector);
  const [showProfileCompletion, setShowProfileCompletion] = useState(false);
  const theme = useTheme();
  const xxl = useMediaQuery(`(min-width:3840px)`);
  const { classes: new_classes } = useStyles();
  const home = 'home';
  const [steps, setSteps] = useState([]);
  const resourcePic = {
    TRUCK: (
      <Truck
        className={new_classes.iconStyles}
        fill={theme.palette.primary.main}
      />
    ),
    BUS: (
      <Bus
        className={new_classes.iconStyles}
        fill={theme.palette.primary.main}
      />
    ),
    TRAILER: (
      <Trailer
        className={new_classes.iconStyles}
        fill={theme.palette.primary.main}
      />
    ),
    FLEET: <Fleet fill={theme.palette.primary.main} alt='fleet' />,
    TIRE: <Wheel fill={theme.palette.primary.main} alt='tire' />,
  };

  const { tiresByDepth, outOfServiceGrouping } = useKpis();

  const workforce = useSelector(workforceSelector);
  const access_token = useSelector(setAccessTokenSelector);

  const [open, setOpen] = useState(false);
  const [resource, setResource] = useState([]);
  const [page, setPage] = useState(0);
  const overview_data = useSelector(fleetOverviewSelector);
  const trackedVehicles = useSelector(setTrackedVehiclesIdsSelector);

  let vehicle_arr = ['TRUCK', 'BUS', 'TRAILER'];
  const preferences = workforce?.homepage_preference;
  const [preferencePayload, setPreferencePayload] = useState({
    ...preferences,
  });
  const [openDashboardSettings, setOpenDashboardSettings] = useState(false);
  const [updatingHomeView, setUpdatingHomeView] = useState(false);
  const vehicleOverviewVisible = preferences?.vehicle_overview;
  const tireOverviewVisible = preferences?.tire_overview;
  const savingPotentialVisible = preferences?.savings_potential;
  const recentActivitiesVisible = preferences?.recent_activities;
  let savingsSize = 7;
  let activitiesSize = 5;
  let vehicleOverviewSize = 7;
  let tireOverviewSize = 5;
  if (!recentActivitiesVisible && savingPotentialVisible) {
    savingsSize = 12;
  }
  if (recentActivitiesVisible && !savingPotentialVisible) {
    activitiesSize = 12;
  }
  if (
    recentActivitiesVisible &&
    !savingPotentialVisible &&
    vehicleOverviewVisible &&
    !tireOverviewVisible
  ) {
    activitiesSize = 5;
  }
  if (
    recentActivitiesVisible &&
    !savingPotentialVisible &&
    !vehicleOverviewVisible &&
    tireOverviewVisible
  ) {
    activitiesSize = 6;
    tireOverviewSize = 6;
  }
  if (
    !recentActivitiesVisible &&
    savingPotentialVisible &&
    vehicleOverviewVisible &&
    !tireOverviewVisible
  ) {
    savingsSize = 12;
    vehicleOverviewSize = 12;
  }
  if (
    !recentActivitiesVisible &&
    savingPotentialVisible &&
    !vehicleOverviewVisible &&
    tireOverviewVisible
  ) {
    savingsSize = 7;
    tireOverviewSize = 5;
  }
  if (
    recentActivitiesVisible &&
    savingPotentialVisible &&
    !vehicleOverviewVisible &&
    tireOverviewVisible
  ) {
    savingsSize = 7;
    tireOverviewSize = 12;
    activitiesSize = 5;
  }
  if (
    recentActivitiesVisible &&
    savingPotentialVisible &&
    vehicleOverviewVisible &&
    !tireOverviewVisible
  ) {
    savingsSize = 7;
    vehicleOverviewSize = 12;
    activitiesSize = 5;
  }

  const renderEntityName = (isDeleted, event, link) => {
    if (!isDeleted) {
      return (
        <a
          href={link}
          target='_blank'
          style={{
            textDecoration: 'none',
            color: link !== undefined ? theme.palette.primary.main : 'grey',
          }}
          without
          rel='noreferrer'
        >
          <span style={{ fontWeight: '900' }}>
            {event.activity.entity_name}
          </span>
        </a>
      );
    }
    return (
      <span style={{ fontWeight: '900', color: 'grey' }}>
        {event.activity.entity_name}
      </span>
    );
  };

  const transformText = event => {
    let lang = i18n.language.split('-')[0];

    try {
      if (vehicle_arr.includes(event.entity_type) && event) {
        let link = `/fleets-data/${event.fleetId}/${
          event.entity_type === 'BUS'
            ? 'buses'
            : event.entity_type.toLowerCase() + 's'
        }/${event.entity_id}`;
        let A501 = event.activity['A501'];
        delete event.activity['fleet_id'];
        let keys = Object.keys(event.activity).filter(x => x !== 'A501');
        return (
          <div
            data-hook={`recent-activity-drawer-content-${
              taskResourceTypes[event.entity_type]
            }`}
          >
            <h4>
              {taskResourceTypes[event.entity_type]}{' '}
              {renderEntityName(A501, event, link)} {getUsername(event.user_id)}{' '}
              {t('homePage.on')} {decodeSource[event.source_of_creation]}
            </h4>
            {keys.map((item, i) => (
              <div
                data-hook={`activity-${
                  taskResourceTypes[event.entity_type]
                }-${i}`}
                key={i}
              >
                <h4>{decodeActivity[lang][item]}</h4>
                <ul>
                  {!!event?.activity[item] &&
                    typeof event.activity[item] !== 'string' &&
                    event.activity[item].map((item_, j) => {
                      return (
                        <li key={j}>
                          <h5>
                            {decodeActivity[lang][item_] !== undefined
                              ? decodeActivity[lang][item_]
                              : item_.includes('Mounted')
                              ? item_.replace('Mounted', t('common.mounted'))
                              : item_.includes('Unmounted')
                              ? item_.replace(
                                  'Unmounted',
                                  t('common.Unmounted')
                                )
                              : item_}
                          </h5>
                        </li>
                      );
                    })}
                </ul>
              </div>
            ))}
            {A501 && (
              <h4>
                <span style={{ color: 'red' }}>
                  {decodeActivity[lang]['A501']}
                </span>
              </h4>
            )}
          </div>
        );
      } else if (event.entity_type === 'TIRE' && event) {
        let _id = event.entity_id;
        let link = `/tire-inventory/${event.fleetId}/${event.entity_id}`;

        let A501 = event.activity['A501'];

        delete event.activity['fleet_id'];

        let keys = Object.keys(event.activity).filter(x => x !== 'A501');
        return (
          <div
            data-hook={`recent-activity-drawer-content-${
              taskResourceTypes[event.entity_type]
            }`}
          >
            <h4>
              {taskResourceTypes[event.entity_type]}{' '}
              <a
                href={link}
                target='_blank'
                style={{
                  textDecoration: 'none',
                  color:
                    link !== undefined ? theme.palette.primary.main : 'black',
                }}
                without
                rel='noreferrer'
              >
                <span style={{ fontWeight: '900' }}>{_id}</span>
              </a>{' '}
              {getUsername(event.user_id)} {'on'}{' '}
              {decodeSource[event.source_of_creation]}
            </h4>
            {keys.map((item, i) => (
              <div
                data-hook={`activity-${
                  taskResourceTypes[event.entity_type]
                }-${i}`}
                key={i}
              >
                <h4>{decodeActivity[lang][item]}</h4>
                <ul>
                  {!!event?.activity[item] &&
                    typeof event?.activity[item] === 'object' &&
                    event?.activity[item].map((item_, j) => (
                      <li key={j}>
                        <h5>
                          {decodeActivity[lang][item_] !== undefined
                            ? decodeActivity[lang][item_]
                            : item_}
                        </h5>
                      </li>
                    ))}
                </ul>
              </div>
            ))}
            {A501 && (
              <h4>
                <span style={{ color: 'red' }}>
                  {decodeActivity[lang]['A501']}
                </span>
              </h4>
            )}
          </div>
        );
      } else if (event.entity_type === 'FLEET' && overview_data && event) {
        let _id = event.entity_id;

        let link;
        let name;
        for (let j = 0; j < overview_data.fleets.length; j++) {
          if (overview_data.fleets?.[j]['fleet_id'] === _id) {
            link = `/fleets-data/${overview_data.fleets[j]['fleet_id']}`;
            name = overview_data.fleets[j]['fleet_name'];
            break;
          }
        }
        let A501 = event.activity['A501'];
        let keys = Object.keys(event.activity).filter(x => x !== 'A501');
        return (
          <div
            data-hook={`recent-activity-drawer-content-${
              taskResourceTypes[event.entity_type]
            }`}
          >
            <h4>
              {taskResourceTypes[event.entity_type]}{' '}
              <a
                href={link}
                target='_blank'
                style={{
                  textDecoration: 'none',
                  color:
                    link !== undefined ? theme.palette.primary.main : 'black',
                }}
                without
                rel='noreferrer'
              >
                <span style={{ fontWeight: '900' }}>{name}</span>
              </a>{' '}
              {event.activity?.entity_name || getUsername(event.user_id)}{' '}
              {t('common.on')} {decodeSource[event.source_of_creation]}
            </h4>
            <ul>
              {keys.map((item_, j) => {
                if (item_ === 'entity_name') {
                  return <></>;
                }

                return (
                  <li key={j}>
                    <h5>
                      {decodeActivity[lang][item_]} : {event.activity[item_]}
                    </h5>
                  </li>
                );
              })}
            </ul>
            {A501 && (
              <h4>
                <span style={{ color: 'red' }}>
                  {decodeActivity[lang]['A501']}
                </span>
              </h4>
            )}
          </div>
        );
      }
    } catch (err) {
      console.error(err);
    }
    return;
  };

  useEffect(() => {
    if (showProfileCompletion) {
      setSteps([
        {
          selector: '#home-basic-infos',
          content: ({ goTo, inDOM }) => (
            <TourContent
              content={t('tour.page.home.homeBasicInfos')}
              finalAction='Next'
              goTo={() => goTo(1)}
              page={home}
            />
          ),
          style: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 8,
          },
        },
        {
          selector: '#actions-required',
          content: ({ goTo, inDOM }) => (
            <TourContent
              content={t('tour.page.home.actionsRequired')}
              finalAction='Next'
              goTo={() => goTo(2)}
              page={home}
            />
          ),
          style: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 8,
          },
        },
        {
          selector: '#savings-potential',
          content: ({ goTo, inDOM }) => (
            <TourContent
              content={t('tour.page.home.savingPotential')}
              finalAction='Finish'
              goTo={() => {
                dispatch(setTour(false));
              }}
              page={home}
            />
          ),
          style: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 8,
          },
        },
      ]);
    } else {
      setSteps([
        {
          selector: '#actions-required',
          content: ({ goTo, inDOM }) => (
            <TourContent
              content={t('tour.page.home.actionsRequired')}
              finalAction='Next'
              goTo={() => goTo(1)}
              page={home}
            />
          ),
          style: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 8,
          },
        },
        {
          selector: '#savings-potential',
          content: ({ goTo, inDOM }) => (
            <TourContent
              content={t('tour.page.home.savingPotential')}
              finalAction='Finish'
              goTo={() => {
                dispatch(setTour(false));
              }}
              page={home}
            />
          ),
          style: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 8,
          },
        },
      ]);
    }
    /*eslint-disable-next-line*/
  }, [showProfileCompletion]);

  const profileStatusKpi = React.useMemo(
    () => [
      {
        key: t('homePage.status.org'),
        value: profileStatus?.org_info_complete_percentage,
      },
      {
        key: t('homePage.status.tireMgmt'),
        value: profileStatus?.tire_management_complete_percentage,
      },
      {
        key: t('homePage.status.generalAsset'),
        value: profileStatus?.asset_config_complete_percentage,
      },
      {
        key: t('homePage.status.fleet'),
        value: profileStatus?.fleet_data_complete_percentage,
      },
      {
        key: t('homePage.status.retreadStrategy'),
        value: profileStatus?.retread_strategy_data_complete_percentage,
      },
      {
        key: t('homePage.status.profile'),
        value: profileStatus?.profile_complete_percentage,
      },
    ],
    [profileStatus, t]
  );

  useEffect(() => {
    const result = profileStatusKpi.find(
      kpi => kpi.key === t('homePage.status.profile')
    );

    result.value === 100
      ? setShowProfileCompletion(false)
      : setShowProfileCompletion(true);
  }, [profileStatusKpi, t]);

  // useEffect(() => {
  //   if (fleets) {
  //     (async () => {
  //       const result = await ourTireRecommendations(fleets);
  //       dispatch(setTyreRecommendations(result));
  //     })();
  //   }
  // }, [fleets, dispatch]);

  useEffect(() => {
    if (access_token && !workforce) {
      dispatch(fetchWorkforceQuery());
    }
    // eslint-disable-next-line
  }, [access_token]);

  const getUsername = useCallback(
    id => {
      const found = workforce?.workforceData.find(user => user.user_id === id);

      return found ? found.name : 'N/A';
    },
    [workforce]
  );

  const closeHandler = () => {
    setOpen(false);
  };

  const recentActivities = () => {
    if (resource.length > 0) {
      return (
        <Grid item xs={activitiesSize}>
          <Paper
            style={{
              padding: '16px 20px',
              boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
              height: '100%',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '1rem',
              }}
            >
              <Box>
                <Typography
                  variant='subtitle1'
                  fontWeight={900}
                  gutterBottom
                  data-hook='recent-activity'
                >
                  {t('common.recentActivity')}
                </Typography>
              </Box>
              <Box>
                <Button
                  color='primary'
                  size='large'
                  className={classes.moreBtn}
                  endIcon={<Timeline />}
                  onClick={() => setOpen(true)}
                  data-hook='recent-activity-drawer-open'
                >
                  {t('homePage.moreBtn')}
                </Button>
              </Box>
            </Box>
            <Box
              style={{
                maxHeight: 250,
                overflowY: 'scroll',
                scrollBehavior: 'smooth',
              }}
            >
              {activityDetails()}
            </Box>
          </Paper>
        </Grid>
      );
    }
  };

  const activityDetails = () => {
    return resource?.slice(0, 2)?.map((item, i) => (
      <Box key={i} display='flex'>
        <Box>
          <Box
            display='flex'
            justifyContent='center'
            data-hook='activity-resource-image'
            className={classes.picBox}
          >
            {resourcePic[item.entity_type]}
          </Box>
        </Box>
        <Box display='flex' flexDirection='column'>
          {transformText(item)}
          <span
            style={{
              opacity: 0.4,
            }}
            data-hook='activity-resource-time'
          >
            {moment(item.createdAt).fromNow()}
          </span>
        </Box>
      </Box>
    ));
  };

  const TrackedVehicles = () => {
    return (
      trackedVehicles?.filter(x => x !== null).length > 0 && (
        <Grid item xs={12}>
          {/* <TireRecommendationsMini
            data-hook='tire-recommdations'
            tracked={trackedVehicles?.filter(x => x !== null)}
          /> */}
          <TireRecomm
            data-hook='tire-recommdations'
            tracked={trackedVehicles?.filter(x => x !== null)}
          />
        </Grid>
      )
    );
  };

  const editDashboard = async () => {
    const payload = { ...preferencePayload };
    delete payload.user_id;
    setUpdatingHomeView(true);
    try {
      const res = await updateDashboard(payload);
      if (res?.status === 200) {
        dispatch(fetchWorkforceQuery());
      }
    } catch (error) {
      console.error('E2109', error);
    } finally {
      setUpdatingHomeView(false);
      setOpenDashboardSettings(false);
    }
  };

  return (
    <div id='my-grid'>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Box id='home-basic-infos'>
            <Grid container spacing={2}>
              <Grid item>
                <Tooltip
                  title={
                    <Typography variant='h6'>
                      {t('homePage.editHomeIcon')}
                    </Typography>
                  }
                >
                  <IconButton
                    style={{ fontSize: '30px' }}
                    onClick={() => setOpenDashboardSettings(true)}
                  >
                    <ViewComfy fontSize={'inherit'} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  {vehicleOverviewVisible && (
                    <Grid item xs={vehicleOverviewSize}>
                      <VehicleOverview
                        data-hook='vehicle-overview'
                        isLoading={isLoadingOverview}
                      />
                    </Grid>
                  )}
                  {tireOverviewVisible && (
                    <Grid item xs={tireOverviewSize}>
                      <TireOverview
                        data-hook='tire-overview'
                        isLoading={isLoadingOverview}
                        Count={IconAndCount}
                        tiresByDepth={tiresByDepth}
                        outServicing={outOfServiceGrouping}
                      />
                    </Grid>
                  )}
                  {savingPotentialVisible && (
                    <Grid item xs={savingsSize} id='savings-potential'>
                      <Card className={classes.card2}>
                        <SavingPotential
                          data-hook='savings-potential'
                          fleets={fleetPresent}
                        />
                      </Card>
                    </Grid>
                  )}
                  {recentActivitiesVisible && recentActivities()}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {preferences?.tire_recommendation && <TrackedVehicles />}
        {preferences?.task_board && (
          <Grid item lg={12} id='actions-required'>
            <ActionsContainer
              xxl={xxl}
              data-hook='actions-container'
              fleetPresent={fleetPresent}
              isLoading={isVehicleDataLoading}
              loadingTasks={loadingTasks}
            />
          </Grid>
        )}
        <Tour
          startAt={0}
          steps={steps}
          isOpen={isTour}
          accentColor={theme.palette.primary.main}
          onRequestClose={() => dispatch(setTour(false))}
          showNavigation={false}
          showButtons={false}
        />
      </Grid>
      <EventsLogDrawer
        open={open}
        data-hook='events-drawer'
        setPage={setPage}
        closeHandler={closeHandler}
        page={page}
        transformText={transformText}
        getUsername={getUsername}
        setResource={setResource}
      />
      <Modal
        className={new_classes.modal}
        open={openDashboardSettings}
        onClose={() => setOpenDashboardSettings(false)}
        closeAfterTransition
        slots={Backdrop}
        slotProps={{
          timeout: 500,
        }}
      >
        <div>
          <DashboardSettings
            preferences={preferencePayload}
            setPreferences={setPreferencePayload}
            staticData={preferences}
            confirmHandler={editDashboard}
            setClose={setOpenDashboardSettings}
            isUpdating={updatingHomeView}
          />
        </div>
      </Modal>
    </div>
  );
};

export default memo(FleetsFoundUI);

export const IconAndCount = ({ icon, count, label, chipColor, isLoading }) => {
  return (
    <Box>
      <Box display='flex' alignItems={'center'} flexDirection={'column'}>
        <Box position='relative'>
          {isLoading ? (
            <Skeleton variant='text' width={40} height={60} />
          ) : (
            icon
          )}
          {isLoading ? (
            <Box position='absolute' bottom={0} right={-10}>
              <Skeleton variant='text' width={30} />
            </Box>
          ) : (
            <Box position='absolute' bottom={0} right={-10}>
              <Chip label={count} color={chipColor || 'primary'} size='small' />
            </Box>
          )}
        </Box>
        {isLoading ? <Skeleton variant='text' width={60} /> : label}
      </Box>
    </Box>
  );
};

IconAndCount.propTypes = {
  icon: PropTypes.any,
  count: PropTypes.any,
  label: PropTypes.any,
  chipColor: PropTypes.any,
  isLoading: PropTypes.bool,
};
FleetsFoundUI.propTypes = {
  profileStatus: PropTypes.any,
  fleetPresent: PropTypes.bool,
  isVehicleDataLoading: PropTypes.bool,
  loadingTasks: PropTypes.bool,
  isLoadingOverview: PropTypes.bool,
};

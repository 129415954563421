import * as Sentry from '@sentry/react';
import SomethingWentWrong from '../pages/SomethingWentWrong';
import { useEffect } from 'react';
import { authProtectedRoutes, publicRoutes } from '../routes/Routes';

const ErrorFallback = props => {
  const { error, resetError } = props;
  console.log('errors', error.message);
  console.error('E10064', {
    props,
  });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SomethingWentWrong />
      <pre>{error.message}</pre>
      <button onClick={resetError}>Try again</button>
    </div>
  );
};

export const SentryErrorBoundryWrapper = ({ children }) => {
  useEffect(() => {
    Sentry.withScope(scope => {
      scope.setTag('section', `error occoured in ${getSection()} section`);
      Sentry.captureException(new Error('error occoured'));
    });
  }, []);

  const getSection = () => {
    const routes = [...authProtectedRoutes, ...publicRoutes];
    const section = routes.find(data =>
      data.path.includes(window.location.pathname.substring(0, 5))
    );
    return section.header;
  };

  return (
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

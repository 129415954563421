import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  IconButton,
  Modal,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import { CloseOutlined, Edit } from '@mui/icons-material';

import trailer_vector from 'assets/images/trailer_vector.svg';
import truck_vector from 'assets/images/truck_vector.svg';
import Loading from 'components/Loading';
import TourContent from 'components/Tour/Content/TourContent';
import VehicleItems from 'pages/FleetsSection/VehicleItems';
import { axl_wise, tireLookup } from './TireConfig/const';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Tour from 'reactour';
import {
  activeFleetSelector,
  allTrackedVehiclesSelector,
  fleetOverviewSelector,
  setActiveFleet,
  setTrackedVehiclesIdsSelector,
  setVehiclesForFleet,
  vehiclesForFleetSelector,
  vehicleUpdatedSelector,
} from 'redux/fleets/ducks';
import { setNotification } from 'redux/notifications/ducks';
import {
  setAccessTokenSelector,
  setPageLevelTourSelector,
  setTour,
  setTourSelector,
  setUserSelector,
} from 'redux/users/ducks';
import {
  assignAccessLevel,
  detachTrailer,
  getDailyReport,
  getVehicle,
  getVehiclesForFleet,
  linkTrailer,
} from 'services/fleet_service';
import { TRACKED_LS, USER_LS, USER_ROLES, VEHICLE } from 'utils/string_utils';
import VehicleDeleteModal from './VehicleDeleteModal';
import VehicleEditModal from './VehicleEditModal';
import classes from './VehiclePage.module.css';
import PopElement from './PopElement';
import AttachAnimation from './AttachAnimation';

import TireConfig from './TireConfig';
import TireRecommendations from './TireRecommendations';
import UsageReports from './UsageReports';
import FileList from './FileList';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { checkTireConfig } from 'utils/helper';
import { ReactComponent as FleetIcon } from 'assets/svg/vehicle/fleet_icon.svg';
import { ReactComponent as Grouping } from 'assets/svg/vehicle/grouping.svg';
import { ReactComponent as Steer } from 'assets/svg/vehicle/steer.svg';
import { ReactComponent as Odometer } from 'assets/svg/vehicle/speedometer.svg';
import { ReactComponent as Tire } from 'assets/svg/vehicle/wheel.svg';
import { ReactComponent as Tank } from 'assets/svg/vehicle/water.svg';
import { ReactComponent as Merged } from 'assets/svg/vehicle/merged.svg';
import { ReactComponent as Truck } from 'assets/svg/home/rec_truck.svg';
import { ReactComponent as Axle } from 'assets/svg/vehicle/axle.svg';
import { ReactComponent as Calendar } from 'assets/svg/vehicle/calendar.svg';
import trailer from 'assets/svg/home/rec_trailer_2.svg';
import { inventoryChangeSelector } from 'redux/tireInventory/ducks';
import CloseIcon from '@mui/icons-material/Close';
import { getWorkForceData } from 'services/auth_service';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import VehicleStatusModal from './VehicleStatusModal';

const useStyles = makeStyles()(theme => {
  return {
    appBar: {
      marginTop: 20,
      boxShadow: 'none',
      padding: 0,
    },
    tabularDetailsBoxContent: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    label: {
      fontSize: '1rem',
    },
    value: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    drawerContent: {
      marginInline: 3,
      display: 'flex',
      justifyContent: 'space-between',
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.spacing(2),
    },
    flexBox: {
      display: 'flex',
    },
    tabularDataContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
    },
    tabularDataContentLeft: {
      width: '50%',
      borderRight: '1px solid rgba(82, 93, 115, 0.2)',
      paddingRight: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    tabularDataContentRight: {
      width: '50%',
      paddingLeft: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    card: {
      position: 'relative',
      paddingInline: 20,
      paddingBlock: 12,
      height: '100%',
    },
    editBox: {
      cursor: 'pointer',
    },
    vehicleName: {
      cursor: 'pointer',
      fontSize: 'clamp(24px, 1vw, 3rem)',
    },
    drawerBtns: {
      fontSize: 'clamp(14px, 1vw, 2.5rem)',
    },
    edit: {
      textTransform: 'unset',
    },
    updateAlt: {
      textTransform: 'unset',
      padding: 0,
      margin: 0,
    },
    trailerName: {
      fontWeight: 1500,
      color: theme.palette.primary.main,
    },
    buttons: {
      textTransform: 'capitalize',
    },
    tab: {
      minWidth: 102,
      textTransform: 'none',
    },
    topBarBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: '0.5rem 0rem',

      borderRight: '1px solid #D1D5DB',
      padding: '0px 24px',
    },
    infoBox: {
      display: 'flex',
      flexDirection: 'row',

      alignItems: 'center',
      minWidth: 200,
    },
    btns: {
      textTransform: 'unset',
      borderRadius: 30,
      marginLeft: '1rem',
    },
    dialog: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: 'inherit',
      gap: '30px',
    },
    fullWidth: {
      width: '100%',
    },
    sliderMain: {
      maxWidth: '100%',
      marginBottom: '20px',
      '& .slick-list': {
        textAlign: 'center',
      },
      '& .slick-slide': {
        '& img': {
          width: '100%',
        },
      },
    },
  };
});

const fleetsDataVehiclePage = 'fleetsDataVehiclePage';

const VehiclePage = () => {
  const { classes: styles } = useStyles();
  const userLs = JSON.parse(USER_LS);
  const isTourRequired = !useSelector(setPageLevelTourSelector)
    ?.fleetsDataVehiclePage;
  const SUPER_ADMIN = USER_ROLES.superAdmin;
  const ADMIN = USER_ROLES.admin;
  const isTour = useSelector(setTourSelector);
  const user = useSelector(setUserSelector);
  const trackedLs = TRACKED_LS;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [vehicle, setVehicle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const [eventDateLink, setEventDateLink] = useState(new Date());
  const trackedVehicles = useSelector(setTrackedVehiclesIdsSelector);
  const { fleet_id, vehicle_id, vehicle_type } = params;
  const [deleteOpen, setDeleteOpen] = useState(false);
  const theme = useTheme();
  const tracked = trackedVehicles || JSON.parse(trackedLs);
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const vehilcesForFleetData = useSelector(vehiclesForFleetSelector);
  const lg = useMediaQuery(`(min-width:1700px)`);
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fleet = useSelector(activeFleetSelector);
  const [fleetVehicles, setFleetVehicles] = useState([]);
  const access_token = useSelector(setAccessTokenSelector);
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [lastNum, setLastNum] = useState(50);
  const [index, setIndex] = useState(0);
  const userDetails = useSelector(setUserSelector) || userLs;
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);
  const currInventoryChangeValue = useSelector(inventoryChangeSelector);
  const [isBouncing, setIsBouncing] = useState(null);
  const [availableTrailers, setAvailableTrailers] = useState([]);
  const [associatedTrailerId, setAssociatedTrailerId] = useState('');
  const userIsNotReporter = userDetails?.iam_role !== USER_ROLES.reporter;
  const [openDialog, setOpenDialog] = useState(false);
  const [isAttachState, setIsAttachState] = useState(true);
  const [trailersList, setTrailersList] = useState([]);
  const [vehiclesData] = useState(vehilcesForFleetData);
  const [openAssignModal, setOpenAssignModal] = useState(false);

  const authToken = localStorage.getItem('access_token');
  const [openAnimation, setOpenAnimation] = useState(false);
  const [users, setUsers] = useState([]);
  let managedVehicles = useSelector(allTrackedVehiclesSelector);
  const [selectedUsers, setSelectedUsers] = useState([]);

  // const [trackTrailer, setTrackTrailer] = useState(false);
  const translation = {
    TRUCK: t('common.truck'),
    TRAILER: t('common.trailer'),
    BUS: 'bus',
  };

  const [typedYear, setTypedYear] = React.useState(eventDateLink.getFullYear());

  const [liveOdo, setLiveOdo] = useState(null);
  // const [isMerged, setIsMerged] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectredTrailers, setSelectredTrailers] = useState([]);
  const [statusModal, setStatusModal] = useState(false);
  // const users = useSelector(workforceSelector);

  useEffect(() => {
    if (fleet_id !== undefined && access_token) {
      fetchFleetVehicles();
    }
    //eslint-disable-next-line
  }, [access_token, fleet_id, managedVehicles, vehicle_id]);

  const fetchFleetVehicles = async () => {
    let temp = [];
    try {
      let res = {};
      if (
        vehilcesForFleetData?.[fleet_id] &&
        vehilcesForFleetData?.[fleet_id]?.status === 200
      ) {
        res = JSON.parse(JSON.stringify(vehilcesForFleetData[fleet_id]));
      } else {
        res = await getVehiclesForFleet({ fleet_id: fleet_id }, access_token);
        dispatch(setVehiclesForFleet(fleet_id, res));
      }

      const trailers = res.data.resp.vehicles.filter(
        vehicle => vehicle.vehicle_type === 'TRAILER'
      );
      // const truck = res?.data?.resp.vehicles.filter(
      //   vehicle => vehicle.vehicle_type === 'TRUCK'
      // );
      setAvailableTrailers(trailers);
      setFleetVehicles(temp);
      setData(
        temp
          .splice(0, 50)
          .filter(
            item =>
              item.fleet_id === vehicle.fleet_id &&
              item.type === vehicle.vehicle_type &&
              item.vehicle_id !== vehicle.vehicle_id
          )
      );
    } catch (error) {
      console.error('E20018', error);
    }
  };

  useEffect(() => {
    const query = async () => {
      try {
        const res = await getDailyReport(vehicle_id);
        if (res.status === 200) {
          const result = res?.data?.resp[vehicle_id];
          if (result?.length > 0) {
            const final = result.sort(function (a, b) {
              return b.timestamp - a.timestamp;
            });
            Object.keys(result).length && setAllData(final);
            if (final?.length > 0) {
              const totalDistance = final.reduce((acc, current) => {
                return acc + current.total_distance_driven;
              }, 0);
              setLiveOdo(totalDistance.toFixed(2));
            }
          }
        }
      } catch (error) {
        console.error('E20026', error);
      }
    };
    if (tracked?.includes(vehicle_id)) {
      query();
    }
  }, [authToken, vehicle_id, tracked]);
  function configAndTrackedStatus(item) {
    let _status = {};
    if (item.axle_type !== null) {
      if (checkTireConfig(item)) {
        _status.configStatus = 'configured';
      } else {
        _status.configStatus = 'partialConfig';
      }
    } else {
      _status.configStatus = 'notConfigured';
    }
    if (item.telematics_protocol) {
      _status.trackStatus = 'tracked';
    } else {
      _status.trackStatus = 'untracked';
    }
    // _status.telematicsProvider = getProtocol(item.vehicle_id, managedVehicles);
    return _status;
  }
  useEffect(() => {
    if (access_token) {
      fetchVehicleData();
    }
    // eslint-disable-next-line
  }, [
    // allVehicles,
    vehicle_id,
    vehicle_type,
    vehicleDataHasChanged,
    // authToken,
    managedVehicles,
    vehiclesData,
    currInventoryChangeValue,
    access_token,
    openAnimation,
    // associatedTrailerId,
  ]);

  const fetchVehicleData = async () => {
    const vType =
      vehicle_type === VEHICLE.vehicle_type.BUSES
        ? VEHICLE.vehicle_type.BUS
        : vehicle_type.slice(0, vehicle_type.length - 1);
    try {
      const res = await getVehicle(vehicle_id, vType);
      if (res.status === 200) {
        const data = res?.data?.resource;
        setVehicle({
          ...data,
          protocol: data.telematics_protocol,
          status: configAndTrackedStatus(data),
        });
        setAssociatedTrailerId(data.couple_data);
        data.vehicle_type === 'TRUCK' && setTrailersList(data.unused_trailer);
      }
    } catch (error) {
      console.error('E20016', error);
    }
  };

  const items = [
    {
      label: t('vehiclePage.tireConfig'),
      component: <TireConfig vehicle={vehicle} usageData={allData} />,
      id: 'tire-config',
    },
    {
      label: t('vehiclePage.vehicleFile'),
      component: <FileList />,
      id: 'vehicle-file',
    },
    {
      label: t('vehiclePage.reports'),
      component: (
        <UsageReports
          isTracked={vehicle.is_telematics_matched}
          isTrailerTracked={vehicle.isCoupled}
        />
      ),
      id: 'reports',
    },
    {
      label: t('vehiclePage.recommendation'),
      component: (
        <TireRecommendations
          isTracked={vehicle.is_telematics_matched}
          vehicleData={vehicle}
        />
      ),
      id: 'recommendations',
    },
  ];

  useEffect(() => {
    const foundFleet = fleets?.find(fleet => fleet.fleet_id === fleet_id);
    if (foundFleet) {
      dispatch(setActiveFleet(foundFleet));
    }
  }, [dispatch, fleets, fleet_id]);

  const getData = useCallback(() => {
    return fleetVehicles
      .splice(index, lastNum)
      .filter(
        item =>
          item.fleet_id === vehicle.fleet_id &&
          item.type === vehicle.vehicle_type &&
          item.vehicle_id !== vehicle.vehicle_id
      );
  }, [index, lastNum, fleetVehicles, vehicle]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loadMore = useCallback(() => {
    setIndex(lastNum);
    setLastNum(prev => prev + 50);
    return setTimeout(() => {
      setData(items => [...items, ...getData()]);
    }, 200);
  }, [lastNum, getData]);

  const drawerItem = (
    <div className={classes.drawer}>
      <Box className={classes.drawerContent}>
        <Box
          fontSize='clamp(20px, 1vw, 2rem)'
          fontWeight={700}
          display='flex'
          alignItems='center'
          gap={'10px'}
        >
          <img
            src={
              vehicle.vehicle_type === VEHICLE.vehicle_type.TRUCK1
                ? truck_vector
                : trailer_vector
            }
            alt='trailer and trucks'
          />{' '}
          {vehicle.vehicle_type === VEHICLE.vehicle_type.TRUCK1
            ? t('vehiclePage.drawerBtn1')
            : t('common.trailer') + 's'}
        </Box>
        <Box onClick={() => setOpen(false)} style={{ cursor: 'pointer' }}>
          <CloseOutlined />
        </Box>
      </Box>
      <Virtuoso
        style={{ height: '100vh' }}
        className={classes.virtuoso}
        data={data}
        endReached={loadMore}
        itemContent={index => (
          <VehicleItems
            subitem={data[index]}
            t={t}
            push={navigate}
            dispatch={dispatch}
            activeVehicle={vehicle}
            entry={data[index].type + 's'}
            vehicles={availableTrailers}
          />
        )}
      />
    </div>
  );

  useEffect(() => {
    if (isTourRequired) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'scroll';
    }
  }, [isTourRequired]);

  const steps = [
    {
      selector: '#vehicle-basic-info',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.vehiclePage.basicVehicleInfo')}
          finalAction='Next'
          goTo={() => goTo(1)}
          page={fleetsDataVehiclePage}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
    {
      selector: '#tire-config',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.vehiclePage.tireConfig')}
          finalAction='Next'
          goTo={() => goTo(2)}
          page={fleetsDataVehiclePage}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
    {
      selector: '#vehicle-file',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.vehiclePage.vehicleFile')}
          finalAction='Next'
          goTo={() => goTo(3)}
          page={fleetsDataVehiclePage}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
    {
      selector: '#reports',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.vehiclePage.reports')}
          finalAction='Next'
          goTo={() => goTo(4)}
          page={fleetsDataVehiclePage}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
    {
      selector: '#recommendations',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.vehiclePage.recommendations')}
          finalAction='Next'
          goTo={() => goTo(5)}
          page={fleetsDataVehiclePage}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
    {
      selector: '#tire-service',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.vehiclePage.tireService')}
          finalAction='Finish'
          goTo={() => {
            dispatch(setTour(false));
          }}
          page={fleetsDataVehiclePage}
        />
      ),
      style: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 8,
      },
    },
  ];

  const checkAccountStatus = label => {
    let condition = vehicle.is_telematics_matched;

    return (
      !condition &&
      label !== t('vehiclePage.tireConfig') &&
      label !== t('vehiclePage.vehicleFile')
    );
  };

  useEffect(() => {
    const processTireInfo = async tire => {
      return { ...tire, status: tire.position ? 200 : 404 };
    };
    if (!!vehicle?.axle_type) {
      const temp = {};
      const axl = vehicle?.axle_type;
      const mountedTires = vehicle?.mounted_tires;

      const resource = Object.keys(axl_wise[axl]);

      for (const element of resource) {
        temp[element] = {
          right: null,
          left: null,
        };
      }
      const x = {};

      mountedTires?.forEach(async (tire, i) => {
        x[i] = tire.axle_position_id;
        let position = tire.position;

        if (vehicle?.axle_type === '6x2') {
          if (position === '2DL') position = '2SL';
          if (position === '2DR') position = '2SR';
        }
        if (position) {
          const axleType =
            vehicle?.axle_type !== '6x2' &&
            vehicle?.axle_type !== '8x2' &&
            vehicle?.axle_type !== '8x4'
              ? vehicle.axle_type === '8x4SS' || vehicle?.axle_type === '10x4TS'
                ? 'X'
                : 'others'
              : vehicle?.axle_type;
          const tireType = tireLookup[axleType][position]?.axle;
          const side = tireLookup[axleType]?.[position]?.side;
          const index = tireLookup[axleType][position]?.index;
          temp[tireType][side] = tireType?.includes('drive')
            ? [null, null]
            : [null];

          try {
            const res = await processTireInfo(tire);

            if (res.status === 200) {
              const data = res;
              const obj = {
                brand: data.brand.toUpperCase(),
                product_line: data.product_line.toUpperCase(),
                size: data.size,
              };
              temp[tireType]?.[side]?.splice(index, 1, obj);
            } else {
              dispatch(setNotification('Something went wrong', 'error', true));
            }
          } catch (error) {
            console.error('E20069', error);
          }
        }
      });
    }

    /*eslint-disable-next-line*/
  }, [vehicle]);

  const infoMissing =
    vehicle?.odometer_reading === null ||
    vehicle?.inspection_date === null ||
    vehicle?.safety_check_date === null;

  useEffect(() => {
    if (infoMissing) {
      setIsBouncing(true);
    }
    const timer = setTimeout(() => {
      setIsBouncing(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [infoMissing]);

  const linkTrailerHandler = async () => {
    if (isAttachState) {
      try {
        const res = await linkTrailer(
          vehicle_id,
          selectredTrailers.map(trailer => trailer.trailer_id),
          moment(eventDateLink).format('YYYY-MM-DD')
        );
        if (res.status === 200) {
          setOpenDialog(false);
          setOpenAnimation(true);
          dispatch(setNotification(res?.data?.return_message, 'success', true));
          const res_ = await getVehiclesForFleet(
            { fleet_id: fleet_id },
            access_token
          );
          dispatch(setVehiclesForFleet(fleet_id, res_));
          fetchVehicleData();
        } else {
          dispatch(setNotification(res?.data?.return_message, 'error', true));
        }
      } catch (error) {
        console.error('E20083', error);
      }
    } else {
      try {
        const res = await detachTrailer(
          vehicle_id,
          selectredTrailers.map(trailer => trailer.trailer_id),
          moment(eventDateLink).format('YYYY-MM-DD')
        );
        if (res.status === 200) {
          setOpenDialog(false);
          setAssociatedTrailerId([]);
          dispatch(setNotification(res?.data?.return_message, 'success', true));
          const res_ = await getVehiclesForFleet(
            { fleet_id: fleet_id },
            access_token
          );
          dispatch(setVehiclesForFleet(fleet_id, res_));
          fetchFleetVehicles();
          fetchVehicleData();
        } else {
          dispatch(setNotification(t('common.error'), 'error', true));
        }
      } catch (error) {
        console.error('E20083', error);
      }
    }
  };

  const closeAnimationHandler = () => {
    setOpenAnimation(false);
  };

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (fleet && JSON.stringify(vehicle) !== '{}') {
      setIsLoading(false);
    }
  }, [fleet, vehicle]);

  if (isLoading) {
    return <Loading />;
  }

  const renderEditIcon = () => {
    if (infoMissing) {
      return (
        <Box display='flex' alignItems='center' className={styles.edit}>
          <Button
            variant='text'
            color='primary'
            className={styles.updateAlt}
            data-cy='update-missing-info-btn'
          >
            <Typography>{t('vehiclePage.pending')}</Typography>
          </Button>
          <Edit color='primary' />
        </Box>
      );
    }
    return (
      <EditOutlinedIcon
        data-hook='edit-vehicle-btn'
        sx={{ cursor: 'pointer' }}
        color='primary'
        data-cy='update-missing-info-btn'
      />
    );
  };

  const renderEditPopup = () => {
    if (userIsNotReporter) {
      return (
        <Box className={styles.flexBox}>
          <Box style={{ marginTop: 10 }} onClick={() => setOpenEdit(true)}>
            <PopElement isLoading={isBouncing}>{renderEditIcon()}</PopElement>
          </Box>
          <Box>
            <IconButton
              aria-label='delete'
              data-hook='delete-vehicle-icon'
              onClick={() => setDeleteOpen(true)}
            >
              <DeleteOutlinedIcon color='primary' fontSize={'medium'} />
            </IconButton>
          </Box>
        </Box>
      );
    }
  };

  const handleSubmit = async () => {
    let value = {
      assign_to_all: false,
      vehicles: {
        include: [],
        exclude: [],
      },
    };
    let vType = 'vehicles';
    value[vType]['include'] = [vehicle.vehicle_id];

    const values = {
      user_id: selectedUsers.map(user => user?.user_id),
      fleets: {
        [vehicle.fleet_id]: value,
      },
    };
    setIsSubmitting(true);
    try {
      await assignAccessLevel(access_token, values);
      dispatch(
        setNotification(t('vehiclePage.assignSuccessMsg'), 'success', true)
      );
    } catch (err) {
      dispatch(setNotification(t('common.assignFailureMsg'), 'error', true));
      throw new Error('error occoured' + err);
    } finally {
      setOpenAssignModal(false);
      setIsSubmitting(false);
    }
  };

  const renderSingleUploadModal = () => {
    return (
      <Modal
        open={openAssignModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          sx={{
            maxHeight: '80%',
            overflow: 'auto',
            width: '30%',
            backgroundColor: 'white',
            padding: '2rem',
            position: 'relative',
          }}
        >
          <Grid container spacing={3} width='100%'>
            <Grid item xs={12}>
              <Typography variant='h5' data-hook='assign-resource-modal-title'>
                {t('vehiclePage.resourceBtn')}
              </Typography>
            </Grid>
            <IconButton
              style={{
                position: 'absolute',
                right: 10,
                top: 10,
                cursor: 'pointer',
              }}
              data-hook='close-assign-modal'
              onClick={() => setOpenAssignModal(false)}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={3}>
            <Grid item mt={3} xs={12}>
              <Autocomplete
                multiple
                id='tags-outlined'
                data-hook='select-users-dropdown'
                options={users?.filter(
                  user =>
                    user.name !== 'CO2OPT BOT' &&
                    user.iam_role !== ADMIN &&
                    user.iam_role !== SUPER_ADMIN
                )}
                getOptionLabel={option => option.name}
                filterSelectedOptions
                onChange={(e, value) => {
                  setSelectedUsers(value);
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    style={{ backgroundColor: '#fff' }}
                    variant='outlined'
                    label='Select Users'
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box mt={3} style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              variant='contained'
              onClick={handleSubmit}
              style={{ border: '1px solid #69A628' }}
              disabled={!selectedUsers?.length || isSubmitting}
              data-hook='assign-resource-submit-btn'
              className={styles.buttons}
            >
              {isSubmitting ? (
                <CircularProgress size={30} color='inherit' />
              ) : (
                t('common.submitBtn')
              )}
            </Button>
          </Box>
        </Paper>
      </Modal>
    );
  };

  const renderTireSize = (isPresent, src, tireSize, tireCarrier) => {
    if (isPresent) {
      return (
        <Grid item lg={4} md={4}>
          <Box className={styles.infoBox}>
            <Box
              mx={2}
              style={{
                width: '45px',
                height: '45px',
                flexShrink: 0,
                borderRadius: '8px',
                background: 'var(--Light-grey, #F6F9F7)',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '30px',
                marginBottom: '30px',
              }}
            >
              {src}
            </Box>
            <Box>
              <Typography color='textSecondary' variant='h6'>
                {tireCarrier}
              </Typography>
              <Typography color='primary' variant='h6'>
                {tireSize}
              </Typography>
            </Box>
          </Box>
        </Grid>
      );
    }
  };

  const renderTankCapacity = () => {
    if (vehicle.metadata?.tank_capacity) {
      return (
        <Grid item lg={4} md={4}>
          <Box className={styles.infoBox}>
            <Box
              mx={2}
              style={{
                width: '45px',
                height: '45px',
                flexShrink: 0,
                borderRadius: '8px',
                background: 'var(--Light-grey, #F6F9F7)',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '30px',
                marginBottom: '30px',
              }}
            >
              <Tank stroke={theme.palette.primary.main} alt='tank capacity' />
            </Box>
            <Box>
              <Typography color='textSecondary' variant='h6'>
                {t('vehiclePage.tank')}
              </Typography>
              <Typography color='primary' variant='h6'>
                {vehicle.metadata.tank_capacity}
              </Typography>
            </Box>
          </Box>
        </Grid>
      );
    }
  };

  const renderAssociatedTrailer = () => {
    if (associatedTrailerId?.length) {
      return (
        <Button
          variant='outlined'
          color='primary'
          fullWidth
          onClick={() => {
            setIsAttachState(false);
            setOpenDialog(true);
          }}
          data-hook='detach-trailer-button'
          style={{
            margin: '1rem 0rem',
            textTransform: 'unset',
          }}
        >
          {'Detach'}
        </Button>
      );
    }
  };

  const renderSelectTrailerForm = () => {
    if (userIsNotReporter) {
      return (
        <>
          <Grid item mt={3} xs={12}>
            <Autocomplete
              multiple
              id='tags-outlined'
              data-hook='select-users-dropdown'
              options={trailersList}
              getOptionLabel={option => option.trailer_name}
              filterSelectedOptions
              onChange={(e, value) => {
                setSelectredTrailers(value);
              }}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    style={{ backgroundColor: '#fff' }}
                    variant='outlined'
                    label='Select Trailers'
                  />
                );
              }}
            />
          </Grid>
          <Box sx={{ marginTop: 4 }}>
            <Grid container spacing={3}>
              <Grid item lg={12}>
                <Button
                  color='primary'
                  variant='contained'
                  style={{
                    textTransform: 'unset',
                    borderRadius: 30,
                  }}
                  disabled={!selectredTrailers?.length}
                  fullWidth
                  onClick={() => {
                    setIsAttachState(true);
                    setOpenDialog(true);
                  }}
                >
                  {t('common.confirm')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      );
    }
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderTrailerSection = () => {
    return (
      <Box>
        {associatedTrailerId.length ? (
          <>
            <Slider {...settings} className={styles.sliderMain}>
              {associatedTrailerId.map(item => (
                <Box
                  style={{
                    display: 'flex',
                    width: '100%',
                    padding: '12px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '12px',
                    background: 'var(--Light-grey, #F6F9F7)',
                  }}
                  my={1}
                >
                  <img src={trailer} alt='trailer' />
                  <Typography variant='h6'>{item?.trailer_name}</Typography>
                </Box>
              ))}
            </Slider>

            {renderAssociatedTrailer()}
          </>
        ) : (
          <Box
            style={{
              display: 'flex',
              width: '100%',
              padding: '12px',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '12px',
              background: 'var(--Light-grey, #F6F9F7)',
            }}
            my={1}
          >
            <Typography variant='h6'>{'NA'}</Typography>
          </Box>
        )}
        {renderSelectTrailerForm()}
      </Box>
    );
  };
  const renderAddTrailerSection = () => {
    if (vehicle_type === VEHICLE.vehicle_type.TRUCKS) {
      return (
        <Grid item lg={4} md={4} xs={12}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              padding: '16px',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              borderRadius: '16px',
              border: '0.5px solid var(--default-gray-200, #E5E7EB)',
              background: 'var(--default-white, #FFF)',
              boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
            }}
          >
            <>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',

                  alignItems: 'center',
                }}
              >
                <Box
                  mx={2}
                  style={{
                    width: '45px',
                    height: '45px',

                    borderRadius: '8px',
                    background: 'var(--Light-grey, #F6F9F7)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Merged fill={theme.palette.primary.main} alt='merged' />
                </Box>
                <Typography variant='subtitle1'>
                  {t('vehiclePage.selectLabel')}
                </Typography>
              </Box>

              {renderTrailerSection()}

              <Dialog
                open={openDialog}
                style={{ padding: '64px 98px' }}
                data-hook='attach-trailer-dialog'
                onClose={closeDialogHandler}
              >
                <DialogTitle>
                  <Typography
                    variant='h5'
                    align='center'
                    data-hook='attach-trailer-title'
                  >
                    {isAttachState
                      ? t('vehiclePage.dialogTitle')
                      : t('vehiclePage.dialogTitle2')}
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Box display='flex' flexDirection='column' gap={'20px'}>
                    <Typography variant='h6'>
                      {isAttachState
                        ? t('vehiclePage.dialogPromptFirst1')
                        : t('vehiclePage.dialogPromptFirst2')}
                      <span>{'trailers'}</span>{' '}
                      {isAttachState
                        ? t('vehiclePage.dialogPromptSecond1')
                        : t('vehiclePage.dialogPromptSecond2')}
                    </Typography>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'center'}
                    >
                      <Truck fill={theme.palette.primary.main} alt='truck' />
                      <img
                        src={trailer}
                        style={{
                          marginLeft: isAttachState ? '-100px' : '0px',
                        }}
                        alt='truck'
                      />
                    </Box>
                    <Box width='100%'>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label={t('tireOperationsModal.eventDate')}
                          value={eventDateLink}
                          onChange={date => {
                            setEventDateLink(date);
                            const year = date.getFullYear().toString();
                            setTypedYear(parseInt(year));
                          }}
                          format='yyyy.MM.dd'
                          sx={{ width: '100%' }}
                          data-hook='event-date-picker'
                        />
                      </LocalizationProvider>
                    </Box>
                    {!isAttachState && (
                      <Grid item mt={3} xs={12}>
                        <Autocomplete
                          multiple
                          id='tags-outlined'
                          data-hook='select-users-dropdown'
                          options={associatedTrailerId}
                          getOptionLabel={option => option.trailer_name}
                          filterSelectedOptions
                          onChange={(e, value) => {
                            setSelectredTrailers(value);
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              style={{ backgroundColor: '#fff' }}
                              variant='outlined'
                              label='Select Trailers'
                            />
                          )}
                        />
                      </Grid>
                    )}

                    <Grid container spacing={3}>
                      <Grid item lg={6}>
                        <Button
                          variant='outlined'
                          color='primary'
                          size='small'
                          style={{
                            textTransform: 'unset',
                            borderRadius: 30,
                          }}
                          fullWidth
                          data-hook='attach-trailer-cancel-button'
                          onClick={closeDialogHandler}
                        >
                          {t('common.cancel')}
                        </Button>
                      </Grid>

                      <Grid item lg={6}>
                        <Button
                          color='primary'
                          variant='contained'
                          style={{
                            textTransform: 'unset',
                            borderRadius: 30,
                          }}
                          data-hook='attach-trailer-confirm-button'
                          disabled={
                            eventDateLink.toString() === 'Invalid Date' ||
                            typedYear < 1900 ||
                            typedYear > 2099 ||
                            (!isAttachState && !selectredTrailers?.length)
                          }
                          fullWidth
                          onClick={linkTrailerHandler}
                        >
                          {t('common.confirm')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
              </Dialog>
            </>
          </Box>
        </Grid>
      );
    }
  };

  const renderVehicleDetails = () => {
    return (
      <>
        <Box style={{ width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='fullWidth'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
          >
            {items.length &&
              items.map((item, i) => (
                <Tab
                  id={item.id}
                  className={`${classes.hov} ${styles.tab}`}
                  key={item.id}
                  data-hook={`${item.id}_tab`}
                  label={
                    <Tooltip
                      style={{ fontSize: 30 }}
                      title={
                        <h3>
                          {!vehicle.is_telematics_matched
                            ? t('vehiclePage.noAccessResponse')
                            : ''}
                        </h3>
                      }
                    >
                      <Typography
                        variant='h6'
                        style={{
                          color: checkAccountStatus(item.label)
                            ? 'gray'
                            : 'black',
                        }}
                      >
                        {item.label}
                      </Typography>
                    </Tooltip>
                  }
                  disabled={item.disabled || checkAccountStatus(item.label)}
                />
              ))}
          </Tabs>
        </Box>
        <Grid item xs={12} md={12} lg={12} xl={12}>
          {items.length &&
            items.map((item, i) => (
              <div hidden={value !== i} key={i}>
                {value === i && <Box mt={2}>{item.component}</Box>}
              </div>
            ))}
        </Grid>
        <Drawer
          anchor='right'
          role='presentation'
          open={open}
          onClose={() => setOpen(false)}
        >
          {drawerItem}
        </Drawer>
      </>
    );
  };
  const renderVehicleStatus = status => {
    if (
      (process.env.REACT_APP_ENV === 'stage' &&
        user?.customer_id === 'Y2NDQ3NDY') ||
      (process.env.REACT_APP_ENV === 'production' &&
        user?.customer_id === 'I2NDQ3NDY')
    ) {
      if (status === 'AL') {
        return (
          <Chip
            label='RL'
            color='secondary'
            size='small'
            onClick={() => setStatusModal(true)}
          />
        );
      } else if (status === 'RL') {
        return (
          <Chip
            label='AL'
            color='primary'
            size='small'
            onClick={() => setStatusModal(true)}
          />
        );
      } else {
        return (
          <Chip label='N/A' size='small' onClick={() => setStatusModal(true)} />
        );
      }
    } else {
      return;
    }
  };

  const fetchFleetVehicle = async () => {
    try {
      const res = await getVehiclesForFleet({ fleet_id }, access_token, false);
      dispatch(setVehiclesForFleet(fleet_id, res));
      return res.status;
    } catch (error) {}
  };
  const renderVehicleDeleteModal = () => {
    if (userIsNotReporter) {
      return (
        <VehicleDeleteModal
          open={deleteOpen}
          setOpen={setDeleteOpen}
          vehicle={vehicle}
          fetchFleetVehicle={fetchFleetVehicle}
        />
      );
    }
  };
  const renderVehicleStatusModal = () => {
    if (vehicle) {
      return (
        <VehicleStatusModal
          open={statusModal}
          handleClose={() => setStatusModal(false)}
          vehicle={vehicle}
          fetchVehicleData={fetchVehicleData}
        />
      );
    }
  };

  const rendrVehicleStatusDetails = field => {
    if (field === 'bg') {
      if (vehicle?.status?.trackStatus === 'tracked') {
        return 'var(--Light-green, #DEF2CC)';
      }
      return 'var(--default-gray-200, #E5E7EB)';
    }
    if (vehicle?.status?.trackStatus === 'tracked') {
      return 'var(--extended-lime-700, #4D7C0F)';
    }
    return 'var(--default-gray-500, #6B7280)';
  };

  function odometerView() {
    const odometer = liveOdo ? `${liveOdo} km` : vehicle?.odometer_reading;
    const odometerLabel = liveOdo
      ? t('vehiclePage.odometerLabel')
      : t('vehiclePage.odometer');
    return (
      <Grid item lg={4} md={4}>
        <Box className={styles.infoBox}>
          <Box
            mx={2}
            style={{
              width: '45px',
              height: '45px',
              flexShrink: 0,
              borderRadius: '8px',
              background: 'var(--Light-grey, #F6F9F7)',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '30px',
              marginBottom: '30px',
            }}
            data-hook='odometer'
          >
            <Odometer fill={theme.palette.primary.main} alt='odometer' />
          </Box>
          <Box>
            <Typography
              color='textSecondary'
              variant='h6'
              data-hook='odometer-label'
            >
              {odometerLabel}
            </Typography>
            <Typography color='primary' variant='h6' data-hook='odometer-value'>
              {odometer ?? 'N/A'}
            </Typography>
          </Box>
        </Box>
      </Grid>
    );
  }

  async function handleOpenAssignModal() {
    if (!users.length) {
      const response = await getWorkForceData();
      setUsers(response?.data?.resp?.workforceData);
    }
    setOpenAssignModal(true);
  }

  return (
    <>
      <Box>
        <Box className={styles.container}>
          <Breadcrumbs separator='>' aria-label='breadcrumb'>
            <NavLink
              color='inherit'
              variant='text'
              to={'/fleets-data/' + fleet?.fleet_id}
              className={classes.nav}
              data-hook='fleet-nav-link'
            >
              {fleet?.fleet_name}
            </NavLink>
            <span
              className={classes.vehicleType}
              color='primary'
              data-hook='vehicle-type'
            >
              {translation[vehicle?.vehicle_type]}
            </span>
            <Typography
              className={styles.vehicleName}
              color='primary'
              data-hook='vehicle-name'
            >
              {vehicle?.name}{' '}
              {renderVehicleStatus(vehicle?.metadata?.interchange?.status)}
            </Typography>
          </Breadcrumbs>

          {renderEditPopup()}
          {renderVehicleStatusModal()}
        </Box>
      </Box>
      <Box
        mt={2}
        data-hook='vehicle-basic-info'
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: !lg ? 'flex-start' : 'space-around',
          alignItems: 'center',
          flexWrap: 'wrap',
          padding: '24px 138px 24px 20px',
          boxShadow: 'none',
          borderRadius: '16px',
          border: '0.5px solid var(--default-gray-300, #D1D5DB)',
          background: 'var(--Light-grey, #F6F9F7)',
        }}
      >
        <Box className={styles.topBarBox}>
          <Typography variant='h6'>
            {translation[vehicle?.vehicle_type]}
          </Typography>
        </Box>
        <Box className={styles.topBarBox}>
          <Typography variant='h6' align='center'>
            {vehicle?.make}
          </Typography>
        </Box>
        <Box className={styles.topBarBox}>
          <Box
            style={{
              display: 'flex',
              padding: '4px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
              border: '1.5px solid var(--default-gray-500, #6B7280)',
              background: 'var(--default-white, #FFF)',
            }}
          >
            <Typography variant='h6'>{vehicle?.reg_plate}</Typography>
          </Box>
        </Box>
        <Box className={styles.topBarBox}>
          <Typography variant='h6'>{vehicle.vin_chassis} </Typography>
        </Box>
        <Box className={styles.topBarBox}>
          <Box mx={1}>
            <FleetIcon stroke={theme.palette.primary.main} alt='fleet' />
          </Box>
          <Typography variant='h6' gutterBottom>
            {fleet?.fleet_name}
          </Typography>
        </Box>
        <Box className={styles.topBarBox}>
          <Box
            style={{
              padding: '2px 18px',
              borderRadius: '30px',
              backgroundColor:
                vehicle?.status?.configStatus === 'configured'
                  ? 'var(--Light-green, #DEF2CC)'
                  : vehicle?.status?.configStatus === 'partialConfig'
                  ? 'var(--extended-orange-100, #FFEDD5)'
                  : 'var(--default-red-100, #FEE2E2)',
              color:
                vehicle?.status?.configStatus === 'configured'
                  ? 'var(--extended-lime-700, #4D7C0F)'
                  : vehicle?.status?.configStatus === 'partialConfig'
                  ? 'var(--extended-orange-500, #F97316)'
                  : 'var(--default-red-500, #EF4444)',
            }}
          >
            <Typography variant='body1'>
              {t(`common.${vehicle?.status?.configStatus}`)}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.topBarBox}>
          <Box
            style={{
              padding: '2px 18px',
              borderRadius: '30px',
              backgroundColor: rendrVehicleStatusDetails('bg'),

              color: rendrVehicleStatusDetails('color'),
            }}
          >
            <Typography variant='body1'>
              {!vehicle.is_telematics_matched
                ? t(`homePage.${vehicle?.status?.trackStatus}`)
                : t(`homePage.tracked`)}
            </Typography>
          </Box>
        </Box>
        <Box className={styles.topBarBox}>
          <Box
            style={{
              padding: '2px 18px',
              borderRadius: '30px',
            }}
          >
            <Button
              style={{
                border: '1px solid #69A628',
                borderRadius: '10px',
                textTransform: 'capitalize',
              }}
              data-hook='assign-resources-btn'
              onClick={() => handleOpenAssignModal()}
            >
              {t('vehiclePage.resourceBtn')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box my={2} data-hook='vehicle-info'>
        <Grid container spacing={3}>
          <Grid
            item
            lg={vehicle_type === VEHICLE.vehicle_type.TRUCKS ? 8 : 12}
            md={vehicle_type === VEHICLE.vehicle_type.TRUCKS ? 8 : 12}
            xs={12}
          >
            <Box
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                padding: '16px',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
                borderRadius: '16px',
                border: '0.5px solid var(--default-gray-200, #E5E7EB)',
                background: 'var(--default-white, #FFF)',

                boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
              }}
            >
              <Grid container spacing={3}>
                <Grid item lg={4} md={4}>
                  <Box className={styles.infoBox}>
                    <Box
                      data-hook='grouping'
                      mx={2}
                      style={{
                        width: '45px',
                        height: '45px',
                        flexShrink: 0,
                        borderRadius: '8px',
                        background: 'var(--Light-grey, #F6F9F7)',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '30px',
                        marginBottom: '30px',
                      }}
                    >
                      <Grouping
                        fill={theme.palette.primary.main}
                        alt='grouping'
                      />
                    </Box>
                    <Box>
                      <Typography
                        color='textSecondary'
                        variant='h6'
                        data-hook='grouping-label'
                      >
                        {t('vehiclePage.grouping')}
                      </Typography>
                      <Typography
                        color='primary'
                        variant='h6'
                        data-hook='grouping-value'
                      >
                        {vehicle?.internal_grouping}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {renderTireSize(
                  vehicle.tire_size?.steer_tire_size,
                  <Steer stroke={theme.palette.primary.main} />,
                  vehicle?.tire_size?.steer_tire_size,
                  t('vehiclePage.steer')
                )}
                {renderTireSize(
                  vehicle.tire_size.drive_tire_size,
                  <Tire fill={theme.palette.primary.main} />,
                  vehicle.tire_size.drive_tire_size,
                  t('vehiclePage.drive')
                )}
                {renderTireSize(
                  vehicle.tire_size?.tire_size,
                  <Tire fill={theme.palette.primary.main} />,
                  vehicle.tire_size?.tire_size,
                  t('vehiclePage.tireSize')
                )}
                {odometerView()}

                {renderTankCapacity()}
                <Grid item lg={4} md={4}>
                  <Box className={styles.infoBox}>
                    <Box
                      mx={2}
                      style={{
                        width: '45px',
                        height: '45px',
                        flexShrink: 0,
                        borderRadius: '8px',
                        background: 'var(--Light-grey, #F6F9F7)',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '30px',
                        marginBottom: '30px',
                      }}
                      data-hook='axle'
                    >
                      <Axle
                        fill={theme.palette.primary.main}
                        stroke={theme.palette.primary.main}
                        alt='axle'
                      />
                    </Box>
                    <Box>
                      <Typography
                        color='textSecondary'
                        variant='h6'
                        data-hook='axle-label'
                      >
                        {t('vehiclePage.axle')}
                      </Typography>
                      <Typography
                        color='primary'
                        variant='h6'
                        data-hook='axle-value'
                      >
                        {vehicle?.axle_type ?? 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4}>
                  <Box className={styles.infoBox}>
                    <Box
                      mx={2}
                      style={{
                        width: '45px',
                        height: '45px',
                        flexShrink: 0,
                        borderRadius: '8px',
                        background: 'var(--Light-grey, #F6F9F7)',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '30px',
                        marginBottom: '30px',
                      }}
                      data-hook='calendar'
                    >
                      <Calendar
                        stroke={theme.palette.primary.main}
                        alt='calendar'
                      />
                    </Box>
                    <Box>
                      <Typography
                        color='textSecondary'
                        variant='h6'
                        data-hook='inspection-label'
                      >
                        {t('vehiclePage.inspection')}
                      </Typography>
                      <Typography
                        color='primary'
                        variant='h6'
                        data-hook='inspection-value'
                      >
                        {vehicle?.inspection_date
                          ? moment(vehicle?.inspection_date).format('YYYY.MM')
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item lg={4} md={4}>
                  <Box className={styles.infoBox}>
                    <Box
                      mx={2}
                      style={{
                        width: '45px',
                        height: '45px',
                        flexShrink: 0,
                        borderRadius: '8px',
                        background: 'var(--Light-grey, #F6F9F7)',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '30px',
                        marginBottom: '30px',
                      }}
                      data-hook='calendar'
                    >
                      <Calendar
                        stroke={theme.palette.primary.main}
                        alt='calendar'
                      />
                    </Box>
                    <Box>
                      <Typography
                        color='textSecondary'
                        variant='h6'
                        data-hook='safety-label'
                      >
                        {t('vehiclePage.safetyCheck')}
                      </Typography>
                      <Typography
                        color='primary'
                        variant='h6'
                        data-hook='safety-value'
                      >
                        {vehicle?.safety_check_date
                          ? moment(vehicle?.safety_check_date).format('YYYY.MM')
                          : 'N/A'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {vehicle?.vehicle_type?.toLowerCase() === 'trailer' && (
                  <Grid item lg={4} md={4}>
                    <Box className={styles.infoBox}>
                      <Box
                        mx={2}
                        style={{
                          width: '45px',
                          height: '45px',
                          flexShrink: 0,
                          borderRadius: '8px',
                          background: 'var(--Light-grey, #F6F9F7)',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginTop: '30px',
                          marginBottom: '30px',
                        }}
                        data-hook='merge-status'
                      >
                        <Merged stroke={theme.palette.primary.main} />
                      </Box>
                      <Box>
                        <Typography color='textSecondary' variant='h6'>
                          {t('vehiclePage.mergeStatus')}
                        </Typography>
                        <Typography color='primary' variant='h6'>
                          {vehicle?.isCoupled
                            ? t(`tireInventoryItems.coupled`)
                            : t(`tireInventoryItems.unmerged`)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          {renderAddTrailerSection()}
        </Grid>
      </Box>

      {renderVehicleDetails()}
      {renderVehicleDeleteModal()}
      <Tour
        startAt={0}
        steps={steps}
        isOpen={isTour}
        accentColor={theme.palette.primary.main}
        onRequestClose={() => dispatch(setTour(false))}
        showNavigation={false}
        showButtons={false}
      />
      {openEdit && (
        <VehicleEditModal
          data-hook='editmodal'
          open={openEdit}
          vehicle={vehicle}
          setOpen={setOpenEdit}
          isTelematicsAvailable={!!liveOdo}
          isMerged={vehicle?.isCoupled}
        />
      )}
      {openAnimation && (
        <AttachAnimation
          open={openAnimation}
          handleClose={closeAnimationHandler}
          t={t}
        />
      )}
      {renderSingleUploadModal()}
    </>
  );
};

export default VehiclePage;

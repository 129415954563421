import krone from 'assets/images/krone_.png';
import transics from 'assets/images/transics.png';
import rio from 'assets/images/rio2.png';
import webfleet from 'assets/images/webfleetLogo.png';
import fleet_board from 'assets/images/fleetBoard.png';
import spedion from 'assets/images/spedion_.png';
import schmitz_ from 'assets/images/SCB_Logo_.png';
import yellow from 'assets/images/yellowfox_.png';
import sensolus from 'assets/images/sensolus_.png';
import route42 from 'assets/images/route42.png';
import trimble from 'assets/images/trimble_logo.png';
import idem from 'assets/images/idem.png';
import volvo from 'assets/images/volvo_telematics.png';
export const providerDetails = [
  {
    name: 'transics',
    imgSrc: transics,
  },
  {
    name: 'krone',
    imgSrc: krone,
  },
  {
    name: 'spedion',
    imgSrc: spedion,
  },
  {
    name: 'schmitz',
    imgSrc: schmitz_,
  },
  {
    name: 'fleetboard',
    imgSrc: fleet_board,
  },
  {
    name: 'rio',
    imgSrc: rio,
  },
  {
    name: 'webfleet',
    imgSrc: webfleet,
  },
  {
    name: 'yellowfox',
    imgSrc: yellow,
  },
  {
    name: 'sensolus',
    imgSrc: sensolus,
  },

  {
    name: 'route42',
    imgSrc: route42,
  },
  {
    name: 'trimble',
    imgSrc: trimble,
  },
  {
    name: 'idem',
    imgSrc: idem,
  },
  {
    name: 'volvo',
    imgSrc: volvo,
  },
];

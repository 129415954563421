import React from 'react';
import { useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import { resetvalidationSchema } from 'utils/string_utils';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPasswordUser } from 'redux/users/ducks';
import { useDispatch, useSelector } from 'react-redux';
import { setStatusSelector } from 'redux/users/ducks';
import Layout from 'components/Layout';
import CustomTextField from 'components/custom/CustomTextField';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      minWidth: 275,
      margin: '0rem 0.5rem',
      padding: '48px 68px 22px',
      boxShadow:
        '0px 5px 15px rgba(0, 0, 0, 0.12), 0px 15px 35px rgba(60, 66, 87, 0.08)',
      borderRadius: 4,
      [theme.breakpoints.down('sm')]: {
        padding: '0px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '0px',
      },
    },
    title: {
      fontSize: 14,
    },
    links: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    img: {
      width: '100%',
      height: '100%',
    },
    btn: {
      margin: '28px 0px',
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
    },
  };
});

const ForgotPassword = () => {
  const { classes } = useStyles();
  const status = useSelector(setStatusSelector);
  let navigate = useNavigate();

  const dispatch = useDispatch();
  const theme = useTheme();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: resetvalidationSchema,
    onSubmit: async values => {
      dispatch(forgotPasswordUser(values.email));
      formik.resetForm();
    },
  });

  useEffect(() => {
    if (status === 'forget') {
      navigate('/reset-password');
    }
    // eslint-disable-next-line
  }, [status]);

  return (
    <Layout>
      <Box
        width='100vw'
        height='100vh'
        bgcolor='#fff'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
      >
        <Box my='1rem'>
          <img src={theme.logo} alt='Logo' className={classes.img} />
        </Box>
        <Card className={classes.root}>
          <CardContent>
            <Typography variant='h4' gutterBottom align='left'>
              Forgot Your Passsword
            </Typography>
            <Typography variant='p' gutterBottom align='left'>
              Please enter the registered email
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <CustomTextField
                margin={'normal'}
                label={'Email'}
                name={'email'}
                id={'email'}
                required
                type={'email'}
                variant={'outlined'}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                fullWidth
              />

              <Box width='100%' mx='auto'>
                <Button
                  color={'primary'}
                  type={'submit'}
                  variant={'contained'}
                  className={classes.btn}
                  fullWidth
                >
                  <Typography variant='h6' align='center'>
                    Submit
                  </Typography>
                </Button>
              </Box>

              <Typography variant='h6' gutterBottom align='center'>
                <Link to='/login'>
                  <span className={classes.links}> {' < '}Back To Login</span>
                </Link>
              </Typography>
            </form>
          </CardContent>
        </Card>
        <Box my='1rem'>
          <Typography variant='subtitle1' color='textSecondary' gutterBottom>
            &copy;Co2Opt&nbsp;.&nbsp;Contact&nbsp;.&nbsp;Privacy&Terms
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import { makeStyles } from 'tss-react/mui';
import {
  Add,
  ExpandLess,
  ExpandMore,
  Layers,
  Storage,
} from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { darken, useTheme, styled } from '@mui/material/styles';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { useTranslation } from 'react-i18next';

import { setAccessTokenSelector } from 'redux/users/ducks';
import {
  activeFleetSelector,
  fetchFleets,
  fleetOverviewSelector,
  fleetUpdatedSelector,
  setActiveFleet,
  setChangeInFleet,
} from 'redux/fleets/ducks';
import MotionWrap from 'wrapper/MotionWrap';
import { telematicsDataSelector } from 'redux/telematicsProvider/ducks';
import { useLocation } from 'react-router-dom';
import DrawerFooter from '../DrawerFooter';
import styles from './Sidebar.module.css';
import { TELEMATICS_INFO } from 'utils/string_utils';
import { ReactComponent as ReportsIcon } from 'assets/svg/common/chartsIcon.svg';
import { ReactComponent as Home } from 'assets/svg/common/home.svg';
import { ReactComponent as Logout } from 'assets/svg/common/logout.svg';
import AddFleet from '../AddFleet';
import ListItemButton from '@mui/material/ListItemButton';
import { fleetCsvUpload } from 'services/fleet_service';
import { setNotification } from 'redux/notifications/ducks';

const drawerWidth = 325;
const xlDrawerWidth = 425;

const myStyle = makeStyles()(theme => {
  return {
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',

      [theme.breakpoints.up('xl')]: {
        width: xlDrawerWidth,
      },
    },
    drawerOpen: {
      backgroundColor: '#1F2937 ',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
    },
    drawerClose: {
      backgroundColor: '#1F2937 ',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(12) + 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    nested: {
      paddingLeft: theme.spacing(4),
      textTransform: 'capitalize',
      cursor: 'pointer',

      '&:hover': {
        background: darken('#1F2937', 0.2),
      },
    },
    addFleetBtn: {
      marginLeft: theme.spacing(4),
      justifyContent: 'flex-start',
      textTransform: 'unset',
      color: '#fff',
      width: '300px',
    },
    active: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      color: theme.palette.primary.main,
      position: 'relative',
    },
    logo: {
      marginTop: '1rem',
      marginBottom: '2rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      marginLeft: '-50px',
    },
    list: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      height: '100%',
    },
    sidbarIcon: {
      fontSize: 32,
    },
    link: {
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
    },
    listItem: {
      margin: '0.5rem 0rem',
      paddingRight: 0,
      borderRadius: '20px 0px 0px 20px',
    },
    collapse: {
      maxHeight: 200,
      overflow: 'auto',
    },
    indicator: {
      width: '8px',
      height: 40,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '5px 0px 0px 5px',
      marginRight: -15,
    },
    mainIndicator: {
      width: '8px',
      height: '100%',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '20px 0px 0px 20px',
      position: 'absolute',
      right: '-10px',
    },
    drawerButton: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      zIndex: 1200,
      transition: 'transform 1s ease',
    },
    moved: {
      transform: 'translateX(-75px)',
    },
    icon: {
      background: theme.palette.primary.main,
      '&:hover': {
        background: darken(theme.palette.primary.main, 0.2),
      },
      width: 40,
      height: 40,
      color: '#fff',
    },
    item: {
      cursor: 'pointer',
      '&:hover': {
        background: darken(theme.palette.primary.main, 0.2),
      },
    },
  };
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  marginTop: theme.spacing(2),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#1F2937',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: '#1F2937',
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: '80px',
  [theme.breakpoints.up('sm')]: {
    // width: `calc(${theme.spacing(8)} + 1px)`,
    width: '97px',
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const [openDrawer, setOpenDrawer] = useState(true);
  const { classes } = myStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openAccordion, setAccordion] = useState(false);
  const access_token = useSelector(setAccessTokenSelector);
  const dispatch = useDispatch();
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const activeFleet = useSelector(activeFleetSelector);

  const telematics_data_ls = JSON.parse(TELEMATICS_INFO);
  const telematics_data_redux = useSelector(telematicsDataSelector);
  const telematics_data = telematics_data_redux ?? telematics_data_ls;

  const location = useLocation();
  const [payload, setPayload] = useState({ fleet_name: '', location: '' });
  const fleetTracker = useSelector(fleetUpdatedSelector);
  const [openDrawer2, setOpenDrawer2] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newList = [
    {
      id: 'first-step',
      key: 'home',
      icon: <Home className={classes.sidbarIcon} />,
      name: t('tabs.home'),
      isDivider: true,
    },
    {
      id: 'overview',
      key: 'overview',
      icon: <Layers className={classes.sidbarIcon} />,
      name: t('tabs.overview'),
      isNested: true,
    },
    {
      id: 'second-step',
      key: 'telematics-data/provider-integration',
      icon: <BackupOutlinedIcon className={classes.sidbarIcon} />,
      name: t('common.integrationInfo'),
    },
    {
      id: 'third-step',
      key: 'fleets-data',
      icon: <LocalShippingOutlinedIcon className={classes.sidbarIcon} />,
      name: t('tabs.fleetsData'),
      isNested: fleets?.length ? true : false,
    },
    {
      id: 'fourth-step',
      key: 'tire-inventory',
      icon: <Storage className={classes.sidbarIcon} />,
      name: t('tabs.tireinventory'),
    },
    {
      id: 'fifth-step',
      key: 'reports',
      icon: <ReportsIcon className={classes.sidbarIcon} />,
      name: t('tabs.reports'),
    },

    {
      key: 'logout',
      icon: <Logout className={classes.sidbarIcon} />,
      name: t('tabs.logout'),
    },
  ];

  const list_wihout_telematics = newList.filter(
    item => item.id !== 'second-step'
  );

  const sidebar_list = fleets?.length
    ? telematics_data !== null
      ? telematics_data.length > 0
        ? list_wihout_telematics
        : newList
      : newList
    : newList.filter(
        item => item.key !== 'reports' && item.key !== 'tire-inventory'
      );

  const allListItems = openDrawer
    ? sidebar_list
    : sidebar_list.filter(item => item.key !== 'logout');

  const [openModal, setOpenModal] = useState(false);

  const handleOptionSelection = item => {
    localStorage.setItem('overviewSelection', item);
    navigate(`/overview/${item}`);
  };
  const handleFleetSelection = (fleet, item) => {
    dispatch(setActiveFleet(fleet));
    localStorage.setItem('selectedFilters', JSON.stringify([]));
    localStorage.setItem('filterParameters', JSON.stringify({}));
    navigate(`/${item.key}/${fleet.fleet_id}`);
  };

  useEffect(() => {
    if (fleets && fleets.length) {
      const fleetId = location.pathname.split('/')[2];
      const found = fleets.find(fleet => fleet.fleet_id === fleetId);
      if (found) {
        dispatch(setActiveFleet(found));
      } else dispatch(setActiveFleet(fleets[0]));
    }
    /*eslint-disable-next-line*/
  }, [fleets]);

  const drawerControlHandler = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(
    function collapseNestIfDrawerClosed() {
      if (!openDrawer || !openDrawer2) {
        setAccordion(false);
        setOpen2ndAccordion(false);
      } else if (openDrawer2) {
        setAccordion(true);
        setOpen2ndAccordion(true);
      }
    },
    [openDrawer, openDrawer2]
  );
  let windowLocation = window.location.pathname;

  useEffect(() => {
    if (!window.location.pathname.includes('fleets-data')) {
      setAccordion(false);
    }
  }, [windowLocation]);

  const handleClose = () => {
    setOpenModal(false);
    let item = { fleet_name: '', location: '' };
    setPayload(item);
  };

  useEffect(() => {
    const handleMouseMove = event => {
      const cursorX = event.clientX;
      if (cursorX >= 325) {
        setOpenDrawer2(false);
      }
    };

    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const createFleet = async () => {
    setIsSubmitting(true);
    try {
      const res = await fleetCsvUpload(payload, access_token);
      if (res.status === 200) {
        dispatch(
          setNotification(t('common.fleetCreateSuccess'), 'success', true)
        );
        dispatch(fetchFleets());
        dispatch(setChangeInFleet(!fleetTracker));
        handleClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getPath2 = item => {
    let x = `/${item.key}`;
    if (fleets?.length && item.isNested && item.key === 'fleets-data') {
      x = `/${item.key}/${activeFleet?.fleet_id}`;
    }

    return x;
  };
  const getPath = item => {
    const selection = localStorage.getItem('overviewSelection');
    let x = `/${item.key}`;
    if (fleets?.length && item.isNested && item.key === 'fleets-data') {
      x = `/${item.key}/${activeFleet?.fleet_id}`;
    } else if (item.isNested && item.key !== 'fleets-data') {
      x = `/${item.key}/${selection}`;
    }

    return x;
  };
  const str = location.pathname.split('/').reverse()[0];
  const [open2ndAccordion, setOpen2ndAccordion] = useState(false);
  const itemButtonClickHandler = item => {
    setAccordion(
      () => item.isNested && item.key === 'fleets-data' && !openAccordion
    );
    setOpen2ndAccordion(
      () => item.isNested && item.key === 'overview' && !open2ndAccordion
    );
  };

  const icon = item => {
    if (item.key === 'fleets-data') {
      return openAccordion && fleets?.length > 0 ? (
        <ExpandLess />
      ) : (
        fleets?.length > 0 && <ExpandMore />
      );
    } else {
      return open2ndAccordion ? <ExpandLess /> : <ExpandMore />;
    }
  };
  function renderCollapsedItems(item) {
    if (item.key === 'overview') {
      return (
        <Collapse
          in={open2ndAccordion || (openDrawer2 && item.isNested)}
          timeout='auto'
          unmountOnExit
          className={classes.collapse}
        >
          <List component='div' disablePadding>
            {[
              { id: 'vehicle', title: t('common.vehicle') },
              { id: 'tire', title: t('common.tire') },
            ].map(option => (
              <ListItem key={option.id} className={classes.nested}>
                <ListItemText
                  primary={option.title}
                  disableTypography
                  style={{
                    color:
                      str === option.id
                        ? theme.palette.primary.main
                        : '#707070',
                    fontSize: 20,
                    marginLeft: '3rem',
                  }}
                  onClick={() => handleOptionSelection(option.id)}
                />
                {option.id === str && <div className={classes.indicator} />}
              </ListItem>
            ))}
          </List>
        </Collapse>
      );
    }
    return (
      item.isNested &&
      item.key === 'fleets-data' && (
        <Collapse
          in={
            (openAccordion && location.pathname.includes(getPath(item))) ||
            (openDrawer2 && item.isNested)
          }
          timeout='auto'
          unmountOnExit
          className={classes.collapse}
        >
          <List component='div' disablePadding data-hook='fleets-list'>
            <ListItem>
              <Button
                startIcon={<Add />}
                variant='text'
                className={classes.addFleetBtn}
                onClick={() => {
                  setOpenModal(true);
                  setOpenDrawer2(false);
                }}
                data-hook='add-fleet-button'
              >
                {t('common.addFleet')}
              </Button>
            </ListItem>
            {fleets?.map((fleet, i) => (
              <ListItem
                className={classes.nested}
                onClick={() => {
                  handleFleetSelection(fleet, item);
                }}
                key={i}
              >
                <ListItemText
                  primary={fleet.fleet_name}
                  disableTypography
                  style={{
                    color:
                      activeFleet?.fleet_id === fleet.fleet_id
                        ? theme.palette.primary.main
                        : '#707070',
                    fontSize: 20,
                    marginLeft: '3rem',
                  }}
                />
                {fleet.fleet_id === location.pathname.split('/')[2] && (
                  <div className={classes.indicator} />
                )}
              </ListItem>
            ))}
          </List>
        </Collapse>
      )
    );
  }

  function renderLastListItem() {
    return (
      <ListItem
        sx={{
          position: 'absolute',
          bottom: 0,
          marginLeft: '24px',
          display: 'block',
        }}
        className={styles.items}
      >
        <Box display={'block'} className={styles.listItems}>
          <NavLink
            to={`/logout`}
            className={({ isActive }) =>
              isActive ? classes.active : classes.link
            }
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: 'center',
                px: 1,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 'auto',
                  justifyContent: 'center',
                  color: 'inherit',
                }}
              >
                {sidebar_list[sidebar_list.length - 1].icon}
              </ListItemIcon>
              <ListItemText
                primary={sidebar_list[sidebar_list.length - 1].name}
                sx={{ display: 'none' }}
              />
            </ListItemButton>
          </NavLink>
        </Box>
      </ListItem>
    );
  }

  function renderAllListItems(thisOpen, setThisOpen) {
    return allListItems.map((item, index) => (
      <ListItem
        key={index}
        disablePadding
        sx={{ display: 'block', marginLeft: '24px' }}
        className={styles.items}
      >
        <Box className={styles.listItems}>
          <NavLink
            onClick={() => {
              if (item.isNested) {
                setThisOpen(true);
              }
            }}
            to={getPath(item)}
            className={({ isActive }) =>
              isActive ? classes.active : classes.link
            }
            data-hook={item.key}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: thisOpen ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {
                // setAccordion(() => item.isNested && !openAccordion);
                itemButtonClickHandler(item);
              }}
              onMouseOver={e => {
                if (item.key === 'fleets-data' && !openDrawer) {
                  setOpenDrawer2(true);
                  setAccordion(true);
                }
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: thisOpen ? 3 : 'auto',
                  justifyContent: 'center',
                  color: location.pathname.includes(getPath2(item))
                    ? theme.palette.primary.main
                    : '#757575',
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.name}
                sx={{
                  opacity: thisOpen ? 1 : 0,
                  color: location.pathname.includes(getPath2(item))
                    ? theme.palette.primary.main
                    : '#757575',
                }}
              />
              {thisOpen && (
                <Box data-hook={`fleets_${index}`}>
                  {item.isNested && <>{icon(item)}</>}
                </Box>
              )}
              {location.pathname.includes(`/${item.key}`) && !item.isNested && (
                <div className={classes.mainIndicator} />
              )}
            </ListItemButton>
          </NavLink>
          {renderCollapsedItems(item)}
        </Box>
      </ListItem>
    ));
  }

  if (openDrawer2) {
    return (
      <div className={classes.root}>
        <CssBaseline />

        <Drawer
          sx={{
            position: 'fixed',
            zIndex: 9999,
          }}
          anchor='left'
          open={openDrawer2}
          variant='permanent'
        >
          <DrawerHeader>
            {openDrawer2 && (
              <Box className={classes.logo} onClick={() => navigate('/home')}>
                <img
                  src={
                    process.env.REACT_APP_THEME === 'bestDrive'
                      ? theme.logo_alt
                      : theme.logo
                  }
                  width={174}
                  alt='logo'
                />
              </Box>
            )}
          </DrawerHeader>
          <Divider />
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
              height: '100vh',
            }}
            data-hook='sidebar-list'
          >
            {renderAllListItems(openDrawer2, setOpenDrawer2)}
          </List>
          <Divider />
        </Drawer>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer variant='permanent' open={openDrawer}>
        <DrawerHeader>
          {openDrawer && (
            <Box className={classes.logo} onClick={() => navigate('/home')}>
              <img
                src={
                  process.env.REACT_APP_THEME === 'bestDrive'
                    ? theme.logo_alt
                    : theme.logo
                }
                width={174}
                alt='logo'
              />
            </Box>
          )}
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            marginTop: !openDrawer && '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            height: !openDrawer ? '100vh' : 'fit-content',
          }}
          data-hook='sidebar-list'
        >
          {renderAllListItems(openDrawer, setOpenDrawer)}
          {!openDrawer && renderLastListItem()}
        </List>
        <Divider />
        {openDrawer && (
          <Box>
            <DrawerFooter />
          </Box>
        )}
      </Drawer>
      <div
        className={`${classes.drawerButton} ${
          !openDrawer ? classes.moved : ''
        }`}
        style={{
          position: openDrawer ? 'absolute' : 'relative',
          left: openDrawer ? drawerWidth - 30 : 15,
          top: 10,
          // width: openDrawer ? drawerWidth : 0,
          width: 0,
          marginLeft: !openDrawer && -15,
        }}
      >
        <IconButton onClick={drawerControlHandler} className={classes.icon}>
          {!openDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>

      {openModal && (
        <AddFleet
          open={openModal}
          closeHandler={handleClose}
          payload={payload}
          setPayload={setPayload}
          submit={createFleet}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
      )}
    </div>
  );
};

export default MotionWrap(React.memo(Sidebar), { x: -20 }, { x: 0 }, { x: 50 });

import React, { useState } from 'react';
import { Chip, Collapse, Divider, Typography } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import moment from 'moment';

const HiddenContent = props => {
  const { classes, note } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      {note[1]?.length > 1 && (
        <div
          className={classes.expand}
          onClick={() => {
            setOpen(!open);
          }}
        >
          {!open ? <ExpandMore /> : <ExpandLess />}
        </div>
      )}
      <Collapse in={open} timeout='auto' unmountOnExit>
        {note[1].slice(1, note[1].length).map((_note, i) => (
          <div key={i}>
            <div className={classes.top}>
              <Typography className={classes.title} variant='h6'>
                {_note.title}
              </Typography>
              <Chip
                label={_note.type}
                style={{
                  backgroundColor: _note.type === 'Feature' ? '#e0f7ed' : '',
                  color: _note.type === 'Feature' ? '#006338' : 'inherit',
                  borderRadius: '3px',
                }}
              />
            </div>
            <div className={classes.desc}>
              <p>{_note.details}</p>
            </div>
            <span className={classes.foot}>
              {moment(_note.date).format('dddd Do MMM, YYYY')}
            </span>
            <Divider className={classes.divider} />
          </div>
        ))}
      </Collapse>
    </>
  );
};

export default HiddenContent;

import React from 'react';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';

import { VEHICLE } from 'utils/string_utils';
import CustomTextField from '../custom/CustomTextField';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      maxWidth: 500,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    btn: {
      margin: '28px 0px',
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
    },
    innerSpan: {
      color: theme.palette.primary.main,
    },
  };
});

const DeleteResource = ({
  open,
  t,
  handleClose,
  handleSubmit,
  setResource,
  resourceName,
  resource,
  action,
  isSubmitting,
}) => {
  const { classes } = useStyles();
  const isVehicleDeleteAction = action === 'vehicleDelete';
  const btnDisabledCondition =
    isSubmitting ||
    resourceName ===
      (isVehicleDeleteAction ? resource?.name : resource?.fleet_name)
      ? false
      : true;

  const labelToShow = {
    truck: t('deleteResource.truckName'),
    trailer: t('deleteResource.trailerName'),
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      data-hook='delete-modal'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <CardContent>
            <IconButton
              style={{ float: 'right' }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              variant='h5'
              align='center'
              data-hook='delete-vehicle-title'
            >
              {isVehicleDeleteAction
                ? resource.vehicle_type === VEHICLE.vehicle_type.TRUCK
                  ? t('vehiclePage.deleteModal.deleteTruck')
                  : t('vehiclePage.deleteModal.deleteTrailer')
                : t('fleetsSection.deleteModal.title')}
            </Typography>
            <form onSubmit={handleSubmit}>
              <CustomTextField
                color={'primary'}
                margin={'normal'}
                label={
                  isVehicleDeleteAction
                    ? labelToShow[resource?.vehicle_type]
                    : t('fleetsSection.deleteModal.fieldLabel')
                }
                required
                data-hook='delete-confirm-input'
                type={'text'}
                value={resourceName}
                onChange={e => setResource(e.target.value)}
                fullWidth
              />
              <Typography variant='subtitle1' align='left'>
                {isVehicleDeleteAction
                  ? resource.vehicle_type === VEHICLE.vehicle_type.TRUCK
                    ? t('vehiclePage.deleteModal.truckNamePrompt')
                    : t('vehiclePage.deleteModal.trailerNamePrompt')
                  : t('fleetsSection.deleteModal.fleetNamePrompt')}
                <span style={{ fontWeight: 900 }}>
                  {'<'}
                  <span className={classes.innerSpan}>
                    {isVehicleDeleteAction
                      ? resource?.name
                      : resource?.fleet_name}
                  </span>
                  {'>'}
                </span>
              </Typography>
              <Box width='100%' mx='auto'>
                <Button
                  color={'secondary'}
                  type={'submit'}
                  variant={'contained'}
                  className={classes.btn}
                  fullWidth
                  disabled={btnDisabledCondition}
                  data-hook='delete-vehicle-button'
                >
                  <Typography variant='h6' align='center'>
                    {isSubmitting ? (
                      <CircularProgress size={30} color='inherit' />
                    ) : (
                      t('common.actions.delete')
                    )}
                  </Typography>
                </Button>
              </Box>
            </form>
            <Box my='1rem'>
              <Typography
                variant='caption'
                gutterBottom
                align='center'
                style={{ fontStyle: 'italic' }}
              >
                {isVehicleDeleteAction
                  ? t('deleteUserModal.deleteVehicleWarning')
                  : t('deleteUserModal.deleteFleetWarning')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Fade>
    </Modal>
  );
};

export default DeleteResource;

export const axle_wise_config = {
  '4x2': {
    steer: 1,
    drive: 1,
  },
  '4x4': {
    steer: 1,
    drive: 1,
  },
  '6x2': {
    steer: 1,
    drive: 2,
  },
  '6x4': {
    steer: 1,
    drive: 2,
  },
  '6x6': {
    steer: 1,
    drive: 2,
  },
  '8x2': {
    steer: 1,
    drive: 3,
  },
  '8x4SS': {
    steer: 1,
    drive: 3,
  },
  '8x4': {
    steer: 2,
    drive: 2,
  },
  '8x4TS': {
    steer: 2,
    drive: 2,
  },
  '10x4TS': {
    steer: 2,
    drive: 3,
  },
  '1-axle': {
    axle: 2,
  },
  '2-axle': {
    axle_1: 2,
    axle_2: 2,
  },
  '3-axle': {
    axle_1: 2,
    axle_2: 2,
    axle_3: 2,
  },
  '4-axle': {
    axle_1: 2,
    axle_2: 2,
    axle_3: 2,
    axle_4: 2,
  },
  '5-axle': {
    axle_1: 2,
    axle_2: 2,
    axle_3: 2,
    axle_4: 2,
    axle_5: 2,
  },
  '2-axle-twin': {
    axle_1: 4,
    axle_2: 4,
  },
};

export const axl_wise = {
  '4x2': {
    steer_0: 2,
    drive_0: 4,
  },
  '4x4': {
    steer_0: 2,
    drive_0: 4,
  },
  '6x2': {
    steer_0: 2,
    drive_0: 4,
    drive_1: 2,
  },
  '6x4': {
    steer_0: 2,
    drive_0: 4,
    drive_1: 4,
  },
  '6x6': {
    steer_0: 2,
    drive_0: 4,
    drive_1: 4,
  },
  '8x2': {
    steer_0: 2,
    drive_0: 2,
    drive_1: 4,
    drive_2: 2,
  },
  '8x4': {
    steer_0: 2,
    drive_0: 2,
    drive_1: 4,
    steer_1: 2,
  },
  '8x4SS': {
    steer_0: 2,
    drive_0: 2,
    drive_1: 4,
    drive_2: 4,
  },
  '8x4TS': {
    steer_0: 2,
    steer_1: 2,
    drive_0: 4,
    drive_1: 4,
  },
  '10x4TS': {
    steer_0: 2,
    steer_1: 2,
    drive_0: 2,
    drive_1: 4,
    drive_2: 4,
  },
  '1-axle': {
    axle_0: 2,
  },
  '2-axle': {
    axle_0: 2,
    axle_1: 2,
  },
  '3-axle': {
    axle_0: 2,
    axle_1: 2,
    axle_2: 2,
  },
  '4-axle': {
    axle_0: 2,
    axle_1: 2,
    axle_2: 2,
    axle_3: 2,
  },
  '5-axle': {
    axle_0: 2,
    axle_1: 2,
    axle_2: 2,
    axle_3: 2,
    axle_4: 2,
  },
  '2-axle-twin': {
    axle_0: 4,
    axle_1: 4,
  },
  '1-axle-twin': {
    axle_0: 4,
  },
};

export const vehicle_resource = {
  truck: {
    axle_types: [
      '4x2',
      '4x4',
      '6x2',
      '6x4',
      '6x6',
      '8x2',
      '8x4SS',
      '8x4TS',
      '10x4TS',
    ],
  },
  bus: {
    axle_types: ['4x2', '6x2', '6x4', '8x4'],
  },
  trailer: {
    axle_types: [
      '1-axle',
      '2-axle',
      '3-axle',
      '4-axle',
      '5-axle',
      '2-axle-twin',
      '1-axle-twin',
    ],
  },
};

export const tireLookup = {
  '6x2': {
    '1SL': {
      index: 0,
      side: 'left',
      axle: 'steer_0',
    },
    '1SR': {
      index: 0,
      side: 'right',
      axle: 'steer_0',
    },
    '2SL': {
      index: 0,
      side: 'left',
      axle: 'steer_1',
    },
    '2SR': {
      index: 0,
      side: 'right',
      axle: 'steer_1',
    },
    '1DL': {
      index: 0,
      side: 'left',
      axle: 'drive_0',
    },
    '1DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_0',
    },
    '1DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_0',
    },
    '1DR': {
      index: 1,
      side: 'right',
      axle: 'drive_0',
    },
    '2DL': {
      index: 0,
      side: 'left',
      axle: 'drive_1',
    },
    '2DLI': {
      index: 0,
      side: 'left',
      axle: 'drive_1',
    },
    '2DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_1',
    },
    '2DR': {
      index: 0,
      side: 'right',
      axle: 'drive_1',
    },
  },
  '8x2': {
    '1SL': {
      index: 0,
      side: 'left',
      axle: 'steer_0',
    },
    '1SR': {
      index: 0,
      side: 'right',
      axle: 'steer_0',
    },
    '2SL': {
      index: 0,
      side: 'left',
      axle: 'steer_1',
    },
    '2SR': {
      index: 0,
      side: 'right',
      axle: 'steer_1',
    },
    '1DL': {
      index: 0,
      side: 'left',
      axle: 'drive_0',
    },
    '1DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_0',
    },
    '1DRI': {
      index: 1,
      side: 'right',
      axle: 'drive_0',
    },
    '1DR': {
      index: 0,
      side: 'right',
      axle: 'drive_0',
    },
    '2DL': {
      index: 0,
      side: 'left',
      axle: 'drive_1',
    },
    '2DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_1',
    },
    '2DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_1',
    },
    '2DR': {
      index: 1,
      side: 'right',
      axle: 'drive_1',
    },
    '3DL': {
      index: 0,
      side: 'left',
      axle: 'drive_2',
    },
    '3DLI': {
      index: 0,
      side: 'left',
      axle: 'drive_2',
    },
    '3DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_2',
    },
    '3DR': {
      index: 0,
      side: 'right',
      axle: 'drive_2',
    },
  },
  X: {
    '1SL': {
      index: 0,
      side: 'left',
      axle: 'steer_0',
    },
    '1SR': {
      index: 0,
      side: 'right',
      axle: 'steer_0',
    },
    '2SL': {
      index: 0,
      side: 'left',
      axle: 'steer_1',
    },
    '2SR': {
      index: 0,
      side: 'right',
      axle: 'steer_1',
    },
    '1DL': {
      index: 0,
      side: 'left',
      axle: 'drive_0',
    },
    '1DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_0',
    },
    '1DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_0',
    },
    '1DR': {
      index: 0,
      side: 'right',
      axle: 'drive_0',
    },
    '2DL': {
      index: 0,
      side: 'left',
      axle: 'drive_1',
    },
    '2DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_1',
    },
    '2DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_1',
    },
    '2DR': {
      index: 1,
      side: 'right',
      axle: 'drive_1',
    },
    '3DL': {
      index: 0,
      side: 'left',
      axle: 'drive_2',
    },
    '3DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_2',
    },
    '3DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_2',
    },
    '3DR': {
      index: 1,
      side: 'right',
      axle: 'drive_2',
    },
  },
  twin: {
    '1TL': {
      index: 0,
      side: 'left',
      axle: 'axle_0',
    },
    '1TLI': {
      index: 1,
      side: 'left',
      axle: 'axle_0',
    },
    '1TR': {
      index: 1,
      side: 'right',
      axle: 'axle_0',
    },
    '1TRI': {
      index: 0,
      side: 'right',
      axle: 'axle_0',
    },

    '2TL': {
      index: 0,
      side: 'left',
      axle: 'axle_1',
    },
    '2TLI': {
      index: 1,
      side: 'left',
      axle: 'axle_1',
    },
    '2TR': {
      index: 1,
      side: 'right',
      axle: 'axle_1',
    },
    '2TRI': {
      index: 0,
      side: 'right',
      axle: 'axle_1',
    },
  },
  others: {
    '1SL': {
      index: 0,
      side: 'left',
      axle: 'steer_0',
    },
    '1SR': {
      index: 0,
      side: 'right',
      axle: 'steer_0',
    },
    '2SL': {
      index: 0,
      side: 'left',
      axle: 'steer_1',
    },
    '2SR': {
      index: 0,
      side: 'right',
      axle: 'steer_1',
    },
    '1DL': {
      index: 0,
      side: 'left',
      axle: 'drive_0',
    },
    '1DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_0',
    },
    '1DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_0',
    },
    '1DR': {
      index: 1,
      side: 'right',
      axle: 'drive_0',
    },
    '2DL': {
      index: 0,
      side: 'left',
      axle: 'drive_1',
    },
    '2DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_1',
    },
    '2DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_1',
    },
    '2DR': {
      index: 1,
      side: 'right',
      axle: 'drive_1',
    },
    '3DL': {
      index: 0,
      side: 'left',
      axle: 'drive_2',
    },
    '3DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_2',
    },
    '3DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_2',
    },
    '3DR': {
      index: 1,
      side: 'right',
      axle: 'drive_2',
    },
    '1TL': {
      index: 0,
      side: 'left',
      axle: 'axle_0',
    },
    '1TR': {
      index: 0,
      side: 'right',
      axle: 'axle_0',
    },
    '2TL': {
      index: 0,
      side: 'left',
      axle: 'axle_1',
    },
    '2TR': {
      index: 0,
      side: 'right',
      axle: 'axle_1',
    },
    '3TR': {
      index: 0,
      side: 'right',
      axle: 'axle_2',
    },
    '3TL': {
      index: 0,
      side: 'left',
      axle: 'axle_2',
    },
    '4TL': {
      index: 0,
      side: 'left',
      axle: 'axle_3',
    },
    '4TR': {
      index: 0,
      side: 'right',
      axle: 'axle_3',
    },
    '5TR': {
      index: 0,
      side: 'right',
      axle: 'axle_4',
    },
    '5TL': {
      index: 0,
      side: 'left',
      axle: 'axle_4',
    },
  },
  '8x4': {
    '1SL': {
      index: 0,
      side: 'left',
      axle: 'steer_0',
    },
    '1SR': {
      index: 0,
      side: 'right',
      axle: 'steer_0',
    },
    '3SL': {
      index: 0,
      side: 'left',
      axle: 'steer_1',
    },
    '3SR': {
      index: 0,
      side: 'right',
      axle: 'steer_1',
    },
    '1DL': {
      index: 0,
      side: 'left',
      axle: 'drive_0',
    },
    '1DR': {
      index: 0,
      side: 'right',
      axle: 'drive_0',
    },
    '2DL': {
      index: 0,
      side: 'left',
      axle: 'drive_1',
    },
    '2DLI': {
      index: 1,
      side: 'left',
      axle: 'drive_1',
    },
    '2DRI': {
      index: 0,
      side: 'right',
      axle: 'drive_1',
    },
    '2DR': {
      index: 1,
      side: 'right',
      axle: 'drive_1',
    },
  },
};

export const stringLookup = {
  '8x2': {
    steer_0: {
      left: {
        0: '1SL',
        1: null,
        positionId: 0,
      },
      right: {
        0: '1SR',
        1: null,
        positionId: 1,
      },
    },

    drive_0: {
      left: {
        0: '1DL',
        1: null,
      },
      right: {
        0: '1DR',
        1: null,
      },
    },
    drive_1: {
      left: {
        0: '2DL',
        1: '2DLI',
      },
      right: {
        0: '2DRI',
        1: '2DR',
      },
    },
    drive_2: {
      left: {
        0: '3DL',
        1: null,
      },
      right: {
        0: '3DR',
        1: null,
      },
    },
  },
  '6x2': {
    steer_0: {
      left: {
        0: '1SL',
        1: null,
        positionId: 0,
      },
      right: {
        0: '1SR',
        1: null,
        positionId: 1,
      },
    },
    drive_0: {
      left: {
        0: '1DL',
        1: '1DLI',
      },
      right: {
        0: '1DRI',
        1: '1DR',
      },
    },
    drive_1: {
      left: {
        0: '2DL',
        1: null,
      },
      right: {
        0: '2DR',
        1: null,
      },
    },
  },
  X: {
    steer_0: {
      left: {
        0: '1SL',
        1: null,
        positionId: 0,
      },
      right: {
        0: '1SR',
        1: null,
        positionId: 1,
      },
    },
    steer_1: {
      left: {
        0: '2SL',
        1: null,
        positionId: 0,
      },
      right: {
        0: '2SR',
        1: null,
        positionId: 1,
      },
    },

    drive_0: {
      left: {
        0: '1DL',
        1: null,
      },
      right: {
        0: '1DR',
        1: null,
      },
    },
    drive_1: {
      left: {
        0: '2DL',
        1: '2DLI',
      },
      right: {
        0: '2DRI',
        1: '2DR',
      },
    },
    drive_2: {
      left: {
        0: '3DL',
        1: '3DLI',
      },
      right: {
        0: '3DRI',
        1: '3DR',
      },
    },
  },
  others: {
    steer_0: {
      left: {
        0: '1SL',
        1: null,
        positionId: 0,
      },
      right: {
        0: '1SR',
        1: null,
        positionId: 1,
      },
    },
    steer_1: {
      left: {
        0: '2SL',
        1: null,
        positionId: 0,
      },
      right: {
        0: '2SR',
        1: null,
        positionId: 1,
      },
    },

    drive_0: {
      left: {
        0: '1DL',
        1: '1DLI',
      },
      right: {
        0: '1DRI',
        1: '1DR',
      },
    },
    drive_1: {
      left: {
        0: '2DL',
        1: '2DLI',
      },
      right: {
        0: '2DRI',
        1: '2DR',
      },
    },
    axle_0: {
      left: {
        0: '1TL',
        1: null,
        positionId: 0,
      },
      right: {
        0: '1TR',
        1: null,
        positionId: 1,
      },
    },
    axle_1: {
      left: {
        0: '2TL',
        1: null,
        positionId: 2,
      },
      right: {
        0: '2TR',
        1: null,
        positionId: 3,
      },
    },
    axle_2: {
      left: {
        0: '3TL',
        1: null,
        positionId: 4,
      },
      right: {
        0: '3TR',
        1: null,
        positionId: 5,
      },
    },
    axle_3: {
      left: {
        0: '4TL',
        1: null,
        positionId: 6,
      },
      right: {
        0: '4TR',
        1: null,
        positionId: 7,
      },
    },
    axle_4: {
      left: {
        0: '5TL',
        1: null,
        positionId: 8,
      },
      right: {
        0: '5TR',
        1: null,
        positionId: 9,
      },
    },
  },
  twin: {
    axle_0: {
      left: {
        0: '1TL',
        1: '1TLI',
        positionId: 0,
      },
      right: {
        0: '1TRI',
        1: '1TR',
        positionId: 1,
      },
    },
    axle_1: {
      left: {
        0: '2TL',
        1: '2TLI',
        positionId: 0,
      },
      right: {
        0: '2TRI',
        1: '2TR',
        positionId: 1,
      },
    },
  },
  '8x4': {
    steer_0: {
      left: {
        0: '1SL',
        1: null,
        positionId: 0,
      },
      right: {
        0: '1SR',
        1: null,
        positionId: 1,
      },
    },
    drive_0: {
      left: {
        0: '1DL',
        1: '1DLI',
      },
      right: {
        0: '1DRI',
        1: '1DR',
      },
    },
    drive_1: {
      left: {
        0: '2DL',
        1: null,
      },
      right: {
        0: '2DR',
        1: null,
      },
    },
    steer_1: {
      left: {
        0: '3SL',
        1: null,
        positionId: 0,
      },
      right: {
        0: '3SR',
        1: null,
        positionId: 1,
      },
    },
  },
};

export const internalAxleName = {
  '8x4SS': 'X',
  '8x4': '8x4',
  '10x4TS': 'X',
  '6x2': '6x2',
  '8x2': '8x2',
  '4x2': 'others',
  '4x4': 'others',
  '6x4': 'others',
  '6x6': 'others',
  '8x4TS': 'others',
  '1-axle': 'others',
  '2-axle': 'others',
  '3-axle': 'others',
  '4-axle': 'others',
  '5-axle': 'others',
  '2-axle-twin': 'twin',
  '1-axle-twin': 'twin',
};

export const positionNames = {
  '8x4SS': [
    '1SL',
    '1SR',
    '1DL',
    '1DR',
    '2DL',
    '2DLI',
    '2DRI',
    '2DR',
    '3DL',
    '3DRI',
    '3DLI',
    '3DR',
  ],
  '8x4': [
    '1SL',
    '1SR',
    '1DL',
    '1DR',
    '2DL',
    '2DLI',
    '2DRI',
    '2DR',
    '3SL',
    '3SR',
  ],
  '10x4TS': [
    '1SL',
    '1SR',
    '2SL',
    '2SR',
    '1DL',
    '1DR',
    '2DL',
    '2DLI',
    '2DRI',
    '2DR',
    '3DL',
    '3DRI',
    '3DLI',
    '3DR',
  ],
  '6x2': ['1SL', '1SR', '1DL', '1DLI', '2DL', '1DRI', '1DR', '2DR'],
  '8x2': [
    '1SL',
    '1SR',
    '1DL',
    '1DR',
    '2DL',
    '2DR',
    '2DRI',
    '2DLI',
    '3DL',
    '3DR',
  ],
  '4x2': ['1SL', '1SR', '1DL', '1DLI', '1DR', '1DRI'],
  '4x4': ['1SL', '1SR', '1DL', '1DLI', '1DR', '1DRI'],
  '6x4': [
    '1SL',
    '1SR',
    '1DL',
    '1DLI',
    '1DR',
    '1DRI',
    '2DL',
    '2DLI',
    '2DR',
    '2DRI',
  ],
  '6x6': [
    '1SL',
    '1SR',
    '1DL',
    '1DLI',
    '1DR',
    '1DRI',
    '2DL',
    '2DLI',
    '2DR',
    '2DRI',
  ],
  '8x4TS': [
    '1SL',
    '1SR',
    '1DL',
    '1DLI',
    '1DR',
    '1DRI',
    '2DL',
    '2DLI',
    '2DR',
    '2DRI',
    '2SL',
    '2SR',
  ],
  '1-axle': ['1TL', '1TR'],
  '2-axle': ['1TL', '1TR', '2TL', '2TR'],
  '3-axle': ['1TL', '1TR', '2TL', '2TR', '3TL', '3TR'],
  '4-axle': ['1TL', '1TR', '2TL', '2TR', '3TL', '3TR', '4TL', '4TR'],
  '5-axle': [
    '1TL',
    '1TR',
    '2TL',
    '2TR',
    '3TL',
    '3TR',
    '4TL',
    '4TR',
    '5TL',
    '5TR',
  ],
  '2-axle-twin': ['1TL', '1TR', '2TL', '2TR', '1TLI', '1TRI', '2TLI', '2TRI'],
  '1-axle-twin': ['1TL', '1TR', '1TLI', '1TRI'],
};

export const truckAxlePressurePositions = {
  '4x2': ['1S', '1D'],
  '4x4': ['1S', '1D'],
  '6x2': ['1S', '1D', '2D'],
  '6x4': ['1S', '1D', '2D'],
  '6x6': ['1S', '1D', '2D'],
  '8x2': ['1S', '1D', '2D', '3D'],
  '8x4SS': ['1S', '1D', '2D', '3D'],
  '8x4TS': ['1S', '2S', '1D', '2D', '3D'],
  '10x4TS': ['1S', '2S', '1D', '2D', '3D'],
};

export const busAxlePressurePositions = {
  '4x2': ['1S', '1D'],
  '6x2': ['1S', '1D', '2D'],
  '6x4': ['1S', '1D', '2D'],
  '8x4': ['1S', '1D', '2D', '3S'],
};

export const trailerAxlePressurePositions = {
  '1-axle': ['1T'],
  '2-axle': ['1T', '2T'],
  '3-axle': ['1T', '2T', '3T'],
  '4-axle': ['1T', '2T', '3T', '4T'],
  '5-axle': ['1T', '2T', '3T', '4T', '5T'],
  '1-axle-twin': ['1T'],
  '2-axle-twin': ['1T', '2T'],
};

export const optimalPressureByAxleType = {
  '4x2': ['1S', '1D'],
  '4x4': ['1S', '1D'],
  '6x2': ['1S', '1D', '2D'],
  '6x4': ['1S', '1D', '2D'],
  '6x6': ['1S', '1D', '2D'],
  '8x2': ['1S', '1D', '2D', '3D'],
  '8x4SS': ['1S', '1D', '2D', '3D'],
  '8x4TS': ['1S', '2S', '1D', '2D', '3D'],
  '10x4TS': ['1S', '2S', '1D', '2D', '3D'],
  '8x4': ['1S', '1D', '2D', '3S'],
  '1-axle': ['1T'],
  '2-axle': ['1T', '2T'],
  '3-axle': ['1T', '2T', '3T'],
  '4-axle': ['1T', '2T', '3T', '4T'],
  '5-axle': ['1T', '2T', '3T', '4T', '5T'],
  '1-axle-twin': ['1T'],
  '2-axle-twin': ['1T', '2T'],
};

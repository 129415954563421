import React, { useEffect, useState } from 'react';
import { deleteFleet } from 'services/fleet_service';
import { setNotification } from 'redux/notifications/ducks';
import { useSelector, useDispatch } from 'react-redux';
import { setAccessTokenSelector } from 'redux/users/ducks';
import { useNavigate } from 'react-router-dom';
import {
  fleetUpdatedSelector,
  setChangeInFleet,
  updateFleetAfterDeletion,
} from 'redux/fleets/ducks';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Fade,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Modal,
  Paper,
  Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import { Search } from '@mui/icons-material';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    deletePaper: {
      width: 650,
      padding: '64px 98px 64px 98px',
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
    },
    group: {
      maxHeight: 320,
      overflow: 'auto',
    },
    btns: {
      flex: '30%',
      borderRadius: 24,
      textTransform: 'unset',
      width: 146,
      height: 40,
      fontSize: '16px',
      lineHeight: '16px',
    },
    fleet: {
      borderRadius: 4,
      border: '1.5px solid #6b7280',
      padding: '1px 12px 1px 12px',
    },
    deleteWarning: {
      lineHeight: '22px',
      fontSize: '16px',
      fontWeight: 400,
    },
    searchBox: {
      display: 'flex',
      alignItems: 'center',
      width: 454,
      border: '1px solid #D1D5DB',
      borderRadius: 8,
      height: 40,
    },
  };
});

const DeleteModal = ({
  open,
  setOpen,
  fleet,
  allFleets,
  handleChange,
  list,
  selectedFleets,
  closeHandler,
}) => {
  const dispatch = useDispatch();
  const access_token = useSelector(setAccessTokenSelector);
  const fleetTracker = useSelector(fleetUpdatedSelector);

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [stage, setStage] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState(
    list ? Object.keys(list) : []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  React.useEffect(() => {
    if (deleteSuccess) {
      dispatch(setChangeInFleet(!fleetTracker));
      const newFleets = allFleets.filter(
        item => item.fleet_id !== fleet?.fleet_id
      );
      if (newFleets.length === 0) {
        navigate(`/home`);
      } else navigate(`/fleets-data/${newFleets[0].fleet_id}`);
    }
    // eslint-disable-next-line
  }, [deleteSuccess]);

  useEffect(() => {
    if (list) {
      setFilteredData(Object.keys(list));
    }
  }, [list]);

  const handleSearch = e => {
    const input = e.target.value;
    setSearchInput(input);

    if (input.length >= 3) {
      const filtered = Object.keys(list).filter(key => {
        return key.toLowerCase().includes(input.toLowerCase());
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(Object.keys(list));
    }
  };

  const handleSubmit = async e => {
    setIsSubmitting(true);
    e.preventDefault();
    const fleetsToDelete = Object.values(selectedFleets);
    let fleetIds = {
      fleet_ids: fleetsToDelete,
    };

    const promises = deleteFleet(access_token, fleetIds);

    promises
      .then(() => {
        dispatch(
          setNotification('Fleet Successfully Deleted', 'success', true)
        );
        setDeleteSuccess(true);
        dispatch(updateFleetAfterDeletion(Object.values(selectedFleets)));
        handleClose();
      })
      .catch(error => {
        dispatch(
          setNotification('An error occured , Try Again', 'error', true)
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  const handleClose = () => {
    setStage(0);
    setFilteredData(Object.keys(list));
    setSearchInput('');
    closeHandler();
  };

  const renderControlledCheckbox = () => {
    if (list) {
      return filteredData.map((item, i) => {
        return (
          <FormControlLabel
            key={i}
            control={
              <Checkbox
                checked={list[item]}
                onChange={handleChange}
                name={item}
                color='primary'
                data-hook={`confirmation-check-box-${i}`}
              />
            }
            label={item}
          />
        );
      });
    }
  };

  const renderConfirmationCheck = () => {
    return Object.entries(selectedFleets).map((checked, j) => (
      <InformationBox key={j} name={checked[0]} fleet_id={checked[1]} />
    ));
  };

  const renderDeleteFleetForm = () => {
    if (stage === 0) {
      return (
        <>
          <Typography
            variant='h5'
            className={classes.title}
            align='center'
            data-hook='delete-fleet-modal-title'
          >
            Delete Fleet
          </Typography>
          <Typography
            variant='caption'
            className={classes.title}
            align='center'
          >
            Select Fleet(s)
          </Typography>

          <Box>
            <div className={classes.searchBox}>
              <IconButton aria-label='search'>
                <Search color='primary' />
              </IconButton>
              <InputBase
                placeholder='Search...'
                fullWidth
                inputProps={{ 'aria-label': 'search' }}
                value={searchInput}
                onChange={handleSearch}
                data-hook='delete-search-input'
              />
            </div>
          </Box>

          <Box display='flex' flexDirection='column'>
            <FormGroup className={classes.group}>
              {renderControlledCheckbox()}
            </FormGroup>
          </Box>
        </>
      );
    } else if (stage === 1) {
      return (
        <div>
          <Typography
            variant='h5'
            className={classes.title}
            data-hook='delete_fleet_cnfrm_msg'
            align='center'
          >
            Are you sure you want to delete this fleet(s)?
          </Typography>

          <Box
            width='100%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={'24px'}
            marginTop={'24px'}
          >
            {renderConfirmationCheck()}
          </Box>
          <Box marginTop='24px'>
            <Divider />
          </Box>

          <Box marginTop='24px'>
            <Typography
              gutterBottom
              align='center'
              className={classes.deleteWarning}
            >
              {t('deleteUserModal.deleteFleetWarning')}
            </Typography>
          </Box>
        </div>
      );
    }
  };

  const actionBtn =
    stage === 0 ? (
      'Next'
    ) : isSubmitting ? (
      <CircularProgress size={30} color='inherit' />
    ) : (
      t('common.tireInventory.confirmBtn')
    );

  return (
    <Modal
      className={classes.modal}
      open={open}
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <Paper className={classes.deletePaper} data-hook='delete-modal'>
          {renderDeleteFleetForm()}
          <Box display='flex' width='100%' gap={'10px'}>
            <Button
              variant='outlined'
              color='primary'
              className={classes.btns}
              startIcon={stage === 0 ? null : <KeyboardArrowLeft />}
              onClick={() => (stage === 0 ? handleClose() : setStage(0))}
              data-hook='cancel-or-back-btn'
            >
              {stage === 0 ? 'Cancel' : 'Back'}
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={classes.btns}
              endIcon={<KeyboardArrowRight />}
              onClick={e => (stage === 1 ? handleSubmit(e) : setStage(1))}
              disabled={
                Object.values(list ?? {}).every(value => !value) || isSubmitting
              }
              data-hook='next-or-delete-btn'
            >
              {actionBtn}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default DeleteModal;

DeleteModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  fleet: PropTypes.any,
  allFleets: PropTypes.any,
};

const InformationBox = props => {
  const { name, fleet_id } = props;
  const { classes } = useStyles();

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      gap={'16px'}
    >
      <Typography className={classes.label}>{name}</Typography>
      <Box className={classes.fleet}>{fleet_id}</Box>
    </Box>
  );
};

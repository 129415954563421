import React from 'react';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Box, Tooltip, Zoom } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    icon: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      zIndex: 100,
    },
    root: {
      overflow: 'auto',
      float: 'left',
      height: '100%',
      width: '100%',
      borderRadius: 8,
      position: 'relative',

      backgroundColor: '#fff',
    },
  };
});

const Tile = ({ bgcolor, infoComponent, style, children, click, id }) => {
  const { classes } = useStyles();

  return (
    <Box bgcolor={bgcolor} className={classes.root} style={style} id={id}>
      {infoComponent && (
        <Tooltip
          title={infoComponent}
          arrow
          className={classes.icon}
          interactive
          TransitionComponent={Zoom}
          TransitionProps={{ timeout: 600 }}
        >
          <InfoOutlinedIcon />
        </Tooltip>
      )}
      {children}
    </Box>
  );
};

Tile.propTypes = {
  bgcolor: PropTypes.string,
  infoComponent: PropTypes.any,
};

Tile.defaultProps = {
  bgcolor: '#fff',
};

export default Tile;

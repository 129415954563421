import React from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  fetchOverviewDetails,
  fleetOverviewSelector,
  setParameters,
  setTires,
} from 'redux/fleets/ducks';
import { NAComponent } from 'pages/TireInventory/TireInventoryItems/FleetTireInventory';
import i18next from 'i18next';
import { useMemo } from 'react';
import CustomTable from 'components/custom/CustomTableTemp';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { getRemainingMonths } from 'pages/TireInventory/TireInventory';
import CommonGridItem from '../CommonGridItem';
import Loading from 'components/Loading';
import { fetchAllResources } from '../VehicleOverviewPage/VehicleOverviewPage';
import LoadAnimation from 'components/LoadAnimation';
import { debounce } from 'lodash';
import { renderStatus } from 'utils/helper';

const TireOverview = () => {
  const { t } = useTranslation();
  const parameters = useSelector(state => state.fleet.parameters);
  const dispatch = useDispatch();
  const currentLanguage = i18next.language;
  const tires = useSelector(state => state.fleet.tires);
  const isLoading = useSelector(state => state.fleet.isLoadingOverviewData);
  const tireOverview = useSelector(state => state.fleet.tireOverview);
  const theme = useTheme();
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const results = useSelector(state => state.fleet.searchResults);
  const [params_, setParams_] = useState({
    accessing: 'ONLY_TIRE',
    tire_location: parameters?.tireLocation || 'IN_USE',
    page_no: 0,
  });
  const [searchQuery, setSearchQuery] = useState(null);
  const tireResults = results?.tires;
  const [downloadedResources, setDownloadedResources] = useState({
    less_3mm: null,
    IN_USE: null,
    '3_5_mm': null,
    less_3mon: null,
  });
  const [isDownloading, setIsDownloading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isInitial, setIsInitial] = useState(true);
  useEffect(() => {
    dispatch(fetchOverviewDetails(params_));

    //eslint-disable-next-line
  }, [fleets]);

  useEffect(() => {
    if (searchQuery?.length >= 2) {
      searchedDataHandler();
    }
    // eslint-disable-next-line
  }, [searchQuery]);

  useEffect(() => {
    if (!isInitial) {
      fetchTirePaginatedData();
    }
    // eslint-disable-next-line
  }, [rowsPerPage, isInitial]);

  const searchedDataHandler = debounce(async () => {
    const params = {
      accessing: 'ONLY_TIRE',
      search_string: searchQuery,
    };
    try {
      dispatch(fetchOverviewDetails(params));
    } catch (err) {
      console.error(err);
    }
  }, 400);

  useEffect(() => {
    return () => {
      searchedDataHandler.cancel();
    };
  }, [searchedDataHandler]);

  const formatNumber = num => {
    if (num === 'N/A') {
      return null;
    }

    const formattedNumber = num?.toLocaleString('de-DE', {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
      useGrouping: false,
    });

    return currentLanguage === 'de' ? formattedNumber : num?.toFixed(2);
  };

  const fetchTirePaginatedData = async (page, location) => {
    try {
      const params = {
        accessing: 'ONLY_TIRE',
        tire_location: location || params_.tire_location,
        page_no: page,
        per_page: rowsPerPage,
      };
      dispatch(fetchOverviewDetails(params));
    } catch (error) {
      console.error('E20045');
    }
  };

  const getTotalForCurrentTab = item => {
    return tireOverview?.[item];
  };

  const [tireValue, setTireValue] = useState(parameters?.tireTabValue || 0);
  const columns = useMemo(
    () => [
      {
        key: 'tire_id',
        title: t('tireInventoryItems.titles.tire_id'),
        render: row => (
          <Link
            color='primary'
            style={{
              textDecoration: 'none',
              padding: 5,
              color: theme.palette.primary.main,
            }}
            to={`/tire-inventory/${row.fleet_id}/${row?.system_tire_id}`}
          >
            {row.tire_id}
          </Link>
        ),
        size: 80,
        canSort: true,
        dataHook: 'tire-id',
      },
      {
        key: 'vehicle_name',
        title: t('tireInventoryItems.titles.name'),
        render: row => (
          <>
            {row?.vehicle_name ? (
              <Link
                style={{
                  textDecoration: 'none',
                  padding: 5,
                  color: theme.palette.primary.main,
                }}
                color='primary'
                to={`/fleets-data/${
                  row.fleet_id
                }/${row.vehicle_type.toLowerCase()}s/${row.vehicle_id}`}
              >
                <NAComponent val={row?.vehicle_name} />
                {renderStatus(row?.metadata?.interchange?.status)}
              </Link>
            ) : (
              <>
                <NAComponent val={row?.vehicle_name} />
                {renderStatus(row?.metadata?.interchange?.status)}
              </>
            )}
          </>
        ),
        size: 80,
        canSort: true,
        dataHook: 'vehicle-name',
      },
      {
        key: 'brand',
        title: t('common.tireInventory.tireBrand'),
        size: 80,
        canSort: true,
        dataHook: 'brand',
      },
      {
        key: 'product_line',
        title: t('common.tireInventory.productLines'),
        size: 80,
        canSort: true,
        dataHook: 'product-line',
      },
      {
        key: 'estimated_tread_depth',
        title: t('tireInventoryItems.titles.estimated_current_tread_depth'),
        render: row => (
          <NAComponent val={formatNumber(row.estimated_tread_depth)} />
        ),
        size: 80,
        canSort: true,
        dataHook: 'tread-depth',
      },
      {
        key: 'estimated_wear_out_date',
        title: t('tireInventoryItems.titles.monthsLeft'),
        render: row => (
          <NAComponent val={getRemainingMonths(row.estimated_wear_out_date)} />
        ),
        filterVariant: 'range',
        canSort: true,
        dataHook: 'remaining-months',
      },
    ],
    /*eslint-disable-next-line*/
    [i18next.language]
  );

  let tireData = [
    {
      label: 'In use',
      component: (
        <TireTable
          tires={tires}
          columns={columns}
          category={'tires_in_use'}
          fetchPaginatedData={fetchTirePaginatedData}
          getTotalForCurrentTab={getTotalForCurrentTab}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: 'IN_USE',
      category: 'tires_in_use',
    },
    {
      label: '<3 mm',
      component: (
        <TireTable
          tires={tires}
          columns={columns}
          category={'less_3mm'}
          fetchPaginatedData={fetchTirePaginatedData}
          getTotalForCurrentTab={getTotalForCurrentTab}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: 'less_3mm',
      category: 'less_3mm',
    },
    {
      label: '3 - 5 mm',
      component: (
        <TireTable
          tires={tires}
          columns={columns}
          category={'between_3mm_5mm'}
          fetchPaginatedData={fetchTirePaginatedData}
          getTotalForCurrentTab={getTotalForCurrentTab}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: '3_5_mm',
      category: 'between_3mm_5mm',
    },
    {
      label: t('common.irregular_wear'),
      component: (
        <TireTable
          tires={tires}
          columns={columns}
          category={'grooves_td_diff_gt_2'}
          fetchPaginatedData={fetchTirePaginatedData}
          getTotalForCurrentTab={getTotalForCurrentTab}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: 'grooves_td_diff_gt_2',
      category: 'grooves_td_diff_gt_2',
    },
    {
      label: '< 3 months',
      component: (
        <TireTable
          tires={tires}
          columns={columns}
          category={'within_3mon'}
          fetchPaginatedData={fetchTirePaginatedData}
          getTotalForCurrentTab={getTotalForCurrentTab}
          loading={isLoading}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setIsInitial={setIsInitial}
        />
      ),
      id: 'less_3mon',
      category: 'within_3mon',
    },
  ];

  tireData = tireData.map(data => {
    const count = tireOverview?.[data.category];
    data.count = count;
    return data;
  });

  const handleChange = (event, newValue) => {
    const total = tireOverview[tireData[newValue].category];
    setTireValue(newValue);
    setParams_({
      ...params_,
      tire_location: tireData[newValue].id,
      accessing: 'ONLY_TIRE',
    });
    dispatch(
      setParameters({
        tireTabValue: newValue,
        tireLocation: tireData[newValue].id,
        accessing: 'ONLY_TIRE',
      })
    );
    if (total) {
      fetchTirePaginatedData(0, tireData[newValue].id);
    } else {
      dispatch(setTires([]));
    }
  };

  if (!tires) {
    return <Loading />;
  }

  return (
    <div style={{ maxHeight: '74vh' }}>
      {isDownloading && (
        <LoadAnimation
          loading={isDownloading}
          setLoading={setIsDownloading}
          useMultiple={true}
        />
      )}
      <CommonGridItem
        title={t('common.tireOverview')}
        currentTab={tireValue}
        tabs={tireData}
        handleChange={handleChange}
        overview={tireOverview}
        identifier='tire'
        setSearchQuery={setSearchQuery}
        results={tireResults}
        downloadResources={() =>
          fetchAllResources(
            tireData,
            tireValue,
            downloadedResources,
            setDownloadedResources,
            'ONLY_TIRE',
            tireOverview,
            setIsDownloading
          )
        }
      />
    </div>
  );
};

export default TireOverview;

const TireTable = ({
  tires,
  columns,
  fetchPaginatedData,
  getTotalForCurrentTab,
  category,
  loading,
  rowsPerPage,
  setRowsPerPage,
  setIsInitial,
}) => {
  return (
    <CustomTable
      data={tires || []}
      columns={columns}
      emptyMessage={'No tires available'}
      paginationHandler={fetchPaginatedData}
      freq={getTotalForCurrentTab(category)}
      loading={loading}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      isInitialRun={setIsInitial}
      total={getTotalForCurrentTab(category)}
    />
  );
};

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CustomAccordion from 'components/custom/Accordion/Accordion';
import FleetSettings from './FleetSettings';
import ProfileSettings from './ProfileSettings';
import MotionWrap from 'wrapper/MotionWrap';
import Tour from 'reactour';
import TourContent from 'components/Tour/Content';
import TelematicsDataIntegration from 'components/TelematicsDataIntegration';
import {
  setTour,
  setTourSelector,
  updateGuideSelection,
} from 'redux/users/ducks';
import UserManagement from './UserManagement';
import { telematicsDataSelector } from 'redux/telematicsProvider/ducks';
import { TELEMATICS_INFO } from 'utils/string_utils';
import Notifications from './Notifications';
import { useTheme } from '@mui/material';
import TirePriceTable from './TireSettings';

const settings = 'settings';
const Settings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const [flag, setFlag] = useState(false);

  const isTour = useSelector(setTourSelector);
  const telematics_data_ls = JSON.parse(TELEMATICS_INFO);
  const telematics_data_redux = useSelector(telematicsDataSelector);
  const telematics_data = telematics_data_redux ?? telematics_data_ls;
  const guideSelectionSelector = useSelector(
    state => state.users.guideSelection
  );

  useEffect(() => {
    const timeout = setTimeout(() => setFlag(true), 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(updateGuideSelection(null));
    };
  }, [dispatch]);

  const styling = {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
  };
  const steps = [
    {
      selector: '#profile-settings',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.settings.profileSettings')}
          finalAction='Next'
          goTo={() => goTo(1)}
          page={settings}
        />
      ),
      style: styling,
    },
    {
      selector: '#fleet-settings',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.settings.fleetSettings')}
          finalAction='Next'
          goTo={() => goTo(2)}
          page={settings}
        />
      ),
      style: styling,
    },
    {
      selector: '#notifications',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t(`guidesPage.tour.notifications`)}
          finalAction='Next'
          goTo={() => goTo(3)}
          page={settings}
        />
      ),
      style: styling,
    },
    {
      selector: '#user-mgt',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t('tour.page.settings.userMgt')}
          finalAction='Next'
          goTo={() => {
            dispatch(setTour(false));
          }}
          page={settings}
        />
      ),
      style: styling,
    },
  ];

  const [showTour, setShowTour] = useState(
    steps.some(step => step.selector.includes(guideSelectionSelector))
      ? true
      : false
  );

  const notificationTour = [
    {
      selector: '#notifications',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t(`guidesPage.tour.notifications`)}
          finalAction='Done'
          goTo={() => setShowTour(false)}
          page={settings}
          hideSkip={true}
        />
      ),
      style: styling,
    },
  ];
  const optimalPressureTour = [
    {
      selector: '#update-opt-pressure',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t(`guidesPage.tour.optimalPressure`)}
          finalAction='Done'
          goTo={() => setShowTour(false)}
          page={settings}
          hideSkip={true}
        />
      ),
      style: styling,
    },
  ];
  const inviteTour = [
    {
      selector: '#create-user-button',
      content: ({ goTo, inDOM }) => (
        <TourContent
          content={t(`guidesPage.tour.invite`)}
          finalAction='Done'
          goTo={() => setShowTour(false)}
          page={settings}
          hideSkip={true}
        />
      ),
      style: styling,
    },
  ];

  const tourObjects = {
    notifications: notificationTour,
    'fleet-settings': optimalPressureTour,
    'user-mgt': inviteTour,
  };

  const items_without_telemactics = [
    {
      summary: t('common.profileSettings'),
      Component: ProfileSettings,
      id: 'profile-settings',
    },
    {
      summary: t('common.fleetSettings'),
      Component: FleetSettings,
      id: 'fleet-settings',
    },
    {
      summary: t('common.notificationSettings'),
      Component: Notifications,
      id: 'notifications',
    },
    {
      summary: t('common.userMgt'),
      Component: UserManagement,
      id: 'user-mgt',
    },
    {
      summary: t('settingsPage.tireSettings.header'),
      Component: TirePriceTable,
      id: 'tire-settings',
    },
  ];
  const items_with_telemactics = [
    ...items_without_telemactics,
    {
      summary: t('common.integrationInfo'),
      Component: TelematicsDataIntegration,
      id: 'telematics-page',
    },
  ];
  const items =
    telematics_data?.length > 0
      ? items_with_telemactics
      : items_without_telemactics;

  const renderTourFromGuide = () => {
    if (flag) {
      return (
        <Tour
          startAt={0}
          steps={tourObjects[guideSelectionSelector]}
          isOpen={showTour}
          accentColor={theme.palette.primary.main}
          onRequestClose={() => setShowTour(false)}
          showNavigation={false}
          showButtons={false}
          showNNumber={false}
          badgeContent={() => ``}
        />
      );
    }
  };
  const renderTour = () => {
    if (flag) {
      return (
        <Tour
          startAt={0}
          steps={steps}
          isOpen={isTour}
          accentColor={theme.palette.primary.main}
          onRequestClose={() => dispatch(setTour(false))}
          showNavigation={false}
          showButtons={false}
        />
      );
    }
  };

  return (
    <div>
      <CustomAccordion items={items} />
      {renderTour()}
      {renderTourFromGuide()}
    </div>
  );
};

export default MotionWrap(
  Settings,
  { opacity: 0, scaleY: 0 },
  { opacity: 1, scaleY: 1 },
  { opacity: 0, scaleY: 0 }
);

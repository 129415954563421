import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  Grid,
  Modal,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/system/Box';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { TIRES, tireOperationsOptions, tireStatuses } from 'utils/string_utils';
import { useSelector } from 'react-redux';
import {
  fleetOverviewSelector,
  setChangeInVehicle,
  setFleetOverview,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  setVehiclesForFleet,
  unprocessedTireInventoryDataSelector,
  vehicleUpdatedSelector,
} from 'redux/fleets/ducks';
import { useParams } from 'react-router';
import { fetchTireInventoryDataAndProcess } from 'pages/TireInventory/TireInventory';
import { useDispatch } from 'react-redux';
import { generateRandomCharacters } from './TireOperationsModal';
import {
  addTire,
  checkTire,
  fleetOverview,
  getVehiclesForFleet,
  unmountOptions,
  updateTire,
} from 'services/fleet_service';
import moment from 'moment';
import { setNotification } from 'redux/notifications/ducks';
import CustomTextField from 'components/custom/CustomTextField';
import {
  debouncedCommonChangeHandler,
  debouncedTireIdChangeHandler,
} from 'services/util_services';
import { setAccessTokenSelector, updateTasks } from 'redux/users/ducks';
import TireForm from 'components/TireForm';
import {
  inventoryChangeSelector,
  setChangeInInventory,
} from 'redux/tireInventory/ducks';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      height: 'auto',
      border: 'none',

      alignItems: 'center',
      textAlign: 'center',
      padding: '48px 98px 48px 98px',
      width: '770px',
      position: 'relative',
      borderRadius: '32px',
      display: 'flex',
      flexDirection: 'column',
      gap: 22,
    },
    back: {
      position: 'absolute',
      left: 5,
      top: '50%',
      transform: 'translate(0, -50%)',
    },
    applyBtn: {
      textTransform: 'unset',
      // margin: '0rem 1rem',
    },
    uploadBox: {
      fontStyle: 'italic',
      textTransform: 'unset',
    },
    navBtns: {
      textTransform: 'unset',
      flex: '30%',
      borderRadius: 24,
    },
    link: {
      textTransform: 'uppercase',
      padding: 5,
      color: theme.palette.primary.main,
      fontSize: theme.typography.h6.fontSize,

      '&:hover': {
        background: theme.palette.primary.light,
      },
    },
    fullWidth: {
      width: '100%',
    },
    unmountHeading: {
      alignSelf: 'flex-start',
      fontWeight: 900,
      margin: 0,
      padding: 0,
    },
  };
});

const ModifyTireModal = ({
  usedTires,
  setUsedTires,
  open,
  setOpen,
  presentTire,
  handleClose,
  operation,
  setHighlightedTire,
  location = 'ALL',
  vehicle,
  axle,
  tire,
  index,
  perPage,
  currentPage,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const steps = [t('common.unmount'), t('common.mount')];
  // const [presentTire, setPresentTire] = useState(null);
  const [unmountReason, setUnmountReason] = useState(null);
  const [unmountOption, setUnmountOption] = useState(null);
  const [eventDate, setEventDate] = useState(new Date());
  const [availableTires, setAvailableTires] = useState([]);
  const [selectedTire, setSelectedTire] = useState('');
  const [newTireMountDate, setNewTireMountDate] = useState(new Date());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [option, setOption] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [tiresDb, setTiresDb] = useState([]);

  const params = useParams();
  const { fleet_id } = params;
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);

  let dispatch = useDispatch();
  const [finalDate, setFinalDate] = useState(new Date());
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);
  const [payload, setPayload] = useState({
    isRetread: presentTire?.isRetread,
    [TIRES.TREAD_DEPTH]: presentTire?.last_tread_depth,
    tire_id: presentTire?.tire_id,
    km: presentTire?.mileage ?? presentTire?.last_km_driven,
    isRegrooved: presentTire?.isRegrooved || false,
  });
  const tDepth = presentTire?.last_tread_depth;
  const mileage = presentTire?.mileage ?? presentTire?.last_km_driven;
  const [errors, setErrors] = useState({
    [TIRES.TIRE_ID]: null,
    [TIRES.TREAD_DEPTH]: null,
    [TIRES.PRESSURE]: null,
  });
  const [isAutoGenerated, setIsAutoGenerated] = useState(true);
  const currInventoryChangeValue = useSelector(inventoryChangeSelector);
  const access_token = useSelector(setAccessTokenSelector);
  const [productLine, setProductLine] = useState(null);
  const [tireBrand, setTireBrand] = useState(null);
  const [initialId, setInitialId] = useState('');
  const [axleName, setAxleName] = useState(null);
  const [lastTireId, setLastTireId] = useState(null);
  const [isNewTire, setNewTire] = useState(false);
  const tireStatic = JSON.parse(JSON.stringify(presentTire));
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const tireInventoryDetails = useSelector(state => state.fleet.overviewData);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChangeSwitch = event => {
    setChecked(event.target.checked);
  };
  const setFleetOverviewFn = async () => {
    const fleetResponse = await fleetOverview(access_token);
    dispatch(setFleetOverview(fleetResponse.data.resp));
    return fleetResponse;
  };
  const [grooves, setGrooves] = useState({
    1: null,
  });
  const errorFound =
    isSubmitting ||
    unmountOption === null ||
    payload[TIRES.TREAD_DEPTH] > 20 ||
    eventDate.toString() === 'Invalid Date';
  const getTires = async () => {
    setIsLoading(true);
    try {
      const data = await fetchTireInventoryDataAndProcess({
        fleet_id: fleet_id,
        inventoryData: fleetInventoryData,
        refetch: true,
        location,
        isPaginated: true,
        pageNo: currentPage || 0,
        perpage: perPage || tireInventoryDetails.metrics[fleet_id].total_tires,
      });
      setTiresDb(data?.originalData);
      setIsLoading(false);
      if (data?.originalData?.length > 0) {
        dispatch(setTireInventoryData(data, fleet_id));
        await setFleetOverviewFn();
      }
    } catch (error) {
      console.error('E20057', error);
    }
  };

  useEffect(() => {
    const getInitialId = async () => {
      let isUsed = true;
      try {
        while (isUsed) {
          const newId = generateRandomCharacters();
          const res_ = await checkTire(newId);
          if (res_.status === 200) {
            setInitialId(newId);
            isUsed = false;
          } else if (res_.status === 404) {
            isUsed = false;
          }
        }
      } catch (error) {
        console.error('E20049', error);
      }
    };

    if (operation === 'tire_change') {
      getTires();
      getInitialId();
    }

    // eslint-disable-next-line
  }, [operation, currInventoryChangeValue]);

  useEffect(
    function sortAvailableTires() {
      const sortFunction = (a, b) => {
        return (
          moment(a.last_event_date).format('X') <
          moment(b.last_event_date).format('X')
        );
      };

      const available = tiresDb?.filter(
        tire =>
          tire.vehicle_id === undefined &&
          tire.size === presentTire?.size &&
          tire.tireStatus !== tireStatuses.RETREADING &&
          tire.tireStatus !== tireStatuses.REPAIR
      );

      tiresDb?.forEach(tire => {
        if (tire.vehicle_id && tire.size === presentTire?.size) {
          if (!usedTires.includes(tire.system_tire_id)) {
            setUsedTires(prev => [...prev, tire.system_tire_id]);
          }
        }
      });
      let new_tires = [];
      for (let i = 0; i < available.length; i++) {
        if (available[i].last_event_type === 'CREATE') {
          new_tires.push(available[i]);
          available[i] = null;
        }
      }
      let result_arr = new_tires
        .sort(sortFunction)
        .concat(available.filter(x => x !== null));

      if (isNewTire) {
        setSelectedTire(
          result_arr.filter(x => x.system_tire_id === lastTireId)[0]
        );
      }
      if (available?.length > 0) setAvailableTires(result_arr);

      setAxleName(
        presentTire.position?.includes('T')
          ? 'TRAILER'
          : presentTire.position?.includes('D')
          ? 'DRIVE'
          : 'STEER'
      );
    },
    // eslint-disable-next-line
    [tiresDb]
  );
  const [typedYear, setTypedYear] = React.useState(eventDate.getFullYear());

  useEffect(() => {
    let disableCondition;
    if (currentStep === 0) {
      disableCondition =
        unmountOption === null ||
        eventDate === null ||
        eventDate.toString() === 'Invalid Date' ||
        typedYear < 1900 ||
        typedYear > 2099;
      setDisabled(disableCondition);
    } else {
      disableCondition =
        unmountOption === null ||
        !selectedTire ||
        JSON.stringify(finalDate) === 'null' ||
        finalDate.toString() === 'Invalid Date' ||
        typedYear < 1900 ||
        typedYear > 2099;
      setDisabled(disableCondition);
    }
  }, [
    unmountOption,
    eventDate,
    selectedTire,
    currentStep,
    finalDate,
    typedYear,
  ]);

  const handlePayloadChange = async e => {
    const tireId_ = presentTire?.tire_id;
    if (e.target.name === TIRES.TIRE_ID) {
      debouncedTireIdChangeHandler(
        e,
        setErrors,
        errors,
        setPayload,
        payload,
        tireId_,
        t,
        access_token,
        setIsAutoGenerated
      );
    } else {
      debouncedCommonChangeHandler(
        e,
        setErrors,
        errors,
        setPayload,
        payload,
        tireId_,
        t,
        access_token,
        setIsAutoGenerated
      );
    }
  };

  const submitFormHandler = async e => {
    e.preventDefault();
    const payload_ = {
      fleet_id,
      tires: [
        {
          tire_id: isAutoGenerated ? initialId : payload.tire_id,
          brand: tireBrand,
          product_line: productLine,
          size: presentTire.size,
          tread_depth: payload.last_tread_depth,
          isRetread: payload.isRetread,
          isRegrooved: payload.isRegrooved,
          grooves_tread_depth: checked ? grooves : {},
        },
      ],
    };

    try {
      const res = await addTire(access_token, payload_);
      if (res.status === 200) {
        const summary_ = res?.data?.resp?.tire_summary;

        setLastTireId(summary_[0].system_tire_id);
        setNewTire(true);

        if (summary_.length && summary_[0].upload_status === 'fail') {
          dispatch(setNotification(summary_[0].upload_result, 'error', true));
        } else {
          dispatch(
            setNotification(
              t('common.tireInventory.tireUploadedMsg'),
              'success',
              true
            )
          );
          const opRes = await setFleetOverviewFn();
          const totalTires =
            opRes?.data?.resp?.metrics[fleet_id].total_tires ||
            tireInventoryDetails.metrics[fleet_id].total_tires;
          const data = await fetchTireInventoryDataAndProcess({
            fleet_id,
            inventoryData: fleetInventoryData,
            refetch: true,
            location: 'ALL',
            isPaginated: true,
            pageNo: 0,
            perpage: totalTires + 1,
          });
          dispatch(setTireInventoryData(data, fleet_id));
          dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
          dispatch(setChangeInInventory(!currInventoryChangeValue));
          if (presentTire) {
            setOption(tireOperationsOptions.CHANGE_TIRE);
            setCurrentStep(1);
          } else {
            setOption(tireOperationsOptions.CHANGE_TIRE);
          }
          closeAddNewTireHandler();
        }
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20062', error);
    }
  };

  const changeHandler2 = (e, value) => {
    setUnmountReason(unmountOptions(t).unmountOptionsMapped[value]);
    setUnmountOption(value);
  };

  const moveToInventoryHandler = async (e, skip) => {
    setIsSubmitting(true);
    const id = presentTire?.system_tire_id;
    const axlId = presentTire?.axle_position_id;
    let response = { axlId };
    let events = {
      event_type: unmountReason,
      event_date: moment(eventDate).format('YYYY-MM-DD'),
    };
    if (payload.km?.length !== 0 && payload.mileage !== mileage) {
      events = { ...events, km: payload.km };
    }
    if (
      payload[TIRES.TREAD_DEPTH]?.length !== 0 &&
      payload[TIRES.TREAD_DEPTH] !== null &&
      payload[TIRES.TREAD_DEPTH] !== tDepth
    ) {
      events = { ...events, tread_depth: payload[TIRES.TREAD_DEPTH] };
    }
    const payload_ = {
      events: [events],
    };

    try {
      const res = await updateTire(id, payload_);
      if (res.status === 200) {
        dispatch(
          setNotification(
            t('common.tireInventory.movedToStorageSuccess'),
            'success',
            true
          )
        );
        getTires();
        dispatch(setChangeInInventory(!currInventoryChangeValue));
        if (!fleet_id) {
          dispatch(updateTasks(fleets));
        }
        response['res'] = 200;
        if (usedTires?.includes(presentTire?.system_tire_id)) {
          const x = usedTires;
          const foundIndex = x.findIndex(
            tire => tire?.system_tire_id === presentTire?.system_tire_id
          );
          x?.splice(foundIndex, 1);
          setUsedTires(x);
        }

        if (skip === false || skip === undefined) {
          dispatch(setChangeInVehicle(!vehicleDataHasChanged));
          if (!!setHighlightedTire) {
            setHighlightedTire(prev => {
              let row = vehicle?.mounted_tires?.filter(
                tire => !!tire.position && tire.tire_id !== presentTire.tire_id
              )[0];

              // setParameters(row);
              return row;
            });
          }
          handleClose();
        }
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20059', error);
    } finally {
      setIsSubmitting(false);
    }
    return response;
  };

  const nextStepClickHandler = async e => {
    setDisabled(true);
    if (currentStep === 1) {
      try {
        await moveToInventoryHandler(e, true);
        await applyHandler(e);
        const vehicles = await getVehiclesForFleet(
          { fleet_id: fleet_id },
          access_token
        );
        dispatch(setVehiclesForFleet(fleet_id, vehicles));
        const data = await fetchTireInventoryDataAndProcess(
          fleet_id,
          fleetInventoryData,
          true,
          location
        );
        dispatch(setTireInventoryData(data, fleet_id));
        dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
        setDisabled(false);
        setOpen(false);
      } catch (error) {
        throw new Error(error);
      } finally {
        setOpen(false);
        setDisabled(false);
      }
    }
    setCurrentStep(prev => prev + 1);
  };

  const changeHandler = (e, value) => {
    setSelectedTire(value);
  };
  const newTireMountDateChangeHandler = date => {
    setFinalDate(date);
    setNewTireMountDate(date);
    const year = date.getFullYear().toString();
    setTypedYear(parseInt(year));
  };
  const applyHandler = async e => {
    e?.preventDefault();
    const position = presentTire.position || presentTire.axle_position;

    const positionId = tireStatic.axle_position_id;
    !!positionId && localStorage.setItem('position_id', positionId);

    let obj = {
      position,
      axle_position_id: positionId ?? localStorage.getItem('position_id'),
      event_type: 'MOUNT',
      event_date: moment(finalDate).format('YYYY-MM-DD'),
    };

    let response = null;

    try {
      const payload_ = {
        events: [{ ...obj }],
      };

      const res = await updateTire(selectedTire.system_tire_id, payload_);
      if (res.status === 200) {
        response = 200;
        if (!!setHighlightedTire) {
          setHighlightedTire(res?.data?.resp);
        }
        if (!usedTires.includes(selectedTire.system_tire_id)) {
          setUsedTires(prev => [...prev, selectedTire.system_tire_id]);
        }
        dispatch(setNotification(res?.data?.return_message, 'success', true));
        dispatch(setChangeInVehicle(!vehicleDataHasChanged));
        handleClose();
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error(error);
    }
    return response;
  };

  const closeAddNewTireHandler = () => {
    setErrors({
      [TIRES.TIRE_ID]: null,
      [TIRES.TREAD_DEPTH]: null,
    });
    setProductLine(null);
    setTireBrand(null);
    setOption(0);
    setGrooves({
      1: null,
    });
    setChecked(false);
  };

  const renderTireChangeSteps = () => {
    if (currentStep === 0 && presentTire) {
      return (
        <>
          <h4 className={classes.unmountHeading}>
            {t('tireOperationsModal.unmountTireTitle')}
          </h4>

          <Autocomplete
            id='unmount-options'
            options={unmountOptions(t).options}
            getOptionLabel={option => option}
            onChange={changeHandler2}
            value={unmountOption}
            className={classes.fullWidth}
            renderInput={params => (
              <TextField
                {...params}
                label={t('tireOperationsModal.unmountReasonLabel')}
                variant='outlined'
              />
            )}
          />

          <Box width='100%'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('tireOperationsModal.unmountDateLabel')}
                value={eventDate}
                onChange={newValue => {
                  setEventDate(newValue);
                  const year = newValue.getFullYear().toString();
                  setTypedYear(parseInt(year));
                }}
                format='yyyy.MM.dd'
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Box>
        </>
      );
    } else if (currentStep === 1) {
      return (
        <>
          <h4 className={classes.unmountHeading}>
            {t('tireOperationsModal.mountTireTitle')}
          </h4>
          <Box display='flex' width='100%'>
            <Autocomplete
              id='combo-box-demo'
              options={availableTires.filter(
                item => !usedTires.includes(item.system_tire_id)
              )}
              getOptionLabel={option =>
                `${option.brand ?? ''} ${option.product_line ?? ''} ${
                  option.tire_id ? '-' : ''
                } ${option.tire_id ?? ''}`
              }
              onChange={changeHandler}
              noOptionsText={t('tireOperationsModal.noTiresMsg')}
              value={selectedTire}
              className={classes.fullWidth}
              renderInput={params => (
                <TextField {...params} label='Tires' variant='outlined' />
              )}
            />
            {isLoading && (
              <Box mx='0.5rem'>
                <CircularProgress />
              </Box>
            )}
          </Box>

          <Box width='100%'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label={t('tireOperationsModal.mountDateLabel')}
                value={newTireMountDate}
                onChange={newTireMountDateChangeHandler}
                sx={{ width: '100%' }}
                format='yyyy.MM.dd'
              />
            </LocalizationProvider>
          </Box>

          <Box display='flex' alignItems='center' className={classes.uploadBox}>
            <Typography variant='h6'>
              {t('tireOperationsModal.uploadTireSuggestion')}
            </Typography>
            <Button
              variant='text'
              color='primary'
              style={{ textTransform: 'inherit' }}
              onClick={() => {
                setOption(tireOperationsOptions.NEW_TIRE);
              }}
            >
              {t('tireOperationsModal.here')}
            </Button>
          </Box>
        </>
      );
    }
  };

  const renderTireOperations = () => {
    if (option === tireOperationsOptions.NEW_TIRE) {
      return (
        <>
          <h2>{t('common.tireInventory.addTire')}</h2>
          <TireForm
            productLine={productLine}
            tireBrand={tireBrand}
            setTireBrand={setTireBrand}
            size={presentTire.size}
            error={errors}
            handleClose={closeAddNewTireHandler}
            setProductLine={setProductLine}
            handleChange={handlePayloadChange}
            initialId={initialId}
            payload={payload}
            setPayload={setPayload}
            submitFormHandler={submitFormHandler}
            axleName={axleName}
            caller='tire_config'
            vehicle={vehicle}
            axle={axle}
            tire={tire}
            index={index}
            checked={checked}
            handleChangeSwitch={handleChangeSwitch}
            grooves={grooves}
            setGrooves={setGrooves}
          />
        </>
      );
    } else if (operation === 'tire_change') {
      return (
        <>
          <Typography variant='h5' data-hook='change-tire-title'>
            {t('common.changeTire')}
          </Typography>
          <Stepper
            activeStep={currentStep}
            style={{ backgroundColor: 'transparent', width: '100%' }}
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderTireChangeSteps()}
          <Box
            width='100%'
            display='flex'
            justifyContent='space-between'
            // px={2}
            marginTop={2}
            gap={'16px'}
          >
            <Button
              className={classes.navBtns}
              variant='outlined'
              onClick={() => {
                currentStep === 0 && setOpen(false);
                setCurrentStep(prev => prev - 1);
              }}
            >
              {currentStep === 1 ? t('common.back') : t('common.cancel')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={classes.navBtns}
              endIcon={currentStep === 0 ? <KeyboardArrowRightIcon /> : <></>}
              onClick={nextStepClickHandler}
              disabled={disabled}
            >
              {currentStep === 0 ? t('common.next') : t('common.confirm')}
            </Button>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Typography variant='h5' data-testid='unmount-tire-modal'>
            {t('common.unmountTire')}
          </Typography>
          <Typography variant='caption' data-hook='unmount-msg-warning'>
            {t('common.tireInventory.unmountMsgWarning')}
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Autocomplete
                id='unmount-options'
                options={unmountOptions(t).options}
                getOptionLabel={option => option}
                onChange={changeHandler2}
                value={unmountOption}
                fullWidth
                renderInput={params => (
                  <TextField
                    {...params}
                    label={t('tireOperationsModal.unmountReasonLabel')}
                    variant='outlined'
                    data-hook='select-reason-field'
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Box width='100%'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={t('tireOperationsModal.unmountDateLabel')}
                    value={eventDate}
                    onChange={date => {
                      setEventDate(date);
                      const year = date.getFullYear().toString();
                      setTypedYear(parseInt(year));
                    }}
                    format='yyyy.MM.dd'
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box width='100%'>
                <CustomTextField
                  name={TIRES.TREAD_DEPTH}
                  id={TIRES.TREAD_DEPTH}
                  label={t('common.tireInventory.depth')}
                  onChange={handlePayloadChange}
                  variant='outlined'
                  value={payload[TIRES.TREAD_DEPTH]}
                  type='number'
                  placeholder='Optional'
                  InputProps={{
                    inputProps: {
                      min: 0,
                      max: 20,
                      step: 'any',
                    },
                  }}
                  error={payload[TIRES.TREAD_DEPTH] > 20}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display='flex'
                gap={'16px'}
                alignSelf='center'
                marginTop={'48px'}
              >
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.navBtns}
                  onClick={handleClose}
                  data-hook='unmount-cancel-button'
                >
                  {t('common.actions.cancel')}
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.navBtns}
                  onClick={moveToInventoryHandler}
                  disabled={errorFound}
                  data-hook='unmount-submit-button'
                >
                  {isSubmitting ? (
                    <CircularProgress size={30} color='inherit' />
                  ) : (
                    <Typography variant='h6' align='center'>
                      {t('tireConfigPage.confirm')}
                    </Typography>
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
      data-hook='change-tire-modal'
    >
      <Fade in={open}>
        <Paper data-hook='modify-tire-modal' className={classes.paper}>
          {renderTireOperations()}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ModifyTireModal;

// import axios from 'axios';

export const sendTelegramMessage = async data => {
  try {
    return data;
    // const { message = 'no data' } = data;
    // @CallMeBot_txtbot.
    // https://api.callmebot.com/text.php?user=[username]&text=[text]&html=[html_format]&links=[link_preview]
    // #for one recipient:
    // https://api.callmebot.com/text.php?user=@myusername&text=This+is+a+test+from+CallMeBot
    // #for multiple recipients:
    // https://api.callmebot.com/text.php?user=@myusername1|@username2&text=This+is+a+test+from+CallMeBot
    // http://api.callmebot.com/text.php?user=varunmiyani&text=This%20is%20a%20test%20from%20Callmebot
    // const prefix = `https://cors-anywhere.herokuapp.com`;

    // TODO: integrate BE api here FE-230
    // const res = await axios.get(
    //   `http://api.callmebot.com/text.php?user=${
    //     process.env.REACT_APP_TELEGRAM_ID
    //   }&text=${encodeURI(message)}`
    // );
    // return res.data;
  } catch (error) {
    console.error('E10066', error);
  }
};

// Telegram docs
// Varun, the bot is already active for this user
// You can now send text messages and make calls to yourself using CallMeBot API.

// To send Text Messages, please visit: https://www.callmebot.com/blog/telegram-text-messages/

// To make a Call, please visit: https://www.callmebot.com/telegram-call-api/

// Example to send a text message: https://api.callmebot.com/text.php?user=@varunmiyani&text=This+is+a+test+message

// Example to make a voice call: https://api.callmebot.com/start.php?user=@varunmiyani&text=This+is+a+test+call

// To Deactivate and Stop the bot send: /stop

// To List the available commands, send: /help

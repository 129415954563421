import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography, Card, CardContent } from '@mui/material';

import Layout from 'components/Layout';
import Loading from 'components/Loading';

import { verifyUser } from 'redux/users/ducks';
import { notificationSelector } from 'redux/notifications/ducks';
import { setStatusSelector } from 'redux/users/ducks';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      maxWidth: 475,
      margin: '0rem 0.5rem',
    },
    title: {
      fontSize: 14,
    },
    links: {
      color: '#007FFF',
      cursor: 'pointer',
    },
  };
});

const VerificationPage = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  let { token } = useParams();
  const status = useSelector(setStatusSelector);
  let navigate = useNavigate();

  const msg = useSelector(notificationSelector);
  useEffect(() => {
    async function verfiyUser() {
      dispatch(verifyUser(token));
    }
    verfiyUser();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setTimeout(function () {
      if (status === 'login') {
        navigate('/login');
      }
    }, 3000);
    // eslint-disable-next-line
  }, [status]);
  return (
    <Layout>
      {msg.message !== null ? (
        <Box
          style={{
            scrollBehavior: 'unset',
          }}
          width='100%'
          height='100vh'
          bgcolor='#ccc'
        >
          <Box
            height='100%'
            display='flex'
            flexGrow={1}
            justifyContent='center'
            alignItems='center'
          >
            <Card className={classes.root}>
              <CardContent>
                <Typography variant='h5' gutterBottom align='left'>
                  {msg.message}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </Layout>
  );
};

export default VerificationPage;

export const decodeActivity = {
  // account related
  en: {
    // account related
    A101: 'Account created',
    A102: 'Account verified',
    A103: 'Account blocked',

    // tire + vehicle related
    A201: 'Tires update',
    A202: 'Tires mounted',
    A203: 'Tires unmounted',
    A204: 'Tires changed',
    A205: 'Tread depth updated',
    A206: 'Pressure updated',
    A207: 'Mileage updated',
    A208: 'Tire id updated',
    A209: 'Retread updated',
    A210: 'Brand updated',
    A211: 'Product line updated',
    A212: 'Tire RFID updated',
    A213: 'Regroove updated',
    A214: 'Grooves tread depth updated',
    A215: 'Dot Updated',

    // fleet related
    A301: 'Trucks created',
    A302: 'Trailers created',
    A303: 'Buses created',
    A304: 'Tires created',
    A305: 'Fleets created',

    // vehicle related
    A401: 'Safety check updated',
    A402: 'Inspection date updated',
    A403: 'Axle type updated',
    A404: 'Odometer updated',
    A405: 'Interchange performed',
    A406: 'Tire Check performed',
    A407: 'Audit',
    A408: 'Steer tire size updated',
    A409: 'Drive tire size updated',
    A410: 'Truck/Trailer coupled',
    A411: 'Truck/Trailer decoupled',
    A412: 'OCR performed for service document',
    A413: 'Name updated',
    A414: 'Registration plate number updated',
    A415: 'VIN updated',
    A416: 'Chassis number updated',
    A417: 'Internal grouping updated',
    A418: 'Air Pressure updated',
    A419: 'Fleet changed',

    // general
    A501: 'Resource deleted',
    A502: 'Configuration',

    // trucks/bus related
    A601: 'Tank capacity updated',

    // trailers related
    A701: 'Trailer lift axle updated',
    A702: 'Trailer tire size updated',
  },
  de: {
    // kontobezogen
    A101: 'Konto erstellt',
    A102: 'Konto verifiziert',
    A103: 'Konto gesperrt',

    // reifen- und fahrzeugbezogen
    A201: 'Reifen aktualisieren',
    A202: 'Reifen montiert',
    A203: 'Reifen abmontiert',
    A204: 'Reifen gewechselt',
    A205: 'Profiltiefe aktualisiert',
    A206: 'Luftdruck aktualisiert',
    A207: 'Kilometerstand aktualisiert',
    A208: 'Reifenkennung aktualisiert',
    A209: 'Runderneuerung aktualisiert',
    A210: 'Marke aktualisiert',
    A211: 'Produktlinie aktualisiert',
    A212: 'RFID-Reifen aktualisiert',
    A213: 'Rillen aktualisiert',
    A214: 'Rillen-Profiltiefe aktualisiert',

    // Flottenbezogen
    A301: 'Lastwagen erstellt',
    A302: 'Anhänger erstellt',
    A303: 'Busse erstellt',
    A304: 'Reifen erstellt',
    A305: 'Fuhrpark erstellt',

    // fahrzeugbezogen
    A401: 'Sicherheitsprüfung aktualisiert',
    A402: 'Inspektionsdatum aktualisiert',
    A403: 'Achsentyp aktualisiert',
    A404: 'Kilometerzähler aktualisiert',
    A405: 'Austausch durchgeführt',
    A406: 'Reifenkontrolle durchgeführt',
    A407: 'Prüfung',
    A408: 'Größe des Lenkradreifens aktualisiert',
    A409: 'Größe des Antriebsreifens aktualisiert',
    A410: 'Lkw/Anhänger gekoppelt',
    A411: 'Lkw/Anhänger abgekuppelt',
    A412: 'OCR für Servicebeleg durchgeführt',
    A413: 'Name aktualisiert',
    A414: 'Autokennzeichen aktualisiert',
    A415: 'VIN aktualisiert',
    A416: 'Fahrgestellnummer aktualisiert',
    A417: 'Interne Gruppierung aktualisiert',
    A418: 'Luftdruck aktualisiert',
    A419: 'Flotte geändert',

    // allgemein
    A501: 'Ressource gelöscht',
    A502: 'Konfiguration',

    // LKW/Busbezogen
    A601: 'Tankinhalt aktualisiert',

    // anhängerbezogen
    A701: 'Anhänger-Liftachse aktualisiert',
    A702: 'Reifengröße des Anhängers aktualisiert',
  },
  pl: {
    // związane z kontem
    A101: 'Utworzono konto',
    A102: 'Konto zweryfikowane',
    A103: 'Konto zablokowane',

    // związane z oponami i pojazdem
    A201: 'Aktualizacja opon',
    A202: 'Opony zamontowane',
    A203: 'Opony zdemontowane',
    A204: 'Opony zmienione',
    A205: 'Zaktualizowano głębokość bieżnika',
    A206: 'Zaktualizowano ciśnienie',
    A207: 'Zaktualizowano przebieg',
    A208: 'Zaktualizowano identyfikator opony',
    A209: 'Aktualizacja bieżnika',
    A210: 'Zaktualizowano markę',
    A211: 'Zaktualizowano linię produktów',
    A212: 'Zaktualizowano RFID opony',
    A213: 'Zaktualizowano rowki bieżnika',
    A214: 'Zaktualizowano głębokość bieżnika rowków',

    // związane z flotą
    A301: 'Utworzono ciężarówki',
    A302: 'Utworzono przyczepy',
    A303: 'Utworzono autobusy',
    A304: 'Utworzono opony',
    A305: 'Utworzono floty',

    // związane z pojazdem
    A401: 'Zaktualizowano kontrolę bezpieczeństwa',
    A402: 'Zaktualizowano datę kontroli',
    A403: 'Zaktualizowano typ osi',
    A404: 'Zaktualizowano licznik kilometrów',
    A405: 'Wykonano wymianę',
    A406: 'Przeprowadzono kontrolę opon',
    A407: 'Audyt',
    A408: 'Zaktualizowano rozmiar opony sterującej',
    A409: 'Zaktualizowano rozmiar opony napędowej',
    A410: 'Truck/Trailer coupled (sprzężona ciężarówka/przyczepa)',
    A411: 'Ciężarówka/przyczepa odłączona',
    A412: 'OCR wykonano dla dokumentu serwisowego',
    A413: 'Aktualizacja nazwy',
    A414: 'Zaktualizowano numer tablicy rejestracyjnej',
    A415: 'Zaktualizowano numer VIN',
    A416: 'Zaktualizowano numer podwozia',
    A417: 'Zaktualizowano grupę wewnętrzną',
    A418: 'Aktualizacja ciśnienia powietrza',
    A419: 'Zmieniono flotę',

    // ogólne
    A501: 'Zasób usunięty',
    A502: 'Konfiguracja',

    // związane z ciężarówkami/autobusami
    A601: 'Zaktualizowano pojemność zbiornika',

    // związane z przyczepami
    A701: 'Zaktualizowano oś podnoszoną przyczepy',
    A702: 'Zaktualizowano rozmiar opon przyczepy',
  },
};

export const decodeSource = {
  S01: 'Dashboard',
  S02: 'OCR Bot',
  S03: 'Android App',
  S04: 'Apple App',
  S05: 'Scheduler Bot',
};

import React, { Fragment, useState } from 'react';
import {
  Badge,
  Box,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { parse } from 'json2csv';
import Downloads from 'components/Downloads';
import PropTypes from 'prop-types';
import GetAppIcon from '@mui/icons-material/GetApp';
import Loading from 'components/Loading';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    details: {
      fontSize: 'clamp(16px, 1vw, 2.5rem)',
      fontWeight: 400,
    },
    load: {
      backgroundColor: '#FFFFFF',
      width: 'fit-content',
      height: 'fit-content',
      padding: 20,
    },
    paragraph: {
      padding: 0,
      margin: 0,
      fontSize: 'clamp(16px, 1vw, 2.5rem)',
      fontWeight: 400,
    },
    hr: {
      color: theme.palette.primary.light,
      width: '100%',
    },
    motionDiv: {
      width: '80%',
      maxHeight: '80%',
    },
    accordionContainer: {
      width: '100%',
      maxHeight: 600,
      overflowY: 'scroll',
    },
  };
});

const TelematicsModal = ({ open, close, resource, vehicles, regTracked }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);
  const getVehicleName = entry => {
    const x = vehicles?.find(vehicle => vehicle.vehicle_id === entry);
    if (x) {
      return x?.name;
    }
    return null;
  };

  const downloadVehicleListHandler = fileType => {
    const unregistered = resource.unregistered_and_tracked;
    const trailerTelematics = ['sensolus', 'schmitz', 'krone'];

    const options = trailerTelematics.includes(resource.protocol)
      ? trailerTemplate
      : truckTemplate;
    const temp = JSON.parse(JSON.stringify(unregistered)).map(item => {
      let y = item;
      y.name = item.telematics_managed_vehicle_name;
      y.reg_plate =
        item.telematics_managed_regplate !== ''
          ? item.telematics_managed_regplate
          : 'N/A';

      delete y.telematics_managed_regplate;
      delete y.telematics_managed_vehicle_name;
      return { ...y, ...options };
    });
    if (fileType === 'xlsx') {
      const unregisteredVehicles = XLSX.utils.json_to_sheet(temp);

      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, unregisteredVehicles, '1');

      XLSX.writeFile(workbook, `unregistered_vehicles.xlsx`);
    } else {
      const headers = Object.keys(temp[0]);
      const csv = parse(temp, headers);
      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `unregistered_vehicles.csv`;
      a.click();
    }
    setOpenDialog(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => close(false)}
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
      >
        <motion.div
          initial={{ y: -100 }}
          animate={{ y: 0 }}
          exit={{ y: 150 }}
          className={classes.motionDiv}
        >
          <Box
            display='flex'
            justifyContent='center'
            className={classes.container}
            data-hook='telematics-modal'
          >
            <Box
              width='100%'
              bgcolor='white'
              padding='20px'
              style={{ overflowY: 'hidden' }}
            >
              <Typography variant='h5' style={{ marginBottom: 20 }}>
                {t('telematicsModal.title')}
              </Typography>

              <Grid container spacing={3}>
                <Grid item lg={4}>
                  {resource !== null && (
                    <Badge
                      badgeContent={resource?.registered_and_tracked?.length}
                      color='primary'
                      max={1000}
                    >
                      <Typography variant='h6'>REGISTERED & TRACKED</Typography>
                    </Badge>
                  )}

                  {vehicles ? (
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                      my={2}
                      style={{
                        maxHeight: 200,
                        overflowY: 'scroll',
                        scrollBehavior: 'smooth',
                      }}
                    >
                      {resource?.registered_and_tracked?.length > 0 ? (
                        regTracked.map(vehicle => (
                          <Fragment key={vehicle}>
                            <>
                              <p className={classes.paragraph}>
                                Vehicle name: <span>{vehicle.name}</span>
                              </p>
                              <p className={classes.paragraph}>
                                Vehicle ID: <span>{vehicle.vehicle_id}</span>
                              </p>
                              <hr className={classes.hr} />
                            </>
                          </Fragment>
                        ))
                      ) : (
                        <span>{t('telematicsModal.noVehicle1')}</span>
                      )}
                    </Box>
                  ) : (
                    <Loading />
                  )}
                </Grid>
                <Grid item lg={4}>
                  {resource && (
                    <Badge
                      badgeContent={resource?.registered_and_untracked?.length}
                      max={1000}
                      color='primary'
                    >
                      <Typography variant='h6'>
                        REGISTERED & UNTRACKED
                      </Typography>
                    </Badge>
                  )}
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    my={2}
                    style={{
                      maxHeight: 200,
                      overflowY: 'scroll',
                      scrollBehavior: 'smooth',
                    }}
                  >
                    {resource?.registered_and_untracked?.length > 0 ? (
                      resource?.registered_and_untracked?.map(vehicle => (
                        <Fragment key={vehicle}>
                          <p className={classes.paragraph}>
                            Vehicle name: <span>{getVehicleName(vehicle)}</span>
                          </p>
                          <p className={classes.paragraph}>
                            Vehicle ID: <span>{vehicle}</span>
                          </p>
                          <hr className={classes.hr} />
                        </Fragment>
                      ))
                    ) : (
                      <span>{t('telematicsModal.noVehicle2')}</span>
                    )}
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  {resource && (
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      width='100%'
                    >
                      <Badge
                        badgeContent={
                          resource?.unregistered_and_tracked?.length
                        }
                        max={1000}
                        color='primary'
                      >
                        <Typography variant='h6'>
                          UNREGISTERED & TRACKED
                        </Typography>
                      </Badge>
                      {resource.unregistered_and_tracked?.length > 0 && (
                        <Tooltip title={t('telematicsModal.downloadMsg')}>
                          <IconButton
                            color='primary'
                            size='small'
                            data-hook='download-unreg-tracked'
                            onClick={event => {
                              event.stopPropagation();
                              setOpenDialog(true);
                            }}
                          >
                            <GetAppIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    my={2}
                    style={{
                      maxHeight: 200,
                      overflowY: 'scroll',
                      scrollBehavior: 'smooth',
                    }}
                  >
                    {resource?.unregistered_and_tracked?.length > 0 ? (
                      resource?.unregistered_and_tracked?.map((vehicle, i) => (
                        <Fragment key={i}>
                          <p className={classes.paragraph}>
                            Vehicle name:{' '}
                            <span>
                              {vehicle?.telematics_managed_vehicle_name}
                            </span>
                          </p>
                          <p className={classes.paragraph}>
                            Registration Number:{' '}
                            <span>
                              {vehicle?.telematics_managed_regplate ?? 'nil'}
                            </span>
                          </p>
                          <hr className={classes.hr} />
                        </Fragment>
                      ))
                    ) : (
                      <span>{t('telematicsModal.noVehicle2')}</span>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </motion.div>
      </Modal>
      <Downloads
        t={t}
        dialogOpen={openDialog}
        setDialogOpen={setOpenDialog}
        handleDownload={downloadVehicleListHandler}
      />
    </>
  );
};

export default TelematicsModal;

TelematicsModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  resource: PropTypes.object,
  vehicles: PropTypes.array,
};
const truckTemplate = {
  vin: '',
  fleet_name: '',
  fleet_location: '',
  internal_grouping: '',
  tank_capacity: '',
  'odometer_reading (optional)': '',
  'inspection_date (optional)': '',
  'safety_check_date (optional)': '',
};
const trailerTemplate = {
  chassis_number: '',
  fleet_name: '',
  fleet_location: '',
  internal_grouping: '',
  'odometer_reading (optional)': '',
  'inspection_date (optional)': '',
  'safety_check_date (optional)': '',
};

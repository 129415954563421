import { Grid, IconButton, Paper, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import { GiCheckMark } from 'react-icons/gi';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { darken } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { updateGuideSelection } from 'redux/users/ducks';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      minWidth: 500,
      maxWidth: 600,
      padding: '0rem 1rem',
    },
    gridItem: {
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      transition: 'transform 0.3s linear',
      cursor: 'pointer',
      '&:hover': {
        transform: 'scale(1.02)',
      },
    },
    title: {
      color: theme.palette.primary.main,
    },
    icon: {
      background: theme.palette.primary.main,
      '&:hover': {
        background: darken(theme.palette.primary.main, 0.5),
      },
      width: 40,
      height: 40,
      color: '#fff',
    },
  };
});

const TaskCard = ({ id, link, icon, isComplete, hide }) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClick = (e, selected) => {
    dispatch(updateGuideSelection(selected));
  };
  return (
    <Grid item xs={12} display={hide && 'none'}>
      <Link
        to={link || '/'}
        style={{ textDecoration: 'none' }}
        onClick={e => handleClick(e, id)}
        id={id}
      >
        <Paper className={classes.gridItem}>
          <Box width={50}>{icon || ''}</Box>
          <Box
            flex={'70%'}
            display={'flex'}
            flexDirection={'column'}
            gap={'10px'}
          >
            <Typography className={classes.title}>
              {t(`guidesPage.title.${id}`)}
            </Typography>
            <Typography>{t(`guidesPage.description.${id}`)}</Typography>
          </Box>

          {!isComplete ? (
            <IconButton className={classes.icon}>
              <ChevronRightIcon />
            </IconButton>
          ) : (
            <IconButton>
              <GiCheckMark fill={theme.palette.primary.main} />
            </IconButton>
          )}
        </Paper>
      </Link>
    </Grid>
  );
};
export default TaskCard;

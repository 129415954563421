import React, { useState } from 'react';
import { Button, Card, Chip, Typography } from '@mui/material';
import moment from 'moment';
import notes from 'assets/changelog/notes.json';
import HiddenContent from './HiddenContent';
import { makeStyles } from 'tss-react/mui';

const gen_width = '55%';

const useStyles = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      padding: theme.spacing(5),
      backgroundColor: '#FFFFFF',
    },
    top: {
      display: 'flex',
      gap: 10,
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
      },
    },
    title: {
      fontWeight: 600,
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      width: gen_width,
      padding: 10,
      borderRadius: '2px',
      boxShadow:
        'rgb(31 31 31 / 17%) 0px 0px 0px 1px inset, rgb(31 31 31 / 4%) 0px 1px 2px 0px',
    },
    version: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f7f9f9',
      padding: 3,
      borderRadius: 2,
      marginBottom: 5,
      width: gen_width,
      fontSize: 18,
    },
    foot: {
      opacity: 0.6,
    },
    btn: {
      width: gen_width,
      textTransform: 'capitalize',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    divider: {
      marginBottom: 10,
    },
    expand: {
      cursor: 'pointer',
      alignSelf: 'flex-end',
      '&:hover': {
        background: '#fafafa',
      },
    },
  };
});

const Updates = () => {
  const { classes } = useStyles();
  const [limit, setLimit] = useState(10);

  return (
    <Card className={classes.container}>
      {Object.entries(notes)
        .slice(0, limit)
        .map((note, i) => (
          <div key={i}>
            <div className={classes.version}>{note[0]}</div>

            <div className={classes.box}>
              <>
                <div className={classes.top}>
                  <Typography className={classes.title} variant='h6'>
                    {note[1][0].title}
                  </Typography>
                  <Chip
                    label={note[1][0].type}
                    style={{
                      backgroundColor:
                        note[1][0].type === 'Feature' ? '#e0f7ed' : '',
                      color:
                        note[1][0].type === 'Feature' ? '#006338' : 'inherit',
                      borderRadius: '3px',
                    }}
                  />
                </div>
                <div className={classes.desc}>
                  <p>{note[1][0].details}</p>
                </div>
                <span className={classes.foot}>
                  {moment(note[1][0].date).format('dddd Do MMM, YYYY')}
                </span>
              </>

              <HiddenContent classes={classes} note={note} />
            </div>
          </div>
        ))}
      {limit < Object.keys(notes).length && (
        <Button
          className={classes.btn}
          onClick={() => setLimit(latest => latest + 10)}
        >
          See More
        </Button>
      )}
    </Card>
  );
};

export default Updates;

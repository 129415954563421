import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import error from 'assets/images/404.png';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      width='100%'
      height='100vh'
      bgcolor='#fff'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Box
        height='50%'
        display='flex'
        justifyContent='center'
        alignItems='center'
        flexDirection='column'
        flexGrow={1}
      >
        <img src={error} alt='' />
        <Typography variant='h4' gutterBottom align='center'>
          There's nothing here
        </Typography>
        <Typography gutterBottom align='center'>
          We were unable to find that page. It appears you have lost your way.
        </Typography>
        <Button
          variant='text'
          color='primary'
          onClick={() => {
            navigate('/home');
          }}
        >
          Take me Home
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;

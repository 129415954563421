import { createTheme } from '@mui/material/styles';
import default_logo from 'assets/images/app_logo.svg';
import krone_logo from 'assets/images/krone_logo.png';
import bestDrive_logo from 'assets/images/BestDrive.svg';
import bestDrive_alt from 'assets/images/BestDriveBlack.svg';
import krone_favicon from 'assets/images/krone_flaticon.png';
import darkScrollbar from '@mui/material/darkScrollbar';
export const default_theme = createTheme({
  logo: default_logo,
  favicon: default_logo,
  site_title: 'CO2OPT Reifenmanagement',
  components: {
    MuiCssBaseline: {
      styleOverrides: themeParam => ({
        body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
        '*::-webkit-scrollbar': {
          width: '5px', // Override scrollbar width
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#69A628', // Override scrollbar thumb color
          borderRadius: '2px',
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: '#E4EFEF', // Override scrollbar track color
        },
      }),
    },
  },

  palette: {
    common: {
      black: '#000',
      white: '#fff',
      yellow: '#F7E993',
      main: '#F7E993',
    },
    primary: {
      //Co2opt theme
      light: '#67A426OD',
      main: '#69A628',
      alt: '#d2ddc7',
      alt1: '#376604',
      link: '#3577DC',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D10011',
      alt: '#860510',

      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },

    boxShadow: '0px 9.59195px 35.9698px rgba(0, 0, 0, 0.04)',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Open Sans'],
    fontWeightBold: '1000',
    fontWeightLight: '400',
    fontWeightRegular: '600',
    fontWeightMedium: '800',
    h1: {
      fontSize: '6.857rem',
      lineHeight: '8rem',
    },
    h2: {
      fontSize: '4.286rem',
      lineHeight: '5.143rem',
    },
    h3: {
      fontSize: '3.429rem',
      lineHeight: '4rem',
    },
    h4: {
      fontSize: '2.429rem',
      lineHeight: '2.571rem',
    },
    h5: {
      fontSize: '1.714rem',
      lineHeight: '1.714rem',
    },
    h6: {
      fontSize: '1.0rem',
      lineHeight: '1.714rem',
    },
    caption: {
      fontSize: '0.857rem',
      lineHeight: '0.857rem',
    },
    button: {
      fontSize: '1rem',
      lineHeight: '1.75rem',
    },
    overline: {
      fontSize: '0.714rem',
      lineHeight: '1.143rem',
    },
    subtitle1: { fontSize: '1.25rem', lineHeight: '1.714rem' },
    subtitle2: { fontSize: '1.5rem', lineHeight: '1.714rem' },
  },
  zIndex: {
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  shape: {
    borderRadius: 8,
  },
});

export const korne_theme = createTheme({
  logo: krone_logo,
  favicon: krone_favicon,
  site_title: 'Krone',
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    primary: {
      // dark: will be calculated from palette.primary.main,
      light: '#78B5EC',
      main: '#08639a',
      alt: '#b7cdda',
      alt1: '#053f63',

      contrastText: '#fff',
    },
    secondary: {
      main: '#D10011',
      alt: '#860510',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },
    boxShadow: '0px 9.59195px 35.9698px rgba(0, 0, 0, 0.04)',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Open Sans'],
    fontWeightBold: '1000',
    fontWeightLight: '400',
    fontWeightRegular: '600',
    fontWeightMedium: '800',
    h1: {
      fontSize: '6.857rem',
      lineHeight: '8rem',
    },
    h2: {
      fontSize: '4.286rem',
      lineHeight: '5.143rem',
    },
    h3: {
      fontSize: '3.429rem',
      lineHeight: '4rem',
    },
    h4: {
      fontSize: '2.429rem',
      lineHeight: '2.571rem',
    },
    h5: {
      fontSize: '1.714rem',
      lineHeight: '1.714rem',
    },
    h6: {
      fontSize: '1.0rem',
      lineHeight: '1.714rem',
    },
    caption: {
      fontSize: '0.857rem',
      lineHeight: '0.857rem',
    },
    button: {
      fontSize: '1rem',
      lineHeight: '1.75rem',
    },
    overline: {
      fontSize: '0.714rem',
      lineHeight: '1.143rem',
    },
  },
});

export const best_drive_theme = createTheme({
  logo: bestDrive_logo,
  logo_alt: bestDrive_alt,
  favicon: bestDrive_logo,
  site_title: 'BestDrive by Continental',
  components: {
    MuiCssBaseline: {
      styleOverrides: themeParam => ({
        body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
        '*::-webkit-scrollbar': {
          width: '5px', // Override scrollbar width
        },
        '*::-webkit-scrollbar-thumb': {
          background: '#ffa500', // Override scrollbar thumb color
          borderRadius: '2px',
        },
        '*::-webkit-scrollbar-track': {
          backgroundColor: '#E4EFEF', // Override scrollbar track color
        },
      }),
    },
  },

  palette: {
    common: {
      black: '#000',
      white: '#fff',
      yellow: '#F7E993',
      main: '#F7E993',
    },
    primary: {
      //Co2opt theme
      light: '#67A4260D',
      main: '#ffa500',
      alt: '#ffe6b3',
      alt1: '#376604',
      link: '#3577DC',
      contrastText: '#fff',
    },
    secondary: {
      main: '#D10011',
      alt: '#860510',

      // dark: will be calculated from palette.secondary.main,
      contrastText: '#fff',
    },

    boxShadow: '0px 9.59195px 35.9698px rgba(0, 0, 0, 0.04)',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: ['Helvetica Neue'],
    fontWeightBold: '1000',
    fontWeightLight: '400',
    fontWeightRegular: '600',
    fontWeightMedium: '800',
    h1: {
      fontSize: '6.857rem',
      lineHeight: '8rem',
    },
    h2: {
      fontSize: '4.286rem',
      lineHeight: '5.143rem',
    },
    h3: {
      fontSize: '3.429rem',
      lineHeight: '4rem',
    },
    h4: {
      fontSize: '2.429rem',
      lineHeight: '2.571rem',
    },
    h5: {
      fontSize: '1.714rem',
      lineHeight: '1.714rem',
    },
    h6: {
      fontSize: '1.0rem',
      lineHeight: '1.714rem',
    },
    caption: {
      fontSize: '0.857rem',
      lineHeight: '0.857rem',
    },
    button: {
      fontSize: '1rem',
      lineHeight: '1.75rem',
    },
    overline: {
      fontSize: '0.714rem',
      lineHeight: '1.143rem',
    },
    subtitle1: { fontSize: '1.25rem', lineHeight: '1.714rem' },
    subtitle2: { fontSize: '1.5rem', lineHeight: '1.714rem' },
  },
  zIndex: {
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  shape: {
    borderRadius: 8,
  },
});

import { Chip, Skeleton } from '@mui/material';
import { VEHICLE } from './string_utils';

const formatterUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const formatterNonEU = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const formatterEUR = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 0,
});

const formatterEURInDecimal = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
});

const formatterNonEUInDecimal = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'EUR',
  maximumFractionDigits: 2,
});
const formatToEurNumberInDecimal = new Intl.NumberFormat('de-DE', {
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const currencyFormatUSD = (value, isDecimalValue) => {
  /* $2,500.00 */
  return formatterUSD.format(value);
};

export const currencyFormatNonEU = (value, isDecimalValue) => {
  /* $2,500.00 */
  if (value / 100 < 1) return formatterNonEUInDecimal.format(value);
  return formatterNonEU.format(value);
};

export const currencyFormatEUR = (value, isDecimalValue) => {
  /* 2.500,00 Euro */
  if (value / 100 < 1) return formatterEURInDecimal.format(value);
  return formatterEUR.format(value);
};
//Formats number specifically
export const convertToEUR = value => {
  return formatToEurNumberInDecimal.format(value);
};

export const LOADING_PLACEHOLDER_TYPES = {
  LINE: 'LINE',
  CARD_TEXT: 'CARD_TEXT',
};

export const LoadingPlaceholder = props => {
  const { type, height = 20, width = '100%', count = 1 } = props;
  // Available Varient
  // <Skeleton variant="text" />
  // <Skeleton variant="circular" width={40} height={40} />
  // <Skeleton variant="rectangular" width={210} height={118} />
  if (type === LOADING_PLACEHOLDER_TYPES.LINE)
    return Array(count)
      .fill('')
      .map((d, i) => (
        <Skeleton
          key={`line${i}`}
          animation='wave'
          height={height}
          width={width}
        />
      ));
};

export const checkTireConfig = vehicle => {
  if (vehicle.axle_type !== null) {
    // let emptyPositionCount = 0;
    // if (vehicle.mounted_tires) {
    //   vehicle.mounted_tires?.forEach(tire => {
    //     if (tire.position === null) {
    //       emptyPositionCount++;
    //     }
    //   });
    // }
    // if (vType === VEHICLE.vehicle_type.TRUCK) {
    //   ['steer', 'drive'].forEach(item => {
    //     for (let i = 0; i < axleType[item]; i++) {
    //       const left_side = vehicle?.mounted_tires[item]?.[i]?.['left'];
    //       const right_side = vehicle?.mounted_tires[item]?.[i]?.['right'];
    //       if (left_side?.length === 0 || right_side?.length === 0) emptyAxle++;
    //     }
    //   });
    // } else if (vType === VEHICLE.vehicle_type.TRAILER) {
    //   let temp = Object.values(axleType);
    //   for (let i = 0; i < temp.length; i++) {
    //     const left_side = vehicle?.mounted_tires[vType]?.[i]?.['left'];
    //     const right_side = vehicle?.mounted_tires[vType]?.[i]?.['right'];

    //     if (left_side?.length === 0 || right_side?.length === 0) emptyAxle++;
    //   }
    // }
    return vehicle.configuration === 'Fully configured' ? true : false;
  }
};

export const epochToDate = epoch => {
  return new Date(epoch * 1000).toDateString();
};

export const currentDate = () => {
  const date = new Date();

  const currentDay = String(date.getDate()).padStart(2, '0');

  const currentMonth = String(date.getMonth() + 1).padStart(2, '0');

  const currentYear = date.getFullYear();

  const currentDate = `${currentDay}.${currentMonth}.${currentYear}`;

  return currentDate;
};
export const renderStatus = (status, size = 'small') => {
  if (status === 'AL') {
    return <Chip label='RL' color='secondary' size={size} />;
  } else if (status === 'RL') {
    return <Chip label='AL' color='primary' size={size} />;
  } else {
    return;
  }
};
export const access_type_map = {
  WEB_AND_MOBILE: 'Web & Mobile',
  WEB_ONLY: 'Web Only',
  MOBILE_ONLY: 'Mobile Only',
};

export const vehicleTypes = [
  VEHICLE.vehicle_type.TRAILER,
  VEHICLE.vehicle_type.TRUCK,
  VEHICLE.vehicle_type.BUS,
];

import { co2OptBaseApi } from '../axios.config';

const customer_url_common_routes = '/customer';
const reports_url_common_routes = `${customer_url_common_routes}/reports`;
const url_analytics_route = '/tire-analytics';
const user_url_common_routes = '/user';
const custom_implementations_route = '/custom';
export const fleetCsvUpload = async (payload, token) => {
  return await co2OptBaseApi
    .post(`${customer_url_common_routes}/fleet`, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10046', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getProfileStatus = async () => {
  return await co2OptBaseApi
    .get(`${customer_url_common_routes}/profile-completion`)
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};
export const fleetOverview = async access_token => {
  return await co2OptBaseApi
    .get(`${customer_url_common_routes}/profile`)
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getSavingsEstimate = async isSavingsPage => {
  return await co2OptBaseApi
    .get(`${customer_url_common_routes}/savings-estimate`, {
      params: {
        home: isSavingsPage,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getFleets = async () => {
  return await co2OptBaseApi
    .get(`${customer_url_common_routes}/fleets`)
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getVehicle = async (vehicleId, vehicle_type) => {
  return await co2OptBaseApi
    .get(`${customer_url_common_routes}/vehicle`, {
      params: {
        vehicle_id: vehicleId,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10050', error);
      if (error.response) {
        return error.response;
      }
    });
};
export const getTrailer = async (access_token, fleetId, vehicleId) => {
  return await co2OptBaseApi
    .get(`${customer_url_common_routes}/trailer`, {
      params: {
        fleet_id: fleetId,
        vehicle_id: vehicleId,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10051', error);
    });
};

export const getTirePriceFilter = async access_token => {
  return await co2OptBaseApi
    .get(`${customer_url_common_routes}/tire-price-filter`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10057', error);
    });
};

export const getVehiclesForFleet = async (
  payload,
  access_token,
  allVehicle,
  page_no,
  per_page
) => {
  return await co2OptBaseApi
    .post(
      `${customer_url_common_routes}/vehicles`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
        params: {
          page_no,
          per_page,
          allVehicle,
        },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getVehicleOverviewDetails = async () => {
  return await co2OptBaseApi
    .get('/customer/profile-overview?home=true')
    .then(res => res)
    .catch(err => {
      throw new Error(err);
    });
};
export const getOverviewDetails = async ({
  accessing,
  tire_location,
  vehicle_location,
  page_no,
  search_string,
  per_page,
}) => {
  return await co2OptBaseApi
    .get('/customer/profile-overview', {
      params: {
        accessing,
        tire_location,
        vehicle_location,
        page_no,
        per_page,
        search_string,
      },
    })
    .then(res => res)
    .catch(err => {
      throw new Error(err);
    });
};

export const updatePressure = async payload => {
  return await co2OptBaseApi
    .post(`/customer/optimum-air-pressure`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E10056', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const updateVehicle = async (payload, vehicleId) => {
  return await co2OptBaseApi
    .post(`${customer_url_common_routes}/vehicle`, payload, {
      params: {
        vehicle_id: vehicleId,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10052', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getDailyReport = async id => {
  return await co2OptBaseApi
    .get(`${reports_url_common_routes}/day-wise-usage`, {
      params: {
        vehicle_id: id,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20025', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const updateRetreadStrategy = async (payload, vehicleId) => {
  return await co2OptBaseApi
    .post(`${customer_url_common_routes}/vehicle-retread-strategy`, payload, {
      params: {
        vehicle_id: vehicleId,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20022', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getTireRecommendations = async (access_token, vehicleId) => {
  return await co2OptBaseApi
    .get(`${reports_url_common_routes}/tire-recommendation`, {
      params: {
        vehicle_id: vehicleId,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20024', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getFilteredTireRecommendations = async (
  payload,
  on_demand = false
) => {
  return await co2OptBaseApi
    .post(`${customer_url_common_routes}/tire-recommendations`, payload, {
      params: { on_demand },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20024', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const deleteVehicle = async (access_token, vehicle) => {
  return await co2OptBaseApi
    .delete(
      `${customer_url_common_routes}/vehicle?vehicle_id=${vehicle.vehicle_id}`
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E20022', error);
    });
};
export const deleteFleet = async (access_token, fleetId) => {
  return await co2OptBaseApi
    .delete(`${customer_url_common_routes}/fleet`, { data: fleetId })
    .then(res => res)
    .catch(function (error) {
      console.error('E20022', error);
    });
};

export const assignAccessLevel = (token, payload) => {
  return co2OptBaseApi
    .put(`${customer_url_common_routes}/white-list-users`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E20040', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};

export const deleteAssignedResources = (token, payload) => {
  return co2OptBaseApi
    .delete(`${customer_url_common_routes}/white-list-users`, {
      data: payload,
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20041', error);
      if (error.response) {
        return {
          status: error.response.status,
          message: error.response.data.message,
        };
      }
    });
};

export const getTireInventoryData = (
  fleet_id,
  location,
  page_no,
  per_page,
  payload
) => {
  let updatedPayload = payload || {};
  Object.keys(updatedPayload).forEach(key => {
    if (updatedPayload[key] === null) {
      delete updatedPayload[key];
    }
  });
  return co2OptBaseApi
    .post(
      `${customer_url_common_routes}/tires`,
      {
        fleet_id,
        ...(Object.values(updatedPayload).some(item => item) && updatedPayload),
      },
      {
        params: { location, page_no, per_page },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E20044', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getAvailableTires = (location, isMountable, fleet_id) => {
  return co2OptBaseApi
    .post(
      `${customer_url_common_routes}/tires`,
      { fleet_id },
      {
        params: { isMountable, location },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E20044', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getFleetCheckRemainderFrequency = access_token => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/customer-overview`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20044', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const updateFleetCheckFrequency = (access_token, days) => {
  return co2OptBaseApi
    .put(
      `${customer_url_common_routes}/customer-overview`,
      {
        fleetcheck_reminder_days: days,
      },
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E20044', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getFilteredTireInventoryData = (fleet_id, location) => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/tire-filter`, {
      params: { fleet_id, location },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20044', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getFilteredFleetVehicles = fleet_id => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/vehicle-filters`, {
      params: { fleet_id },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20044', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const readTire = tireId => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/tire/${tireId}`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20068', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const addTire = (token, payload) => {
  return co2OptBaseApi
    .post(`${customer_url_common_routes}/tire`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E20046', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const checkTire = id => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/tire-check/${id}`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20048', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const updateTire = (id, payload) => {
  return co2OptBaseApi
    .put(`${customer_url_common_routes}/tire/${id}`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E20055', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const deleteTire = (token, id) => {
  return co2OptBaseApi
    .delete(`${customer_url_common_routes}/tire/${id}`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20050', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const moveToStorage = (token, id) => {
  return co2OptBaseApi
    .put(`${customer_url_common_routes}/tire-store/${id}`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20056', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const tireWearForecast = payload => {
  return co2OptBaseApi
    .post(`${url_analytics_route}/tire-wear-forecast`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E20061', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const tireDatabase = () => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/knowledge/tires`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20063', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getTireEventHistory = id => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/tire-events`, {
      params: { system_tire_id: id },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20044', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getAllFleetTireEventHistory = fleetId => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/tire-events`, {
      params: { fleet_id: fleetId },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20073', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const fetchSingleTireEventHistory = system_tire_id => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/tire-events`, {
      params: { system_tire_id },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20073', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getAllReports = payload => {
  return co2OptBaseApi
    .post(`${customer_url_common_routes}/reports`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E20074', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const linkTrailer = (truck_id, trailer_id, event_date) => {
  return co2OptBaseApi
    .put(`${customer_url_common_routes}/couple-truck-trailer`, {
      event_date: event_date,
      trailer_id: trailer_id,
      truck_id: truck_id,
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20081', error);
      if (error.response) {
        return error.response;
      }
    });
};
export const detachTrailer = (truck_id, trailer_id, event_date) => {
  return co2OptBaseApi
    .put(`${customer_url_common_routes}/decouple-truck-trailer`, {
      event_date: event_date,
      trailer_id: trailer_id,
      truck_id: truck_id,
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20084', error);
      if (error.response) {
        return error.response;
      }
    });
};
export const revertEvent = system_id => {
  return co2OptBaseApi
    .post(
      `${customer_url_common_routes}/revert-tire-event`,
      {},
      {
        params: { system_tire_id: system_id },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E20082', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getFleetTasks = resource_id => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/task`, {
      // params: { resource_id },
      params: { page_no: 0 },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20086', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const resolveTask = (task_id, payload) => {
  return co2OptBaseApi
    .post(`${customer_url_common_routes}/task_rectify`, payload, {
      params: { task_id },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20100', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getEventHistory = page_no => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/app-events-log`, {
      params: { page_no },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20088', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getTableauData = () => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/tableau`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20098', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getTableauToken = () => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/tableau/token`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20108', error);
      if (error.response) {
        return error.response;
      }
    });
};
export const getNotificationPreferences = () => {
  return co2OptBaseApi
    .get(`${customer_url_common_routes}/alert-preferences`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20098', error);
      if (error.response) {
        return error.response;
      }
    });
};
export const changeNotificationPreferences = payload => {
  return co2OptBaseApi
    .put(`${customer_url_common_routes}/alert-preferences`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E20104', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const getSubscribedUsers = () => {
  return co2OptBaseApi
    .get(`${user_url_common_routes}/email-subscription`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20140', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const sendEmailToSelectedUsers = payload => {
  return co2OptBaseApi
    .post(`${user_url_common_routes}/email-subscription`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E20140', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const uploadServiceReport = (choice, files) => {
  return co2OptBaseApi
    .post(`/messenger/ocr/${choice}`, files)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
      if (err.response) {
        return err.response;
      }
    });
};

export const ourTireRecommendations = async fleets => {
  let result;
  const fleetsResponse = fleets?.map(fleet =>
    getVehiclesForFleet(fleet.fleet_id)
  );
  result = await Promise.allSettled(fleetsResponse);
  let tyreRecommendations = {};
  for (let i = 0; i < result.length; i++) {
    tyreRecommendations = {
      ...tyreRecommendations,
      [fleets[i].fleet_id]: result[i].value,
    };
  }
  return tyreRecommendations;
};

export const getMileage = (tire, data) => {
  if (data?.length === 0 || !data) {
    return tire?.last_km_driven ?? 0;
  }
  let startTimestamp = new Date(tire?.mount_date).getTime();
  let latest = new Date(tire?.last_event_date).getTime();
  // let latest = new Date(data[0]?.timestamp * 1000);
  let filteredArray = data.filter(obj => {
    let objTimestamp = new Date(obj?.timestamp * 1000);
    return objTimestamp >= startTimestamp && objTimestamp <= latest;
  });

  let totalMileage = filteredArray.reduce(
    (acc, obj) => acc + obj.total_distance_driven,
    0
  );

  return Math.round(totalMileage);
};

export const deleteTask = task_id => {
  return co2OptBaseApi
    .delete(`${customer_url_common_routes}/task`, {
      params: { task_id },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20140', error);
      if (error.response) {
        return error.response;
      }
    });
};
export const getVehicleStatus = async () => {
  return await co2OptBaseApi
    .get(`${custom_implementations_route}/get-status`)
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};
export const updateVehicleStatus = async (payload, vehicle_id) => {
  return await co2OptBaseApi
    .put(
      `${custom_implementations_route}/update-status`,
      { metadata: payload },
      {
        params: {
          vehicle_id: vehicle_id,
        },
      }
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const bulkDelete = async payload => {
  return await co2OptBaseApi
    .put(`${customer_url_common_routes}/tire/bulk-update`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E21047', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const tireOcr = payload => {
  return co2OptBaseApi
    .put(`${customer_url_common_routes}/tire-ocr`, payload)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      console.error(err);
      if (err.response) {
        return err.response;
      }
    });
};

export const getFilterTirePrices = async (access_token, payload) => {
  return await co2OptBaseApi
    .post(`${customer_url_common_routes}/get-tire-price`, payload, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const deleteTirePrices = async (access_token, tireId) => {
  return await co2OptBaseApi
    .delete(`${customer_url_common_routes}/tire-price/${tireId}`)
    .then(res => res)
    .catch(function (error) {
      console.error('E20022', error);
    });
};

export const updateTirePrices = (access_token, tireId, payload) => {
  return co2OptBaseApi
    .put(`${customer_url_common_routes}/tire-price/${tireId}`, payload, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then(res => res)
    .catch(function (error) {
      console.error('E20044', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const fetchRecData = async () => {
  return await co2OptBaseApi
    .get(`${customer_url_common_routes}/mission-profile-recommendations`)
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const moveVehicles = async payload => {
  return await co2OptBaseApi
    .post(`${customer_url_common_routes}/transfer-fleet`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E21052', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const createTirePrices = async payload => {
  return await co2OptBaseApi
    .post(`${customer_url_common_routes}/tire-price`, payload)
    .then(res => res)
    .catch(function (error) {
      console.error('E21052', error);
      if (error.response) {
        throw new Error(error.response.data.return_message);
      }
    });
};

export const perPageTirePrices = async (page_no = 0, rowsPerPage = 10) => {
  return await co2OptBaseApi
    .post(
      `${customer_url_common_routes}/get-tire-price?per_page=${rowsPerPage}&page_no=${page_no}`,
      {}
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};
export const getTirePrices = async (access_token, page_no = 0, perPage) => {
  return await co2OptBaseApi
    .post(
      `${customer_url_common_routes}/get-tire-price?per_page=${perPage}&page_no=${page_no}`,
      {}
    )
    .then(res => res)
    .catch(function (error) {
      console.error('E10047', error);
      if (error.response) {
        return error.response;
      }
    });
};

export const unmountOptions = t => {
  const options = [
    t('tireOperationsModal.unmountReasons.store'),
    t('tireOperationsModal.unmountReasons.worn'),
    t('tireOperationsModal.unmountReasons.repair'),
    t('tireOperationsModal.unmountReasons.warranty'),
    t('tireOperationsModal.unmountReasons.smashed'),
    t('tireOperationsModal.unmountReasons.puncture'),
  ];

  const unmountOptionsMapped = {
    [t('tireOperationsModal.unmountReasons.store')]: 'UNMOUNT_STORE',
    [t('tireOperationsModal.unmountReasons.worn')]: 'UNMOUNT_WORN',
    [t('tireOperationsModal.unmountReasons.repair')]: 'UNMOUNT_REPAIR',
    [t('tireOperationsModal.unmountReasons.warranty')]: 'UNMOUNT_WARRANTY',
    [t('tireOperationsModal.unmountReasons.smashed')]: 'UNMOUNT_SMASH',
    [t('tireOperationsModal.unmountReasons.puncture')]: 'UNMOUNT_PUNCTURE',
  };

  return { options, unmountOptionsMapped };
};

import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  FormControl,
  InputLabel,
  Modal,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';
import vergolst from 'assets/images/Vergoelst.png';
import driver from 'assets/images/Driver.png';
import CustomTextField from 'components/custom/CustomTextField';
import {
  getVehiclesForFleet,
  tireOcr,
  updateTire,
  updateVehicle,
} from 'services/fleet_service';
import {
  setChangeInVehicle,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  setVehiclesForFleet,
  unprocessedTireInventoryDataSelector,
  vehicleUpdatedSelector,
} from 'redux/fleets/ducks';
import { useParams } from 'react-router-dom';
import { fetchTireInventoryDataAndProcess } from 'pages/TireInventory/TireInventory';
import { useSelector } from 'react-redux';
import AxleConfig from './AxleConfig';
import TireSizeConfig from './TireSizeConfig';
import { ExpandMore } from '@mui/icons-material';
import { tiresDbSelector } from 'redux/tireInventory/ducks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import moment from 'moment';
import { setAccessTokenSelector } from 'redux/users/ducks';

const getColor = props => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: '770px',
      height: 'auto',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      padding: '2rem 3rem',
      overflow: 'auto',
    },
    form: {
      marginTop: theme.spacing(10),
      width: 574,
    },
    formControl: {
      width: '100%',
    },
    btns: {
      flex: '30%',
      borderRadius: 24,
      textTransform: 'unset',
    },
    container: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: `${props => getColor(props)}`,
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border 0.24s ease-in-out',
    },
    previewBox: {
      display: 'flex',
      gap: 10,
      flexWrap: 'wrap',
      marginBottom: 20,
      padding: 10,
    },
    step2: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    actionBtns: {
      textTransform: 'unset',
      marginTop: '20px',
    },
  };
});

const pdf_ext = {
  'application/pdf': ['.pdf'],
};

const logo = {
  driver: <img src={driver} alt='driver' style={{ width: 250 }} />,
  vergolst: <img src={vergolst} alt='vergolst' style={{ width: 250 }} />,
};
const ServiceDocUpload = ({
  category,
  open,
  close,
  files,
  setFiles,
  uploadDocument,
  activeStep,
  setActiveStep,
  content,
  setContent,
  popUpCode,
  setPopUpCode,
  configData,
  loading,
  setLoading,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: pdf_ext,
  });
  const dispatch = useDispatch();
  const [payload, setPayload] = React.useState(content?.resources?.payload);
  const [editablePayload, setEditablePayload] = React.useState(
    content?.resources?.tire
  );
  const [tireToEdit, setTireToEdit] = useState(null);
  const params = useParams();
  const { fleet_id, vehicle_id } = params;
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);
  const [focus, setFocus] = useState({
    edit_payload: false,
    edit_inventory: false,
  });
  const [axleType, setAxleType] = useState(null);
  const [trailerTire, setTrailerTire] = useState('');
  const [tireSize, setTireSize] = useState(null);
  const [presentStep, setPresentStep] = useState(0);
  const [mountTires, setMountTires] = useState(
    configData?.mounted_tires.filter(tire => tire.position)
  );
  const keysToDisplay = ['brand', 'product_line', 'tire_id', 'id'];
  const keysToDisplay1 = ['brand', 'model', 'tire_id', 'id'];
  let payload_ = payload;
  const tiresResource = useSelector(tiresDbSelector);
  const access_token = useSelector(setAccessTokenSelector);
  const handleNext = useCallback(() => {
    setActiveStep(activeStep + 1);

    // eslint-disable-next-line
  }, [activeStep]);

  useEffect(() => {
    if (acceptedFiles.length > 1) {
      dispatch(setNotification('Multiple files not allowed', 'error', true));
    } else if (acceptedFiles.length === 1) {
      setFiles(acceptedFiles);
      handleNext();
    }

    // eslint-disable-next-line
  }, [acceptedFiles.length]);

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  function dropSuccessView() {
    return activeStep === 1 ? (
      <Box
        display={files.length > 0 ? 'flex' : 'none'}
        className={classes.step2}
      >
        <div className={classes.previewBox}>
          <div>
            <p>{files?.[0].name}</p>
            <br />
          </div>
        </div>
        <Button
          variant='contained'
          color='primary'
          onClick={uploadDocument}
          disabled={loading || !files?.[0]}
          data-hook='upload-doc'
        >
          {t('common.uploadBtn')}
        </Button>
      </Box>
    ) : (
      ''
    );
  }

  useEffect(() => {
    setPayload(content?.resources?.payload);
    setEditablePayload(
      content?.resources.tire?.map(item => {
        item.id = item.tire_id;
        return item;
      })
    );
  }, [content]);

  function extractTextInParenthesis(text) {
    const regex = /\(.*?\)/g;
    const matches = text?.match(regex);
    return matches ? matches.map(match => match.slice(1, -1)) : [];
  }

  const steps = [
    t('tireConfigPage.axleSelect'),
    t('tireConfigPage.tireSelect'),
  ];

  const submitHandler = async e => {
    setLoading(true);
    if (focus.edit_inventory) {
      if (Array.isArray(tireToEdit)) {
        const tires = tireToEdit.map(item => {
          const newItem = Object.keys(item)
            .filter(key =>
              keysToDisplay.filter(item => item !== 'id').includes(key)
            )
            .reduce((obj, key) => {
              obj[key] = item[key];
              return obj;
            }, {});

          return newItem;
        });

        let count = 0;
        try {
          await Promise.all(
            tires.map(async (item, i) => {
              const res = await updateTire(tireToEdit[i].system_tire_id, item);
              if (res.status === 200) {
                count = count + 1;
              }
            })
          );
          if (count === tires.length) {
            await commonAfterSuccessCall(
              fleet_id,
              fleetInventoryData,
              dispatch,
              commonPutRequest
            );
          }
        } catch (error) {
          console.error('E2333', error);
        } finally {
          setLoading(false);
        }
      } else {
        const payload__ = Object.keys(tireToEdit)
          .filter(key =>
            keysToDisplay.filter(item => item !== 'id').includes(key)
          )
          .reduce((obj, key) => {
            obj[key] = tireToEdit[key];
            return obj;
          }, {});

        try {
          const res = await updateTire(tireToEdit.system_tire_id, payload__);
          if (res.status === 200) {
            await commonAfterSuccessCall(
              fleet_id,
              fleetInventoryData,
              dispatch,
              commonPutRequest
            );
          }
        } catch (error) {
          console.error('E2330', error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      commonPutRequest();
    }
  };

  async function commonAfterSuccessCall(
    fleet_id,
    fleetInventoryData,
    dispatch,
    commonPutRequest
  ) {
    const data = await fetchTireInventoryDataAndProcess({
      fleet_id: fleet_id,
      inventoryData: fleetInventoryData,
      refetch: true,
    });
    dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
    dispatch(setTireInventoryData(data, fleet_id));
    commonPutRequest();
  }

  const closeAction = () => {
    setContent(null);
    setPopUpCode(null);
    setFiles([]);
    setActiveStep(0);
    close();
  };

  const commonPutRequest = async () => {
    setLoading(true);
    try {
      const res = await tireOcr(payload);
      if (res.status_code === 200) {
        closeAction();
      } else {
        dispatch(setNotification(res?.data?.return_message, 'error', true));
        if (res?.data.popUpCode) {
          setPopUpCode(res.data.popUpCode);
          setContent(res.data);
        }
      }
      const res2 = await getVehiclesForFleet(
        { fleet_id: fleet_id },
        access_token
      );
      dispatch(setVehiclesForFleet(fleet_id, res2));
      dispatch(setChangeInVehicle(!vehicleDataHasChanged));
      const data = await fetchTireInventoryDataAndProcess({
        fleet_id: fleet_id,
        inventoryData: fleetInventoryData,
        refetch: true,
      });
      dispatch(setUnprocessedTireInventoryData(data.res, fleet_id));
      dispatch(setTireInventoryData(data, fleet_id));
    } catch (error) {
      console.error('E2331', error);
    } finally {
      setLoading(false);
    }
  };

  const updateTireToEditState = newObject => {
    setTireToEdit(prevState => {
      if (Array.isArray(prevState)) {
        const index = prevState.findIndex(item => item.id === newObject.id);
        if (index !== -1) {
          const updatedArray = [...prevState];
          updatedArray[index] = { ...updatedArray[index], ...newObject };
          return updatedArray;
        } else {
          return [...prevState, newObject];
        }
      } else {
        if (prevState.id === newObject.id) {
          return { ...prevState, ...newObject };
        } else {
          return [prevState, newObject];
        }
      }
    });
  };

  const handleEditTire = async (e, entry, i, id) => {
    let tires = [];
    let thisTire = {};
    let thisId = '';
    if (entry === 'from_inventory') {
      tires = [...editablePayload];
    } else {
      tires = [...mountTires];
    }
    thisId = tires.findIndex(tire => tire.id === id);
    thisTire = tires[thisId];
    thisTire[e.target.name] = e.target.value;
    setEditablePayload([...tires]);
    setMountTires([...tires]);

    updateTireToEditState(thisTire);
    setFocus(prevFocus => {
      return {
        edit_payload:
          entry === 'after_service' || entry === 'before_service'
            ? true
            : false,
        edit_inventory:
          entry === 'from_inventory' || entry === 'from_mount' ? true : false,
      };
    });
  };

  const handlePayloadChange = async (e, entry, i, value) => {
    const id = payload_[entry].findIndex(item => item.tire_id === value);
    payload_[entry][id][e.target.name] = e.target.value;

    setPayload({ ...payload_ });
    setFocus(prevFocus => {
      return {
        edit_payload:
          entry === 'after_service' || entry === 'before_service'
            ? true
            : false,
        edit_inventory:
          entry === 'from_inventory' || entry === 'from_mount' ? true : false,
      };
    });
  };

  function dropzone() {
    return activeStep === 0 ? (
      <div>
        <Container
          className={classes.container}
          {...getRootProps({
            isFocused,
            isDragAccept,
            isDragReject,
          })}
          style={{
            borderColor: getColor({
              isFocused,
              isDragAccept,
              isDragReject,
            }),
          }}
        >
          <input {...getInputProps()} />
          <p style={{ fontSize: 'clamp(14px, 1vw, 1.5rem)' }}>
            {t('custom_dropzone.infoText')}
          </p>
        </Container>
      </div>
    ) : (
      ''
    );
  }

  function codeFiveView() {
    const titles = content?.return_message;
    const title = content?.return_message?.join(' ');
    const extractedTexts = extractTextInParenthesis(title);
    const afterService =
      payload_?.after_service?.map(item => {
        item.id = item.tire_id;
        return item;
      }) || [];
    let filteredAfterServiceData = afterService.map(item => {
      const newItem = Object.keys(item)
        .filter(key => keysToDisplay1.includes(key))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});

      return newItem;
    });

    let filteredInventoryData = editablePayload?.map(item => {
      const newItem = Object.keys(item)
        .filter(key => keysToDisplay.includes(key))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});

      return newItem;
    });
    return (
      <Box maxHeight={'100vh'} display={'flex'} flexDirection={'column'}>
        {titles?.concat(...['PLEASE EDIT ONE SECTION']).map(entry => (
          <Typography variant='subtitle2' color={'secondary'}>
            {entry}
          </Typography>
        ))}
        <Box
          container
          gap={'20px'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Box flex={'50%'}>
            <AccordionSummary>After Service</AccordionSummary>
            {filteredAfterServiceData
              ?.filter(data => extractedTexts.includes(data.id))
              .map((payload, i) => (
                <Accordion key={i} expanded={afterService?.length <= 2}>
                  <AccordionDetails
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    {Object.entries(payload)
                      .filter(entry => entry[0] !== 'id')
                      .map(item => {
                        return (
                          <Box width='100%' key={item[0]}>
                            <CustomTextField
                              data-hook={`text-field-${item[0]}`}
                              name={item[0]}
                              id={item[0]}
                              label={item[0]}
                              onChange={event =>
                                handlePayloadChange(
                                  event,
                                  'after_service',
                                  i,
                                  payload.tire_id
                                )
                              }
                              variant='outlined'
                              value={item[1]}
                              type='text'
                              fullWidth
                              disabled={item[0] === 'tire_id'}
                            />
                          </Box>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
          <Box flex='50%'>
            <AccordionSummary>Tire in Inventory</AccordionSummary>
            {filteredInventoryData
              ?.filter(data => extractedTexts.includes(data.id))
              .reverse()
              .map((payload, i) => (
                <Accordion
                  expanded={filteredInventoryData?.length <= 2}
                  // data-hook={`task-accordian-${i}`}
                  key={i}
                >
                  <AccordionDetails
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    {Object.entries(payload)
                      .filter(entry => entry[0] !== 'id')
                      .map(item => {
                        return (
                          <Box width='100%' key={item[0]}>
                            <CustomTextField
                              data-hook={`text-field-${item[0]}`}
                              name={item[0]}
                              id={item[0]}
                              label={item[0]}
                              onChange={event =>
                                handleEditTire(
                                  event,
                                  'from_inventory',
                                  i,
                                  payload.id
                                )
                              }
                              variant='outlined'
                              value={item[1]}
                              type='text'
                              fullWidth
                            />
                          </Box>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Box>
        <ActionButtons
          classes={classes}
          submitHandler={submitHandler}
          close={closeAction}
        />
      </Box>
    );
  }
  function codeThreeView() {
    const titles = content?.return_message;
    const title = Array.isArray(content?.return_message)
      ? content?.return_message?.join(' ')
      : content?.return_message;

    const availableSizes = [...new Set(tiresResource?.sizes)];

    const usablePayload = Object.keys(payload || {})
      .filter(key => ['date', 'reg_plate', 'tire_size'].includes(key))
      .reduce((obj, key) => {
        obj[key] = payload[key];
        return obj;
      }, {});

    const handleEdit = e => {
      let updatedPayload = { ...payload };
      updatedPayload[e.target.name] = e.target.value;
      setPayload({ ...updatedPayload });
    };

    const dateChangeHandler = (date, name) => {
      let updatedPayload = { ...payload };
      updatedPayload[name] = moment(date).format('YYYY-MM-DD');
      setPayload({ ...updatedPayload });
    };

    return (
      <Box maxHeight={'100vh'} display={'flex'} flexDirection={'column'}>
        {Array.isArray(titles) ? (
          titles?.map(entry => (
            <Typography variant='subtitle2' color={'secondary'}>
              {entry}
            </Typography>
          ))
        ) : (
          <Typography variant='subtitle2' color={'secondary'}>
            {title}
          </Typography>
        )}
        <Box
          container
          gap={'20px'}
          display={'flex'}
          justifyContent={'space-between'}
          flexDirection={'column'}
        >
          {title?.includes('size') && (
            <FormControl variant='outlined' className={classes.form}>
              <InputLabel htmlFor='outlined-age-native-simple'>
                Tire Size
              </InputLabel>
              <Select
                native
                value={usablePayload.tire_size}
                onChange={handleEdit}
                label='tire_size'
                name='tire_size'
                fullWidth
              >
                <option aria-label='None' value='' />
                {availableSizes.map((item, i) => (
                  <option key={i} label={item} value={item} />
                ))}
              </Select>
            </FormControl>
          )}
          {title?.includes('Registration') && (
            <CustomTextField
              name={'reg_plate'}
              // id={item[0]}
              label={'Registration Plate'}
              onChange={handleEdit}
              variant='outlined'
              value={usablePayload.reg_plate}
              type='text'
              fullWidth
            />
          )}
          {title?.includes('Date') && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {' '}
              <DatePicker
                label={'Service Date'}
                value={parseISO(usablePayload.date)}
                onChange={date => dateChangeHandler(date, 'date')}
                renderInput={params => <TextField {...params} name='date' />}
                format='yyyy.MM.dd'
              />
            </LocalizationProvider>
          )}
        </Box>
        {popUpCode === 'three' && (
          <ActionButtons
            classes={classes}
            submitHandler={submitHandler}
            close={closeAction}
          />
        )}
      </Box>
    );
  }

  function codeTwoView() {
    let disableCondition;
    if (presentStep === 0) {
      disableCondition = axleType === null;
    } else {
      disableCondition = axleType === null || tireSize === null;
    }
    const title = content?.return_message;
    const nextStepClickHandler = async () => {
      let payload = {
        name: vehicle.name,
        reg_plate: configData.reg_plate,
        internal_grouping: configData.internal_grouping,
        axle_type: axleType,
      };
      if (presentStep === steps.length - 1) {
        payload = {
          ...payload,
          tire_size: trailerTire,
          chassis_number: configData.chassis_number,
        };

        try {
          const res = await updateVehicle(
            payload,
            vehicle_id,
            configData.vehicle_type
          );
          if (res.status === 200) {
            dispatch(
              setNotification(
                t('tireConfigPage.axlConfiguredSuccess'),
                'success',
                true
              )
            );

            dispatch(setChangeInVehicle(!vehicleDataHasChanged));
            commonPutRequest();
          }
        } catch (error) {
          console.error('E20067', error);
        }
      } else {
        setPresentStep(prev => prev + 1);
      }
    };
    let vehicle = content?.resources.vehicle;
    vehicle.vehicle_type = 'trailer';
    return (
      <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
        <Typography variant='subtitle2' color={'secondary'}>
          {title}
        </Typography>
        <Stepper
          activeStep={presentStep}
          // alternativeLabel
          style={{ backgroundColor: 'transparent' }}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {presentStep ===
          steps.findIndex(step => step === t('tireConfigPage.axleSelect')) && (
          <AxleConfig
            configData={vehicle}
            axle={axleType}
            setAxle={setAxleType}
          />
        )}
        {presentStep ===
          steps.findIndex(step => step === t('tireConfigPage.tireSelect')) && (
          <TireSizeConfig
            trailerTire={trailerTire}
            setTrailerTire={setTrailerTire}
            configData={vehicle}
            size={tireSize}
            setSize={setTireSize}
          />
        )}
        <Button
          variant='contained'
          color='primary'
          onClick={nextStepClickHandler}
          disabled={disableCondition}
          style={{ alignSelf: 'center' }}
        >
          {presentStep < steps.length - 1
            ? t('common.next')
            : t('common.confirm')}
        </Button>
      </Box>
    );
  }

  function codeFourView() {
    const titles = content?.return_message;
    const beforeService =
      payload_?.before_service?.map(item => {
        item.id = item.tire_id;
        return item;
      }) || [];

    let filteredBeforeServiceData = beforeService.map(item => {
      const newItem = Object.keys(item)
        .filter(key => keysToDisplay1.concat(['axle']).includes(key))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});

      return newItem;
    });
    let filteredMountedTires = mountTires?.map(item => {
      const newItem = Object.keys(item)
        .filter(key => keysToDisplay.concat(['position']).includes(key))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});

      return newItem;
    });

    return (
      <Box maxHeight={'100vh'} display={'flex'} flexDirection={'column'}>
        {titles
          ?.concat(...['PLEASE EDIT THE TIRES TO BE SAME!'])
          ?.map(entry => (
            <Typography variant='subtitle2' color={'secondary'}>
              {entry}
            </Typography>
          ))}
        <Box
          container
          gap={'20px'}
          display={'flex'}
          justifyContent={'space-between'}
        >
          <Box flex={'50%'}>
            <h5>Before Service</h5>
            {filteredBeforeServiceData
              ?.filter(data => titles.join('').includes(data.axle))
              .map((payload, i) => (
                <Accordion key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    {payload.axle}
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    {Object.entries(payload)
                      .filter(entry => entry[0] !== 'id')
                      .map(item => {
                        return (
                          <Box width='100%' key={item[0]}>
                            <CustomTextField
                              data-hook={`text-field-${item[0]}`}
                              name={item[0]}
                              id={item[0]}
                              label={item[0]}
                              onChange={event =>
                                handlePayloadChange(
                                  event,
                                  'before_service',
                                  i,
                                  payload.tire_id
                                )
                              }
                              variant='outlined'
                              value={item[1]}
                              type='text'
                              fullWidth
                              disabled={item[0] === 'axle'}
                            />
                          </Box>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
          <Box flex='50%'>
            <h5>Mounted Tires</h5>
            {filteredMountedTires
              ?.filter(data => titles.join('').includes(data.position))
              .map((payload, i) => (
                <Accordion key={i}>
                  <AccordionSummary>{payload.position}</AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    {Object.entries(payload)
                      .filter(entry => entry[0] !== 'id')
                      .map(item => {
                        return (
                          <Box width='100%' key={item[0]}>
                            <CustomTextField
                              data-hook={`text-field-${item[0]}`}
                              name={item[0]}
                              id={item[0]}
                              label={item[0]}
                              onChange={event =>
                                handleEditTire(event, 'from_mount', i)
                              }
                              variant='outlined'
                              value={item[1]}
                              type='text'
                              disabled={item[0] === 'position'}
                              fullWidth
                            />
                          </Box>
                        );
                      })}
                  </AccordionDetails>
                </Accordion>
              ))}
          </Box>
        </Box>

        <ActionButtons
          classes={classes}
          submitHandler={submitHandler}
          close={closeAction}
        />
      </Box>
    );
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {
        setActiveStep(0);
        closeAction();
      }}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          {!content ? (
            <>
              {logo[category]}

              <Box marginTop={2} width='100%'>
                <React.Fragment>
                  {dropzone()}
                  {dropSuccessView()}
                  <Box display='flex' flexDirection='row'>
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleBack}
                        variant='outlined'
                        color='primary'
                      >
                        {t('common.back')}
                      </Button>
                    )}
                  </Box>
                </React.Fragment>
              </Box>
            </>
          ) : popUpCode === 'five' ? (
            codeFiveView()
          ) : popUpCode === 'four' ? (
            codeFourView()
          ) : popUpCode === 'two' ? (
            codeTwoView()
          ) : (
            codeThreeView()
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default ServiceDocUpload;

function ActionButtons(props) {
  return (
    <Box display={'flex'} alignSelf={'center'} gap={'20px'}>
      <Button
        className={props.classes.actionBtns}
        variant='outlined'
        onClick={props.close}
      >
        Cancel
      </Button>
      <Button
        variant='contained'
        className={props.classes.actionBtns}
        onClick={props.submitHandler}
      >
        Submit
      </Button>
    </Box>
  );
}

ServiceDocUpload.propTypes = {
  open: PropTypes.bool,
  category: PropTypes.string,
  close: PropTypes.func,
  files: PropTypes.array,
  setFiles: PropTypes.func,
  uploadDocument: PropTypes.func,
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func,
};

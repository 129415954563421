import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Fade, Modal, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setAccessTokenSelector } from 'redux/users/ducks';
import {
  fleetOverview,
  getDailyReport,
  updateTire,
} from 'services/fleet_service';
import { setNotification } from 'redux/notifications/ducks';
import {
  inventoryChangeSelector,
  setChangeInInventory,
} from 'redux/tireInventory/ducks';
import {
  FleetActionTypes,
  setChangeInVehicle,
  // setFleetWideTireHistoryData,
  setTireInventoryData,
  setTrackedVehiclesIdsSelector,
  setUnprocessedTireInventoryData,
  unprocessedTireInventoryDataSelector,
  vehicleUpdatedSelector,
} from 'redux/fleets/ducks';
import { useTranslation } from 'react-i18next';
import { EDIT, event_types, TIRES, TRACKED_LS } from 'utils/string_utils';
import {
  debouncedCommonChangeHandler,
  debouncedTireIdChangeHandler,
} from 'services/util_services';
import moment from 'moment';
import {
  // fetchFleetTireEventHistory,
  fetchTireInventoryDataAndProcess,
} from './TireInventory';
import TireForm from 'components/TireForm';

const useStyles = makeStyles()(theme => {
  return {
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      // maxWidth: '1000px',
      width: '770px',
      height: 'auto',
      border: 'none',

      alignItems: 'center',
      textAlign: 'center',
      padding: '48px 98px 48px 98px',
      position: 'absolute',
      zIndex: 9999,
    },
  };
});

export const tireStatus = [
  'STORE_BANK',
  'WARRANTY_BANK',
  'PUNCTURE_BANK',
  'SMASH_BANK',
  'WORN_BANK',
  'REPAIR_BANK',
];

const UpdateTire = props => {
  const {
    current,
    id,
    tireId,
    open,
    setOpen,
    tireData,
    setTire,
    close,
    setHighlightedTire,
    last_pressure,
    vehicleType,
    axle,
    index,
    tire,
    updateFromTd,
    setRegrooveCheck = () => {},
    location = 'ALL',
    page = 0,
    vehicle,
    setIsRegrooved,
    // isRetread = false,
    isRegroovedSelected = false,
    perPage = 10,
    dot,
  } = props;

  const { classes } = useStyles();
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const [size, setSize] = useState(current.currentSize);
  const [error, setError] = useState({
    [TIRES.TIRE_ID]: null,
    [TIRES.TREAD_DEPTH]: null,
    pressure: null,
  });

  const [reports, setReports] = useState(null);
  const trackedLs = TRACKED_LS;
  const trackedVehicles = useSelector(setTrackedVehiclesIdsSelector);
  useEffect(() => {
    if (tireData?.grooves_tread_depth) {
      delete tireData?.grooves_tread_depth?.max_diff;
    }
    // eslint-disable-next-line
  }, []);
  const sanitiseGroove = data => {
    if (data) {
      let temp = JSON.parse(JSON.stringify(data));
      delete temp['max_diff'];
      return temp;
    } else {
      return null;
    }
  };

  function getStartOfWeekDate(week, year) {
    const firstDayOfYear = new Date(year, 0, 1);
    const daysOffset = (week - 1) * 7;
    const startOfWeekDate = new Date(
      firstDayOfYear.setDate(firstDayOfYear.getDate() + daysOffset)
    );

    const dayOfWeek = startOfWeekDate.getDay();
    const daysToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    startOfWeekDate.setDate(startOfWeekDate.getDate() - daysToMonday);
    return startOfWeekDate;
  }

  function convertWeekYearStringToDate(weekYearString) {
    if (weekYearString) {
      const [week, year] = weekYearString.split('/').map(Number);

      const fullYear = 2000 + year;
      return getStartOfWeekDate(week, fullYear);
    }
    return '';
  }

  const [payload, setPayload] = useState({
    tire_id: tireId,
    mileage: !!tireData?.mileage
      ? Math.round(parseFloat(tireData?.mileage))
      : tireData.last_km_driven,
    pressure: tireData?.last_pressure?.toString(),
    grooves_tread_depth: sanitiseGroove(tireData?.grooves_tread_depth),
    isRegrooved: tireData.isRegrooved,
    isRetread: tireData.isRetread,
    last_tread_depth: tireData.last_tread_depth?.toString(),
    dot: convertWeekYearStringToDate(tireData.dot),
  });

  const staticValues = {
    tire_id: tireId,
    mileage: !!tireData?.mileage
      ? Math.round(parseFloat(tireData?.mileage))
      : tireData.last_km_driven,
    pressure: tireData?.last_pressure?.toString(),
    grooves_tread_depth: sanitiseGroove(tireData?.grooves_tread_depth),
    isRegrooved: tireData.isRegrooved,
    isRetread: tireData.isRetread,
    last_tread_depth: tireData.last_tread_depth?.toString(),
  };

  const km = !!tireData?.mileage
    ? Math.round(parseFloat(tireData?.mileage))
    : tireData.last_km_driven;
  const access_token = useSelector(setAccessTokenSelector);
  const currInventoryChangeValue = useSelector(inventoryChangeSelector);
  const [eventDate, setEventDate] = useState(new Date());
  const tracked = trackedVehicles || trackedLs;
  const [fieldIsTouched, setFieldIsTouched] = useState(false);
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);
  const [isGrooveChanged, setGrooveChanged] = useState(false);
  // const [initialPayload, setInitialPayload] = useState(null);
  const [updatePressure, setUpdatePressure] = useState(false);
  const [updateRegroove, setUpdateRegroove] = useState(false);
  const [updateTread, setUpdateTread] = useState(false);
  const vehicleDataHasChanged = useSelector(vehicleUpdatedSelector);
  const axlePosition = tireData.optimum_air_pressure;
  const optimumPressure =
    updateFromTd &&
    axlePosition &&
    tireData.optimum_air_pressure[tireData.axle_position.slice(0, 2)];

  useEffect(() => {
    const query = async () => {
      try {
        const res = await getDailyReport(tireData.vehicle_id);
        if (res.status === 200) {
          const result = res?.data?.resp[tireData.vehicle_id];

          if (result.length > 0) {
            const final = result.sort(function (a, b) {
              return b.timestamp - a.timestamp;
            });
            Object.keys(result).length && setReports(final);
          }
        }
      } catch (error) {
        console.error('E20026', error);
      }
    };
    if (tracked !== null) {
      const isTracked = tracked.includes(tireData.vehicle_id);
      if (tireData.status === TIRES.tireStatus.IN_USE && isTracked) {
        query();
      }
    }

    // eslint-disable-next-line
  }, [access_token, tireData.vehicle_id]);

  useEffect(() => {
    try {
      setSize(current.currentSize);
    } catch (error) {
      console.error('E20064', error);
    }

    // eslint-disable-next-line
  }, [current, tireId, reports]);

  const handleGrooveTD = (item, val) => {
    setPayload({
      ...payload,
      grooves_tread_depth: {
        ...payload.grooves_tread_depth,
        [item]: parseFloat(val),
      },
    });
  };
  const handleIncrement = () => {
    if (payload.grooves_tread_depth) {
      setPayload({
        ...payload,
        grooves_tread_depth: {
          ...payload.grooves_tread_depth,
          [Object.keys(payload.grooves_tread_depth).length + 1]: null,
        },
      });
    } else {
      setPayload({
        ...payload,
        grooves_tread_depth: {
          1: null,
        },
      });
    }
  };
  const handleDeleteGroove = item => {
    let temp = JSON.parse(JSON.stringify(payload.grooves_tread_depth));
    delete temp[item];
    setPayload({
      ...payload,
      grooves_tread_depth: temp,
    });
  };
  const handleClose = () => {
    setOpen(false);
    setIsRegrooved && setIsRegrooved(false);
    setPayload({
      tire_id: '',
      [TIRES.TREAD_DEPTH]: '',
      mileage: '',
      pressure: '',
    });
    setSize(null);
    setError({
      [TIRES.TIRE_ID]: null,
      [TIRES.TREAD_DEPTH]: null,
    });
    setGrooveChanged(false);
    close && close();
  };

  // const [isInitialId, setIsInitialId] = useState(second)
  const handleChange = async e => {
    if (e.target.name === TIRES.TIRE_ID) {
      debouncedTireIdChangeHandler(
        e,
        setError,
        error,
        setPayload,
        payload,
        tireId,
        t,
        access_token
      );
    } else {
      debouncedCommonChangeHandler(
        e,
        setError,
        error,
        setPayload,
        payload,
        tireId,
        t,
        access_token
      );
    }
    if (
      e.target.name === TIRES.TIRE_ID ||
      e.target.name === TIRES.MILEAGE ||
      e.target.name === TIRES.TREAD_DEPTH
    ) {
      setFieldIsTouched(true);
    }
    setFieldIsTouched(true);
  };
  const checkGrooveTd = groove_td => {
    if (groove_td) {
      if (Object.keys(groove_td).length) {
        return groove_td;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const getWeekNumber = date => {
    const tempDate = new Date(date.getTime());
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
    const startOfYear = new Date(tempDate.getFullYear(), 0, 1);
    return Math.ceil(((tempDate - startOfYear) / 86400000 + 1) / 7);
  };

  const dateConverter = date => {
    const weekNumber = getWeekNumber(date);
    const year = date.getFullYear().toString().slice(-2);
    return `${weekNumber}/${year}`;
  };

  const submitFormHandler = async e => {
    e.preventDefault();
    const obj = { event_date: moment(eventDate).format('YYYY-MM-DD') };
    let payload_ = {};
    let events = [];
    const event_date = moment(eventDate).format('YYYY-MM-DD');
    if (km && payload.mileage && parseInt(km) !== parseInt(payload.mileage)) {
      payload_ = {
        ...payload_,
        events: [
          {
            ...obj,
            event_type: event_types.UPDATE_MILEAGE,
            km: payload.mileage,
          },
        ],
      };
    }

    if (
      staticValues.last_tread_depth !== payload.last_tread_depth &&
      updateTread
    ) {
      events.push({
        event_type: event_types.UPDATE_DEPTH,
        tread_depth: payload.last_tread_depth,
        event_date,
      });
    }

    if (payload.tire_id !== tireId) {
      payload_ = {
        ...payload_,
        tire_id: payload.tire_id,
      };
    }

    if (updatePressure && payload.pressure) {
      events.push({
        event_type: event_types.UPDATE_PRESSURE,
        pressure: payload.pressure,
        event_date,
      });
    }

    if (isGrooveChanged) {
      delete payload.grooves_tread_depth.max_diff;
      payload_ = {
        ...payload_,
        grooves_tread_depth: checkGrooveTd(payload.grooves_tread_depth),
      };
    }
    payload_.events = events;
    if (payload_.events?.length === 0) {
      delete payload_.events;
    }
    payload_ = {
      ...payload_,
      ...(tireData.isRetread !== payload.isRetread && {
        isRetread: payload.isRetread,
      }),
      ...(tireData.isRegrooved !== payload.isRegrooved && {
        isRegrooved: payload.isRegrooved,
      }),
    };
    if (payload.dot) {
      payload_ = { ...payload_, dot: dateConverter(payload.dot) };
    }

    await editTireDetails(payload_);
  };

  const handleRegroove = async e => {
    e.preventDefault();
    const obj = { event_date: moment(eventDate).format('YYYY-MM-DD') };

    let payload_ = {
      events: [
        {
          ...obj,
          event_type: event_types.REGROOVE,
          tread_depth: payload.last_tread_depth,
        },
      ],
    };
    let unmountPayload = {
      events: [
        {
          ...obj,
          event_type: event_types.UNMOUNT,
          tread_depth: payload.last_tread_depth,
        },
      ],
    };

    if (!tireStatus.includes(tireData.status)) {
      await editTireDetails(unmountPayload, true);
    }
    await editTireDetails(payload_);

    await setRegrooveCheck(true);
    return;
  };

  const editTireDetails = async (payload_, skipRefresh = false) => {
    try {
      const res = await updateTire(id, payload_);

      const resp = await fleetOverview('token');
      dispatch({
        type: FleetActionTypes.SET_OVERVIEW_DATA,
        payload: resp?.data?.resp,
      });

      if (res.status === 200) {
        const { data: responseData } = res;
        dispatch(
          setNotification(responseData?.return_message, 'success', true)
        );
        if (setHighlightedTire) {
          if (!isRegroovedSelected) {
            setHighlightedTire({
              ...responseData?.resp,
              axle_position_id: tireData?.axle_position_id,
            });
          } else {
            setHighlightedTire(prev => {
              let row = vehicle?.mounted_tires?.filter(
                tire => !!tire.position && tire.tire_id !== tireId
              )[0];

              // setParameters(row);
              return row;
            });
          }
        }

        if (!skipRefresh) {
          const inventoryData = await fetchTireInventoryDataAndProcess({
            fleet_id: responseData?.resp?.fleet_id,
            inventoryData: fleetInventoryData,
            refetch: true,
            location,
            isPaginated: true,
            pageNo: page,
            perpage: perPage,
          });

          dispatch(
            setTireInventoryData(inventoryData, responseData?.resp?.fleet_id)
          );
          dispatch(
            setUnprocessedTireInventoryData(
              inventoryData.res,
              responseData?.resp?.fleet_id
            )
          );

          if (setTire !== undefined) {
            const updatedTire = inventoryData?.originalData.filter(
              tire => tire.tire_id === tireData.tire_id
            );
            setTire(updatedTire);
          }

          if (updateRegroove) {
            dispatch(setChangeInVehicle(!vehicleDataHasChanged));
            if (setHighlightedTire) {
              setHighlightedTire(null);
            }
          }

          dispatch(setChangeInInventory(!currInventoryChangeValue));
        }
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20060', error);
    } finally {
      setUpdatePressure(false);
      setUpdateRegroove(false);
      if (!skipRefresh) {
        handleClose();
      }
    }
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      data-hook='Update-Tire-Modal'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={Backdrop}
      slotProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Typography variant='h5' data-hook='Tire-Update-Title'>
            {isRegroovedSelected
              ? t('common.tireInventory.updateRegrooveHeading')
              : t('common.tireInventory.tireUpdateHeading')}
          </Typography>
          <Box marginTop={'32px'}>
            <TireForm
              size={size}
              setSize={setSize}
              error={error}
              setError={setError}
              setUpdateTread={setUpdateTread}
              handleClose={handleClose}
              handleChange={handleChange}
              initialId={tireId}
              payload={payload}
              setPayload={setPayload}
              staticPayload={staticValues}
              tire={tire}
              isGrooveChanged={isGrooveChanged}
              tireData={tireData}
              handleGrooveTD={handleGrooveTD}
              handleIncrement={handleIncrement}
              setGrooveChanged={setGrooveChanged}
              submitFormHandler={submitFormHandler}
              eventDate={eventDate}
              setDate={setEventDate}
              caller={EDIT}
              last_pressure={last_pressure}
              fieldIsTouched={fieldIsTouched}
              setUpdateRegroove={setUpdateRegroove}
              setUpdatePressure={setUpdatePressure}
              vehicleType={vehicleType}
              axle={axle}
              index={index}
              optPressure={optimumPressure}
              handleRegroove={handleRegroove}
              handleDeleteGroove={handleDeleteGroove}
              vehicle={vehicle}
              isRegroovedSelected={isRegroovedSelected}
              dot={dot}
            />
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

UpdateTire.propTypes = {
  current: PropTypes.any,
  id: PropTypes.any,
  tireId: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  tireData: PropTypes.any,
  tireInventoryAction: PropTypes.bool,
};
UpdateTire.defaultProps = {
  tireInventoryAction: false,
};

export default UpdateTire;

import React, { useMemo, useState, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  fleetOverviewSelector,
  allTrackedVehiclesSelector,
  setTireInventoryData,
  setTrackedVehiclesIdsSelector,
  unprocessedTireInventoryDataSelector,
  allUsageReportsSelector,
  setUsageReports,
} from 'redux/fleets/ducks';
import { getAllReports } from 'services/fleet_service';
import { VEHICLE } from 'utils/string_utils';
import * as XLSX from 'xlsx';
import { fetchTireInventoryDataAndProcess } from '../TireInventory/TireInventory';
import { SelectAllBtn } from '../Settings/UserManagement/AssetsWhitelisting/SelectAllBtn';
import { kpiList } from './const';
import { setNotification } from 'redux/notifications/ducks';
import Loading from 'components/Loading';

import LoadAnimation from 'components/LoadAnimation';
import { Close, Download, Input, Search } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import CustomTable from 'components/custom/CustomTable';
import useFetchAllVehicles from 'hooks/useFetchAllVehicles';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    listItem: {
      transition: 'box-shadow 0.3s ease-in-out',
      '&:hover': {
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
      },
    },

    activeListItem: {
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
    },
    kpiGrid: {
      overflowY: 'auto',
      marginBottom: '20px',
    },
    toggleBtn: { textTransform: 'capitalize' },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      marginTop: 10,
    },
    li: {
      listStyle: 'none',
      padding: 20,
      border: '1px solid #ccc',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      transition: 'all 0.3s ease-out',
      width: '95%',
      background: '#fff',
      cursor: 'pointer',

      '&:hover': {
        width: '100%',
      },
    },
    active: {
      width: '100%',
    },
    ul: {
      padding: 0,
    },
    arrow: {
      fontSize: 16,
      marginLeft: 5,
      color: theme.palette.primary.alt,
    },
    heading: {
      marginBottom: 20,
      width: '100%',
      fontSize: 24,
    },
    button: {
      minWidth: 185,
      maxWidth: 250,
      textTransform: 'unset',
    },
    box: {
      maxHeight: 175,
      overflowY: 'scroll',
    },
    accordion: {
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    label: {
      color: theme.palette.primary.main,
    },
    value: { color: '#6c7178' },
    span: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    common: {
      overflowY: 'scroll',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '20px',
      gap: 20,
      overflowY: 'scroll',
    },
    searchBar: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: '1px solid #D1D5DB',
      borderRadius: 8,
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      background: 'white',
    },
    closeIcon: {
      position: 'absolute',
      right: 10,
      top: 10,
      cursor: 'pointer',
    },
  };
});

export const keyValue = {
  mission_profile: null,
  total_share_motorway: '%',
  total_share_regional: '%',
  total_share_urban: '%',
  average_speed: 'kmph',
  total_distance_driven: 'km',
  total_number_of_trips: null,
  average_fuel_consumption: 'l/100km',
  total_fuel_burnt: 'l',
  total_co2_emissions: 'kg',
  total_time_on_the_move: 'hrs',
  idling_locations: null,
  minimum_change_in_elevation: null,
  maximum_change_in_elevation: null,
  average_change_in_elevation: null,
};

const TIRE_CHANGELOG_INDEX = 2;
const VEHICLE_CHANGELOG_INDEX = 3;

const AllReports = () => {
  const { classes } = useStyles();
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(null);
  const [vehicleCounter, setVehicleCounter] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const { isLoading, allVehicles, fetchFleets } = useFetchAllVehicles();

  const [vehicles, setVehicles] = useState([]);
  const [vehiclesStatic, setVehiclesStatic] = useState([]);
  const [tires, setTires] = useState([]);
  const [selectedVehicles, setSelectedVehicles] = useState({});
  const [selectedVehiclesStatic, setSelectedVehiclesStatic] = useState({});
  const [selectedTires, setSelectedTires] = useState([]);
  const [tiresOverview, setTiresOverview] = useState({});
  const [vehicleResource, setVehicleResource] = useState([]);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });
  const [touched, setTouched] = useState(false);
  const [allResources, setAllResources] = useState(allVehicles);
  const [dataIsLoading, setDataIsLoading] = useState(false);
  let tracked = useSelector(allTrackedVehiclesSelector);
  const trackedVehicleIds = useSelector(setTrackedVehiclesIdsSelector);
  const [noKpisSelected, setNoKpisSelected] = useState(false);
  const [trackedVehicleSelected, setTrackedVehicleSelected] = useState(false);
  const tireInventoryDetails = useSelector(state => state.fleet.overviewData);

  const buttonIsDisabled = useMemo(() => {
    return (
      activeIndex === null ||
      (activeIndex === 3 && vehicleResource.length === 0) ||
      (activeIndex === 4 && !trackedVehicleSelected) ||
      (tires.length === 0 && vehicles.length === 0) ||
      noKpisSelected ||
      (Object.keys(tiresOverview).length &&
        Object.entries(tiresOverview).every(item => item[1] === 0))
    );
  }, [
    activeIndex,
    vehicleResource,
    trackedVehicleSelected,
    tires,
    vehicles,
    noKpisSelected,
    tiresOverview,
  ]);

  const [kpis_, setKpis_] = useState(kpiList);
  let entryNo = null;
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);

  const usageReports = useSelector(allUsageReportsSelector);
  const [filtered, setFiltered] = useState(null);
  const [availableIds, setAvailableIds] = useState(null);

  useEffect(() => {
    if (fleets && fleets.length) {
      fetchFleets(fleets);
    }
    /*eslint-disable-next-line*/
  }, [fleets]);
  const getAllUsageReports = async () => {
    const truthyKeys = Object.keys(kpis_[4]).filter(key => kpis_[4][key]);
    let payload = {
      type: 'USAGE_REPORTS',
      kpis: truthyKeys,
      vehicle_id: trackedVehicleIds,
    };
    try {
      const res = await getAllReports(payload);
      if (res.status === 200) {
        dispatch(setUsageReports(res.data));
        return res.data;
      }
    } catch (error) {
      console.error('E20106', error);
    }
  };

  useEffect(() => {
    if (isDownloading && filtered) {
      setTimeout(() => {
        downloadUsageReportsHandler(filtered, availableIds);
      }, 1000);
    }
    /*eslint-disable-next-line*/
  }, [isDownloading, filtered]);
  useEffect(() => {
    if (activeIndex !== null) {
      const truthyKeys = Object.keys(kpis_[activeIndex]).filter(
        key => kpis_[activeIndex][key]
      );
      if (truthyKeys.length === 0) {
        setNoKpisSelected(true);
      } else setNoKpisSelected(false);
    }
    /*eslint-disable-next-line*/
  }, [activeIndex, kpis_]);

  useEffect(() => {
    if (tracked?.length > 0) {
      const unregisteredAndTrackedCombined = tracked.reduce(
        (accumulator, currentValue) => {
          return accumulator.concat(currentValue.unregistered_and_tracked);
        },
        []
      );
      const newArr = unregisteredAndTrackedCombined.map(vehicle => {
        const newObj = {
          ...vehicle,
          name: vehicle.telematics_managed_vehicle_name,
          vehicle_id: vehicle.telematics_managed_regplate,
          fleet_id: 'unregistered_and_tracked',
        };
        delete newObj.telematics_managed_vehicle_name;
        delete newObj.telematics_managed_regplate;
        return newObj;
      });

      const addedKpis = {
        currently_burnt: false,
        days_in_service: false,
        estimated_tread_depth: false,
        axle_position: false,
        axle_type: false,
        mission_profile: false,
        remaining_months: false,
      };
      const updatedKpis = [...kpis_];
      updatedKpis[1] = { ...updatedKpis?.[1], ...addedKpis };
      setKpis_(updatedKpis);
      let temp = allVehicles;
      if (activeIndex === 0) {
        temp = temp?.concat(newArr);
      }
      setAllResources(temp);
    } else {
      setAllResources(allVehicles);
    }

    // eslint-disable-next-line
  }, [allVehicles, tracked, activeIndex]);

  let allFleets = fleets;
  let b = [
    {
      fleet_id: 'unregistered_and_tracked',
      fleet_name: 'Unregistered Vehicles',
    },
  ];
  if (tracked?.length > 0) {
    if (
      tracked?.some(item => item?.unregistered_and_tracked?.length > 0) &&
      activeIndex === 0
    ) {
      allFleets = allFleets?.concat(b);
    }
  }

  const replaceNullValues = arr => {
    const updatedData = JSON.parse(JSON.stringify(arr)).map(item => {
      const updatedItem = { ...item };
      Object.keys(updatedItem).forEach(key => {
        if (updatedItem[key] === null || updatedItem[key] === undefined) {
          updatedItem[key] = 'N/A';
        }
      });
      return updatedItem;
    });

    return updatedData;
  };

  const filterSearchHandler = (e, index) => {
    let updated = JSON.parse(JSON.stringify(vehiclesStatic));
    let updatedArr = updated[index].vehicles;
    const searchQuery = e.target.value.toLowerCase();

    const filtered = updatedArr.filter(vehicle => {
      const lowerCaseName = vehicle.name.toLowerCase();
      return lowerCaseName.includes(searchQuery);
    });

    updated[index].vehicles = filtered;
    setVehicles(updated);
    if (searchQuery.length === 0) {
      setVehicles(vehiclesStatic);
    }
  };

  const handleChange = async (e, value) => {
    setTouched(true);
    if (value === null) {
      value = [];
      setTouched(false);
    } else if (!Array.isArray(value)) {
      value = [value];
    }

    let temp = [];
    let temp2 = [];
    const selected_vehicles = new Map();
    const selected_tires = new Map();
    const tiresOverview_ = new Map();
    const fleet_map = new Map();
    if (value.length > 0) {
      for (const element of value) {
        if (activeIndex === 0 || activeIndex === 3) {
          const vehiclesInFleet = allResources?.filter(
            vehicle => vehicle.fleet_id === element.fleet_id
          );

          if (vehiclesInFleet?.length > 0) {
            let trucks_arr = vehiclesInFleet.filter(truck => {
              return (
                truck.vehicle_type === VEHICLE.vehicle_type.TRUCK.toUpperCase()
              );
            });
            let trailers_arr = vehiclesInFleet.filter(trailer => {
              return (
                trailer.vehicle_type ===
                VEHICLE.vehicle_type.TRAILER.toUpperCase()
              );
            });
            let buses_arr = vehiclesInFleet.filter(bus => {
              return (
                bus.vehicle_type === VEHICLE.vehicle_type.BUS.toUpperCase()
              );
            });

            let others = vehiclesInFleet.filter(vehicle => {
              return !vehicle.vehicle_type;
            });
            const vehicles_arr = trucks_arr
              .concat(trailers_arr)
              .concat(buses_arr)
              .concat(others);
            const final_vehicles = [];
            temp.push({
              fleet: element,
              vehicles: vehicles_arr,
            });

            setVehicleCounter(
              vehicleCounter + activeIndex === 2
                ? final_vehicles.length
                : vehicles_arr.length
            );
            selected_vehicles.set(element.fleet_name, vehicles_arr);
            fleet_map.set(element.fleet_name, element.fleet_id);
          }
        }
        if (activeIndex === 1) {
          setDataIsLoading(true);
          let data;
          data = await fetchTireInventoryDataAndProcess({
            fleet_id: element.fleet_id,
            inventoryData: fleetInventoryData,
            refetch: true,
            location: 'ALL',
            isPaginated: true,
            pageNo: 0,
            perpage: tireInventoryDetails.metrics[element.fleet_id].total_tires,
            isReports: true,
          });
          dispatch(setTireInventoryData(data, element.fleet_id));

          if (data) {
            selected_tires.set(element.fleet_name, data?.originalData);
            temp2.push({
              fleet: element,
              tires: data?.originalData,
            });
          }
          tiresOverview_.set(
            element.fleet_name,
            tireInventoryDetails
              ? tireInventoryDetails.metrics[element.fleet_id].total_tires
              : 0
          );
        }
        if (activeIndex === 2) {
          tiresOverview_.set(
            element.fleet_name,
            tireInventoryDetails
              ? tireInventoryDetails.metrics[element.fleet_id].total_tires
              : 0
          );
          selected_tires.set(element.fleet_name, [1]);
          temp2.push({
            fleet: element,
            tires: [1],
          });
        }
        if (activeIndex === 4) {
          const vehiclesInFleet = allResources?.filter(
            vehicle => vehicle.fleet_id === element.fleet_id
          );
          if (vehiclesInFleet?.length > 0) {
            let trucks_arr = vehiclesInFleet.filter(truck => {
              return (
                truck.vehicle_type === VEHICLE.vehicle_type.TRUCK.toUpperCase()
              );
            });
            let trailers_arr = vehiclesInFleet.filter(trailer => {
              return (
                trailer.vehicle_type ===
                VEHICLE.vehicle_type.TRAILER.toUpperCase()
              );
            });
            let buses_arr = vehiclesInFleet.filter(bus => {
              return (
                bus.vehicle_type === VEHICLE.vehicle_type.BUS.toUpperCase()
              );
            });
            const vehicles_arr = trucks_arr
              .concat(trailers_arr)
              .concat(buses_arr);

            const final_vehicles = [];

            for (let k = 0; k < vehicles_arr.length; k++) {
              if (trackedVehicleIds?.includes(vehicles_arr[k][VEHICLE.ID])) {
                final_vehicles.push(vehicles_arr[k]);
              }
            }
            temp.push({
              fleet: element,
              vehicles: final_vehicles,
            });
            setVehicleCounter(
              vehicleCounter + activeIndex === 2
                ? final_vehicles.length
                : vehicles_arr.length
            );
            selected_vehicles.set(element.fleet_name, final_vehicles);
            fleet_map.set(element.fleet_name, element.fleet_id);
          }
        }
      }

      setSelectedVehicles(Object.fromEntries(selected_vehicles));
      setSelectedVehiclesStatic(Object.fromEntries(selected_vehicles));
      setSelectedTires(Object.fromEntries(selected_tires));
      setTiresOverview(Object.fromEntries(tiresOverview_));

      setVehicles(temp);
      setVehiclesStatic(temp);
      setTires(temp2);
      setDataIsLoading(false);
    } else {
      setSelectedVehicles({});
      setSelectedTires([]);
      setVehicles([]);
      setVehiclesStatic({});
      setVehicleCounter(0);
      setTires([]);
      setTiresOverview({});
    }
  };

  const generateReport = async () => {
    moment.locale('de');
    const file_name = vehicles.map(x => x.fleet.fleet_name).join('+');
    setFiltered(null);
    setIsDownloading(true);
    const now = new Date();
    const dateTimeString = now.toLocaleString();
    try {
      if (activeIndex === 0) {
        let vIds = [];
        let values = Object.values(selectedVehicles);
        let temp = [];
        for (let i = 0; i < values.length; i++) {
          temp = temp.concat(values[i]);
        }
        vIds = temp.map(x => x.vehicle_id);

        let payload = {
          type: 'VEHICLE_LIST',
          vehicle_id: vIds,
        };
        if (dates.startDate) {
          payload = { ...payload, start_time: dates.startDate };
        }
        if (dates.endDate) {
          payload = { ...payload, end_time: dates.endDate };
        }
        const trueKeys = [];

        for (const key in kpis_[activeIndex]) {
          if (kpis_[activeIndex][key] === true) {
            trueKeys.push(key);
          }
        }
        if (trueKeys.length > 0) {
          payload = { ...payload, kpis: trueKeys };
        }

        try {
          const res = await getAllReports(payload);
          if (res?.status === 200) {
            const thisData = processData(res?.data);
            const ws = XLSX.utils.json_to_sheet(thisData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
            XLSX.writeFile(
              workbook,
              `${file_name}_vehicle_list_${dateTimeString}_.xlsx`
            );
          } else {
            dispatch(setNotification(t('common.error'), 'error', true));
          }
        } catch (error) {
          console.error('E20078', error);
        }
      }

      if (activeIndex === 1) {
        let arr = [];
        tires.forEach(itm => {
          const fleet_name = itm['fleet']['fleet_name'];
          if (selectedTires[fleet_name]) {
            arr = arr.concat(
              JSON.parse(JSON.stringify(selectedTires[fleet_name]))
            );
          }
        });
        const modifiedTires = arr?.map(tire_ => {
          const { created_by, system_tire_id, ...rest } = tire_;

          const filter = rest;

          for (const key in kpis_[activeIndex]) {
            if (
              kpis_[activeIndex].hasOwnProperty(key) &&
              kpis_[activeIndex][key] === false &&
              filter.hasOwnProperty(key)
            ) {
              delete filter[key];
            }
          }
          return filter;
        });
        const refinedArr = replaceNullValues(modifiedTires);
        const combinedTires = refinedArr.reduce((acc, curr) => {
          return acc.concat(curr);
        }, []);
        let fleets_with_data = [];
        allFleets.forEach(fleet => {
          combinedTires.forEach((tire, i) => {
            if (tire.fleet_id === fleet.fleet_id) {
              fleets_with_data.push({
                fleet_id: tire.fleet_id,
                fleet_name: fleet.fleet_name,
              });
            }
          });
        });
        const selectedFleets = [];
        const uniqueArray = Array.from(
          new Set(fleets_with_data.map(item => item.fleet_id))
        ).map(fleet_id => {
          return fleets_with_data.find(item => item.fleet_id === fleet_id);
        });
        const workbook = XLSX.utils.book_new();

        uniqueArray.forEach(item => {
          const filter = combinedTires.filter(tire => {
            return item.fleet_id === tire.fleet_id;
          });
          selectedFleets.push(item.fleet_name);

          const worksheet = XLSX.utils.json_to_sheet(filter);

          XLSX.utils.book_append_sheet(workbook, worksheet, item.fleet_name);
        });

        const allFleetNames = selectedFleets.map(item => item).join('+');

        XLSX.writeFile(
          workbook,
          `${allFleetNames}_${moment().format(
            'YYYY-MM-DD HH:mm:ss'
          )}_tire_inventory.xlsx`
        );
      }

      if (activeIndex === 2) {
        let data = [];
        let resource = {};
        let payload = {
          type: 'TIRE_CHANGELOG',
        };
        if (dates.startDate) {
          payload = { ...payload, start_time: dates.startDate };
        }
        if (dates.endDate) {
          payload = { ...payload, end_time: dates.endDate };
        }
        const trueKeys = [];

        for (const key in kpis_[activeIndex]) {
          if (kpis_[activeIndex][key] === true) {
            trueKeys.push(key);
          }
        }
        if (trueKeys.length > 0) {
          payload = { ...payload, kpis: trueKeys };
        }
        for (const element of tires) {
          payload = { ...payload, fleet_id: element.fleet.fleet_id };
          if (element.tires?.length > 0) {
            try {
              const res = await getAllReports(payload);

              if (res?.status === 200) {
                data.push(res?.data);
                resource[element.fleet.fleet_name] = res?.data;
              } else {
                dispatch(setNotification(t('common.error'), 'error', true));
              }
            } catch (error) {
              console.error('E20077', error);
            }
          }
        }
        Object.entries(resource).forEach((entry, i) => {
          const thisData = processData(entry[1]);
          const ws = XLSX.utils.json_to_sheet(thisData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
          XLSX.writeFile(
            workbook,
            `tire_changelog_${dateTimeString}_${entry[0]}.xlsx`
          );
        });
      }
      if (activeIndex === 3) {
        const vIds = [];
        vehicleResource.forEach(vehicle => {
          vIds.push(vehicle.vehicle_id);
        });
        let payload = {
          type: 'VEHICLE_CHANGELOG',
          vehicle_id: vIds,
        };
        if (dates.startDate) {
          payload = { ...payload, start_time: dates.startDate };
        }
        if (dates.endDate) {
          payload = { ...payload, end_time: dates.endDate };
        }
        const trueKeys = [];

        for (const key in kpis_[activeIndex]) {
          if (kpis_[activeIndex][key] === true) {
            trueKeys.push(key);
          }
        }
        if (trueKeys.length > 0) {
          payload = { ...payload, kpis: trueKeys };
        }

        try {
          const res = await getAllReports(payload);
          if (res?.status === 200) {
            const thisData = processData(res?.data);
            const ws = XLSX.utils.json_to_sheet(thisData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, ws, 'Sheet1');
            XLSX.writeFile(
              workbook,
              `${file_name}_vehicle_changelog_${dateTimeString}_.xlsx`
            );
          } else {
            dispatch(setNotification(t('common.error'), 'error', true));
          }
        } catch (error) {
          console.error('E20078', error);
        }
      }
      if (activeIndex === 4) {
        const truthyKeys = Object.keys(kpis_[activeIndex]).filter(
          key => kpis_[activeIndex][key]
        );

        let dataToUse = [];
        let vehicle_id = [];
        let thisData;
        let filteredBySelection = [];
        Object.entries(selectedVehicles).forEach(item => {
          const mapped = item[1].map(vehicle => {
            return { ...vehicle, fleet_name: item[0] };
          });
          dataToUse = dataToUse.concat(mapped);
        });
        vehicle_id = dataToUse.map(vehicle => vehicle.vehicle_id);
        if (
          truthyKeys.length !== Object.keys(kpis_[activeIndex]).length ||
          !!dates.startDate ||
          !!dates.endDate
        ) {
          const truthyKeys = Object.keys(kpis_[activeIndex]).filter(
            key => kpis_[activeIndex][key]
          );
          let payload = {
            type: 'USAGE_REPORTS',
            kpis: truthyKeys,
            vehicle_id: trackedVehicleIds,
          };
          if (dates.startDate) {
            payload = { ...payload, start_time: dates.startDate };
          }
          if (dates.endDate) {
            payload = { ...payload, end_time: dates.endDate };
          }
          const res = await getAllReports(payload);
          if (res.status === 200) {
            thisData = processData(res.data);
          }
        } else {
          if (!usageReports) {
            const data = await getAllUsageReports();
            thisData = processData(data);
          } else {
            thisData = processData(usageReports);
          }
        }
        filteredBySelection = filterItemsByVehicleId(vehicle_id, thisData);
        if (filteredBySelection.length) {
          setFiltered(filteredBySelection);
        } else {
          dispatch(
            setNotification(
              'No reports for selected time period',
              'error',
              true
            )
          );
          setIsDownloading(false);
        }
        setAvailableIds(vehicle_id);
      }
    } catch (error) {
      console.error('E20099', error);
    } finally {
      if (activeIndex !== 4) {
        setIsDownloading(false);
      }
    }
  };

  useEffect(() => {
    let enableButton = Object.entries(selectedVehicles).some(
      ([_, arr]) => arr.length > 0
    );
    setTrackedVehicleSelected(enableButton);
  }, [selectedVehicles]);

  const handleKpiChange = (index, propertyName) => {
    const updatedKpis = [...kpis_];
    updatedKpis[index][propertyName] = !updatedKpis[index][propertyName];
    setKpis_(updatedKpis);
  };

  const downloadUsageReportsHandler = (data, uniqueRegNums) => {
    if (data || data?.length) {
      const { filter } = getKpiData(data, checkIfDecimal);
      const truthyKeys = Object.keys(kpis_[activeIndex]).filter(
        key => kpis_[activeIndex][key]
      );
      let month = [];
      let week = [];
      let year = [];
      const dailyReport = dailyHandler(
        JSON.parse(JSON.stringify(filter)),
        truthyKeys
      );

      const processData = () => {
        const filterCopy = filter.slice();
        const totalItems = uniqueRegNums.length;

        for (let i = 0; i < totalItems; i++) {
          const item = uniqueRegNums[i];
          const x = filterCopy.filter(item_ => item_.vehicle_id === item);
          const weeklyReport = timeSpanFilter(x, 'w', truthyKeys);
          const monthlyReport = timeSpanFilter(x, 'M', truthyKeys);
          const yearlyReport = timeSpanFilter(x, 'y', truthyKeys);

          week = weeklyReport.concat(week);
          month = monthlyReport.concat(month);
          year = yearlyReport.concat(year);
        }
      };

      processData();

      const daySheet = XLSX.utils.json_to_sheet(dailyReport);
      daySheet['!cols'] = new Array(entryNo).fill({ wch: 24 });
      const weekSheet = XLSX.utils.json_to_sheet(week);
      weekSheet['!cols'] = new Array(entryNo).fill({ wch: 24 });
      const monthSheet = XLSX.utils.json_to_sheet(month);
      monthSheet['!cols'] = new Array(entryNo).fill({ wch: 24 });
      const yearSheet = XLSX.utils.json_to_sheet(year);
      yearSheet['!cols'] = new Array(entryNo).fill({ wch: 24 });

      const workbook = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(workbook, daySheet, 'Daily');
      XLSX.utils.book_append_sheet(workbook, weekSheet, 'Weekly');
      XLSX.utils.book_append_sheet(workbook, monthSheet, 'Monthly');
      XLSX.utils.book_append_sheet(workbook, yearSheet, 'Yearly');
      XLSX.writeFile(
        workbook,
        `${moment().format('YYYY-MM-DD HH:mm:ss')}_reports.xlsx`
      );
    }
    setIsDownloading(false);
  };

  function compareDates(date1, date2) {
    const date1Parsed = new Date(date1);
    const date2Parsed = new Date(date2);

    if (date1Parsed < date2Parsed) {
      return -1;
    } else if (date1Parsed > date2Parsed) {
      return 1;
    } else {
      return 0;
    }
  }
  const timeSpanFilter = (entry, format, titles, form) => {
    const result = entry.reduce((val, obj) => {
      let comp = moment(obj['timestamp']).format(format);
      (val[comp] = val[comp] || []).push(obj);
      return val;
    }, {});

    const length = Object.values(result).length;

    const temp = [];

    const newTitles = titles.filter(title => {
      return (
        title !== 'timestamp' &&
        title !== 'mission_profile' &&
        title !== VEHICLE.ID
      );
    });

    for (let i = 0; i < length; i++) {
      let timeSpanObj = {};

      const items = Object.values(result)[i];
      const sortedArray = items.sort((a, b) =>
        compareDates(a.timestamp, b.timestamp)
      );
      const earliest = sortedArray[0];
      const latest = sortedArray[sortedArray.length - 1];

      const start = items[0];
      const startW = earliest;
      const end = latest;

      for (let j = 0; j < newTitles.length; j++) {
        if (timeSpanObj[newTitles[j]] === undefined) {
          timeSpanObj[newTitles[j]] = 0;
        }

        let nanEntries = 0;

        const kpiSum = items.reduce(function (acc, val) {
          if (isNaN(val[newTitles[j]])) {
            nanEntries++;
          }
          return (
            (isNaN(acc) ? 0 : acc) +
            parseFloat(isNaN(val[newTitles[j]]) ? 0 : val[newTitles[j]])
          );
        }, 0);

        if (
          newTitles[j].includes('average') ||
          newTitles[j].includes('share')
        ) {
          timeSpanObj[newTitles[j]] = checkIfDecimal(
            kpiSum / (items.length - nanEntries)
          );
        } else {
          timeSpanObj[newTitles[j]] = checkIfDecimal(kpiSum);
        }

        if (isNaN(timeSpanObj[newTitles[j]])) {
          timeSpanObj[newTitles[j]] = 'N/A';
        }
      }
      timeSpanObj['vehicle'] = getVehicleName(entry[0].vehicle_id);
      const year = new Date(start.timestamp).getFullYear();
      const month = new Date(start.timestamp).toLocaleString('en-US', {
        month: 'short',
      });
      if (format === 'y') {
        timeSpanObj.year = year;
      }
      if (format === 'M') {
        timeSpanObj.month = `${month}, ${year}`;
      }
      if (format === 'w') {
        timeSpanObj.end_date = end.timestamp;
        timeSpanObj.start_date = startW.timestamp;
      }
      if (!!start.mission_profile) {
        timeSpanObj.mission_profile = start.mission_profile;
      }
      temp.push(timeSpanObj);
      entryNo = Object.keys(timeSpanObj).length;
    }

    if (form !== 'initial') {
      const report = temp.map(obj => {
        // change key name
        const kpiTitles = Object.keys(obj);
        for (let i = 0; i < kpiTitles.length - 1; i++) {
          let check =
            keyValuePair(kpiTitles[i]) !== undefined &&
            keyValuePair(kpiTitles[i]) !== null;
          let units = keyValuePair(kpiTitles[i]);
          let output = `(${units})`;

          // Add units to keys
          obj[`${kpiTitles[i]} ${check ? output : ''}`] = obj[kpiTitles[i]];

          delete obj[kpiTitles[i]];
        }
        const entries = Object.entries(obj);

        entries.reverse();

        return Object.fromEntries(entries);
      });

      return report;
    } else return;
  };

  const getVehicleName = entry => {
    const viaReg = allVehicles?.find(vehicle => vehicle.reg_plate === entry);
    if (viaReg) {
      return viaReg?.name;
    } else {
      const viaId = allVehicles?.find(vehicle => vehicle.vehicle_id === entry);
      return viaId?.name;
    }
  };
  function dailyHandler(data, titles) {
    let report = data?.map(obj => {
      // change key name
      obj['Date'] = obj['timestamp'];

      obj['vehicle'] = getVehicleName(obj.vehicle_id);
      delete obj['timestamp'];
      delete obj[VEHICLE.ID];

      // const kpiTitles = Object.keys(obj);
      const kpiTitles = titles.filter(title => {
        return (
          title !== 'timestamp' &&
          title !== 'mission_profile' &&
          title !== VEHICLE.ID
        );
      });

      for (let i = 0; i < kpiTitles.length; i++) {
        let check = keyValuePair(kpiTitles[i]) !== null;
        let units = keyValuePair(kpiTitles[i]);
        let output = `(${units})`;

        if (obj[kpiTitles[i]] === '') {
          obj[kpiTitles[i]] = 'N/A';
        }

        // Add units to keys
        obj[`${kpiTitles[i]} ${check ? output : ''}`] = obj[kpiTitles[i]];

        delete obj[kpiTitles[i]];
      }

      for (let key in obj) {
        const newKey = key.replace(/\s*\(undefined\)\s*/g, '');
        if (newKey !== key) {
          obj[newKey] = obj[key];
          delete obj[key];
        }
      }
      return obj;
    });

    //Removing Empty Columns
    let report_keys = Object.keys(report[0]);
    let delete_keys = [];
    for (let i = 0; i < report_keys.length; i++) {
      let key = report_keys[i];
      let flag = 1;
      for (let j = 0; j < report.length; j++) {
        if (
          report[j][key] === '' ||
          report[j][key] === null ||
          report[j][key] === undefined
        ) {
          continue;
        } else {
          flag = 0;
          break;
        }
      }
      if (flag) {
        delete_keys.push(key);
      }
    }
    if (delete_keys.length > 0) {
      return report.map(obj => {
        for (let i = 0; i < delete_keys.length; i++) {
          delete obj[delete_keys[i]];
        }
        return obj;
      });
    }

    return report;
  }
  const keyValuePair = title => {
    const items = keyValue;

    return items[title];
  };

  const checkIfDecimal = entry => {
    if (typeof entry === 'string') {
      if (entry.includes('.')) {
        let converted = parseFloat(entry);
        const result = converted - Math.floor(converted) !== 0;

        return result ? converted.toFixed(2) : converted;
      }
      return entry;
    } else {
      const result = entry - Math.floor(entry) !== 0;

      return result ? parseFloat(entry).toFixed(2) : entry;
    }
  };
  const toggleAll = index => {
    const updatedCheckboxes = {};
    const updatedKpis = [...kpis_];
    for (const key in updatedKpis[index]) {
      updatedCheckboxes[key] = areAllBoxesUnchecked ? true : false;
    }
    updatedKpis[index] = updatedCheckboxes;
    setKpis_(updatedKpis);
  };

  const checkedItemChangeHandler = vehicle => {
    setVehicleResource(curr => {
      if (curr.findIndex(item => item.name === vehicle.name) !== -1) {
        return curr.filter(item => item.name !== vehicle.name);
      } else {
        return [...curr, vehicle];
      }
    });
  };

  const handleToggle = (fleet, entry) => {
    if (activeIndex === 0 || activeIndex === 3 || activeIndex === 4) {
      if (
        selectedVehicles[fleet]
          .map(x => x.vehicle_id)
          .includes(entry.vehicle_id)
      ) {
        setSelectedVehicles({
          ...selectedVehicles,
          [fleet]: selectedVehicles[fleet].filter(
            x => x.vehicle_id !== entry.vehicle_id
          ),
        });
        setVehicleCounter(vehicleCounter - 1);
      } else {
        setSelectedVehicles({
          ...selectedVehicles,
          [fleet]: [...selectedVehicles[fleet], entry],
        });
        setVehicleCounter(vehicleCounter + 1);
      }
    }
    if (activeIndex === 1) {
      if (selectedTires[fleet].map(x => x.tire_id).includes(entry.tire_id)) {
        setSelectedTires({
          ...selectedTires,
          [fleet]: selectedTires[fleet].filter(
            x => x.tire_id !== entry.tire_id
          ),
        });
      } else {
        setSelectedTires({
          ...selectedTires,
          [fleet]: [...selectedTires[fleet], entry],
        });
      }
    }
  };

  const dateChangeHandler = (date, key) => {
    if (date === null || date.toString() === 'Invalid Date') {
      setDates({
        ...dates,
        [key]: null,
      });
    } else {
      setDates({
        ...dates,
        [key]: moment(date).format('YYYY-MM-DD'),
      });
    }
  };

  const areAllBoxesUnchecked = Object.values(kpis_[activeIndex ?? 0])?.every(
    value => !value
  );
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const columns = [
    {
      key: 'type',
      title: 'Report Type',
      canSort: false,
    },
    {
      key: 'action',
      title: '',
      canSort: false,
      render: row => (
        <Button
          className={classes.button}
          onClick={() => {
            setActiveIndex(row.id);
            setDrawerIsOpen(true);
          }}
          endIcon={<Input />}
          data-hook={`report-${row.id}`}
        >
          {t('multiLayerReport.create')}
        </Button>
      ),
    },
  ];

  const tableData = [
    { type: t('multiLayerReport.reportTypes.list'), action: '', id: 0 },
    { type: t('multiLayerReport.reportTypes.inventory'), action: '', id: 1 },
    {
      type: t('multiLayerReport.reportTypes.tireChangelog'),
      action: '',
      id: 2,
    },
    {
      type: t('multiLayerReport.reportTypes.vehicleChangelog'),
      action: '',
      id: 3,
    },
    { type: t('multiLayerReport.reportTypes.usage'), action: '', id: 4 },
  ];
  const renderVehicleReports = () => {
    if (activeIndex > 1) {
      return (
        <Paper elevation={0} className={classes.paper}>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            data-hook='start-date-picker'
          >
            <Box data-cy='start-date-picker' width={'100%'}>
              <DatePicker
                label={t('multiLayerReport.startDate')}
                value={dates.startDate ? parseISO(dates.startDate) : null}
                maxDate={parseISO(dates.endDate)}
                minDate={Date.now}
                onChange={date => {
                  dateChangeHandler(date, 'startDate');
                }}
                format='yyyy.MM.dd'
                data-hook='start-date-picker'
                sx={{ width: '100%' }}
              />
            </Box>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box data-cy='end-date-picker' width={'100%'}>
              <DatePicker
                label={t('multiLayerReport.endDate')}
                value={dates.endDate ? parseISO(dates.endDate) : null}
                minDate={parseISO(dates.startDate)}
                onChange={date => {
                  dateChangeHandler(date, 'endDate');
                }}
                format='yyyy.MM.dd'
                data-hook='end-date-picker'
                sx={{ width: '100%' }}
              />
            </Box>
          </LocalizationProvider>
        </Paper>
      );
    }
  };

  const webStyle = {
    noRenderVehicleView: { textAlign: 'center', color: '#69A628' },
  };

  const isMultipleSelectDisabled =
    activeIndex === TIRE_CHANGELOG_INDEX ||
    activeIndex === VEHICLE_CHANGELOG_INDEX;

  const renderNovehicleView = () => {
    if (vehicles.length < 1 && touched && activeIndex === 0) {
      return (
        <Box style={webStyle.noRenderVehicleView}>{t('common.noVehicles')}</Box>
      );
    } else if (
      Object.keys(tiresOverview).length &&
      Object.entries(tiresOverview).every(item => item[1] === 0) &&
      (activeIndex === 1 || activeIndex === 2) &&
      touched
    ) {
      return (
        <Box style={webStyle.noRenderVehicleView}>
          No tires associated with fleet please Add some to download the report.
        </Box>
      );
    } else if (
      touched &&
      activeIndex === 3 &&
      vehicleResource.length === 0 &&
      vehicles.length === 0
    ) {
      return (
        <Box style={webStyle.noRenderVehicleView}>
          No vehicles present in the fleet please Add some to download the
          report.
        </Box>
      );
    } else if (
      activeIndex === 4 &&
      vehicles?.[0]?.vehicles.length === 0 &&
      !trackedVehicleSelected
    ) {
      return (
        <Box style={webStyle.noRenderVehicleView}>
          No tracked vehicles are present in the selected fleet please Add some
          to download the report.
        </Box>
      );
    } else if (noKpisSelected) {
      return (
        <Box style={webStyle.noRenderVehicleView}>
          No Kpis are selected please select any fleet to download the report.
        </Box>
      );
    }
  };
  const renderSelectVehicleForm = () => {
    if (vehicles.length > 0) {
      return (
        <Box className={classes.kpiGrid}>
          <Typography variant='h6' className={classes.heading}>
            {t('multiLayerReport.selectVehicles')}
          </Typography>
          {activeIndex === 0 || activeIndex === 4 ? (
            <Box id={'accordion-wrapper'} maxHeight={500} px={0.5}>
              {vehicles?.map((item, i) => (
                <Accordion
                  key={i}
                  id={'accordion-wrapper-1'}
                  style={{ border: '1px solid grey', borderRadius: '10px' }}
                  className='accordion-class'
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    className='select-accordion'
                  >
                    <Typography variant='h6' data-hook='fleet-name'>
                      {item?.fleet.fleet_name}
                    </Typography>
                  </AccordionSummary>
                  {selectedVehicles[item?.fleet.fleet_name] !== undefined && (
                    <Box
                      maxHeight={175}
                      className={classes.common}
                      data-hook='vehicle-list'
                    >
                      <>
                        <AccordionDetails
                          style={{ textAlign: 'center' }}
                          className={classes.accordion}
                        >
                          <div className={classes.searchBar}>
                            <IconButton aria-label='search'>
                              <Search color='primary' />
                            </IconButton>
                            <InputBase
                              placeholder='Search...'
                              fullWidth
                              inputProps={{ 'aria-label': 'search' }}
                              onChange={e => filterSearchHandler(e, i)}
                            />
                          </div>
                          {item?.vehicles?.length > 0 ? (
                            <FormGroup className={classes.root}>
                              {item?.vehicles.map((vehicle, i) => (
                                <FormControlLabel
                                  key={vehicle.name}
                                  control={
                                    <Checkbox
                                      checked={selectedVehicles[
                                        item.fleet.fleet_name
                                      ]
                                        .map(x => x.vehicle_id)
                                        .includes(vehicle.vehicle_id)}
                                      color='primary'
                                      onChange={() =>
                                        handleToggle(
                                          item?.fleet.fleet_name,
                                          vehicle
                                        )
                                      }
                                      data-hook={`vehicle-check-${i}`}
                                    />
                                  }
                                  label={vehicle.name}
                                />
                              ))}
                            </FormGroup>
                          ) : (
                            <Typography
                              variant='subtitle1'
                              gutterBottom
                              align='center'
                            >
                              {activeIndex === 4 && (
                                <span className={classes.span}>
                                  {t('multiLayerReport.noTrackedVehicles')}
                                </span>
                              )}
                            </Typography>
                          )}
                        </AccordionDetails>
                      </>
                    </Box>
                  )}
                </Accordion>
              ))}
            </Box>
          ) : (
            <Box maxHeight={500} px={0.5}>
              {vehicles?.map((item, i) => (
                <Accordion key={i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                    style={{ border: '1px solid grey', borderRadius: '10px' }}
                  >
                    <Typography variant='h6'>
                      {item?.fleet.fleet_name}
                    </Typography>
                  </AccordionSummary>
                  {selectedVehicles[item?.fleet.fleet_name] !== undefined && (
                    <Box className={classes.box}>
                      {item?.vehicles?.length > 0 ? (
                        <AccordionDetails className={classes.accordion}>
                          <Box className={classes.searchBar} marginBottom={2}>
                            <IconButton aria-label='search'>
                              <Search color='primary' />
                            </IconButton>
                            <InputBase
                              placeholder='Search...'
                              fullWidth
                              inputProps={{ 'aria-label': 'search' }}
                              onChange={e => filterSearchHandler(e, i)}
                              data-hook='search-bar'
                            />
                          </Box>
                          <SelectAllBtn
                            setVehicles={setVehicleResource}
                            selectedVehiclesStatic={selectedVehiclesStatic}
                            fleet_id={item?.fleet?.fleet_id}
                            resource={'all'}
                            method={'PUT'}
                            vehicles={item?.vehicles}
                          />
                          <FormGroup
                            className={classes.root}
                            data-hook='vehicle-display'
                          >
                            {item?.vehicles.map((vehicle, i) => (
                              <FormControlLabel
                                key={i}
                                data-hook='vehicle-item'
                                control={
                                  <Checkbox
                                    edge='start'
                                    checked={
                                      vehicleResource.findIndex(
                                        item => item.name === vehicle.name
                                      ) !== -1
                                    }
                                    color='primary'
                                    disableRipple
                                    onChange={e =>
                                      checkedItemChangeHandler(vehicle)
                                    }
                                  />
                                }
                                label={vehicle.name}
                              />
                            ))}
                          </FormGroup>
                        </AccordionDetails>
                      ) : (
                        <Typography
                          variant='subtitle1'
                          gutterBottom
                          align='center'
                        >
                          <span className={classes.span}>
                            {t('multiLayerReport.noTrackedVehicles')}
                          </span>
                        </Typography>
                      )}
                    </Box>
                  )}
                </Accordion>
              ))}
            </Box>
          )}
        </Box>
      );
    }
  };

  const renderTireReport = () => {
    if (Object.entries(tiresOverview).length > 0) {
      return (
        <>
          <Typography variant='h6' className={classes.heading}>
            {t('multiLayerReport.overview')}
          </Typography>
          <Paper elevation={0} className={classes.paper}>
            {Object.entries(tiresOverview).map(entry => {
              return (
                <RowContent key={entry[0]} label={entry[0]} value={entry[1]} />
              );
            })}
          </Paper>
        </>
      );
    }
  };

  const renderMultilayerSelectFleet = () => {
    if (activeIndex !== null) {
      return (
        <Grid item xs={6} lg={3} className={classes.kpiGrid}>
          <Typography variant='h6' className={classes.heading}>
            {activeIndex < 1
              ? t('multiLayerReport.selectFleet')
              : t('multiLayerReport.selectFleet2')}
          </Typography>
          <Autocomplete
            multiple={isMultipleSelectDisabled ? false : true}
            id='tags-outlined'
            options={allFleets}
            getOptionLabel={option => option.fleet_name}
            filterSelectedOptions
            onChange={handleChange}
            onFocus={() => setTouched(true)}
            renderInput={params => (
              <TextField
                {...params}
                style={{ backgroundColor: '#fff' }}
                variant='outlined'
                label={t('common.fleets')}
              />
            )}
            data-hook='select-fleet'
          />
          {renderNovehicleView()}
          {renderVehicleReports()}
        </Grid>
      );
    }
  };

  const renderKpiList = () => {
    return (
      <Box display={'flex'} flexWrap={'wrap'} width={'100%'}>
        {Object.keys(kpis_[activeIndex])?.map((kpi, i) => {
          return (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  checked={kpis_[activeIndex][kpi]}
                  color='primary'
                  onChange={() => handleKpiChange(activeIndex, kpi)}
                  name={kpi}
                  data-hook={`kpi-select-${i}`}
                />
              }
              label={kpi}
            />
          );
        })}
      </Box>
    );
  };

  const renderMultilayerSelectKpi = () => {
    if (activeIndex !== null) {
      return (
        <Grid
          item
          xs={6}
          lg={3}
          className={classes.kpiGrid}
          marginTop={'10px'}
          data-hook='select-kpis-section'
        >
          <Typography variant='h6' className={classes.heading}>
            {t('multiLayerReport.selectKpis')}
          </Typography>

          <Box
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            justifyContent='flex-start'
            paddingLeft={'5px'}
          >
            <Button
              className={classes.toggleBtn}
              variant='outlined'
              color='primary'
              size='small'
              onClick={() => toggleAll(activeIndex)}
            >{`${
              areAllBoxesUnchecked
                ? t('multiLayerReport.select')
                : t('multiLayerReport.deselect')
            }`}</Button>
            <FormControl component='fieldset' className={classes.formControl}>
              <FormGroup>{renderKpiList()}</FormGroup>
            </FormControl>
          </Box>
        </Grid>
      );
    }
  };

  const modalCloseHandler = () => {
    setDrawerIsOpen(false);
    setActiveIndex(null);
    setSelectedVehicles({});
    setVehicles([]);
    setSelectedTires({});
    setVehicles([]);
    setVehiclesStatic([]);
    setTires([]);
    setDates({ startDate: null, endDate: null });
    setTiresOverview({});
    setTouched(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <Box marginBottom={'20px'}>
        <Breadcrumbs separator='>' aria-label='breadcrumb'>
          <Typography className={classes.vehicleName} color='textPrimary'>
            {t('multiLayerReport.reportPageTitle')}
          </Typography>
        </Breadcrumbs>
      </Box>
      <CustomTable columns={columns} data={tableData} showFooter={false} />

      <Modal
        open={drawerIsOpen}
        onClose={modalCloseHandler}
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
      >
        <Box
          p={2}
          width={800}
          minHeight={'200px'}
          bgcolor={'white'}
          overflow={'auto'}
          position={'relative'}
        >
          {renderMultilayerSelectFleet()}
          {renderSelectVehicleForm()}
          {renderTireReport()}
          {renderMultilayerSelectKpi()}
          <div className={classes.closeIcon} onClick={modalCloseHandler}>
            <IconButton data-hook='close-upload-modal'>
              <Close />
            </IconButton>
          </div>
          <Box
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
          >
            <Button
              color={'primary'}
              type={'submit'}
              variant={'contained'}
              disabled={buttonIsDisabled || isDownloading}
              onClick={() => generateReport()}
              className={classes.button}
              endIcon={<Download />}
              data-hook='download-report'
            >
              {!isDownloading || activeIndex === 4 ? (
                t('multiLayerReport.downloadReport')
              ) : (
                <CircularProgress size={30} />
              )}
            </Button>
          </Box>
        </Box>
      </Modal>
      {(isDownloading || dataIsLoading) && (
        <LoadAnimation
          loading={isDownloading || dataIsLoading}
          setLoading={setIsDownloading || setDataIsLoading}
          useMultiple={isDownloading ? true : false}
        />
      )}
    </div>
  );
};

export default AllReports;

function getKpiData(allData, checkIfDecimal) {
  const filter = allData?.map(row => {
    let arr;
    if (row) arr = Object.entries(row);
    // Filter out entries with null values and reverse the order so that "Date" comes first
    const filtered = arr?.reverse().filter(([key, value]) => {
      return value !== null;
    });

    // Convert back to object
    return filtered ? Object.fromEntries(filtered) : {};
  });

  if (filter?.length > 0) {
    for (let a = 0; a < filter.length; a++) {
      let kpiTitles = Object.keys(filter[0]);

      for (let index = 0; index < kpiTitles.length; index++) {
        if (kpiTitles[index] === 'timestamp') {
          continue;
        } else {
          filter[a][kpiTitles[index]] = checkIfDecimal(
            filter[a][kpiTitles[index]]
          );
        }
      }
    }
  }

  return { filter };
}

const RowContent = ({ label, value }) => {
  const { classes } = useStyles();
  return (
    <Box display='flex' justifyContent={'space-between'}>
      <Typography variant='h6' className={classes.label}>
        {label}
      </Typography>
      <Box whiteSpace={'noWrap'}>
        {' '}
        <Typography variant='h6' className={classes.value}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export const processData = dataToProcess => {
  const csvData = dataToProcess; // CSV data as a string

  // Split the CSV data into lines
  const csvLines = csvData.split('\n');

  // Parse the header row to get column names and remove double quotes
  const header = csvLines[0]
    .split(',')
    .map(columnName => columnName.replace(/"/g, ''));

  // Parse the data rows and remove double quotes
  const data = [];
  for (let i = 1; i < csvLines.length; i++) {
    const row = csvLines[i].split(',').map(item => item.replace(/"/g, ''));
    const rowData = {};
    for (let j = 0; j < header.length; j++) {
      rowData[header[j]] = row[j];
    }
    data.push(rowData);
  }

  return data;
};

function filterItemsByVehicleId(itemsArray, objectsArray) {
  const filteredObjects = objectsArray?.filter(object => {
    return itemsArray.includes(object.vehicle_id);
  });

  return filteredObjects;
}

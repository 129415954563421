import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

const useStyles = makeStyles()(theme => {
  return {
    paper: {
      width: '770px',
      // width: '100%',
      height: 'auto',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      padding: '2rem 3rem',
    },
    form: {
      marginTop: theme.spacing(5),
      width: 574,
    },
    formControl: {
      width: '100%',
    },
    btns: {
      flex: '30%',
      borderRadius: 24,
      textTransform: 'unset',
    },
  };
});

const DashboardSettings = ({
  preferences,
  setPreferences,
  staticData,
  confirmHandler,
  setClose,
  isUpdating,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  React.useEffect(() => {
    setPreferences(staticData);
    // eslint-disable-next-line
  }, []);

  const checkboxHandler = e => {
    const updatedPreferences = { ...preferences };
    updatedPreferences[e.target.name] = e.target.checked;
    setPreferences(updatedPreferences);
  };

  const closeHandler = () => {
    setPreferences({ ...staticData });
    setClose(false);
  };

  if (!preferences) {
    return '';
  }

  return (
    <Paper className={classes.paper}>
      <Typography variant='subtitle2'>
        {t('homePage.dashboardEditTitle')}
      </Typography>

      <Box display={'flex'} flexDirection={'column'}>
        {Object.entries(preferences)
          .filter(item => item[0] !== 'user_id')
          .map(item => (
            <FormControlLabel
              style={{ alignSelf: 'flex-start' }}
              key={item[0]}
              control={
                <Checkbox
                  checked={item[1]}
                  color='primary'
                  name={item[0]}
                  onChange={checkboxHandler}
                  disabled={
                    Object.values(preferences).filter(item => !!item).length <=
                      3 && !!item[1]
                  }
                />
              }
              label={t(`homePage.dashboardSections.${item[0]}`)}
            />
          ))}
      </Box>
      <Box
        display='flex'
        width='100%'
        justifyContent='space-between'
        gap={'16px'}
      >
        <Button
          variant='outlined'
          color='primary'
          data-hook='cancel-button'
          className={classes.btns}
          onClick={closeHandler}
        >
          {t('newFleetModal.cancel')}
        </Button>
        <Button
          variant='contained'
          data-hook='confirm-button'
          color='primary'
          className={classes.btns}
          disabled={isEqual(staticData, preferences) || isUpdating}
          onClick={confirmHandler}
        >
          {!isUpdating ? (
            t('newFleetModal.confirm')
          ) : (
            <CircularProgress size={30} color='inherit' />
          )}
        </Button>
      </Box>
    </Paper>
  );
};

export default DashboardSettings;

DashboardSettings.propTypes = {
  preferences: PropTypes.object,
  setPreferences: PropTypes.func,
  confirmHandler: PropTypes.func,
  setClose: PropTypes.func,
  isUpdating: PropTypes.bool,
  staticData: PropTypes.object,
};

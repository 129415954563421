import React, { useState } from 'react';
import { boolean } from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  Paper,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import moment from 'moment';
// import Loading from 'components/Loading';
import { Link } from 'react-router-dom';
import { TIRES, USER_LS, USER_ROLES } from 'utils/string_utils';
import { deleteTask, readTire, resolveTask } from 'services/fleet_service';
import { useSelector } from 'react-redux';
import {
  fleetOverviewSelector,
  tireInventoryDataSelector,
} from 'redux/fleets/ducks';
import {
  setTaskSelector,
  setUserSelector,
  updateTasks,
} from 'redux/users/ducks';
import CustomTable from 'components/custom/CustomTable';
import ModifyTireModal from '../VehiclePage/TireConfig/TireOperationsModal/ModifyTireModal';
import LoadAnimation from 'components/LoadAnimation';
import CustomTextField from 'components/custom/CustomTextField';
import { tiresDbSelector } from 'redux/tireInventory/ducks';
import { useDispatch } from 'react-redux';
import { setNotification } from 'redux/notifications/ducks';

const useStyles = makeStyles()(theme => {
  return {
    commonSkeleton: {
      height: '21%',
      width: '100%',
    },
    actionRequiredTableSkeleton: {
      borderRadius: 5,
    },
    fullscreen: {
      cursor: 'pointer',
      color: ' rgba(0, 0, 0, 0.54)',
      // padding: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      width: '800px',
      maxHeight: '90%',
      border: 'none',
      padding: '2rem 3rem',
      overflow: 'scroll',
    },
    box: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 10,
    },
    span: {
      opacity: 1,
      transform: 'none',
      fontWeight: 500,
    },
    heading: { fontSize: 'clamp(18px, 1.3vw, 3rem)', fontWeight: 600 },
    loadingDiv: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    list: {
      listStyle: 'none',
      maxHeight: 100,
      overflowY: 'auto',
      padding: 0,
    },
    tireLink: {
      color: theme.palette.primary.main,
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    navBtns: {
      textTransform: 'unset',
      flex: '30%',
      borderRadius: 24,
    },
    form: {
      width: '100%',
    },
  };
});

const NA = 'N/A';

const NAComponent = ({ val, type }) => {
  if (type === boolean) return <span>{!!val}</span>;
  return (
    <span
      style={{
        display: 'inline-block',
        maxWidth: 300,
        maxHeight: 220,
        overflowY: 'auto',
      }}
    >
      {val || NA}
    </span>
  );
};
const initialPayload = {
  isRetread: true,
  [TIRES.TREAD_DEPTH]: '',
  tire_id: '',
  mileage: '',
};
const Tasks = ({ loading }) => {
  const { t } = useTranslation();
  const userLs = JSON.parse(USER_LS);
  const { classes } = useStyles();
  const [open2, setOpen2] = useState(false);
  const [payload, setPayload] = useState({ ...initialPayload });
  const availableTasks = useSelector(setTaskSelector);
  const tireInventoryData = useSelector(tireInventoryDataSelector);
  const [isLoading, setLoading] = useState(false);
  const [tireData, setTireData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [editablePayload, setEditablePayload] = useState({
    before_service: [],
    after_service: [],
    tire_size: null,
  });
  const [editablePayloadStatic, setEditablePayloadStatic] = useState({
    before_service: [],
    after_service: [],
    tire_size: null,
  });
  const tiresResource = useSelector(tiresDbSelector);
  const availableSizes = [...new Set(tiresResource?.sizes)];
  const [data, setData] = useState(null);
  let dispatch = useDispatch();
  const userDetails = useSelector(setUserSelector) || userLs;
  const fleets = useSelector(fleetOverviewSelector)?.fleets;
  const userIsReporter = userDetails?.iam_role === USER_ROLES.reporter;

  const closeHandler = () => {
    setOpen2(false);
  };
  const handleDeleteTask = async row => {
    try {
      const res = await deleteTask(row.task_id);
      if (res?.status === 200) {
        dispatch(updateTasks(fleets));
      }
    } catch (err) {
      console.error(err);
    }
  };
  function formatDate(date) {
    return date ? moment(date).format('YYYY.MM.DD') : '';
  }

  let columns = [
    {
      key: 'title',
      title: t('homePage.taskBoard.title'),
      render: row => (
        <>
          {row?.source_of_creation === 'TIRE_UPDATE' ||
          row?.source_of_creation === 'AUDIT' ? (
            <p>
              <Link
                data-testid={`tire-navigation-link-${row.entity_id}`}
                to={`/tire-inventory/${row?.metadata?.fleet_id}/${row?.entity_id}`}
                // target='_blank'
                className={classes.tireLink}
              >
                Tire {row?.entity_id}
              </Link>
              : {row?.title}
            </p>
          ) : (
            <NAComponent val={row?.title} />
          )}
        </>
      ),
    },
    {
      key: 'description',
      title: t('homePage.taskBoard.description'),
      render: row => (
        <>
          {row?.metadata?.task_level?.[0] === 'T201' ||
          row?.metadata?.task_level?.[0] === 'T301' ||
          row?.metadata?.task_level?.[0] === 'T203' ? (
            <>
              <span style={{ textTransform: 'capitalize' }}>
                {t(`common.${row?.metadata?.vehicle_type?.toLowerCase()}`)}
              </span>{' '}
              {linkToVehicle(
                `/fleets-data/${
                  row?.metadata?.fleet_id
                }/${row?.metadata?.vehicle_type?.toLowerCase()}${
                  row?.vehicle_type === 'BUS' ? 'es' : 's'
                }/${row?.metadata?.vehicle_id}`,
                false,
                row.metadata.vehicle_name
              )}
            </>
          ) : row?.description?.length > 1 ? (
            <ul className={classes.ul}>
              {row.description?.map((item, i) => (
                <li data-testid={`vehicle-link-${i}`} key={item}>
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            row?.description[0]
          )}
        </>
      ),
    },
    {
      key: 'source_of_creation',
      title: t('homePage.taskBoard.source'),
      render: row => (
        <span>
          {row.source_of_creation.split('_')[0] === 'TIRE'
            ? t(`common.${row.source_of_creation.split('_')[0].toLowerCase()}`)
            : row.source_of_creation.split('_')[0]}
        </span>
      ),
    },

    {
      key: 'createdAt',
      title: t('homePage.taskBoard.createdAt'),
      render: row => <NAComponent val={formatDate(row?.createdAt)} />,
    },
    {
      key: 'actions',
      title: t('homePage.taskBoard.actions'),
      render: row => (
        <>
          {row?.title === '1' ? (
            <Button
              variant='outlined'
              color='primary'
              style={{ textTransform: 'unset' }}
              onClick={() => buttonClickHandler(row)}
            >
              {t('common.unmount')}
            </Button>
          ) : (
            <></>
          )}
          {row?.metadata?.task_level &&
            row?.metadata?.task_level?.map(item => decodeNextAction(item, row))}
        </>
      ),
    },
  ];

  if (userIsReporter) {
    columns = columns.filter(item => item.key !== 'actions');
  }

  const handleClose = () => {
    // setOpen(false);
    setOpenModal(false);
  };
  const handlePayloadChange = async (e, entry, i) => {
    const updatedPayload = { ...editablePayload };
    const updatedArray = [...updatedPayload[entry]];
    updatedArray[i] = { ...updatedArray[i], [e.target.name]: e.target.value };
    updatedPayload[entry] = updatedArray;
    setEditablePayload(updatedPayload);
  };

  const doneBtnClickHandler = async (e, row) => {
    const data_ = row ?? data;
    setLoading(true);
    let payload = {
      entity_id: data_.entity_id,
      customer_id: data_.customer_id,
    };
    payload.metadata = { ...data_.metadata.payload };
    payload.metadata.before_service = editablePayload.before_service;
    payload.metadata.after_service = editablePayload.after_service;
    payload.metadata.tire_size = editablePayload.tire_size;
    try {
      const response = await resolveTask(data_.task_id, payload);
      if (response.status === 200) {
        handleClose();
      } else {
        dispatch(setNotification(response?.data.message, 'error', true));
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const buttonClickHandler = async row => {
    let thisTire;
    try {
      if (!tireInventoryData?.[row?.metadata.fleet_id]) {
        setLoading(true);
        const res = await readTire(row?.entity_id);
        if (res.status === 200) {
          const resource = res?.data?.resp;
          thisTire = resource;
        }
      } else {
        const fleetTires =
          tireInventoryData?.[row?.metadata.fleet_id]?.originalData;
        thisTire = fleetTires.find(
          tire => tire.system_tire_id === row.entity_id
        );
      }
    } catch (error) {
      console.error('E20045');
    } finally {
      setLoading(false);
    }
    setOpen2(true);

    setPayload({
      ...payload,
      [TIRES.TREAD_DEPTH]: thisTire?.last_tread_depth,
      tire_id: thisTire?.tire_id,
      system_tire_id: thisTire?.system_tire_id,
    });
    setTireData(thisTire);
  };

  const linkToVehicle = (to, useBtn, title) => {
    return (
      <Link to={to} className={classes.tireLink}>
        {useBtn ? (
          <Button
            data-hook='link-vehicle-button'
            varian='text'
            color='primary'
            style={{ textTransform: 'unset' }}
          >
            {t('homePage.taskBoard.configureTires')}
          </Button>
        ) : (
          title
        )}
      </Link>
    );
  };
  const contactUsLink = () => {
    return (
      <Link
        to={`mailto:${process.env.REACT_APP_INFO_EMAIL_ID}`}
        className={classes.tireLink}
        data-hook='link-contact-us'
      >
        {t('homePage.taskBoard.contactUs')}
      </Link>
    );
  };
  const decodeNextAction = (code, row) => {
    switch (code) {
      case 'T100':
        return contactUsLink();
      case 'T101':
        return (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
          >
            <Button
              style={{ textTransform: 'unset' }}
              color='primary'
              variant='text'
              data-hook='edit-task'
              onClick={() => {
                if (editablePayload.tire_size === null) {
                  setEditablePayload({
                    ...editablePayload,
                    before_service: row?.metadata.payload.before_service,
                    after_service: row?.metadata.payload.after_service,
                    tire_size: row?.metadata.payload.tire_size,
                  });
                  setEditablePayloadStatic({
                    ...editablePayload,
                    before_service: row?.metadata.payload.before_service,
                    after_service: row?.metadata.payload.after_service,
                    tire_size: row?.metadata.payload.tire_size,
                  });
                }
                setData(row);
                setOpenModal(true);
              }}
            >
              {t('homePage.taskBoard.changePayload')}
            </Button>
          </Box>
        );
      case 'T102':
        return (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
            data-hook='configure-tires'
          >
            {linkToVehicle(
              `/fleets-data/${
                row?.metadata.fleet_id
              }/${row?.entity_type?.toLowerCase()}${
                row?.entity_type === 'BUS' ? 'es' : 's'
              }/${row?.entity_id}`,
              true,
              t('homePage.taskBoard.configureTires')
            )}
            <Button
              varian='text'
              color='primary'
              style={{ textTransform: 'unset' }}
              data-hook='resolve-task'
              onClick={async () => {
                setLoading(true);
                let payload = {
                  entity_id: row.entity_id,
                  customer_id: row.customer_id,
                };
                payload.metadata = { ...row.metadata.payload };

                try {
                  const response = await resolveTask(row.task_id, payload);
                  if (response.status === 200) {
                    dispatch(
                      setNotification(t('common.success'), 'success', true)
                    );
                  } else {
                    dispatch(
                      setNotification(response?.data.message, 'error', true)
                    );
                  }
                } catch (error) {
                } finally {
                  setLoading(false);
                }
              }}
            >
              {t('homePage.taskBoard.done')}
            </Button>
          </Box>
        );
      case 'T201':
        return (
          <Box display='flex' flexDirection='column'>
            <Button
              variant='text'
              color='primary'
              style={{ textTransform: 'unset' }}
              onClick={() => buttonClickHandler(row)}
              data-hook='unmount'
            >
              {t('common.unmount')}
            </Button>
          </Box>
        );
      case 'T203':
        return (
          <Box display='flex' flexDirection='column'>
            <Button
              variant='text'
              color='primary'
              style={{ textTransform: 'unset' }}
              onClick={() => buttonClickHandler(row)}
              data-hook='unmount'
            >
              {t('common.unmount')}
            </Button>
          </Box>
        );
      case 'T301':
        return (
          <Box display='flex' flexDirection='column'>
            <Button
              variant='text'
              color='primary'
              style={{ textTransform: 'unset' }}
              onClick={() => handleDeleteTask(row)}
              data-hook='approve'
            >
              {t('common.approve')}
            </Button>
          </Box>
        );
      default:
        return contactUsLink();
    }
  };

  return (
    <div>
      <LoadAnimation loading={isLoading} setLoading={setLoading} />
      {!loading ? (
        <div data-hook='tasks-table'>
          <CustomTable columns={columns} data={availableTasks} />
        </div>
      ) : (
        <Box className={classes.commonSkeleton}>
          <Skeleton
            variant='rectangular'
            height='100%'
            className={classes.actionRequiredTableSkeleton}
          />
        </Box>
      )}
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        className={classes.modal}
        data-hook='task-modal'
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
      >
        <Paper className={classes.paper}>
          <Box className={classes.box}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  {t('homePage.taskBoard.before')}
                </Typography>
              </Grid>
              {editablePayload.before_service?.map((payload, i) => (
                <Grid item xs={12} key={i}>
                  <Accordion
                    expanded={editablePayload.before_service?.length <= 2}
                    data-hook={`task-accordian-${i}`}
                  >
                    <AccordionSummary>{i + 1}</AccordionSummary>
                    <AccordionDetails
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                      }}
                    >
                      {Object.entries(payload).map(item => {
                        return (
                          <Box width='100%' key={item[0]}>
                            <CustomTextField
                              data-hook={`text-field-${item[0]}`}
                              name={item[0]}
                              id={item[0]}
                              label={item[0]}
                              onChange={event =>
                                handlePayloadChange(event, 'before_service', i)
                              }
                              variant='outlined'
                              value={item[1]}
                              type='text'
                              fullWidth
                            />
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography variant='h6' data-hook='taskboard-after'>
                  {t('homePage.taskBoard.after')}
                </Typography>
              </Grid>
              {editablePayload.after_service?.map((payload, i) => (
                <Grid item xs={12} key={i}>
                  <Accordion
                    expanded={editablePayload.after_service?.length <= 2}
                    data-hook={`taskboard-after-accordian-${i}`}
                  >
                    <AccordionSummary>{i + 1}</AccordionSummary>
                    <AccordionDetails
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                      }}
                    >
                      {Object.entries(payload).map(item => {
                        return (
                          <Box width='100%' key={item[0]}>
                            <CustomTextField
                              name={item[0]}
                              id={item[0]}
                              label={item[0]}
                              data-hook={`task-text-field-after-service-${i}`}
                              onChange={event =>
                                handlePayloadChange(event, 'after_service', i)
                              }
                              variant='outlined'
                              value={item[1]}
                              type={
                                item[0] === 'tread_depth' ? 'number' : 'text'
                              }
                              InputProps={
                                item[0] === 'tread_depth' && {
                                  inputProps: {
                                    min: 0,
                                    max: 20,
                                    step: 'any',
                                  },
                                }
                              }
                              fullWidth
                            />
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box marginTop={4} width='100%'>
            <FormControl variant='outlined' className={classes.form}>
              <InputLabel htmlFor='outlined-age-native-simple'>
                tire_size
              </InputLabel>
              <Select
                variant='standard'
                native
                value={editablePayload.tire_size}
                onChange={event =>
                  setEditablePayload({
                    ...editablePayload,
                    tire_size: event.target.value,
                  })
                }
                data-hook='task-tire-size'
                label='tire_size'
                name='tire_size'
                fullWidth
              >
                <option aria-label='None' value='' />
                {availableSizes.map((item, i) => (
                  <option key={i} label={item} value={item} />
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            display='flex'
            gap={'16px'}
            alignSelf='center'
            marginTop={'48px'}
          >
            <Button
              variant='outlined'
              color='primary'
              className={classes.navBtns}
              data-hook='task-cancel-button'
              onClick={() => {
                setEditablePayload(editablePayloadStatic);
                handleClose();
              }}
            >
              {t('common.actions.cancel')}
            </Button>
            <Button
              variant='contained'
              color='primary'
              className={classes.navBtns}
              onClick={doneBtnClickHandler}
              data-hook='task-confirm-button'
            >
              <Typography variant='h6' align='center'>
                {t('tireConfigPage.confirm')}
              </Typography>
            </Button>
          </Box>
        </Paper>
      </Modal>
      {open2 && (
        <ModifyTireModal
          open={open2}
          data-hook='task-modify-button'
          setOpen={setOpen2}
          presentTire={tireData}
          operation={'storage'}
          handleClose={closeHandler}
        />
      )}
    </div>
  );
};

export default Tasks;

export const unmountOptions = ['Worn out', 'Repair'];

import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

export const tableHeadings = [
  {
    id: '2',
    title: { en: 'Recommended Tire:', de: 'Empfohlener Reifen:' },
    sub: 'Steer',
  },
  {
    id: '3',
    title: { en: 'Recommended Tire:', de: 'Empfohlener Reifen:' },
    sub: 'Drive',
  },
  {
    id: '4',
    title: { en: 'Recommended Tire:', de: 'Empfohlener Reifen:' },
    sub: 'Trailer',
  },
  {
    id: '5',
    title: { en: 'TCO Rating', de: 'TCO Bewertung' },
  },
  {
    id: '6',
    title: {
      en: 'Estimated TCO Savings (€)',
      de: 'Gesamtkostenreduzierung (€)',
    },
  },
  {
    id: '7',
    title: {
      en: 'Estimated Cost/100Km (€)',
      de: 'Geschätzte Kosten/100Km (€)',
    },
  },
  {
    id: '8',
    title: { en: 'Buy', de: 'Kaufen' },
  },
];

export const InputWrapper = styled('div')`
  margin-top: 10px;
  width: 100%;

  border: 1px solid #d9d9d9;
  background-color: #fff;
  border-radius: 4px;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    font-size: 14px;
    height: 30px;
    box-sizing: border-box;
    padding: 4px 6px;
    width: 0;
    min-width: 30px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`;

export const Listbox = styled('ul')`
  width: 300px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

export const filteringRecommendationOptions = [
  {
    key: 'retreadable',
    name: 'is_retread',
  },
  {
    key: 'price',
    name: 'is_price',
  },
  {
    key: 'runningReading',
    name: 'is_mileage',
  },
  {
    key: 'fuelConsumption',
    name: 'is_rrc',
  },
  {
    key: 'traction',
    name: 'is_traction',
  },
  {
    key: 'sameBrand',
    name: 'same_brand',
  },
];

export const Tag = styled(({ label, onDelete, ...props }) => (
  <div {...props}>
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
))`
  display: flex;
  align-items: center;
  height: 24px;
  margin: 2px;
  line-height: 22px;
  background-color: #fafafa;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 4px 0 10px;
  outline: 0;
  overflow: hidden;

  &:focus {
    border-color: #40a9ff;
    background-color: #e6f7ff;
  }

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 12px;
    cursor: pointer;
    padding: 4px;
  }
`;

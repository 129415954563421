import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setPageLevelTour, setTour } from 'redux/users/ducks';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    tourAction: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '50%',
    },
  };
});

const TourContent = ({
  goTo,
  content,
  finalAction = 'Next',
  page,
  hideSkip = false,
}) => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  return (
    <Box>
      <Typography variant='h6' gutterBottom style={{ color: '#fff' }}>
        {content}
      </Typography>
      <Box className={classes.tourAction}>
        {!hideSkip && (
          <Button
            variant='contained'
            color='inherit'
            onClick={() => {
              dispatch(setPageLevelTour(page));
              dispatch(setTour(false));
            }}
          >
            Skip
          </Button>
        )}
        <Button variant='contained' onClick={goTo} color='inherit'>
          {finalAction}
        </Button>
      </Box>
    </Box>
  );
};

export default TourContent;

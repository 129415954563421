import { useCallback, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getVehiclesForFleet } from 'services/fleet_service';
import { setAccessTokenSelector } from 'redux/users/ducks';
import {
  setAllFleetVehicles,
  setTrackedVehiclesIdsSelector,
  setVehiclesForFleet,
  vehiclesForFleetSelector,
} from 'redux/fleets/ducks';
import { useTranslation } from 'react-i18next';
import { TRACKED_LS } from 'utils/string_utils';

const useSelectFleet = () => {
  const [vehicles, setVehicles] = useState(null);
  const dispatch = useDispatch();
  const trackedVehicles = useSelector(setTrackedVehiclesIdsSelector);
  const trackedLs = TRACKED_LS;
  const [fleetsFound, setFleetsFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const access_token = useSelector(setAccessTokenSelector);
  const vehilcesForFleetData = useSelector(vehiclesForFleetSelector);
  const { t } = useTranslation();
  const tracked = trackedVehicles || trackedLs;
  let isLoaded = useRef(true);

  const fetchFleets = useCallback(
    async (data, requery = false) => {
      try {
        const id = [];
        for (let i = 0; i < data?.length; i++) {
          id.push(data[i].fleet_id);
        }
        if (id.length > 0) {
          setFleetsFound(true);

          try {
            const temp = [];
            // const temp2 = [];
            for (let i = 0; i < id.length; i++) {
              let res = {};
              if (vehilcesForFleetData?.[id[i]] && !requery) {
                res = vehilcesForFleetData[id[i]];
              } else {
                res = await getVehiclesForFleet(
                  {
                    fleet_id: id[i],
                  },
                  access_token
                );
                dispatch(setVehiclesForFleet(id[i], res));
              }
              // temp2.push(res);
              const data = res?.data?.resp?.vehicles.filter(
                x => x.vehicle_type === 'TRUCK'
              );
              const data2 = res?.data?.resp?.vehicles.filter(
                x => x.vehicle_type === 'TRAILER'
              );
              const data3 = res?.data?.resp?.vehicles.filter(
                x => x.vehicle_type === 'BUS'
              );

              try {
                if (data2?.length) {
                  for (let i = 0; i < data2.length; i++) {
                    if (data2[i].axle_type !== null) {
                      if (
                        !data2[i].configuration
                          .toLowerCase()
                          .includes('partial')
                      ) {
                        data2[i].config_status = t('common.configured');
                      } else {
                        data2[i].config_status = t('common.partialConfig');
                      }
                    } else {
                      data2[i].config_status = t('homePage.unconfigured');
                    }
                    if (tracked?.toString().includes(data2[i].vehicle_id)) {
                      data2[i].track_status = 'Tracked';
                    } else {
                      data2[i].track_status = 'Untracked';
                    }
                    temp.push({ ...data2[i], type: 'trailer' });
                  }
                }
                if (data?.length) {
                  for (let i = 0; i < data.length; i++) {
                    if (data[i].axle_type !== null) {
                      if (
                        !data[i].configuration.toLowerCase().includes('partial')
                      ) {
                        data[i].config_status = t('common.configured');
                      } else {
                        data[i].config_status = t('common.partialConfig');
                      }
                    } else {
                      data[i].config_status = t('homePage.unconfigured');
                    }
                    if (tracked?.toString().includes(data[i].vehicle_id)) {
                      data[i].track_status = 'Tracked';
                    } else {
                      data[i].track_status = 'Untracked';
                    }
                    temp.push({ ...data[i], type: 'truck' });
                  }
                }
                if (data3?.length) {
                  for (let i = 0; i < data3.length; i++) {
                    if (data3[i].axle_type !== null) {
                      if (
                        !data3[i].configuration
                          .toLowerCase()
                          .includes('partial')
                      ) {
                        data3[i].config_status = t('common.configured');
                      } else {
                        data3[i].config_status = t('common.partialConfig');
                      }
                    } else {
                      data3[i].config_status = t('homePage.unconfigured');
                    }
                    if (tracked?.toString().includes(data3[i].vehicle_id)) {
                      data3[i].track_status = 'Tracked';
                    } else {
                      data3[i].track_status = 'Untracked';
                    }
                    temp.push({ ...data3[i], type: 'bus' });
                  }
                }
              } catch (error) {
                console.error('E20017', error);
              }
            }
            if (isLoaded) {
              // setUnconfiguredVehicles(temp);
              const uniqueVehicles = Array.from(
                new Map(temp.map(item => [item.vehicle_id, item])).values()
              );
              setVehicles(uniqueVehicles);
              dispatch(setAllFleetVehicles(uniqueVehicles));
            }
          } catch (error) {
            console.error('E00021', error);
          }
        } else {
          setFleetsFound(false);
          // setNumberCondition(0);
        }
      } catch (error) {
        console.error('E20009', error);
      } finally {
        setIsLoading(false);
      }

      return () => (isLoaded.current = false);
    },
    //eslint-disable-next-line
    [access_token]
  );
  return {
    fetchFleets,
    vehicles,
    // unconfiguredVehicles,
    fleetsFound,
    isLoading,
    // numberCondition,
  };
};

export default useSelectFleet;

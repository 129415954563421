import React, { useEffect, useState } from 'react';
import 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
  ArrowBackIos,
  ArrowForwardIos,
  CloudDownload,
} from '@mui/icons-material';
import * as XLSX from 'xlsx';

import { setNotification } from 'redux/notifications/ducks';
import { getDailyReport } from 'services/fleet_service';
import { setAccessTokenSelector } from 'redux/users/ducks';
import { useNavigate, useParams } from 'react-router-dom';
import KpiChartModal from './KpiChartModal';
import * as CONST from './const';
import moment from 'moment';
import { VEHICLE } from 'utils/string_utils';
import Loading from 'components/Loading';
import CustomDatePicker from 'components/custom/CustomDatePicker';

const useStyles = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px 5px',
      gap: 10,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    icons: {
      transition: 'all 0.3s linear',
      cursor: 'pointer',
      color: theme.palette.primary.main,
      '&:hover': {
        transform: 'scale(1.5)',
      },
    },
    modal: {
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      margin: '15rem auto',
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: '100px',
    },
    navBtn: {
      textTransform: 'unset',
    },
    doughnutCard: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#fff',
      objectFit: 'contain',
      boxShadow: 'none',
      position: 'relative',
    },
    doughnut: {
      flexShrink: 2,
      position: 'absolute',
      left: 100,
      top: -75,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '190%',
      [theme.breakpoints.up('lg')]: {
        width: 300,
      },
    },
    downloadBtn: {
      cursor: 'pointer',
    },
    usageReportContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      position: 'relative',
    },
    arrowBackIosContainer: {
      display: 'flex',
      margin: '0rem 2rem',
      marginTop: 20,
    },
    noInfo: {
      fontWeight: '900',
      fontSize: 'clamp(12px, 1vw, 2rem)',
    },
    newTile: {
      width: '100%',
      height: '100%',
      display: 'flex',
      padding: '16px 24px 24px 24px',
      flexDirection: 'column',
      alignItems: 'flex-start',
      borderRadius: '16px',
      border: '0.5px solid var(--default-gray-200, #E5E7EB)',
      background: 'var(--default-white, #FFF)',
      boxShadow: '0px 2px 16px 0px rgba(179, 179, 179, 0.10)',
    },
  };
});

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

const UsageReports = ({ isTracked }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const access_token = useSelector(setAccessTokenSelector);
  const params = useParams();
  const { vehicle_id } = params;
  // const [open, setOpen] = useState(false);
  const [reportType, setReportType] = useState('daily');
  const [index, setIndex] = useState(0);
  const [mission, setMission] = useState('');
  const [allData, setAllData] = useState([]);
  const [weeklyReportData, setWeeklyReportData] = useState([]);
  const [pieChartData, setPieChartData] = useState([]);
  const [chartTitle, setChartTitle] = useState('');
  let entryNo = null;
  const [reportsAvailable, setReportsAvailable] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [inputIsTouched, setInputIsTouched] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [kpis, setKpis] = useState({});
  const [labels, setLabels] = useState([]);
  const [selectedDate, setSelectedDate] = useState();

  const [openKpiModal, setOpenKpiModal] = useState(false);
  const [activeKey, setActiveKey] = useState(null);
  const [navBtnUsed, setNavBtnUsed] = useState(true);
  const theme = useTheme();
  const [filteredToWeekly, setFilteredToWeekly] = useState([]);
  const [allImportantKpis, setAllImportantKpis] = useState([
    {
      kpis: [
        'total_share_motorway',
        'total_share_regional',
        'total_share_urban',
      ],
      title: 'Distribution',
    },
  ]);

  useEffect(() => {
    const query = async () => {
      try {
        const res = await getDailyReport(vehicle_id);
        if (res.status === 200) {
          const result = res?.data?.resp[vehicle_id];
          if (result.length > 0) {
            const final = result.sort(function (a, b) {
              return b.timestamp - a.timestamp;
            });
            Object.keys(result).length && setAllData(final);
          }
        } else setReportsAvailable(false);
      } catch (error) {
        console.error('E20026', error);
      } finally {
        setIsLoading(false);
      }
    };
    if (isTracked) {
      query();
    }
    const time = setTimeout(() => {
      setIsLoading(false);
    }, [2000]);

    return () => clearTimeout(time);
  }, [access_token, vehicle_id, isTracked]);

  useEffect(() => {
    if (allData.length > 0) {
      let allTitlesArrLength = [];
      let allTitles = [];
      const filter = allData.map(row => {
        const arr = Object.entries(row);

        // Filter out entries with null values
        const filtered = arr.reverse().filter(([key, value]) => {
          return value !== null;
        });

        // Convert back to object
        return Object.fromEntries(filtered);
      });

      for (let b = 0; b < filter.length; b++) {
        const titles = Object.keys(filter[b]);
        allTitlesArrLength.push(titles.length);
      }

      for (let a = 0; a < filter.length; a++) {
        let kpiTitles = Object.keys(filter[a]);
        if (Object.keys(filter[a]).length === Math.max(...allTitlesArrLength)) {
          allTitles = Object.keys(filter[a]);
        }

        for (let index = 0; index < kpiTitles.length; index++) {
          if (kpiTitles[index] === 'timestamp') {
            filter[a][kpiTitles[index]] = formatDate(
              new Date(filter[a]['timestamp'] * 1000)
            );
          } else {
            filter[a][kpiTitles[index]] = checkIfDecimal(
              filter[a][kpiTitles[index]]
            );
          }
        }
      }
      let reportsData = [];
      const weeklyData = [];

      // Get data distributed to corresponding weeks
      const result = filter.reduce((val, obj) => {
        let comp = moment(obj['timestamp'], 'YYYY-MM-DD').format('w');
        (val[comp] = val[comp] || []).push(obj);
        return val;
      }, {});

      const length = Object.values(result).length;

      const newTitles = allTitles.filter(title => {
        return (
          title !== 'timestamp' &&
          title !== 'mission_profile' &&
          title !== VEHICLE.ID
        );
      });

      for (let i = 0; i < length; i++) {
        let timeSpanObj = {};

        const items = Object.values(result)[i];
        const start = items[0];
        const startW = items[items.length - 1];
        const end = items[0];

        for (let j = 0; j < newTitles.length; j++) {
          if (timeSpanObj[newTitles[j]] === undefined) {
            timeSpanObj[newTitles[j]] = 0;
          }
          let nanEntries = 0;
          const kpiSum = items.reduce(function (acc, val) {
            if (isNaN(val[newTitles[j]])) {
              nanEntries++;
            }

            return (
              (isNaN(acc) ? 0 : acc) +
              parseFloat(isNaN(val[newTitles[j]]) ? 0 : val[newTitles[j]])
            );
          }, 0);
          if (
            newTitles[j].includes('average') ||
            newTitles[j].includes('share')
          ) {
            timeSpanObj[newTitles[j]] = checkIfDecimal(
              kpiSum / (items.length - nanEntries)
            );
          } else {
            timeSpanObj[newTitles[j]] = checkIfDecimal(kpiSum);
          }
        }

        timeSpanObj.end_date = end.timestamp;
        timeSpanObj.start_date = startW.timestamp;

        timeSpanObj.mission_profile = start.mission_profile;
        weeklyData.push(timeSpanObj);
      }
      setWeeklyReportData(weeklyData);

      if (reportType === 'daily') {
        reportsData = filter;
      } else {
        reportsData = weeklyData.reverse();
      }
      setKpis(reportsData[index]);

      if (navBtnUsed) {
        setSelectedDate(
          formatDate(
            new Date(
              reportsData[index]?.timestamp ||
                reportsData[index]?.['start_date']
            )
          )
        );
      }
      setMission(reportsData[index]?.['mission_profile']);
      setReportsAvailable(true);
    } else setReportsAvailable(false);
  }, [allData, index, reportType, navBtnUsed]);

  useEffect(() => {
    const temp = [];
    const temp2 = [];
    const l = [];

    if (Object.keys(kpis).length > 0) {
      Object.entries(kpis).forEach(entry => {
        if (
          entry[0] === 'total_share_motorway' ||
          entry[0] === 'total_share_regional' ||
          entry[0] === 'total_share_urban'
        ) {
          temp.push(entry);
        }
      });
      temp.forEach(item => {
        temp2.push(parseFloat(item[1]).toFixed(2));
        l.push(item[0].split('_')[2]);
      });
      setPieChartData(temp2);

      setLabels(l);
    }
  }, [kpis]);

  const handleChange = event => {
    setReportType(event.target.value);
    // setOpen(true);
    setIndex(0);
  };

  const handleDateChange = event => {
    setSelectedDate(moment(event).format('YYYY-MM-DD'));
    setInputIsTouched(true);
    if (reportType === 'daily') {
      setNavBtnUsed(true);
    } else {
      setNavBtnUsed(false);
    }
  };
  const handleNext = () => {
    setInputIsTouched(false);
    setNavBtnUsed(true);
    setIndex(current => {
      if (current === 0) {
        dispatch(
          setNotification(
            reportType === 'daily'
              ? t('usageReportsPage.noDataDay')
              : t('usageReportsPage.noDataWeek'),
            'info',
            true
          )
        );
        return 0;
      }
      return current - 1;
    });
  };

  const handlePrevious = () => {
    setInputIsTouched(false);
    setNavBtnUsed(true);
    if (reportType === 'daily') {
      setIndex(current => {
        if (current === allData.length - 1) {
          dispatch(
            setNotification(t('usageReportsPage.noDataDay'), 'info', true)
          );

          return current;
        }
        return current + 1;
      });
    }

    if (reportType === 'weekly') {
      setIndex(current => {
        if (current === weeklyReportData.length - 1) {
          dispatch(
            setNotification(t('usageReportsPage.noDataWeek'), 'info', true)
          );

          return current;
        }
        return current + 1;
      });
    }
  };

  const checkDateData = currDate => {
    const dataDaily = allData;
    const dataWeekly = weeklyReportData;

    if (reportType === 'daily') {
      const a = new Date(currDate);
      const dateOnly = a.toString().split(' ').slice(0, 4);
      setSelectedDate(formatDate(currDate));
      const foundIndex = dataDaily.findIndex(kpi => {
        const x = new Date(kpi.timestamp * 1000)
          .toString()
          .split(' ')
          .slice(0, 4);
        return x.toString() === dateOnly.toString();
      });

      return foundIndex;
    } else {
      const startDate = new Date(currDate);

      const foundIndex = dataWeekly.findIndex(item => {
        const x = new Date(item.start_date);
        const y = new Date(item.end_date);
        return startDate >= x && startDate <= y;
      });

      return foundIndex;
    }
  };

  const handleSearch = () => {
    if (reportType === 'daily') {
      const foundIndex = checkDateData(selectedDate);
      if (foundIndex !== -1) {
        setIndex(foundIndex);
      }
    } else if (reportType === 'weekly') {
      const foundIndex = checkDateData(selectedDate);

      if (foundIndex !== -1) {
        setIndex(foundIndex);
      }
    }
    // setOpen(false);
    setInputIsTouched(false);
  };

  const keyValuePair = title => {
    const items = CONST.keyValue;

    return items[title];
  };

  const data = {
    labels: labels,
    datasets: [
      {
        label: '',
        data: pieChartData,
        backgroundColor: [theme.palette.primary.main, '#babbc2', '#474747'],
        borderColor: [theme.palette.primary.main, '#babbc2', '#474747'],
        borderWidth: 1,
      },
    ],
  };

  const capitalizeFirst = word => {
    const result = word.charAt(0).toUpperCase() + word.slice(1);

    return result;
  };

  const disableDaysWithNoData = date => {
    const dataDaily = allData;
    const found = dataDaily.find(day => {
      const dbDate = new Date(day.timestamp * 1000)
        .toString()
        .split(' ')
        .slice(0, 4)
        .join('-');
      return dbDate === date.toString().split(' ').slice(0, 4).join('-');
    });
    if (found === undefined) return true;
  };

  const checkIfDecimal = entry => {
    if (typeof entry === 'string') {
      return entry;
    } else if (isNaN(entry)) {
      return 0;
    } else {
      return Math.round(entry);
    }
  };

  const dailyHandler = data => {
    return data.map(obj => {
      // change key name
      obj['Date'] = obj['timestamp'];
      delete obj['timestamp'];
      delete obj[VEHICLE.ID];
      const kpiTitles = Object.keys(obj);
      for (let i = 0; i < kpiTitles.length - 1; i++) {
        let check =
          keyValuePair(kpiTitles[i]) !== null &&
          keyValuePair(kpiTitles[i]) !== undefined;

        let units = keyValuePair(kpiTitles[i]);
        let output = `(${units})`;

        // Add units to keys
        obj[`${kpiTitles[i]} ${check ? output : ''}`] = obj[kpiTitles[i]];

        delete obj[kpiTitles[i]];
      }

      return obj;
    });
  };

  const timeSpanFilter = (entry, format, titles, form) => {
    const result = entry.reduce((val, obj) => {
      let comp = moment(obj['timestamp'], 'YYYY-MM-DD').format(format);
      (val[comp] = val[comp] || []).push(obj);
      return val;
    }, {});

    const length = Object.values(result).length;

    const temp = [];

    const newTitles = titles.filter(title => {
      return (
        title !== 'timestamp' &&
        title !== 'mission_profile' &&
        title !== VEHICLE.ID
      );
    });

    for (let i = 0; i < length; i++) {
      let timeSpanObj = {};

      const items = Object.values(result)[i];
      const start = items[0];
      const startW = items[items.length - 1];
      const end = items[0];

      for (let j = 0; j < newTitles.length; j++) {
        if (timeSpanObj[newTitles[j]] === undefined) {
          timeSpanObj[newTitles[j]] = 0;
        }

        let nanEntries = 0;

        const kpiSum = items.reduce(function (acc, val) {
          if (isNaN(val[newTitles[j]])) {
            nanEntries++;
          }
          return (
            (isNaN(acc) ? 0 : acc) +
            parseFloat(isNaN(val[newTitles[j]]) ? 0 : val[newTitles[j]])
          );
        }, 0);

        if (
          newTitles[j].includes('average') ||
          newTitles[j].includes('share')
        ) {
          timeSpanObj[newTitles[j]] = checkIfDecimal(
            kpiSum / (items.length - nanEntries)
          );
        } else {
          timeSpanObj[newTitles[j]] = checkIfDecimal(kpiSum);
        }
      }

      // this arrangement ensures start_date and end_date are placed first in the excel sheet
      const year = new Date(start.timestamp).getFullYear();
      const month = new Date(start.timestamp).toLocaleString('en-US', {
        month: 'short',
      });
      if (format === 'y') {
        timeSpanObj.year = year;
      }
      if (format === 'M') {
        timeSpanObj.month = `${month}, ${year}`;
      }
      if (format === 'w') {
        timeSpanObj.end_date = end.timestamp;
        timeSpanObj.start_date = startW.timestamp;
      }
      timeSpanObj.mission_profile = start.mission_profile;
      temp.push(timeSpanObj);
      setFilteredToWeekly(temp);
      entryNo = Object.keys(timeSpanObj).length;
    }

    if (form !== 'initial') {
      const report = JSON.parse(JSON.stringify(temp)).map(obj => {
        // change key name
        const kpiTitles = Object.keys(obj);
        for (let i = 0; i < kpiTitles.length - 1; i++) {
          let check =
            keyValuePair(kpiTitles[i]) !== undefined &&
            keyValuePair(kpiTitles[i]) !== null;
          let units = keyValuePair(kpiTitles[i]);
          let output = `(${units})`;

          // Add units to keys
          obj[`${kpiTitles[i]} ${check ? output : ''}`] = obj[kpiTitles[i]];

          delete obj[kpiTitles[i]];
        }
        const entries = Object.entries(obj);

        entries.reverse();

        return Object.fromEntries(entries);
      });

      return report;
    } else return;
  };

  useEffect(() => {
    const { filter, allTitles } = getKpiData(allData, checkIfDecimal);
    timeSpanFilter(filter, 'w', allTitles, 'initial');
    // eslint-disable-next-line
  }, [allData]);
  // eslint-disable-next-line
  const downloadReportHandler = () => {
    const { filter, allTitles } = getKpiData(allData, checkIfDecimal);

    const yearlyReport = timeSpanFilter(filter, 'y', allTitles);
    const monthlyReport = timeSpanFilter(filter, 'M', allTitles);
    const weeklyReport = timeSpanFilter(filter, 'w', allTitles);
    const dailyReport = dailyHandler(filter);

    const daySheet = XLSX.utils.json_to_sheet(dailyReport);
    daySheet['!cols'] = new Array(entryNo).fill({ wch: 24 });
    const weekSheet = XLSX.utils.json_to_sheet(weeklyReport.reverse());
    weekSheet['!cols'] = new Array(entryNo).fill({ wch: 24 });
    const monthSheet = XLSX.utils.json_to_sheet(monthlyReport.reverse());
    monthSheet['!cols'] = new Array(entryNo).fill({ wch: 24 });
    const yearSheet = XLSX.utils.json_to_sheet(yearlyReport.reverse());
    yearSheet['!cols'] = new Array(entryNo).fill({ wch: 24 });

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, daySheet, 'Daily');
    XLSX.utils.book_append_sheet(workbook, weekSheet, 'Weekly');
    XLSX.utils.book_append_sheet(workbook, monthSheet, 'Monthly');
    XLSX.utils.book_append_sheet(workbook, yearSheet, 'Yearly');

    XLSX.writeFile(workbook, `reports_${vehicle_id}.xlsx`);
  };

  useEffect(() => {
    function capitalizeString(str) {
      return str
        ?.split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }

    const temp = allImportantKpis;

    // Get the object with most number of keys
    const mostKeysObj = allData.reduce((acc, obj) => {
      if (Object.keys(obj).length > Object.keys(acc).length) {
        return obj;
      } else {
        return acc;
      }
    }, {});
    const keys = [];

    // remove items with null values
    for (let [key, value] of Object.entries(mostKeysObj)) {
      if (value !== null && !keys.includes(key)) {
        keys.push(key);
      }
    }
    const filteredKeys = keys.filter(key => !keysToRemove.includes(key));

    filteredKeys.forEach(item => {
      temp.push({ kpis: [item], title: capitalizeString(item) });
    });

    setAllImportantKpis(temp);

    // eslint-disable-next-line
  }, [allData]);

  const resultDb = Object.entries(kpis).filter(([key, value]) => {
    const excludedKeys = [
      'mission_profile',
      'total_share_motorway',
      'total_share_regional',
      'total_share_urban',
      'timestamp',
      'start_date',
      'end_date',
      VEHICLE.ID,
    ];

    return (
      Object.keys(kpis).length > 0 &&
      value !== null &&
      !excludedKeys.includes(key)
    );
  });

  const calculateSize = () => {
    const sizeOptions = {
      1: 12,
      2: 6,
      3: 4,
      4: 3,
      5: 'auto',
    };

    const defaultSize = 2;

    return sizeOptions[resultDb.length] || defaultSize;
  };

  if (isLoading) return <Loading />;

  if (!isTracked) {
    return (
      <Card className={classes.card}>
        <Button
          data-hook='no-telematics-message'
          variant='text'
          color='primary'
          className={classes.navBtn}
          onClick={() => navigate('/telematics-data/provider-integration')}
        >
          <Typography
            variant='h6'
            align='center'
            gutterBottom
            color='error'
            className={classes.noInfo}
          >
            {t('usageReportsPage.noTelematicsMessage')}
          </Typography>
        </Button>
      </Card>
    );
  } else if (isTracked && !reportsAvailable) {
    return (
      <Card className={classes.card}>
        <Typography
          variant='h6'
          align='center'
          gutterBottom
          color='error'
          className={classes.noInfo}
          data-hook='no-reports'
        >
          {t('usageReportsPage.noReportsMessage')}
        </Typography>
      </Card>
    );
  }

  function renderUsageReportList() {
    return resultDb.map((kpi, i) => {
      const title = kpi[0];
      const formattedTitle = title.split('_').join(' ');
      const translationKey = `usageReportsPage.kpiTitles.${title}`;
      const translation_ = t(translationKey);
      const output =
        translation_ !== translationKey ? translation_ : formattedTitle;
      const value = kpi[1] ?? '';
      const unit = keyValuePair(title) ?? '';
      return (
        <Grid
          item
          lg={calculateSize()}
          md={3}
          xs={12}
          key={kpi[0]}
          style={{ width: '20%' }}
          onClick={() => {
            setChartTitle(
              kpi[0]
                .split('_')
                .map(x => x[0].toUpperCase() + x.slice(1))
                .join(' ')
            );
            setActiveKey([kpi[0]]);
            setOpenKpiModal(true);
          }}
        >
          <Card className={classes.newTile}>
            <Box
              style={{
                width: '100%',
                paddingBottom: '1rem',
                borderBottom: '1px solid #E5E7EB',
              }}
            >
              <Typography variant='h6' gutterBottom fontWeight='bold'>
                {output}
              </Typography>
            </Box>
            <Box style={{ margin: '1rem 0rem' }}>
              <Typography variant='h5' gutterBottom fontWeight='bold'>
                {`${title !== 'mission_profile' ? Math.round(value) : value}  ${
                  unit ?? ''
                }`}
              </Typography>
            </Box>
          </Card>
        </Grid>
      );
    });
  }

  function renderKpiChartModal() {
    return (
      allData?.length > 0 && (
        <KpiChartModal
          open={openKpiModal}
          setOpen={setOpenKpiModal}
          kpi={activeKey}
          chartTitle={chartTitle}
          data={allData}
          reportType={reportType}
          allKpis={allImportantKpis}
          weekly={filteredToWeekly}
        />
      )
    );
  }

  return (
    <>
      <Box my='1.5rem'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box className={classes.usageReportContainer}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <form
                  onSubmit={e => e.preventDefault()}
                  noValidate
                  className={classes.container}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CustomDatePicker
                      label={'Usage date'}
                      value={selectedDate ? parseISO(selectedDate) : null}
                      onChange={handleDateChange}
                      autoOk
                      shouldDisableDate={disableDaysWithNoData}
                      data-hook='usage-date-picker'
                    />
                  </LocalizationProvider>
                  <Button
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={handleSearch}
                    style={{
                      display: inputIsTouched ? 'block' : 'none',
                    }}
                  >
                    {t('common.submitBtn')}
                  </Button>
                </form>
                <Box className={classes.arrowBackIosContainer}>
                  <Box className={classes.icons} onClick={handlePrevious}>
                    <Tooltip
                      title={
                        reportType === 'daily'
                          ? t('usageReportsPage.prevDay')
                          : t('usageReportsPage.prevWeek')
                      }
                    >
                      <ArrowBackIos />
                    </Tooltip>
                  </Box>
                  <Box className={classes.icons} onClick={handleNext}>
                    <Tooltip
                      title={
                        reportType === 'daily'
                          ? t('usageReportsPage.nextDay')
                          : t('usageReportsPage.nextWeek')
                      }
                    >
                      <ArrowForwardIos />
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
              <Box
                display='flex'
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Tooltip title={t('usageReportsPage.download')}>
                  <IconButton size={'large'} onClick={downloadReportHandler}>
                    <CloudDownload color='primary' sx={{ fontSize: '40px' }} />
                  </IconButton>
                </Tooltip>
                <FormControl variant='outlined' className={classes.formControl}>
                  <InputLabel id='demo-simple-select-label'>
                    {t('usageReportsPage.reportType')}
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label={t('usageReportsPage.reportType')}
                    value={reportType}
                    onChange={handleChange}
                  >
                    <MenuItem value={'daily'}>
                      {t('usageReportsPage.daily')}
                    </MenuItem>
                    <MenuItem value={'weekly'}>
                      {t('usageReportsPage.weekly')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Grid>

          <Grid item lg={6} sm={12}>
            <Card className={classes.newTile}>
              <Box
                style={{
                  width: '100%',
                  paddingBottom: '1rem',
                  borderBottom: '1px solid #E5E7EB',
                }}
              >
                <Typography variant='subtitle1' gutterBottom fontWeight='bold'>
                  {t('usageReportsPage.kpiTitles.mission_profile')}
                </Typography>
              </Box>
              <Box style={{ margin: '1rem 0rem' }}>
                <Typography variant='h5' gutterBottom fontWeight='bold'>
                  {capitalizeFirst(mission) ?? ''}
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Card
              className={classes.newTile}
              onClick={() => {
                setChartTitle('Distribution');
                setActiveKey([
                  'total_share_motorway',
                  'total_share_regional',
                  'total_share_urban',
                ]);
                setOpenKpiModal(true);
              }}
            >
              <Box
                style={{
                  width: '100%',
                  paddingBottom: '1rem',
                  borderBottom: '1px solid #E5E7EB',
                }}
              >
                <Typography variant='subtitle1' gutterBottom fontWeight='bold'>
                  {t('usageReportsPage.kpiTitles.distribution')}
                </Typography>
              </Box>
              <Box>
                <Doughnut data={data} options={options} />
              </Box>
            </Card>
          </Grid>
          {renderUsageReportList()}
        </Grid>
      </Box>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box className={classes.modal}>Heatmap will show up here</Box>
      </Modal>
      {renderKpiChartModal()}
    </>
  );
};
// eslint-disable-next-line

export default UsageReports;

const options = {
  responsive: false,
  maintainAspectRatio: true,
  cutout: 40,

  plugins: {
    legend: {
      position: 'right',
      align: 'center',
      labels: {
        boxWidth: 16,
        padding: 12,
      },
    },
    title: {
      text: 'Share',
      position: 'top',
      align: 'start',
      color: '#000',
      font: { size: 24, weight: 'bold' },
    },
  },
};
function getKpiData(allData, checkIfDecimal) {
  let allTitles = [];
  let allTitlesArrLength = [];
  const filter = JSON.parse(JSON.stringify(allData)).map(row => {
    const arr = Object.entries(row);

    // Filter out entries with null values and reverse the order so that "Date" comes first
    const filtered = arr.reverse().filter(([key, value]) => {
      return value !== null;
    });

    // Convert back to object
    return Object.fromEntries(filtered);
  });

  for (let b = 0; b < filter.length; b++) {
    const titles = Object.keys(filter[b]);
    allTitlesArrLength.push(titles.length);
  }

  for (let a = 0; a < filter.length; a++) {
    let kpiTitles = Object.keys(filter[0]);
    if (Object.keys(filter[a]).length === Math.max(...allTitlesArrLength)) {
      allTitles = Object.keys(filter[a]);
    }

    for (let index = 0; index < kpiTitles.length; index++) {
      if (kpiTitles[index] === 'timestamp') {
        filter[a][kpiTitles[index]] = formatDate(
          new Date(filter[a]['timestamp'] * 1000)
        );
      } else {
        filter[a][kpiTitles[index]] = checkIfDecimal(
          filter[a][kpiTitles[index]]
        );
      }
    }
  }

  return { filter, allTitles };
}

const keysToRemove = [
  'vehicle_id',
  'mission_profile',
  'total_share_motorway',
  'total_share_regional',
  'total_share_urban',
  'timestamp',
];

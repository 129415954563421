import { call, put, takeLatest } from 'redux-saga/effects';

import { UserActionTypes, setUserNotificationPreferences } from './ducks';
import { NotificationsActionTypes } from 'redux/notifications/ducks';
import {
  Signup,
  Login,
  Verify,
  ResetLinkRequest,
  ResetPasswordRequest,
  verifyInvitedUser,
  completeSignupQuery,
  getWorkForceData,
  deleteInvitedUser,
  editInvitedUser,
} from '../../services/auth_service';
import { encrypter } from 'utils/Encrypter';
import {
  fleetOverview,
  // getFleetTasks,
  // getFleets,
  getProfileStatus,
  getVehiclesForFleet,
  tireDatabase,
  getTableauData,
  getFleetTasks,
  getNotificationPreferences,
  changeNotificationPreferences,
  getVehicleStatus,
} from 'services/fleet_service';
import { FleetActionTypes } from '../fleets/ducks';
// import { getOrgRetreadingStrategy } from 'services/org_level_retread_strategy';
import { InventoryActionTypes } from '../tireInventory/ducks';

import { TelematicsProviderActionTypes } from '../telematicsProvider/ducks';

export function* usersSaga() {
  yield takeLatest(UserActionTypes.SET_TOKEN, accessTokenSaga);
  yield takeLatest(UserActionTypes.VERIFY_USER, VerifyUser);
  yield takeLatest(UserActionTypes.SIGNUP_USER, signupUserSaga);
  yield takeLatest(UserActionTypes.LOGIN_USER, loginUserSaga);
  yield takeLatest(FleetActionTypes.SET_OVERVIEW_DATA, fleetOverviewSaga);
  yield takeLatest(
    UserActionTypes.FORGOT_PASSWORD_USER,
    forgotPasswordUserSaga
  );
  yield takeLatest(UserActionTypes.RESET_PASSWORD, ResetPassword);
  yield takeLatest(UserActionTypes.VERIFY_INVITED_USER, VerifyInvitee);
  yield takeLatest(UserActionTypes.COMPLETE_SIGNUP, CompleteRegistration);
  yield takeLatest(
    UserActionTypes.DELETE_WORKFORCE_DATA_QUERY,
    deleteWorkForceDataSaga
  );
  yield takeLatest(
    UserActionTypes.EDIT_WORKFORCE_DATA_QUERY,
    editWorkForceDataSaga
  );
  yield takeLatest(UserActionTypes.FETCH_WORKFORCE_DATA, workForceDataSaga);
  yield takeLatest(FleetActionTypes.FETCH_FLEETS_DATA, fetchFleetsSaga);
  yield takeLatest(
    FleetActionTypes.FETCH_VEHILCES_FOR_FLEETS,
    fetchVehiclesForFleetsSaga
  );
  yield takeLatest(UserActionTypes.UPDATE_TASKS, fleetOverviewSaga);
  yield takeLatest(
    UserActionTypes.FETCH_NOTIFICATION_PREFERENCES,
    getNotificationPreferencesSaga
  );
  yield takeLatest(
    UserActionTypes.UPDATE_NOTIFICATION_PREFERENCES,
    updateNotificationPreferencesSaga
  );
}

function* getNotificationPreferencesSaga() {
  try {
    // Call your API function to fetch initial data
    const res = yield call(getNotificationPreferences);

    // Dispatch an action to set the data in the Redux store
    yield put(setUserNotificationPreferences(res?.data.resp));
  } catch (error) {
    // Handle errors if necessary
  }
}

function* updateNotificationPreferencesSaga(action) {
  try {
    const { data } = action.payload;
    const res = yield call(changeNotificationPreferences, data);
    if (res?.status === 200) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Operation Successful',
          type: 'success',
          show: true,
        },
      });
      yield put(setUserNotificationPreferences(res?.data.resp));
    }
  } catch (error) {
    console.error(error);
  }
}
function* accessTokenSaga(action) {
  try {
    const { token } = action.payload;
    const arr = [];
    let res = yield call(fleetOverview, token);
    if (res?.status === 200) {
      yield put({
        type: FleetActionTypes.SET_OVERVIEW_DATA,
        payload: res?.data?.resp,
      });
      const fleets = res?.data?.resp?.fleets;
      if (fleets?.length) {
        fleets.forEach(item => arr.push(item.fleet_name));
      }
      localStorage.setItem('allFleet', JSON.stringify(arr));
    }

    yield put({
      type: TelematicsProviderActionTypes.FETCH_TELEMATICS_DATA,
    });

    res = yield call(getProfileStatus);
    if (res?.status === 200) {
      yield put({
        type: UserActionTypes.SET_PROFILE_STATUS,
        payload: { data: res?.data?.resp },
      });
    }

    res = yield call(getVehicleStatus, token);

    if (res?.status === 200) {
      yield put({
        type: FleetActionTypes.SET_VEHICLE_STATUS,
        payload: res?.data?.vehicle_status,
      });
    }

    res = yield call(tireDatabase);
    if (res?.status === 200) {
      yield put({
        type: InventoryActionTypes.SET_EU_TIRES,
        payload: res?.data?.resp,
      });
      yield put({
        type: InventoryActionTypes.SET_BRANDS,
        payload: res.data?.resp?.brands,
      });
    }

    res = yield call(getTableauData, token);

    if (res?.status === 200) {
      yield put({
        type: UserActionTypes.SET_FLEETS_WITH_TABLEAU,
        payload: res?.data?.resp,
      });
    }
  } catch (error) {
    console.error(error);
  }
}

function* fleetOverviewSaga(action) {
  try {
    const { fleets } = action.payload;
    let allFleetsData = fleets;

    const allTasks = [];
    if (allFleetsData?.length > 0) {
      const res = yield call(getFleetTasks);
      if (res.status === 200) {
        const resource = res?.data?.resp;
        if (resource?.length > 0) {
          resource.forEach(task => {
            allTasks.push(task);
          });
        }
      }

      yield put({
        type: UserActionTypes.SET_TASKS,
        payload: { value: allTasks },
      });
    }
  } catch (error) {
    console.error(error);
  }
}
// for invited users
function* VerifyInvitee(action) {
  try {
    const { token } = action.payload;
    const res = yield call(verifyInvitedUser, token);
    if (res.status === 200) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'User Verification Successful',
          type: 'success',
          show: false,
        },
      });
      yield put({
        type: UserActionTypes.SET_STATUS,
        payload: {
          status: 'verified',
        },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'User Verification Failed. Contact admin to get a new link',
          type: 'error',
          show: false,
        },
      });
    }
  } catch (error) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'Something went wrong',
        type: 'error',
        show: true,
      },
    });
  }
}
function* VerifyUser(action) {
  try {
    const { token } = action.payload;
    const res = yield call(Verify, token);
    if (res.status === 200) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'User Verification Successful',
          type: 'success',
          show: false,
        },
      });
      yield put({
        type: UserActionTypes.SET_STATUS,
        payload: {
          status: 'login',
        },
      });
    } else if (res.status === 100) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'User already verified.',
          type: 'error',
          show: false,
        },
      });
    } else if (res.status === 401) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message:
            'Wrong username. Follow the standard protocol of email verification.',
          type: 'error',
          show: false,
        },
      });
    } else if (res.status === 406) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'User deactivated for malacious behaviour. Contact Admin.',
          type: 'error',
          show: false,
        },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'An error has occurred. Please try again.',
          type: 'error',
          show: false,
        },
      });
    }
  } catch (e) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'An error has occurred. Please try again.',
        type: 'error',
        show: true,
      },
    });
  }
}

// for invited users
function* CompleteRegistration(action) {
  try {
    const { password, locale, token } = action.payload;
    const res = yield call(completeSignupQuery, password, locale, token);
    if (res.status === 200) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Signup was successful',
          type: 'success',
          show: true,
        },
      });
      yield put({
        type: UserActionTypes.SET_STATUS,
        payload: {
          user: res.data.user,
          status: 'completed',
        },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: res.message,
          type: 'error',
          show: true,
        },
      });
    }
  } catch (error) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'Something went wrong',
        type: 'error',
        show: true,
      },
    });
  }
}
function* ResetPassword(action) {
  try {
    const { userToken, newPassword } = action.payload;
    const res = yield call(ResetPasswordRequest, userToken, newPassword);
    if (res.status === 200) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Password changed successfully',
          type: 'success',
          show: true,
        },
      });
      yield put({
        type: UserActionTypes.SET_STATUS,
        payload: {
          user: res.data.user,
          status: 'reset',
        },
      });
    } else if (res.status === 400) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message:
            'Wrong password token. Your account is flagged for malacious behaviour and will remain inaccessible. Contact admin.',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 401) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message:
            'Password reset link expired. Request a new link by hitting forgot password again.',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 404) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message:
            'Wrong username. Please sign-up in order to use our services.',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 403) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Wrong user token.',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 406) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'User has been deactived due to attempted malacious attack.',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 409) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message:
            'Wrong password token. Your account is flagged for suspicious behaviour. Contact admin.',
          type: 'error',
          show: true,
        },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'An error has occurred. Please try again.',
          type: 'error',
          show: true,
        },
      });
    }
  } catch (e) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'An error has occurred. Please try again.',
        type: 'error',
        show: true,
      },
    });
  }
}

function* signupUserSaga(action) {
  const { data } = action.payload;

  try {
    const res = yield call(Signup, data);
    if (res.status === 200) {
      yield put({
        type: UserActionTypes.SET_STATUS,
        payload: {
          status: 'verify',
        },
      });
    } else if (res.status === 401) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'User already exists',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 406) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Required fields have not been provided',
          type: 'error',
          show: true,
        },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'An error has occurred. Please try again.',
          type: 'error',
          show: true,
        },
      });
    }
  } catch (e) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'An error has occurred. Please try again.',
        type: 'error',
        show: true,
      },
    });
  }
}

function* loginUserSaga(action) {
  const { data } = action.payload;
  try {
    const res = yield call(Login, data);
    if (res.status === 200) {
      yield put({
        type: UserActionTypes.SET_TOKEN,
        payload: {
          token: res.data.access_token,
        },
      });

      localStorage.setItem('refresh_token', encrypter(res.data.refresh_token));
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Successfully Logged in',
          type: 'success',
          show: true,
        },
      });

      yield put({
        type: UserActionTypes.SET_USER,
        payload: {
          user: res.data.user,
          status: 'login',
        },
      });
    } else if (res.status === 401) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Wrong username',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 403) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Wrong password',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 406) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'User has not been verified',
          type: 'error',
          show: true,
        },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'An error has occurred. Please try again.',
          type: 'error',
          show: true,
        },
      });
    }
  } catch (e) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'An error has occurred. Please try again.',
        type: 'error',
        show: true,
      },
    });
  }
}

function* forgotPasswordUserSaga(action) {
  try {
    const res = yield call(ResetLinkRequest, action.payload.email);
    if (res.status === 200) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'Check email for password reset instructions.',
          type: 'success',
          show: true,
        },
      });
      yield put({
        type: UserActionTypes.SET_STATUS,
        payload: {
          user: res.data.user,
          status: 'forget',
        },
      });
    } else if (res.status === 401) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message:
            'Wrong username. Please sign-up in order to use our services.',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 403) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'User deactivate due to malacious activity on the account.',
          type: 'error',
          show: true,
        },
      });
    } else if (res.status === 406) {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message:
            'User has not been verified yet. A verification email has been sent to your registered email again. Verify and retry.',
          type: 'error',
          show: true,
        },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'An error has occurred. Please try again.',
          type: 'error',
          show: true,
        },
      });
    }
  } catch (e) {
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'An error has occurred. Please try again.',
        type: 'error',
        show: true,
      },
    });
  }
}

export function* workForceDataSaga() {
  try {
    const res = yield call(getWorkForceData);
    if (res.status === 200) {
      yield put({
        type: UserActionTypes.SET_WORKFORCE,
        payload: {
          workforce: res?.data.resp,
        },
      });
    }
  } catch (error) {
    console.error('E20039', error);
  }
}
function* editWorkForceDataSaga({ payload }) {
  try {
    const res = yield call(editInvitedUser, payload);
    if (res.status === 200) {
      yield put({
        type: UserActionTypes.FETCH_WORKFORCE_DATA,
      });
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'userManagement.successEdit',
          type: 'success',
          show: true,
          translated: true,
        },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: res.message,
          type: 'error',
          show: true,
        },
      });
    }
  } catch (error) {
    console.error('E20039', error);
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'common.error',
        type: 'error',
        show: true,
        translated: true,
      },
    });
  }
}

function* deleteWorkForceDataSaga({ payload }) {
  try {
    const res = yield call(deleteInvitedUser, payload);
    if (res.status === 200) {
      yield put({
        type: UserActionTypes.DELETE_WORKFORCE_DATA,
        payload: payload,
      });
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: 'userManagement.successDelete',
          type: 'success',
          show: true,
          translated: true,
        },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: res.message,
          type: 'error',
          show: true,
        },
      });
    }
  } catch (error) {
    console.error('E20039', error);
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'common.error',
        type: 'error',
        show: true,
        translated: true,
      },
    });
  }
}

function* fetchFleetsSaga() {
  try {
    const res = yield call(fleetOverview);

    const arr = [];
    if (res?.status === 200) {
      yield put({
        type: FleetActionTypes.SET_OVERVIEW_DATA,
        payload: res?.data?.resp,
      });
      const fleets = res?.data?.resp?.fleets;
      if (fleets?.length) {
        fleets.forEach(item => arr.push(item.fleet_name));
      }
      localStorage.setItem('allFleet', JSON.stringify(arr));
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: res.message,
          type: 'error',
          show: true,
        },
      });
    }
  } catch (error) {
    console.error('E20039', error);
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'common.error',
        type: 'error',
        show: true,
        translated: true,
      },
    });
  }
}

function* fetchVehiclesForFleetsSaga({ payload }) {
  const { id, page_no, per_page, setLoading, access_token, search_query } =
    payload;

  try {
    const reqPayload = search_query
      ? { fleet_id: id, search_string: search_query }
      : { fleet_id: id };

    setLoading && setLoading(true);
    const res = yield call(
      getVehiclesForFleet,
      reqPayload,
      access_token,
      false,
      page_no,
      per_page
    );
    if (res.status === 200) {
      //sets raw response
      yield put({
        type: FleetActionTypes.SET_VEHILCES_FOR_FLEETS,
        payload: { id, data: res },
      });
    } else {
      yield put({
        type: NotificationsActionTypes.SET_NOTIFICATION,
        payload: {
          message: res.message,
          type: 'error',
          show: true,
        },
      });
    }
  } catch (error) {
    console.error('E20039', error);
    yield put({
      type: NotificationsActionTypes.SET_NOTIFICATION,
      payload: {
        message: 'common.error',
        type: 'error',
        show: true,
        translated: true,
      },
    });
  } finally {
    setLoading && setLoading(false);
  }
}

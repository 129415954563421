import React, { useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { fleetOverview, updateTire } from 'services/fleet_service';
import { setNotification } from 'redux/notifications/ducks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  setFleetOverview,
  setTireInventoryData,
  setUnprocessedTireInventoryData,
  unprocessedTireInventoryDataSelector,
  updateTireInventoryData,
} from 'redux/fleets/ducks';
import { TIRE_INVENTORY } from 'utils/string_utils';
import { fetchTireInventoryDataAndProcess } from './TireInventory';
import { makeStyles } from 'tss-react/mui';
import { setAccessTokenSelector } from 'redux/users/ducks';
import {
  inventoryChangeSelector,
  setChangeInInventory,
} from 'redux/tireInventory/ducks';
const useStyles = makeStyles()(theme => {
  return {
    root: {
      maxWidth: 500,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    btn: {
      margin: '28px 0px',
      padding: '13.5px 10px',
      borderRadius: '5px',
      boxShadow: 'none',
      textTransform: 'unset',
    },
  };
});

const DeleteTire = ({
  open,
  setOpen,
  id,
  tireInventoryAction,
  location = 'ALL',
  page,
  perPage,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [eventDate, setEventDate] = useState(new Date());
  const access_token = useSelector(setAccessTokenSelector);
  const fleetInventoryData = useSelector(unprocessedTireInventoryDataSelector);
  const [typedYear, setTypedYear] = React.useState(eventDate.getFullYear());
  const currInventoryChangeValue = useSelector(inventoryChangeSelector);
  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    let events = {
      event_type: 'DISPOSE',
      event_date: moment(eventDate).format('YYYY-MM-DD'),
    };

    const payload_ = {
      events: [events],
    };

    try {
      const res = await updateTire(id, payload_);
      if (res.status === 200) {
        dispatch(
          setNotification(t('deleteTire.deleteSuccessful'), 'success', true)
        );
        if (tireInventoryAction) {
          dispatch(
            updateTireInventoryData(
              '',
              res?.data?.resp?.tire_id,
              res?.data?.resp?.fleet_id,
              TIRE_INVENTORY.UPDATE.DELETE
            )
          );
        }

        const data = await fetchTireInventoryDataAndProcess({
          fleet_id: res?.data?.resp?.fleet_id,
          inventoryData: fleetInventoryData,
          refetch: true,
          location,
          pageNo: page,
          perpage: perPage,
          isPaginated: true,
        });
        dispatch(setTireInventoryData(data, res?.data?.resp?.fleet_id));
        dispatch(
          setUnprocessedTireInventoryData(data.res, res?.data?.resp?.fleet_id)
        );
        const fleetResponse = await fleetOverview(access_token);
        dispatch(setFleetOverview(fleetResponse.data.resp));
        dispatch(setChangeInInventory(!currInventoryChangeValue));
        handleClose();
      } else {
        dispatch(setNotification(t('common.error'), 'error', true));
      }
    } catch (error) {
      console.error('E20059', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const disableCondition =
    isSubmitting ||
    eventDate.toString() === 'Invalid Date' ||
    typedYear < 1900 ||
    typedYear > 2099;
  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      data-hook='delete-tire-modal'
    >
      <Fade in={open}>
        <Card className={classes.root}>
          <CardContent>
            <IconButton
              style={{ float: 'right' }}
              onClick={() => handleClose()}
              data-hook='close-modal'
            >
              <Close />
            </IconButton>
            <Typography variant='h5' align='center'>
              {t('common.tireInventory.removeTooltip')}
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label={'Event Date'}
                    value={eventDate}
                    onChange={date => {
                      setEventDate(date);
                      const year = date.getFullYear().toString();
                      setTypedYear(parseInt(year));
                    }}
                    format='yyyy.MM.dd'
                    sx={{ width: '100%' }}
                    data-hook='event-date-picker'
                  />
                </LocalizationProvider>
              </Box>

              <Box width='100%' mx='auto'>
                <Button
                  color='secondary'
                  type='submit'
                  variant='contained'
                  className={classes.btn}
                  fullWidth
                  disabled={disableCondition}
                  data-hook='delete-button'
                >
                  <Typography variant='h6' align='center'>
                    {t('deleteTire.delete')}
                  </Typography>
                </Button>
              </Box>
            </form>
            <Box my='1rem'>
              <Typography variant='subtitle2' gutterBottom align='center'>
                {t('deleteTire.warning')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Fade>
    </Modal>
  );
};

export default DeleteTire;

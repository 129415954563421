import React, { useState } from 'react';
import { Typography, Box, Chip, useTheme, Tooltip } from '@mui/material';
import { setActiveVehicle } from 'redux/fleets/ducks';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { checkTireConfig } from 'utils/helper';
import img from 'assets/images/link.png';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => {
  return {
    chip: {
      borderRadius: 0,
      fontWeight: 900,
      margin: '0.5rem 0rem',
    },
    card: {
      height: 'fitContent',
      boxShadow: 'none',
      cursor: 'pointer',
      padding: '0px 1.5rem',

      '& h2': {
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },

      '&:hover': {
        background: '#fafafa',
      },
    },
    arrows: {
      '&:hover': {
        background: 'rgba(0, 0, 0, 0.04)',
      },
    },
    viewCardRoot: {
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #ccc',
    },
    imgContainer: {
      width: '20px',
      height: 'inherit',
      display: 'block',
      overflow: 'hidden',

      '& img': {
        width: '100%',
        height: 'inherit',
        objectFit: 'contain',
      },
    },
  };
});

const VehicleItems = props => {
  const {
    dispatch,
    entry,
    activeVehicle,
    subitem,
    t,
    push,
    fullView,
    vehicles,
  } = props;

  const { classes: styles } = useStyles();
  const [open, setOpen] = useState(false);
  const [openAlt, setOpenAlt] = useState(true);
  const theme = useTheme();

  const getTrailerName = id => {
    const found = vehicles?.find(v => v.vehicle_id === id);

    return found ? found.name : 'N/A';
  };

  function navFunction(entry, subitem) {
    dispatch(
      setActiveVehicle(
        entry
          ? {
              ...subitem,
              vehicle_type: entry.slice(0, entry.length - 1),
            }
          : {
              ...subitem,
              vehicle_type: activeVehicle?.vehicle_type.slice(
                0,
                activeVehicle?.vehicle_type.length - 1
              ),
            }
      )
    );
    localStorage.setItem(
      'activeVehicle',
      JSON.stringify(
        entry
          ? {
              ...subitem,
              vehicle_type: entry.slice(0, entry.length - 1),
            }
          : {
              ...subitem,
              vehicle_type: activeVehicle?.vehicle_type.slice(
                0,
                activeVehicle?.vehicle_type.length - 1
              ),
            }
      )
    );
    push(`/fleets-data/${subitem?.fleet_id}/${entry}/${subitem?.vehicle_id}`);
  }

  const view = (
    <>
      <Box className={styles.viewCardRoot}>
        <Chip
          size='medium'
          style={{
            borderRadius: 0,
            backgroundColor:
              subitem?.axle_type !== null
                ? checkTireConfig(subitem)
                  ? theme.palette.primary.light
                  : '#f5eab4'
                : 'rgba(220, 110, 85, 0.15)',
            color:
              subitem?.axle_type !== null
                ? checkTireConfig(subitem)
                  ? theme.palette.primary.main
                  : 'black'
                : '#DC6E55',
            fontWeight: 900,
            margin: '0.5rem 0rem',
          }}
          label={
            subitem?.axle_type !== null
              ? checkTireConfig(subitem)
                ? t('common.configured')
                : t('common.partialConfig')
              : t('fleetsSection.notConfigured')
          }
        />
      </Box>
      <h2>
        {t('fleetsSection.vehicleNumber')}: <span>{subitem?.name}</span>
      </h2>
      <h2>
        {t('fleetsSection.numberPlate')}: <span>{subitem?.reg_plate}</span>
      </h2>
      <h2>
        {t('fleetsSection.internalGrouping')}:{' '}
        <span>{subitem?.internal_grouping}</span>
      </h2>
      <h2>
        {t('fleetsSection.telematics')}: {subitem?.protocol ?? 'N/A'}
      </h2>
      {subitem?.associated_trailer_id && (
        <h2>
          {t('vehiclePage.selectLabel')}{' '}
          {getTrailerName(subitem?.associated_trailer_id)}
        </h2>
      )}
    </>
  );

  const view2 = (
    <Box
      display='flex'
      alignItems='center'
      gap={
        fullView === undefined || !fullView[entry]
          ? open
            ? 0
            : '5px'
          : openAlt
          ? 0
          : '5px'
      }
    >
      <Typography variant='h5' gutterBottom align='left'>
        {subitem?.name}
      </Typography>
      {!checkTireConfig(subitem) && (
        <Chip
          size='medium'
          style={{
            backgroundColor:
              subitem?.axle_type !== null
                ? checkTireConfig(subitem)
                  ? theme.palette.primary.light
                  : '#f5eab4'
                : 'rgba(220, 110, 85, 0.15)',
            color:
              subitem?.axle_type !== null
                ? checkTireConfig(subitem)
                  ? theme.palette.primary.main
                  : 'black'
                : '#DC6E55',
          }}
          className={styles.chip}
          label={
            subitem?.axle_type !== null
              ? checkTireConfig(subitem)
                ? t('common.configured')
                : t('common.partialConfig')
              : t('fleetsSection.notConfigured')
          }
        />
      )}
      <Chip
        label={subitem?.protocol ?? 'Telematics N/A'}
        size='medium'
        style={{
          backgroundColor: subitem?.protocol
            ? theme.palette.primary.light
            : 'rgba(220, 110, 85, 0.15)',
          color: subitem?.protocol ? theme.palette.primary.main : '#DC6E55',
        }}
        className={styles.chip}
      />
      {subitem?.associated_trailer_id && (
        <Tooltip title='Linked Trailer'>
          <Chip
            label={
              <Box display='flex' alignItems='center'>
                <div className={styles.imgContainer}>
                  <img src={img} alt='icon showing linked trailer' />
                </div>
                <span>{getTrailerName(subitem?.associated_trailer_id)}</span>
              </Box>
            }
            size='medium'
            className={styles.chip}
          />
        </Tooltip>
      )}
      {subitem?.associated_truck_name && (
        <Tooltip title='Linked Truck'>
          <Chip
            label={
              <Box display='flex' alignItems='center'>
                <div className={styles.imgContainer}>
                  <img src={img} alt='icon showing linked truck' />
                </div>
                <span>{subitem?.associated_truck_name}</span>
              </Box>
            }
            size='medium'
            className={styles.chip}
          />
        </Tooltip>
      )}
    </Box>
  );

  const renderView = open => {
    if (open) {
      return view;
    }
    return view2;
  };

  const renderVehicleItems = (isOpen, setter) => {
    return (
      <Box className={styles.content}>
        <div
          style={{ width: '100%' }}
          onClick={() => {
            navFunction(entry, subitem);
          }}
        >
          {renderView(isOpen)}
        </div>
        <div
          style={{ alignSelf: isOpen ? 'flex-start' : 'center' }}
          className={styles.arrows}
          onClick={() => setter(!isOpen)}
        >
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
      </Box>
    );
  };

  return (
    <div key={subitem?.vehicle_id} className={styles.card}>
      {fullView === undefined || !fullView[entry]
        ? renderVehicleItems(open, setOpen)
        : renderVehicleItems(openAlt, setOpenAlt)}
    </div>
  );
};

export default VehicleItems;

VehicleItems.propTypes = {
  dispatch: PropTypes.any,
  entry: PropTypes.any,
  activeVehicle: PropTypes.object,
  subitem: PropTypes.object,
  t: PropTypes.any,
  push: PropTypes.func,
  fullView: PropTypes.any,
  vehicles: PropTypes.array,
};
